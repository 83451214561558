import dayjs, { Dayjs } from 'dayjs';
import { LabeledValue, SelectValue } from 'antd/lib/select';

import { OPTION_KEYS_FOR_SELECT } from 'app-constants/select-options';
import { useAppSelector } from 'hooks/store-hooks';
import { FilterReturnType } from 'hooks/use-filter';
import wimSelectors from 'store/wim/selectors';
import { TransitsFilterValues } from 'store/wim/types/transits';

export function useIntensityTrafficFilter<TransitsType extends TransitsFilterValues>({
  state,
  handleFilterChange,
}: Pick<FilterReturnType<Partial<TransitsType>>, 'handleFilterChange' | 'state'>) {
  const platforms = useAppSelector(wimSelectors.platforms.getPlatforms);

  const onPlatformNameChange = (value: SelectValue) => {
    if (value && Array.isArray(value)) {
      if (value.includes(OPTION_KEYS_FOR_SELECT.All as string & LabeledValue)) {
        handleFilterChange(
          'wimIds',
          platforms?.map((platform) => platform.uid)
        );
      } else {
        handleFilterChange('wimIds', value);
      }
    }
  };

  const onRangePickerChange = (ranges: [Dayjs | null, Dayjs | null] | null) => {
    if (!ranges) {
      return;
    }

    const [start, end] = ranges;

    if (start && end) {
      handleFilterChange('start', dayjs(start).valueOf());
      handleFilterChange('end', dayjs(end).valueOf());
    }
  };

  return {
    state,
    handleFilterChange,
    platforms,
    onPlatformNameChange,
    onRangePickerChange,
  };
}
