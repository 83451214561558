import { put, takeEvery } from 'redux-saga/effects';
import makeRequestSaga from 'store/common';
import { getWimsAvailability } from 'api/statistics';

import { SagaPayload } from 'types';
import { GetStatisticQueryParamsType } from '../types';
import { requestWimsStatus, getWimsStatusSuccess, getWimsStatusError, setIsLoading } from './slice';

export function* wimsStatusRequestSaga({ payload }: SagaPayload<GetStatisticQueryParamsType>) {
  yield put(setIsLoading(true));
  yield makeRequestSaga(getWimsStatusSuccess, getWimsStatusError, getWimsAvailability);
}

export function* wimsStatusRequestWatchSaga() {
  yield takeEvery(requestWimsStatus, wimsStatusRequestSaga);
}
