import { useEffect, useState } from 'react';
import qs from 'qs';
import { useHistory } from 'react-router-dom';

interface UrlParamsProps {
  initialUrlParams?: {
    [key: string]: any;
  };
}

export function stringifyUrlParams(urlParams: Record<string, any>, options?: qs.IStringifyOptions) {
  return qs.stringify(urlParams, {
    addQueryPrefix: true,
    ...options,
  });
}

export function parseQueryUrlString(queryUrl: string): Record<string, any> {
  return qs.parse(queryUrl, {
    ignoreQueryPrefix: true,
  }) as Record<string, any>;
}

export const useUrlParams = ({ initialUrlParams }: UrlParamsProps) => {
  const history = useHistory();
  const [queryUrlParams, setQueryUrlParams] = useState(() => parseQueryUrlString(history.location.search));

  const onUrlParamsChange = (newUrlParams: Record<string, any>) => {
    setQueryUrlParams({ ...queryUrlParams, ...newUrlParams });
  };

  const onPushUrlParams = (newUrlParams: Record<string, any>) => {
    history.push({ search: stringifyUrlParams({ ...queryUrlParams, ...newUrlParams }) });
  };

  const onSliceUrlParams = (slicedUrlParams: Record<string, any>) => {
    history.push({ search: stringifyUrlParams(slicedUrlParams) });
  };

  const onRemoveUrlParams = (removedUrlParams: string | string[]) => {
    const newUrlParams = JSON.parse(JSON.stringify(queryUrlParams));
    if (Array.isArray(removedUrlParams)) {
      removedUrlParams.forEach((param) => {
        delete newUrlParams[param];
      });
    } else {
      delete newUrlParams[removedUrlParams];
    }
    history.push({ search: stringifyUrlParams(newUrlParams) });
  };

  useEffect(() => {
    if (initialUrlParams) {
      setQueryUrlParams({ ...queryUrlParams, ...initialUrlParams });
      onPushUrlParams(initialUrlParams);
    }
    setQueryUrlParams(parseQueryUrlString(history.location.search));
  }, [history.location.search]);

  return { queryUrlParams, onUrlParamsChange, onPushUrlParams, onSliceUrlParams, onRemoveUrlParams };
};
