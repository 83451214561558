import _ from 'lodash';

import { WimViolationType } from 'store/platforms/wims/wim/transits/violations/types';

export const getViolationsChartData = (violationsData: WimViolationType[]) => {
  const violationCount = _(violationsData)
    .groupBy(({ totalWeight }) => Math.round(totalWeight / 1000))
    .map((overweights) => ({
      weight: overweights.map(({ totalWeight }) => Math.round(totalWeight / 1000))[0],
      ..._(overweights).countBy('overweight').value(),
    }))
    .value();

  const weights = _(violationCount)
    .chain()
    .map((val: { [key: string]: number }) => val.weight)
    .value();

  const minWeight = _(weights).min();
  const maxWeight = _(weights).max();
  const chartData = [];

  if (maxWeight !== undefined && minWeight !== undefined) {
    for (let i = +minWeight; i <= +maxWeight; i += 1) {
      const currentWeight = violationCount.findIndex((a: { [key: string]: string | number }) => a.weight === i);

      if (currentWeight !== -1) {
        chartData.push(violationCount[currentWeight]);
      } else {
        chartData.push({
          weight: i,
          axle: 0,
        });
      }
    }
  }
  return chartData;
};
