import { useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch } from 'react-redux';

import { DEFAULT_RANGE_DAYS } from 'app-constants/defaults';
import { wimActions } from 'store/wim/slices';
import { WimPlatformUid } from 'store/wim/types';
import { SummaryTypes } from '../../../store/wim/types/platforms';

function useGetPlatformSummary(wimPlatformUid?: WimPlatformUid, start?: Dayjs, end?: Dayjs) {
  const dispatch = useDispatch();

  useEffect(() => {
    const dateRange = {
      start:
        start?.valueOf() ||
        dayjs()
          .subtract(DEFAULT_RANGE_DAYS - 1, 'days')
          .valueOf(),
      end: end?.valueOf() || dayjs().valueOf(),
    };

    if (wimPlatformUid) {
      dispatch(
        wimActions.platforms.requestPlatformSummary({
          wimUid: wimPlatformUid,
          ...dateRange,
          type: [SummaryTypes.ERRORS, SummaryTypes.PLATE_RECOGNITIONS_HOURLY, SummaryTypes.LAST_TRANSITS_DATES],
        })
      );
    }
  }, [dispatch, end, start, wimPlatformUid]);
}

export default useGetPlatformSummary;
