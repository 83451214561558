import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type WimsStatusType = {
  total: number;
  ok: number;
  error: number;
  warning: number;
};

export interface WimsStatusState {
  data: WimsStatusType;
  isLoading: boolean;
  error: Error | null;
}

const initialState: WimsStatusState = {
  data: {
    error: 0,
    ok: 0,
    warning: 0,
    total: 0,
  },
  isLoading: false,
  error: null,
};

const wimsStatus = createSlice({
  name: 'wimsStatus',
  initialState,
  reducers: {
    requestWimsStatus: (state, _payload): void => {
      state.isLoading = true;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>): void => {
      state.isLoading = payload;
    },
    getWimsStatusSuccess: (state, { payload }): void => {
      state.isLoading = false;
      state.data = payload;
    },
    getWimsStatusError: (state, { payload }): void => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { requestWimsStatus, getWimsStatusSuccess, getWimsStatusError, setIsLoading } = wimsStatus.actions;

export default wimsStatus.reducer;
