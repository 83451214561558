import palette from 'palette';
import styled from 'styled-components/macro';

import SFUITextMedium from 'fonts/SFUIText-Medium.woff';

interface TransitInfoItemContainerProps {
  vertical: boolean;
  wide: boolean;
  isLeftAlign: boolean;
}

interface TextValueProps {
  isError?: boolean;
  wide?: boolean;
  centerValue?: boolean;
}

export const TransitInfoItemContainer = styled.div<TransitInfoItemContainerProps>`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  justify-content: ${({ wide, isLeftAlign }) => {
    const align = isLeftAlign ? 'flex-start' : 'flex-end';
    return wide ? 'space-between' : align;
  }};
  align-items: center;
  margin: 5px 0;
`;

export const Title = styled.span`
  color: ${palette.black.b3};
  white-space: nowrap;
`;

export const TextValue = styled.span<TextValueProps>`
  @font-face {
    font-family: 'SFUITextMedium';
    font-size: 14px;
    src: url(${SFUITextMedium}) format('woff');
  }
  ${({ centerValue }) => centerValue && 'text-align: center'};
  font-family: 'SFUITextMedium';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  ${({ wide }) => wide && 'width: 40%'};
  padding: 0 6px;
  border-radius: 4px;
  min-width: 90px;
  background-color: ${(props) => (props.isError ? palette.red.r3 : 'none')};
  color: ${(props) => (props.isError ? palette.white.w1 : palette.black.b4)};
  margin: 0 5px;
`;
