import * as intrudersListSelector from './intrudersList/selectors';
import * as overloadStatisticsSelector from './overloadStatistics/selectors';
import * as platformsListSelector from './platformsList/selectors';
import * as totalTransitsSelector from './totalTransits/selectors';
import * as trucksTransitsSelector from './trucksTransits/selectors';
import * as transitsStatisticSelector from './transitsStatistic/selectors';
import * as violationOverloadStatisticSelector from './violationOverloadStatistic/selectors';
import * as vrpDetectStatisticSelector from './vrpDetectStatistic/selectors';
import * as wimsStatusSelector from './wimsStatus/selectors';

export const statisticsSelectors = {
  intrudersList: {
    ...intrudersListSelector,
  },
  overloadStatistics: {
    ...overloadStatisticsSelector,
  },
  platformsList: {
    ...platformsListSelector,
  },
  transitsStatistic: {
    ...transitsStatisticSelector,
  },
  totalTransits: {
    ...totalTransitsSelector,
  },
  trucksTransits: {
    ...trucksTransitsSelector,
  },
  violationOverloadStatistic: {
    ...violationOverloadStatisticSelector,
  },
  vrpDetectStatistic: {
    ...vrpDetectStatisticSelector,
  },
  wimsStatus: {
    ...wimsStatusSelector,
  },
};
