import React from 'react';

import { Col, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT, RANGES } from 'app-constants/date';
import { OPTION_KEYS_FOR_SELECT } from 'app-constants/select-options';

import { DatePicker } from 'components';
import { ContentFilterProps } from 'hoc/withFilter';
import { TransitsArchiveFilterValues, TransitsIntensityTrafficFilterValues } from 'store/wim/types/transits';

import { StyledSelect, StyledFilter } from './styles';
import { useIntensityTrafficFilter } from './hooks/use-intensity-traffic-filter';

export const INITIAL_TRANSITS_FILTER_VALUES: Partial<TransitsArchiveFilterValues> = {
  start: dayjs().startOf('day').valueOf(),
  end: dayjs().endOf('day').valueOf(),
};

const IntensityTrafficFilter: React.FC<ContentFilterProps<TransitsIntensityTrafficFilterValues>> = ({
  filterManager,
}) => {
  const { Option } = Select;
  const { t } = useTranslation();

  const { onPlatformNameChange, onRangePickerChange, state, platforms } = useIntensityTrafficFilter(filterManager);

  const ranges = {
    [t('date.hour')]: RANGES.getHourRange(),
    [t('date.day')]: RANGES.getDayRange(),
    [t('date.week')]: RANGES.getWeekRange(),
    [t('date.month')]: RANGES.getMonthRange(),
    [t('date.year')]: RANGES.getYearRange(),
    [t('date.quarter')]: RANGES.getQuarterRange(),
  };

  const initialDateRange: [Dayjs, Dayjs] = [
    state.start ? dayjs(state.start) : dayjs().startOf('day'),
    state.end ? dayjs(state.end) : dayjs().endOf('day'),
  ];

  return (
    <StyledFilter>
      <Col span={5}>
        <StyledSelect
          maxTagTextLength={20}
          maxTagCount={1}
          allowClear
          value={state.platformNames}
          showSearch
          placeholder={t('filter.allPlatforms')}
          optionFilterProp="children"
          onChange={onPlatformNameChange}
          mode="multiple"
        >
          <Option key={OPTION_KEYS_FOR_SELECT.All} value={OPTION_KEYS_FOR_SELECT.All}>
            {t('filter.selectAll')}
          </Option>
          {platforms?.map((platform) => (
            <Option key={platform.uid} value={platform.uid}>
              {platform.wim}
            </Option>
          ))}
        </StyledSelect>
      </Col>
      <Col span={12} offset={1}>
        <DatePicker.RangePicker
          clearIcon={false}
          value={initialDateRange}
          showTime={{ format: DATE_FORMAT.RANGE_PICKER_TIME }}
          format={DATE_FORMAT.RANGE_PICKER}
          ranges={ranges as Record<string, [Dayjs | null, Dayjs | null]>}
          onChange={onRangePickerChange}
        />
      </Col>
    </StyledFilter>
  );
};

export default IntensityTrafficFilter;
