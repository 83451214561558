import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { DefaultTableContainer, ModalWrapper } from 'components';
import { stringifyUrlParams, useUrlParams } from 'hooks/use-url-params';
import { Modal } from 'types/modal';
import { WimPlatform } from 'store/wim/types';
import { useDispatch } from 'react-redux';
import { Certificate } from 'store/admin/certificates/types';
import { requestUpdateCertificates } from 'store/admin/certificates/slice';
import { StyledEditLink, StyledContent } from './styles';
import { UpdateContent } from './modal/UpdateContent';
import { certificateColumns, getDataSource } from './table-schema';
import { UpdateCertificateValues } from './modal/UpdateContent/types';

interface CertificateSummaryProps {
  certificates: Certificate[];
  selectedPlatformId: WimPlatform['uid'];
  isLoading: boolean;
  isEditable?: boolean;
}

const getChangedValues = (prev: Certificate[], changed: Partial<UpdateCertificateValues>) => {
  const changedUids = changed.certificates?.map((item) => item.uid) || [];
  const deleted = prev.filter((item) => !changedUids.includes(item.uid));
  const added = changed.certificates?.filter((item) => item.uid === '');
  const updated = changed.certificates?.filter((item) => {
    if (item.uid === '') {
      return false;
    }
    const prevCert = prev.find((prevItem) => prevItem.uid === item.uid);

    return !(
      prevCert &&
      prevCert.type === item.type &&
      prevCert.name === item.name &&
      prevCert.description === item.description
    );
  });

  return {
    added,
    updated,
    deleted: deleted.length ? deleted.map((item) => item.uid) : [],
  };
};

const CertificateSummary: React.FC<CertificateSummaryProps> = ({
  certificates,
  selectedPlatformId,
  isLoading,
  isEditable,
}) => {
  const dispatch = useDispatch();
  const { queryUrlParams } = useUrlParams({});
  const {
    location: { pathname, search },
    goBack,
  } = useHistory();
  const { t } = useTranslation();
  const onSubmitForm = (values: Partial<UpdateCertificateValues>): void => {
    const changed = getChangedValues(certificates, values);
    dispatch(requestUpdateCertificates({ ...changed, wimUid: selectedPlatformId as string }));
    goBack();
  };

  return (
    <StyledContent>
      <StyledEditLink>
        <Link
          to={{
            pathname,
            search: stringifyUrlParams({
              ...queryUrlParams,
              modal: Modal.EDIT_DEVICE_CERTIFICATE,
              platform: selectedPlatformId,
            }),
          }}
        >
          <EditOutlined style={{ fontSize: 20 }} />
        </Link>
      </StyledEditLink>
      <DefaultTableContainer columns={certificateColumns} dataSource={getDataSource(certificates)} bordered />
      {isEditable && (
        <ModalWrapper
          isVisible={search.includes(`modal=${Modal.EDIT_DEVICE_CERTIFICATE}`)}
          onClose={goBack}
          title={t('adminWimsPage.updateCertificates')}
          width={900}
          destroyOnClose
        >
          <UpdateContent certificates={getDataSource(certificates)} onSubmitForm={onSubmitForm} isLoading={isLoading} />
        </ModalWrapper>
      )}
    </StyledContent>
  );
};

export { CertificateSummary };
