import { useEffect, useState } from 'react';
import { PageHeaderProps } from 'antd';
import { Location } from 'history';

import { ROUTES } from '../app-constants/routes';
import { RouteToTitle } from '../app-constants/i18n';

type HeaderTitle = PageHeaderProps['title'];
type HeaderContent = PageHeaderProps['extra'];
type SyncHeaderProps = {
  getRouteToTitle: RouteToTitle;
  location: Location;
  // currentRoute?: string;
};

export type SyncHeaderHookReturnType = {
  setHeaderRenderer: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
  headerTitle: HeaderTitle;
  headerContent: HeaderContent;
  onBackButton: (() => void) | undefined;
  setHeaderOnBackButtonState: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
  dev?: boolean;
};

export function useSyncHeader({ getRouteToTitle, location }: SyncHeaderProps): SyncHeaderHookReturnType {
  const [dev, setDev] = useState(false);
  const [headerTitle, setHeaderTitle] = useState<HeaderTitle>('');
  const [headerContent, setHeaderContent] = useState<HeaderContent>(null);
  const [headerRenderedInner, setHeaderRenderer] = useState<JSX.Element | null>(null);
  const [onBackButton, setHeaderOnBackButtonState] = useState<() => void | undefined>();

  useEffect(() => {
    const localizedRoutes = getRouteToTitle(location.pathname);
    const titleConfig = localizedRoutes[location.pathname as ROUTES];

    if (titleConfig && titleConfig.headerTitle !== headerTitle) {
      setHeaderTitle(titleConfig.headerTitle);
    }
    if (titleConfig && titleConfig.dev) {
      setDev(true);
    } else {
      setDev(false);
    }
    setHeaderContent(headerRenderedInner || null);
  }, [location.pathname, headerRenderedInner, getRouteToTitle, headerTitle, onBackButton]);

  return {
    dev,
    setHeaderRenderer,
    setHeaderOnBackButtonState,
    headerTitle,
    headerContent,
    onBackButton,
  };
}
