import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PlatformsListType = {
  wimId: string;
  date: number;
  vrpFront?: string;
  vehicleClass?: number;
  name?: string;
  road?: string;
  violations: number;
  wimUid: string;
};

interface PlatformsListState {
  data: PlatformsListType[];
  isLoading: boolean;
  error: Error | null;
}

const initialState: PlatformsListState = {
  data: [],
  isLoading: false,
  error: null,
};

const platformsList = createSlice({
  name: 'platformsList',
  initialState,
  reducers: {
    requestPlatformsList: (state, _payload): void => {
      state.isLoading = true;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>): void => {
      state.isLoading = payload;
    },
    getPlatformsListSuccess: (state, { payload }): void => {
      state.isLoading = false;
      state.data = payload;
    },
    getPlatformsListError: (state, { payload }): void => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { requestPlatformsList, getPlatformsListSuccess, getPlatformsListError, setIsLoading } =
  platformsList.actions;

export default platformsList.reducer;
