import qs from 'qs';

import env from 'config/env';

export const trimUrlQueryKey = (query: string) => query.substring(0, query.indexOf('='));

export const trimUrlQueryValue = (query: string) => query.substring(query.indexOf('=') + 1, query.length);

export const getExportUrl = (path: string, query: Record<string, any>) => {
  const searchParams = qs.stringify(query, { arrayFormat: 'brackets', addQueryPrefix: true });

  return path + searchParams;
};

export const splitPlatformPath = (path: string) => {
  const splittedPath = path.split('/');
  const mappedMainPath = splittedPath.slice(0, splittedPath.length - 1);
  const selectedPlatformUid = splittedPath[splittedPath.length - 1];

  return {
    onlyPlatformUid: selectedPlatformUid,
    withoutPlatformUid: mappedMainPath.join('/'),
  };
};
