import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { DEFAULT_RANGE_DAYS } from 'app-constants/defaults';
import { DATE_FORMAT } from 'app-constants/date';
import { NormalizedSummary } from 'store/wim/normalization';
import { PlateRecognitionPayload } from 'components/BarChart/BarChartPlateRecognitions';
import { getDaysRange, formatDateTo } from 'helpers/date.formatter';
import PlateRecognitionWrapper from './PlateRecognitionWrapper';

interface PlateRecognitionGraphProps {
  summary: NormalizedSummary;
}

const PlateRecognitionGraph: React.FC<PlateRecognitionGraphProps> = ({ summary }) => {
  const { t } = useTranslation();
  const { totalPlateRecognitions } = summary;
  const plateRecognitionDateKeys = Object.keys(totalPlateRecognitions.byDate);

  const range = {
    start: dayjs()
      .subtract(DEFAULT_RANGE_DAYS - 1, 'days')
      .valueOf(),
    end: dayjs().valueOf(),
  };

  const noData = getDaysRange(range).reduce((acc, currentDate) => {
    return [
      ...acc,
      {
        recognizedKey: 0,
        unrecognizedKey: 0,
        timestamp: 0,
        date: dayjs(currentDate).format(DATE_FORMAT.RANGE_PICKER_TIME),
        fullDate: dayjs(currentDate).format(DATE_FORMAT.TABLE_FORMAT),
      },
    ];
  }, [] as PlateRecognitionPayload[]);

  const data: PlateRecognitionPayload[] = plateRecognitionDateKeys.length
    ? plateRecognitionDateKeys.sort().map((key) => ({
        recognizedKey: totalPlateRecognitions.byDate[key].recognized,
        unrecognizedKey: totalPlateRecognitions.byDate[key].unrecognized,
        timestamp: parseInt(key, 10),
        date: formatDateTo(parseInt(key, 10), DATE_FORMAT.RANGE_PICKER_TIME),
        fullDate: formatDateTo(parseInt(key, 10), DATE_FORMAT.TABLE_FORMAT),
      }))
    : noData;

  return (
    <>
      <span>{t('map.mapModal.lastPlateRecognitions', { days: DEFAULT_RANGE_DAYS })}</span>
      <PlateRecognitionWrapper data={data} recognizedKey="recognizedKey" unrecognizedKey="unrecognizedKey" />
    </>
  );
};

export default PlateRecognitionGraph;
