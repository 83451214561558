import React from 'react';

import { StyledModal, StyledModalTitle } from './styles';

export interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
  title: string | JSX.Element | undefined;
  style?: React.CSSProperties;
  width?: number;
  destroyOnClose?: boolean;
}

function ModalWrapper(props: React.PropsWithChildren<ModalProps>): JSX.Element {
  return (
    <StyledModal
      style={props.style}
      title={<StyledModalTitle>{props.title}</StyledModalTitle>}
      onCancel={props.onClose}
      visible={props.isVisible}
      footer={null}
      transitionName=""
      maskTransitionName=""
      width={props.width}
      destroyOnClose={props.destroyOnClose}
    >
      {props.children}
    </StyledModal>
  );
}

export default ModalWrapper;
