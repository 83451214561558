import styled from 'styled-components/macro';
import { Button, Card, Input, Space } from 'antd';
import palette from 'palette';

export const StyledExportDialogCard = styled(Card)`
  position: absolute;
  width: 400px;
  top: 80px;
  right: 50px;
  z-index: 50;
`;
export const StyledCloseBtn = styled(Button)`
  color: ${palette.white.w1};
  background: ${palette.red.r6};
`;

export const StyledEmailField = styled(Space)`
  width: 100%;
`;

export const StyledInput = styled(Input)`
  : {
    border: chartreuse;
  }
`;
