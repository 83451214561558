import React from 'react';
import { InputNumber, Slider } from 'antd';
import { useTranslation } from 'react-i18next';
import { Title } from './styles';

interface SliderWithNumericInputsProps {
  title: string;
  startValue?: number;
  endValue?: number;
  max: number;
  min?: number;
  onStartChange?: (value: number | undefined) => void;
  onEndChange?: (value: number) => void;
  onSliderChange?: (value: [number, number]) => void;
  formatter?: (value?: number) => string;
  startPlaceholder?: string;
  endPlaceholder?: string;
}

export const SliderWithNumericInputs: React.FC<SliderWithNumericInputsProps> = ({
  title,
  startValue,
  endValue,
  min = 0,
  max,
  startPlaceholder,
  endPlaceholder,
  formatter,
  onStartChange,
  onEndChange,
  onSliderChange,
}) => {
  const { t } = useTranslation();
  const startInputHandler = (e: number) => {
    if (!e && endValue) {
      return onSliderChange ? onSliderChange([e, endValue]) : null;
    }

    if (e && !endValue) {
      onEndChange && onEndChange(e);
      return onStartChange ? onStartChange(e) : null;
    }

    if (e >= max) {
      onEndChange && onEndChange(max);
      return onStartChange ? onStartChange(max) : null;
    }

    if (endValue !== undefined && e > endValue) {
      onEndChange && onEndChange(e);
      return onStartChange ? onStartChange(e) : null;
    }

    return onStartChange ? onStartChange(e) : null;
  };
  const endInputHandler = (e: number) => {
    if (!e) {
      return onSliderChange ? onSliderChange([e, e]) : null;
    }

    if (startValue !== undefined && e < startValue) {
      onStartChange && onStartChange(e);
      return onEndChange ? onEndChange(e) : null;
    }

    return onEndChange ? onEndChange(e) : null;
  };

  return (
    <div>
      <Title>{title}</Title>
      <div>
        <InputNumber<number>
          style={{ marginRight: 8 }}
          min={min}
          max={max}
          value={startValue}
          stringMode={false}
          placeholder={startPlaceholder || t('common.min')}
          onChange={startInputHandler}
          formatter={formatter}
        />
        <InputNumber<number>
          min={min}
          max={max}
          value={endValue}
          placeholder={endPlaceholder || t('common.max')}
          onChange={endInputHandler}
          formatter={formatter}
        />
      </div>
      <Slider min={min} max={max} value={[startValue || 0, endValue || 0]} onChange={onSliderChange} range />
    </div>
  );
};
