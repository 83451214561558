import styled from 'styled-components/macro';
import { Row, Col } from 'antd';
import palette from 'palette';

export const StyledWimsWrapper = styled.div`
  padding: 12px 20px;
  background-color: ${palette.white.w1};
`;
export const StyledHeaderWrapper = styled(Row)`
  width: 100%;
  height: 36px;
  font-size: 14px;
`;
export const StyledHeaderWims = styled(Col)`
  align-items: center;
  height: 36px;
  font-size: 14px;
  color: ${palette.grey.g7};
`;
