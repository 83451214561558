import React, { FC, ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';

import { StyledSelectWithLabel, StyledLabel } from './styles';

interface SelectPlatformMediaFileProps {
  name: string;
  setFile: (file: File) => void;
  fileName?: string;
}

export const FormikSelectMediaFile: FC<SelectPlatformMediaFileProps> = ({ name, setFile, fileName }) => {
  const { t } = useTranslation();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  return (
    <StyledSelectWithLabel>
      <StyledLabel>{t('infoFields.file')}</StyledLabel>
      {!fileName && <Field type="file" name={name} onChange={handleFileChange} />}
      <div>{fileName}</div>
    </StyledSelectWithLabel>
  );
};
