import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spin } from 'antd';
import { Formik } from 'formik';

import palette from 'palette';
import { FormikInputFieldWithLabel } from 'elements/FormikInputFieldWithLabel';
import { FormikInputFieldWithLabelAndDatePicker } from 'elements/FormikInputFieldWithLabelAndDatePicker';
import { getChangedValues } from 'helpers/formik.helpers';
import { StyledButtons, StyledContentForm, StyledEditBlock } from './styles';
import { PlatformFieldName, PlatformState, UpdatePlatformValues } from '../../types';
import { FormikDatePickerWithLabel } from '../../../../../../../elements/FormikDatePickerWithLabel';

interface UpdateContentProps {
  state: PlatformState;
  onSubmitForm: (values: Partial<UpdatePlatformValues>) => void;
  isLoading: boolean;
}

export const UpdateContent: FC<UpdateContentProps> = ({ state, onSubmitForm, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Formik<UpdatePlatformValues>
      initialValues={{
        address: state.address,
        alias: state.alias,
        description: state.description,
        road: state.road,
        region: state.region,
        status: state.status,
        measureStatus: state.measureStatus,
        provider: state.provider,
        mapPosition: state.mapPosition,
        wim: state.wim,
        serialNumber: state.serialNumber,
        verificationCertificate: state.verificationCertificate,
        effectiveDate: state.effectiveDate,
        lanes: state.lanes,
      }}
      onSubmit={(submittedValues) => {
        onSubmitForm(getChangedValues(submittedValues, state));
      }}
    >
      {({ values, handleChange, handleSubmit, handleReset, setFieldValue }) => (
        <StyledContentForm onSubmit={handleSubmit} onReset={handleReset}>
          <Spin spinning={isLoading}>
            <StyledEditBlock>
              <FormikInputFieldWithLabel
                name={PlatformFieldName.address}
                onChange={handleChange}
                label={t('infoFields.address')}
                defaultValue={values.address || ''}
              />
              <FormikInputFieldWithLabel
                name={PlatformFieldName.road}
                onChange={handleChange}
                label={t('infoFields.road')}
                defaultValue={values.road || ''}
              />
              <FormikInputFieldWithLabel
                name={PlatformFieldName.mapPosition}
                onChange={handleChange}
                label={t('infoFields.coordinates')}
                defaultValue={values.mapPosition}
              />
              <FormikInputFieldWithLabel
                name={PlatformFieldName.region}
                onChange={handleChange}
                label={t('infoFields.region')}
                defaultValue={values.region || ''}
              />
              <FormikInputFieldWithLabel
                name={PlatformFieldName.wim}
                onChange={handleChange}
                label={t('infoFields.name')}
                defaultValue={values.wim}
              />
              <FormikInputFieldWithLabel
                name={PlatformFieldName.alias}
                onChange={handleChange}
                label={t('infoFields.shortName')}
                defaultValue={values.alias || ''}
              />
              <FormikInputFieldWithLabel
                name={PlatformFieldName.provider}
                onChange={handleChange}
                label={t('infoFields.provider')}
                defaultValue={values.provider || ''}
              />
              <FormikInputFieldWithLabel
                name={PlatformFieldName.status}
                onChange={handleChange}
                label={t('infoFields.status')}
                defaultValue={values.status || ''}
              />
              <FormikInputFieldWithLabel
                name={PlatformFieldName.description}
                onChange={handleChange}
                label={t('infoFields.description')}
                defaultValue={values.description || ''}
              />
              <FormikInputFieldWithLabel
                name={PlatformFieldName.serialNumber}
                onChange={handleChange}
                label={t('infoFields.serialNumber')}
                defaultValue={values.serialNumber || ''}
              />
              <FormikInputFieldWithLabel
                name={PlatformFieldName.measureStatus}
                onChange={handleChange}
                label={t('infoFields.measureStatus')}
                defaultValue={values.measureStatus || ''}
              />
              <FormikDatePickerWithLabel
                name={PlatformFieldName.effectiveDate}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                label={t('infoFields.effectiveDate')}
                defaultValue={values.effectiveDate || ''}
              />
            </StyledEditBlock>
          </Spin>
          <StyledButtons>
            <Button htmlType="submit" style={{ color: palette.white.w1, backgroundColor: palette.blue.b1 }}>
              {t('common.apply')}
            </Button>
            <Button onClick={handleReset} style={{ color: palette.white.w1, backgroundColor: palette.red.r3 }}>
              {t('common.cancel')}
            </Button>
          </StyledButtons>
        </StyledContentForm>
      )}
    </Formik>
  );
};
