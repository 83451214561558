import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from 'app-constants/routes';
import { AccessControl } from 'components/AccessControl';
import { PermissionDenied } from 'pages';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/user/selectors';
import { UserPermission } from '../store/user/types';

const UsersPage = lazy(() => import('pages/AdminManagement/Users'));
const WimsPage = lazy(() => import('pages/AdminManagement/Wims'));
const GroupsPage = lazy(() => import('pages/AdminManagement/Groups'));
const AdminHomePage = lazy(() => import('pages/AdminManagement/Home'));
const SettingsPage = lazy(() => import('pages/AdminManagement/Settings'));

export const AdminNavigationRouter: React.FC = () => {
  const user = useSelector(userSelectors.getUser);

  return (
    <Suspense fallback={<PermissionDenied />}>
      <Switch>
        <Route
          exact
          path={ROUTES.AdminHome}
          render={() => (
            <AccessControl acl={[UserPermission.WRITE]} permissions={user?.permissions} redirect>
              <AdminHomePage />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={ROUTES.AdminUsers}
          render={() => (
            <AccessControl acl={[UserPermission.WRITE]} permissions={user?.permissions} redirect>
              <UsersPage />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={ROUTES.AdminWims}
          render={() => (
            <AccessControl acl={[UserPermission.WRITE]} permissions={user?.permissions} redirect>
              <WimsPage />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={ROUTES.AdminGroups}
          render={() => (
            <AccessControl acl={[UserPermission.WRITE]} permissions={user?.permissions} redirect>
              <GroupsPage />
            </AccessControl>
          )}
        />
        <Route
          exact
          path={ROUTES.SystemSettings}
          render={() => (
            <AccessControl acl={[UserPermission.WRITE]} permissions={user?.permissions} redirect>
              <SettingsPage />
            </AccessControl>
          )}
        />
      </Switch>
    </Suspense>
  );
};
