import React, { useMemo } from 'react';
import { Tooltip } from 'antd';
import {
  WifiOutlined,
  ReloadOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CameraOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { batch, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import palette from 'palette';
import { INITIAL_TRANSITS_FILTER_VALUES } from 'store/wim/filter/transits';
import { stringifyUrlParams } from 'hooks/use-url-params';
import { getColorOfPercentValue, getColorOfWimConnection } from 'helpers/icon.formatter';
import { getUpdatedTime } from 'helpers/date.formatter';
import { getIntegerPercent } from 'helpers/percent.formatter';
import { wimActions } from 'store/wim/slices';
import { DEFAULT_RANGE_DAYS } from 'app-constants/defaults';
import { THREE_WEEKS } from 'app-constants/date';
import { ROUTES } from 'app-constants/routes';
import { NormalizedWimPlatform, NormalizedSummary } from 'store/wim/normalization';
import InfoText from 'elements/InfoText';
import WimErrorsByLanes from './WimErrorsByLanes';
import PlateRecognitionGraph from './PlateRecognitionGraph';
import {
  StyledAddress,
  StyledButtonsBlock,
  StyledInfoBlock,
  StyledLastUpdated,
  StyledPopupContent,
  StyledUpdateButton,
} from './styles';
import { HintTooltip } from '../../../../Transits/styles';

interface WimContentProps {
  state: NormalizedWimPlatform;
  summary: NormalizedSummary;
  route: string;
}

export const WimContent: React.FC<WimContentProps> = ({ state, summary, route }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const coordinates = useMemo(() => state.mapPosition.coordinates.join(', '), [state.mapPosition.coordinates]);

  const { minutes, seconds, lastTime } = getUpdatedTime(state.lastTransitDatetime);

  const totalWimErrors = useMemo(
    () =>
      summary.totalErrors.reduce((acc, wimError) => {
        if (wimError.error) {
          acc.push(getIntegerPercent(wimError.error));
        }

        return acc;
      }, [] as number[]),
    [summary]
  );

  const minWimError = useMemo(() => Math.min(...totalWimErrors), [totalWimErrors]);

  const maxWimError = useMemo(() => Math.max(...totalWimErrors), [totalWimErrors]);

  const formatViolation = useMemo(() => {
    if (Number.isSafeInteger(minWimError) && Number.isSafeInteger(maxWimError)) {
      return `${t('glossary.from')} ${minWimError}% ${t('glossary.until')} ${maxWimError}%`;
    }

    return undefined;
  }, [minWimError, maxWimError, t]);

  const handleUpdateClick = () =>
    batch(() => {
      dispatch(wimActions.platforms.requestPlatforms());
      dispatch(
        wimActions.platforms.requestPlatformSummary({
          wimUid: state.uid,
          start: dayjs()
            .subtract(DEFAULT_RANGE_DAYS - 1, 'days')
            .valueOf(),
          end: dayjs().valueOf(),
        })
      );
    });

  return (
    <StyledPopupContent>
      <StyledAddress>
        <Link to={{ pathname: route, state: { fromPage: { pathname: location.pathname, search: location.search } } }}>
          {state.address}
        </Link>
      </StyledAddress>
      <Link
        color={palette.blue.b3}
        to={{
          pathname: ROUTES.TransitsArchiveWim,
          search: stringifyUrlParams({
            sort: { key: 'datetime', value: 'descend' },
            filter: {
              ...INITIAL_TRANSITS_FILTER_VALUES,
              platformUids: [state.uid],
            },
          }),
        }}
      >
        {t('map.mapModal.transitsList')}
      </Link>{' '}
      <span>{t('map.mapModal.overArea')}</span>
      <StyledButtonsBlock>
        <div>
          <span>{t('common.connection')}</span>
          <Tooltip
            title={<HintTooltip>{t('map.mapModal.connectionValidityDetails', { period: 5 })}</HintTooltip>}
            color={palette.white.w1}
          >
            <WifiOutlined
              style={{
                color: getColorOfWimConnection(state.lastTransitDatetime),
              }}
            />
          </Tooltip>
        </div>
        <div>
          <span>{t('map.mapModal.measureValidity')}</span>
          <Tooltip
            title={<HintTooltip>{t('map.mapModal.measureValidityDetails')}</HintTooltip>}
            color={palette.white.w1}
          >
            {state.measureValidity && state.measureValidity >= 0.9 ? (
              <CheckCircleOutlined style={{ color: palette.green.g2 }} />
            ) : (
              <ExclamationCircleOutlined style={{ color: palette.orange.o2 }} />
            )}
          </Tooltip>
        </div>
        <div>
          <span>{t('map.mapModal.mediaValidity')}</span>
          <Tooltip title={<HintTooltip>{t('map.mapModal.mediaValidityDetails')}</HintTooltip>} color={palette.white.w1}>
            <CameraOutlined style={{ color: getColorOfPercentValue(state.mediaValidity) }} />
          </Tooltip>
        </div>
      </StyledButtonsBlock>
      <StyledInfoBlock>
        <InfoText label={t('infoFields.coordinates')} value={coordinates} />
        <InfoText label={t('infoFields.region')} value={state.region} />
        <InfoText label={t('infoFields.road')} value={state.road} />
        <InfoText label={t('infoFields.status')} value={state.status} />
        <InfoText label={t('infoFields.provider')} value={state.provider} />
        <InfoText label={t('infoFields.laneQuantity')} value={Object.keys(summary.lanes.byUid).length} />
        <InfoText label={t('map.mapModal.laneViolation', { days: DEFAULT_RANGE_DAYS })} value={formatViolation} />
        <PlateRecognitionGraph summary={summary} />
        <div>
          <StyledLastUpdated>
            <InfoText
              label={t('infoFields.lastUpdatedData')}
              value={
                minutes && seconds && lastTime
                  ? `${lastTime}
                (${t('glossary.minute', { minutes })} ${t('glossary.second', { seconds })} ${t('glossary.passed')})
                `
                  : t('common.noData')
              }
            />{' '}
            <StyledUpdateButton onClick={handleUpdateClick} onKeyDown={handleUpdateClick} role="button" tabIndex={-1}>
              <ReloadOutlined />
            </StyledUpdateButton>
          </StyledLastUpdated>
        </div>
      </StyledInfoBlock>
    </StyledPopupContent>
  );
};
