import React, { FC } from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { authActions } from 'store/auth/slice';
import { StyledBottomBlock } from './styles';
import { User } from '../../../../../../store/user/types';

interface BottomActionsProps {
  state: User;
}
export const BottomActions: FC<BottomActionsProps> = ({ state }) => {
  const dispatch = useDispatch();

  const onLogOut = () => {
    dispatch(authActions.triggerPasswordReset({ email: state.email }));
    dispatch(authActions.requestLogout());
  };

  return (
    <StyledBottomBlock>
      <Button type="text" danger onClick={onLogOut}>
        Скинути пароль
      </Button>
    </StyledBottomBlock>
  );
};
