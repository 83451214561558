export const convertWebpToJpeg = async (base64Value: string): Promise<string> => {
  const loadImage = (url: string) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.addEventListener('load', () => {
        resolve(image);
      });
      image.src = url;
    });
  };

  const jpegImage = await loadImage(base64Value).then((image: unknown) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.height = (image as HTMLImageElement).naturalHeight;
    canvas.width = (image as HTMLImageElement).naturalWidth;
    if (context) {
      context.drawImage(image as HTMLImageElement, 0, 0);
    }
    return canvas.toDataURL('image/jpeg');
  });

  return jpegImage;
};
