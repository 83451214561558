import React, { useEffect, useState } from 'react';
import { ExternalNotification, MediaNode, MediaNodes } from '../../store/system/types';
import { StyledContent, StyledSummary } from '../AdminManagement/Groups/styles';
import { ListWithSummary, ModalWrapper } from '../../components';
import { useAppDispatch, useAppSelector } from '../../hooks/store-hooks';
import Row from './NotificationRow';
import { useUrlParams } from '../../hooks/use-url-params';
import { useNotificationsState } from './hooks/use-nodes-state';
import { useNotificationsPageState } from './hooks/use-notifications-page-state';
import { useGetNotifications } from './hooks/use-get-notifications';
import { NotificationSummary } from './NotificationSummary';

const ExternalNotifications: React.FC = () => {
  const dispatch = useAppDispatch();
  const { queryUrlParams, onPushUrlParams } = useUrlParams({});
  const { externalNotifications, isLoading, isLoaded } = useNotificationsState();
  const { notificationsPageState, handleUpdatePageState } = useNotificationsPageState(
    externalNotifications ? externalNotifications.notifications : null,
    queryUrlParams
  );

  useGetNotifications();

  const onSelectedNode = (notification: ExternalNotification) => {
    handleUpdatePageState({ notification });
    onPushUrlParams({ notification: notification.id });
  };

  const renderItem = (item: ExternalNotification) => (
    <Row
      item={item}
      onItemClick={onSelectedNode}
      selectedItem={notificationsPageState.notification}
      isListLoaded={isLoaded}
    />
  );

  return (
    <StyledContent>
      <StyledSummary>
        <ListWithSummary
          list={externalNotifications ? externalNotifications.notifications : null}
          isSelectedItemLoading={isLoading}
          isListLoading={isLoading}
          renderItem={renderItem}
          rowKey={(item) => `list-item-${item.id}`}
        >
          {notificationsPageState.notification && <NotificationSummary state={notificationsPageState.notification} />}
        </ListWithSummary>
      </StyledSummary>
    </StyledContent>
  );
};

export default ExternalNotifications;
