import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { systemActions } from 'store/system/slices';

export const useGetConnectors = (): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(systemActions.connectors.requestConnectors());
  }, []);
};
