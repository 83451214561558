import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';

import { ModalWrapper } from 'components';
import SingleDatePicker from 'components/SingleDatePicker';

import { useUrlParams } from 'hooks/use-url-params';
import { BarChartViolations } from 'components/BarChart';
import { useAppDispatch, useAppSelector } from 'hooks/store-hooks';
import { ViolationsLegend } from 'components/BarChart/Legends';
import { requestWimTransitSummary } from 'store/platforms/wims/wim/transits/summary/slice';
import palette from 'palette';
import { WimPlatformUid } from 'store/wim/types';
import { ERROR, VehiclesTypes } from '../constants/violations';
import DetailTable from './DetailTable';
import {
  StyledDetailBarChartWrapper,
  StyledDetailTitle,
  StyledDetailViolationsHeader,
  StyledDetailWrapper,
} from './styles';
import { getSummaryChartData } from '../helpers/get-summary-chart-data';

interface SummaryDetailProps {
  isActive: boolean;
  closeDetail: () => void;
  wimUid?: WimPlatformUid;
}

type ParsedDataType = {
  [key: string]: number | string;
};

const SummaryDetail: React.FC<SummaryDetailProps> = ({ isActive, wimUid, closeDetail }) => {
  const { t } = useTranslation();
  const [currentDate, setCurrentDate] = useState(dayjs().startOf('day'));
  const [currentSummary, setCurrentSummary] = useState<ParsedDataType[]>([]);
  const { summaryData, isWimTransitSummaryLoading } = useAppSelector((state) => state.platforms.wims.transits.summary);
  const { queryUrlParams } = useUrlParams({});
  const platforms = useAppSelector((platformsState) => platformsState.wim.platforms.platforms);
  const dispatch = useAppDispatch();

  const currentPlatform = platforms?.find((value) => value.uid === wimUid);
  const currentLane = currentPlatform?.lanes?.find((value) => value.uid === queryUrlParams?.summaryModal?.laneUid);

  useEffect(() => {
    if (summaryData && summaryData.length) {
      setCurrentSummary(getSummaryChartData(summaryData));
    }
  }, [summaryData]);

  useEffect(() => {
    if (queryUrlParams?.summaryModal?.date) {
      setCurrentDate(dayjs(parseInt(queryUrlParams.summaryModal.date, 10)));
    }
  }, [queryUrlParams?.summaryModal?.date]);

  const tableSummary = summaryData?.map((summary) => ({ ...summary, key: summary.datetime })) || [];

  useEffect(() => {
    if (queryUrlParams?.summaryModal?.laneUid && wimUid) {
      dispatch(
        requestWimTransitSummary({ date: currentDate.valueOf(), wimUid, laneUid: queryUrlParams.summaryModal.laneUid })
      );
    }
  }, [currentDate, wimUid]);

  return (
    <ModalWrapper
      isVisible={isActive}
      onClose={closeDetail}
      title={
        currentLane && currentPlatform
          ? `${currentLane.direction},  ${currentLane.laneDescription}  (${currentPlatform.wim})`
          : ''
      }
    >
      <Spin spinning={!summaryData || !currentSummary}>
        <StyledDetailViolationsHeader className="detail-header">
          <SingleDatePicker currentDate={currentDate} setDateHandler={setCurrentDate} />
        </StyledDetailViolationsHeader>
        <StyledDetailWrapper>
          <StyledDetailTitle>{t('violationsPage.detail.coutntVehicle')}</StyledDetailTitle>
          <StyledDetailBarChartWrapper>
            <BarChartViolations
              data={currentSummary}
              dataKeys={[
                { fill: palette.purple.p2, dataKey: VehiclesTypes.truck, key: VehiclesTypes.truck, stackId: '1' },
                {
                  fill: palette.orange.o2,
                  dataKey: VehiclesTypes.empty_truck,
                  key: VehiclesTypes.empty_truck,
                  stackId: '1',
                },
                { fill: palette.green.g5, dataKey: VehiclesTypes.car, key: VehiclesTypes.car, stackId: '1' },
              ]}
              dataKeyX={ERROR}
              tooltip={false}
            />
            <ViolationsLegend
              payload={[
                { fill: palette.purple.p2, dataKey: VehiclesTypes.truck },
                { fill: palette.orange.o2, dataKey: VehiclesTypes.empty_truck },
                { fill: palette.green.g5, dataKey: VehiclesTypes.car },
              ]}
              width="30px"
              height="20px"
            />
          </StyledDetailBarChartWrapper>
          <StyledDetailTitle>
            {t('violationsPage.detail.passDetectors')}({summaryData?.length || 0})
          </StyledDetailTitle>
        </StyledDetailWrapper>
        <DetailTable data={tableSummary} isLoading={isWimTransitSummaryLoading} />
      </Spin>
    </ModalWrapper>
  );
};

export default SummaryDetail;
