import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { wimActions } from 'store/wim/slices';

export const useGetMonitoringMetrics = (): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(wimActions.monitoring.requestWimMonitoringMetrics());
  }, [dispatch]);
};
