import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { getMyUser } from 'api/users';
import { ROUTES } from 'app-constants/routes';
import { normalizeUserEmptyValues } from 'store/admin/normalization';
import { authActions } from 'store/auth/slice';
import { authSelectors } from 'store/auth/selectors';
import { SagaContext } from 'types/saga';
import { userActions } from './slice';
import { User } from './types';

export function* userRequestSaga(context: SagaContext) {
  const isAuthenticated = yield select(authSelectors.isAuthenticated);

  if (context.routerHistory.location.pathname !== ROUTES.Profile) {
    yield put(userActions.startUserLoading());
  }

  try {
    const response: AxiosResponse<User> = yield call(getMyUser, {});
    if (response) {
      const userWithNonNullValues: User = yield call(normalizeUserEmptyValues, response.data);
      yield put(userActions.getUserSuccess(userWithNonNullValues));

      if (!isAuthenticated) {
        yield put(authActions.setIsAuthenticated(true));
      }
    }
  } catch (error) {
    yield put(userActions.getUserError(error.message));
    yield put(authActions.setIsAuthenticated(false));
  } finally {
    yield put(userActions.finishUserLoading());
  }
}

export default function* userRequestWatchSaga(context: SagaContext) {
  yield takeLatest(userActions.requestUser, () => userRequestSaga(context));
}
