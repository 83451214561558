import { Select } from 'antd';
import palette from 'palette';
import styled from 'styled-components/macro';

export const StyledSelect = styled(Select)`
  min-width: 200px;
  margin: 0 8px 0 0;
  height: 30px;
  font-size: 17px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

export const StyledFilter = styled.div`
  display: flex;
  width: 100%;
  background: ${palette.white.w1};
  padding: 20px;
`;
