import { API_URLS } from 'app-constants/api';
import { HTTPService } from './http.service';

export class SecondaryHTTPService extends HTTPService {
  constructor() {
    super(API_URLS.secondary);
  }
}

const secondaryHttpService = new SecondaryHTTPService();

export default secondaryHttpService;
