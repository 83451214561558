import { put, takeEvery } from 'redux-saga/effects';
import makeRequestSaga from 'store/common';
import { getViolationOverloadStatistic } from 'api/statistics';
import { SagaPayload } from 'types';
import { GetStatisticQueryParamsType } from '../types';
import {
  getViolationOverweightStatisticError,
  getViolationOverweightStatisticSuccess,
  requestViolationOverweightStatistic,
  setIsLoading,
} from './slice';

export function* violationOverweightStatisticRequestSaga({ payload }: SagaPayload<GetStatisticQueryParamsType>) {
  yield put(setIsLoading(true));
  yield makeRequestSaga(
    getViolationOverweightStatisticSuccess,
    getViolationOverweightStatisticError,
    getViolationOverloadStatistic,
    payload
  );
}

export function* violationOverwightStatisticRequestWatchSaga() {
  yield takeEvery(requestViolationOverweightStatistic, violationOverweightStatisticRequestSaga);
}
