import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Space, Spin, Tabs, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import dayjs from 'dayjs';
import { useHistory, useParams } from 'react-router-dom';

import { HeaderConfigContext } from 'app/App';
import palette from 'palette';
import { DefaultTableContainer, HeaderExportExtraWithMap } from 'components';
import wimSelectors from 'store/wim/selectors';
import { wimActions } from 'store/wim/slices';
import { useAppDispatch, useAppSelector } from 'hooks/store-hooks';
import { stringifyUrlParams, useUrlParams } from 'hooks/use-url-params';
import { separateByThousands } from 'helpers/number.formatter';
import { DATE_FORMAT } from 'app-constants/date';
import { ROUTES } from 'app-constants/routes';
import correctMeasure from 'assets/icons/status/correct-measure.svg';
import errorMeasure from 'assets/icons/status/error-measure.svg';
import { getVehicleClassIconById } from 'helpers/get-vehicle-class-icon';
import { getVehicleTypeDescription } from 'helpers/get-vehicle-description';
import { plateFormatter } from 'helpers/label.formatter';
import { violationsTypeTitle } from 'app-constants/violations';
import { WimMeasurementFlagType, WimViolationType, PhotoType } from 'store/wim/types/transits';
import { measurementFlagsTypeTitle } from 'app-constants/measurement-flags';
import { placeholderImg } from 'assets';

import { AxlesTableRow, getTransitAxlesTableColumns, getTransitSizesTableColumns, SizesTableRow } from './table-schema';
import { TransitInfoItem } from './components/TransitInfoItem';
import {
  GalleryContainer,
  InfoBarContainer,
  Tag,
  TagsContainer,
  TooltipContentContainer,
  TransitContainer,
  TransitDate,
  TransitInfoContainer,
  TransitTitle,
  TransitTitleContainer,
  TooltipTitleContainer,
  TransitInfoSection,
  Title,
  Text,
  TagsSection,
  StyledTabs,
  SpinContainer,
  StyledPercentText,
} from './styles';
import { PlatePhoto } from './components/PlatePhoto';
import { getAxleInfo } from '../helpers/get-axles-info';
import VehicleSchema from '../ArchiveTabs/components/VehicleSchema';
import { getVehicleSchema } from '../helpers/get-vehicle-schema';

interface HistoryLocationState {
  fromComparativeAnalysis?: boolean;
  pathname: string;
  queryUrlParams: string;
}

type TransitParams = {
  uid: string;
  ts: string;
};

export const Transit: React.FC = () => {
  const [fullLocationText, setFullLocationText] = useState<string>('');
  const [transitCoords, setTransitCoords] = useState<number[] | undefined>();
  const [goBackCounter, setGoBackCounter] = useState<number>(-1);
  const { setHeaderOnBackButtonState, setHeaderRenderer } = useContext(HeaderConfigContext);
  const platforms = useAppSelector(wimSelectors.platforms.getPlatforms);
  const transit = useAppSelector(wimSelectors.transits.getTransit);
  const transitPhotos = useAppSelector(wimSelectors.transits.getTransitPhotos);
  const isLoading = useAppSelector(wimSelectors.transits.isLoading);
  const isTransitPhotosLoading = useAppSelector(wimSelectors.transits.isTransitPhotosLoading);
  const history = useHistory<HistoryLocationState>();
  const dispatch = useAppDispatch();
  const { queryUrlParams } = useUrlParams({});
  const { t } = useTranslation();
  const { uid, ts } = useParams<TransitParams>();

  queryUrlParams.transitUid = uid;
  queryUrlParams.transitTs = ts;

  const { TabPane } = Tabs;

  const onBackButton = useCallback(() => {
    if (history.location.state?.fromComparativeAnalysis) {
      return history.push(ROUTES.ComparativeAnalysis);
    }
    return history.push({
      pathname: history.location?.state?.pathname,
      search: history.location?.state?.queryUrlParams,
    });
  }, [history]);

  const onSwitchTabs = (tabParam: Record<string, any>) => {
    history.push({ search: stringifyUrlParams({ ...tabParam }) });
    setGoBackCounter((prev) => prev - 1);
  };

  useEffect(() => {
    if (!transitCoords && transit) {
      setTransitCoords(platforms?.find((platform) => platform.uid === transit.platformUid)?.mapPosition.coordinates);
    }
    if (transit && transitCoords && platforms) {
      const platform = platforms.filter((wimPlatform) => wimPlatform.uid === transit.platformUid)[0];

      setFullLocationText(
        `${platform.address} / ${transit.platformName} / GPS ${transitCoords[0]}, ${transitCoords[1]}`
      );
    }
  }, [transit, transitCoords, platforms]);

  useEffect(() => {
    dispatch(wimActions.transits.clearTransitInfo());

    if (queryUrlParams.transitUid && queryUrlParams.transitTs) {
      dispatch(
        wimActions.transits.requestTransit({
          transitUid: queryUrlParams.transitUid,
          ts: queryUrlParams.transitTs,
        })
      );
    }

    return () => {
      dispatch(wimActions.transits.clearTransitInfo());
    };
  }, [dispatch, queryUrlParams.transitTs, queryUrlParams.transitUid]);

  useEffect(() => {
    setHeaderOnBackButtonState(() => () => onBackButton());

    return () => {
      setHeaderOnBackButtonState(undefined);
    };
  }, [goBackCounter, onBackButton, setHeaderOnBackButtonState]);

  useEffect(() => {
    if (transit) {
      transit.platformUid &&
        transit.uid &&
        setHeaderRenderer(() => (
          <HeaderExportExtraWithMap
            tooltipText={t('transitPage.documentPreview')}
            onMapButtonClick={() =>
              history.push({
                pathname: ROUTES.TransitsMap,
                search: stringifyUrlParams({
                  plate: transit.plate,
                  filter: {
                    start: dayjs(transit.datetime).startOf('day').valueOf(),
                    end: dayjs(transit.datetime).endOf('day').valueOf(),
                  },
                }),
              })
            }
            onExportButtonClick={() =>
              history.push({
                pathname: ROUTES.TransitDocument,
                search: stringifyUrlParams({ transitUid: transit.uid, ts: transit.datetime }),
                state: history.location.state,
              })
            }
          />
        ));

      dispatch(wimActions.transits.requestTransitPhotos({ transitUid: transit.uid }));
    }

    return () => setHeaderRenderer(null);
  }, [dispatch, history, setHeaderRenderer, t, transit]);

  if (isLoading || !transit) {
    return (
      <SpinContainer>
        <Spin spinning />
      </SpinContainer>
    );
  }

  const sizesTableData = [
    { param: t('transitPage.sizesTable.items.length'), ...transit.size.length },
    { param: t('transitPage.sizesTable.items.width'), ...transit.size.width },
    { param: t('transitPage.sizesTable.items.height'), ...transit.size.height },
    { param: t('transitPage.sizesTable.items.weight'), ...transit.size.weight },
  ];

  const axlesColumns = Object.values(getTransitAxlesTableColumns({ withErrors: true }));
  const sizesColumns = Object.values(getTransitSizesTableColumns({ withErrors: true }));

  const renderErrorPercent = (value: number) => {
    if (!value) {
      return null;
    }
    const textSign = value > 0 ? '+' : '';
    const textValue = (value * 100).toFixed(2);

    return <StyledPercentText value={value}>{textSign + textValue}%</StyledPercentText>;
  };

  return (
    <TransitContainer>
      <TransitTitleContainer>
        <TransitTitle>{`${t('transitPage.transit')}(${transit.id}) ${t(
          'glossary.over'
        )} ${fullLocationText}`}</TransitTitle>
        <TransitDate>{dayjs(transit.datetime).format(DATE_FORMAT.TABLE_FORMAT)}</TransitDate>
      </TransitTitleContainer>

      <div>
        <TransitInfoContainer>
          <TransitInfoSection>
            <TransitInfoItem title={t('transitPage.speed')} value={transit.speed} />
            <TransitInfoItem title={t('transitPage.direction')} value={transit.direction} />
            <TransitInfoItem title={t('transitPage.wimName')} value={transit.wimName} />
            <TransitInfoItem
              title={t('transitPage.coords')}
              value={
                (transitCoords && `${transitCoords[0].toFixed(3)}, ${transitCoords[1].toFixed(3)}`) ||
                t('common.notRecorder')
              }
            />
          </TransitInfoSection>

          <div>
            <TransitInfoItem
              title={t('transitPage.country')}
              value={transit.countryPlate.length ? transit.countryPlate : t('glossary.not')}
            />
            <TransitInfoItem title={t('transitPage.roadTemprature')} value={transit.roadTemperature} />
          </div>
        </TransitInfoContainer>

        <TagsSection>
          <Title>{t('transitPage.offenceTypes')}:</Title>
          {transit.violations.length ? (
            <TagsContainer>
              {transit.violations
                .filter((violation) => violation.type !== '')
                .map((violation) => {
                  return (
                    <Tag isError key={violation.type}>
                      {violationsTypeTitle[violation.type as WimViolationType] ?? violation.type}
                    </Tag>
                  );
                })}
            </TagsContainer>
          ) : (
            <Text>{t('glossary.not')}</Text>
          )}
        </TagsSection>

        <TagsSection>
          <Title>{t('transitPage.vendorErrors')}:</Title>
          {transit.flags.length ? (
            <TagsContainer>
              {transit.flags
                .filter((flag) => flag.type !== '')
                .map((flag) => {
                  return (
                    <Tag key={flag.type}>
                      {measurementFlagsTypeTitle[flag.type as WimMeasurementFlagType] ?? flag.type}
                    </Tag>
                  );
                })}
            </TagsContainer>
          ) : (
            <Text>{t('glossary.not')}</Text>
          )}
        </TagsSection>
      </div>

      <InfoBarContainer>
        <TransitInfoItem
          isLeftAlign={false}
          centerValue
          isError={!!transit.violations.find((violation) => violation.type === WimViolationType.OVERLOAD)}
          title={t('transitPage.totalWeight')}
          value={`${separateByThousands(+transit?.weight?.toFixed(2))} ${t('glossary.measureKilogram')}`}
        />
        <TransitInfoItem
          title={t('transitPage.withError')}
          details={renderErrorPercent(transit?.weightErrorPercent)}
          value={`${separateByThousands(+transit?.weightWithError?.toFixed(2))} ${t('glossary.measureKilogram')}`}
        />
        <Tooltip
          title={
            <TooltipTitleContainer>
              {transit.flags.length > 0 ? t('transitPage.incorrectMeasurement') : t('transitPage.correctMeasurement')}
            </TooltipTitleContainer>
          }
          color={palette.white.w1}
        >
          <TooltipContentContainer>
            <Title>{t('transitPage.status')}: </Title>
            <img src={transit.flags.length > 0 ? errorMeasure : correctMeasure} alt="error status" />
          </TooltipContentContainer>
        </Tooltip>

        <TransitInfoItem
          title={
            <>
              <span>{transit.vehicleClass}. </span>
              <img
                src={getVehicleClassIconById(transit?.vehicleClass)}
                alt={transit?.vehicleClass.toString()}
                height={25}
              />
            </>
          }
          value={getVehicleTypeDescription(transit?.vehicleClass)}
        />
      </InfoBarContainer>

      <InfoBarContainer>
        <PlatePhoto
          title={t('transitPage.plateFront')}
          isPlateRecognized
          plate={plateFormatter(transit.plate)}
          photoSrc={transitPhotos.PLATE}
        />
        <PlatePhoto
          title={t('transitPage.plateBack')}
          isPlateRecognized
          plate={plateFormatter(transit.plateBack)}
          photoSrc={transitPhotos.PLATER}
        />
      </InfoBarContainer>

      <StyledTabs defaultActiveKey="schema" onChange={(val) => onSwitchTabs({ tab: val })}>
        <TabPane tab={t('transitPage.schema')} key="schema">
          <VehicleSchema {...getVehicleSchema(transit)} />
        </TabPane>
        <TabPane tab={t('transitPage.table')} key="table">
          <Space direction="vertical">
            <DefaultTableContainer<AxlesTableRow>
              dataSource={getAxleInfo(transit.axles, transit.axlesGroups)}
              columns={axlesColumns}
              rowClassName={(record) => {
                return record.overweightPercent && record.overweightPercent > 0 ? 'ant-error-row' : '';
              }}
            />
            <DefaultTableContainer<SizesTableRow>
              dataSource={sizesTableData}
              columns={sizesColumns}
              rowClassName={(record) => {
                return record.oversize > 0 ? 'ant-error-row' : '';
              }}
            />
          </Space>
        </TabPane>
      </StyledTabs>

      <GalleryContainer>
        {isTransitPhotosLoading ? (
          <SpinContainer>
            <Spin />
          </SpinContainer>
        ) : (
          <ImageGallery
            showIndex
            showPlayButton={false}
            items={Object.keys(PhotoType).map((photoKey) => ({
              original: transitPhotos[photoKey as PhotoType] || placeholderImg,
              fullscreen: transitPhotos[photoKey as PhotoType],
              thumbnail: transitPhotos[photoKey as PhotoType],
              description: t(`transitPage.pictures.${photoKey as PhotoType}` as const),
            }))}
          />
        )}
      </GalleryContainer>
    </TransitContainer>
  );
};
