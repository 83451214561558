import React, { FC } from 'react';
import dayjs from 'dayjs';

import { Skeleton, Typography } from 'antd';
import { StyledListRow, StyledIconsBlock } from './styles';
import { ExternalNotification } from '../../../store/system/types';
import { DATE_FORMAT } from '../../../app-constants/date';

interface NotificationListRowProps {
  item: ExternalNotification;
  selectedItem: ExternalNotification | null;
  isListLoaded: boolean | null;
  onItemClick: (item: ExternalNotification) => void;
}

const Row: FC<NotificationListRowProps> = ({ item, onItemClick, selectedItem, isListLoaded }) => {
  const { Text } = Typography;

  return (
    <StyledListRow $isSelected={item.id === selectedItem?.id} onClick={() => onItemClick(item)}>
      <Skeleton loading={!isListLoaded} active>
        <Text strong>{item.group}</Text>
        <Text>{dayjs(item.timestamp).format(DATE_FORMAT.TABLE_FORMAT)}</Text>
        <StyledIconsBlock>
          <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" />
          </svg>
        </StyledIconsBlock>
      </Skeleton>
    </StyledListRow>
  );
};

export default Row;
