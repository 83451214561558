import { User } from 'store/user/types';
import { UsersListSort } from './types';

export const usersListSorters = {
  [UsersListSort.default]: (a: User, b: User) => {
    if (a.fullName && b.fullName) {
      const nameA = a.fullName.toUpperCase();
      const nameB = b.fullName.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    }
    return 0;
  },
};
