import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ROUTES } from 'app-constants/routes';
import useGetPlatforms from 'pages/Wim/hooks/use-get-platforms';
import { stringifyUrlParams, useUrlParams } from 'hooks/use-url-params';
import OnlineTabs from './OnlineTabs';
import ArchiveTabs from './ArchiveTabs';
import IntensityTraffic from './IntensityTraffic';
import { Transit } from './Transit';
import { TransitDocument } from './TransitDocument';

import TransitsMap from './TransitsMap';
import { StyledContainer } from './styles';

// TODO: Refactor/discuss approach to working with tabs
const TransitsPage: React.FC = () => {
  useGetPlatforms();
  const { queryUrlParams } = useUrlParams({});

  return (
    <StyledContainer>
      <Switch>
        <Redirect
          exact
          from={ROUTES.TransitsOnline}
          to={{
            pathname: ROUTES.TransitsOnlineWim,
            search: stringifyUrlParams({ sort: { key: 'datetime', value: 'descend' } }),
          }}
        />
        <Redirect
          exact
          from={ROUTES.TransitsArchive}
          to={{
            pathname: ROUTES.TransitsArchiveWim,
            search: stringifyUrlParams({ ...queryUrlParams, sort: { key: 'datetime', value: 'descend' } }),
          }}
        />
        <Route path={`${ROUTES.TransitsOnline}/:tabKey`} component={OnlineTabs} />
        <Route path={`${ROUTES.TransitsArchive}/:tabKey`} component={ArchiveTabs} />
        <Route path={ROUTES.TransitsIntensityTraffic} component={IntensityTraffic} />
        <Route path={`${ROUTES.Transit}/:ts/:uid`} component={Transit} />
        <Route path={ROUTES.TransitDocument} component={TransitDocument} />
        <Route path={ROUTES.TransitsMap} component={TransitsMap} />
      </Switch>
    </StyledContainer>
  );
};

export default TransitsPage;
