const palette = {
  blue: {
    b1: '#1890FF',
    b2: '#112A45',
    b3: '#0091FF',
    b4: '#111D2C',
    b5: '#8cc8ff',
    b6: '#8fe9f5',
    b7: '#1666de',
    b8: '#2196f3',
    b9: '#13C2C2',
  },
  green: {
    g1: '#26B46B',
    g2: '#49AA19',
    g3: '#58b772',
    g4: '#26B46B',
    g5: '#7eec82',
    g6: '#52C41A',
    g7: '#2FC25B',
    g8: '#00AB84',
    g9: '#63a000',
  },
  red: {
    r1: '#FF0000',
    r2: '#de614a',
    r3: '#FF4D4F',
    r4: '#F37370',
    r5: '#EA0000',
    r6: '#FE5659',
  },
  black: {
    b1: '#000000',
    b2: '#262626',
    b3: 'rgba(0, 0, 0, 0.65)',
    b4: 'rgba(0, 0, 0, 0.85)',
    b5: 'rgba(0,0,0,0.45)',
  },
  white: {
    w1: '#ffffff',
  },
  grey: {
    g1: '#b0b0b0',
    g2: '#E9E9E9',
    g3: '#E8E8E8',
    g4: 'rgba(0, 0, 0, 0.02)',
    g5: '#d9d9d9',
    g6: '#c2c2c2',
    g7: '#7f7f7f',
    g8: '#efefef',
    g9: '#7e7e7e',
    g10: '#666',
    g11: '#f0f0f0',
  },
  orange: {
    o1: '#F3956A',
    o2: '#ffc069',
    o3: '#ff8f00',
  },
  purple: {
    p1: '#8543E0',
    p2: '#bb92ff',
  },
  yellow: {
    y1: '#fed400',
  },
};

export default palette;
