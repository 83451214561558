import React, { useEffect, useState } from 'react';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ROUTES } from 'app-constants/routes';
import { useAppSelector } from 'hooks/store-hooks';
import { userSelectors } from 'store/user/selectors';
import { AccessControl } from 'components/AccessControl';
import { User, UserPermission } from 'store/user/types';

import { StyledSider } from './styles';
import { MainNavMenu } from './components/MainNavMenu';
import { AdminNavMenu } from './components/AdminNavMenu';

export interface NavMenuProps {
  collapsed: boolean;
  user: User;
  selectedKeys: string[];
  openKeys: string[];
  onOpenChange: (openKeys: string[]) => void;
}

const rootSubmenuKeys = [ROUTES.Wim, ROUTES.Transits, ROUTES.Detectors];

function getSelectedMenuItem(path: string) {
  let selectedKeys = path;

  if (path === ROUTES.TransitsArchiveWim) {
    selectedKeys = ROUTES.TransitsArchive;
  }

  if (path === ROUTES.TransitsOnlineWim) {
    selectedKeys = ROUTES.TransitsOnline;
  }

  return [selectedKeys];
}

function getSelectedSubMenu(path: string) {
  const domain = path.substring(0, path.indexOf('/', 1));

  return [domain];
}

const NavBar: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [openKeys, setOpenKeys] = useState(getSelectedSubMenu(pathname));
  const isAdminPanelOn = pathname.startsWith(ROUTES.AdminHome);
  const user = useAppSelector(userSelectors.getUser);

  useEffect(() => {
    setOpenKeys(getSelectedSubMenu(pathname));
  }, [pathname]);

  const onCollapse = (isCollapsed: boolean) => {
    setCollapsed(isCollapsed);
  };

  const onOpenChange = (keys: React.ReactText[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key as string) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey as ROUTES) === -1) {
      setOpenKeys(keys as string[]);
    } else {
      latestOpenKey ? setOpenKeys([latestOpenKey as string]) : setOpenKeys([]);
    }
  };

  const customTrigger = collapsed ? (
    <div className="trigger-wrapper folded">
      <MenuUnfoldOutlined />
      <span className="trigger-title folded-title">{t('navBar.triggerTitle')}</span>
    </div>
  ) : (
    <div className="trigger-wrapper">
      <MenuFoldOutlined />
      <span className="trigger-title">{t('navBar.triggerTitle')}</span>
    </div>
  );

  if (!user) {
    return null;
  }

  const navMenuProps = {
    user,
    collapsed,
    onOpenChange,
    openKeys,
    selectedKeys: getSelectedMenuItem(pathname),
  };

  return (
    <StyledSider collapsible collapsed={collapsed} onCollapse={onCollapse} trigger={customTrigger} width={224}>
      {isAdminPanelOn ? (
        <AccessControl acl={[UserPermission.WRITE]} permissions={user.permissions} redirect>
          <AdminNavMenu {...navMenuProps} />
        </AccessControl>
      ) : (
        <MainNavMenu {...navMenuProps} />
      )}
    </StyledSider>
  );
};

export default NavBar;
