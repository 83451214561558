import { ActiveWimPlatformDetail, ActiveWimPlatformDetails } from 'store/wim/types';

export interface WimPlatformLane {
  [wimUid: string]: { value: string | null; name: string };
}

export function getWimPlatformLanes(
  wimPlatformDetails: ActiveWimPlatformDetails,
  propertyKey: keyof ActiveWimPlatformDetail
): WimPlatformLane[] {
  return Object.keys(wimPlatformDetails).map((wimUid) => ({
    [wimUid]: {
      value: wimPlatformDetails[wimUid][propertyKey],
      name: wimPlatformDetails[wimUid].name,
    },
  }));
}
