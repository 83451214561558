import React, { FC, useState } from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import L from 'leaflet';

interface CustomMarkerProps {
  zIndexOffset: number;
  icon: L.Icon<L.IconOptions>;
  position: L.LatLngExpression;
  name: string;
  onClick: (wimName: string) => void;
}

export const CustomMarker: FC<CustomMarkerProps> = ({ zIndexOffset, icon, position, name, onClick }) => {
  const [showWimName, setShowWimName] = useState(false);

  return (
    <Marker
      zIndexOffset={zIndexOffset}
      icon={icon}
      eventHandlers={{
        mouseover: () => setShowWimName(true),
        mouseout: () => setShowWimName(false),
        click: () => onClick(name),
      }}
      position={position}
    >
      {showWimName && (
        <Tooltip direction="top" permanent>
          {name}
        </Tooltip>
      )}
    </Marker>
  );
};
