import React from 'react';
import { Result, Button } from 'antd';

import 'antd/dist/antd.css';
import { useTranslation } from 'react-i18next';

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Result
      status="404"
      title="404"
      subTitle={t('notFoundPage.description')}
      extra={<Button type="primary">Back Home</Button>}
    />
  );
};
export default NotFoundPage;
