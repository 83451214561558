import { DataType } from 'components/BarChart/BarChartViolations';
import { SummaryError } from 'store/wim/types';
import { SummaryViolation } from 'store/wim/types/platforms';

type ItemType = SummaryError | SummaryViolation | DataType;

export const getMinMax = (data: ItemType[], dataKeyX: string) => {
  const result = data.reduce<number[]>((resultItem, item: ItemType) => {
    const getNumbers = Object.keys(item).reduce<number[]>((accum, key) => {
      if (key !== dataKeyX) {
        accum.push(Number(item[key as keyof ItemType]));
      }

      return accum;
    }, []);

    resultItem.push(...getNumbers);

    return resultItem;
  }, []);

  let min = Math.min(...result);
  let max = Math.max(...result);

  if (min > 0) {
    min = 0;
  } else {
    if (Math.abs(min) > Math.abs(max)) {
      max = Math.abs(min);
    }

    min = -Math.abs(max);
  }

  return [min, max];
};
