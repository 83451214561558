import { ColumnsType, ColumnType } from 'antd/lib/table';
import i18n from 'i18n';
import dayjs from 'dayjs';
import { UserLog, UserLogList } from '../../types';
import { UserLogActivity } from '../../../../../store/user/types';

type LogInfoDataSource = {
  type: string;
  value: string | number;
};

type LogInfoColumn = {
  type: ColumnType<LogInfoDataSource>;
  value: ColumnType<LogInfoDataSource>;
};

export const logInfoDataSource = (log: UserLogActivity[]): UserLogList[] => {
  return log.map((item) => {
    return { ...item, log: item.details, timestamp: dayjs(item.createDate).format('DD.MM.YYYY / HH:mm:ss') };
  });
};

export const logsColumns: ColumnsType<UserLogList> = [
  {
    title: i18n.t('user.logs.date'),
    key: 'timestamp',
    dataIndex: 'timestamp',
  },
  {
    title: i18n.t('user.logs.action'),
    key: 'action',
    dataIndex: 'action',
  },
  {
    title: i18n.t('user.logs.entity'),
    key: 'entity',
    dataIndex: 'entity',
  },
  {
    title: i18n.t('user.logs.log'),
    key: 'log',
    dataIndex: 'log',
  },
];
