import React from 'react';

import { BarChartPlateRecognitions } from 'components/BarChart';
import { PlateRecognitionPayload } from 'components/BarChart/BarChartPlateRecognitions';

interface PlateRecognitionProps {
  data: PlateRecognitionPayload[];
  recognizedKey: string;
  unrecognizedKey: string;
}

const PlateRecognitionWrapper: React.FC<PlateRecognitionProps> = ({ data, recognizedKey, unrecognizedKey }) => (
  <BarChartPlateRecognitions
    width={400}
    height={180}
    data={data || []}
    recognizedKey={recognizedKey}
    unrecognizedKey={unrecognizedKey}
    isTooltip
  />
);

export default PlateRecognitionWrapper;
