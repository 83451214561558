import { API_URLS } from 'app-constants/api';
import { CreateGroup, UpdateGroup } from 'store/admin/groups/types';
import { ApiRequest } from 'types';
import { PostGroupBindings } from 'store/admin/platforms/wimGroups/types';
import secondaryHttpService from '../services/secondary-http.service';

export const createGroup =
  (group: CreateGroup): ApiRequest =>
  () =>
    secondaryHttpService.post(API_URLS.deviceGroup(), group);

export const getGroups: ApiRequest = () => secondaryHttpService.get(API_URLS.deviceGroups(), {});

export const updateGroup =
  (group: UpdateGroup): ApiRequest =>
  () => {
    const { id, ...copyUpdatedGroup } = group;
    return secondaryHttpService.put(API_URLS.deviceGroup(), { groupId: id, ...copyUpdatedGroup });
  };

export const getWimGroupBindings: ApiRequest = () => secondaryHttpService.get(API_URLS.wimGroupBindings(), {});

export const postWimGroupBindings =
  (data: PostGroupBindings): ApiRequest =>
  () =>
    secondaryHttpService.put(API_URLS.wimGroupBinding(), { uid: data.wimUid, groupId: data.groupId });

export const deleteWimGroupBindings =
  (data: PostGroupBindings): ApiRequest =>
  () =>
    secondaryHttpService.delete(API_URLS.wimGroupBinding(), { params: { uid: data.wimUid, groupId: data.groupId } });
