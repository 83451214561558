import styled from 'styled-components/macro';

import palette from 'palette';
import SFUITextMedium from 'fonts/SFUIText-Medium.woff';

export const StyledTableWrapper = styled.div`
  .ant-pagination-total-text {
    flex-grow: 1;
    color: ${palette.black.b3};
  }

  .intensity-traffic tr td:nth-of-type(1) {
    width: 200px;
  }

  .ant-table-thead {
    font-weight: 500;
    color: ${palette.black.b4};
  }

  .ant-table-tbody {
    color: ${palette.black.b3};

    .ant-table-cell {
      &.clickable {
        cursor: pointer;
      }
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-item,
  .ant-pagination-options-size-changer {
    border-radius: 4px;
    font-family: 'SFUITextRegular';
    font-size: 12px;

    .ant-pagination-item-link {
      color: ${palette.black.b3};
      border-radius: 4px;
    }

    .ant-select-selector {
      color: ${palette.black.b3};
      border-radius: 4px;
    }

    a {
      color: ${palette.black.b3};
    }
  }

  .ant-pagination-item-active {
    background-color: #108ee9;
    border: 0px;

    a {
      color: white;
    }
  }

  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }

  .react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
  }

  .ant-error-row {
    background-color: rgb(253, 232, 228);

    :hover {
      color: ${palette.blue.b3};
    }
    > td {
      background-color: rgb(253, 232, 228);
    }
  }
`;

export const WrappedTableTitle = styled.div`
  @font-face {
    font-family: 'SFUITextMedium';
    font-size: 14px;
    src: url(${SFUITextMedium}) format('woff');
  }

  font-family: 'SFUITextMedium';
  color: ${palette.black.b4};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
`;
