import styled from 'styled-components/macro';
import { Card } from 'antd';

import palette from 'palette';

export const StyledTitle = styled.div`
  color: #1d39c4;
  text-decoration: underline;
  cursor: pointer;

  .result-error-icon {
    color: red;
    font-width: bold;
    padding-right: 10px;
  }
`;

export const StyledInfo = styled.div`
  padding-top: 20px;
  padding-left: 20px;
`;
