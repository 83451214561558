import styled from 'styled-components/macro';
import { Card, List } from 'antd';
import SFUITextMedium from 'fonts/SFUIText-Medium.woff';

export const StyledCardList = styled(Card)`
  margin-bottom: 10px;
  width: 100%;
`;

export const StyledList = styled(List)`
  margin: 0px;
  padding: 0px;
`;

export const StyledCardTitle = styled.span`
  @font-face {
    font-family: 'SFUITextMedium';
    font-size: 14px;
    src: url(${SFUITextMedium}) format('woff');
  }
  font-family: 'SFUITextMedium';
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
`;
