import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import makeRequestSaga from 'store/common';
import { getWimPlatforms, getWimPlatformsSummary, getWimPlatformSummary } from 'api/wim';
import { SagaPayload } from 'types';
import { AxiosResponse } from 'axios';
import {
  requestPlatforms,
  getPlatformsSuccess,
  getPlatformsError,
  requestPlatformSummary,
  getPlatformSummarySuccess,
  requestPlatformsSummary,
  getPlatformsSummarySuccess,
} from './slice';
import { GetWimPlatformsSummaryQueryParams, GetWimSummaryQueryParams, WimPlatformSummary } from '../types';

type ServerWimPlatformSummary = WimPlatformSummary & { laneUid: string };

export function* wimPlatformsRequestSaga() {
  yield makeRequestSaga(getPlatformsSuccess, getPlatformsError, getWimPlatforms);
}

export function* wimPlatformSummaryRequestSaga({ payload }: SagaPayload<GetWimSummaryQueryParams>) {
  try {
    const response: AxiosResponse<ServerWimPlatformSummary[]> = yield call(getWimPlatformSummary, payload);

    if (response) {
      const updatedWimPlatformSummary = response.data.reduce<WimPlatformSummary[]>((acc, currentSummary) => {
        const { laneUid, uid, ...restSummary } = currentSummary;

        acc.push({ ...restSummary, uid: laneUid || uid });
        return acc;
      }, []);

      yield put(getPlatformSummarySuccess(updatedWimPlatformSummary));
    }
  } catch (error) {
    yield put(getPlatformsError(error.message));
  }
}

function* wimPlatformsSummarySaga({ payload }: SagaPayload<GetWimPlatformsSummaryQueryParams>) {
  yield makeRequestSaga(getPlatformsSummarySuccess, getPlatformsError, getWimPlatformsSummary, payload);
}

export function* wimPlatformsSummaryRequestWatchSaga() {
  yield takeEvery(requestPlatformsSummary, wimPlatformsSummarySaga);
}

export function* wimPlatformsRequestWatchSaga() {
  yield takeEvery(requestPlatforms, wimPlatformsRequestSaga);
}

export function* wimPlatformSummaryRequestWatchSaga() {
  yield takeLatest(requestPlatformSummary, wimPlatformSummaryRequestSaga);
}
