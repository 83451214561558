export function prettifyWithSpaces(value: number): string {
  return value.toLocaleString().replace(/,/g, ' ');
}

export function separateByThousands(number: number, separator = ' '): string {
  return String(number).replace(/\d(?=(\d{3})+(\.|$))/g, `$&${separator}`);
}

export const getFormattedThousandsNumber = (dataNum: number | 'N/A' | undefined, delimiter: string) => {
  if (dataNum) {
    return separateByThousands(+dataNum, delimiter);
  }
  return dataNum;
};
