import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import palette from 'palette';
import { ButtonsContainer, FilterWrapper } from './styles';

interface FilterContainerProps {
  onFilterSubmitClick: () => void;
  onFilterClearClick: () => void;
}

export const FilterContainer: React.FC<FilterContainerProps> = ({
  children,
  onFilterSubmitClick,
  onFilterClearClick,
}) => {
  const { t } = useTranslation();

  return (
    <FilterWrapper noValidate>
      {children}
      <ButtonsContainer>
        <Button style={{ color: palette.white.w1, backgroundColor: palette.blue.b1 }} onClick={onFilterSubmitClick}>
          {t('common.apply')}
        </Button>
        <Button style={{ color: palette.white.w1, backgroundColor: palette.red.r3 }} onClick={onFilterClearClick}>
          {t('common.cancel')}
        </Button>
      </ButtonsContainer>
    </FilterWrapper>
  );
};
