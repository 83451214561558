import { takeLatest, fork, put } from 'redux-saga/effects';

import { SagaPayload } from 'types';
import { wimsStatusRequestSaga } from '../wimsStatus/saga';
import { totalTransitsRequestSaga } from '../totalTransits/saga';
import { trucksTransitsRequestSaga } from '../trucksTransits/saga';
import { overweightStatisticsRequestSaga } from '../overloadStatistics/saga';
import { transitsStatisticRequestSaga } from '../transitsStatistic/saga';
import { violationOverweightStatisticRequestSaga } from '../violationOverloadStatistic/saga';
import { vrpDetectStatisticRequestSaga } from '../vrpDetectStatistic/saga';
import { platformsListRequestSaga } from '../platformsList/saga';
import { intrudersListRequestSaga } from '../intrudersList/saga';
import { getAllStatisticsError, getAllStatisticsSuccess, requestAllStatistics } from './slice';

export function* allStatisticsSaga(payload: SagaPayload) {
  try {
    yield fork(wimsStatusRequestSaga, payload);
    yield fork(totalTransitsRequestSaga, payload);
    yield fork(trucksTransitsRequestSaga, payload);
    yield fork(overweightStatisticsRequestSaga, payload);
    yield fork(transitsStatisticRequestSaga, payload);
    yield fork(violationOverweightStatisticRequestSaga, payload);
    yield fork(vrpDetectStatisticRequestSaga, payload);
    yield fork(intrudersListRequestSaga, payload);
    yield fork(platformsListRequestSaga, payload);
    yield put(getAllStatisticsSuccess(payload));
  } catch (err) {
    yield put(getAllStatisticsError(err));
  }
}

export function* allStatisticsWatchSaga() {
  yield takeLatest(requestAllStatistics, allStatisticsSaga);
}
