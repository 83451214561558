import { Dayjs } from 'dayjs';
import { LabeledValue, SelectValue } from 'antd/lib/select';

import { OPTION_KEYS_FOR_SELECT } from 'app-constants/select-options';
import { useAppSelector } from 'hooks/store-hooks';
import { FilterReturnType } from 'hooks/use-filter';
import wimSelectors from 'store/wim/selectors';
import { WimPlatform } from 'store/wim/types';
import { TimePeriod } from 'types/date-period';

export enum WimMonitoringFilterParams {
  platformUids = 'platformUids',
  monitoringMetricId = 'monitoringMetricId',
  start = 'start',
  end = 'end',
}

export type WimMonitoringMetricFilterValues = TimePeriod & {
  platformUids: string[];
  monitoringMetricId: string;
};

type ReturnWimMonitoringFilterType = {
  state: Partial<WimMonitoringMetricFilterValues>;
  handleFilterChange: (field: keyof WimMonitoringMetricFilterValues, value: unknown) => void;
  platforms: WimPlatform[] | null;
  onPlatformNameChange: (name: SelectValue) => void;
  onRangePickerChange: (ranges: [Dayjs | null, Dayjs | null] | null) => void;
  onMetricChange: (value: SelectValue) => void;
};

export function useWimMonitoringFilter({
  state,
  handleFilterChange,
}: Pick<
  FilterReturnType<Partial<WimMonitoringMetricFilterValues>>,
  'handleFilterChange' | 'state'
>): ReturnWimMonitoringFilterType {
  const platforms = useAppSelector(wimSelectors.platforms.getPlatforms);

  const onPlatformNameChange = (value: SelectValue) => {
    if (value && Array.isArray(value)) {
      if (value.includes(OPTION_KEYS_FOR_SELECT.All as string & LabeledValue)) {
        handleFilterChange(
          WimMonitoringFilterParams.platformUids,
          platforms?.map((platform) => platform.uid)
        );
      } else {
        handleFilterChange(WimMonitoringFilterParams.platformUids, value);
      }
    }
  };

  const onMetricChange = (value: SelectValue) => {
    handleFilterChange(WimMonitoringFilterParams.monitoringMetricId, value);
  };

  const onRangePickerChange = (ranges: [Dayjs | null, Dayjs | null] | null) => {
    if (!ranges) {
      return;
    }
    const [start, end] = ranges;

    if (start && end) {
      handleFilterChange(WimMonitoringFilterParams.start, start.valueOf());
      handleFilterChange(WimMonitoringFilterParams.end, end.valueOf());
    }
  };

  return {
    state,
    handleFilterChange,
    platforms,
    onPlatformNameChange,
    onRangePickerChange,
    onMetricChange,
  };
}
