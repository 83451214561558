import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router';

import { useAppDispatch } from 'hooks/store-hooks';
import { Logo } from 'components';
import { authActions } from 'store/auth/slice';
import { authSelectors } from 'store/auth/selectors';
import { ConfirmationFormValues, LoginFormValues, PasswordResetFormValues } from './types';
import { AuthorizationWrapper, LoginPageWrapper } from './styles';
import Login from './Login';
import Confirmation from './Confirmation';
import PasswordReset from './PasswordReset';

type StateLocation = {
  from?: string;
};

interface AuthorizationPageProps {
  isAuthenticated: boolean;
}

const AuthorizationPage: React.FC<AuthorizationPageProps> = ({ isAuthenticated }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { state } = useLocation<StateLocation>();
  const [step, setStep] = useState<'login' | 'confirmation' | 'password_reset'>('login');
  const [email, setEmail] = useState<string>('');
  const isMFAActivationPage = useSelector(authSelectors.isMFAActivationPage);
  const isMFAConfirmationPage = useSelector(authSelectors.isMFAConfirmationPage);
  const isPasswordResetPage = useSelector(authSelectors.isPasswordResetPage);

  const onLoginSubmit = (submittedValues: LoginFormValues) => {
    setEmail(submittedValues.email);
    dispatch(authActions.requestLogin(submittedValues));
  };

  const onConfirmationSubmit = (submittedValues: ConfirmationFormValues) => {
    dispatch(authActions.postValidationCode({ email, code: submittedValues.code, activation: isMFAActivationPage }));
  };

  const onPasswordResetSubmit = (submittedValues: PasswordResetFormValues) => {
    dispatch(authActions.postPasswordReset({ email, password: submittedValues.password }));
  };

  useEffect(() => {
    if (isMFAActivationPage || isMFAConfirmationPage) {
      setStep('confirmation');
    }
    if (isPasswordResetPage) {
      setStep('password_reset');
    }
  }, [isMFAActivationPage, isMFAConfirmationPage, isPasswordResetPage]);

  if (isAuthenticated) {
    return <Redirect to={state?.from || '/'} />;
  }

  return (
    <LoginPageWrapper>
      <AuthorizationWrapper>
        <Logo collapsed={false} vertical />
        {step === 'login' && <Login onSubmit={onLoginSubmit} />}
        {step === 'confirmation' && (
          <Confirmation onSubmit={onConfirmationSubmit} isActivation={isMFAActivationPage} email={email} />
        )}
        {step === 'password_reset' && <PasswordReset onSubmit={onPasswordResetSubmit} email={email} />}
      </AuthorizationWrapper>
    </LoginPageWrapper>
  );
};

export default AuthorizationPage;
