import styled from 'styled-components/macro';
import { PageHeader } from 'antd';

import palette from 'palette';

export const StyledAntHeader = styled(PageHeader)`
  box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
  background-color: ${palette.white.w1};
  .ant-page-header-heading-title {
    font-weight: normal;
  }
`;

export const StyledNativeHeader = styled.header`
  position: sticky;
  top: 0;
  z-index: 100;
`;
