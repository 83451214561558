import { AxiosError, AxiosResponse } from 'axios';

import { API_URLS } from 'app-constants/api';
import { JwtTokensResponse } from 'store/auth/types';
import { store } from 'store/store';
import { authActions } from 'store/auth/slice';
import httpService, { HTTPService } from './http.service';
import { localStorageService } from './local-storage.service';

class TokenService {
  private jwtTokensPromise: Promise<JwtTokensResponse> | undefined;

  static getNewJwtTokensPairByRefreshToken(): Promise<JwtTokensResponse> {
    const refreshToken = localStorageService.getRefreshToken();
    if (refreshToken) {
      return httpService
        .post(API_URLS.refreshAccessToken(), {
          refreshToken,
        })
        .then((response: AxiosResponse) => {
          localStorageService.setTokens({
            accessToken: response.data.accessToken,
            refreshToken: response.data.refreshToken,
          });

          return response.data;
        });
    }

    return Promise.reject(store.dispatch(authActions.requestLogout()));
  }

  addNewAccessTokenToErrorRequest(errorRequest: AxiosError) {
    const originalRequest = errorRequest.config;

    if (!this.jwtTokensPromise) {
      this.jwtTokensPromise = TokenService.getNewJwtTokensPairByRefreshToken();
    }

    return this.jwtTokensPromise
      ?.then((jwtTokensPair) => {
        if (originalRequest?.headers) {
          originalRequest.headers[HTTPService.AUTH_HEADER_KEY] = `Bearer ${jwtTokensPair.accessToken}`;
        }
        return httpService.instance(originalRequest);
      })
      .finally(() => {
        this.jwtTokensPromise = undefined;
      });
  }
}

export const tokenService = new TokenService();
