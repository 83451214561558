import { takeEvery } from 'redux-saga/effects';

import makeRequestSaga from 'store/common';
import { getPlatformDetectors } from 'api/platforms';
import { SagaPayload } from 'types';
import { GetPlatformDetectorsQueryParams } from '../types';
import { requestDetectors, getDetectorsSuccess, getDetectorsError } from './slice';

export function* detectorsRequestSaga({ payload }: SagaPayload<GetPlatformDetectorsQueryParams>) {
  yield makeRequestSaga(getDetectorsSuccess, getDetectorsError, getPlatformDetectors, payload);
}

export function* detectorsRequestWatchSaga() {
  yield takeEvery(requestDetectors, detectorsRequestSaga);
}
