import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { updatePlatform, createPlatform, updatePlatformMedia } from 'api/platforms';
import { ApiRequest, SagaPayload } from 'types';
import {
  requestUpdatePlatform,
  requestUpdatePlatformSuccess,
  requestUpdatePlatformError,
  requestCreatePlatform,
  requestCreatePlatformSuccess,
  requestCreatePlatformError,
  clearMessages,
  requestUploadPlatformMedia,
} from './slice';
import { UpdateWimPlatformData, UpdateWimPlatformFormData } from '../../wim/types/platforms';
import { UpdatePlatformMedia } from '../../../pages/AdminManagement/Wims/components/PlatformSummary/types';
import { setNotificationError, setNotificationSuccess } from '../notifications/slice';
import { MessageTitle } from '../notifications/types';

function* makeUserRequestSaga(
  failureAction: ActionCreatorWithPayload<string, string>,
  apiFn: ApiRequest,
  payload = {}
) {
  try {
    const response: AxiosResponse<string> = yield call(apiFn, payload);
    if (response) {
      return response;
    }
  } catch (error) {
    yield put(failureAction(error.message));
    throw error;
  }
  return null;
}

export function* platformUpdateRequestSaga({ payload }: SagaPayload<UpdateWimPlatformData>) {
  try {
    const platformUpdated = yield call(makeUserRequestSaga, requestUpdatePlatformError, updatePlatform(payload));
    yield put(setNotificationSuccess({ title: MessageTitle.crudSuccess }));
    yield put(requestUpdatePlatformSuccess(platformUpdated.data));
  } catch (error) {
    yield put(setNotificationError({ title: MessageTitle.crudError, message: error.message }));
    yield put(requestUpdatePlatformError(error));
  }
}

export function* platformCreateRequestSaga({ payload }: SagaPayload<UpdateWimPlatformFormData>) {
  try {
    const platformUpdated = yield call(makeUserRequestSaga, requestCreatePlatformError, createPlatform(payload));
    yield put(setNotificationSuccess({ title: MessageTitle.crudSuccess }));
    yield put(requestCreatePlatformSuccess(platformUpdated.data));
  } catch (error) {
    yield put(setNotificationError({ title: MessageTitle.crudError, message: error.message }));
    yield put(requestCreatePlatformError(error));
  }
}

export function* platformUploadMediaRequestSaga({ payload }: SagaPayload<UpdatePlatformMedia>) {
  try {
    yield call(updatePlatformMedia(payload), {});
    yield put(setNotificationSuccess({ title: MessageTitle.crudSuccess }));
  } catch (error) {
    yield put(setNotificationError({ title: MessageTitle.crudError, message: error.message }));
    console.log(error);
  }
}

export function* clearMessagesSaga() {
  yield put(clearMessages());
}

export function* platformUpdateRequestWatchSaga() {
  yield takeLatest(requestUpdatePlatform, platformUpdateRequestSaga);
}

export function* platformCreateRequestWatchSaga() {
  yield takeLatest(requestCreatePlatform, platformCreateRequestSaga);
}

export function* clearErrorsWatchSaga() {
  yield takeLatest(clearMessages, clearMessagesSaga);
}

export function* platformUploadMediaRequestWatchSaga() {
  yield takeLatest(requestUploadPlatformMedia, platformUploadMediaRequestSaga);
}
