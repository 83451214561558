import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Connectors } from '../types';

type InitialState = {
  isLoading: boolean;
  connectors: Connectors | null;
  error: any;
};

const initialState: InitialState = {
  isLoading: false,
  connectors: null,
  error: null,
};

const connectors = createSlice({
  name: 'connectors',
  initialState,
  reducers: {
    requestConnectors: (state) => {
      state.isLoading = true;
    },
    getConnectorsSuccess: (state, { payload }: PayloadAction<Connectors>): void => {
      state.isLoading = false;
      state.connectors = payload;
    },
    getConnectorsError: (state, { payload }: PayloadAction<string>): void => {
      state.isLoading = false;
      state.error = payload;
    },
    clearConnectors: (state): void => {
      state.connectors = null;
    },
  },
});

export const { requestConnectors, getConnectorsSuccess, getConnectorsError, clearConnectors } = connectors.actions;

export default connectors.reducer;
