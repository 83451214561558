import React, { ReactText } from 'react';
import { useTranslation } from 'react-i18next';
import { BarChart, XAxis, YAxis, Bar, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import palette from 'palette';
import { ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { ContentType } from 'recharts/types/component/Tooltip';

import { PlateRecognitionTooltip } from './Tooltips';
import { PlateRecognitionChartWrapper } from './styles';

export interface PlateRecognitionPayload {
  recognizedKey: number;
  unrecognizedKey: number;
  timestamp: number;
  date: string;
  fullDate: string;
}

interface BarChartProps {
  data: PlateRecognitionPayload[];
  recognizedKey: string;
  unrecognizedKey: string;
  width: number;
  height: number;
  isTooltip?: boolean;
  tooltipContent?: ContentType<ValueType, ReactText>;
}

const BarChartPlateRecognitions: React.FC<BarChartProps> = ({
  data,
  recognizedKey,
  unrecognizedKey,
  width,
  height,
  isTooltip,
  tooltipContent,
}) => {
  const { t } = useTranslation();

  const renderLegendText = (value: string) =>
    value === 'recognizedKey' ? (
      <span>{t('map.mapModal.recognizedPlate')}</span>
    ) : (
      <span>{t('map.mapModal.unrecognizedPlate')}</span>
    );

  return (
    <PlateRecognitionChartWrapper>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={width} height={height} data={data}>
          <XAxis dataKey="date" interval="preserveStart" tickSize={3} minTickGap={6} />
          <YAxis />
          {isTooltip && <Tooltip content={tooltipContent ?? <PlateRecognitionTooltip />} />}
          <Bar dataKey={unrecognizedKey} stackId="a" fill={palette.red.r2} />
          <Bar dataKey={recognizedKey} stackId="a" fill={palette.green.g3} />
          <Legend
            formatter={renderLegendText}
            key="platerecognitions-chart-legend"
            verticalAlign="bottom"
            align="center"
          />
        </BarChart>
      </ResponsiveContainer>
    </PlateRecognitionChartWrapper>
  );
};

export { BarChartPlateRecognitions };
