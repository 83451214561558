import { call, cancel, cancelled, fork, put, take, takeLatest } from 'redux-saga/effects';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import i18n from 'i18n';

import {
  getMfaQrCode,
  logout,
  postPasswordReset,
  postValidationCode,
  sendAuthForm,
  triggerPasswordReset,
} from 'api/auth';
import { ROUTES } from 'app-constants/routes';
import { localStorageService } from 'services/local-storage.service';
import { setupNewTokens } from 'store/common/tokens';
import { userActions } from 'store/user/slice';
import { SagaContext, SagaPayload } from 'types/saga';
import { authActions } from './slice';
import {
  LoginErrorResponse,
  LoginQueryParams,
  LoginResponse,
  PostMfaConfirmationRequest,
  PostPasswordResetRequest,
  RefreshToken,
  TriggerPasswordResetRequest,
} from './types';

function* authorizeError(errorResponse: AxiosResponse) {
  if (errorResponse.data.error === LoginErrorResponse.INCORRECT_AUTH) {
    yield put({
      type: authActions.getLoginError.type,
      payload: { userMessage: i18n.t('loginForm.incorrect'), serverError: null },
    });

    return i18n.t('loginForm.incorrect');
  }

  if (errorResponse.status === LoginErrorResponse.STATUS_USER_INACTIVE) {
    yield put({
      type: authActions.getLoginError.type,
      payload: { userMessage: i18n.t('loginForm.inactive'), serverError: null },
    });

    return i18n.t('loginForm.inactive');
  }

  if (errorResponse.data.error === LoginErrorResponse.PASSWORD_LESS_6_CHARS) {
    yield put({
      type: authActions.getLoginError.type,
      payload: { userMessage: i18n.t('loginForm.passwordLess6Chars'), serverError: null },
    });

    return i18n.t('loginForm.passwordLess6Chars');
  }

  if (errorResponse.data.error === LoginErrorResponse.INCORRECT_EMAIL) {
    yield put({
      type: authActions.getLoginError.type,
      payload: { userMessage: i18n.t('loginForm.incorrectEmail'), serverError: null },
    });

    return i18n.t('loginForm.incorrectEmail');
  }

  yield put({
    type: authActions.getLoginError.type,
    payload: { userMessage: null, serverError: errorResponse.data },
  });

  return errorResponse.data;
}

function* authorize(email: string, password: string, context: SagaContext) {
  try {
    const response: AxiosResponse<LoginResponse> = yield call(sendAuthForm, { email, password });

    if (response) {
      yield call(setupNewTokens, {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
      });

      yield put({ type: userActions.getUserSuccess.type, payload: response.data.user });
      yield put({ type: authActions.getLoginSuccess.type, payload: response.data });
      yield put({ type: authActions.setIsAuthenticated.type, payload: true });
      yield put({ type: authActions.getLoginError.type, payload: { userMessage: null, serverError: null } });
    }

    yield context.routerHistory.push(ROUTES.Home);
  } catch (error) {
    if (error.response.status === LoginErrorResponse.STATUS_MFA_ACTIVATION) {
      yield put({ type: authActions.setMFAActivationPage.type, payload: true });
    }
    if (error.response.status === LoginErrorResponse.STATUS_MFA_CONFIRMATION) {
      yield put({ type: authActions.setMFAConfirmationPage.type, payload: true });
    }
    if (error.response.status === LoginErrorResponse.PASSWORD_RESET) {
      yield put({ type: authActions.setPasswordResetPage.type, payload: true });
    }
    yield authorizeError(error.response);
  } finally {
    if (yield cancelled()) {
      const refreshToken = yield call([localStorageService, localStorageService.getRefreshToken]);
      yield call(clearProfile, refreshToken);
    }
  }
}

export default function* authFlowWatchSaga(context: SagaContext) {
  while (true) {
    const {
      payload: { email, password },
    }: ReturnType<ActionCreatorWithPayload<LoginQueryParams, string>> = yield take(authActions.requestLogin.type);
    const authorizeTask = yield fork(authorize, email, password, context);

    const logoutAction = yield take([
      authActions.requestLogout.type,
      authActions.getLoginError.type,
      authActions.getLogoutSuccess.type,
    ]);

    if (logoutAction.type === authActions.requestLogout.type) {
      yield cancel(authorizeTask);
      const refreshToken = yield call([localStorageService, localStorageService.getRefreshToken]);
      yield call(clearProfile, refreshToken);
    }
  }
}

function* clearProfile(refreshToken?: RefreshToken) {
  yield put(userActions.clearUser());
  yield put(authActions.clearLogin());

  if (refreshToken) {
    yield call(logout, refreshToken);
  }

  yield call([localStorageService, localStorageService.clearJwtTokens]);
}

export function* logoutFlowWatchSaga() {
  while (true) {
    yield take(authActions.requestLogout.type);
    const refreshToken = yield call([localStorageService, localStorageService.getRefreshToken]);
    yield call(clearProfile, refreshToken);
  }
}

function* getQrCodeSaga({ payload }: SagaPayload<string>) {
  try {
    const email = payload;
    const response: AxiosResponse<{ qrCodeBase64: string }> = yield call(getMfaQrCode, { email });

    if (response) {
      yield put(authActions.getQrSuccess(response.data));
    }
  } catch (error) {
    yield put(authActions.getQrError(error));
  }
}

export function* mfaActivateQrCodeWatchSaga() {
  yield takeLatest(authActions.requestQrActivation, getQrCodeSaga);
}

function* postValidationCodeSaga({ payload }: SagaPayload<PostMfaConfirmationRequest>) {
  try {
    const response: AxiosResponse<any> = yield call(postValidationCode, payload);

    if (response) {
      yield call(setupNewTokens, {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
      });

      yield put({ type: userActions.getUserSuccess.type, payload: response.data.user });
      yield put({ type: authActions.getLoginSuccess.type, payload: response.data });
      yield put({ type: authActions.setIsAuthenticated.type, payload: true });
      yield put({ type: authActions.getLoginError.type, payload: { userMessage: null, serverError: null } });

      yield put(authActions.postValidationCodeSuccess());
    }
  } catch (error) {
    yield put(authActions.postValidationCodeError(error));
  }
}

function* postPasswordResetSaga({ payload }: SagaPayload<PostPasswordResetRequest>) {
  try {
    const response: AxiosResponse<any> = yield call(postPasswordReset, payload);

    if (response) {
      yield call(setupNewTokens, {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
      });

      yield put({ type: userActions.getUserSuccess.type, payload: response.data.user });
      yield put({ type: authActions.getLoginSuccess.type, payload: response.data });
      yield put({ type: authActions.setIsAuthenticated.type, payload: true });
      yield put({ type: authActions.getLoginError.type, payload: { userMessage: null, serverError: null } });

      yield put(authActions.postValidationCodeSuccess());
    }
  } catch (error) {
    yield put(authActions.postValidationCodeError(error));
  }
}

function* triggerPasswordResetSaga({ payload }: SagaPayload<TriggerPasswordResetRequest>) {
  try {
    yield call(triggerPasswordReset, payload);
  } catch (error) {
    yield put(authActions.getLogoutError(error));
  }
}

export function* mfaPostValidationCodeWatchSaga() {
  yield takeLatest(authActions.postValidationCode, postValidationCodeSaga);
}

export function* passwordResetPostWatchSaga() {
  yield takeLatest(authActions.postPasswordReset, postPasswordResetSaga);
}

export function* triggerPasswordResetWatchSaga() {
  yield takeLatest(authActions.triggerPasswordReset, triggerPasswordResetSaga);
}
