import React, { FC } from 'react';
import { Select } from 'antd';

import { Group } from 'store/admin/groups/types';
import { useTranslation } from 'react-i18next';

interface SelectDeviceGroupsProps {
  deviceGroups: Group[];
  onSelect: (id: number) => void;
}

export const FormikSelectWimDeviceGroup: FC<SelectDeviceGroupsProps> = ({ deviceGroups, onSelect }) => {
  const { t } = useTranslation();
  const onChange = (id: number) => {
    onSelect(id);
  };

  return (
    <Select
      style={{ width: 320 }}
      onChange={onChange}
      options={deviceGroups.map((group) => {
        return {
          value: group.id,
          label: group.name + (group.enabled ? '' : ` ${t('group.disabled')}`),
        };
      })}
    />
  );
};
