import palette from 'palette';
import styled from 'styled-components/macro';

export const StyledDetailViolationsHeader = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  border-bottom: 1px solid ${palette.grey.g5};
`;
export const StyledDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledDetailTitle = styled.span`
  margin: 5px 0;
`;
export const StyledDetailBarChartWrapper = styled.div`
  width: 100%;
  height: 220px;
`;
