import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetWimVehicleClassificationsQueryParams, VehicleClassification } from '../types';

type InitialState = {
  isLoading: boolean;
  vehicleClassifications: VehicleClassification[];
  error: string | null;
};

const initialState: InitialState = {
  isLoading: false,
  vehicleClassifications: [],
  error: null,
};

const vehicleClassifications = createSlice({
  name: 'vehicleClassifications',
  initialState,
  reducers: {
    requestWimVehicleClassifications: (
      state,
      { payload }: PayloadAction<GetWimVehicleClassificationsQueryParams>
    ): void => {
      state.isLoading = true;
    },
    getWimVehicleClassificationsSuccess: (state, { payload }: PayloadAction<VehicleClassification[]>): void => {
      state.isLoading = false;
      state.vehicleClassifications = payload;
    },
    getWimVehicleClassificationsError: (state, { payload }: PayloadAction<string>): void => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const {
  requestWimVehicleClassifications,
  getWimVehicleClassificationsSuccess,
  getWimVehicleClassificationsError,
} = vehicleClassifications.actions;

export default vehicleClassifications.reducer;
