import i18n from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import ua from 'locales/ua/translation.json';
import ru from 'locales/ru/translation.json';
import en from 'locales/en/translation.json';

const DETECTION_OPTIONS = {
  order: ['localStorage'],
  caches: ['localStorage'],
};

i18n
  .use(ICU)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: DETECTION_OPTIONS,
    fallbackLng: 'ua',
    debug: false,
    resources: {
      ua: {
        translation: ua,
      },
      en: {
        translation: en,
      },
      ru: {
        translation: ru,
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
