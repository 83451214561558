import React, { PropsWithChildren, ReactElement, useEffect } from 'react';
import { Button, Popconfirm, Table, TableProps } from 'antd';
import { ColumnsType, ColumnType } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';
import { FieldArrayRenderProps } from 'formik';

import { StyledTableWrapper } from './styles';

export type EditableTableColumn<TableRecord> = ColumnType<TableRecord> & { editable?: boolean };

interface EditableTableContainerProps<TableRecord>
  extends Omit<TableProps<TableRecord>, 'columns' | 'onChange' | 'dataSource'> {
  columns: EditableTableColumn<TableRecord>[];
  form: FieldArrayRenderProps;
  rowAdd: TableRecord;
  dataSource?: TableRecord[];
}

function EditableTableContainer<TableRecord extends Record<string, any>>({
  dataSource,
  columns,
  form,
  rowAdd,
  ...tableProps
}: PropsWithChildren<EditableTableContainerProps<TableRecord>>): ReactElement {
  const { t } = useTranslation();

  const handleDelete = (index: number) => {
    form.remove(index);
  };

  const actionColumn: ColumnType<TableRecord> = {
    dataIndex: 'action',
    render: (_text, _record, index) => (
      <Popconfirm
        title={t('phrases.sureToDelete')}
        onConfirm={() => handleDelete(index)}
        okText={t('common.yes')}
        cancelText={t('common.no')}
      >
        <a href="/">{t('common.delete')}</a>
      </Popconfirm>
    ),
  };

  const handleAdd = () => {
    form.push(rowAdd);
  };

  useEffect(() => {
    columns.push(actionColumn);
    return () => {
      const columnIndex = columns.indexOf(actionColumn);
      columns.splice(columnIndex, 1);
    };
  }, []);

  return (
    <StyledTableWrapper>
      <Table<TableRecord>
        dataSource={dataSource}
        columns={columns as ColumnsType<TableRecord>}
        rowClassName={() => 'editable-row'}
        locale={{ emptyText: t('common.noData') }}
        {...tableProps}
      />
      <Button onClick={handleAdd} type="primary" style={{ marginTop: 16 }}>
        {t('phrases.addRow')}
      </Button>
    </StyledTableWrapper>
  );
}

export { EditableTableContainer };
