import { all, fork } from 'redux-saga/effects';

import { SagaContext } from 'types/saga';
import authWatchSaga, {
  logoutFlowWatchSaga,
  mfaActivateQrCodeWatchSaga,
  mfaPostValidationCodeWatchSaga,
  passwordResetPostWatchSaga,
  triggerPasswordResetWatchSaga,
} from './auth/saga';
import wimWatchSaga from './wim/saga';
import platformsWatchSaga from './platforms/saga';
import statisticsSaga from './statistics/saga';
import { adminWatchSaga } from './admin/saga';
import userRequestWatchSaga from './user/saga';
import systemWatchSaga from './system/saga';

const sagas = [
  userRequestWatchSaga,
  wimWatchSaga,
  platformsWatchSaga,
  authWatchSaga,
  statisticsSaga,
  logoutFlowWatchSaga,
  mfaActivateQrCodeWatchSaga,
  mfaPostValidationCodeWatchSaga,
  passwordResetPostWatchSaga,
  triggerPasswordResetWatchSaga,
  adminWatchSaga,
  systemWatchSaga,
];

function* rootSaga(context: SagaContext) {
  yield all(sagas.map((saga) => fork(saga, context)));
}

export default rootSaga;
