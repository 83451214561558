import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import WimsMapPage from './Map';
import WimsGridPage from './Grid';
import useGetPlatforms from './hooks/use-get-platforms';
import ActivePlatform from './ActivePlatform';
import MonitoringPage from './Monitoring';

const WimTab: FC = () => {
  const { path } = useRouteMatch();
  useGetPlatforms();

  return (
    <Switch>
      <Route exact path={`${path}/map`} component={WimsMapPage} />
      <Route exact path={`${path}/grid`} component={WimsGridPage} />
      <Route exact path={`${path}/monitoring`} component={MonitoringPage} />
      <Route path={`${path}/:id`} component={ActivePlatform} />
    </Switch>
  );
};

export default WimTab;
