import { useEffect } from 'react';

import { useAppDispatch } from 'hooks/store-hooks';
import { wimActions } from 'store/wim/slices';

function useGetPlatforms(): void {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(wimActions.platforms.requestPlatforms());
  }, [dispatch]);
}

export default useGetPlatforms;
