import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { TableContainerWithSorting } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks/store-hooks';
import { TransitsTableRow, getOnlineTransitsTableColumns } from 'pages/Transits/table-schema';
import wimSelectors from 'store/wim/selectors';
import { wimActions } from 'store/wim/slices';
import { Container, OnlineText } from './styles';

const TransitsOnline: React.FC = () => {
  const dispatch = useAppDispatch();
  const columns = Object.values(getOnlineTransitsTableColumns());
  const transits = useAppSelector(wimSelectors.transits.getTransits);
  const isLoading = useAppSelector(wimSelectors.transits.isLoading);
  const isOnline = useAppSelector(wimSelectors.transits.isOnlineMode);
  const { t } = useTranslation();

  const isNotEmpty = transits?.items.length;

  const startOnline = () => dispatch(wimActions.transits.setOnlineMode(true));

  return isOnline || isNotEmpty ? (
    <TableContainerWithSorting<TransitsTableRow>
      loading={isLoading}
      dataSource={transits?.items}
      columns={columns}
      showSorterTooltip={false}
      scroll={{ y: 500 }}
      rowClassName={(record) => {
        return record.violations.length ? 'ant-error-row' : '';
      }}
      rowKey={(record) => record.id}
    />
  ) : (
    <Container>
      <OnlineText>Для відстежування проїздів в режимі онлайн натисніть</OnlineText>
      <Button style={{ color: '#1890FF', alignItems: 'center' }} type="text" onClick={() => startOnline()}>
        {t('common.start')}
      </Button>
    </Container>
  );
};

export default TransitsOnline;
