import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { SortDirection } from 'app-constants/table';
import { TableContainerWithSorting } from 'components';
import { useUrlParams } from 'hooks/use-url-params';
import { useAppDispatch, useAppSelector } from 'hooks/store-hooks';
import { usePagination } from 'hooks/use-pagination';
import wimSelectors from 'store/wim/selectors';
import { wimActions } from 'store/wim/slices';
import {
  DEFAULT_PAGE_SIZE_OPTIONS,
  DEFAULT_OFFSET,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  TransitsTableRow,
  getArchiveTransitsTableColumns,
} from 'pages/Transits/table-schema';
import { ComponentFilterProps } from '../../../../../hoc/withFilter';
import { TransitsArchiveFilterValues } from '../../../../../store/wim/types';

const TransitsArchive: React.FC<ComponentFilterProps<TransitsArchiveFilterValues>> = ({ filterManager }) => {
  const dispatch = useAppDispatch();
  const { queryUrlParams } = useUrlParams({});
  const columns = Object.values(getArchiveTransitsTableColumns());
  const transits = useAppSelector(wimSelectors.transits.getTransits);
  const isLoading = useAppSelector(wimSelectors.transits.isLoading);
  const { t } = useTranslation();
  const { isFilterSubmitClicked } = filterManager;
  const [filterSubmitButtonClicked, setFilterSubmitButtonClicked] = useState({});

  const { pagination } = usePagination({
    offset: DEFAULT_OFFSET,
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
    total: transits?.total,
  });

  const { page, pageSize, offset } = pagination;

  const startInitialDate = dayjs().subtract(1, 'hour').valueOf();
  const endInitialDate = dayjs().valueOf();

  useEffect(() => {
    dispatch(wimActions.transits.clearTransitsInfo());
  }, [dispatch]);

  useEffect(() => {
    if (isFilterSubmitClicked) {
      setFilterSubmitButtonClicked({ ...filterSubmitButtonClicked });
    }
  }, [isFilterSubmitClicked]);

  useEffect(() => {
    dispatch(
      wimActions.transits.requestTransits({
        start: startInitialDate,
        end: endInitialDate,
        limit: pageSize,
        sort: {
          [queryUrlParams.sort.key]:
            queryUrlParams.sort.value === 'descend' ? SortDirection.DESCEND : SortDirection.ASCEND,
        },
        offset,
        ...queryUrlParams.filter,
      })
    );
  }, [dispatch, offset, pageSize, page, filterSubmitButtonClicked, queryUrlParams.sort.key, queryUrlParams.sort.value]);

  const paginationOptions = {
    ...pagination,
    showTotal: (total: number) => {
      if (total === Number.MAX_SAFE_INTEGER) {
        return `${t('transitsPage.table.itemsUnknown')}`;
      }

      return `${t('common.total')} ${total} ${t('transitsPage.table.items')}`;
    },
  };

  return (
    <TableContainerWithSorting<TransitsTableRow>
      loading={isLoading}
      pagination={paginationOptions}
      dataSource={transits?.items}
      columns={columns}
      showSorterTooltip={false}
      scroll={{ y: 500 }}
      size="small"
      rowClassName={(record) => (record.violations.length ? 'ant-error-row' : '')}
      rowKey={(record) => record.id}
    />
  );
};

export default TransitsArchive;
