import React from 'react';
import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { CollapsedInfo } from '../../errors/CollapsedInfo';

interface ErrorProps {
  message: string;
  description: string;
}

const initialDescription = 'Please check and modify the following information before resubmitting.';

export const ErrorResult: React.FC<ErrorProps> = ({ message, description = initialDescription }) => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <Result
      status="error"
      title={message}
      extra={[
        <Button type="primary" key="console" onClick={goBack}>
          Go Back
        </Button>,
      ]}
    >
      <CollapsedInfo info={description} />
    </Result>
  );
};

export default ErrorResult;
