const env = {
  WAGA_ANALYTICS_URL: process.env.REACT_APP_WAGA_ANALYTICS_URL,
  WAGA_URL: process.env.REACT_APP_WAGA_URL,
  WAGA_API_URL: process.env.REACT_APP_WAGA_API_URL,
  SECONDARY_WAGA_API_URL: process.env.REACT_APP_SECONDARY_WAGA_API_URL,
  AUTH: {
    username: process.env.REACT_APP_AUTH_USERNAME || '',
    password: process.env.REACT_APP_AUTH_PASSWAORD || '',
  },
  DETECTORS_ENABLED: process.env.REACT_APP_DETECTORS_ENABLED === 'true' || false,
  USE_TENANT_LOGO: process.env.USE_TENANT_LOGO === 'true' || false,
  TENANT_NAME: process.env.TENANT_NAME || 'system',
};

export default env;
