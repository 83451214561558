import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, put, PutEffect, SimpleEffect, takeEvery } from 'redux-saga/effects';

import { getIntensityTraffic } from 'api/statistics';
import makeRequestSaga from 'store/common';
import { getWimTransit, getWimTransits, getTransitPhotoInBase64 } from 'api/wim';
import { SagaPayload } from 'types';
import { GetStatisticQueryParamsType } from 'store/statistics/types';
import { GetWimTransitsQueryParams, GetWimTransitPayload } from '../types';
import {
  requestTransits,
  getTransitsSuccess,
  getTransitsError,
  getTransitSuccess,
  getTransitError,
  requestTransit,
  getTransitPhotoSuccess,
  getTransitPhotosSuccess,
  getTransitPhotoError,
  requestTransitPhotos,
  requestTransitPhoto,
  clearTransitPhotos,
  getTransitPhotosError,
  requestIntensityTraffic,
  getIntensityTrafficSuccess,
  getIntensityTrafficError,
  requestTransitWithCoordinatesByPlate,
  getTransitWithCoordinatesByPlate,
} from './slice';
import {
  GetTransitWithCoordinatesByPlate,
  GetWimTransitPhoto,
  GetWimTransitPhotoPayload,
  GetWimTransitPhotos,
  GetWimTransitPhotosPayload,
  PhotoType,
  WimTransitsWithMapCoordinates,
} from '../types/transits';

export function* wimTransitsRequestSaga({ payload }: SagaPayload<GetWimTransitsQueryParams>) {
  yield makeRequestSaga(getTransitsSuccess, getTransitsError, getWimTransits, payload);
}

export function* wimTransitRequestSaga({ payload }: SagaPayload<GetWimTransitPayload>) {
  yield makeRequestSaga(
    getTransitSuccess,
    getTransitError,
    getWimTransit({ transitUid: payload.transitUid, ts: payload.ts }),
    payload
  );
}

export function* wimTransitPhotoRequestSaga({
  payload,
}: SagaPayload<GetWimTransitPhoto>): Generator<
  | CallEffect<AxiosResponse>
  | PutEffect<{ payload: GetWimTransitPhotoPayload | string; type: string }>
  | SimpleEffect<string>,
  void,
  AxiosResponse
> {
  try {
    yield put(clearTransitPhotos());
    const response: AxiosResponse = yield call(getTransitPhotoInBase64, {
      transitUid: payload.transitUid,
      params: { type: payload.photoType } as any,
    });

    if (response) {
      const image = `data:${response.headers['content-type'].toLowerCase()};base64,${Buffer.from(
        response.data,
        'utf8'
      ).toString('base64')}`;

      yield put(
        getTransitPhotoSuccess({
          photoType: payload.photoType,
          value: image,
        })
      );
    } else {
      yield put(getTransitPhotoError(JSON.parse(response)));
    }
  } catch (error: any) {
    yield put(getTransitPhotoError(error.message));
  }
}

export function* wimTransitPhotosRequestSaga({
  payload,
}: SagaPayload<GetWimTransitPhotos>): Generator<
  | AllEffect<Generator<CallEffect<AxiosResponse> | PutEffect<{ payload: string; type: string }>>>
  | PutEffect<{ payload: GetWimTransitPhotosPayload; type: string }>,
  void,
  AxiosResponse[]
> {
  const responseArray = yield all([
    ...Object.keys(PhotoType).map((key, index) =>
      (function* (): Generator<CallEffect<AxiosResponse> | PutEffect<{ payload: string; type: string }>, void, void> {
        try {
          return yield call(getTransitPhotoInBase64, { transitUid: payload.transitUid, params: { type: key } as any });
        } catch (error: any) {
          return yield put(getTransitPhotosError(error.message));
        }
      })()
    ),
  ]);

  yield put(
    getTransitPhotosSuccess({
      photos: responseArray.map((res, index) => {
        return {
          photoType: Object.keys(PhotoType)[index] as PhotoType,
          value:
            res.data &&
            `data:${res.headers['content-type'].toLowerCase()};base64,${Buffer.from(res.data, 'utf8').toString(
              'base64'
            )}`,
        };
      }),
    })
  );
}

export function* intensityTrafficRequestSaga({ payload }: SagaPayload<GetStatisticQueryParamsType>) {
  yield makeRequestSaga(getIntensityTrafficSuccess, getIntensityTrafficError, getIntensityTraffic, payload);
}
function* wimTransitWithCoordinatesByPlateRequestSaga({ payload }: SagaPayload<GetTransitWithCoordinatesByPlate>) {
  try {
    const response: AxiosResponse<WimTransitsWithMapCoordinates> = yield call(getWimTransits, payload);

    if (response) {
      yield put(getTransitWithCoordinatesByPlate(response.data.items));
    }
  } catch (error) {
    yield put(getTransitsError(error.message));
  }
}

export function* wimTransitsRequestWatchSaga() {
  yield takeEvery(requestTransits, wimTransitsRequestSaga);
}

export function* wimTransitRequestWatchSaga() {
  yield takeEvery(requestTransit, wimTransitRequestSaga);
}

export function* wimTransitPhotoRequestWatchSaga() {
  yield takeEvery(requestTransitPhoto, wimTransitPhotoRequestSaga);
}

export function* wimTransitPhotosRequestWatchSaga() {
  yield takeEvery(requestTransitPhotos, wimTransitPhotosRequestSaga);
}

export function* intensityTrafficRequestWatchSaga() {
  yield takeEvery(requestIntensityTraffic, intensityTrafficRequestSaga);
}

export function* wimTransitWithCoordinatesByPlateRequestWatchSaga() {
  yield takeEvery(requestTransitWithCoordinatesByPlate, wimTransitWithCoordinatesByPlateRequestSaga);
}
