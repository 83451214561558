import styled, { css } from 'styled-components/macro';

interface ChartMarkerProps {
  color: string;
}

interface ValueProps {
  align?: string;
  accented?: boolean;
  small?: boolean;
}

export const StyledBody = styled.tbody``;

export const StyledColumn = styled.td``;

export const StyledLegend = styled.div`
  font-size: 12px;
`;

const ChartMarker = styled.svg<ChartMarkerProps>`
  color: ${({ color }) => color};
  width: 100%;
  height: 100%;
`;

export const StyledMarker = styled(ChartMarker)`
  width: 10px;
  height: 7px;
  margin-right: 4px;
`;

export const StyledRectangle = styled.rect`
  width: 100%;
  height: 100%;
`;

export const StyledRow = styled.tr``;

export const StyledTable = styled.table`
  margin-bottom: 10px;
`;

export const StyledTooltip = styled.div`
  width: max-content;
  padding: 10px;
  font-size: 16px;
  color: rgb(38, 38, 38);
  background: white;
  border-radius: 3px;
  box-shadow: rgb(0 0 0 / 25%) 0px 0px 10px;
  transform: translateY(calc(-100%));
`;

export const StyledValue = styled.div<ValueProps>`
  padding-right: 9px;

  text-align: ${({ align }) => align || 'right'};

  ${({ accented }) =>
    accented &&
    css`
      font-weight: 600;
    `}

  ${({ small }) =>
    small &&
    css`
      font-size: 12px;
    `}
`;
