import React, { FC } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { SelectValue } from 'antd/lib/select';

import { Group } from 'store/admin/groups/types';
import { StyledSelect, StyledSelectWithLabel, StyledLabel } from './styles';

interface SelectDeviceGroupsProps {
  name: string;
  onChange: (value: SelectValue) => void;
  value?: number[] | null;
  deviceGroups: Group[];
}

const { Option } = Select;

export const FormikSelectDeviceGroups: FC<SelectDeviceGroupsProps> = ({ name, value = [], onChange, deviceGroups }) => {
  const { t } = useTranslation();

  return (
    <StyledSelectWithLabel>
      <StyledLabel>{t('infoFields.deviceGroups')}</StyledLabel>
      <Field
        as={StyledSelect}
        name={name}
        style={{ width: '100%' }}
        mode="multiple"
        defaultValue={value}
        onChange={onChange}
      >
        {deviceGroups.map((group) => (
          <Option key={`${group.id}-${group.name}`} value={group.id}>
            {group.name}
            {group.enabled ? '' : ` ${t('group.disabled')}`}
          </Option>
        ))}
      </Field>
    </StyledSelectWithLabel>
  );
};
