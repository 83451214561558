import dayjs from 'dayjs';
import { isEmpty, isNull, isUndefined } from 'lodash';
import { TransitsArchiveFilterKey, TransitsArchiveFilterValues } from 'store/wim/types/transits';

const isValueSignificant = (value: unknown): boolean => !isEmpty(value) || !isUndefined(value) || !isNull(value);

export const queryParamsToFilterValues = (filterValues: {
  [key: string]: any;
}): Partial<TransitsArchiveFilterValues> => {
  return Object.keys(filterValues).reduce((acc, currentFilterValueKey) => {
    if (isValueSignificant(filterValues[currentFilterValueKey])) {
      acc[currentFilterValueKey as keyof TransitsArchiveFilterValues] = filterValues[currentFilterValueKey];
    }

    if (currentFilterValueKey === TransitsArchiveFilterKey.start) {
      acc[currentFilterValueKey] = filterValues[currentFilterValueKey]
        ? dayjs(parseInt(filterValues[currentFilterValueKey], 10)).valueOf()
        : dayjs().subtract(1, 'hour').valueOf();
    }

    if (currentFilterValueKey === TransitsArchiveFilterKey.end) {
      acc[currentFilterValueKey] = filterValues[currentFilterValueKey]
        ? dayjs(parseInt(filterValues[currentFilterValueKey], 10)).valueOf()
        : dayjs().valueOf();
    }

    if (
      currentFilterValueKey === TransitsArchiveFilterKey.plateRecognized ||
      currentFilterValueKey === TransitsArchiveFilterKey.plateBackRecognized
    ) {
      acc[currentFilterValueKey] = !!filterValues[currentFilterValueKey];
    }

    return acc;
  }, {} as Partial<TransitsArchiveFilterValues>);
};
