import React from 'react';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import i18n from 'i18n';
import dayjs from 'dayjs';

import { wrapTableTitle } from 'components/TableContainer/DefaultTableContainer';
import { getFloatPercent } from 'helpers/percent.formatter';
import { DATE_FORMAT } from 'app-constants/date';
import { WimMonitoringMetricStatistics } from 'store/wim/monitoring/types';

export type WimMonitoringMetricStatisticsTableType = WimMonitoringMetricStatistics;

export type StatisticsTableColumns = {
  [key in keyof Omit<WimMonitoringMetricStatisticsTableType, 'uid' | 'totalTransits'>]:
    | ColumnType<WimMonitoringMetricStatisticsTableType>
    | ColumnGroupType<WimMonitoringMetricStatisticsTableType>;
};

export const getStatisticTableColumns = (): StatisticsTableColumns => ({
  wim: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('wimsPage.monitoring.statistic.wim')),
    width: 60,
    sorter: (a, b) => Number(a.wim.substr(3)) - Number(b.wim.substr(3)),
    align: 'center',
    key: 'wim',
    dataIndex: 'wim',
    className: 'clickable',
  },
  period: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('wimsPage.monitoring.statistic.period')),
    width: 120,
    align: 'center',
    key: 'period',
    dataIndex: 'period',
    className: 'clickable',
    render: (period: [number, number]) => (
      <>
        <span>{dayjs(period[0]).format(DATE_FORMAT.FULLDATE_NO_TIME)}</span>
        <span> - </span>
        <span>{dayjs(period[1]).format(DATE_FORMAT.FULLDATE_NO_TIME)}</span>
      </>
    ),
  },
  metric: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('wimsPage.monitoring.statistic.metric')),
    width: 60,
    sorter: (a, b) => Number(a.metric) - Number(b.metric),
    align: 'center',
    key: 'metric',
    dataIndex: 'metric',
    className: 'clickable',
  },
  percentFromTotalTransits: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('wimsPage.monitoring.statistic.percentFromTotalTransits')),
    width: 80,
    sorter: (a, b) => a.percentFromTotalTransits - b.percentFromTotalTransits,
    align: 'center',
    key: 'percentFromTotalTransits',
    dataIndex: 'percentFromTotalTransits',
    className: 'clickable',
    render: (percent: number) => getFloatPercent(percent),
  },
});
