import { API_URLS } from 'app-constants/api';
import httpService from 'services/http.service';
import { PostSettings } from 'store/admin/settings/types';
import { ApiRequest } from 'types';

export const getSettings: ApiRequest = () => httpService.get(API_URLS.settings(), {});

export const updateSettings =
  (settings: PostSettings): ApiRequest =>
  () => {
    return httpService.post(API_URLS.settings(), settings);
  };
