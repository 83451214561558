import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SettingsResponse, PostSettings, SettingsError } from './types';

interface InitialState {
  isUpdateSettingsLoading: boolean;
  isGetSettingsLoading: boolean;
  settingsResult: SettingsResponse | null;
  settingsError: SettingsError;
}

const initialState: InitialState = {
  isUpdateSettingsLoading: false,
  isGetSettingsLoading: false,
  settingsResult: null,
  settingsError: { userMessage: null, serverError: null },
};

const systemSettings = createSlice({
  name: 'systemSettings',
  initialState,
  reducers: {
    requestGetSettings: (state): void => {
      state.isGetSettingsLoading = true;
    },
    requestGetSettingsSuccess: (state, { payload }: PayloadAction<SettingsResponse>): void => {
      state.settingsResult = payload;
      state.isGetSettingsLoading = false;
    },
    requestGetSettingsError: (state, { payload }: PayloadAction<SettingsError>): void => {
      state.settingsError = payload;
      state.isGetSettingsLoading = false;
    },
    requestPostSettings: (state, { payload }: PayloadAction<PostSettings>): void => {
      state.isUpdateSettingsLoading = true;
    },
    requestPostSettingsSuccess: (state, { payload }: PayloadAction<SettingsResponse>): void => {
      state.settingsResult = payload;
      state.isUpdateSettingsLoading = false;
    },
    requestPostSettingsError: (state, { payload }: PayloadAction<SettingsError>): void => {
      state.settingsError = payload;
      state.isUpdateSettingsLoading = false;
    },
  },
});

export const {
  requestGetSettings,
  requestGetSettingsSuccess,
  requestGetSettingsError,
  requestPostSettings,
  requestPostSettingsSuccess,
  requestPostSettingsError,
} = systemSettings.actions;

export default systemSettings.reducer;
