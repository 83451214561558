import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/root-reducer';
import { normalizeWimPlatform, normalizeWimPlatformsWithDeepSummary, normalizeWimSummary } from '../normalization';
import { WimPlatform } from '../types';
import { WimPlatformWithDeepSummary, WimPlatformSummary } from '../types/platforms';

export const getPlatforms = (state: RootState): WimPlatform[] | null => state.wim.platforms.platforms;

export const getNormalizedPlatforms = createSelector(
  getPlatforms,
  (platforms) => platforms && normalizeWimPlatform(platforms)
);

export const getPlatformSummary = createSelector(
  [(state: RootState): WimPlatformSummary[] | null => state.wim.platforms.platformSummary],
  (summary) => summary && normalizeWimSummary(summary)
);

export const getPlatformsSummary = createSelector(
  [(state: RootState): WimPlatformWithDeepSummary[] | null => state.wim.platforms.platformsSummary],
  (platformsWithDeepSummary) =>
    platformsWithDeepSummary && normalizeWimPlatformsWithDeepSummary(platformsWithDeepSummary)
);

export const isPlatformsLoading = (state: RootState): boolean => state.wim.platforms.isPlatformsLoading;

export const isSummaryLoading = (state: RootState): boolean => state.wim.platforms.isSummaryLoading;

export const isPlatformsSummaryLoading = (state: RootState): boolean => state.wim.platforms.isPlatformsSummaryLoading;
