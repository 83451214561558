import React from 'react';

import { Col, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT, RANGES } from 'app-constants/date';
import { OPTION_KEYS_FOR_SELECT } from 'app-constants/select-options';

import { DatePicker, Loader } from 'components';
import { ContentFilterProps } from 'hoc/withFilter';

import wimSelectors from 'store/wim/selectors';
import { useAppSelector } from 'hooks/store-hooks';
import { useWimMonitoringFilter, WimMonitoringMetricFilterValues } from './hooks/use-wim-monitoring-filter';
import { StyledSelect, StyledFilter } from './styles';
import { useGetMonitoringMetrics } from './hooks/use-get-monitoring-metrics';

export const WimMonitoringFilter: React.FC<ContentFilterProps<WimMonitoringMetricFilterValues>> = ({
  filterManager,
}) => {
  const { Option } = Select;
  const wimMonitoringMetrics = useAppSelector(wimSelectors.monitoring.getWimMonitoringMetrics);
  const { t } = useTranslation();
  const { onPlatformNameChange, onMetricChange, onRangePickerChange, state, platforms } =
    useWimMonitoringFilter(filterManager);

  useGetMonitoringMetrics();

  const ranges = {
    [t('date.month')]: RANGES.getMonthRange(),
    [t('date.year')]: RANGES.getYearRange(),
    [t('date.quarter')]: RANGES.getQuarterRange(),
  };

  if (!wimMonitoringMetrics) {
    return <Loader isLoading />;
  }

  return (
    <StyledFilter>
      <Col span={5}>
        <StyledSelect
          maxTagTextLength={20}
          maxTagCount={1}
          value={state.platformUids}
          placeholder={t('filter.allPlatforms')}
          optionFilterProp="children"
          onChange={onPlatformNameChange}
          mode="multiple"
          allowClear
          showSearch
        >
          {platforms?.map((platform) => (
            <Option key={platform.uid} value={platform.uid}>
              {platform.wim}
            </Option>
          ))}
        </StyledSelect>
      </Col>
      <Col span={7} offset={1}>
        <StyledSelect
          maxTagTextLength={20}
          maxTagCount={1}
          value={state?.monitoringMetricId}
          placeholder={`${t('wimsPage.monitoring.statistic.metric')} (${t('glossary.required')})`}
          optionFilterProp="children"
          onChange={onMetricChange}
          aria-required={!state?.monitoringMetricId}
          allowClear
          showSearch
        >
          {wimMonitoringMetrics
            ? wimMonitoringMetrics.map((monitoringMetric) => (
                <Option key={monitoringMetric.codeName} value={monitoringMetric.id.toString()}>
                  {t(`measurementFlags.${monitoringMetric.codeName}` as const)}
                </Option>
              ))
            : null}
        </StyledSelect>
      </Col>
      <Col span={12} offset={1}>
        <DatePicker.RangePicker
          clearIcon={false}
          value={[dayjs(state.start), dayjs(state.end)]}
          showTime={{ format: DATE_FORMAT.RANGE_PICKER_TIME }}
          format={DATE_FORMAT.RANGE_PICKER}
          ranges={ranges as Record<string, [Dayjs | null, Dayjs | null]>}
          onChange={onRangePickerChange}
        />
      </Col>
    </StyledFilter>
  );
};
