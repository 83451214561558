import { useEffect, useState } from 'react';
import { useUrlParams } from './use-url-params';

const DEFAULT_OFFSET = 0;
const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_PAGE_SIZE_RANGE = ['10', '25', '50', '100'];

export type PaginationProps = {
  page: number;
  offset: number;
  pageSize: number;
  onChange?: (page: number, pageSize?: number) => void;
  total?: number;
  showTotal?: (total: number) => string;
  pageSizeOptions?: string[];
};

export const usePagination = ({
  offset = DEFAULT_OFFSET,
  page = DEFAULT_PAGE,
  pageSize = DEFAULT_PAGE_SIZE,
  pageSizeOptions = DEFAULT_PAGE_SIZE_RANGE,
  total,
}: Partial<PaginationProps>) => {
  const [pagination, setPagination] = useState<PaginationProps>({
    page,
    offset,
    pageSize,
    pageSizeOptions,
    total,
  });
  const { queryUrlParams, onPushUrlParams } = useUrlParams({});

  useEffect(() => {
    if (queryUrlParams.page) {
      setPagination((s) => ({
        ...s,
        page: parseInt(queryUrlParams.page, 10),
        pageSize,
        offset: (parseInt(queryUrlParams.page, 10) - 1) * pageSize,
      }));
    }
  }, []);

  useEffect(() => {
    setPagination((s) => ({ ...s, total }));
  }, [total]);

  useEffect(() => {
    onPushUrlParams({ page: pagination.page, total: pagination.total });
  }, [pagination.page, pagination.total]);

  const onChange = (changedPage: number, changedPageSize?: number) => {
    const isNotPrevPageSize = changedPageSize !== pagination.pageSize;

    if (changedPageSize) {
      setPagination((s) => ({
        ...s,
        page: isNotPrevPageSize ? page : changedPage,
        pageSize: changedPageSize,
        offset: isNotPrevPageSize ? offset : (changedPage - 1) * changedPageSize,
      }));
    }
  };

  return { pagination: { ...pagination, onChange } };
};
