import { useSelector } from 'react-redux';

import adminSelectors from 'store/admin/selectors';
import { Group } from 'store/admin/groups/types';
import { useTranslation } from 'react-i18next';

function useWimGroupsState() {
  const platformWimGroupBindings = useSelector(adminSelectors.wimGroups.getResult);
  const { t } = useTranslation();
  const wimGroups = useSelector(adminSelectors.groups.getGroups);
  const isWimGroupsLoading = useSelector(adminSelectors.wimGroups.getGroupsLoading);

  const getGroupsByWim = (wimUid: string): Group[] => {
    if (!platformWimGroupBindings) {
      return [];
    }

    const platformWimGroups = platformWimGroupBindings
      .filter((item) => item.wimUuid === wimUid)
      .map((item) => item.groupId);

    return (
      wimGroups
        ?.filter((group) => platformWimGroups.includes(group.id))
        .map((item) => {
          return { ...item, name: item.name + (item.enabled ? '' : ` ${t('group.disabled')}`) };
        }) || []
    );
  };

  return {
    platformWimGroupBindings,
    getGroupsByWim,
    isWimGroupsLoading,
  };
}

export default useWimGroupsState;
