import { all } from 'redux-saga/effects';

import { wimTransitsSummaryRequestWatchSaga } from './summary/saga';
import { wimTransitsViolationsRequestWatchSaga } from './violations/saga';

function* wimTransitsWatchSaga() {
  yield all([wimTransitsViolationsRequestWatchSaga(), wimTransitsSummaryRequestWatchSaga()]);
}

export default wimTransitsWatchSaga;
