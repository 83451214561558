import React, { FC, useCallback } from 'react';

import { DefaultTableContainer } from 'components';

import { WimMonitoringMetricStatistics } from 'store/wim/monitoring/types';
import { getStatisticTableColumns } from './table-schema';

interface WimMonitoringMetricStatisticsProps {
  onChangeWim: (wim: WimMonitoringMetricStatistics) => void;
  wimsMonitoringMetricStatistics: WimMonitoringMetricStatistics[];
}

export const MonitoringMetricStatistics: FC<WimMonitoringMetricStatisticsProps> = ({
  onChangeWim,
  wimsMonitoringMetricStatistics,
}) => {
  const columns = Object.values(getStatisticTableColumns());

  const onRowHandler = useCallback(
    (record) => {
      return {
        onClick: () => {
          onChangeWim(record);
        },
      };
    },
    [onChangeWim]
  );

  return (
    <DefaultTableContainer
      columns={columns}
      dataSource={wimsMonitoringMetricStatistics}
      showSorterTooltip={false}
      scroll={{ y: 'calc(100vh - 220px)' }}
      size="small"
      onRow={onRowHandler}
    />
  );
};
