import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { adminActions } from 'store/admin/slices';

export const useGetUsers = (): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminActions.users.requestUsers());
  }, []);
};
