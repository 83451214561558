import React from 'react';
import { PageHeaderProps } from 'antd';
import { useTranslation } from 'react-i18next';
import PageInDevTitle from 'elements/PageInDevTitle';
import { StyledAntHeader, StyledNativeHeader } from './styles';

interface HeaderInt extends PageHeaderProps {
  dev?: boolean;
}
const Header: React.FC<HeaderInt> = ({ title, dev, onBack, ...props }) => {
  const { t } = useTranslation();
  const a = (
    <>
      {title}
      {dev && <PageInDevTitle />}
    </>
  );
  return (
    <StyledNativeHeader>
      <StyledAntHeader title={a} onBack={onBack} {...props} />
    </StyledNativeHeader>
  );
};

export default Header;
