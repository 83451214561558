import React from 'react';
import { Card, Radio, RadioChangeEvent, Spin } from 'antd';

import { StyledBlock, StyledTitle } from './styles';

export type TabListItem = {
  key: string;
  tab: string;
  isDisabled: boolean;
};

interface CardWithRadioTabsProps {
  cardTitle: string;
  cardStatistics: string;
  contentList: Record<string, JSX.Element> | null;
  tabList: TabListItem[];
  activeTabKey: string;
  onTabChange: (e: RadioChangeEvent) => void;
  style?: React.CSSProperties;
}

export const CardWithRadioTabs: React.FC<CardWithRadioTabsProps> = ({
  cardTitle,
  cardStatistics,
  contentList,
  tabList,
  activeTabKey,
  onTabChange,
  style,
}) => {
  return (
    <Card
      style={style}
      title={
        <StyledTitle>
          {cardTitle}
          <br />
          {cardStatistics}
          <Radio.Group key="radio-group" value={activeTabKey} onChange={onTabChange}>
            {tabList.map(({ key, tab, isDisabled }) => (
              <Radio.Button key={key} value={key} disabled={isDisabled}>
                {tab}
              </Radio.Button>
            ))}
          </Radio.Group>
        </StyledTitle>
      }
    >
      <Spin spinning={!contentList}>
        <StyledBlock>{contentList && contentList[activeTabKey as keyof typeof contentList]}</StyledBlock>
      </Spin>
    </Card>
  );
};
