/* eslint-disable no-param-reassign */
import {
  SummaryError,
  Wim,
  WimPlatform,
  WimPlatformDeepSummary,
  WimPlatformSummary,
  WimPlatformWithDeepSummary,
} from './types';
import { DeepSummaryError } from './types/platforms';

export type NormalizedSummary = {
  lanes: { byUid: Record<string, WimPlatformSummary> };
  totalErrors: SummaryError[];
  totalPlateRecognitions: NormalizedSummaryPlateRecognition;
};

export type NormalizedWimPlatform = Omit<WimPlatform, 'lanes'> & { lanes: NormalizedWimError };

export interface NormalizedWimPlatforms {
  byUid: Record<string, NormalizedWimPlatform>;
}

export type Lane = {
  direction: string;
  lanesInfo: LaneInfo[];
};
export type LaneInfo = Omit<Wim, 'direction'>;
export interface NormalizedWimError {
  byDirection: {
    [key in string]: Lane;
  };
}

export interface NormalizedSummaryPlateRecognition {
  byDate: {
    [key in string]: {
      recognized: number;
      unrecognized: number;
      date: number;
    };
  };
}

export interface NormalizedWimPlatformLanesDeepSummary {
  byDirection: {
    [direction: string]: Omit<WimPlatformDeepSummary, 'direction'>[];
  };
  totalErrors: SummaryError[];
  totalPlateRecognitions: NormalizedSummaryPlateRecognition;
}
export type NormalizedWimPlatformWithDeepSummary = Omit<WimPlatformWithDeepSummary, 'lanes'> & {
  lanes: NormalizedWimPlatformLanesDeepSummary;
};

export const normalizeWimSummary = (state: WimPlatformSummary[]) => prepareWimSummary(state);

export const normalizeWimPlatform = (state: WimPlatform[]) => prepareWimPlatform(state);

export const normalizeWimPlatformsWithDeepSummary = (state: WimPlatformWithDeepSummary[]) =>
  prepareWimPlatformsWithDeepSummary(state);

export function normalizeArrayByDates<ArrayType>(state: ArrayType[], value: string) {
  return prepareArrayByDates(state, value);
}

function prepareWimSummary(summary: WimPlatformSummary[]) {
  return summary.reduce((acc, currentSummary) => {
    if (!acc.lanes) {
      acc.lanes = { byUid: {} };
      acc.totalPlateRecognitions = { byDate: {} };
      acc.totalErrors = [];
    }

    acc.lanes.byUid[currentSummary.uid] = currentSummary;

    if (currentSummary.errors?.length) {
      acc.totalErrors = acc.totalErrors.concat(currentSummary.errors);
    }

    if (currentSummary.plateRecognitions?.length) {
      currentSummary.plateRecognitions.forEach((plate) => {
        const { date, recognized, unrecognized } = plate;
        let totalRecognized = recognized;
        let totalUnrecognized = unrecognized;

        if (acc.totalPlateRecognitions.byDate[date]) {
          if (acc.totalPlateRecognitions.byDate[date].recognized) {
            totalRecognized = acc.totalPlateRecognitions.byDate[date].recognized + recognized;
          }

          if (acc.totalPlateRecognitions.byDate[date].unrecognized) {
            totalUnrecognized = acc.totalPlateRecognitions.byDate[date].unrecognized + unrecognized;
          }
        }

        acc.totalPlateRecognitions.byDate[date] = {
          recognized: totalRecognized,
          unrecognized: totalUnrecognized,
          date,
        };
      });
    }

    return acc;
  }, {} as NormalizedSummary);
}

function prepareWimPlatform(platforms: WimPlatform[]) {
  return platforms.reduce((platfromsAcc, currentPlatform) => {
    if (!platfromsAcc.byUid) {
      platfromsAcc.byUid = {};
    }

    platfromsAcc.byUid[currentPlatform.uid] = {
      ...currentPlatform,
      lanes: currentPlatform.lanes.reduce((wimAcc, currentWim) => {
        const { direction, ...restWim } = currentWim;

        if (!wimAcc.byDirection) {
          wimAcc.byDirection = {};
        }

        if (!wimAcc.byDirection[direction]) {
          wimAcc.byDirection[direction] = {} as Lane;
        }

        if (!wimAcc.byDirection[direction].lanesInfo) {
          wimAcc.byDirection[direction].lanesInfo = [];
        }

        wimAcc.byDirection[direction].direction = direction;
        wimAcc.byDirection[direction].lanesInfo.push({ ...restWim });

        return wimAcc;
      }, {} as NormalizedWimError),
    };

    return platfromsAcc;
  }, {} as NormalizedWimPlatforms);
}

function prepareArrayByDates<ElementType extends Record<string, any>>(array: ElementType[], value: string) {
  return array.reduce<{ byDate: Record<string, ElementType[]> }>(
    (acc, currentValue) => {
      if (!acc.byDate[currentValue.date] && currentValue[value]) {
        acc.byDate[currentValue.date] = [];
      }

      if (currentValue[value]) {
        acc.byDate[currentValue.date].push(currentValue);
      }

      return acc;
    },
    { byDate: {} }
  );
}

function prepareWimPlatformsWithDeepSummary(
  platformsSummary: WimPlatformWithDeepSummary[]
): NormalizedWimPlatformWithDeepSummary[] {
  return platformsSummary.reduce<NormalizedWimPlatformWithDeepSummary[]>((acc, platformWithDeepSummary) => {
    const newPlatformLanes: NormalizedWimPlatformLanesDeepSummary = {
      byDirection: {},
      totalErrors: [],
      totalPlateRecognitions: { byDate: {} },
    };

    platformWithDeepSummary.lanes.forEach((lane) => {
      const { direction, ...restLane } = lane;

      if (!newPlatformLanes.byDirection[direction]) {
        newPlatformLanes.byDirection[direction] = [];
      }

      newPlatformLanes.byDirection[direction].push(restLane);

      if (restLane.errors.length) {
        restLane.errors.forEach((laneError) => {
          if (laneError.error) {
            newPlatformLanes.totalErrors.push({ ...laneError, error: Number.parseFloat(laneError.error) });
          }
        });
      }

      if (restLane.plateRecognitions?.length) {
        restLane.plateRecognitions.forEach((plate) => {
          const { date, recognized, unrecognized } = plate;
          let totalRecognized = Number.parseInt(recognized, 10);
          let totalUnrecognized = Number.parseInt(unrecognized, 10);

          if (newPlatformLanes.totalPlateRecognitions.byDate[date]) {
            if (newPlatformLanes.totalPlateRecognitions.byDate[date].recognized) {
              totalRecognized =
                newPlatformLanes.totalPlateRecognitions.byDate[date].recognized + Number.parseInt(recognized, 10);
            }

            if (newPlatformLanes.totalPlateRecognitions.byDate[date].unrecognized) {
              totalUnrecognized =
                newPlatformLanes.totalPlateRecognitions.byDate[date].unrecognized + Number.parseInt(unrecognized, 10);
            }
          }

          newPlatformLanes.totalPlateRecognitions.byDate[date] = {
            recognized: totalRecognized,
            unrecognized: totalUnrecognized,
            date,
          };
        });
      }
    });

    acc.push({ ...platformWithDeepSummary, lanes: newPlatformLanes });

    return acc;
  }, []);
}
