import { call, put, takeEvery } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import { MediaNode } from '../types';

import { requestNodes, getNodesSuccess, getNodesError } from './slice';
import { getMediaClusterStatus } from '../../../api/system';

export function* mediaNodesRequestSaga() {
  try {
    const response: AxiosResponse<MediaNode[]> = yield call(getMediaClusterStatus, {});
    if (response) {
      yield put(getNodesSuccess({ nodes: response.data }));
    }
  } catch (error) {
    yield put(getNodesError(error.message));
  }
}

export function* mediaNodesRequestWatchSaga() {
  yield takeEvery(requestNodes, mediaNodesRequestSaga);
}
