import React from 'react';
import { Redirect } from 'react-router-dom';
import { ROUTES } from 'app-constants/routes';

interface AccessControlProps {
  acl: string[];
  permissions?: string[];
  fallback?: React.ReactNode;
  redirect?: boolean | string;
}

export const AccessControl: React.FC<AccessControlProps> = ({
  children,
  acl,
  permissions,
  fallback,
  redirect,
  ...props
}) => {
  // TODO: change this check when back-end permission will be ready
  if (permissions?.length && permissions.some((p) => acl.includes(p))) {
    if (React.isValidElement(children)) {
      return React.cloneElement(children, props);
    }
    return <>{children}</>;
  }

  if (fallback) {
    return <>{fallback}</>;
  }

  if (redirect) {
    return <Redirect push to={typeof redirect === 'boolean' ? ROUTES.PermissionDenied : redirect} />;
  }

  return null;
};
