import { put, takeEvery } from 'redux-saga/effects';
import makeRequestSaga from 'store/common';
import { getVrpDetectStatistic } from 'api/statistics';
import { SagaPayload } from 'types';
import { GetStatisticQueryParamsType } from '../types';
import {
  requestVrpDetectStatistic,
  getVrpDetectStatisticSuccess,
  getVrpDetectStatisticError,
  setIsLoading,
} from './slice';

export function* vrpDetectStatisticRequestSaga({ payload }: SagaPayload<GetStatisticQueryParamsType>) {
  yield put(setIsLoading(true));
  yield makeRequestSaga(getVrpDetectStatisticSuccess, getVrpDetectStatisticError, getVrpDetectStatistic, payload);
}

export function* vrpDetectStatisticRequestWatchSaga() {
  yield takeEvery(requestVrpDetectStatistic, vrpDetectStatisticRequestSaga);
}
