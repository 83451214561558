export type NotificationType = 'success' | 'info' | 'warning' | 'error';

export enum MessageTitle {
  // TODO: add new message title and locales variables
  createGroupSuccess = 'notification.createGroupSuccess',
  createGroupError = 'notification.createGroupError',
  crudSuccess = 'notification.crudSuccess',
  crudError = 'notification.crudError',
}
export interface InitialState {
  data: Array<NotificationMessageData>;
}

export interface Notification {
  title: MessageTitle;
  message?: string;
}

export interface NotificationMessageData {
  type: NotificationType;
  title: MessageTitle;
  message?: string;
}
