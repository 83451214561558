import { RootState } from 'store/root-reducer';
import { WimMonitoringMetric, WimMonitoringMetricStatistics, WimMonitoringMetricStatisticsByTime } from './types';

export const getWimMonitoringMetrics = (state: RootState): WimMonitoringMetric[] | null =>
  state.wim.monitoring.wimMonitoringMetrics;

export const isWimMonitoringMetricsLoading = (state: RootState): boolean =>
  state.wim.monitoring.isWimMonitoringMetricsLoading;

export const getWimsMonitoringMetricStatistics = (state: RootState): WimMonitoringMetricStatistics[] | null =>
  state.wim.monitoring.wimsMonitoringMetricStatistics;

export const isWimsMonitoringMetricStatisticsLoading = (state: RootState): boolean =>
  state.wim.monitoring.isWimMonitoringMetricStatisticsLoading;

export const getWimMonitoringMetricStatisticsByTime = (state: RootState): WimMonitoringMetricStatisticsByTime | null =>
  state.wim.monitoring.wimMonitoringMetricStatisticsByTime;

export const isWimMonitoringMetricStatisticsByTimeLoading = (state: RootState): boolean =>
  state.wim.monitoring.isWimMonitoringMetricStatisticsByTimeLoading;
