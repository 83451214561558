import { useEffect, useState } from 'react';
import { ExternalNotification } from '../../../store/system/types';

export type QueryUrl = {
  defaultQuery: string | undefined;
};

type UpdateStateHandler = (value: Partial<NotificationsPageState>) => void;

interface NotificationsPageState {
  notifications: ExternalNotification[] | null;
  notification: ExternalNotification | null;
}

function getSelected(
  notifications: ExternalNotification[] | null,
  first: ExternalNotification | null,
  previouslySelected: ExternalNotification | null,
  queryId?: string
): ExternalNotification | null {
  if (queryId && notifications) {
    return notifications.find((node) => node.id.toString() === queryId) || null;
  }
  if (!previouslySelected && first) {
    return first;
  }

  return previouslySelected;
}

const useNotificationsPageState = (items: ExternalNotification[] | null, queryUrlParams: Record<string, any>) => {
  const [notificationsPageState, setNotificationsPageState] = useState<NotificationsPageState>({
    notifications: null,
    notification: null,
  });

  useEffect(() => {
    if (items) {
      const copy = [...items];
      const defaultSorted = copy;

      const first = defaultSorted[0];
      setNotificationsPageState((prevState) => ({
        notifications: defaultSorted,
        notification: getSelected(defaultSorted, first, prevState.notification, queryUrlParams.notification),
      }));
    }
  }, [items]);

  const handleUpdatePageState: UpdateStateHandler = (newState) => {
    setNotificationsPageState({ ...notificationsPageState, ...newState });
  };

  return {
    notificationsPageState,
    handleUpdatePageState,
  };
};

export { useNotificationsPageState };
