import React, { useEffect } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { DefaultTableContainer, ModalWrapper } from 'components';
import { stringifyUrlParams, useUrlParams } from 'hooks/use-url-params';
import { useAppDispatch } from 'hooks/store-hooks';
import { Modal } from 'types/modal';
import { WimPlatform } from 'store/wim/types';
import { useSelector } from 'react-redux';
import { StyledEditLink, StyledContent } from './styles';
import { UpdateContent } from './modal/UpdateContent';
import { wimsColumns } from './table-schema';
import { UpdateWimValues } from './modal/UpdateContent/types';
import { normalizeWimsEmptyValues } from '../../../../../store/admin/normalization';
import { adminActions } from '../../../../../store/admin/slices';
import { wimActions } from '../../../../../store/wim/slices';
import adminSelectors from '../../../../../store/admin/selectors';

interface WimsSummaryProps {
  selectedPlatformId: WimPlatform['uid'];
  lanes: WimPlatform['lanes'];
  isLoading: boolean;
  isEditable?: boolean;
}

const WimsSummary: React.FC<WimsSummaryProps> = ({ lanes, selectedPlatformId, isLoading, isEditable }) => {
  const { queryUrlParams } = useUrlParams({});
  const dispatch = useAppDispatch();
  const {
    location: { pathname, search },
    goBack,
  } = useHistory();
  const { t } = useTranslation();
  const putLanesResult = useSelector(adminSelectors.lanes.getResult);
  const onSubmitForm = (values: Partial<UpdateWimValues>): void => {
    const normalizedValues = normalizeWimsEmptyValues(values);
    if (Object.keys(values).length > 0) {
      dispatch(adminActions.lanes.requestUpdateLanes({ lanes: normalizedValues, platformUid: selectedPlatformId }));
    }
    goBack();
  };
  useEffect(() => {
    if (putLanesResult) {
      dispatch(wimActions.platforms.requestPlatforms());
    }
    dispatch(adminActions.lanes.clearMessages());
  }, [putLanesResult]);
  return (
    <StyledContent>
      <StyledEditLink>
        <Link
          to={{
            pathname,
            search: stringifyUrlParams({ ...queryUrlParams, modal: Modal.EDIT_WIMS, platform: selectedPlatformId }),
          }}
        >
          <EditOutlined style={{ fontSize: 20 }} />
        </Link>
      </StyledEditLink>
      <DefaultTableContainer columns={wimsColumns} dataSource={lanes} bordered />
      {isEditable && (
        <ModalWrapper
          isVisible={search.includes(`modal=${Modal.EDIT_WIMS}`)}
          onClose={goBack}
          title={t('adminWimsPage.updateWims')}
          width={900}
          destroyOnClose
        >
          <UpdateContent wims={lanes} onSubmitForm={onSubmitForm} isLoading={isLoading} />
        </ModalWrapper>
      )}
    </StyledContent>
  );
};

export { WimsSummary };
