import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components/macro';

interface LoaderProps {
  isLoading: boolean;
}

// todo: move into styles.ts
const StyledSpin = styled(Spin)`
  position: relative;
  margin: calc(33% - 16px) calc(50% - 16px);
`;

const Loader: React.FC<LoaderProps> = ({ isLoading, children }) =>
  isLoading ? <StyledSpin spinning size="large" /> : <div>{children}</div>;

export default Loader;
