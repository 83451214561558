import { TimePeriod } from 'types/date-period';

export type LaneDirectionType = 'DIRECT' | 'OPPOSITE';

export type Wim = {
  uid: string;
  laneType: string;
  laneDescription: string;
  laneDirection: string;
  direction: string;
  laneIndex: number;
  laneOrigin: string;
  laneDestination: string;
};

export type MapPosition = {
  type: string;
  coordinates: [number, number];
};

export type VerificationCertificate = {
  number: string;
  expirationDate: number;
};

export type WimPlatformUid = string & { readonly brand: unique symbol };

export type WimPlatformDeviceId = number & { readonly brand: unique symbol };

export interface WimPlatform {
  uid: WimPlatformUid;
  mapPosition: MapPosition;
  lanes: Wim[];
  verificationCertificate: VerificationCertificate;
  wim: string;
  alias: string;
  description: string;
  address: string;
  serialNumber: string;
  road: string;
  region: string;
  provider: string;
  status: string;
  measureStatus: string;
  lastTransitDatetime: number | null;
  measureValidity?: number;
  mediaValidity?: number;
  plateRecognition?: number;
  effectiveDate?: string;
}

export interface SummaryError {
  date: number;
  error?: number;
  prediction?: number;
}
export interface SummaryViolation {
  date: number;
  axle: number;
  both: number;
  full: number;
}

export interface SummaryPlateRecognition {
  recognized: number;
  unrecognized: number;
  date: number;
}

export interface WimPlatformSummary {
  uid: string;
  errors: SummaryError[];
  violations?: SummaryViolation[];
  plateRecognitions?: SummaryPlateRecognition[];
  lastTransitDatetime: number | null;
}

export interface DeepSummaryError {
  date: number;
  error?: string;
  [x: string]: any;
}

export interface DeepSummaryPlateRecognition {
  recognized: string;
  unrecognized: string;
  date: number;
}

export interface DeepSummaryViolation {
  date: number | string;
  axle: string;
  both: string;
  full: string;
}

export interface WimPlatformDeepSummary {
  uid: string;
  direction: string;
  laneDescription: string;
  laneType: string;
  errors: DeepSummaryError[];
  violations?: DeepSummaryViolation[];
  plateRecognitions?: DeepSummaryPlateRecognition[];
  lastTransitDatetime: number | null;
}

export type WimPlatformWithDeepSummary = Omit<WimPlatform, 'lanes'> & { lanes: WimPlatformDeepSummary[] };

export enum SummaryTypes {
  ERRORS = 'errors',
  VIOLATIONS = 'violations',
  PLATE_RECOGNITIONS = 'plateRecognitions',
  PLATE_RECOGNITIONS_HOURLY = 'plateRecognitionsHourly',
  LAST_TRANSITS_DATES = 'lastTransitsDates',
}

export type GetWimSummaryQueryParams = Partial<TimePeriod> & {
  wimUid?: WimPlatformUid;
  type?: Array<SummaryTypes>;
  lastTransit?: string;
};

export type GetWimPlatformsSummaryQueryParams = TimePeriod;

export interface GetWimPlatformSummaryViolationsQueryParams {
  wimUid?: WimPlatformUid;
  laneUid?: string;
  date?: number;
}

export interface UpdateWimPlatformFormData {
  mapPosition?: MapPosition;
  verificationCertificate?: VerificationCertificate;
  wim?: string;
  alias?: string;
  address?: string;
  serialNumber?: string;
}

export interface UpdateWimPlatformData extends UpdateWimPlatformFormData {
  uid: WimPlatformUid;
}

export interface UpdateWimsData {
  platformUid: WimPlatformUid;
  lanes: Wim[];
}

export interface GetWimLanesData {
  platformUid: WimPlatformUid;
}
