import { combineReducers } from '@reduxjs/toolkit';

import * as usersActions from './users/slice';
import usersSlice from './users/slice';
import * as platformsActions from './platforms/slice';
import platformsSlice from './platforms/slice';
import lanesSlices from './platforms/wimLanes/slice';
import * as lanesActions from './platforms/wimLanes/slice';
import * as groupsActions from './groups/slice';
import groupsSlice from './groups/slice';
import * as wimGroupsActions from './platforms/wimGroups/slice';
import wimGroupsSlice from './platforms/wimGroups/slice';
import * as systemSettingsActions from './settings/slice';
import systemSettingsSlice from './settings/slice';
import * as certificatesActions from './certificates/slice';
import certificatesSlice from './certificates/slice';
import * as notificationsActions from './notifications/slice';
import notificationsSlice from './notifications/slice';

export const adminSlices = combineReducers({
  users: usersSlice,
  platforms: platformsSlice,
  lanes: lanesSlices,
  groups: groupsSlice,
  wimGroups: wimGroupsSlice,
  systemSettings: systemSettingsSlice,
  certificates: certificatesSlice,
  notifications: notificationsSlice,
});

export const adminActions = {
  users: { ...usersActions },
  platforms: { ...platformsActions },
  lanes: { ...lanesActions },
  groups: { ...groupsActions },
  wimGroups: { ...wimGroupsActions },
  systemSettings: { ...systemSettingsActions },
  certificates: { ...certificatesActions },
  notifications: { ...notificationsActions },
};
