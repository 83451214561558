import { put, takeEvery } from 'redux-saga/effects';
import makeRequestSaga from 'store/common';

import { SagaPayload } from 'types';
import { getIntrudersList } from 'api/statistics';
import { requestIntrudersList, getIntrudersListSuccess, getIntrudersListError, setIsLoading } from './slice';
import { GetStatisticQueryParamsType } from '../types';

export function* intrudersListRequestSaga({ payload }: SagaPayload<GetStatisticQueryParamsType>) {
  yield put(setIsLoading(true));
  yield makeRequestSaga(getIntrudersListSuccess, getIntrudersListError, getIntrudersList, payload);
}

export function* intrudersListRequestWatchSaga() {
  yield takeEvery(requestIntrudersList, intrudersListRequestSaga);
}
