import React, { FC } from 'react';

import { ComposedChart, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Bar, Scatter } from 'recharts';
import dayjs from 'dayjs';

import { LaneTransit } from 'store/wim/types/transits';
import { TimePeriod } from 'types/date-period';
import { parseByChart, setDateRange } from './helpers/get-bars-from-transits-violations-by-lane';
import { BarChartTransitsViolationsByWimTooltip } from './Tooltips';
import { StyledBarChartTransitsByWim } from './styles';
import palette from '../../palette';

type BarChartTransitsByWimProps = Partial<TimePeriod> & {
  data: LaneTransit;
};

export const BarChartTransitsViolationsByLane: FC<BarChartTransitsByWimProps> = ({ data, start, end }) => {
  const { format, step } = setDateRange(start, end);
  const lanesWithViolationsSortedByTime = parseByChart(data, step, start, end);

  if (!lanesWithViolationsSortedByTime) {
    return null;
  }

  const dateFormatter = (date: number) => dayjs(date).format(format);

  return (
    <StyledBarChartTransitsByWim>
      <ResponsiveContainer height="100%" width="100%">
        <ComposedChart
          data={lanesWithViolationsSortedByTime}
          margin={{
            top: 20,
          }}
          barCategoryGap="10%"
        >
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis dataKey="date" tickFormatter={dateFormatter} interval="preserveStart" />

          <YAxis />
          <Tooltip content={<BarChartTransitsViolationsByWimTooltip />} />
          <React.Fragment key="bar-fragment-">
            <Bar dataKey="axle" stackId="violation" fill={palette.orange.o2} background={false} name="axle" />
            <Bar dataKey="both" stackId="violation" fill={palette.green.g5} background={false} name="both" />
            <Bar dataKey="full" stackId="violation" fill={palette.blue.b5} background={false} name="full" />
          </React.Fragment>
          <Scatter dataKey="total" fill={palette.red.r1} />
        </ComposedChart>
      </ResponsiveContainer>
    </StyledBarChartTransitsByWim>
  );
};
