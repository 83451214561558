import styled from 'styled-components/macro';

import SFUITextSemibold from 'fonts/SFUIText-Semibold.woff';
import palette from 'palette';

export const StyledTooltip = styled.div`
  @font-face {
    font-family: 'SFUITextSemibold';
    src: url(${SFUITextSemibold}) format('woff');
  }
  display: flex;
  flex-direction: column;
  width: max-content;
  height: 100%;
  padding: 5px;
  font-size: 14px;
  background: white;
  border-radius: 3px;
  box-shadow: rgb(0 0 0 / 25%) 0px 0px 10px;
  transform: translateY(-75%);
`;

export const StyledTooltipTitle = styled.div`
  font-family: 'SFUITextSemibold';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 5px 10px;
  height: fit-content;
  border-bottom: 1px solid ${palette.grey.g5};
`;

export const StyledItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`;

export const StyledLabel = styled.span`
  margin: 0 5px 0 0;
`;

export const StyledValue = styled.span`
  font-family: 'SFUITextSemibold';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  order: 30;
`;
