import React, { FC } from 'react';
import { Resizable, ResizeCallbackData } from 'react-resizable';

interface ResizableTitleProps {
  width: number;
  onResize: (e: React.SyntheticEvent<Element, Event>, resizeData: ResizeCallbackData) => void;
}

export const ResizableTableTitle: FC<ResizableTitleProps> = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          role="row"
          tabIndex={0}
          onKeyDown={() => {}}
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};
