import React, { ReactNode } from 'react';
import { TransitInfoItemContainer, Title, TextValue } from './styles';

interface TransitInfoItemProps {
  title: ReactNode | string;
  value: React.ReactNode;
  details?: React.ReactNode | boolean;
  isError?: boolean;
  vertical?: boolean;
  wide?: boolean;
  isLeftAlign?: boolean;
  centerValue?: boolean;
}
export const TransitInfoItem: React.FC<TransitInfoItemProps> = ({
  title,
  value,
  details = false,
  isError = false,
  vertical = false,
  wide = false,
  isLeftAlign = true,
  centerValue = false,
}) => {
  return (
    <TransitInfoItemContainer wide={wide} vertical={vertical} isLeftAlign={isLeftAlign}>
      <Title>
        {title}
        {details ? <> ({details})</> : null}:{' '}
      </Title>
      <TextValue wide={wide} isError={isError} centerValue={centerValue}>
        {value}
      </TextValue>
    </TransitInfoItemContainer>
  );
};
