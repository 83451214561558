import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuProps, Menu as ANTMenu } from 'antd';
import { StyledDiv } from './styles';

const { SubMenu } = ANTMenu;

const LanguageSwitcher: React.FC = () => {
  const { t, i18n } = useTranslation();
  const languages = [
    { value: 'en', name: 'English' },
    { value: 'ua', name: 'Українська' },
  ];
  const handleMenuClick: MenuProps['onClick'] = async (e) => {
    localStorage.setItem('i18nextLng', JSON.stringify(e.key));

    i18n.changeLanguage(e.key);
    window.location.reload();
  };

  return (
    <StyledDiv>
      <ANTMenu theme="dark" defaultSelectedKeys={['/']} mode="inline">
        <SubMenu key="lang-menu" title={t('common.language')}>
          {languages &&
            languages.map((lang) => {
              return (
                <Menu.Item key={lang.value} onClick={handleMenuClick}>
                  {lang.name}
                </Menu.Item>
              );
            })}
        </SubMenu>
      </ANTMenu>
    </StyledDiv>
  );
};
export default LanguageSwitcher;
