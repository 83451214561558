export const UAD_WIM_STATUSES = [
  {
    value: 'штрафує',
    label: 'штрафує',
  },
  {
    value: 'пошкоджений',
    label: 'пошкоджений',
  },
  {
    value: 'ЗБД',
    label: 'ЗБД',
  },
  {
    value: 'окупація',
    label: 'окупація',
  },
  {
    value: 'підключення',
    label: 'підключення',
  },
  {
    value: 'баланс',
    label: 'баланс',
  },
  {
    value: 'зупинено будівництво',
    label: 'зупинено будівництво',
  },
  {
    value: 'сертифікація',
    label: 'сертифікація',
  },
  {
    value: 'тендер скасовано',
    label: 'тендер скасовано',
  },
];

export const UAD_REGIONS = [
  { value: 'Вінницька', label: 'Вінницька' },
  { value: 'Волинська', label: 'Волинська' },
  { value: 'Дніпропетровська', label: 'Дніпропетровська' },
  { value: 'Донецька', label: 'Донецька' },
  { value: 'Житомирська', label: 'Житомирська' },
  { value: 'Закарпатська', label: 'Закарпатська' },
  { value: 'Запорізька', label: 'Запорізька' },
  { value: 'Івано-Франківська', label: 'Івано-Франківська' },
  { value: 'Київська', label: 'Київська' },
  { value: 'Кіровоградська', label: 'Кіровоградська' },
  { value: 'Луганська', label: 'Луганська' },
  { value: 'Львівська', label: 'Львівська' },
  { value: 'Миколаївська', label: 'Миколаївська' },
  { value: 'Одеська', label: 'Одеська' },
  { value: 'Полтавська', label: 'Полтавська' },
  { value: 'Рівненська', label: 'Рівненська' },
  { value: 'Сумська', label: 'Сумська' },
  { value: 'Тернопільська', label: 'Тернопільська' },
  { value: 'Харківська', label: 'Харківська' },
  { value: 'Херсонська', label: 'Херсонська' },
  { value: 'Хмельницька', label: 'Хмельницька' },
  { value: 'Черкаська', label: 'Черкаська' },
  { value: 'Чернівецька', label: 'Чернівецька' },
  { value: 'Чернігівська', label: 'Чернігівська' },
];

export const UAD_LANES = [
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
];
