import React, { useEffect, useState } from 'react';
import { AimOutlined, EditOutlined, FileImageOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ModalWrapper } from 'components';
import { stringifyUrlParams, useUrlParams } from 'hooks/use-url-params';
import { Modal } from 'types/modal';
import { WimPlatform } from 'store/wim/types';
import { useDispatch, useSelector } from 'react-redux';
import { Result, Tooltip } from 'antd';
import adminSelectors from 'store/admin/selectors';
import { StyledEditLink, StyledContent } from './styles';
import { UpdateContent } from './modal/UpdateContent';
import { InfoContent } from './components/InfoContent';
import { PlatformState, UpdatePlatformMedia, UpdatePlatformValues } from './types';
import { adminActions } from '../../../../../store/admin/slices';
import { wimActions } from '../../../../../store/wim/slices';
import { normalizePlatformEmptyValues } from '../../../../../store/admin/normalization';
import { UploadMedia } from './modal/UploadMedia';
import { ROUTES } from '../../../../../app-constants/routes';

interface PlatformSummaryProps {
  state: WimPlatform;
  isLoading: boolean;
  isEditable?: boolean;
}

const PlatformSummary: React.FC<PlatformSummaryProps> = ({ state, isLoading, isEditable }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState<File>();
  const { queryUrlParams } = useUrlParams({});
  const {
    location: { pathname, search },
    goBack,
  } = useHistory();
  const { t } = useTranslation();
  const putPlatformError = useSelector(adminSelectors.platforms.getErrors);
  const putPlatformResult = useSelector(adminSelectors.platforms.getResult);

  function makeNewState() {
    console.log('PlatformSummary.index - newState state: ', state);
    return {
      ...state,
      mapPosition: state.mapPosition.coordinates.join(', '),
      verificationCertificate: state.verificationCertificate,
    };
  }

  const newState: PlatformState = makeNewState();

  const onSubmitEditPlatform = (values: Partial<UpdatePlatformValues>): void => {
    const normalizedValues = normalizePlatformEmptyValues(values);
    console.log(normalizedValues);
    console.log('PlatformSummary.index - onSubmitEditPlatform called with values: ', values);
    if (Object.keys(values).length > 0) {
      dispatch(adminActions.platforms.clearMessages());
      dispatch(adminActions.platforms.requestUpdatePlatform({ ...normalizedValues, uid: state.uid }));
    }
    goBack();
  };

  const onSubmitUploadMedia = (values: Omit<UpdatePlatformMedia, 'uid'>) => {
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      const base64 = reader.result as string;
      dispatch(adminActions.platforms.requestUploadPlatformMedia({ uid: state.uid, file: base64, type: values.type }));
      goBack();
    };
  };

  useEffect(() => {
    dispatch(adminActions.platforms.clearMessages());
  }, [dispatch, state.uid]);

  useEffect(() => {
    if (putPlatformResult) {
      dispatch(wimActions.platforms.requestPlatforms());
    }
  }, [dispatch, putPlatformResult]);
  return (
    <StyledContent>
      <StyledEditLink>
        <Tooltip placement="right" title={t('adminWimsPage.viewPlatformDetails')}>
          <Link
            to={{
              pathname: `${ROUTES.Wim}/${state.uid}`,
              state: { fromPage: { pathname, search } },
            }}
          >
            <AimOutlined style={{ fontSize: 20 }} />
          </Link>
        </Tooltip>
        <Tooltip placement="right" title={t('adminWimsPage.updatePlatformMainInfo')}>
          <Link
            to={{
              pathname,
              search: stringifyUrlParams({ ...queryUrlParams, modal: Modal.EDIT_PLATFORM, platform: state.uid }),
            }}
          >
            <EditOutlined style={{ fontSize: 20 }} />
          </Link>
        </Tooltip>
        <Tooltip placement="right" title={t('adminWimsPage.uploadMediaPlatformInfo')}>
          <Link
            to={{
              pathname,
              search: stringifyUrlParams({
                ...queryUrlParams,
                modal: Modal.UPLOAD_PLATFORM_MEDIA,
                platform: state.uid,
              }),
            }}
          >
            <FileImageOutlined style={{ fontSize: 20 }} />
          </Link>
        </Tooltip>
      </StyledEditLink>
      <InfoContent state={newState} />
      {putPlatformError && (
        <Result style={{ border: '1px solid red' }} status="warning" title={putPlatformError.message} />
      )}
      {isEditable && (
        <>
          <ModalWrapper
            isVisible={search.includes(`modal=${Modal.EDIT_PLATFORM}`)}
            onClose={goBack}
            title={t('adminWimsPage.updatePlatformMainInfo')}
            width={900}
            destroyOnClose
          >
            <UpdateContent state={newState} onSubmitForm={onSubmitEditPlatform} isLoading={isLoading} />
          </ModalWrapper>
          <ModalWrapper
            isVisible={search.includes(`modal=${Modal.UPLOAD_PLATFORM_MEDIA}`)}
            onClose={() => {
              setFile(undefined);
              goBack();
            }}
            title={t('adminWimsPage.updatePlatformMedia')}
            width={900}
            destroyOnClose
          >
            <UploadMedia
              onSubmitForm={onSubmitUploadMedia}
              isLoading={isLoading}
              setFile={setFile}
              fileName={file?.name}
            />
          </ModalWrapper>
        </>
      )}
    </StyledContent>
  );
};

export { PlatformSummary };
