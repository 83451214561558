import React, { useEffect, useState } from 'react';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Dayjs } from 'dayjs';

import { DATE_FORMAT } from 'app-constants/date';

import { StyledSingleDatePicker } from './styles';

interface SingleDatePickerProps {
  currentDate: Dayjs;
  setDateHandler: (date: Dayjs) => void;
}

const SingleDatePicker: React.FC<SingleDatePickerProps> = ({ currentDate, setDateHandler }) => {
  const [date, setDate] = useState(currentDate);

  useEffect(() => {
    setDateHandler(date);
  }, [date]);

  return (
    <StyledSingleDatePicker>
      <Button type="text" onClick={() => setDate(date.subtract(1, 'day'))}>
        <LeftOutlined />
      </Button>
      <span>{currentDate.format(DATE_FORMAT.FULLDATE_NO_TIME)}</span>
      <Button type="text" onClick={() => setDate(date.add(1, 'day'))}>
        <RightOutlined />
      </Button>
    </StyledSingleDatePicker>
  );
};

export default SingleDatePicker;
