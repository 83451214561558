// FIXME: Remove interface after implementation on BE
export interface CameraInfo {
  direction: string;
  alias: string;
}

const wimCamerasMapper = [
  {
    wim: 'PI', // WIM 7
    cameras: [
      {
        direction: 'Підгорівка-Кременчуг',
        alias: 'wim-7',
      },
      {
        direction: 'Підгорівка-Полтава',
        alias: 'wim-7-2',
      },
    ],
  },
  {
    wim: 'MS', // WIM 19
    cameras: [
      {
        direction: 'Мангуш-Маріуполь',
        alias: 'wim-19',
      },
      {
        direction: 'Мангуш-Одеса',
        alias: 'wim-19-2',
      },
    ],
  },
  {
    wim: 'KO', // WIM 16
    cameras: [
      {
        direction: 'Комишуваха-Маріуполь',
        alias: 'wim-16',
      },
      {
        direction: 'Комишуваха-Запоріжжя',
        alias: 'wim-16-2',
      },
    ],
  },
  {
    wim: 'LU', // WIM 15
    cameras: [
      {
        direction: 'Лукашево-Запоріжжя',
        alias: 'wim-15',
      },
      {
        direction: 'Лукашево-Дніпро',
        alias: 'wim-15-2',
      },
    ],
  },
  {
    wim: 'GA', // WIM 8
    cameras: [
      {
        direction: 'Галагурівка-Дніпро',
        alias: 'wim-8',
      },
      {
        direction: 'Галагурівка-Решетилівка',
        alias: 'wim-8-2',
      },
    ],
  },
  {
    wim: 'NE', // WIM 20
    cameras: [
      {
        direction: 'Знам’янка-Ізварине',
        alias: 'wim-20',
      },
      {
        direction: 'Знам’янка-Ізварине',
        alias: 'wim-20-2',
      },
    ],
  },
];

export { wimCamerasMapper };
