import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';

import { ApiRequest } from 'types';

function* makeRequestSaga<P, E>(
  successActionCreator: ActionCreatorWithPayload<P, string>,
  failureActionCreator: ActionCreatorWithPayload<E, string>,
  apiFn: ApiRequest,
  apiFnParams = {}
): Generator<CallEffect<AxiosResponse> | PutEffect<{ payload: P | E; type: string }>, void, AxiosResponse> {
  try {
    const response = yield call(apiFn, apiFnParams);

    if (response) {
      yield put(successActionCreator(response.data));
    } else {
      yield put(failureActionCreator(JSON.parse(response)));
    }
  } catch (error: any) {
    yield put(failureActionCreator(error?.message || 'error'));
  }
}

export default makeRequestSaga;
