import palette from 'palette';
import styled from 'styled-components/macro';

export const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const StyledSummary = styled.div`
  padding: 24px;
  margin: 24px;
  height: calc(100% - 48px);
  background-color: ${palette.white.w1};
`;
