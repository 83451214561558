import React from 'react';
import { EditOutlined, DatabaseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ModalWrapper } from 'components';
import { stringifyUrlParams, useUrlParams } from 'hooks/use-url-params';
import { adminActions } from 'store/admin/slices';
import { User } from 'store/user/types';
import { Modal } from 'types/modal';
import { StyledEditLink, StyledContent } from './styles';
import { UpdateContent } from './modal/UpdateContent';
import { InfoContent } from './components/InfoContent';
import { UpdateUserValues } from '../../types';

interface UserSummaryProps {
  state: User;
  isLoading: boolean;
  isEditable?: boolean;
}

const UserSummary: React.FC<UserSummaryProps> = ({ state, isLoading, isEditable }) => {
  const dispatch = useDispatch();
  const { queryUrlParams } = useUrlParams({});
  const { t } = useTranslation();
  const {
    location: { pathname, search },
    goBack,
  } = useHistory();

  const onSubmitEditUser = (values: UpdateUserValues) => {
    if (Object.keys(values).length > 0) {
      dispatch(adminActions.users.requestUpdateUser({ ...values, uid: state.uid }));
    }
    goBack();
  };

  return (
    <StyledContent>
      <StyledEditLink>
        <Link
          to={{ pathname, search: stringifyUrlParams({ ...queryUrlParams, modal: Modal.EDIT_USER, user: state.uid }) }}
        >
          <EditOutlined style={{ fontSize: 20 }} />
        </Link>
      </StyledEditLink>
      <StyledEditLink>
        <Link to={{ pathname, search: stringifyUrlParams({ ...queryUrlParams, user: state.uid }) }}>
          <DatabaseOutlined style={{ fontSize: 20 }} />
        </Link>
      </StyledEditLink>
      <InfoContent state={state} />
      {isEditable && (
        <ModalWrapper
          isVisible={search.includes(`modal=${Modal.EDIT_USER}`)}
          onClose={goBack}
          title={t('user.modal.title.edit')}
          width={900}
          destroyOnClose
        >
          <UpdateContent state={state} onSubmitForm={onSubmitEditUser} isLoading={isLoading} />
        </ModalWrapper>
      )}
    </StyledContent>
  );
};

export { UserSummary };
