import { takeEvery } from 'redux-saga/effects';

import makeRequestSaga from 'store/common';
import { getWimViolations } from 'api/wim';
import { SagaPayload } from 'types';
import { GetWimPlatformSummaryViolationsQueryParams } from 'store/wim/types/platforms';
import { getWimTransitViolationsError, requestWimTransitsViolations, getWimTransitViolationsSuccess } from './slice';

export function* wimTransitsViolationsRequestSaga({
  payload,
}: SagaPayload<GetWimPlatformSummaryViolationsQueryParams>) {
  yield makeRequestSaga(getWimTransitViolationsSuccess, getWimTransitViolationsError, getWimViolations, payload);
}

export function* wimTransitsViolationsRequestWatchSaga() {
  yield takeEvery(requestWimTransitsViolations, wimTransitsViolationsRequestSaga);
}
