import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  LoginError,
  LoginQueryParams,
  PostMfaConfirmationRequest,
  PostPasswordResetRequest,
  RefreshToken,
  TriggerPasswordResetRequest,
} from './types';

interface InitialState {
  isLoading: boolean;
  isAuthenticated: boolean;
  isMFAActivationPage: boolean;
  isMFAConfirmationPage: boolean;
  isPasswordResetPage: boolean;
  loginError: LoginError;
  qrCodeBase64: string | null;
  error: any;
}

const initialState: InitialState = {
  isLoading: false,
  isAuthenticated: false,
  isMFAActivationPage: false,
  isMFAConfirmationPage: false,
  isPasswordResetPage: false,
  loginError: { userMessage: null, serverError: null },
  qrCodeBase64: null,
  error: null,
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    requestLogin: (state, _payload: PayloadAction<LoginQueryParams>): void => {
      state.isLoading = true;
    },
    requestLogout: (state): void => {
      state.isLoading = true;
    },
    requestAccessToken: (state, { payload }: PayloadAction<RefreshToken>): void => {
      state.isLoading = true;
    },
    getLoginSuccess: (state): void => {
      state.isLoading = false;
    },
    getLogoutSuccess: (state): void => {
      state.isLoading = false;
    },
    getAccessTokenSuccess: (state): void => {
      state.isLoading = false;
    },
    getLogoutError: (state, { payload }: PayloadAction<any>): void => {
      state.isLoading = false;
      state.error = payload.message;
    },
    getLoginError: (state, { payload }: PayloadAction<LoginError>): void => {
      state.isLoading = false;
      state.loginError = payload;
    },
    getAccessTokenError: (state, { payload }: PayloadAction<any>): void => {
      state.isLoading = false;
      state.error = payload.message;
    },
    setIsAuthenticated: (state, { payload }: PayloadAction<boolean>): void => {
      state.isAuthenticated = payload;
    },
    setMFAActivationPage: (state, { payload }: PayloadAction<boolean>): void => {
      state.isMFAActivationPage = payload;
    },
    setMFAConfirmationPage: (state, { payload }: PayloadAction<boolean>): void => {
      state.isMFAConfirmationPage = payload;
    },
    setPasswordResetPage: (state, { payload }: PayloadAction<boolean>): void => {
      state.isPasswordResetPage = payload;
    },
    requestQrActivation: (state, { payload }: PayloadAction<string>): void => {
      state.isLoading = true;
    },
    getQrSuccess: (state, { payload }: PayloadAction<{ qrCodeBase64: string }>): void => {
      state.isLoading = false;
      state.qrCodeBase64 = payload.qrCodeBase64;
    },
    getQrError: (state, { payload }: PayloadAction<LoginError>): void => {
      state.isLoading = false;
      state.loginError = payload;
    },
    postValidationCode: (state, { payload }: PayloadAction<PostMfaConfirmationRequest>): void => {
      state.isLoading = true;
    },
    postPasswordReset: (state, { payload }: PayloadAction<PostPasswordResetRequest>): void => {
      state.isLoading = true;
    },
    postValidationCodeSuccess: (state): void => {
      state.isLoading = false;
    },
    postValidationCodeError: (state, { payload }: PayloadAction<LoginError>): void => {
      state.isLoading = false;
      state.loginError = { userMessage: 'validationError', serverError: null };
    },
    postPasswordResetSuccess: (state): void => {
      state.isLoading = false;
    },
    postPasswordResetError: (state, { payload }: PayloadAction<LoginError>): void => {
      state.isLoading = false;
      state.loginError = { userMessage: 'validationError', serverError: null };
    },
    triggerPasswordReset: (state, { payload }: PayloadAction<TriggerPasswordResetRequest>): void => {
      state.isLoading = true;
    },
    clearLogin: () => initialState,
  },
});

export const authActions = {
  ...auth.actions,
};

export default auth.reducer;
