import dayjs from 'dayjs';
import palette from 'palette';
import { timeDiff } from './date.formatter';

export const getColorOfWimConnection = (lastTransitTime: number | null): string => {
  if (lastTransitTime) {
    if (timeDiff(dayjs().valueOf(), lastTransitTime) < 5) {
      return palette.green.g2;
    }
  }
  return palette.red.r3;
};

export const getColorOfPercentValue = (state: number | undefined): string => {
  if (state && state >= 0.9) {
    return palette.green.g2;
  }
  return palette.red.r3;
};
