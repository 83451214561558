import React, { FC } from 'react';
import { AxleGroupType } from '..';

import { StyledAxle, StyledDualWheel, StyledSeparator, StyledSingleWheel, StyledTotalWeight } from './styles';
import { separateByThousands } from '../../../../../../helpers/number.formatter';

interface AxleProps {
  dualWheels?: boolean;
  totalWeight?: number;
  unit?: string;
  overload?: boolean;
  group?: AxleGroupType;
}

const Axle: FC<AxleProps> = ({ dualWheels = false, totalWeight = 0, unit = 'кг', overload, group }) => {
  return (
    <StyledAxle>
      {!dualWheels ? <StyledSingleWheel overload={overload} /> : <StyledDualWheel overload={overload} />}
      <StyledSeparator group={group} />
      {!dualWheels ? <StyledSingleWheel overload={overload} /> : <StyledDualWheel overload={overload} />}
      <StyledTotalWeight overload={overload}>
        {separateByThousands(totalWeight)} {unit}
      </StyledTotalWeight>
    </StyledAxle>
  );
};

export default Axle;
