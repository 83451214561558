import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ViolationOverweightStatisticType = {
  overload: number;
  overaxleload: number;
  overgroupload: number;
  date: number;
};

interface ViolationOverweightStatisticState {
  data: ViolationOverweightStatisticType[];
  isLoading: boolean;
  error: Error | null;
}

const initialState: ViolationOverweightStatisticState = {
  data: [],
  isLoading: false,
  error: null,
};

const violationOverloadStatistic = createSlice({
  name: 'violationOverloadStatistic',
  initialState,
  reducers: {
    requestViolationOverweightStatistic: (state, _payload): void => {
      state.isLoading = true;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>): void => {
      state.isLoading = payload;
    },
    getViolationOverweightStatisticSuccess: (state, { payload }): void => {
      state.isLoading = false;
      state.data = payload;
    },
    getViolationOverweightStatisticError: (state, { payload }): void => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const {
  requestViolationOverweightStatistic,
  getViolationOverweightStatisticSuccess,
  getViolationOverweightStatisticError,
  setIsLoading,
} = violationOverloadStatistic.actions;

export default violationOverloadStatistic.reducer;
