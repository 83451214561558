import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { DatePeriodType } from 'app-constants/date';
import { WimMonitoringMetricBarChart } from 'components/BarChart';
import { DefaultTableContainer } from 'components';
import { WimMonitoringMetricStatistics, WimMonitoringMetricStatisticsByTime } from 'store/wim/monitoring/types';
import { TimePeriod } from 'types/date-period';
import { getWimMonitoringMetricTableColumns } from './table-schema';
import { StyledCard } from './styles';

interface WimMonitoringMetricStatisticsProps {
  wim: WimMonitoringMetricStatistics;
  wimMonitoringMetricStatisticsByTime: WimMonitoringMetricStatisticsByTime;
  timePeriod: TimePeriod;
  datePeriod?: DatePeriodType;
}

export const WimMonitoringMetricStatisticsCard: FC<WimMonitoringMetricStatisticsProps> = ({
  wim,
  wimMonitoringMetricStatisticsByTime,
  timePeriod: { start, end },
  datePeriod,
}) => {
  const columns = Object.values(getWimMonitoringMetricTableColumns({ start, end }));
  const { t } = useTranslation();

  return (
    <StyledCard title={`${t('wimsPage.monitoring.metric.metricBywim')} ${wim.wim}`}>
      <WimMonitoringMetricBarChart
        syncId="date"
        data={wimMonitoringMetricStatisticsByTime.metricsByPeriod}
        key="25"
        datePeriod={datePeriod}
      />
      <DefaultTableContainer
        columns={columns}
        dataSource={wimMonitoringMetricStatisticsByTime.transit.items}
        showSorterTooltip={false}
        scroll={{ y: 'calc(100vh - 220px)' }}
        size="small"
      />
    </StyledCard>
  );
};
