import { ColumnGroupType, ColumnType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';

type Sorting = {
  sortingKey: string;
  sortingOrder: SortOrder;
};

export function assignSortingOrderToColumn<TableRecord>(
  column: ColumnGroupType<TableRecord> | ColumnType<TableRecord>,
  sorting: Sorting
): ColumnGroupType<TableRecord> | ColumnType<TableRecord> {
  const newColumn = column;
  const { sortingKey, sortingOrder } = sorting;

  if (newColumn.key === sortingKey) {
    newColumn.sortOrder = sortingOrder || 'ascend';
  }
  if (newColumn.key !== sortingKey) {
    newColumn.sortOrder = null;
  }

  return newColumn;
}
