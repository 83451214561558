import { NormalizedWimPlatform, NormalizedSummary } from 'store/wim/normalization';

export enum WimGridViewParams {
  list = 'list',
  // tile = 'tile',
}

export enum WimsListSort {
  default = 'default',
  last_updated = 'last_updated',
}

export interface WimGridViewProps {
  platforms: NormalizedWimPlatform[];
  isSummaryLoading: boolean;
  selectedPlatform: NormalizedWimPlatform;
  onSelectPlatform: (platform: NormalizedWimPlatform) => void;
  isListLoaded: boolean | null;
  summary: NormalizedSummary | null;
}

type GridViewContent = (props: WimGridViewProps) => JSX.Element;

export type WimGridView = {
  [view in WimGridViewParams]: GridViewContent;
};

export type WimsGridFilterFormValues = {
  search: string;
  road: string;
  region: string;
  provider: string;
  lanes: number;
  status: string;
};
