import React from 'react';

import dayjs from 'dayjs';
import { DATE_FORMAT } from 'app-constants/date';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import i18n from 'i18n';
import { Tooltip } from 'antd';

import palette from 'palette';

import VrpLink from 'pages/Violations/helpers/VrpLink';
import IdLink from 'pages/Transits/helpers/IdLink';
import { getVehicleClassIconByName, getVehicleIdByName } from 'helpers/get-vehicle-class-icon';
import { getVehicleTypeDescriptionOld } from 'helpers/get-vehicle-description';
import { wrapTableTitle } from 'components/TableContainer/DefaultTableContainer';
import { HintTooltip } from 'pages/Transits/styles';
import { WimTransitItem } from 'store/wim/types';
import { TimePeriod } from 'types/date-period';
// import { StyledCheckCircleOutlined, StyledExclamationCircleOutlined } from './styles';

export type WimMonitoringMeticTableColumns = {
  [key in keyof Pick<WimTransitItem, 'id' | 'datetime' | 'lane' | 'plate' | 'vehicleClassDescription'>]:
    | ColumnType<WimTransitItem>
    | ColumnGroupType<WimTransitItem>;
};

export const getWimMonitoringMetricTableColumns = ({ start, end }: TimePeriod): WimMonitoringMeticTableColumns => ({
  id: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('wimsPage.monitoring.metric.transitId')),
    width: 100,
    sorter: (a, b) => Number(a.id) - Number(b.id),
    fixed: 'left',
    key: 'id',
    dataIndex: 'id',
    align: 'center',
    render: (id: string, row) => <IdLink id={id} uid={row.uid} datetime={row.datetime} />,
  },
  datetime: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('wimsPage.monitoring.metric.date')),
    width: 170,
    sorter: (a, b) => a.datetime - b.datetime,
    fixed: 'left',
    key: 'datetime',
    dataIndex: 'datetime',
    align: 'center',
    render: (date: number) => dayjs(date).format(DATE_FORMAT.TABLE_FORMAT),
  },
  lane: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('wimsPage.monitoring.metric.lane')),
    sorter: (a, b) => a.lane.length - b.lane.length,
    key: 'lane',
    dataIndex: 'lane',
    align: 'center',
    ellipsis: {
      showTitle: false,
    },
    render: (text: string) => (
      <Tooltip title={<HintTooltip>{text}</HintTooltip>} color={palette.white.w1}>
        {text}
      </Tooltip>
    ),
  },
  // status: { ????
  //   title: (): JSX.Element => wrapTableTitle(i18n.t('wimsPage.monitoring.metric.status')),
  //   width: 85,
  //   key: 'status',
  //   dataIndex: 'status',
  //   align: 'center',
  //   render: (status: boolean) => (
  //     <Tooltip title={<HintTooltip>{status ? 'Online' : 'Error'}</HintTooltip>} color={palette.white.w1}>
  //       {status ? <StyledCheckCircleOutlined /> : <StyledExclamationCircleOutlined />}
  //     </Tooltip>
  //   ),
  // },
  plate: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('wimsPage.monitoring.metric.plate')),
    width: 130,
    sorter: (a, b) => (a.plate && b.plate ? a.plate.length - b.plate?.length : -1),
    key: 'plate',
    dataIndex: 'plate',
    align: 'center',
    render: (plate: string) => <VrpLink vrp={plate} start={start} end={end} />,
  },
  // trailerPlate: {???
  //   title: (): JSX.Element => wrapTableTitle(i18n.t('wimsPage.monitoring.metric.trailerPlate')),
  //   width: 120,
  //   sorter: (a, b) => a.trailerPlate.length - b.trailerPlate.length,
  //   key: 'trailerPlate',
  //   dataIndex: 'trailerPlate',
  //   align: 'center',
  // },
  vehicleClassDescription: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('wimsPage.monitoring.metric.vehicleType')),
    width: 140,
    sorter: (a, b) => a.vehicleClassDescription.length - b.vehicleClassDescription.length,
    key: 'vehicleClassDescription',
    dataIndex: 'vehicleClassDescription',
    align: 'center',
    render: (vehicleClassDescription: string) => (
      <Tooltip
        title={<HintTooltip>{getVehicleTypeDescriptionOld(vehicleClassDescription)}</HintTooltip>}
        color={palette.white.w1}
      >
        <span>{getVehicleIdByName(vehicleClassDescription)} </span>
      </Tooltip>
    ),
  },
  // totalWeight: {???
  //   title: (): JSX.Element => wrapTableTitle(i18n.t('wimsPage.monitoring.metric.totalWeight')),
  //   width: 90,
  //   sorter: (a, b) => a.totalWeight - b.totalWeight,
  //   key: 'totalWeight',
  //   dataIndex: 'totalWeight',
  //   align: 'center',
  // },
});
