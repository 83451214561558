import i18n from 'i18n';
import * as Yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(Yup);

export const editUserValidationSchema = Yup.object().shape({
  email: Yup.string().required(i18n.t('loginForm.emptyLogin')).email(),
  password: Yup.string()
    .min(8, i18n.t('loginForm.passwordLess6Chars'))
    .minRepeating(1, i18n.t('user.validation.password.minRepeating'))
    .minLowercase(1, i18n.t('user.validation.password.minLowercase'))
    .minUppercase(1, i18n.t('user.validation.password.minUppercase'))
    .minNumbers(1, i18n.t('user.validation.password.minNumbers'))
    .minSymbols(1, i18n.t('user.validation.password.minSymbols'))
    .nullable(),
  socialNetworks: Yup.array().of(
    Yup.object().shape({
      application: Yup.string().required(
        i18n.t('phrases.requiredField', { title: i18n.t('user.socialNetworks.application') })
      ),
      contact: Yup.string().required(i18n.t('phrases.requiredField', { title: i18n.t('user.socialNetworks.contact') })),
    })
  ),
});
