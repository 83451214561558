import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetWimPlatformSummaryViolationsQueryParams } from 'store/wim/types/platforms';
import { WimSummaryType } from './types';

type InitialState = {
  isWimTransitSummaryLoading: boolean;
  summaryData: WimSummaryType[] | null;
  error: string | null;
};

const initialState: InitialState = {
  isWimTransitSummaryLoading: false,
  summaryData: null,
  error: null,
};

const wimTransitSummary = createSlice({
  name: 'wimTransitSummary',
  initialState,
  reducers: {
    requestWimTransitSummary: (state, { payload }: PayloadAction<GetWimPlatformSummaryViolationsQueryParams>): void => {
      state.isWimTransitSummaryLoading = true;
    },

    getWimTransitSummarySuccess: (state, { payload }: PayloadAction<WimSummaryType[]>): void => {
      state.isWimTransitSummaryLoading = false;
      state.summaryData = payload;
    },

    getWimTransitSummaryError: (state, { payload }: PayloadAction<string>): void => {
      state.isWimTransitSummaryLoading = false;
      state.error = payload;
    },
  },
});

export const { requestWimTransitSummary, getWimTransitSummarySuccess, getWimTransitSummaryError } =
  wimTransitSummary.actions;

export default wimTransitSummary.reducer;
