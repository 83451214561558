import { NormalizedWimPlatforms } from '../../../store/wim/normalization';

export const getFilteredByLanes = (platforms: NormalizedWimPlatforms, lanes: number): NormalizedWimPlatforms => {
  const data = platforms.byUid;
  const result: NormalizedWimPlatforms = { byUid: {} };

  // eslint-disable-next-line guard-for-in
  for (const key in data) {
    let quantity = 0;
    const q = data[key].lanes.byDirection;
    // eslint-disable-next-line guard-for-in
    for (const k in q) {
      quantity += q[k].lanesInfo.length;
    }
    if (lanes === quantity) {
      result.byUid[key] = data[key];
    }
  }
  console.log('getFilteredByLanes', lanes);
  return result;
};

export const getFilteredByStatus = (platforms: NormalizedWimPlatforms, status: string): NormalizedWimPlatforms => {
  const data = platforms.byUid;
  const result: NormalizedWimPlatforms = { byUid: {} };

  // eslint-disable-next-line guard-for-in
  for (const key in data) {
    if (data[key].status === status) {
      result.byUid[key] = data[key];
    }
  }

  return result;
};

export const getFilteredByRegion = (platforms: NormalizedWimPlatforms, region: string): NormalizedWimPlatforms => {
  const data = platforms.byUid;
  const result: NormalizedWimPlatforms = { byUid: {} };

  // eslint-disable-next-line guard-for-in
  for (const key in data) {
    const { alias } = data[key];
    const regex = new RegExp(region, 'i');
    if (regex.test(alias)) {
      result.byUid[key] = data[key];
    }
  }
  return result;
};

export const getFilteredBySearch = (platforms: NormalizedWimPlatforms, search: string): NormalizedWimPlatforms => {
  const data = platforms.byUid;
  const result: NormalizedWimPlatforms = { byUid: {} };

  // eslint-disable-next-line guard-for-in
  for (const key in data) {
    const { alias, road } = data[key];
    const regex = new RegExp(search, 'i');
    if (regex.test(`${alias} ${road}`)) {
      result.byUid[key] = data[key];
    }
  }
  return result;
};

export const getFilteredByRoad = (platforms: NormalizedWimPlatforms, search: string): NormalizedWimPlatforms => {
  const data = platforms.byUid;
  const result: NormalizedWimPlatforms = { byUid: {} };

  // eslint-disable-next-line guard-for-in
  for (const key in data) {
    const { road } = data[key];
    const regex = new RegExp(search, 'i');
    if (regex.test(road)) {
      result.byUid[key] = data[key];
    }
  }
  return result;
};

export const getFilteredByProvider = (platforms: NormalizedWimPlatforms, search: string): NormalizedWimPlatforms => {
  const data = platforms.byUid;
  const result: NormalizedWimPlatforms = { byUid: {} };

  // eslint-disable-next-line guard-for-in
  for (const key in data) {
    const { provider } = data[key];
    const regex = new RegExp(search, 'i');
    if (regex.test(provider)) {
      result.byUid[key] = data[key];
    }
  }
  return result;
};
