import React, { useCallback, useEffect } from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks/store-hooks';
import adminSelectors from '../../store/admin/selectors';
import { NotificationMessageData } from '../../store/admin/notifications/types';

/*
 * dispatch one of notification actions to show the message
 * or use put from saga:
 * Actions: setNotificationSuccess, setNotificationError, setNotificationWarning, setNotificationInfo
 */
export const NotificationMessage: React.FC = () => {
  const { t } = useTranslation();
  const notifications = useAppSelector(adminSelectors.notifications.getNotifications);

  const openNotificationWithIcon = useCallback((message: NotificationMessageData) => {
    notification[message.type]({
      message: t(message.title),
      description: message.message,
    });
  }, []);

  useEffect(() => {
    if (!notifications.length) {
      return;
    }
    openNotificationWithIcon(notifications[notifications.length - 1]);
  }, [notifications, openNotificationWithIcon]);

  return null;
};
