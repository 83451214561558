import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';

import { StyledError, StyledField } from './styles';

interface FormikWimSimpleCellProps {
  index: number;
  dataIndex: string;
  value: string;
  readOnly?: boolean;
}

export const FormikWimSimpleCell: FC<FormikWimSimpleCellProps> = ({ index, dataIndex, value, readOnly }) => {
  return (
    <>
      <StyledField name={`wims.${index}.${dataIndex}`} defaultValue={value} readOnly={readOnly} />
      <Field name={`wims.${index}.${dataIndex}`}>
        {({ meta }: FieldProps) => {
          const { error, touched } = meta;

          return touched && error ? <StyledError>{error}</StyledError> : null;
        }}
      </Field>
    </>
  );
};
