import { delay, put, takeEvery } from 'redux-saga/effects';

import { mockedComparativeAnalysisTransits } from 'mocks/comparative-analysis';

import { requestTransits, getTransitsSuccess } from './slice';

export function* wimTransitsAnalysisRequestSaga() {
  const mockedAnalysisTransits = mockedComparativeAnalysisTransits;
  yield delay(1000);
  yield put(getTransitsSuccess(mockedAnalysisTransits));
}

export function* wimTransitsAnalysisRequestWatchSaga() {
  yield takeEvery(requestTransits, wimTransitsAnalysisRequestSaga);
}
