import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  HomePage,
  NotFoundPage,
  TransitsPage,
  ViolationsPage,
  WimTab,
  PermissionDenied,
  AuthorizationPage,
  ProfilePage,
  ComparativeAnalysisPage,
} from 'pages';
import { ROUTES } from 'app-constants/routes';
import { ProtectedRoute } from 'components';
import { authSelectors } from 'store/auth/selectors';
import { AdminNavigationRouter } from './AdminNavigationRouter';
import ExternalNotifications from '../pages/ExternalNotifications';
import MediaCluster from '../pages/MediaCluster';
import Connectors from '../pages/Connectors';

const RoutesContainer: React.FC = () => {
  const isAuthenticated = useSelector(authSelectors.isAuthenticated);

  return (
    <Switch>
      <Route exact path={ROUTES.Authorization}>
        <AuthorizationPage isAuthenticated={isAuthenticated} />
      </Route>
      <ProtectedRoute isAuthenticated={isAuthenticated} exact path="/">
        <HomePage />
      </ProtectedRoute>
      <ProtectedRoute isAuthenticated={isAuthenticated} path={ROUTES.Wim}>
        <WimTab />
      </ProtectedRoute>
      <ProtectedRoute isAuthenticated={isAuthenticated} path={ROUTES.Transits}>
        <TransitsPage />
      </ProtectedRoute>
      <ProtectedRoute isAuthenticated={isAuthenticated} exact path={ROUTES.WimViolations}>
        <ViolationsPage />
      </ProtectedRoute>
      <ProtectedRoute isAuthenticated={isAuthenticated} exact path={`${ROUTES.WimViolations}/:id`}>
        <ViolationsPage />
      </ProtectedRoute>
      <ProtectedRoute isAuthenticated={isAuthenticated} exact path={ROUTES.PermissionDenied}>
        <PermissionDenied />
      </ProtectedRoute>
      {/* <ProtectedRoute isAuthenticated={isAuthenticated} exact path={`${ROUTES.ComparativeAnalysis}`}> */}
      {/*  <ComparativeAnalysisPage /> */}
      {/* </ProtectedRoute> */}
      <ProtectedRoute isAuthenticated={isAuthenticated} exact path={`${ROUTES.ServerMonitoring}`}>
        <MediaCluster />
      </ProtectedRoute>
      <ProtectedRoute isAuthenticated={isAuthenticated} exact path={`${ROUTES.ExternalNotifications}`}>
        <ExternalNotifications />
      </ProtectedRoute>
      <ProtectedRoute isAuthenticated={isAuthenticated} exact path={`${ROUTES.ExternalConnectors}`}>
        <Connectors />
      </ProtectedRoute>
      <ProtectedRoute isAuthenticated={isAuthenticated} exact path={ROUTES.Profile}>
        <ProfilePage />
      </ProtectedRoute>
      <ProtectedRoute isAuthenticated={isAuthenticated} path={ROUTES.AdminHome}>
        <AdminNavigationRouter />
      </ProtectedRoute>
      <ProtectedRoute isAuthenticated={isAuthenticated} path="*">
        <NotFoundPage />
      </ProtectedRoute>
    </Switch>
  );
};

export default RoutesContainer;
