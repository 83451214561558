import { useEffect } from 'react';

import { useAppDispatch } from 'hooks/store-hooks';
import { adminActions } from 'store/admin/slices';

function useGetWimGroups(): void {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(adminActions.wimGroups.requestGetGroupBindings());
  }, [dispatch]);
}

export default useGetWimGroups;
