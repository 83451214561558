import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Collapse } from 'antd';

import { DATE_FORMAT } from 'app-constants/date';

import { LanesTransit, LaneTransit } from 'store/wim/types/transits';
import { StyledCollapse, StyledWimItem } from './styles';
import { StyledValueWrapper } from '../../styles';
import { TimePeriod } from '../../../../../../../types/date-period';
import { BarChartTransitsViolationsByLane } from '../../../../../../../components/BarChart';
import { separateByThousands } from '../../../../../../../helpers/number.formatter';

interface TransitsDetailListByWimProps extends Partial<TimePeriod> {
  lanes: LanesTransit;
}

export const TransitsDetailListByWim: FC<TransitsDetailListByWimProps> = ({ lanes, start, end }) => {
  const { t } = useTranslation();
  const { Panel } = Collapse;

  return (
    <StyledCollapse>
      {Object.keys(lanes).map((laneUid) => {
        const lane = lanes[laneUid];

        return (
          <Panel key={laneUid} header={`${lane.direction} (${lane.lane})`}>
            {Object.keys(lane).map((laneKey) => {
              const valueWithMeasures = () => {
                const laneValue = lane[laneKey as keyof LaneTransit];

                if (laneKey === 'maxWeight' || laneKey === 'minWeight' || laneKey === 'averageWeight') {
                  return `${separateByThousands(+laneValue)} ${t('glossary.measureKilogram')}`;
                }

                if (laneKey === 'averageSpeed') {
                  return `${laneValue} ${t('glossary.measureSpeed')}`;
                }

                if (laneKey === 'lastTransitDate') {
                  return dayjs(laneValue as number).format(DATE_FORMAT.TABLE_FORMAT);
                }

                return laneValue;
              };

              if (laneKey !== 'transits' && laneKey !== 'violations' && laneKey !== 'direction' && laneKey !== 'lane') {
                return (
                  <StyledWimItem key={laneKey}>
                    <span>{`${t(
                      `transitsMapPage.${
                        laneKey as keyof Omit<LaneTransit, 'laneUid' | 'violations' | 'lane' | 'direction' | 'transits'>
                      }` as const
                    )}: `}</span>
                    <StyledValueWrapper>{valueWithMeasures()}</StyledValueWrapper>
                  </StyledWimItem>
                );
              }

              return null;
            })}
            <BarChartTransitsViolationsByLane data={lane} start={start} end={end} />
          </Panel>
        );
      })}
    </StyledCollapse>
  );
};
