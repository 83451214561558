import React, { useContext, useEffect, useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

import { HeaderConfigContext } from 'app/App';
import { DefaultTableContainer, HeaderExportExtra, Loader } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks/store-hooks';
import { useUrlParams } from 'hooks/use-url-params';
import wimSelectors from 'store/wim/selectors';
import { wimActions } from 'store/wim/slices';
import { getVehicleClassIconById } from 'helpers/get-vehicle-class-icon';
import { DATE_FORMAT } from 'app-constants/date';
import { cLogo } from 'assets';
import { ROUTES } from 'app-constants/routes';
import { measurementFlagsTypeTitle } from 'app-constants/measurement-flags';
import { WimMeasurementFlagType, WimViolationType } from 'store/wim/types';
import { violationsTypeTitle } from 'app-constants/violations';
import { convertWebpToJpeg } from 'helpers/convert-webp-to-jpeg';

import { TransitInfoItem } from '../Transit/components/TransitInfoItem';
import {
  TitleContainer,
  TransitDocumentContainer,
  Title,
  TransitInfoContainer,
  SectionTitleContainer,
  SectionTitle,
  StyledImage,
  FooterContainer,
  TransitInfoSectionContainer,
  SectionContentContainer,
  StyledTableContainer,
  Text,
  LogoContainer,
} from './styles';
import {
  AxlesTableRow,
  getTransitAxlesTableColumns,
  getTransitSizesTableColumns,
  SizesTableRow,
} from '../Transit/table-schema';
import { getAxleInfo } from '../helpers/get-axles-info';
import { getTransitPdfDocument } from './pdf/document-builder';
import VehicleSchema from '../ArchiveTabs/components/VehicleSchema';
import { getVehicleSchema } from '../helpers/get-vehicle-schema';

export const TransitDocument: React.FC = () => {
  const [pdfImages, setPdfImages] = useState({ PLATE: '', FRONT: '', SIDE: '' });
  const [schemaSrc, setSchemaSrc] = useState<string>();
  const [objectName, setObjectNameText] = useState<string>('');
  const [fullLocationText, setFullLocationText] = useState<string>('');
  const [transitCoords, setTransitCoords] = useState<number[] | undefined>();
  const platforms = useAppSelector(wimSelectors.platforms.getPlatforms);
  const dispatch = useAppDispatch();
  const transit = useAppSelector(wimSelectors.transits.getTransit);
  const transitDocumentPhotos = useAppSelector(wimSelectors.transits.getTransitPhotos);
  const isTransitDocumentPhotosLoading = useAppSelector(wimSelectors.transits.isTransitPhotosLoading);
  const isLoading = useAppSelector(wimSelectors.transits.isLoading);
  const { setHeaderOnBackButtonState, setHeaderRenderer } = useContext(HeaderConfigContext);
  const { t } = useTranslation();
  const { queryUrlParams } = useUrlParams({});
  const history = useHistory();

  const reportDate = dayjs().valueOf();
  const onBackButton = useCallback(() => {
    dispatch(wimActions.transits.clearTransit());
    if (transit?.platformUid && transit.uid && transit.datetime) {
      return history.push({
        pathname: `${ROUTES.Transit}/${transit.datetime}/${transit.uid}`,
        state: history.location.state,
      });
    }
    return null;
  }, [transit]);

  const generateTransitPdfDocument = useCallback(() => {
    if (transit && !isLoading && !isTransitDocumentPhotosLoading) {
      const document = getTransitPdfDocument(
        transit,
        reportDate,
        pdfImages,
        schemaSrc,
        objectName,
        fullLocationText,
        transitCoords
      );
      document.open();
    }
  }, [
    transit,
    isLoading,
    isTransitDocumentPhotosLoading,
    reportDate,
    pdfImages,
    schemaSrc,
    objectName,
    fullLocationText,
    transitCoords,
  ]);

  useEffect(() => {
    if (!transitCoords && transit) {
      setTransitCoords(platforms?.find((platform) => platform.uid === transit.platformUid)?.mapPosition.coordinates);
    }

    if (transit && transitCoords && platforms) {
      const platform = platforms.filter((wimPlatform) => wimPlatform.uid === transit.platformUid)[0];

      setObjectNameText(`${transit.platformName}`);
      setFullLocationText(`${platform.address}`);
    }
  }, [
    transit,
    pdfImages.PLATE,
    pdfImages.FRONT,
    pdfImages.SIDE,
    schemaSrc,
    isTransitDocumentPhotosLoading,
    objectName,
    fullLocationText,
    transitCoords,
    platforms,
  ]);

  useEffect(() => {
    if (!transitCoords && transit) {
      setTransitCoords(platforms?.find((platform) => platform.uid === transit.platformUid)?.mapPosition.coordinates);
    }

    if (transit && transitCoords && platforms) {
      const platform = platforms.filter((wimPlatform) => wimPlatform.uid === transit.platformUid)[0];

      setObjectNameText(`${transit.platformName}`);
      setFullLocationText(`${platform.address}`);
    }
  }, [transit, transitCoords, platforms]);

  useEffect(() => {
    dispatch(wimActions.transits.clearTransitInfo());

    if (queryUrlParams.transitUid && queryUrlParams.ts) {
      dispatch(
        wimActions.transits.requestTransit({
          transitUid: queryUrlParams.transitUid,
          ts: queryUrlParams.ts,
        })
      );
    }

    return () => {
      setHeaderOnBackButtonState(undefined);
      dispatch(wimActions.transits.clearTransitInfo());
    };
  }, [dispatch, queryUrlParams.transitUid, queryUrlParams.ts, setHeaderOnBackButtonState]);

  useEffect(() => {
    setHeaderRenderer(
      <HeaderExportExtra tooltipText={t('common.exportPdf')} onExportButtonClick={generateTransitPdfDocument} />
    );

    return () => setHeaderRenderer(null);
  }, [generateTransitPdfDocument, setHeaderRenderer, t]);

  useEffect(() => {
    setHeaderOnBackButtonState(() => onBackButton);

    if (transit) {
      dispatch(wimActions.transits.requestTransitPhotos({ transitUid: transit.uid }));
    }
  }, [transit, onBackButton, setHeaderOnBackButtonState, dispatch]);

  useEffect(() => {
    const formatTransitDocumentPhotos = async () => {
      const images = {
        PLATE: '',
        FRONT: '',
        SIDE: '',
      };

      if (transitDocumentPhotos.PLATE) {
        const img = await convertWebpToJpeg(transitDocumentPhotos.PLATE);
        images.PLATE = img;
      }
      if (transitDocumentPhotos.FRONT) {
        const img = await convertWebpToJpeg(transitDocumentPhotos.FRONT);
        images.FRONT = img;
      }
      if (transitDocumentPhotos.SIDE) {
        const img = await convertWebpToJpeg(transitDocumentPhotos.SIDE);
        images.SIDE = img;
      }

      if (!isTransitDocumentPhotosLoading) {
        setPdfImages(images);
      }
    };

    formatTransitDocumentPhotos();
  }, [
    transitDocumentPhotos.PLATE,
    transitDocumentPhotos.FRONT,
    transitDocumentPhotos.SIDE,
    isTransitDocumentPhotosLoading,
  ]);

  if (isLoading || !transit) {
    return <Loader isLoading />;
  }

  const sizesTableData = [
    { param: t('transitPage.sizesTable.items.weight'), ...transit.size.weight },
    { param: t('transitPage.sizesTable.items.length'), ...transit.size.length },
    { param: t('transitPage.sizesTable.items.width'), ...transit.size.width },
    { param: t('transitPage.sizesTable.items.height'), ...transit.size.height },
  ];

  const axlesColumns = Object.values(getTransitAxlesTableColumns({ withErrors: false }));
  const sizesColumns = Object.values(getTransitSizesTableColumns({ withErrors: false }));

  return (
    <TransitDocumentContainer>
      <TitleContainer>
        <LogoContainer>
          <img src={cLogo} width={40} alt="logo" />
        </LogoContainer>
        <div>
          <Title>{t('transitDocument.weighingResults')}</Title>
        </div>
        <div />
      </TitleContainer>
      <TransitInfoContainer>
        <TransitInfoSectionContainer>
          <TransitInfoItem
            title={t('transitDocument.date')}
            value={dayjs(transit.datetime).format(DATE_FORMAT.FULLDATE_NO_TIME)}
          />
          <TransitInfoItem title={t('transitDocument.object')} value={objectName} />
          <TransitInfoItem title={t('transitDocument.location')} value={fullLocationText} />
        </TransitInfoSectionContainer>
        <TransitInfoSectionContainer>
          <TransitInfoItem
            title={t('transitDocument.time')}
            value={dayjs(transit.datetime).format(DATE_FORMAT.FULLTIME)}
            isLeftAlign={false}
          />
          <TransitInfoItem
            title={t('transitDocument.gps')}
            value={
              (transitCoords && `${transitCoords[0].toFixed(3)}, ${transitCoords[1].toFixed(3)}`) ||
              t('common.notRecorder')
            }
            isLeftAlign={false}
          />
        </TransitInfoSectionContainer>
      </TransitInfoContainer>
      <div>
        <SectionTitleContainer>
          <SectionTitle>1. {t('transitDocument.generalInformation')}</SectionTitle>
        </SectionTitleContainer>
        <SectionContentContainer>
          <TransitInfoItem title={t('transitDocument.recordNumber')} value={transit.id} wide />
          <TransitInfoItem title={t('transitDocument.numberPlate')} value={transit.plate} wide />
          <TransitInfoItem title={t('transitDocument.countryOfOrigin')} value={transit.countryPlate} wide />
          <TransitInfoItem title={t('transitDocument.axles')} value={transit.axlesCount} wide />
          <TransitInfoItem title={t('transitDocument.vehicleClass')} value={transit.vehicleClass} wide />
          <TransitInfoItem
            title={t('transitDocument.vehiclePicture')}
            value={<img src={getVehicleClassIconById(transit.vehicleClass)} alt="icon" width={50} height={25} />}
            wide
          />
          <TransitInfoItem title={t('transitDocument.speed')} value={transit.speed} wide />
          <TransitInfoItem title={t('transitDocument.lane')} value={transit.lane} wide />
          <TransitInfoItem title={t('transitDocument.movingDirection')} value={transit.direction} wide />
          <TransitInfoItem title={t('transitDocument.pavementTemperature')} value={transit.roadTemperature} wide />
        </SectionContentContainer>
      </div>
      <div>
        <SectionTitleContainer>
          <SectionTitle>2. {t('transitDocument.schema')}</SectionTitle>
        </SectionTitleContainer>
        <SectionContentContainer>
          <VehicleSchema {...getVehicleSchema(transit)} setPathToSchemaImage={setSchemaSrc} />
        </SectionContentContainer>
      </div>
      <div>
        <SectionTitleContainer>
          <SectionTitle>3. {t('transitDocument.vehicleDimensions')}</SectionTitle>
        </SectionTitleContainer>
        <SectionContentContainer>
          <StyledTableContainer>
            <DefaultTableContainer<SizesTableRow> dataSource={sizesTableData} columns={sizesColumns} />
          </StyledTableContainer>
        </SectionContentContainer>
      </div>
      <div>
        <SectionTitleContainer>
          <SectionTitle>4. {t('transitDocument.vehicleWeight')}</SectionTitle>
        </SectionTitleContainer>
        <SectionContentContainer>
          <StyledTableContainer>
            <DefaultTableContainer<AxlesTableRow>
              dataSource={getAxleInfo(transit.axles, transit.axlesGroups)}
              columns={axlesColumns}
            />
          </StyledTableContainer>
        </SectionContentContainer>
      </div>
      <div>
        <SectionTitleContainer>
          <SectionTitle>5. {t('transitDocument.numberPlatePicture')}</SectionTitle>
        </SectionTitleContainer>
        <SectionContentContainer>
          {transitDocumentPhotos.PLATE ? (
            <StyledImage src={transitDocumentPhotos.PLATE} />
          ) : (
            <Text>{t('transitDocument.noPhoto')}</Text>
          )}
        </SectionContentContainer>
      </div>
      <div>
        <SectionTitleContainer>
          <SectionTitle>6. {t('transitDocument.vehicleFrontPicture')}</SectionTitle>
        </SectionTitleContainer>
        <SectionContentContainer>
          {transitDocumentPhotos.FRONT ? (
            <StyledImage src={transitDocumentPhotos.FRONT} />
          ) : (
            <Text>{t('transitDocument.noPhoto')}</Text>
          )}
        </SectionContentContainer>
      </div>
      <div>
        <SectionTitleContainer>
          <SectionTitle>7. {t('transitDocument.vehicleSidePicture')}</SectionTitle>
        </SectionTitleContainer>
        <SectionContentContainer>
          {transitDocumentPhotos.SIDE ? (
            <StyledImage src={transitDocumentPhotos.SIDE} />
          ) : (
            <Text>{t('transitDocument.noPhoto')}</Text>
          )}
        </SectionContentContainer>
      </div>
      <div>
        <SectionTitleContainer>
          <SectionTitle>8. {t('transitDocument.registeredViolations')}</SectionTitle>
        </SectionTitleContainer>
        <SectionContentContainer>
          {transit.violations.length ? (
            <ul>
              {transit.violations
                .filter((violation) => violation.type !== '')
                .map((violation) => {
                  return <li key={violation.type}>{violationsTypeTitle[violation.type as WimViolationType]}</li>;
                })}
            </ul>
          ) : (
            <Text>{t('transitDocument.notRecorded')}</Text>
          )}
        </SectionContentContainer>
      </div>
      <div>
        <SectionTitleContainer>
          <SectionTitle>9. {t('transitDocument.incorrectMeasurement')}</SectionTitle>
        </SectionTitleContainer>
        <SectionContentContainer>
          {transit.flags.length ? (
            <ul>
              {transit.flags
                .filter((flag) => flag.type !== '')
                .map((flag) => {
                  return <li key={flag.type}>{measurementFlagsTypeTitle[flag.type as WimMeasurementFlagType]}</li>;
                })}
            </ul>
          ) : (
            <Text>{t('transitDocument.notRecorded')}</Text>
          )}
        </SectionContentContainer>
      </div>

      <FooterContainer>
        <TransitInfoItem
          title={t('transitDocument.reportDate')}
          value={dayjs(reportDate).format(DATE_FORMAT.TABLE_FORMAT)}
        />
      </FooterContainer>
    </TransitDocumentContainer>
  );
};
