import React, { useCallback, useContext, useEffect, useState } from 'react';
import escapeStringRegexp from 'escape-string-regexp';
import { useDispatch } from 'react-redux';

import { HeaderConfigContext } from 'app/App';
import { FilterButton, Loader } from 'components';
import { NormalizedWimPlatform } from 'store/wim/normalization';
import { wimActions } from 'store/wim/slices';
import { ComponentSortProps, withSort } from 'hoc/withSort';
import { ComponentFilterProps, withFilter } from 'hoc/withFilter';
import { convertDatePeriodToTimePeriod } from 'helpers/date.formatter';
import { DatePeriod } from 'types/date-period';
import { useUrlParams } from 'hooks/use-url-params';
import useWimState from '../hooks/use-wim-state';
import { GridViewNavigation } from './GridViewNavigation';
import WimsListView from './components/List';
import WimsTileView from './components/Tile';
import { wimListSorters } from './sorting-schema';
import { WimGridView, WimGridViewParams, WimsGridFilterFormValues, WimsListSort } from './types';
import { SortSelect, SortSelectProps } from './SortSelect';
import { useWimsGridState } from '../hooks/use-wim-grid-state';
import { useClearSummaryOnMount } from '../hooks/use-clear-summary-on-mount';
import { WimsGridFilter } from './WimsGridFilter';

const gridViews: WimGridView = {
  list: (props) => <WimsListView {...props} />,
  // tile: () => <WimsTileView />,
};

type WimsGridProps = ComponentFilterProps<WimsGridFilterFormValues> & ComponentSortProps<SortSelectProps>;

const WimsGrid: React.FC<WimsGridProps> = ({ filterManager, sortManager }) => {
  useClearSummaryOnMount();
  const dispatch = useDispatch();
  const { setHeaderRenderer } = useContext(HeaderConfigContext);
  const { normalizedPlatforms, isPlatformsLoading, normalizedSummary, isSummaryLoading, isPlatformsLoaded } =
    useWimState();

  const { queryUrlParams, onPushUrlParams } = useUrlParams({});

  const { wimsGridPageState, handleUpdateWimsListPageState } = useWimsGridState(normalizedPlatforms, queryUrlParams);

  const { sorters, SortComponent } = sortManager;
  const { handleFilterOpen, handleFilterClose, isFilterActive } = filterManager;
  const [filteredPlatforms, setFilteredPlatforms] = useState(wimsGridPageState.mapPlatforms);

  const onGridViewChange = useCallback(
    (view: string) => {
      onPushUrlParams({ view });
    },
    [onPushUrlParams]
  );

  const onSort = useCallback(
    (sort: WimsListSort) => {
      if (wimsGridPageState.mapPlatforms) {
        const sortedPlatform = wimsGridPageState.mapPlatforms.sort(sorters[sort]);

        handleUpdateWimsListPageState({ ...wimsGridPageState, mapPlatforms: sortedPlatform });
        onPushUrlParams({ sort });
      }
    },
    [handleUpdateWimsListPageState, wimsGridPageState]
  );

  const onItemSelect = useCallback(
    (item: NormalizedWimPlatform) => {
      if (item.uid !== wimsGridPageState.selectedPlatform?.uid) {
        dispatch(wimActions.platforms.clearPlatformSummary());
      }
      handleUpdateWimsListPageState({ ...wimsGridPageState, selectedPlatform: item });
      onPushUrlParams({ uid: item.uid });
    },
    [handleUpdateWimsListPageState, wimsGridPageState]
  );

  const filterPredicate = (platform: NormalizedWimPlatform, filter: any, regExp: RegExp): boolean => {
    let result =
      regExp.test(platform.wim) ||
      regExp.test(platform.road) ||
      regExp.test(platform.provider) ||
      regExp.test(platform.alias) ||
      regExp.test(platform.region) ||
      regExp.test(platform.address);

    if (filter.road) {
      result = result && new RegExp(escapeStringRegexp(filter.road), 'i').test(platform.road);
    }
    if (filter.provider) {
      result = result && new RegExp(escapeStringRegexp(filter.provider), 'i').test(platform.provider);
    }
    if (filter.status) {
      result = result && filter.status === platform.status;
    }
    if (filter.region) {
      result = result && filter.region === platform.region;
    }
    if (filter.lanes) {
      const lanes = Object.values(platform.lanes.byDirection).reduce((acc, arr) => acc + arr.lanesInfo.length, 0);
      result = result && parseInt(filter.lanes, 10) === lanes;
    }

    return result;
  };

  const onFilter = () => {
    if (wimsGridPageState.mapPlatforms && queryUrlParams.filter) {
      const regExp = new RegExp(escapeStringRegexp(queryUrlParams.filter?.search || ''), 'i');
      const selectedPlatform =
        wimsGridPageState.mapPlatforms.filter((p) => filterPredicate(p, queryUrlParams.filter, regExp))[0] || null;

      setFilteredPlatforms(
        wimsGridPageState.mapPlatforms.filter((p) => filterPredicate(p, queryUrlParams.filter, regExp))
      );
      // onPushUrlParams({ uid: selectedPlatform.uid });
      dispatch(wimActions.platforms.clearPlatformSummary());
      handleUpdateWimsListPageState({
        ...wimsGridPageState,
        selectedPlatform,
      });
    }
  };

  useEffect(() => {
    if (queryUrlParams.view === 'tile') {
      dispatch(wimActions.platforms.requestPlatformsSummary(convertDatePeriodToTimePeriod(DatePeriod.LAST_14_DAYS)));
    }
  }, [queryUrlParams.view]);

  useEffect(() => {
    setFilteredPlatforms(wimsGridPageState.mapPlatforms);
    if (!queryUrlParams.filter && !queryUrlParams.wim) {
      onPushUrlParams({ uid: wimsGridPageState.selectedPlatform?.uid });
    }
  }, [wimsGridPageState.mapPlatforms]);

  useEffect(() => {
    setHeaderRenderer(
      <FilterButton isFilterActive={isFilterActive} onOpen={handleFilterOpen} onClose={handleFilterClose} />
    );

    return () => setHeaderRenderer(null);
  }, [isFilterActive, setHeaderRenderer]);

  useEffect(() => {
    if (queryUrlParams.filter) {
      onFilter();
    } else {
      setFilteredPlatforms(wimsGridPageState.mapPlatforms);
    }
  }, [queryUrlParams.filter, wimsGridPageState.mapPlatforms]);

  if (!wimsGridPageState.mapPlatforms || !wimsGridPageState.selectedPlatform || !filteredPlatforms) {
    return <Loader isLoading={isPlatformsLoading} />;
  }

  return (
    <>
      <GridViewNavigation
        gridViews={gridViews}
        defaultActiveKey={queryUrlParams.view as WimGridViewParams}
        onChange={onGridViewChange}
        extraContent={<SortComponent defaultValue={queryUrlParams.sort as WimsListSort} onChange={onSort} />}
      >
        {gridViews[queryUrlParams.view as WimGridViewParams]({
          platforms: filteredPlatforms,
          selectedPlatform: wimsGridPageState.selectedPlatform,
          onSelectPlatform: onItemSelect,
          summary: normalizedSummary,
          isListLoaded: isPlatformsLoaded,
          isSummaryLoading,
        })}
      </GridViewNavigation>
    </>
  );
};

const formatUrlParams = (filterValues: { [key: string]: any }): Partial<WimsGridFilterFormValues> => {
  return Object.keys(filterValues).reduce((acc, currentFilterValueKey) => {
    if (
      currentFilterValueKey === 'search' ||
      currentFilterValueKey === 'road' ||
      currentFilterValueKey === 'region' ||
      currentFilterValueKey === 'status' ||
      currentFilterValueKey === 'lanes' ||
      currentFilterValueKey === 'provider'
    ) {
      acc[currentFilterValueKey] = filterValues[currentFilterValueKey];
    }
    return acc;
  }, {} as Partial<WimsGridFilterFormValues>);
};

const WimsGridPage = withFilter<WimsGridFilterFormValues>({
  filterProps: {
    initialState: {
      search: '',
      status: undefined,
      region: undefined,
      provider: undefined,
      road: undefined,
      lanes: undefined,
    },
    formatUrlParams,
  },
  FilterContent: WimsGridFilter,
})(
  withSort<SortSelectProps>({
    sorters: wimListSorters,
    SortComponent: SortSelect,
  })(WimsGrid)
);

export default WimsGridPage;
