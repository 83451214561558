import { Field } from 'formik';
import styled from 'styled-components/macro';

import palette from 'palette';

export const StyledField = styled(Field)`
  cursor: pointer;
  padding: 5px 12px;
  border: none;
  position: relative;

  :hover {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  [data-theme='dark'] :hover {
    border: 1px solid #434343;
  }
`;

export const StyledError = styled.div`
  color: ${palette.red.r3};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
`;
