import styled from 'styled-components/macro';

import palette from 'palette';

export const StyledDiv = styled.div`
  .ant-menu,
  .ant-menu-item {
    background: ${palette.blue.b4};
  }

  .ant-menu.ant-menu-sub > li,
  .ant-menu.ant-menu-sub {
    padding-left: 30px !important;
  }
`;
