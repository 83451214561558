import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TransitsStatisticType = {
  truckTraffic: number;
  totalTraffic: number;
  date: number;
};

interface TransitsStatisticState {
  data: TransitsStatisticType[];
  isLoading: boolean;
  error: Error | null;
}

const initialState: TransitsStatisticState = {
  data: [],
  isLoading: false,
  error: null,
};

const transitsStatistic = createSlice({
  name: 'transitsStatistic',
  initialState,
  reducers: {
    requestTransitsStatistic: (state, _payload): void => {
      state.isLoading = true;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>): void => {
      state.isLoading = payload;
    },
    getTransitsStatisticSuccess: (state, { payload }): void => {
      state.isLoading = false;
      state.data = payload;
    },
    getTransitsStatisticError: (state, { payload }): void => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { requestTransitsStatistic, getTransitsStatisticSuccess, getTransitsStatisticError, setIsLoading } =
  transitsStatistic.actions;

export default transitsStatistic.reducer;
