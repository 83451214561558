export type UserId = string & { readonly brand: unique symbol };

export type UserSocialNetwork = {
  application: string;
  contact: string;
};

export enum SocialNetwork {
  TELEGRAM = 'TELEGRAM',
  FACEBOOK = 'FACEBOOK',
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum UserPermission {
  WRITE = 'wim:write',
  READ = 'wim:read',
  DELETE = 'wim:delete',
  ANALYTICS = 'wim:analytics',
}

export interface User {
  uid: UserId;
  email: string;
  createDate: number;
  updateDate: number;
  fullName?: string;
  position?: string;
  department?: string;
  status?: UserStatus;
  permissions?: UserPermission[];
  deviceGroups?: number[];
  phone?: string;
  socialNetworks?: UserSocialNetwork[];
  mfaEnabled?: boolean;
  forcePasswordRefresh?: boolean;
}

export interface CreateUser {
  password?: string | null;
  email: string;
  fullName?: string;
  department?: string;
  phone?: string;
  position?: string;
  status?: UserStatus;
  deviceGroups?: number[];
  permissions?: UserPermission[];
  socialNetworks?: UserSocialNetwork[];
  mfaEnabled?: boolean;
  forcePasswordRefresh?: boolean;
}

export interface UpdateUser {
  password?: string | null;
  uid: UserId;
  fullName?: string;
  department?: string;
  email?: string;
  phone?: string;
  position?: string;
  status?: UserStatus;
  deviceGroups?: number[];
  permissions?: UserPermission[];
  socialNetworks?: UserSocialNetwork[];
  mfaEnabled?: boolean;
  forcePasswordRefresh?: boolean;
}

export interface UserLogActivityQuery {
  initiatorUid: UserId;
  fileName?: string;
}

export type LogDetails = {
  ip: string;
  userAgent: string;
};

export interface UserLogActivity {
  action: string;
  entity: string;
  createDate: number;
  details: any;
  description: string;
}

export type UserEditRequest = Exclude<User, 'uid' | 'createDate' | 'updateDate'>;
