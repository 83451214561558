import { useCallback } from 'react';

import { OnTableChangeAction } from 'components/TableContainer/DefaultTableContainer';
import { useUrlParams } from './use-url-params';

function useSorting<TableRecord>() {
  const { queryUrlParams, onPushUrlParams, onUrlParamsChange } = useUrlParams({});

  const onSortingChange: OnTableChangeAction = useCallback(
    (_pagination, _filter, sorter) => {
      if (Array.isArray(sorter)) {
        return;
      }

      const { columnKey, order } = sorter;

      onUrlParamsChange({ sort: { key: columnKey, value: order || 'ascend' } });
      onPushUrlParams({ sort: { key: columnKey, value: order || 'ascend' } });
    },
    [queryUrlParams]
  );

  return {
    onSortingChange,
    sortingKey: queryUrlParams?.sort?.key,
    sortingOrder: queryUrlParams?.sort?.value,
  };
}

export { useSorting };
