import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { DefaultTableContainer, ModalWrapper } from 'components';
import { stringifyUrlParams, useUrlParams } from 'hooks/use-url-params';
import { useAppDispatch } from 'hooks/store-hooks';
import { Modal } from 'types/modal';
import { Group } from 'store/admin/groups/types';
import { requestDeleteGroupBindings, requestPostGroupBindings } from 'store/admin/platforms/wimGroups/slice';
import { StyledEditLink, StyledContent } from './styles';
import { groupsColumns } from './table-schema';
import { UpdateContent } from './modal/UpdateContent';

interface GroupsSummaryProps {
  groups: Group[];
  isEditable?: boolean;
  wimUid: string;
  isWimGroupsLoading: boolean;
}

const GroupsSummary: React.FC<GroupsSummaryProps> = ({ groups, isEditable, wimUid, isWimGroupsLoading }) => {
  const { queryUrlParams } = useUrlParams({});
  const dispatch = useAppDispatch();
  const {
    location: { pathname, search },
    goBack,
  } = useHistory();
  const { t } = useTranslation();

  const addNewGroupBinding = (id: number) => {
    dispatch(requestPostGroupBindings({ wimUid, groupId: id }));
  };
  const deleteBinding = (id: number) => {
    dispatch(requestDeleteGroupBindings({ wimUid, groupId: id }));
  };

  return (
    <StyledContent>
      <StyledEditLink>
        <Link
          to={{
            pathname,
            search: stringifyUrlParams({ ...queryUrlParams, modal: Modal.EDIT_PLATFORM_GROUPS, platform: '' }),
          }}
        >
          <EditOutlined style={{ fontSize: 20 }} />
        </Link>
      </StyledEditLink>
      <DefaultTableContainer columns={groupsColumns} dataSource={groups} bordered />
      {isEditable && (
        <ModalWrapper
          isVisible={search.includes(`modal=${Modal.EDIT_PLATFORM_GROUPS}`)}
          onClose={goBack}
          title={t('adminWimsPage.updateWimGroups')}
          width={900}
          destroyOnClose
        >
          <UpdateContent
            groups={groups}
            onAddNew={addNewGroupBinding}
            onDelete={deleteBinding}
            isLoading={isWimGroupsLoading}
          />
        </ModalWrapper>
      )}
    </StyledContent>
  );
};

export { GroupsSummary };
