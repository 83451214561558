import React from 'react';
import { useDispatch } from 'react-redux';
import { Menu as ANTMenu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  VideoCameraOutlined,
  AimOutlined,
  ProfileOutlined,
  FundProjectionScreenOutlined,
  SettingOutlined,
  UserOutlined,
  CloudServerOutlined,
  FieldTimeOutlined,
  ExportOutlined,
} from '@ant-design/icons';

import { Logo } from 'components';
import { AccessControl } from 'components/AccessControl';
import {
  DETECTORS_MENU_ITEMS,
  DEVIATIONS_MENU_ITEMS,
  TRANSITS_MENU_ITEMS,
  WIM_MENU_ITEMS,
} from 'app-constants/nav-bar-items';
import { ROUTES } from 'app-constants/routes';
import env from 'config/env';
import { NavMenuProps } from 'components/NavBar';
import { stringifyUrlParams } from 'hooks/use-url-params';
import { authActions } from 'store/auth/slice';

import NavBarItem from '../NavBarItem';
import { UserPermission } from '../../../../store/user/types';
import LanguageSwitcher from '../../../LanguageSwitcher';

const { SubMenu } = ANTMenu;

export const MainNavMenu: React.FC<NavMenuProps> = ({ collapsed, user, openKeys, selectedKeys, onOpenChange }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const onSignOutClick = () => {
    dispatch(authActions.requestLogout());
  };

  return (
    <>
      <ANTMenu
        theme="dark"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultSelectedKeys={['/']}
        mode="inline"
        selectedKeys={selectedKeys}
      >
        <Logo collapsed={collapsed} onClick={() => history.push('/')} />
        {env.DETECTORS_ENABLED && (
          <SubMenu key={ROUTES.Detectors} icon={<VideoCameraOutlined />} title={t('navBar.detectorDevices')}>
            {DETECTORS_MENU_ITEMS.map((menuItem) => (
              <NavBarItem key={menuItem.selectedKey} labelKey={menuItem.labelKey} route={menuItem.route} />
            ))}
          </SubMenu>
        )}
        <SubMenu key={ROUTES.Wim} icon={<AimOutlined />} title={t('navBar.wim')}>
          {WIM_MENU_ITEMS.map((menuItem) => (
            <NavBarItem key={menuItem.selectedKey} labelKey={menuItem.labelKey} route={menuItem.route} />
          ))}
        </SubMenu>
        <SubMenu key={ROUTES.Transits} icon={<ProfileOutlined />} title={t('navBar.transits')}>
          {TRANSITS_MENU_ITEMS.map((menuItem) => (
            <NavBarItem key={menuItem.selectedKey} labelKey={menuItem.labelKey} route={menuItem.route} />
          ))}
        </SubMenu>

        <SubMenu key={ROUTES.Violations} icon={<FundProjectionScreenOutlined />} title={t('navBar.deviations')}>
          {DEVIATIONS_MENU_ITEMS.map((menuItem) => (
            <NavBarItem key={menuItem.selectedKey} labelKey={menuItem.labelKey} route={menuItem.route} />
          ))}
          <AccessControl
            key={ROUTES.ComplexesExport}
            acl={[UserPermission.ANALYTICS]}
            permissions={user.permissions}
            redirect={false}
          >
            <NavBarItem
              key={ROUTES.ComplexesExport}
              labelKey="businessAnalytic"
              route={{ pathname: env.WAGA_ANALYTICS_URL }}
              isExternalLink
            />
          </AccessControl>
        </SubMenu>
        <NavBarItem
          key={ROUTES.ServerMonitoring}
          labelKey="mediaStorage"
          route={{ pathname: ROUTES.ServerMonitoring }}
          icon={<CloudServerOutlined />}
        />
        <NavBarItem
          key={ROUTES.ExternalNotifications}
          labelKey="notifications"
          route={{ pathname: ROUTES.ExternalNotifications }}
          icon={<FieldTimeOutlined />}
        />
        <NavBarItem
          key={ROUTES.ExternalConnectors}
          labelKey="externalSync"
          route={{ pathname: ROUTES.ExternalConnectors }}
          icon={<ExportOutlined />}
        />
      </ANTMenu>
      <ANTMenu
        theme="dark"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultSelectedKeys={['/']}
        selectedKeys={selectedKeys}
        selectable={false}
        mode="inline"
      >
        <SubMenu key="profile" title={user.fullName} icon={<UserOutlined />}>
          <NavBarItem key={ROUTES.Profile} labelKey="profile" route={ROUTES.Profile} />
          <LanguageSwitcher />
          <NavBarItem key="sign-out" labelKey="signOut" route={ROUTES.Authorization} onClick={onSignOutClick} />
        </SubMenu>
        <AccessControl
          key={ROUTES.AdminWims}
          acl={[UserPermission.WRITE]}
          permissions={user.permissions}
          redirect={false}
        >
          <NavBarItem
            key={ROUTES.AdminWims}
            labelKey="admin"
            route={{ pathname: ROUTES.AdminWims, search: stringifyUrlParams({ sort: 'default' }) }}
            icon={<SettingOutlined />}
          />
        </AccessControl>
      </ANTMenu>
    </>
  );
};
