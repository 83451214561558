enum ROUTES {
  Home = '/',
  Locations = '/locations',
  Violations = '/violations',
  WimViolations = '/violations/wim',
  ComparativeAnalysis = '/violations/comparative-analysis',
  Detectors = '/detectors',
  NotFound = '/404',
  PermissionDenied = '/403',
  Accidents = '/accidents',
  Complexes = '/complexes',
  ComplexesExport = '/complexes/export',
  TransitsSpeed = '/transitsspeed',
  ParkingsCameras = '/parkings/cameras',
  ParkingsCoverage = '/parkings/coverage',
  Platforms = '/platforms',
  Suspicions = '/suspicions',
  Wim = '/wim',
  Transit = '/transits/transit',
  TransitDocument = '/transits/transit-document',
  Transits = '/transits',
  TransitsOnline = '/transits/online',
  TransitsOnlineWim = '/transits/online/wim',
  TransitsIntensityTraffic = '/transits/intensity-traffic',
  TransitsOnlineDetectors = '/transits/online/detectors',
  TransitsArchive = '/transits/archive',
  TransitsArchiveWim = '/transits/archive/wim',
  TransitsArchiveDetectors = '/transits/archive/detectors',
  TransitsMap = '/transits/map',
  Card = '/card',
  VehicleClassification = '/vehicle-classifications',
  WimsMap = '/wim/map',
  WimsGrid = '/wim/grid',
  AdminHome = '/admin',
  Profile = '/profile',
  Authorization = '/authorization',
  WimsMonitoring = '/wim/monitoring',
  AdminUsers = '/admin/users',
  SystemSettings = '/admin/system-settings',
  AdminWims = '/admin/wims',
  AdminGroups = '/admin/groups',
  UserActivity = '/user-activity',
  Statistic = 'statistic',
  StatisticAvailability = '/availability',
  StatisticTrafficIntensity = '/traffic-intensity',
  StatisticTotalTraffic = '/total-traffic',
  StatisticTruckTraffic = '/truck-traffic',
  StatisticOverload = '/overload',
  StatisticViolations = '/violations',
  StatisticOffenders = '/offenders',
  StatisticTotalAndTruckTrafficByTimePeriods = '/total-and-truck-traffic-by-time-periods',
  StatisticOverloadByTimePeriods = '/overload-by-time-periods',
  StatisticVrpRecognitionByTimePeriods = '/vrp-recognition-by-time-periods',
  ServerMonitoring = '/cluster/monitoring',
  ExternalConnectors = '/external/connectors',
  ExternalNotifications = '/external/notifications',
}

export { ROUTES };
