import React from 'react';

import { useTranslation } from 'react-i18next';
import { ColumnGroupType, ColumnType } from 'antd/lib/table';

import palette from 'palette';

import { StyledLegendItemMarker } from 'components/BarChart/Legends/ViolationsLegend/styles';
import { VehicleTypes } from 'store/platforms/wims/wim/transits/summary/types';

import { getTableTitle } from 'pages/Violations/helpers/get-table-title';
import { getIntegerPercent } from 'helpers/percent.formatter';
import VrpLink from 'pages/Violations/helpers/VrpLink';
import dayjs from 'dayjs';

export type SummaryTableRow = {
  currentWeight: number;
  datetime: number;
  error: number;
  medianWeight: number;
  type: string;
  vrp: string;
};

export type SummaryTableColumns = {
  [key in keyof Omit<SummaryTableRow, 'datetime'>]: ColumnType<SummaryTableRow> | ColumnGroupType<SummaryTableRow>;
};

export const GetDetailTableColumns = (): SummaryTableColumns => {
  const { t } = useTranslation();

  return {
    type: {
      title: () => getTableTitle(t('violationsPage.detail.type')),
      dataIndex: 'type',
      key: 'type',
      showSorterTooltip: false,
      sorter: (a, b) => a.type.length - b.type.length,
      width: 85,
      render: (type: VehicleTypes) => (
        <StyledLegendItemMarker dataKey={type} width="30px" height="20px" color={palette.orange.o2} />
      ),
    },
    vrp: {
      title: () => getTableTitle(t('violationsPage.detail.vrp')),
      showSorterTooltip: false,
      dataIndex: 'vrp',
      key: 'vrp',
      width: 120,
      render: (text: string, row) => (
        <VrpLink
          vrp={text}
          start={dayjs(row.datetime).startOf('day').valueOf()}
          end={dayjs(row.datetime).endOf('day').valueOf()}
        />
      ),
    },
    medianWeight: {
      title: () => getTableTitle(t('violationsPage.detail.medianWeight')),
      showSorterTooltip: false,
      dataIndex: 'medianWeight',
      key: 'medianWeight',
      width: 85,
      sorter: (a, b) => a.medianWeight - b.medianWeight,
    },
    currentWeight: {
      title: () => getTableTitle(t('violationsPage.detail.currentWeight')),
      showSorterTooltip: false,
      dataIndex: 'currentWeight',
      key: 'currentWeight',
      width: 85,
      sorter: (a, b) => a.currentWeight - b.currentWeight,
    },
    error: {
      title: () => getTableTitle(t('violationsPage.detail.error')),
      showSorterTooltip: false,
      dataIndex: 'error',
      key: 'error',
      width: 85,
      sorter: (a, b) => a.error - b.error,
      render: (err: number) => <span>{getIntegerPercent(err)}</span>,
    },
  };
};
