import { useSelector } from 'react-redux';

import wimSelectors from 'store/wim/selectors';

function useWimState() {
  const platforms = useSelector(wimSelectors.platforms.getPlatforms);
  const isPlatformsLoading = useSelector(wimSelectors.platforms.isPlatformsLoading);

  return {
    platforms,
    isPlatformsLoading,
    isPlatformsLoaded: platforms && !!platforms.length,
  };
}

export default useWimState;
