import { useCallback, useEffect } from 'react';

import { useAppDispatch } from 'hooks/store-hooks';
import { wimActions } from 'store/wim/slices';
import { GetWimVehicleClassificationsQueryParams } from 'store/wim/types';

export function useGetWimVehicleClassifications(query: GetWimVehicleClassificationsQueryParams = {}): void {
  const dispatch = useAppDispatch();

  const fetchVehicleClassifications = useCallback(() => {
    dispatch(wimActions.vehicleClassifications.requestWimVehicleClassifications(query));
  }, [dispatch, query.filter, query.lang]);

  useEffect(() => {
    fetchVehicleClassifications();
  }, [fetchVehicleClassifications]);
}
