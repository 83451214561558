import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { createGroup, getGroups, updateGroup } from 'api/groups';
import { SagaPayload } from 'types';
import { CreateGroup, Group, UpdateGroup } from 'store/admin/groups/types';
import {
  requestGroups,
  getGroupsSuccess,
  getGroupsError,
  requestCreateGroup,
  postCreateGroupSuccess,
  postCreateGroupError,
  requestUpdateGroup,
  // getNewUserError,
} from './slice';
import adminSelectors from '../selectors';
import { setNotificationError, setNotificationSuccess } from '../notifications/slice';
import { MessageTitle } from '../notifications/types';

export function* groupsRequestSaga() {
  try {
    const response: AxiosResponse<Group[]> = yield call(getGroups, {});
    if (response) {
      yield put(getGroupsSuccess(response.data));
    }
  } catch (error) {
    yield put(getGroupsError(error.message));
  }
}

export function* groupUpdateRequestSaga({ payload }: SagaPayload<UpdateGroup>) {
  const groups: readonly Group[] = yield select(adminSelectors.groups.getGroups);

  const response: AxiosResponse<Group> | null | never = yield call(updateGroup(payload), {});
  const updatedGroup = response?.data;

  if (updatedGroup) {
    yield put(setNotificationSuccess({ title: MessageTitle.crudSuccess }));
    const newGroups = [...groups].reduce<Group[]>((acc, currentGroup) => {
      if (currentGroup.id === updatedGroup.id) {
        return [...acc, updatedGroup];
      }

      return [...acc, currentGroup];
    }, []);

    yield put(getGroupsSuccess(newGroups));
  }
}

export function* createGroupRequestSaga({ payload }: SagaPayload<CreateGroup>) {
  try {
    const newGroup = yield call(createGroup(payload), {});
    yield put(setNotificationSuccess({ title: MessageTitle.crudSuccess }));
    yield put(postCreateGroupSuccess(newGroup.data));
  } catch (error) {
    yield put(setNotificationError({ title: MessageTitle.crudError }));
    yield put(postCreateGroupError(error));
  }
}

export function* groupsRequestWatchSaga() {
  yield takeLatest(requestGroups, groupsRequestSaga);
}

export function* createGroupRequestWatchSaga() {
  yield takeLatest(requestCreateGroup, createGroupRequestSaga);
}

export function* groupUpdateRequestWatchSaga() {
  yield takeLatest(requestUpdateGroup, groupUpdateRequestSaga);
}
