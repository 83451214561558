import { RootState } from 'store/root-reducer';
import { User } from './types';

const isUserLoading = (state: RootState): boolean => state.user.isLoading;
const getUser = (state: RootState): User | null => state.user.entity;

export const userSelectors = {
  isUserLoading,
  getUser,
};
