import styled from 'styled-components/macro';
import 'react-image-gallery/styles/css/image-gallery.css';

import palette from 'palette';
import { Tabs } from 'antd';

import SFUITextMedium from 'fonts/SFUIText-Medium.woff';

interface TagProps {
  isError?: boolean;
}

interface StyledCellTextProps {
  isError: boolean;
}

interface StyledPercentTextProps {
  value: number;
}

export const TransitContainer = styled.div`
  background-color: ${palette.white.w1};
  padding: 24px 15px;
  margin: 24px 15px;
`;

export const TransitTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid black;
  margin-bottom: 30px;
  color: ${palette.black.b4};
`;

export const TransitTitle = styled.p`
  @font-face {
    font-family: 'SFUITextMedium';
    font-size: 16px;
    src: url(${SFUITextMedium}) format('woff');
  }
  font-family: 'SFUITextMedium';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  margin: 0;
`;

export const TransitDate = styled.span`
  @font-face {
    font-family: 'SFUITextMedium';
    font-size: 16px;
    src: url(${SFUITextMedium}) format('woff');
  }
  font-family: 'SFUITextMedium';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
`;

export const TransitInfoContainer = styled.div`
  display: flex;
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px;
`;

export const TagsSection = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
  align-items: baseline;
`;

export const TransitInfoSection = styled.div`
  margin-right: 56px;
`;

export const Tag = styled.span<TagProps>`
  display: inline-block;
  height: auto;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 2px 10px;
  color: ${palette.white.w1};

  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background-color: ${({ isError }) => (isError ? palette.red.r3 : palette.orange.o1)};

  border-radius: 4px;
  box-shadow: '0 0 10px rgba(0, 0, 0, 0.25)';
`;

export const InfoBarContainer = styled.div`
  display: flex;
  background-color: rgba(245, 245, 245, 0.5);
  padding: 15px;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 15px;
  border-radius: 2px;
`;

export const TooltipContentContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TooltipTitleContainer = styled.div`
  max-width: 500px;
  font-size: 14px;
  color: ${palette.black.b2};
  white-space: 'pre-line';
`;

export const Title = styled.span`
  margin-right: 5px;
  color: ${palette.black.b3};
`;

export const Text = styled.p`
  @font-face {
    font-family: 'SFUITextMedium';
    font-size: 16px;
    src: url(${SFUITextMedium}) format('woff');
  }
  font-family: 'SFUITextMedium';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  color: ${palette.black.b4};
`;

export const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  ::before {
    content: '';
    position: static;
    top: 0;
    width: 100%;
    margin: 0 0 25px 0;
    height: 50px;
    background: ${palette.grey.g4};
  }
  .image-gallery-slide img {
    height: 400px;
    width: 800px;
  }

  .image-gallery-content.fullscreen .image-gallery-slide img {
    height: 800px;
    width: 1000px;
  }

  .image-gallery-thumbnail {
    transition: unset;
    &:hover {
      border: 2px solid ${palette.blue.b7};
    }

    &:focus {
      border: 2px solid ${palette.blue.b7};
    }
  }

  .image-gallery-thumbnail.active {
    border: 2px solid ${palette.blue.b7};

    &:hover {
      border: 2px solid ${palette.blue.b7};
    }
  }

  .image-gallery-icon {
    &:hover {
      color: ${palette.blue.b7};
    }
  }

  .image-gallery-slide span {
    width: 100%;
    bottom: 0;
  }
`;

export const StyledCellText = styled.span<StyledCellTextProps>`
  color: ${({ isError }) => (isError ? palette.red.r3 : 'inherit')};
`;

export const StyledPercentText = styled.span<StyledPercentTextProps>`
  color: ${({ value }) => (value > 0 ? palette.red.r3 : palette.blue.b3)};
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 32px;
  }
  .ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 12px 0;
    font-size: 14px;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
  }
`;

export const SpinContainer = styled.div`
  margin: 60px;
  text-align: center;
  width: 100%;
`;
