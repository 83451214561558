import { Select } from 'antd';
import palette from 'palette';
import styled from 'styled-components/macro';

export const StyledSelect = styled(Select)`
  width: 100%;
`;

export const StyledFilter = styled.div`
  display: flex;
  width: 100%;
  background: ${palette.white.w1};
  padding: 20px;

  .ant-picker-dropdown {
    z-index: 1000000;
  }
`;
