import React, { FC } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import type { WimGridView, WimGridViewParams } from './types';
import { StyledGridWrapper } from './styles';

interface GridViewNavigationProps {
  gridViews: WimGridView;
  defaultActiveKey: WimGridViewParams;
  onChange: (view: WimGridViewParams | string) => void;
  extraContent?: JSX.Element;
}

const GridViewNavigation: FC<GridViewNavigationProps> = ({
  gridViews,
  defaultActiveKey,
  onChange,
  extraContent,
  children,
}) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;

  return (
    <StyledGridWrapper>
      <Tabs defaultActiveKey={defaultActiveKey} onChange={onChange} tabBarExtraContent={extraContent}>
        {Object.keys(gridViews).map((key) => (
          <TabPane tab={t(`lists.gridViews.${key as WimGridViewParams}` as const)} tabKey={key} key={key}>
            {children}
          </TabPane>
        ))}
      </Tabs>
    </StyledGridWrapper>
  );
};

export { GridViewNavigation };
