import dayjs from 'dayjs';
import { isEmpty, isNull, isUndefined } from 'lodash';
import { WimPlatformUid } from 'store/wim/types';

const isValueSignificant = (value: unknown): boolean => !isEmpty(value) || !isUndefined(value) || !isNull(value);

enum ViolationsArchiveFilterKey {
  wimUid = 'wimUid',
  start = 'start',
  end = 'end',
}
export interface ViolationsFilterValues {
  wimUid: WimPlatformUid;
  start: number;
  end: number;
}

export const queryParamsToViolationsFilterValues = (filterValues: {
  [key: string]: any;
}): Partial<ViolationsFilterValues> => {
  return Object.keys(filterValues).reduce((acc, currentFilterValueKey) => {
    if (isValueSignificant(filterValues[currentFilterValueKey])) {
      acc[currentFilterValueKey as keyof ViolationsFilterValues] = filterValues[currentFilterValueKey];
    }

    if (currentFilterValueKey === ViolationsArchiveFilterKey.start) {
      acc[currentFilterValueKey] = filterValues[currentFilterValueKey]
        ? dayjs(parseInt(filterValues[currentFilterValueKey], 10)).valueOf()
        : dayjs().subtract(1, 'hour').valueOf();
    }

    if (currentFilterValueKey === ViolationsArchiveFilterKey.end) {
      acc[currentFilterValueKey] = filterValues[currentFilterValueKey]
        ? dayjs(parseInt(filterValues[currentFilterValueKey], 10)).valueOf()
        : dayjs().valueOf();
    }

    if (currentFilterValueKey === ViolationsArchiveFilterKey.wimUid) {
      acc[currentFilterValueKey] = filterValues[currentFilterValueKey];
    }

    return acc;
  }, {} as Partial<ViolationsFilterValues>);
};
