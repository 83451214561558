import { TableProps } from 'antd';
import { WimTransitAxle, WimTransitAxleGroup } from 'store/wim/types/transits';

import { MAX_AXLES_COUNT } from '../constants/axles';
import { AxlesTableRow } from '../Transit/table-schema';

export function getAxleInfo(
  rawAxles: WimTransitAxle[],
  rawAxlesGroups: WimTransitAxleGroup[]
): TableProps<AxlesTableRow>['dataSource'] {
  if (!rawAxles || rawAxles.length === 0) return [];

  let currentAxleIndex = 0;
  const axlesInfo: AxlesTableRow[] = [];

  rawAxlesGroups.forEach((group, groupIndex) => {
    let axles = [];

    const firstAxleIndex = group.firstAxle + 1;
    const lastAxleIndex = group.lastAxle + 1;

    if (firstAxleIndex > currentAxleIndex) {
      axles = rawAxles.slice(currentAxleIndex, firstAxleIndex - 1);

      axles.forEach((axle) => {
        axlesInfo.push(axle);
      });
    }

    axles = rawAxles.slice(firstAxleIndex - 1, lastAxleIndex);
    axles = axles.map((axle) => ({
      ...axle,
      groupIndex: groupIndex + 1,
    }));

    axlesInfo.push({
      weight: group.totalWeight,
      overweightPercent: group.overweightPercent,
      totalWeightWithError: group.totalWeightWithError,
      totalWeightErrorPercent: group.totalWeightErrorPercent,
      weightLimit: group.totalWeightLimit,
      groupIndex: groupIndex + 1,
      number: -1,
      wheelbase: 0,
      maxOverAxleLoad: group.maxOverAxleLoad,
    });

    axles.forEach((axle) => {
      axlesInfo.push(axle);
    });

    currentAxleIndex = lastAxleIndex;
  });

  if (currentAxleIndex < MAX_AXLES_COUNT) {
    const axles = rawAxles.slice(currentAxleIndex, MAX_AXLES_COUNT);
    axles.forEach((axle) => {
      axlesInfo.push(axle);
    });
  }

  return axlesInfo;
}
