import i18n from 'i18n';
import { PLATE_FIELDS } from 'app-constants/plate-fields';

export const plateFormatter = (plate: string | null): string => {
  if (plate === PLATE_FIELDS.PlateNoRead || plate === PLATE_FIELDS.PlateNoReadSecond || plate === '' || !plate) {
    return i18n.t('transitPage.plateNotRecognized');
  }

  return plate.trim();
  // .replace(/^(\d+)(\D.*)$/g, '$1 $2')
  // .replace(/^(.*\D)(\d+)(\D.*)$/g, '$1 $2 $3')
  // .replace(/^(.*\D)(\d+)$/g, '$1 $2')
};

export const getInputSuffixFormatter =
  (suffix: string) =>
  (value?: number): string =>
    value ? `${value} ${suffix}` : '';

export const formatWimName = (name: string): string => name;
export const formatWimAddress = (address: string): string => address;
