import zeroId from 'assets/images/vehicles/0.svg';
import threeId from 'assets/images/vehicles/3.svg';
import fourId from 'assets/images/vehicles/4.svg';
import fiveId from 'assets/images/vehicles/5.svg';
import sixId from 'assets/images/vehicles/6.svg';
import sevenId from 'assets/images/vehicles/7.svg';
import eightId from 'assets/images/vehicles/8.svg';
import nineId from 'assets/images/vehicles/9.svg';
import tenId from 'assets/images/vehicles/10.svg';
import elevenId from 'assets/images/vehicles/11.svg';
import twelveId from 'assets/images/vehicles/12.svg';
import thirteenId from 'assets/images/vehicles/13.svg';
import fourteenId from 'assets/images/vehicles/14.svg';
import fiveteenId from 'assets/images/vehicles/15.svg';
import sixteenId from 'assets/images/vehicles/16.svg';
import seventeenId from 'assets/images/vehicles/17.svg';
import eighteenId from 'assets/images/vehicles/18.svg';
import nineteenId from 'assets/images/vehicles/19.svg';
import twentyId from 'assets/images/vehicles/20.svg';

const VEHICLE_CLASSES = [
  {
    id: '0',
    lang: 'UK',
    name: 'Транспортний засіб не класифікується',
    icon: zeroId,
  },
  {
    id: '1',
    lang: 'UK',
    name: 'Легковий автомобіль',
    icon: threeId,
  },
  {
    id: '1',
    lang: 'UK',
    name: 'Легковий автомобіль, мінівен',
    icon: threeId,
  },
  {
    id: '2',
    lang: 'UK',
    name: 'Фургон',
    icon: threeId,
  },
  {
    id: '3',
    lang: 'UK',
    name: 'Легковий автомобіль з 1-вісним причепом',
    icon: threeId,
  },
  {
    id: '4',
    lang: 'UK',
    name: 'Легковий автомобіль з 2-вісним причепом',
    icon: threeId,
  },
  {
    id: '5',
    lang: 'UK',
    name: '2-вісний вантажний автомобіль',
    icon: fourId,
  },
  {
    id: '6',
    lang: 'UK',
    name: '3-вісний вантажний автомобіль (1+2)',
    icon: fiveId,
  },
  {
    id: '7',
    lang: 'UK',
    name: '3-вісний вантажний автомобіль (2+1)',
    icon: fiveId,
  },
  {
    id: '8',
    lang: 'UK',
    name: '4-вісний вантажний автомобіль (1+3)',
    icon: sixId,
  },
  {
    id: '9',
    lang: 'UK',
    name: '4-вісний вантажний автомобіль (2+2)',
    icon: sixId,
  },
  {
    id: '10',
    lang: 'UK',
    name: '2-вісний вантажний автомобіль із 1-вісним причепом',
    icon: eightId,
  },
  {
    id: '11',
    lang: 'UK',
    name: '2-вісний вантажний автомобіль із 2-вісним причепом (2)',
    icon: eightId,
  },
  {
    id: '12',
    lang: 'UK',
    name: '2-вісний вантажний автомобіль із 2-вісним причепом (1+1)',
    icon: eightId,
  },
  {
    id: '13',
    lang: 'UK',
    name: '2-вісний вантажний автомобіль (1+1) із 3-вісним причепом (1+2)',
    icon: tenId,
  },
  {
    id: '14',
    lang: 'UK',
    name: '3-вісний вантажний автомобіль (1+2) із 1-вісним причепом',
    icon: nineId,
  },
  {
    id: '15',
    lang: 'UK',
    name: '4-вісний вантажний автомобіль (1+3) із 1-вісним причепом',
    icon: elevenId,
  },
  {
    id: '16',
    lang: 'UK',
    name: '3-вісний вантажний автомобіль (2+1) із 1-вісним причепом',
    icon: nineId,
  },
  {
    id: '17',
    lang: 'UK',
    name: '3-вісний вантажний автомобіль (2+1) із 2-вісним причепом (2)',
    icon: nineId,
  },
  {
    id: '18',
    lang: 'UK',
    name: '4-вісний вантажний автомобіль (2+2) із 1-вісним причепом',
    icon: elevenId,
  },
  {
    id: '19',
    lang: 'UK',
    name: '3-вісний вантажний автомобіль (1+2) із 2-вісним причепом (1+1)',
    icon: nineId,
  },
  {
    id: '20',
    lang: 'UK',
    name: '3-вісний вантажний автомобіль (1+2) із 3-вісним причепом (1+2)',
    icon: elevenId,
  },
  {
    id: '21',
    lang: 'UK',
    name: '2-вісний тягач із 1-вісним напівпричепом',
    icon: twelveId,
  },
  {
    id: '22',
    lang: 'UK',
    name: '2-вісний тягач із 2-вісним напівпричепом',
    icon: thirteenId,
  },
  {
    id: '23',
    lang: 'UK',
    name: '2-вісний тягач із 3-вісним напівпричепом',
    icon: fourteenId,
  },
  {
    id: '24',
    lang: 'UK',
    name: '3-вісний тягач із 1-вісним напівпричепом',
    icon: fiveteenId,
  },
  {
    id: '25',
    lang: 'UK',
    name: '3-вісний тягач із 2-вісним напівпричепом',
    icon: fiveteenId,
  },
  {
    id: '26',
    lang: 'UK',
    name: '3-вісний тягач із 3-вісним напівпричепом',
    icon: sixteenId,
  },
  {
    id: '27',
    lang: 'UK',
    name: '2-вісний автобус',
    icon: nineteenId,
  },
  {
    id: '28',
    lang: 'UK',
    name: '3-вісний автобус',
    icon: nineteenId,
  },
  {
    id: '29',
    lang: 'UK',
    name: 'ТЗ з декількома причепами (для ФУАД) /> = 7 осей (інші)',
    icon: seventeenId,
  },
  {
    id: '30',
    lang: 'UK',
    name: 'Мотоцикл',
    icon: twentyId,
  },
  {
    id: '31',
    lang: 'UK',
    name: '2-вісний тягач',
    icon: fourId,
  },
  {
    id: '32',
    lang: 'UK',
    name: '3-вісний тягач',
    icon: fiveId,
  },
  {
    id: '33',
    lang: 'UK',
    name: '3-вісний вантажний автомобіль із 2-вісним причепом',
    icon: nineId,
  },
  {
    id: '34',
    lang: 'UK',
    name: 'Фургон з 1-вісним причепом',
    icon: threeId,
  },
  {
    id: '35',
    lang: 'UK',
    name: 'Фургон з 2-вісним причепом',
    icon: threeId,
  },
  {
    id: '36',
    lang: 'UK',
    name: 'Автопоїзд із трьома осями',
    icon: nineteenId,
  },
  {
    id: '37',
    lang: 'UK',
    name: 'Автопоїзд із чотирма осями, 4-Axle',
    icon: nineteenId,
  },
  {
    id: '38',
    lang: 'UK',
    name: '4-вісний вантажний автомобіль із 2-вісним причепом',
    icon: nineId,
  },
  {
    id: '39',
    lang: 'UK',
    name: 'Спеціальний вантажний автомобіль',
    icon: sevenId,
  },
  {
    id: '40',
    lang: 'UK',
    name: 'Фургон доставки з осьовою дистанцією 3-4.5м, вагою до 7.5 тонн',
    icon: threeId,
  },
  {
    id: '41',
    lang: 'UK',
    name: 'Фургон з напівпричепом (швейцарський)',
    icon: threeId,
  },
  {
    id: '42',
    lang: 'UK',
    name: '5-вісний вантажівка (5 і більше осей для ФУАД)',
    icon: sevenId,
  },
  {
    id: '43',
    lang: 'UK',
    name: '4-вісний автобус',
    icon: nineteenId,
  },
  {
    id: '44',
    lang: 'UK',
    name: '2-A тягач, 1-A напівпричеп, 2-A причеп',
    icon: fourteenId,
  },
  {
    id: '45',
    lang: 'UK',
    name: '2-A тягач, 2-A напівпричеп, 2-A причеп',
    icon: fourteenId,
  },
  {
    id: '46',
    lang: 'UK',
    name: '2-A тягач, 1-A напівпричеп, 3-A причеп',
    icon: fourteenId,
  },
  {
    id: '47',
    lang: 'UK',
    name: '3-A тягач, 1-A напівпричеп, 2-A причеп',
    icon: sixteenId,
  },
  {
    id: '48',
    lang: 'UK',
    name: '3-A тягач, 1-A напівпричеп, 3-A причеп',
    icon: seventeenId,
  },
  {
    id: '49',
    lang: 'UK',
    name: '3-A тягач, 2-A напівпричеп, 2-A причеп',
    icon: seventeenId,
  },
  {
    id: '50',
    lang: 'UK',
    name: '3-A тягач, 2-A напівпричеп, 3-A причеп',
    icon: seventeenId,
  },
  {
    id: '51',
    lang: 'UK',
    name: '3-A тягач, 2-A напівпричеп, 4-A причеп',
    icon: seventeenId,
  },
  {
    id: '52',
    lang: 'UK',
    name: '2-A тягач, 2-A напівпричеп, 2-A напівпричеп',
    icon: fourteenId,
  },
  {
    id: '53',
    lang: 'UK',
    name: '3-A тягач, 2-A напівпричеп, 2-A напівпричеп',
    icon: seventeenId,
  },
  {
    id: '54',
    lang: 'UK',
    name: '3-A тягач, 3-A напівпричеп, 2-A напівпричеп',
    icon: seventeenId,
  },
  {
    id: '55',
    lang: 'UK',
    name: '2-вісний вантажний автомобіль (1+1) із 3-вісним причепом (3)',
    icon: tenId,
  },
  {
    id: '56',
    lang: 'UK',
    name: '3-вісний вантажний автомобіль із 3-вісним причепом',
    icon: elevenId,
  },
  {
    id: '57',
    lang: 'UK',
    name: 'Мобільний кран (багатовісний)',
    icon: sevenId,
  },
  {
    id: '58',
    lang: 'UK',
    name: 'Буде визначений',
    icon: twentyId,
  },
  {
    id: '59',
    lang: 'UK',
    name: 'Буде визначений',
    icon: twentyId,
  },
  {
    id: '60',
    lang: 'UK',
    name: '2-вісна сільськогосподарська техніка',
    icon: eighteenId,
  },
  {
    id: '61',
    lang: 'UK',
    name: '2-вісна сільськогосподарська техніка із 1-вісним причепом, ',
    icon: eighteenId,
  },
  {
    id: '62',
    lang: 'UK',
    name: '2-вісна сільськогосподарська техніка із 2-вісним причепом',
    icon: eighteenId,
  },
  {
    id: '63',
    lang: 'UK',
    name: '2-вісна сільськогосподарська техніка із 3-вісним причепом',
    icon: eighteenId,
  },
];

export { VEHICLE_CLASSES };
