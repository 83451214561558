import React, { FC } from 'react';
import { Button } from 'antd';

import palette from 'palette';
import { StyledFilterIcon } from './styles';

interface FilterButtonProps {
  isFilterActive: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const FilterButton: FC<FilterButtonProps> = ({ isFilterActive, onOpen, onClose }) => (
  <Button
    type="text"
    icon={
      <StyledFilterIcon
        $iconColor={isFilterActive ? palette.green.g1 : palette.black.b1}
        onClick={() => (isFilterActive ? onClose() : onOpen())}
      />
    }
  />
);
