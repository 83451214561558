import { ViolationsType } from 'pages/Violations/types';
import palette from 'palette';

const errorLegend = { fill: palette.blue.b5, dataKey: 'error' as ViolationsType };
const interpolationLegend = { fill: palette.blue.b6, dataKey: 'interpolation' as ViolationsType };
const predictionLegend = { fill: palette.blue.b6, dataKey: 'prediction' as ViolationsType };
const fullLegend = { fill: palette.blue.b5, dataKey: 'full' as ViolationsType };
const axleLegend = { fill: palette.orange.o2, dataKey: 'axle' as ViolationsType };
const bothLegend = { fill: palette.green.g5, dataKey: 'both' as ViolationsType };

export default { errorLegend, interpolationLegend, predictionLegend, fullLegend, axleLegend, bothLegend };
