import palette from 'palette';
import styled from 'styled-components/macro';

interface LegendMarkerType {
  interpolation?: string;
  color?: string;
}

export const StyledLegendItemMarker = styled.div<LegendMarkerType>`
  width: 16px;
  height: 10px;
  margin: 0 5px;
  background: ${(props) => {
    if (props.interpolation === 'interpolation') {
      return `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg"%3E%3Cdefs%3E%3Cpattern id="stripedBG" width="4px" height="4px" patternUnits="userSpaceOnUse" patternTransform="rotate(45)"%3E%3Cline stroke="%238cc8ff" stroke-width="2px" y2="4px"%3E%3C/line%3E%3C/pattern%3E%3C/defs%3E%3Cpath d="m 0 0 h 20 v 10 h -20 Z" fill="url(%23stripedBG)"%3E%3C/path%3E%3C/svg%3E')`;
    }
    return props.color;
  }};
`;
export const StyledTooltip = styled.div`
  background-color: ${palette.white.w1};
  padding: 10px;
  font-size: 1.2em;
`;
export const StyledTooltipWithWimStatisticsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledTooltipItemValue = styled.div`
  min-width: 30px;
`;

export const StyledTooltipItemName = styled.div`
  font-size: 0.8em;
`;
export const StyledLegend = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  alignitems: center;
`;
export const StyledLegendItem = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 0 0 5px;
`;
