import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { wimActions } from 'store/wim/slices';

const useClearSummaryOnMount = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(wimActions.platforms.clearPlatformSummary());
  }, [dispatch]);
};

export { useClearSummaryOnMount };
