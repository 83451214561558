import React, { useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import { ChartItemProps } from './types';

interface EmptyPieChartWithActiveShapeProps {
  data: ChartItemProps[];
  dataKey: string;
  colors: string[];
  width: number;
  height: number;
}

export const EmptyPieChartWithActiveShape: React.FC<EmptyPieChartWithActiveShapeProps> = ({
  data,
  dataKey,
  colors,
  width,
  height,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  const formattedEmptyChartValues = data.map((currentItem) => {
    const newItem = currentItem;
    newItem.value = 1;

    return newItem;
  });

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={width} height={height}>
        <Pie
          activeIndex={activeIndex}
          data={formattedEmptyChartValues}
          dataKey={dataKey}
          cx="50%"
          cy="50%"
          outerRadius={110}
          paddingAngle={2}
          isAnimationActive={false}
          onMouseEnter={onPieEnter}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index + 1}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};
