import { SelectValue } from 'antd/lib/select';
import dayjs, { Dayjs } from 'dayjs';

import { useAppSelector } from 'hooks/store-hooks';
import { FilterReturnType } from 'hooks/use-filter';

import wimSelectors from 'store/wim/selectors';

import { FilterValues } from '..';

export function useViolationsFilter<ViolationsType extends FilterValues>({
  state,
  handleFilterChange,
}: Pick<FilterReturnType<Partial<ViolationsType>>, 'handleFilterChange' | 'state'>) {
  const wims = useAppSelector(wimSelectors.platforms.getPlatforms);

  const onWimChange = (wimUid: SelectValue) => {
    handleFilterChange('wimUid', wimUid);
  };

  const onDateRangeChange = (ranges: [Dayjs | null, Dayjs | null] | null) => {
    if (!ranges) {
      return;
    }
    const [start, end] = ranges;
    handleFilterChange('start', dayjs(start).valueOf());
    handleFilterChange('end', dayjs(end).valueOf());
  };

  return {
    state,
    wims,
    onWimChange,
    onDateRangeChange,
  };
}
