import dayjs, { Dayjs } from 'dayjs';
import { LabeledValue, SelectValue } from 'antd/lib/select';

import { useAppSelector } from 'hooks/store-hooks';
import { FilterReturnType } from 'hooks/use-filter';
import wimSelectors from 'store/wim/selectors';
import { TransitsArchiveFilterValues, TransitsFilterValues } from 'store/wim/types/transits';
import { PLATE_FIELDS } from 'app-constants/plate-fields';
import { OPTION_KEYS_FOR_SELECT } from 'app-constants/select-options';
import { VIOLATIONS_TYPES } from 'app-constants/violations';

export function useTransitsFilter<TransitsType extends TransitsFilterValues>({
  state,
  handleFilterChange,
  onRemoveFields,
}: Pick<FilterReturnType<Partial<TransitsType>>, 'handleFilterChange' | 'state' | 'onRemoveFields'>) {
  const platforms = useAppSelector(wimSelectors.platforms.getPlatforms);

  const onPlatformNameChange = (value: SelectValue) => {
    if (value && Array.isArray(value)) {
      if (value.includes(OPTION_KEYS_FOR_SELECT.All as string & LabeledValue)) {
        handleFilterChange(
          'platformUids',
          platforms?.map((platform) => platform.uid)
        );
      } else {
        handleFilterChange('platformUids', value);
      }
    }
  };

  const onRangePickerChange = (ranges: [Dayjs | null, Dayjs | null] | null) => {
    if (!ranges) {
      return;
    }

    const [start, end] = ranges;

    if (start && end) {
      handleFilterChange('start', dayjs(start).valueOf());
      handleFilterChange('end', dayjs(end).valueOf());
    }
  };

  const onViolationsTypesChange = (value: SelectValue) => {
    if (value && Array.isArray(value)) {
      if (value.includes(OPTION_KEYS_FOR_SELECT.All as string & LabeledValue)) {
        handleFilterChange(
          'violationsTypesByOr',
          Object.values(VIOLATIONS_TYPES).map((violation) => violation.id)
        );
      } else {
        handleFilterChange('violationsTypesByOr', value);
      }
    }
  };

  const getOnPlateChange =
    (field: PLATE_FIELDS.Plate | PLATE_FIELDS.PlateBack) =>
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const recognizedFieldName =
        field === PLATE_FIELDS.Plate ? PLATE_FIELDS.PlateRecognized : PLATE_FIELDS.PlateBackRecognized;
      const { value } = target;
      handleFilterChange(field, value);
      handleFilterChange(recognizedFieldName, true);
      if (value === null || value === '') {
        onRemoveFields(field);
      }
    };

  const getOnSliderChange =
    (startField: keyof TransitsArchiveFilterValues, endField: keyof TransitsArchiveFilterValues) =>
    ([startValue, endValue]: [number, number]) => {
      handleFilterChange(startField, startValue);
      handleFilterChange(endField, endValue);
      if (startValue === null && endValue) {
        onRemoveFields(startField);
      }
      if (!startValue && !endValue) {
        onRemoveFields(startField, endField);
      }
    };

  return {
    state,
    handleFilterChange,
    platforms,
    onPlatformNameChange,
    onRangePickerChange,
    onViolationsTypesChange,
    getOnPlateChange,
    getOnSliderChange,
  };
}
