import React from 'react';
import { Menu as ANTMenu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ArrowLeftOutlined, UserOutlined, AimOutlined, UsergroupAddOutlined, SettingOutlined } from '@ant-design/icons';

import { Logo } from 'components';
import { ROUTES } from 'app-constants/routes';
import { NavMenuProps } from 'components/NavBar';
import { stringifyUrlParams } from 'hooks/use-url-params';

import NavBarItem from '../NavBarItem';

export const AdminNavMenu: React.FC<NavMenuProps> = ({ collapsed, openKeys, selectedKeys, onOpenChange }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <ANTMenu
        theme="dark"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultSelectedKeys={[ROUTES.AdminWims]}
        mode="inline"
        selectedKeys={selectedKeys}
      >
        <Logo collapsed={collapsed} onClick={() => history.push('/')} />
        <NavBarItem
          key={ROUTES.AdminWims}
          labelKey="wimsList"
          route={{ pathname: ROUTES.AdminWims, search: stringifyUrlParams({ sort: 'default' }) }}
          icon={<AimOutlined />}
        />
        <NavBarItem
          key={ROUTES.AdminUsers}
          labelKey="usersList"
          route={{ pathname: ROUTES.AdminUsers, search: stringifyUrlParams({ sort: 'default' }) }}
          icon={<UserOutlined />}
        />
        <NavBarItem
          key={ROUTES.AdminGroups}
          labelKey="groupsList"
          route={{ pathname: ROUTES.AdminGroups, search: stringifyUrlParams({ sort: 'default' }) }}
          icon={<UsergroupAddOutlined />}
        />
        <NavBarItem
          key={ROUTES.SystemSettings}
          labelKey="systemSettings"
          route={{ pathname: ROUTES.SystemSettings }}
          icon={<SettingOutlined />}
        />
      </ANTMenu>
      <ANTMenu
        theme="dark"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultSelectedKeys={['/']}
        selectedKeys={selectedKeys}
        selectable={false}
        mode="inline"
      >
        <NavBarItem
          key={t('navBar.closeAdminPanel')}
          labelKey="closeAdminPanel"
          route={ROUTES.Home}
          icon={<ArrowLeftOutlined />}
        />
      </ANTMenu>
    </>
  );
};
