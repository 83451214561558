import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spin } from 'antd';
import { FieldArray, Formik } from 'formik';

import { EditableTableContainer } from 'components';
import palette from 'palette';
import { Wim, WimPlatform } from 'store/wim/types';
import { StyledButtons, StyledContentForm, StyledEditBlock } from './styles';
import { UpdateWimValues } from './types';
import { wimsColumns } from './table-schema';

interface UpdateContentProps {
  wims: WimPlatform['lanes'];
  onSubmitForm: (values: Partial<UpdateWimValues>) => void;
  isLoading: boolean;
}

export const UpdateContent: FC<UpdateContentProps> = ({ wims, onSubmitForm, isLoading }) => {
  const { t } = useTranslation();

  const rowAdd: Wim = {
    uid: '',
    laneDescription: '',
    laneDirection: '',
    laneType: '',
    laneOrigin: '',
    laneDestination: '',
    direction: '',
    laneIndex: 0,
  };

  return (
    <Formik
      initialValues={{
        lanes: wims,
      }}
      onSubmit={(submittedValues) => {
        onSubmitForm(submittedValues);
      }}
    >
      {({ values, handleSubmit, handleReset }) => (
        <StyledContentForm onSubmit={handleSubmit} onReset={handleReset}>
          <Spin spinning={isLoading}>
            <StyledEditBlock>
              <FieldArray
                name="lanes"
                render={(arrayHelpers) => (
                  <EditableTableContainer<Wim>
                    form={arrayHelpers}
                    rowAdd={rowAdd}
                    columns={wimsColumns}
                    dataSource={values.lanes}
                    pagination={false}
                    bordered
                  />
                )}
              />
            </StyledEditBlock>
          </Spin>
          <StyledButtons>
            <Button htmlType="submit" style={{ color: palette.white.w1, backgroundColor: palette.blue.b1 }}>
              {t('common.apply')}
            </Button>
            <Button onClick={handleReset} style={{ color: palette.white.w1, backgroundColor: palette.red.r3 }}>
              {t('common.cancel')}
            </Button>
          </StyledButtons>
        </StyledContentForm>
      )}
    </Formik>
  );
};
