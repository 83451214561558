import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetPlatformDetectorsQueryParams, PlatformDetector } from '../types';

type InitialState = {
  isLoading: boolean;
  detectors: PlatformDetector | null;
  error: any;
};

const initialState: InitialState = {
  isLoading: false,
  detectors: null,
  error: null,
};

const detectors = createSlice({
  name: 'detectors',
  initialState,
  reducers: {
    requestDetectors: (state, { payload }: PayloadAction<GetPlatformDetectorsQueryParams>): void => {
      state.isLoading = true;
    },
    getDetectorsSuccess: (state, { payload }: PayloadAction<PlatformDetector>): void => {
      state.isLoading = false;
      state.detectors = payload;
    },
    getDetectorsError: (state, { payload }: PayloadAction<string>): void => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { getDetectorsError, getDetectorsSuccess, requestDetectors } = detectors.actions;

export default detectors.reducer;
