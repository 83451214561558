import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Certificate, CreateCertificate, UpdateCertificates } from './types';

interface InitialState {
  isLoading: boolean;
  isCreateCertificateLoading: boolean;
  isUpdatedCertificateLoading: boolean;
  entities: Certificate[] | null;
  error: any;
}

const initialState: InitialState = {
  isLoading: false,
  isCreateCertificateLoading: false,
  isUpdatedCertificateLoading: false,
  entities: null,
  error: null,
};

const certificates = createSlice({
  name: 'certificates',
  initialState,
  reducers: {
    requestCertificates: (state): void => {
      state.isLoading = true;
    },
    requestUpdateCertificates: (state, { payload }: PayloadAction<UpdateCertificates>) => {
      state.isUpdatedCertificateLoading = true;
    },
    requestCreateCertificate: (state, { payload }: PayloadAction<CreateCertificate>) => {
      state.isCreateCertificateLoading = true;
    },
    getCertificatesSuccess: (state, { payload }: PayloadAction<Certificate[]>): void => {
      state.isLoading = false;
      state.isCreateCertificateLoading = false;
      state.isUpdatedCertificateLoading = false;
      state.entities = payload;
    },
    getCertificatesError: (state, { payload }: PayloadAction<string>): void => {
      state.isLoading = false;
      state.isUpdatedCertificateLoading = false;
      state.error = payload;
    },
    getNewCertificateError: (state, { payload }: PayloadAction<string>): void => {
      state.isLoading = false;
      state.error = payload;
    },
    postCreateCertificateSuccess: (state, { payload }: PayloadAction<Certificate[]>) => {
      state.isCreateCertificateLoading = false;
      state.entities = payload;
    },
    postCreateCertificateError: (state, { payload }: PayloadAction<string>) => {
      state.isCreateCertificateLoading = false;
      state.error = payload;
    },
  },
});

export const {
  requestCertificates,
  requestUpdateCertificates,
  requestCreateCertificate,
  getCertificatesSuccess,
  getCertificatesError,
  getNewCertificateError,
  postCreateCertificateSuccess,
  postCreateCertificateError,
} = certificates.actions;

export default certificates.reducer;
