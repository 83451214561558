import { useSelector } from 'react-redux';

import wimSelectors from 'store/wim/selectors';

function useWimState() {
  const platforms = useSelector(wimSelectors.platforms.getPlatforms);
  const activeWimPlatformDetails = useSelector(wimSelectors.activePlatform.getActiveWimPlatformDetails);
  const activeWimPlatformErrors = useSelector(wimSelectors.activePlatform.getActiveWimPlatformErrors);
  const normalizedPlatforms = useSelector(wimSelectors.platforms.getNormalizedPlatforms);
  const normalizedSummary = useSelector(wimSelectors.platforms.getPlatformSummary);
  const isPlatformsLoading = useSelector(wimSelectors.platforms.isPlatformsLoading);
  const isSummaryLoading = useSelector(wimSelectors.platforms.isSummaryLoading);

  return {
    platforms,
    activeWimPlatformDetails,
    activeWimPlatformErrors,
    normalizedPlatforms,
    normalizedSummary,
    isPlatformsLoading,
    isSummaryLoading,
    isPlatformsLoaded: normalizedPlatforms && !!Object.keys(normalizedPlatforms.byUid).length,
  };
}

export default useWimState;
