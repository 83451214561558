import { all } from 'redux-saga/effects';
import { mediaNodesRequestWatchSaga } from './media-cluster/saga';
import { externalNotificationsRequestWatchSaga } from './notifications/saga';
import { connectorsRequestWatchSaga } from './connectors/saga';

function* systemWatchSaga() {
  yield all([mediaNodesRequestWatchSaga(), externalNotificationsRequestWatchSaga(), connectorsRequestWatchSaga()]);
}

export default systemWatchSaga;
