import { WimTransit } from 'store/wim/types';
import { AxlesType } from '../ArchiveTabs/components/VehicleSchema';

export const getVehicleSchema = (transit: WimTransit) => {
  const axles: AxlesType[] = transit.axles.map(({ wheelbase, tires, weight, number, overweight }) => {
    const axleGroupIndex = transit.axlesGroups.findIndex(
      ({ firstAxle, lastAxle }) => firstAxle <= number && lastAxle >= number
    );

    const result = {
      axleDistance: wheelbase,
      dualWheels: tires === 2,
      weight,
      overload: overweight,
    };

    if (axleGroupIndex >= 0) {
      return {
        ...result,
        group: { overload: transit.axlesGroups[axleGroupIndex].overweight, groupIndex: axleGroupIndex },
      };
    }

    return result;
  });

  const violations = { overWidth: false, overHeight: false, overLength: false };
  if (transit.size) {
    transit.size.length.oversize > 0 && (violations.overLength = true);
    transit.size.height.oversize > 0 && (violations.overHeight = true);
    transit.size.width.oversize > 0 && (violations.overWidth = true);
  }

  return {
    axles,
    width: transit.size.width.value,
    height: transit.size.height.value,
    length: transit.size.length.value,
    totalWeight: transit.weight,
    overweightPercent: transit.overweightPercent,
    ...violations,
  };
};
