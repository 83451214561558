import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors } from 'store/user/selectors';

import { Loader } from 'components';
import { userActions } from 'store/user/slice';
import { UserSummary } from './components/UserSummary';
import { StyledContent } from './styles';

const ProfilePage: FC = () => {
  const dispatch = useDispatch();
  const isUserLoading = useSelector(userSelectors.isUserLoading);
  const user = useSelector(userSelectors.getUser);

  useEffect(() => {
    dispatch({ type: userActions.requestUser });
  }, [dispatch]);

  if (!user) {
    return <Loader isLoading={isUserLoading} />;
  }

  return (
    <StyledContent>
      <UserSummary state={user} />
    </StyledContent>
  );
};

export default ProfilePage;
