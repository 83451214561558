export default `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABB8AAAHbCAQAAAApcPomAAAAAmJLR0QA/4ePzL8AAAAJcEhZcwAA
LiMAAC4jAXilP3YAAAAHdElNRQflCwoMDireEuzYAAABYnpUWHRSYXcgcHJvZmlsZSB0eXBlIGlj
YwAAOI2dVFuOgzAM/M8p9gh+mxyHBiLt/S+wDiQUVlTd7iCKNHbs8SNN36WkrwY1SNBADIIiQqJA
uFG22OripCQuRKCTZp0JwEtt5ngZTkiGxs4egRQUpAyDrVbjVfgDamRtinAQC9NyKPsQ6UP/OfSD
k0vX0itgSiamzrbXi6Nse7hHh6AfwNwPoBiF7eAfMPgU7ZRoaA9UeiZYzc/88uQv/uvBt0DbPHZD
lSOzn3iC6anozOOhNHkrbRo1AwhbDenZybK5hYWAkXN7KGYbrahRnsZCcHhxO2+LcwpjjjG34+TQ
fg03Yw996eEBxEvI2LXUlo2mnnOi/G5sr/Dp+P8f6LgaJ1TWtsRjkREtLt2d4x1iETW6aL9ojHu6
9S+9c3hnH0IupeWC275Infe7JrXcKWaZlvadgfY9qllvSyNj7GnbXwY81lzSD3dD5+EuTNuGAAAD
uHpUWHRSYXcgcHJvZmlsZSB0eXBlIHhtcAAASImdVkuyozAM3PsUcwQj2RI+Dgmwm6pZzvGnW84H
AknlTagQYiyp1fo5/f39J/3CZyhtTHrV1UfPNpjaxaoXySZWza3ZorPIsl4ul1UE680KV6prLbPm
Mnsuir2jtVRGnxyCVX0qSy2GXyhUhZCIrrpI1quPOvloELSZxmyQzP92tcWV7xItAE2xlTh06i8e
2wPJUw3WLpQoDwnJdSxzzUkIbvVY0iqLmszAM2jRESuuDWuDGv5nVby9YlXwPODNit8R90E1yRyL
E+6GOzyU/HLJzT0BCuypUkqxF9ckxUu6N3rBlXWCO6vHRxbHLlkCsYflxiuQCO6C+9wNAJGrIz5k
xEe4BQt8v0cBCAgVAiHWgqkGhrDj/t6GBMJWB7FE1YndxoIEH/GGseUZIjxrwuMMV0bgyQQPavM9
Vk+VENETAjIgiF3pfOrecyPuFYKVoaersNoVD0fFyKcRngARHOZTTt8Bv+E+iD99SOe8lBkl8BMT
Y9pzA4XziSPqpVS3HpJz5elM+145K9QRdOxZq0QarEEyypXqO6OJOdPt00ZhplQoIg9SaWS166G6
jlyBbLlZzjLWaB0UQFqrtDcmain9lwai7vDttYaMpsJaUASsO0WJsu6KXKJUWYl5p3Y2pkwz89bV
pr3ejdoWaqUX8q2c36utiTVmHepwBvlb1anrjhKYzKLOZ6qLQDc+o+nWSMYFNUAcB6rJIGvtSvGb
1x2NvhVoBfRVVv0Ks40l1WOaNkEV5Jkwere+M27jFrkORUzAoGOJPALVVMTMxiYkY4sJMcFB4VcH
Y7P14AYXDM0YB4gmins2UYcqNGWtQCTcmWjZ2I/BhB4REK/6oyPcEezGRexJHckZHzAA81ojfjnQ
jbxMiBMqQBfRAadHqw03GgSqcVa0uON7QMeItj44gei6R5uecLuQLJGxzGynTWxiXy7BCAoXJphV
Tr5QXGROsFZTTDHFFig3Cg7YjDkFwfsA2KLCcI6oAZ237YBImFMIow/7qYH8aHDs2Ycmng3u0yPo
3hQxh3t62f42T/ZpcnQ7ffL7k9t7AO4Jwa4d7EmZftNYbupS6GOThWZHvtLzEoXKBPymN/bWmN72
xvVn9GMcQfcQLby8ev4t9azO9H8leqzQtOf+I/XfdMiduv+iPu25/zCWttwxYuSNBvqBKGb/LT6y
Pg53m6KJyfux1rpcen8eO6iPs+NT/f7AjENEf3VybK90L8It/cSd/gEqx7oKlbgVdgAAE9lJREFU
eNrt3dt62ziygFHKn+f9H1lz4bhtWSeCB6CqsNbd3tPdIWGk+At0nMv/FijvOvoCAAa4nPef/hx9
b3ASyQDM7vccPDgl5APVyAaAe9+z8aCMkA/UIRwA3vmalLsjQj5Qg3QAWO+67EwI+UB2wgFgi10J
8TH66mEX8QCw3XXrFHX6QF7SAWC/TacQ8oGcpAPAcZoTwssLMhIPAEdrmqzygWw2v6kD4KWG6Sof
yEU6AJxn9Qc0+UAm4gHgbKsmrXwgD/EA0MOKaSsfyEI8APTyduLKB3IQDwA9vZm68oEMxANAby8n
r3wgPvEAMMKL6SsfiE48AIzydALLB2ITDwAjPZnC8oHIxANASPIBAHju4Qc5+UBczh4AIngwjeUD
UYkHgCjuJrJ8AAAayQdicvYAEMmfqSwfiEg8AIQmHwCA924+2MkH4nH2ABCcfAAA1vj14U4+EI2z
B4Dw5AMAsM5/H/DkAwDQSD4Qi1cXAJH9m9LyAQBoJB8AgEbygUi8ugCI7ros8gEAaCYfAIBG8oE4
vLoASEI+AAAtrvIBAGgmHwCARvIBAGgkHwCARvKBKPy5C4A05AMA0OYqHwCARvIBAGj0OfoCoJvL
6AsA6OT07yZz+gAANJIPAEAj+QAANJIPAEAj+QAANJIPAEAj+QAANJIPAEAj+QAANJIPAEAj+QAA
NJIPAEAj+QAANJIPAEAj+QAANJIPAEAj+QAANJIPAEAj+QAANJIPAEAj+QAANJIPAEAj+QAANJIP
AEAj+QAANJIPAECjz9EXwAPX0RcAAK/IhygkAwBpyIfRZAMA6ciHcYQDAEnJhxGEAwCpyYfepAMA
6cmHnqQDACXIh16kAwBlyIcepAMApfipk+cTDwAU4/ThXNIBgIKcPpxJPABQknw4j3gAoCgvL84h
HQAozOnDGcQDAKXJh+OJBwCKkw9HEw8AlCcfjiUeAJiAfDiSeABgCvLhOOIBgEnIh6OIBwCmIR8A
gEby4RjOHgCYiHw4gngAYCryYT/xAMBk5AMA0Eg+7OXsAYDpyId9xAMAE5IPAEAj+bCHswcApiQf
AIBG8mE7Zw8ATEo+AACN5MNWzh4AmJZ8AAAayYdtnD0AMDH5AAA0kg8AQCP5sIVXFwBMTT4AAI3k
AwDQSD608+oCgMnJBwCgkXxo5ewBgOnJBwCgkXwAABrJhzZeXQCAfAAAWn2OvgDeuJz033WOAsBm
8qFF30fuWeEAADvJh5jyp0PbHTgLAUjF9z5ElD8e3DFAafJhvV6fkOd8lM551wBJyYdoPEYBCE8+
rNXn7EE8AJCAfAAAGskHAKCRfFjHqwsA+I98AAAayYc4nD0AkIR8WMPPRASAX+QDANBIPkTh1QUA
aciH97y6AIAb8gEAaCQf3vETHwDgD/kAADSSDxE4ewAgFfnwmm+bBIA78gEAaCQfxvPqAoBk5MMr
Xl0AwAPyAQBoJB9G8+oCgHTkw3NeXQDAQ/JhLGcPACQkH55x9gAAT8gHAKCRfBjJqwsAUpIPj3l1
AQBPyQcAoJF8GMerCwCSkg+PeHUBAC/Ih1GcPQCQlnwAABrJh3teXQDAS/JhDK8uAEhMPvzl7AEA
3pAPAEAj+TCCVxcApCYfbnl1AQBvyYf+nD0AkJx8AAAayYffvLoAgBXkQ29eXQCQnnwAABrJhx9e
XQDAKvKhL68uAChAPnxz9gAAK8mHnpw9AFCCfAAAGsmHL15dAMBq8qEfry4AKEI+AACN5MOyeHUB
AE3kQy9eXQBQhnwAABrJhz6vLpw9AFCIfAAAGskH3zYJAI3kQw9eXQBQinwAABrNng9eXQBAs9nz
oQevLgAoRj4AAI3mzgevLgBgg7nzoQevLgAoRz4AAI1mzgc/rBoANpk5HwCATebNB982CQAbzZsP
PXh1AUBJ8gEAaDRrPsz+6mL2+wdgl1nzoQevLgAoas58mP2z9+z3D8BOn6MvoLt+j04PaQCKGpkP
Hq8AkFLffBAMAFBAn3yQDQBQyLn5IBsAoKDz8kE6AEBRZ+SDcMDPvAAo7eh8kA4AUN6R+SAdAGAK
R+WDdACAaRyRD9IBAKay/++8EA/85RsnAYrbd/ogHQBgQntOH8QDAExp6+mDdACAaW07fRAPPOc7
HwDK25IP4gEAptb68kI6AMD02k4fxAPveHUBMIGWfBAPAMDSkg/igfecPQBMYW0+iAcA4J91+SAe
WMPZA8Ak1uSDeAAAfnmfD+KBdZw9AExj/9+4CQBM5l0+OHtgHWcPABN5nQ/igXXEA8BUXuWDeAAA
HnieD+KBtZw9AEzGt06yl3gAmM6zfHD2wDriAWBCj/NBPAAAT3l5wR7OHgCm9CgfnD2wjngAmJTT
B7YSDwDTus8HZw+sIR4AJub0gS3EA8DUPv/8384eeEc6AEzP6QNtxAMAf/LB2QOviQcAlvuXF/CM
dADgn9+nD84eeE48APAfpw+8Jx0AuPGTD84eeEQ6AHDH6QPPSQcAHpIPPCYdAHjqOx+8uuCbcADg
DacP/BAOAKwiH5ANADT6yoeRry48ugAgmXGnD7IBAJIakw/SAQAS658P0gEAkvtY+n7ng3gAgPQ+
9v8nGogHACigZz6IBwAooV8+iAcAKKJXPogHACjjo8s3TooHACikx+mDeACAUvr+yQsAoIDz88HZ
AwAU4/QBAGgkHwCARmfng1cXAFCO0wcAoJF8AAAayQcAoJF8AAAayQcAoJF8AAAayQcAoJF8AAAa
yQcAoJF8AAAayQcAoJF8AAAayQcAoJF8AAAayQcAoJF8AAAayQcAoJF8AAAayQcAoJF8AAAayQcA
oJF8AAAayQcAoJF8AAAayQcAoJF8AAAayQcAoJF8AAAafY6+AAAY5tr8b1xGX3IM8gGAubQnw7N/
e+KUkA+vtGyxiTcRQAr7suHVf3HCJ4B8uLV9c+lRgJiOz4Znv8JE018+fDl2c024kQACOj8cHv16
U8z+2fPh3K0lIwDG6B0O97928ck/bz703FpTbCWAIEamw+1VFJ77M+bDqI0lIgDOFiMdbq+m5Nyf
LR/Gb6ziPQowyPj5/urKys39mfIhztYq3KMAA8SZ76+usNTUnyMfYm6scpsJYIiYM/7xdZaZ+vXz
Ifa2KrWZALqLPeMfXW+RmV89HzJsLAkBsEWGCf/4qgvM/Mr5kGljFdlOAJ1kmvCPrz75zK/6F3Zf
E26tfFcMMEaFeZn8HiqePuT9khToUYCT5Z3xj+4k7cyvd/qQfWNlv36AM1WbkWnvp1Y+ZHxlUfUu
AI5XcTomvadK+ZD0S1D+XgCOUPejVco7q5MPCRf/zf1UuyOA7apPxHT3VyMfqj5qa94VQKsZpmGy
e6yQD8mW3L0BNJllEqa6z/z5kGq53R9Ao5mmYKKz9Oz5kGah3SPABvNNwCR3nDsfkizyAfc5y50C
/Jh19qW468z5kGKB3S3AJjNPvQT3njcfEiyuOwbYaPaJF/7+s+ZD+IV11wCbmXbh1yBnPgRfVHcO
wG6hJ37GfAi9oO4eYCdzLoF8+WBbWQGgMjPuR+C1yJYPgZeyI6sAVGW+3Qq7HrnyIewyAnAAU/5e
0DXJlA9Bl3AIawHUY7I9FnJd8uRDyOUbyHoAzCLgxM+SDwGXbjhrAlRipqWSJR94xG82oArz7LVw
65MjH8ItGwAHMuXfC7ZGGfIh2JKFYm0AZhFq4sfPh1DLFZD1AbIzxxKKnw+84zcekJkZtl6gtYqe
D4GWCgD4EjsfxMM61gnIyvxqE2a9YucDAJWFeRgmEmTNIudDkCVKwVoB0FHkfKCFgACyMbe2CbFu
cfMhxPIAAPei5oN4aGfNgEzMrO0CrF3UfAAAwvocfQEPBeiqJy6hr+/67/oAoos3R1/Pz2jXO3ze
x8yHSC4r/n/RthUAa617DH//U+b9PxHzIcYXp6Xr4pxJDO9RgBUizMu2OX/770S4/sHz/ux8yPg4
23rFcTYVAK/tezZdlumnfbxvnRz7Bbnszp39/4V9Jt/QQAKj59Qxc3r0tB+8jhFfXoxy3EbQpe2s
F9DDsY/8iaf9+acPORb2+Ioc16U5Vhygt3Pm8shTiIHz/qPDbbfc3pilOGsNRh9sAcQz6pF35kSe
cNrH+96H/s7dUiM2Vb7zh3xXDORy9iyeLiD65MPah0P/h0iPx/t0mwrgqVpnzLe/xlQfF3udPsT8
dNnrSy0gXou5O4Aaej7WJ5r2/V5exHtE9Pwy995S8Va7xrUC2fSevtMERM/vfXj3mOj7GLGlYhAP
MJPev+NHTN5JPi5+dL3VOI+KGbZUBnF2BFDPqKk7xbTv/ScvYjwuZthSMVY6/zUCtJsgIPr/wc3r
k4dGv0fJyC/rBFtqpat4gOnUfkU9zpBp+pUP/d/UjHt4jN5Qo3/9CKQDcLbRs3b0r3+6kX/nxfcj
pPwi/3Hp9PCM97edjo6G0b8+0EuE6ddr1i/LkOkW4a/M6nnbEbZUPx6XAOP0DIju5vqh1VHiIcp1
AFRkxp7v8p0PFrsv6w1wjkjzNdK1HGym04fCX0YA6OknHzxc+7LeAMeLNlujXc9h5jl9KPslBIDe
5smHeAQNwLEiztWI13SA3/lQ9BbL3xsA9HRx+gAA5yr5AfY2H0reYuD7inpdABmZqR05fQAAGv3N
B+0GAMeq9Wy9LIvTh9FqbSmAcczTru7zod4XoN4dAcBQj04fPG4BgEf+NYKXFwBAo8f54PyhH2sN
sF/0WRr9+po9O30od6MAwE7/1UH9lxdCCAAO9jwfPHYBgB+/yuBj3T8GAPCt/ssLAGC/m0OFj/X/
KAAwqT9F8O70QUAAAH+8f3khIABgbnctsOZ7HwQEAPDLum+dFBAAMKsHFbD2T14ICACY0cMCWP8H
N7MGxHX0BQBwOrP+LE+e/i0/9yFrQADAWFnz5umTv+3HRgmI42XdUgBMrPWnTgoIAJjDi2d++w+t
vkgIACjv5dN+2995kSsgvB4AqC/yrI98bc+8edJv/SuzcgVEXBm3FAC1rXjPsP1v3PQSAwDqWfV0
3/cXdmcJCJ/xAeqLOuujXtdjK5/s+/LBGcQ+ubYUANWtfqbvzYemXwwATuRD2R5NBwJH5EOGMwhb
CoARsjx/Gp/jx+TD1y8cPSGiybKlAPIwWbfY8AT/PPgCliXqF+8qbwDoLOYT8beNz8bjTh9+X4oH
9XvxtxRARpGma6RreWTH8/rY04ffl7Qs0RbO+QMAfNn5RDwrH35fXJyIiBMQcdYEoJoosz7qpD9k
dc7Nh9sLjbqQ/VkJgDNFCYhoDlyVHvlwf9njHp+2FAA9xPmgeMpzr2c+vLuVPks9PiB6bqnR9wrw
W7/5N3rW942HAfd6xp+8SHT7A8TpUYDKRk7b8vEQKx96saUAZjBq2k/xMXHGfLClAOYwYupOMulj
5UO/T8u2FEB//c9Ee0/e/pN+0DlzrHzoqf6WAqDv9J1o0s+bD/W3lO98AFiWfhN4oniIlw99H3nX
bn9YFIBlGfXBpscUHjPph31QjJYP/Z3/BZ9sSwEEdO7HxV4fRgORD+c+3ifcUgAvjftwc9Y8Hjfn
B35QjJcPIxbjrIe8dACI5PhpP+2HxDE/tDqi63JsuozdUF5dADx23LSfNBy+xDt9GHuwdcxmmLZG
AVYY/wFn/5SOMOeHrqPTh7/2dun4DRXhtyZAdNunfYQ5P1zMfLgM/uJc/13Fln8LgHdGT/lv31ex
bt7HuOZvgz8oxsyHGNZGRKwNNXxLAaTzM8cvL/43fomaD1HK9H7jXBabCWCvOFP+Vsyrujf8g2LU
fIgr+tYavqUAqC/in7z44jEIUJkpv12AtYubD2wRYEsBUF/kfPAoBKjMlN8mxLpFzgdahdhSAKuZ
Wu2CrFnsfAiySADAb7HzQUC0sFZAPiZXmzDrFT0fWCvMlgJoYnqlFD8fbCwAWJZQT8T4+cAagbYU
QCMTbJ1Q65QhH0ItWEhWCMjNFEsnQz7YWADMLtiTMEc+hFu2UKwNkJ9J9lq49cmSDzwTbksBbGKa
PRdwbfLkQ8DFC8CqAHWYaInkyQcb654VAagv5KzPlA9BlxCAg5jy94KuSa58CLuMQ1gLoB6T7VbY
9ciWD4GXsjPrANRkuv0IvBb58oFlCb2lAHYy4b6EXoeM+RB6Qa0AwG6mXPg1yJgP4RfV3QPsNPuk
C3//OfMhwcK6c4BdZp52Ce49az6kWFx3DbDDrBMvxX3nzYckC+yOATabceoluefM+bAslyzLfNDd
AsxmtsmX5n5z58OyJFpq9wmwwUzTL9G95s+HVMvtHgGazTIBU91nhXxItuQb7q72/QG8M8McTHaH
NfIh3bK7M4BGladhwjyqkg8pF3/VXQHwpepETHlfdfJhWZJ+CV7cTa37Adir4lxMeke18iHtl6H0
nQAcqdJ0TJxD1fIh9Rfj5i4AeKzGnE8+6evlw7Ik/5KU+Y0BcJ7sczL9pP8cfQEnuSzLch19ERuv
G4D3ss75IpO+5unDl3xtl+16AcbKODUzXvMDVU8fvuWp0yIbCqCrPFP++2qLqJ4Py7Isl/Bbq9CG
AuguR0IUm/Qz5MP3Fy3m5iq2oQCGiJ0QBSf9HPnwJd7mKrihAIaJ+VGx6KSfKR+WJU5CFN1OAMNF
mfPFJ/1s+bAs4/u09IYCCGB8QpSf9DPmw5cRm6v8dgIIY9RHxUkm/bz5sCw/X+Tzt9ck2wkgGHP+
JHPnw7fzGnWy7QQQ1JlnEVNOevnw42cD7N1gU24lgPCOm/PTT3r58MjtplizySbfRgDJ/J3a7ye9
OX/j/7GgOnfimKZSAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTExLTEwVDEyOjE0OjQyKzAwOjAw
CHRGOwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0xMS0xMFQxMjoxNDo0MiswMDowMHkp/ocAAAA3
dEVYdGljYzpjb3B5cmlnaHQAQ29weXJpZ2h0IDE5OTkgQWRvYmUgU3lzdGVtcyBJbmNvcnBvcmF0
ZWQxbP9tAAAAIHRFWHRpY2M6ZGVzY3JpcHRpb24AQWRvYmUgUkdCICgxOTk4KbC66vYAAAAASUVO
RK5CYII=`;
