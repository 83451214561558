import React, { FC } from 'react';

import { CardProps } from 'antd';
import { StyledCardList, StyledCardTitle, StyledList } from './styles';

export const CardWithList: FC<CardProps> = ({ title, children }) => (
  <StyledCardList title={<StyledCardTitle>{title}</StyledCardTitle>}>
    <StyledList>{children}</StyledList>
  </StyledCardList>
);
