import React from 'react';
import dayjs from 'dayjs';
import i18n from 'i18n';
import * as H from 'history';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';

import { THREE_WEEKS } from 'app-constants/date';
import { ROUTES } from 'app-constants/routes';
import { stringifyUrlParams } from 'hooks/use-url-params';
import { NormalizedWimPlatform } from 'store/wim/normalization';
import { CameraInfo } from 'app-constants/wim-cameras-mapper';
import { Modal } from 'types/modal';
import { StyledLinks } from './styles';
import { LinksDropdown } from './LinksDropdown';

interface LinksProps {
  selectedPlatform: NormalizedWimPlatform | null;
  handleCameraModalOpen: (cameraInfo: CameraInfo) => void;
}

enum LinksType {
  LINK = 'LINK',
  LIST = 'LIST',
}

export const getLinks = (selectedPlatform: NormalizedWimPlatform) => ({
  transits: {
    id: dayjs().add(1).valueOf(),
    type: LinksType.LINK,
    title: i18n.t('activePlatform.links.transits'),
    route: {
      pathname: ROUTES.TransitsArchiveWim,
      search: stringifyUrlParams({
        sort: { key: 'datetime', value: 'descend' },
        filter: { platformUids: [selectedPlatform.uid] },
      }),
    },
  },
  violations: {
    id: dayjs().add(2).valueOf(),
    type: LinksType.LINK,
    title: i18n.t('activePlatform.links.violations'),
    route: {
      pathname: ROUTES.WimViolations,
      search: stringifyUrlParams({
        filter: {
          wimUid: selectedPlatform.uid,
          start: dayjs().subtract(THREE_WEEKS, 'milliseconds').valueOf(),
          end: dayjs().endOf('day').valueOf(),
        },
      }),
    },
  },
  camera: {
    id: dayjs().add(3).valueOf(),
    type: LinksType.LIST,
    title: i18n.t('activePlatform.links.camera'),
    route: ROUTES.NotFound,
  },
  areaSchema: {
    id: dayjs().add(4).valueOf(),
    type: LinksType.LINK,
    title: i18n.t('activePlatform.links.areaSchema'),
    route: (location: H.Location) => `${location.pathname}?modal=${Modal.PLATFORM_TRANSITS_SCHEMA}`,
  },
  overview: {
    id: dayjs().add(5).valueOf(),
    type: LinksType.LINK,
    title: i18n.t('activePlatform.links.overview'),
    route: (location: H.Location) => `${location.pathname}?modal=${Modal.PLATFORM_OVERVIEW}`,
  },
});

export const Links: React.FC<LinksProps> = ({ selectedPlatform, handleCameraModalOpen }) => {
  const platformUid = selectedPlatform?.uid;
  const links = selectedPlatform && getLinks(selectedPlatform);

  return (
    <Spin spinning={!platformUid && !links}>
      <StyledLinks>
        {platformUid && links && (
          <>
            {Object.values(links).map((link) =>
              link.type === LinksType.LINK ? (
                <Link key={link.id} to={link.route}>
                  {link.title}
                </Link>
              ) : (
                <LinksDropdown
                  key={link.id}
                  title={link.title}
                  platformUid={platformUid}
                  onItemClick={handleCameraModalOpen}
                />
              )
            )}
          </>
        )}
      </StyledLinks>
    </Spin>
  );
};
