import { WimTransitDetails } from 'store/wim/types/transits';

export type DetailTransitsByWim = {
  totalTransits: number;
  totalViolations: number;
  averageSpeed: number;
  averageWeight: number;
  minWeight: number;
  maxWeight: number;
  lastTransitDate: number;
};

export const parsedWimDataFromModal = (transitsByWim: WimTransitDetails): DetailTransitsByWim => {
  const { totalTransits, totalViolations, averageSpeed, averageWeight, lastTransitDate, minWeight, maxWeight } =
    transitsByWim;

  return {
    totalTransits,
    totalViolations,
    averageSpeed,
    averageWeight,
    minWeight,
    maxWeight,
    lastTransitDate,
  };
};
