import { ColumnsType } from 'antd/lib/table';
import i18n from 'i18n';
import { Group } from 'store/admin/groups/types';

export const groupsColumns: ColumnsType<Group> = [
  {
    title: i18n.t('group.name'),
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: i18n.t('group.description'),
    key: 'description',
    dataIndex: 'description',
  },
];
