import dayjs from 'dayjs';
import i18n from 'i18n';

import { DatePeriodType, Months } from 'app-constants/date';
import { getCurrentPeriod } from './get-current-period';

export const setDatePeriod = (date: number, datePeriod: DatePeriodType = DatePeriodType.today) => {
  const currentPeriod = dayjs(date).format(getCurrentPeriod(datePeriod));
  if (Months[currentPeriod as Months]) {
    return i18n.t(`dates.${Months[currentPeriod as Months]}` as const);
  }
  return dayjs(date).format(currentPeriod);
};
