import { Input, InputProps } from 'antd';
import { Field } from 'formik';
import React, { FC } from 'react';

import { StyledError, StyledFormikInputFieldWithLabel, StyledLabel, fieldError } from './styles';

interface FormikInputFieldWithLabelProps extends InputProps {
  label: string;
  errors?: string;
  touched?: boolean;
}

export const FormikInputFieldWithLabel: FC<FormikInputFieldWithLabelProps> = ({
  label,
  defaultValue,
  onChange,
  errors,
  touched,
  ...restProps
}) => {
  return (
    <>
      <StyledFormikInputFieldWithLabel>
        <StyledLabel>{label}</StyledLabel>
        <Field
          style={errors && touched ? fieldError : {}}
          as={Input}
          defaultValue={defaultValue}
          onChange={onChange}
          {...restProps}
        />
      </StyledFormikInputFieldWithLabel>
      {errors && touched ? <StyledError>{errors}</StyledError> : null}
    </>
  );
};
