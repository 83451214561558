import { useEffect, useState } from 'react';

import { CreateUser, User, UserLogActivity, UserStatus } from 'store/user/types';
import { UsersListSort } from '../types';
import { usersListSorters } from '../sorting-schema';

export type QueryUrl = {
  defaultQuery: string | undefined;
  onUpdateUrl: (value: string) => void;
};

type UpdateStateHandler = (value: Partial<UsersPageState>) => void;

interface UsersPageState {
  users: User[] | null;
  selectedUser: User | null;
  newUser: CreateUser;
  activityLogs: UserLogActivity[] | null;
}

export const newUser: CreateUser = {
  email: '',
  password: '',
  department: '',
  fullName: '',
  permissions: [],
  phone: '',
  position: '',
  socialNetworks: undefined,
  status: UserStatus.INACTIVE,
  mfaEnabled: false,
  forcePasswordRefresh: false,
};

function getSelectedUser(
  users: User[] | null,
  firstUser: User | null,
  previouslySelected: User | null,
  queryUserId?: string
): User | null {
  if (queryUserId && users) {
    return users.find((user) => user.uid.toString() === queryUserId) || null;
  }
  if (!previouslySelected && firstUser) {
    return firstUser;
  }

  return previouslySelected;
}

const useUsersPageState = (users: User[] | null, queryUrlParams: Record<string, any>) => {
  const [usersPageState, setUsersPageState] = useState<UsersPageState>({
    users: null,
    selectedUser: null,
    newUser,
    activityLogs: [],
  });

  useEffect(() => {
    if (users) {
      const copyUsers = [...users];
      const defaultSortedUsers = copyUsers.sort(usersListSorters[queryUrlParams.sort as UsersListSort]);

      const firstUser = defaultSortedUsers[0];
      setUsersPageState((prevState) => ({
        users: defaultSortedUsers,
        selectedUser: getSelectedUser(defaultSortedUsers, firstUser, prevState.selectedUser, queryUrlParams.user),
        newUser,
        activityLogs: [],
      }));
    }
  }, [users]);

  const handleUpdateUsersPageState: UpdateStateHandler = (newState) => {
    setUsersPageState({ ...usersPageState, ...newState });
  };

  return {
    usersPageState,
    handleUpdateUsersPageState,
  };
};

export { useUsersPageState };
