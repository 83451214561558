import styled from 'styled-components/macro';
import { Col, Row } from 'antd';
import palette from 'palette';

export const StyledLine = styled(Row)`
  height: max-content;
  :not(:last-child) {
    border-bottom: 2px dashed ${palette.grey.g6};
  }
`;
export const StyledLineType = styled(Col)`
  max-width: 150px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: ${palette.grey.g7};
`;

export const StyledLineTypeLg = styled(Col)`
  color: ${palette.grey.g7};
`;
