import styled from 'styled-components/macro';
import { InputNumber, Select, Tag } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export const TooltipContentWrapper = styled.div`
  max-width: 500px;

  font-size: 14px;
  white-space: pre-line;
`;

export const IdInputNumber = styled(InputNumber)`
  width: 200px;
  padding-right: 20px;
  .ant-input-number-handler-wrap {
    visibility: hidden;
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const SearchIcon = styled(SearchOutlined)`
  position: absolute;
  top: 8px;
  right: 10px;
`;

export const StyledSelect = styled(Select)`
  width: 285px;
`;

export const StyledTag = styled(Tag)`
  margin-right: 3px;
`;

export const StyledSelectVehicleTypeItem = styled.div`
  display: flex;
  column-gap: 3px;
`;
