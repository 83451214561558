import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { API_URLS } from 'app-constants/api';
import { store } from 'store/store';
import { authActions } from 'store/auth/slice';
import { AccessToken } from 'store/auth/types';
import { localStorageService } from './local-storage.service';
import { tokenService } from './token.service';

export class HTTPService {
  static AUTH_HEADER_KEY = 'Authorization';

  static UNAUTHORIZED = 401;

  instance: AxiosInstance;

  constructor(baseURL?: string) {
    this.instance = axios.create({
      baseURL,
      timeout: 120000,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    });
  }

  post<Request, Response>(url: string, data?: Request, config?: AxiosRequestConfig): Promise<AxiosResponse<Response>> {
    return this.instance.post(url, data, config);
  }

  get<Request, Response>(
    url: string,
    params: Request,
    paramsSerializer?: (queryParams: any) => string
  ): Promise<AxiosResponse<Response>> {
    return this.instance.get(url, { params, paramsSerializer });
  }

  put<Request, Response>(url: string, data?: Request): Promise<AxiosResponse<Response>> {
    return this.instance.put(url, JSON.stringify(data));
  }

  patch<Request, Response>(url: string, data?: Request): Promise<AxiosResponse<Response>> {
    return this.instance.patch(url, data);
  }

  delete<Request, Response>(url: string, params?: Request): Promise<AxiosResponse<Response>> {
    return this.instance.delete(url, params);
  }

  setAuthHeader(token: AccessToken) {
    this.instance.defaults.headers.common[HTTPService.AUTH_HEADER_KEY] = `Bearer ${token}`;
  }

  removeAuthHeader() {
    this.instance.defaults.headers.common[HTTPService.AUTH_HEADER_KEY] = '';
  }

  getDocumentInBase64<Response>(
    url: string,
    params?: Request,
    paramsSerializer?: (queryParams: any) => string
  ): Promise<AxiosResponse<Response>> {
    return this.instance.get(url, {
      params,
      paramsSerializer,
      responseType: 'arraybuffer',
    });
  }

  getDocumentInBlob<Request, Response>(
    url: string,
    params?: Request,
    paramsSerializer?: (queryParams: any) => string
  ): Promise<AxiosResponse<Response>> {
    return this.instance.get(url, {
      params,
      paramsSerializer,
      responseType: 'blob',
    });
  }

  setupInterceptors(): void {
    this.instance.interceptors.request.use((request) => {
      const originalRequest = request;
      const accessToken = localStorageService.getAccessToken();
      if (originalRequest?.headers && accessToken && request.url !== API_URLS.login()) {
        originalRequest.headers[HTTPService.AUTH_HEADER_KEY] = `Bearer ${accessToken}`;
      }

      return originalRequest;
    });

    this.instance.interceptors.response.use(undefined, (error: AxiosError) => {
      // Check if we enter to the error that was called by refresh request
      if (error.config.url === API_URLS.refreshAccessToken()) {
        return store.dispatch(authActions.requestLogout());
      }

      if (error.response?.status === HTTPService.UNAUTHORIZED && error.response.config.url !== API_URLS.login()) {
        // The interceptor need to return non-error value that the redux can handle it as success response
        return tokenService.addNewAccessTokenToErrorRequest(error);
      }
      // Throw common error that redux can handle it as response was failed
      throw error;
    });
  }
}

const httpService = new HTTPService(API_URLS.base);

export default httpService;
