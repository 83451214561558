import dayjs from 'dayjs';

export const DATE_FORMAT = {
  TABLE_FORMAT: 'DD.MM.YYYY HH:mm:ss',
  DAY_WITH_MONTH: 'DD.MM',
  FULLDATE_NO_TIME: 'DD.MM.YYYY',
  FULLTIME: 'HH:mm:ss',
  RANGE_PICKER: 'DD-MM-YYYY HH:mm',
  RANGE_PICKER_TIME: 'HH:mm',
  BY_MONTH: 'MMM',
};

export const RANGES = {
  getHourRange: () => [dayjs().startOf('hour'), dayjs().endOf('hour')],
  getDayRange: () => [dayjs().startOf('day'), dayjs().endOf('day')],
  getWeekRange: () => [dayjs().startOf('week'), dayjs().endOf('week')],
  getMonthRange: () => [dayjs().startOf('month'), dayjs().endOf('month')],
  getQuarterRange: () => [dayjs().startOf('quarter'), dayjs().endOf('quarter')],
  getYearRange: () => [dayjs().startOf('year'), dayjs().endOf('year')],
};

export const SECONDS_PER_DAY = 86400;

export const THREE_WEEKS = 21 * 24 * 60 * 60 * 1000;

export enum DatePeriodType {
  lastHour = 'lastHour',
  today = 'today',
  lastWeek = 'lastWeek',
  lastMonth = 'lastMonth',
  lastYear = 'lastYear',
}

export enum Months {
  'Jan' = 'Jan',
  'Feb' = 'Feb',
  'Mar' = 'Mar',
  'Apr' = 'Apr',
  'May' = 'May',
  'Jun' = 'Jun',
  'Jul' = 'Jul',
  'Aug' = 'Aug',
  'Sep' = 'Sep',
  'Oct' = 'Oct',
  'Nov' = 'Nov',
  'Dec' = 'Dec',
}
