import React, { FC, useState } from 'react';
import { Button, Checkbox, Progress, Space, Spin, Radio } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useAppSelector } from 'hooks/store-hooks';
import wimSelectors from 'store/wim/selectors';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RadioChangeEvent } from 'antd/es/radio/interface';
import { useTranslation } from 'react-i18next';
import { StyledExportDialogCard, StyledCloseBtn } from './styles';

interface ExportDialogProps {
  progress?: number;
  completed?: boolean;
  fileIsLoading: boolean;
  visibleButtons: { cancel: boolean; export: boolean; getFile: boolean };
  onClose: () => void;
  onExport: (format: string) => void;
  onCancel: () => void;
  getFile: () => void;
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ExportDialog: FC<ExportDialogProps> = ({
  onClose,
  onExport,
  progress,
  completed,
  fileIsLoading,
  onCancel,
  getFile,
  visibleButtons,
}) => {
  const [checkedLink, setCheckedLink] = useState(false);
  const [format, setFormat] = useState('csv');

  const { t } = useTranslation();
  const exportDialogIsOpen = useAppSelector(wimSelectors.transits.exportDialogIsOpen);

  const onChangeLink = (e: CheckboxChangeEvent) => {
    setCheckedLink(e.target.checked);
  };

  const onChangeFormat = (e: RadioChangeEvent) => {
    setFormat(e.target.value);
  };

  const onClickExport = () => {
    onExport(format);
  };

  const onGetFile = () => {
    setCheckedLink(false);
    setFormat('csv');
    getFile();
  };

  const onCancelFileDownload = () => {
    setCheckedLink(false);
    setFormat('csv');
    onCancel();
  };

  if (fileIsLoading) {
    return (
      <StyledExportDialogCard style={{ textAlign: 'center', width: 100 }}>
        <Spin indicator={antIcon} />
      </StyledExportDialogCard>
    );
  }

  if (!exportDialogIsOpen && progress) {
    return (
      <div style={{ position: 'absolute', top: 50, left: '1%', zIndex: 100, width: '98%' }}>
        <Progress percent={progress} size="default" showInfo={completed} />
      </div>
    );
  }

  if (!exportDialogIsOpen) {
    return null;
  }

  return (
    <StyledExportDialogCard title={t('transitsPage.exportDialog.exportDoc')}>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        {!progress && (
          <Checkbox checked={checkedLink} onChange={onChangeLink}>
            {t('transitsPage.exportDialog.createLink')}
          </Checkbox>
        )}
        {!progress && (
          <Radio.Group defaultValue="csv" buttonStyle="solid" onChange={onChangeFormat}>
            <Radio.Button value="csv">.csv</Radio.Button>
            <Radio.Button value="xlsx">.xlsx</Radio.Button>
          </Radio.Group>
        )}
        {progress && <Progress percent={progress} size="default" showInfo={completed} />}
        <Space>
          {visibleButtons.export && (
            <Button type="primary" onClick={onClickExport} disabled={!checkedLink || Boolean(progress)}>
              {t('common.export')}
            </Button>
          )}
          <StyledCloseBtn onClick={onClose}>{t('common.close')}</StyledCloseBtn>
          {visibleButtons.cancel && <Button onClick={onCancelFileDownload}>{t('common.cancel')}</Button>}
          {visibleButtons.getFile && <Button onClick={onGetFile}>{t('common.download')}</Button>}
        </Space>
      </Space>
    </StyledExportDialogCard>
  );
};

export default ExportDialog;
