import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MediaNodes } from '../types';

type InitialState = {
  isLoading: boolean;
  nodes: MediaNodes | null;
  error: any;
};

const initialState: InitialState = {
  isLoading: false,
  nodes: null,
  error: null,
};

const mediaCluster = createSlice({
  name: 'mediaCluster',
  initialState,
  reducers: {
    requestNodes: (state) => {
      state.isLoading = true;
    },
    getNodesSuccess: (state, { payload }: PayloadAction<MediaNodes>): void => {
      state.isLoading = false;
      state.nodes = payload;
    },
    getNodesError: (state, { payload }: PayloadAction<string>): void => {
      state.isLoading = false;
      state.error = payload;
    },
    clearNodes: (state): void => {
      state.nodes = null;
    },
  },
});

export const { requestNodes, getNodesSuccess, getNodesError, clearNodes } = mediaCluster.actions;

export default mediaCluster.reducer;
