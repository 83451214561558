import { DatePeriodType } from 'app-constants/date';

const Periods = {
  hour: 3600000,
  day: 86400000,
  week: 604800000,
  month: 2592000000,
  year: 31536000000,
};

export const getDatePeriod = (currentInterval: number) => {
  if (currentInterval <= Periods.hour) {
    return DatePeriodType.lastHour;
  }
  if (currentInterval >= Periods.hour && currentInterval <= Periods.day) {
    return DatePeriodType.today;
  }
  if (currentInterval >= Periods.day && currentInterval <= Periods.week) {
    return DatePeriodType.lastWeek;
  }
  if (currentInterval >= Periods.week && currentInterval <= Periods.year) {
    return DatePeriodType.lastMonth;
  }
  if (currentInterval >= Periods.year) {
    return DatePeriodType.lastYear;
  }
  return DatePeriodType.today;
};
