import React, { FC, useEffect } from 'react';

import { Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT, RANGES } from 'app-constants/date';

import { DatePicker } from 'components';
import { ContentFilterProps } from 'hoc/withFilter';
import { WimPlatformUid } from 'store/wim/types';
import { useViolationsFilter } from './hooks/use-violations-filter';

import { StyledSelect, StyledFilter } from './styles';

export type FilterValues = {
  start: number;
  end: number;
  wimUid: WimPlatformUid;
};

const ViolationsFilter: FC<ContentFilterProps<FilterValues>> = ({ filterManager }) => {
  const { Option } = Select;
  const { t } = useTranslation();
  const { onDateRangeChange, onWimChange, state, wims } = useViolationsFilter(filterManager);

  const ranges = {
    [t('date.month')]: RANGES.getMonthRange(),
    [t('date.year')]: RANGES.getYearRange(),
    [t('date.quarter')]: RANGES.getQuarterRange(),
  } as Record<string, [Dayjs | null, Dayjs | null]>;

  if (!wims) {
    return null;
  }

  return (
    <StyledFilter>
      <StyledSelect id="wims-list" onChange={onWimChange} value={state.wimUid}>
        {wims &&
          wims.map(({ wim, uid }) => (
            <Option value={uid} key={uid}>
              {wim}
            </Option>
          ))}
      </StyledSelect>
      <DatePicker.RangePicker
        clearIcon={false}
        value={[dayjs(state.start), dayjs(state.end)]}
        format={DATE_FORMAT.FULLDATE_NO_TIME}
        ranges={ranges}
        onChange={onDateRangeChange}
      />
    </StyledFilter>
  );
};

export default ViolationsFilter;
