import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { LaneTransit } from 'store/wim/types/transits';
import { DetailTransitsByWim } from 'pages/Transits/TransitsMap/helpers/get-platform-for-modal';
import { DATE_FORMAT } from 'app-constants/date';

import { StyledValueWrapper } from '../../styles';
import { StyledDetailsList } from './styles';
import { separateByThousands } from '../../../../../../../helpers/number.formatter';

interface TransitsDetailListByPlatformProps {
  transitsDetailsByWim: DetailTransitsByWim;
}

export const TransitsDetailListByPlatform: FC<TransitsDetailListByPlatformProps> = ({ transitsDetailsByWim }) => {
  const { t } = useTranslation();
  return (
    <StyledDetailsList>
      {Object.keys(transitsDetailsByWim).map((key) => {
        const value = transitsDetailsByWim[key as keyof DetailTransitsByWim];

        const valueWithMeasures = () => {
          if (key === 'maxWeight' || key === 'minWeight' || key === 'averageWeight') {
            return `${separateByThousands(value)} ${t('glossary.measureKilogram')}`;
          }

          if (key === 'averageSpeed') {
            return `${value} ${t('glossary.measureSpeed')}`;
          }

          if (key === 'lastTransitDate') {
            return dayjs(value).format(DATE_FORMAT.TABLE_FORMAT);
          }

          return value;
        };

        return (
          <li key={key}>
            <span>{`${t(
              `transitsMapPage.${
                key as keyof Omit<LaneTransit, 'laneUid' | 'violations' | 'lane' | 'direction' | 'transits'>
              }` as const
            )}: `}</span>
            <StyledValueWrapper>{valueWithMeasures()}</StyledValueWrapper>
          </li>
        );
      })}
    </StyledDetailsList>
  );
};
