import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spin } from 'antd';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';

import palette from 'palette';
import { FormikInputFieldWithLabel } from 'elements/FormikInputFieldWithLabel';
import { FormikInputFieldWithLabelAndDatePicker } from 'elements/FormikInputFieldWithLabelAndDatePicker';
import { getChangedValues } from 'helpers/formik.helpers';
import adminSelectors from 'store/admin/selectors';
import { StyledButtons, StyledContentForm, StyledEditBlock } from './styles';
import { CreatePlatform, PlatformFieldName } from './types';
import { createPlatformValidationSchema } from './validation-schema';

interface CreateContentProps {
  state: CreatePlatform;
  onSubmitForm: (values: Partial<CreatePlatform>) => void;
}

export const CreateContent: FC<CreateContentProps> = ({ state, onSubmitForm }) => {
  const isCreateUserLoading = useSelector(adminSelectors.users.isCreateUserLoading);
  const { t } = useTranslation();

  return (
    <Formik<CreatePlatform>
      initialValues={{
        address: state.address,
        mapPosition: state.mapPosition,
        wim: state.wim,
        serialNumber: state.serialNumber,
        alias: state.alias,
        description: state.description,
        provider: state.provider,
        region: state.region,
        road: state.road,
        status: state.status,
        measureStatus: state.measureStatus,
        verificationCertificate: {
          number: state.verificationCertificate.number,
          expirationDate: state.verificationCertificate.expirationDate,
        },
      }}
      validationSchema={createPlatformValidationSchema}
      onSubmit={(submittedValues) => {
        onSubmitForm(getChangedValues(submittedValues, state));
      }}
    >
      {({ errors, touched, values, handleChange, handleSubmit, handleReset, setFieldValue }) => (
        <StyledContentForm onSubmit={handleSubmit} onReset={handleReset}>
          <Spin spinning={isCreateUserLoading}>
            <StyledEditBlock>
              <FormikInputFieldWithLabel
                name={PlatformFieldName.address}
                onChange={handleChange}
                label={t('infoFields.address')}
                defaultValue={values.address || ''}
                errors={errors.address}
                touched={touched.address}
              />
              <FormikInputFieldWithLabel
                name={PlatformFieldName.mapPosition}
                onChange={handleChange}
                label={t('infoFields.coordinates')}
                defaultValue={values.mapPosition}
                errors={errors.mapPosition}
                touched={touched.mapPosition}
              />
              <FormikInputFieldWithLabel
                name={PlatformFieldName.wim}
                onChange={handleChange}
                label={t('infoFields.name')}
                defaultValue={values.wim}
                errors={errors.wim}
                touched={touched.wim}
              />
              <FormikInputFieldWithLabel
                name={PlatformFieldName.alias}
                onChange={handleChange}
                label={t('infoFields.shortName')}
                defaultValue={values.alias || ''}
              />
              <FormikInputFieldWithLabel
                name={PlatformFieldName.serialNumber}
                onChange={handleChange}
                label={t('infoFields.serialNumber')}
                defaultValue={values.serialNumber || ''}
              />
            </StyledEditBlock>
          </Spin>
          <StyledButtons>
            <Button htmlType="submit" style={{ color: palette.white.w1, backgroundColor: palette.blue.b1 }}>
              {t('common.apply')}
            </Button>
            <Button onClick={handleReset} style={{ color: palette.white.w1, backgroundColor: palette.red.r3 }}>
              {t('common.cancel')}
            </Button>
          </StyledButtons>
        </StyledContentForm>
      )}
    </Formik>
  );
};
