import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import { ContentFilterProps } from 'hoc/withFilter';
import { StyledFilterFormSearch } from './styles';
import { WimsPageFilterFormValues } from '../../types';

const FilterPanel: FC<ContentFilterProps<WimsPageFilterFormValues>> = ({ filterManager }) => {
  const { t } = useTranslation();
  const { handleFilterChange, state, onFilterSubmitClick } = filterManager;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFilterChange('search', event.currentTarget.value);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onFilterSubmitClick();
    }
  };

  return (
    <Row>
      <Col span={12}>
        <StyledFilterFormSearch
          id="search"
          name="search"
          placeholder={t('common.search')}
          onChange={onChange}
          value={state.search}
          onKeyDown={onKeyDown}
        />
      </Col>
    </Row>
  );
};

export { FilterPanel };
