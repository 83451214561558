import { WimPlatform } from 'store/wim/types';

export type CreatePlatformValues = Omit<WimPlatform, 'uid' | 'lastTransitDatetime'>;

export type CreatePlatform = Omit<WimPlatform, 'uid' | 'lastTransitDatetime' | 'lanes' | 'mapPosition'> & {
  mapPosition: string;
};

export enum PlatformFieldName {
  wim = 'wim',
  alias = 'alias',
  mapPosition = 'mapPosition',
  lanes = 'lanes',
  address = 'address',
  serialNumber = 'serialNumber',
  lastTransitDatetime = 'lastTransitDatetime',
  uid = 'uid',
  verificationCertificate = 'verificationCertificate',
}
