import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ExternalNotifications } from '../types';

type InitialState = {
  isLoading: boolean;
  notifications: ExternalNotifications | null;
  error: any;
};

const initialState: InitialState = {
  isLoading: false,
  notifications: null,
  error: null,
};

const externalNotifications = createSlice({
  name: 'externalNotifications',
  initialState,
  reducers: {
    requestExternalNotifications: (state) => {
      state.isLoading = true;
    },
    getExternalNotificationsSuccess: (state, { payload }: PayloadAction<ExternalNotifications>): void => {
      state.isLoading = false;
      state.notifications = payload;
    },
    getExternalNotificationsError: (state, { payload }: PayloadAction<string>): void => {
      state.isLoading = false;
      state.error = payload;
    },
    clearExternalNotifications: (state): void => {
      state.notifications = null;
    },
  },
});

export const {
  requestExternalNotifications,
  getExternalNotificationsSuccess,
  getExternalNotificationsError,
  clearExternalNotifications,
} = externalNotifications.actions;

export default externalNotifications.reducer;
