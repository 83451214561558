import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { deleteWimGroupBindings, getWimGroupBindings, postWimGroupBindings } from 'api/groups';
import { SagaPayload } from '../../../../types';
import {
  requestDeleteGroupBindings,
  requestDeleteGroupBindingsError,
  requestDeleteGroupBindingsSuccess,
  requestGetGroupBindings,
  requestGetGroupBindingsError,
  requestGetGroupBindingsSuccess,
  requestPostGroupBindings,
  requestPostGroupBindingsError,
  requestPostGroupBindingsSuccess,
} from './slice';
import { GetWimGroupBindingsResponse, PostGroupBindings, WimGroupBinding } from './types';
import { setNotificationError, setNotificationSuccess } from '../../notifications/slice';
import { MessageTitle } from '../../notifications/types';

export function* groupsGetRequestSaga() {
  try {
    const response: AxiosResponse<GetWimGroupBindingsResponse> = yield call(getWimGroupBindings, {});
    if (response) {
      yield put(requestGetGroupBindingsSuccess(response.data));
    }
  } catch (error) {
    yield put(requestGetGroupBindingsError(error));
  }
}

export function* addWimGroupRequestSaga({ payload }: SagaPayload<PostGroupBindings>) {
  try {
    const response: AxiosResponse<WimGroupBinding> = yield call(postWimGroupBindings(payload), {});

    if (response) {
      yield put(setNotificationSuccess({ title: MessageTitle.createGroupSuccess }));
      yield call(groupsGetRequestSaga);
      yield put(requestPostGroupBindingsSuccess(response.data));
    }
  } catch (error) {
    yield put(setNotificationError({ title: MessageTitle.createGroupError, message: error.message }));
    yield put(requestPostGroupBindingsError(error));
  }
}

export function* deleteWimGroupRequestSaga({ payload }: SagaPayload<PostGroupBindings>) {
  try {
    const response: AxiosResponse<string> = yield call(deleteWimGroupBindings(payload), {});

    if (response) {
      yield put(setNotificationSuccess({ title: MessageTitle.crudSuccess }));
      yield call(groupsGetRequestSaga);
      yield put(requestDeleteGroupBindingsSuccess(response.data));
    }
  } catch (error) {
    yield put(setNotificationError({ title: MessageTitle.crudError, message: error.message }));
    yield put(requestDeleteGroupBindingsError(error));
  }
}

export function* groupsGetRequestWatchSaga() {
  yield takeLatest(requestGetGroupBindings, groupsGetRequestSaga);
}

export function* addWimGroupRequestSagaWatchSaga() {
  yield takeLatest(requestPostGroupBindings, addWimGroupRequestSaga);
}

export function* deleteWimGroupRequestSagaWatchSaga() {
  yield takeLatest(requestDeleteGroupBindings, deleteWimGroupRequestSaga);
}
