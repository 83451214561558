import { AccessToken, JwtTokenType, RefreshToken } from 'store/auth/types';

type JwtTokensPair = {
  accessToken: AccessToken;
  refreshToken: RefreshToken;
};

class LocalStorageService {
  storage: Storage;

  constructor() {
    this.storage = window.localStorage;
  }

  setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  setTokens(tokens: JwtTokensPair) {
    this.setItem(JwtTokenType.ACCESS_TOKEN, tokens.accessToken);
    this.setItem(JwtTokenType.REFRESH_TOKEN, tokens.refreshToken);
  }

  getAccessToken(): AccessToken | null {
    return this.storage.getItem(JwtTokenType.ACCESS_TOKEN) as AccessToken;
  }

  getRefreshToken(): RefreshToken | null {
    return this.storage.getItem(JwtTokenType.REFRESH_TOKEN) as RefreshToken;
  }

  clearJwtTokens(): void {
    this.storage.removeItem(JwtTokenType.ACCESS_TOKEN);
    this.storage.removeItem(JwtTokenType.REFRESH_TOKEN);
  }
}

export const localStorageService = new LocalStorageService();
