import styled from 'styled-components/macro';

export const PlateRecognitionChartWrapper = styled.div`
  display: block;
  margin-top: 20px;
  margin-left: -10px;
  margin-right: auto;
  width: 100%;
  height: 200px;
`;

export const StyledBarChartViolations = styled.div`
  height: 150px;
`;

export const StyledBarChart = styled.div`
  height: 130px;
`;

export const StyledBarChartTransitsByWim = styled.div`
  height: 180px;
  width: 450px;
`;
