import React from 'react';

import { useModal, ModalState } from 'hooks/use-modal';
import { useUrlParams } from 'hooks/use-url-params';

interface ModalProps<ModalContent extends { uid: string }> {
  onOpen: (data: ModalContent, queryParam: string) => void;
  onClose: (platformType: string) => void;
  title: string | undefined;
  route: string;
  state: ModalState<ModalContent>['state'];
  isVisible: ModalState<ModalContent>['isVisible'];
  showModal: (isShown: boolean) => void;
  setModalState: (data: ModalContent) => void;
}

export interface ComponentModalProps<ModalContent extends { uid: string }> {
  modalManager: ModalProps<ModalContent>;
}

export const withModal =
  <OwnComponentProps extends Record<string, any>, ModalContent extends { uid: string }>(props: OwnComponentProps) =>
  (Component: React.FC<ComponentModalProps<ModalContent> & OwnComponentProps>): JSX.Element => {
    const modal = useModal<ModalContent>();
    const { onPushUrlParams, onRemoveUrlParams } = useUrlParams({});

    const handleModalClose = (platformType: string) => {
      modal.handleShowModal(false);
      onRemoveUrlParams(platformType);
    };

    const handleModalOpen = (data: ModalContent, queryParam: string) => {
      modal.addModalState(data);
      modal.handleShowModal(true);
      onPushUrlParams({ [queryParam]: data.uid });
    };

    const propsWithModal: ModalProps<ModalContent> = {
      title: props.getTitle(modal.state),
      route: props.getRoute(modal.state),
      onOpen: handleModalOpen,
      onClose: handleModalClose,
      state: modal.state,
      isVisible: modal.isVisible,
      showModal: modal.handleShowModal,
      setModalState: modal.addModalState,
    };

    return <Component {...props} modalManager={propsWithModal} />;
  };
