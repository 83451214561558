import React, { FC } from 'react';
import { Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FilterButton } from 'components';
import palette from 'palette';
import { stringifyUrlParams, useUrlParams } from 'hooks/use-url-params';
import { Modal } from 'types/modal';
import { StyledHeaderExtra } from './styles';

interface HeaderExtraProps {
  isFilterActive: boolean;
  onFilterOpen: () => void;
  onFilterClose: () => void;
}

export const HeaderExtra: FC<HeaderExtraProps> = ({ isFilterActive, onFilterOpen, onFilterClose }) => {
  const { pathname } = useLocation();
  const { queryUrlParams } = useUrlParams({});
  const { t } = useTranslation();

  return (
    <StyledHeaderExtra>
      <Tooltip
        title={<span style={{ color: palette.black.b1 }}>{t('adminWimsPage.createPlatform')}</span>}
        color={palette.white.w1}
      >
        <Link to={{ pathname, search: stringifyUrlParams({ ...queryUrlParams, modal: Modal.NEW_PLATFORM }) }}>
          <PlusOutlined style={{ fontSize: 20, color: palette.black.b1 }} />
        </Link>
      </Tooltip>
      <FilterButton isFilterActive={isFilterActive} onOpen={onFilterOpen} onClose={onFilterClose} />
    </StyledHeaderExtra>
  );
};
