import React, { PropsWithChildren } from 'react';
import { Input, Space, Select, Checkbox, Row, Col, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';

import { DatePicker, SliderWithNumericInputs } from 'components';
import { getInputSuffixFormatter } from 'helpers/label.formatter';
import { PLATE_FIELDS } from 'app-constants/plate-fields';
import { DATE_FORMAT, RANGES } from 'app-constants/date';

import { ContentFilterProps } from 'hoc/withFilter';
import { ComparativeAnalysisFilterValues } from 'store/wim/types/transits';

import { StyledSelect, TooltipContentWrapper } from './styles';
import { useComparativeAnalysisFilter } from './hooks/use-comparative-analysis-filter';

const { Option } = Select;

function ComparativeAnalysisFilterPanel<ComparativeAnalysisType extends ComparativeAnalysisFilterValues>({
  filterManager,
}: PropsWithChildren<ContentFilterProps<ComparativeAnalysisType>>): JSX.Element {
  const { t } = useTranslation();

  const {
    state,
    handleFilterChange,
    platforms,
    onPlatformNameChange,
    onRangePickerChange,
    getOnPlateChange,
    getOnSliderChange,
  } = useComparativeAnalysisFilter(filterManager);

  const ranges = {
    [t('date.month')]: RANGES.getMonthRange(),
    [t('date.year')]: RANGES.getYearRange(),
    [t('date.quarter')]: RANGES.getQuarterRange(),
  };
  return (
    <Space direction="vertical">
      <Row>
        <Col flex="stretch" style={{ width: 352 }}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <DatePicker.RangePicker
              clearIcon={false}
              value={[dayjs(state.start), dayjs(state.end)]}
              showTime={{ format: DATE_FORMAT.RANGE_PICKER_TIME }}
              format={DATE_FORMAT.RANGE_PICKER}
              ranges={ranges as Record<string, [Dayjs | null, Dayjs | null]>}
              onChange={onRangePickerChange}
            />
          </Space>
        </Col>
      </Row>

      <Row>
        <Space>
          <StyledSelect
            allowClear
            value={state.baseWim}
            showSearch
            placeholder={t('filter.baseWim')}
            optionFilterProp="children"
            onChange={(value) => handleFilterChange('baseWim', value)}
          >
            {platforms
              ?.filter((platform) => !state.intermediateWims?.includes(platform.uid) && platform.uid !== state.endWim)
              .map((platform) => (
                <Option key={platform.uid} value={platform.uid}>
                  {platform.wim}
                </Option>
              ))}
          </StyledSelect>
          <StyledSelect
            maxTagTextLength={16}
            maxTagCount={1}
            allowClear
            value={state.intermediateWims}
            showSearch
            placeholder={t('filter.intermediateWims')}
            optionFilterProp="children"
            onChange={onPlatformNameChange}
            mode="multiple"
          >
            {platforms
              ?.filter((platform) => platform.uid !== state.baseWim && platform.uid !== state.endWim)
              .map((platform) => (
                <Option key={platform.uid} value={platform.uid}>
                  {platform.wim}
                </Option>
              ))}
          </StyledSelect>
          <StyledSelect
            allowClear
            value={state.endWim}
            showSearch
            placeholder={t('filter.endWim')}
            optionFilterProp="children"
            onChange={(value) => handleFilterChange('endWim', value)}
          >
            {platforms
              ?.filter((platform) => !state.intermediateWims?.includes(platform.uid) && platform.uid !== state.baseWim)
              .map((platform) => (
                <Option key={platform.uid} value={platform.uid}>
                  {platform.wim}
                </Option>
              ))}
          </StyledSelect>
        </Space>
      </Row>

      <Row>
        <Space>
          <Col>
            <Space>
              <SliderWithNumericInputs
                title={t('filter.weight')}
                max={81000}
                startValue={state.weightFrom}
                endValue={state.weightTo}
                onStartChange={(value) => handleFilterChange('weightFrom', value)}
                onEndChange={(value) => handleFilterChange('weightTo', value)}
                onSliderChange={getOnSliderChange('weightFrom', 'weightTo')}
                formatter={getInputSuffixFormatter(t('glossary.measureKilogram'))}
              />
              <Checkbox
                checked={state.isMoreThanBaseWeight}
                onChange={(e) => handleFilterChange('isMoreThanBaseWeight', e.target.checked)}
              >
                {t('filter.moreThanBaseWeight')}
              </Checkbox>
            </Space>
          </Col>
          <Col>
            <Space direction="vertical">
              <Tooltip
                placement="bottom"
                title={<TooltipContentWrapper>{t('filter.plateHint')}</TooltipContentWrapper>}
              >
                <Input
                  value={state.plate}
                  onChange={getOnPlateChange(PLATE_FIELDS.Plate)}
                  placeholder={t('filter.plateFront')}
                  allowClear
                  suffix={<SearchOutlined />}
                />
              </Tooltip>
            </Space>
          </Col>
        </Space>
      </Row>
    </Space>
  );
}

export default ComparativeAnalysisFilterPanel;
