import env from 'config/env';
import { CameraInfo, wimCamerasMapper } from 'app-constants/wim-cameras-mapper';

export function constructWimVideoSrcLink(alias: string): string {
  return `${env.WAGA_API_URL}/video/${alias}/index.m3u8`;
}

export function getCamerasInfoByWimId(wimId: string): CameraInfo[] | undefined {
  const camerasInfo = wimCamerasMapper.find((wimObject) => wimObject.wim === wimId);
  return camerasInfo?.cameras;
}
