import { useEffect, useState } from 'react';
import { MediaNode } from '../../../store/system/types';

export type QueryUrl = {
  defaultQuery: string | undefined;
};

type UpdateStateHandler = (value: Partial<NodePageState>) => void;

interface NodePageState {
  nodes: MediaNode[] | null;
  node: MediaNode | null;
}

function getSelectedNode(
  nodes: MediaNode[] | null,
  firstNode: MediaNode | null,
  previouslySelected: MediaNode | null,
  queryNodeId?: string
): MediaNode | null {
  if (queryNodeId && nodes) {
    return nodes.find((node) => node.id.toString() === queryNodeId) || null;
  }
  if (!previouslySelected && firstNode) {
    return firstNode;
  }

  return previouslySelected;
}

const useNodesPageState = (nodes: MediaNode[] | null, queryUrlParams: Record<string, any>) => {
  const [nodesPageState, setNodesPageState] = useState<NodePageState>({
    nodes: null,
    node: null,
  });

  useEffect(() => {
    if (nodes) {
      const copyNodes = [...nodes];
      const defaultSortedNodes = copyNodes;

      const firstNode = defaultSortedNodes[0];
      setNodesPageState((prevState) => ({
        nodes: defaultSortedNodes,
        node: getSelectedNode(defaultSortedNodes, firstNode, prevState.node, queryUrlParams.user),
      }));
    }
  }, [nodes]);

  const handleUpdateNodesPageState: UpdateStateHandler = (newState) => {
    setNodesPageState({ ...nodesPageState, ...newState });
  };

  return {
    nodesPageState,
    handleUpdateNodesPageState,
  };
};

export { useNodesPageState };
