import { put, takeEvery } from 'redux-saga/effects';
import makeRequestSaga from 'store/common';
import { getTruckTraffic } from 'api/statistics';
import { SagaPayload } from 'types';
import { GetStatisticQueryParamsType } from '../types';
import { requestTrucksTransits, getTrucksTransitsSuccess, getTrucksTransitsError, setIsLoading } from './slice';

export function* trucksTransitsRequestSaga({ payload }: SagaPayload<GetStatisticQueryParamsType>) {
  yield put(setIsLoading(true));
  yield makeRequestSaga(getTrucksTransitsSuccess, getTrucksTransitsError, getTruckTraffic, {
    ...payload,
    weightFrom: 12000,
  });
}

export function* trucksTransitsRequestWatchSaga() {
  yield takeEvery(requestTrucksTransits, trucksTransitsRequestSaga);
}
