import { getIntegerPercent } from 'helpers/percent.formatter';
import { WimSummaryType } from 'store/platforms/wims/wim/transits/summary/types';

export const getSummaryChartData = (summaryData: WimSummaryType[]) => {
  let min = 0;
  let max = 0;

  const chartData = [];

  const data = summaryData
    .reduce((accum: any[], summary) => {
      const result = [...accum];
      const error = getIntegerPercent(summary.error);
      const indexError = accum.findIndex((val) => val.error === error && val[summary.type]);

      if (min > error) {
        min = error;
      }
      if (max < error) {
        max = error;
      }

      if (indexError !== -1) {
        result[indexError][summary.type] += 1;
        return result;
      }

      result.push({
        [summary.type]: 1,
        error,
      });

      return result;
    }, [])
    .sort((a, b) => a.error - b.error);

  for (let i = min; i <= max; i += 1) {
    const currentError = data.findIndex((a: { [key: string]: string | number }) => a.error === i);

    if (currentError !== -1) {
      chartData.push(data[currentError]);
    } else {
      chartData.push({
        car: 0,
        error: i,
      });
    }
  }

  return chartData;
};
