import { combineReducers } from '@reduxjs/toolkit';

import * as mediaNodesActions from './media-cluster/slice';
import mediaNodesSlices from './media-cluster/slice';
import * as externalNotificationsActions from './notifications/slice';
import externalNotificationsSlices from './notifications/slice';
import * as connectorsActions from './connectors/slice';
import connectorsSlices from './connectors/slice';

export const systemSlices = combineReducers({
  mediaNodes: mediaNodesSlices,
  externalNotifications: externalNotificationsSlices,
  connectors: connectorsSlices,
});

export const systemActions = {
  mediaNodes: { ...mediaNodesActions },
  externalNotifications: { ...externalNotificationsActions },
  connectors: { ...connectorsActions },
};
