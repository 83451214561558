import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Popconfirm, Spin, Table } from 'antd';

import { Group } from 'store/admin/groups/types';
import { StyledTableWrapper } from 'components/TableContainer/EditableTableContainer/styles';
import { ColumnType } from 'antd/lib/table/interface';
import { useAppSelector } from 'hooks/store-hooks';
import adminSelectors from 'store/admin/selectors';
import { FormikSelectWimDeviceGroup } from 'elements/FormikSelectWimDeviceGroup';
import { StyledButtons, StyledContentForm, StyledEditBlock } from './styles';
import { groupsColumns } from '../../table-schema';

interface UpdateContentProps {
  groups: Group[];
  onAddNew: (id: number) => void;
  onDelete: (id: number) => void;
  isLoading: boolean;
}

export const UpdateContent: FC<UpdateContentProps> = ({ groups, onAddNew, onDelete, isLoading }) => {
  const { t } = useTranslation();
  const deviceGroups = useAppSelector(adminSelectors.groups.getGroups);
  const platformGroupIds = groups.map((item) => item.id);

  const actionColumn: ColumnType<Group> = {
    dataIndex: 'action',
    render: (_text, _record) => (
      <Popconfirm
        title={t('phrases.sureToDelete')}
        onConfirm={() => onDelete(_record.id)}
        okText={t('common.yes')}
        cancelText={t('common.no')}
      >
        <a href="/">{t('common.delete')}</a>
      </Popconfirm>
    ),
  };

  return (
    <StyledContentForm>
      <Spin spinning={isLoading}>
        <StyledEditBlock>
          <StyledTableWrapper>
            <Table<Group>
              dataSource={groups}
              columns={[...groupsColumns, actionColumn]}
              rowClassName={() => 'editable-row'}
              locale={{ emptyText: t('common.noData') }}
              pagination={false}
            />
            <StyledButtons>
              {t('group.addGroup')}:
              <FormikSelectWimDeviceGroup
                deviceGroups={deviceGroups?.filter((item) => !platformGroupIds.includes(item.id)) || []}
                onSelect={onAddNew}
              />
            </StyledButtons>
          </StyledTableWrapper>
        </StyledEditBlock>
      </Spin>
    </StyledContentForm>
  );
};
