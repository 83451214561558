export const enum PLATE_FIELDS {
  Plate = 'plate',
  PlateBack = 'plateBack',
  PlateRecognized = 'plateRecognized',
  PlateBackRecognized = 'plateBackRecognized',
  PlateNoRead = 'NoRead',
  PlateNoReadSecond = 'No-Read',
}

export const PLATE_RECOGNITION_PERCENTAGE = 75;
