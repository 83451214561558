import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Empty, Spin } from 'antd';
import dayjs from 'dayjs';

import { CardWithList } from 'components';

import { useAppSelector } from 'hooks/store-hooks';
import { statisticsSelectors } from 'store/statistics/selectors';

import ListItem from './ListItem/ListItem';
import { StyledPlatformCards } from './styles';

const PlatformCards: FC = () => {
  const { t } = useTranslation();
  const platforms = useAppSelector(statisticsSelectors.platformsList.getPlatformsList);
  const intruders = useAppSelector(statisticsSelectors.intrudersList.getIntrudersList);

  return (
    <StyledPlatformCards>
      <CardWithList title={t(`homePage.lists.wimViolationsList`)}>
        <Spin spinning={platforms.isLoading}>
          {platforms.data.length !== 0 ? (
            platforms.data.map((item, index) => (
              <ListItem {...item} key={`${item.name}-${dayjs().valueOf()}`} counter={index + 1} />
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('common.noData')} />
          )}
        </Spin>
      </CardWithList>
      <CardWithList title={t(`homePage.lists.intrudersList`)}>
        <Spin spinning={intruders.isLoading}>
          {intruders.data.length !== 0 ? (
            intruders.data.map((item, index) => (
              <ListItem {...item} key={`${item.vrpFront}-${item.date}`} counter={index + 1} />
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('common.noData')} />
          )}
        </Spin>
      </CardWithList>
    </StyledPlatformCards>
  );
};

export default PlatformCards;
