import React, { MouseEventHandler } from 'react';

import { Bar, BarChart, CartesianGrid, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import dayjs from 'dayjs';

import { DATE_FORMAT } from 'app-constants/date';
import palette from 'palette';
import { getMinMax } from 'pages/Violations/helpers/get-min-max';
import { SummaryError, SummaryViolation } from 'store/wim/types/platforms';
import setBackground from 'helpers/set-background';
import ViolationsChartTooltip from './Tooltips/ViolationsChartTooltip';

import { StyledBarChartViolations } from './styles';

export interface ViolationsBarChartProps {
  data: SummaryError[] | SummaryViolation[] | DataType[] | null;
  dataKeys: DataKey[];
  unit?: string;
  dataKeyX: string;
  height?: number;
  onClickBar?: (data: MouseEventHandler) => void | null;
  tooltip?: boolean;
  syncId?: string | number;
}

export type DataKey = {
  key: string;
  dataKey: string;
  fill: string;
  stackId: string;
};

export type DataType = {
  [key: string]: string | number;
};

const BarChartViolations: React.FC<ViolationsBarChartProps> = ({
  data,
  dataKeys,
  height = 130,
  dataKeyX,
  unit = '',
  onClickBar,
  tooltip = true,
  syncId = 'anyId',
}): JSX.Element | null => {
  if (!data) {
    return null;
  }
  const [min, max] = getMinMax(data, dataKeyX);

  return (
    <StyledBarChartViolations>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={250} height={height} data={data} margin={{ top: 10 }} syncId={syncId} barCategoryGap="10%">
          <CartesianGrid strokeDasharray="1" vertical={false} />
          <XAxis
            dataKey={dataKeyX}
            tick={({ x, y, payload }) => (
              <g transform={`translate(${x - 3},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="middle" fill={palette.grey.g10}>
                  {dataKeyX === 'date' ? dayjs(payload.value).format(DATE_FORMAT.DAY_WITH_MONTH) : payload.value}
                </text>
              </g>
            )}
            minTickGap={10}
          />
          <YAxis unit={unit} axisLine={false} tickLine={false} domain={[0, max]} tickCount={3} ticks={[0, max]} />
          <ReferenceLine y={0} stroke={palette.grey.g10} />
          {tooltip && <Tooltip content={<ViolationsChartTooltip unit={unit} />} />}
          {dataKeys?.map(({ dataKey, key, fill, stackId }) => (
            <Bar
              dataKey={dataKey}
              stackId={stackId}
              fill={fill}
              key={key + dataKey}
              shape={setBackground}
              onClick={onClickBar}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </StyledBarChartViolations>
  );
};

export { BarChartViolations };
