import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import escapeStringRegexp from 'escape-string-regexp';

import { HeaderConfigContext } from 'app/App';
import { ListWithSummary, ModalWrapper } from 'components';
import { ComponentSortProps, withSort } from 'hoc/withSort';
import { useUrlParams } from 'hooks/use-url-params';
import { ComponentFilterProps, withFilter } from 'hoc/withFilter';
import { adminActions } from 'store/admin/slices';
import { User } from 'store/user/types';
import { Modal } from 'types/modal';
import { Tabs } from 'antd';
import dayjs from 'dayjs';
import Row from './components/Row';
import { StyledContent, StyledSummary } from './styles';
import { useGetUsers } from './hooks/use-get-users';
import { newUser, useUsersPageState } from './hooks/use-users-page-state';
import { usersListSorters } from './sorting-schema';
import { SortSelect, SortSelectProps } from './components/SortSelect';
import { CreateUserValues, UsersFilterFormValues, UsersListSort } from './types';
import { useUsersState } from './hooks/use-users-state';
import { HeaderExtra } from './components/HeaderExtra';
import { FilterPanel } from './components/FilterPanel';
import { CreateContent } from './modal/CreateContent';
import { UserSummary } from './components/UserSummary';
import { useGetGroups } from '../Groups/hooks/use-get-groups';
import { LogsSummary } from './components/LogsSummary';
import { useAppSelector } from '../../../hooks/store-hooks';
import adminSelectors from '../../../store/admin/selectors';

enum SummaryTab {
  MAIN_INFO = 'MAIN_INFO',
  LOGS = 'LOGS',
}

type UsersProps = ComponentFilterProps<UsersFilterFormValues> & ComponentSortProps<SortSelectProps>;

const UsersPage: React.FC<UsersProps> = ({ filterManager, sortManager }) => {
  const { setHeaderRenderer } = useContext(HeaderConfigContext);
  const dispatch = useDispatch();
  const { queryUrlParams, onPushUrlParams } = useUrlParams({});

  const { users, isUsersLoading, isUpdateUserLoading, isUsersLoaded, isCreateUserLoading } = useUsersState();
  const { usersPageState, handleUpdateUsersPageState } = useUsersPageState(users, queryUrlParams);
  const [filteredUsers, setFilteredUsers] = useState(usersPageState.users);
  const activityLogs = useAppSelector(adminSelectors.users.getUserActivityLogs);
  const {
    goBack,
    location: { search },
  } = useHistory();
  const { t } = useTranslation();

  const { sorters, SortComponent } = sortManager;
  const { handleFilterOpen, handleFilterClose, isFilterActive, isFilterSubmitted, state, handleFilterSubmitted } =
    filterManager;

  const onFilter = () => {
    if (usersPageState.users && state.search) {
      const regExp = new RegExp(escapeStringRegexp(state.search || ''), 'i');
      const selectedUser = usersPageState.users.filter(({ fullName }) => fullName && regExp.test(fullName))[0] || null;

      setFilteredUsers(usersPageState.users.filter(({ fullName }) => fullName && regExp.test(fullName)));
      handleUpdateUsersPageState({ selectedUser });
      handleFilterSubmitted(false);
    }
  };

  const onSort = (sort: UsersListSort) => {
    if (usersPageState.users) {
      const sortedUsers = usersPageState.users.sort(sorters[sort]);

      handleUpdateUsersPageState({ users: sortedUsers });
      onPushUrlParams({ sort });
    }
  };

  useGetUsers();
  useGetGroups();

  useEffect(() => {
    setHeaderRenderer(
      <HeaderExtra isFilterActive={isFilterActive} onFilterOpen={handleFilterOpen} onFilterClose={handleFilterClose} />
    );

    return () => setHeaderRenderer(null);
  }, [setHeaderRenderer, isFilterActive]);

  useEffect(() => {
    if (!queryUrlParams.filter) {
      setFilteredUsers(usersPageState.users);
    }
  }, [queryUrlParams.filter, usersPageState.users]);

  useEffect(() => {
    if (isFilterSubmitted) {
      onFilter();
    }
  }, [isFilterSubmitted, usersPageState.users]);

  useEffect(() => {
    if (!activityLogs && usersPageState.selectedUser) {
      dispatch(adminActions.users.getUserLogActivity({ initiatorUid: usersPageState.selectedUser.uid }));
    }
  }, [usersPageState.selectedUser, activityLogs, dispatch]);

  const onLogExport = () => {
    const fileName = `user_activity_${dayjs().format('YYYY-MM-DDTHH-mm-ss')}.csv`;
    if (activityLogs && usersPageState.selectedUser) {
      dispatch(
        adminActions.users.getUserLogActivityExport({ initiatorUid: usersPageState.selectedUser.uid, fileName })
      );
    }
  };

  const onSelectedUser = (user: User) => {
    handleUpdateUsersPageState({ selectedUser: user });
    onPushUrlParams({ user: user.uid });
    dispatch(adminActions.users.getUserLogActivity({ initiatorUid: user.uid }));
  };

  const onSubmitNewUser = (values: CreateUserValues) => {
    dispatch(adminActions.users.requestCreateUser(values));
    handleUpdateUsersPageState({ newUser });
    goBack();
  };

  const renderItem = (item: User) => (
    <Row
      item={item}
      onItemClick={onSelectedUser}
      selectedItem={usersPageState.selectedUser}
      isListLoaded={isUsersLoaded}
    />
  );

  return (
    <StyledContent>
      <StyledSummary>
        <SortComponent defaultValue={queryUrlParams.sort} onChange={onSort} />
        <ListWithSummary
          list={filteredUsers}
          isSelectedItemLoading={isUsersLoading}
          isListLoading={isUsersLoading}
          renderItem={renderItem}
          rowKey={(item) => `list-item-${item.uid}`}
        >
          <Tabs defaultActiveKey={SummaryTab.MAIN_INFO}>
            <Tabs.TabPane key={SummaryTab.MAIN_INFO} tab={t('navBar.userInfo')}>
              {usersPageState.selectedUser && (
                <UserSummary state={usersPageState.selectedUser} isLoading={isUpdateUserLoading} isEditable />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane key={SummaryTab.LOGS} tab={t('navBar.userLogs')}>
              {usersPageState.selectedUser && <LogsSummary logs={activityLogs} onExport={onLogExport} />}
            </Tabs.TabPane>
          </Tabs>
        </ListWithSummary>
      </StyledSummary>
      <ModalWrapper
        isVisible={search.includes(`modal=${Modal.NEW_USER}`)}
        onClose={goBack}
        title={t('user.modal.title.add')}
        width={900}
        destroyOnClose
      >
        <CreateContent state={usersPageState.newUser} onSubmitForm={onSubmitNewUser} />
      </ModalWrapper>
    </StyledContent>
  );
};

function formatUrlParams({ search }: Record<string, any>): Partial<UsersFilterFormValues> {
  return {
    search: search || undefined,
  };
}

export default withFilter({
  filterProps: { initialState: { search: '' }, formatUrlParams },
  FilterContent: FilterPanel,
})(withSort({ sorters: usersListSorters, SortComponent: SortSelect })(UsersPage));
