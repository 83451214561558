export enum QueryURLParams {
  SORT_BY = 'sortBy',
  START = 'start',
  END = 'end',
}

export enum SortDirection {
  ASCEND = 1,
  DESCEND = -1,
}
