/*

Error: Warning: findDOMNode is deprecated in StrictMode.

Error description:
  This error occurs by the components of ant-design that have no support for React.StrictMode

Issues:
 - https://github.com/ant-design/ant-design/issues/22493
 - https://github.com/ant-design/ant-design/issues/26136

*/

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import { App } from 'app/App';
import StoreProviderWithRouter from 'app/StoreProviderWithRouter';
import httpService from 'services/http.service';
import secondaryHttpService from 'services/secondary-http.service';

dayjs.extend(quarterOfYear);
httpService.setupInterceptors();
secondaryHttpService.setupInterceptors();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <StoreProviderWithRouter>
        <App />
      </StoreProviderWithRouter>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
