import { put, takeEvery } from 'redux-saga/effects';
import makeRequestSaga from 'store/common';
import { getTransitsStatistic } from 'api/statistics';
import { SagaPayload } from 'types';
import { GetStatisticQueryParamsType } from '../types';
import {
  requestTransitsStatistic,
  getTransitsStatisticSuccess,
  getTransitsStatisticError,
  setIsLoading,
} from './slice';

export function* transitsStatisticRequestSaga({ payload }: SagaPayload<GetStatisticQueryParamsType>) {
  yield put(setIsLoading(true));
  yield makeRequestSaga(getTransitsStatisticSuccess, getTransitsStatisticError, getTransitsStatistic, payload);
}

export function* transitsStatisticRequestWatchSaga() {
  yield takeEvery(requestTransitsStatistic, transitsStatisticRequestSaga);
}
