import styled from 'styled-components/macro';

export const StyledLabel = styled.span``;

export const StyledDescription = styled.span``;

export const StyledTooltip = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  padding: 10px;
  font-size: 16px;
  color: rgb(38, 38, 38);
  background: white;
  border-radius: 3px;
  box-shadow: rgb(0 0 0 / 25%) 0px 0px 10px;
  transform: translateY(calc(-100%));
`;
