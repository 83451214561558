import React from 'react';

import dayjs from 'dayjs';
import i18n from 'i18n';
import { Link } from 'react-router-dom';

import { ROUTES } from 'app-constants/routes';

import { WIMAlertIcon, WIMDangerIcon, WIMNormalIcon } from 'assets/icons';
import { DATE_FORMAT } from 'app-constants/date';
import { formatIntegerPercent } from 'helpers/percent.formatter';
import { TimePeriod } from 'types/date-period';

import { StyledCard } from 'components/Cards/styles';
import {
  GaugeTitle,
  GaugeTitlePercent,
  GaugeTitleTotal,
  StyledAlertWims,
  StyledDeflectWims,
  StyledTotalWims,
  StyledWorkedWims,
  StyledFlexBlock,
  StyledLegend,
  StyledValue,
  StyledWimStatuses,
} from '../Gauges/styles';
import { getFormattedThousandsNumber } from '../../../helpers/number.formatter';

export enum GaugesStatistics {
  'wimsStatus' = 'wimsStatus',
  'totalTransits' = 'totalTransits',
  'trucksTransits' = 'trucksTransits',
  'overloadStatistics' = 'overloadStatistics',
}

interface CreateGaugeCardProps {
  title: GaugesStatistics;
  data: CardDataType;
  isLoading: boolean;
  previousTimePeriod?: TimePeriod;
}

export type CardDataType = {
  total?: number | 'N/A';
  currentData?: number | 'N/A';
  secondData?: number | 'N/A';
  delta?: number | 'N/A';
  warning?: number;
};

export const createGaugeCard = ({ data, title, isLoading, previousTimePeriod }: CreateGaugeCardProps) => {
  const { total, delta, currentData, secondData, warning } = data;
  let deltaByPercent: 'N/A' | string = 'N/A';

  const cardTitle = (
    <GaugeTitle>
      {i18n.t(`homePage.cards.${title}` as const)} {title === GaugesStatistics.wimsStatus && `(${total ?? 0})`}
    </GaugeTitle>
  );

  if (delta) {
    deltaByPercent = delta === 0 || delta === 'N/A' ? delta.toString() : `+${formatIntegerPercent(delta)}`;
  }

  return (
    <StyledCard title={cardTitle} key={title + dayjs().valueOf().toString()} loading={isLoading}>
      {title === GaugesStatistics.wimsStatus ? (
        <StyledTotalWims>
          <Link to={ROUTES.WimsMap}>
            <StyledWimStatuses>
              <StyledWorkedWims>{currentData || 0}</StyledWorkedWims>
              <StyledDeflectWims>{secondData || 0}</StyledDeflectWims>
              <StyledAlertWims>{warning || 0}</StyledAlertWims>
            </StyledWimStatuses>
          </Link>
          <StyledLegend>
            <StyledFlexBlock>
              <img src={WIMNormalIcon} alt={i18n.t('homePage.cards.legend.ok')} height={20} />
              <span>{i18n.t('homePage.cards.legend.ok')}</span>
            </StyledFlexBlock>
            <StyledFlexBlock>
              <img src={WIMDangerIcon} alt={i18n.t('homePage.cards.legend.error')} height={20} />
              <span>{i18n.t('homePage.cards.legend.error')}</span>
            </StyledFlexBlock>
            <StyledFlexBlock>
              <img src={WIMAlertIcon} alt={i18n.t('homePage.cards.legend.warning')} height={20} />
              <span>{i18n.t('homePage.cards.legend.warning')}</span>
            </StyledFlexBlock>
          </StyledLegend>
        </StyledTotalWims>
      ) : (
        <GaugeTitleTotal>{getFormattedThousandsNumber(currentData, ' ')}</GaugeTitleTotal>
      )}
      {(delta || delta === 0) && (
        <GaugeTitlePercent delta={delta}>
          {delta === 'N/A' ? delta : `${delta > 0 ? '+' : ''}${formatIntegerPercent(delta)}`}
        </GaugeTitlePercent>
      )}
      {(secondData || secondData === 0) && title !== GaugesStatistics.wimsStatus && (
        <div className="ant-card-footer home">
          <StyledValue>{`${i18n.t('homePage.filters.prevPeriod')}: `}</StyledValue>
          <span>{getFormattedThousandsNumber(secondData, ' ')}</span>
          <span>
            ({dayjs(previousTimePeriod?.start).format(DATE_FORMAT.FULLDATE_NO_TIME)} -{' '}
            {dayjs(previousTimePeriod?.end).format(DATE_FORMAT.FULLDATE_NO_TIME)})
          </span>
        </div>
      )}
    </StyledCard>
  );
};
