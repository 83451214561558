export function getChangedValues<FormikValues extends Record<string, any>>(
  submittedValues: FormikValues,
  initialValues: Partial<FormikValues>
): Partial<FormikValues> {
  return Object.entries(submittedValues).reduce<Partial<FormikValues>>((acc, [key, value]) => {
    const hasChanged = value !== null && initialValues[key] !== value;

    if (hasChanged) {
      acc[key as keyof FormikValues] = value;
    }

    return acc;
  }, {});
}
