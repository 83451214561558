import React, { FC } from 'react';
import { Spin } from 'antd';

import { useTranslation } from 'react-i18next';

import { PlatformStatisticsBarChar, PlateRecognitionStatisticsBarChart } from 'components/BarChart';

import { DatePeriodType } from 'app-constants/date';
import { statisticsSelectors } from 'store/statistics/selectors';
import { useAppSelector } from 'hooks/store-hooks';
import { getVrpDetectStatistic } from './helper/get-vrp-detect-statistic';
import { StyledChartBlock, StyledCharts } from './styles';

interface ChartsProps {
  datePeriod?: DatePeriodType;
  start: number;
  end: number;
}

const Charts: FC<ChartsProps> = ({ datePeriod, start, end }) => {
  const { t } = useTranslation();

  const transits = useAppSelector(statisticsSelectors.transitsStatistic.getTransitsStatistic);
  const violationOverload = useAppSelector(
    statisticsSelectors.violationOverloadStatistic.getViolationOverloadStatistic
  );
  const vrpDetect = useAppSelector(statisticsSelectors.vrpDetectStatistic.getVrpDetectStatistic);

  const vrpDetectByPercent = () =>
    vrpDetect?.data?.map((item) => {
      const { date, amountOfNotRecognized, amountOfRecognized } = item;

      return {
        detect: getVrpDetectStatistic(amountOfRecognized, amountOfNotRecognized),
        date,
      };
    });

  const transitsStatisticParsedData = transits?.data.map(({ date, totalTraffic, truckTraffic }) => ({
    count: totalTraffic,
    trucks: truckTraffic,
    date,
  }));

  return (
    <StyledCharts>
      <StyledChartBlock title={t('homePage.charts.transitsStatistic')}>
        <Spin spinning={transits.isLoading}>
          <PlatformStatisticsBarChar
            data={transitsStatisticParsedData}
            syncId="transitsStatistic"
            lineKey="trucks"
            datePeriod={datePeriod}
            start={start}
            end={end}
          />
        </Spin>
      </StyledChartBlock>
      <StyledChartBlock title={t('homePage.charts.violationOverloadStatistic')}>
        <Spin spinning={violationOverload.isLoading}>
          <PlatformStatisticsBarChar
            data={violationOverload.data}
            syncId="violationOverloadStatistic"
            datePeriod={datePeriod}
            start={start}
            end={end}
          />
        </Spin>
      </StyledChartBlock>
      <StyledChartBlock title={t('homePage.charts.vrpDetectStatistic')}>
        <Spin spinning={vrpDetect.isLoading}>
          <PlateRecognitionStatisticsBarChart
            data={vrpDetectByPercent()}
            syncId="vrpDetectStatistic"
            unit="%"
            datePeriod={datePeriod}
            start={start}
            end={end}
          />
        </Spin>
      </StyledChartBlock>
    </StyledCharts>
  );
};

export default Charts;
