import React, { useEffect, useState, FC } from 'react';

import dayjs from 'dayjs';

import { ComponentFilterProps, withFilter } from 'hoc/withFilter';
import useGetPlatforms from 'pages/Wim/hooks/use-get-platforms';
import { wimActions } from 'store/wim/slices';
import { useUrlParams } from 'hooks/use-url-params';
import { useAppDispatch } from 'hooks/store-hooks';
import { useHeaderWithFiltersRenderer } from 'components/HeaderFilter';
import { WimPlatformUid } from 'store/wim/types';
import { StyledViolations } from './styles';
import ViolationsFilter, { FilterValues } from './ViolationsFilter';
import Wims from './WimsList';
import ViolationsDetail from './ViolationsDetail';
import SummaryDetail from './SummaryDetail';
import { queryParamsToViolationsFilterValues } from './ViolationsFilter/schema';

export enum DetailType {
  violations = 'violations',
  summary = 'summary',
}

const INITIAL_VIOLATIONS_FILTER_VALUES: Partial<FilterValues> = {
  start: dayjs().subtract(3, 'week').valueOf(),
  end: dayjs().valueOf(),
};

const Violations: FC<ComponentFilterProps<FilterValues>> = ({ filterManager }) => {
  const { queryUrlParams, onPushUrlParams, onRemoveUrlParams } = useUrlParams({});

  useHeaderWithFiltersRenderer({ filterManager });
  useGetPlatforms();

  const { state, isFilterSubmitted } = filterManager;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (queryUrlParams?.filter?.wimUid && queryUrlParams?.filter?.start && queryUrlParams?.filter?.end) {
      if (isFilterSubmitted) {
        dispatch(wimActions.platforms.requestPlatformSummary({ ...queryUrlParams.filter, lastTransit: false }));
      }
    }
  }, [queryUrlParams?.filter?.start, queryUrlParams?.filter?.end, queryUrlParams?.filter?.wimUid]);

  const closeDetailHandler = () => {
    onRemoveUrlParams(['violationsModal', 'summaryModal']);
  };

  const showDetailHandler = (detailType: DetailType, date: number, laneUid?: string) => {
    if (detailType === DetailType.violations) {
      onPushUrlParams({ violationsModal: { date, wimUid: state.wimUid, laneUid } });
    }

    if (detailType === DetailType.summary) {
      onPushUrlParams({ summaryModal: { date, wimUid: state.wimUid, laneUid } });
    }
  };

  return (
    <>
      <StyledViolations>
        {queryUrlParams?.filter?.wimUid && (
          <Wims
            wimUid={queryUrlParams.filter.wimUid}
            year={dayjs(state.start).get('year')}
            showDetail={showDetailHandler}
            start={state.start}
            end={state.end}
          />
        )}
      </StyledViolations>
      <ViolationsDetail
        isActive={!!queryUrlParams?.violationsModal}
        closeDetail={closeDetailHandler}
        wimUid={queryUrlParams?.filter?.wimUid as WimPlatformUid}
      />
      <SummaryDetail
        isActive={!!queryUrlParams?.summaryModal}
        closeDetail={closeDetailHandler}
        wimUid={queryUrlParams?.filter?.wimUid as WimPlatformUid}
      />
    </>
  );
};

export default withFilter<FilterValues>({
  filterProps: {
    initialState: INITIAL_VIOLATIONS_FILTER_VALUES,
    formatUrlParams: queryParamsToViolationsFilterValues,
    defaultOpen: true,
  },

  FilterContent: ViolationsFilter,
})(Violations);
