import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { BarChartViolations } from 'components/BarChart';
import { ViolationsLegend } from 'components/BarChart/Legends';
import { getIntegerPercent } from 'helpers/percent.formatter';
import { DetailType } from 'pages/Violations';
import { SummaryError, SummaryPlateRecognition, SummaryViolation } from 'store/wim/types/platforms';
import legends from './legends';
import { StyledLine, StyledLineTypeLg, StyledLineType } from './styles';

import PlateRecognitionWrapper from '../../../../../../Wim/Grid/components/List/components/WimContent/PlateRecognitionWrapper';
import { formatDateTo } from '../../../../../../../helpers/date.formatter';
import { DATE_FORMAT } from '../../../../../../../app-constants/date';
import { PlateRecognitionPayload } from '../../../../../../../components/BarChart/BarChartPlateRecognitions';

interface BarChartData {
  errors?: SummaryError[];
  violations?: SummaryViolation[];
  recognitions: PlateRecognitionPayload[];
}

export type LaneProps = {
  violations?: SummaryViolation[];
  errors?: ErrorType[];
  recognitions?: SummaryPlateRecognition[];
  laneUid?: string;
  laneType?: string;
  year?: number;
  start?: number;
  end?: number;
  showDetail: (detailType: DetailType, date: number, laneUid?: string) => void;
};

export type ErrorType = {
  date: number;
  error?: number;
  prediction?: number;
  interpolation?: number;
};

export const Lane: React.FC<LaneProps> = ({
  violations,
  errors,
  recognitions,
  laneUid,
  laneType,
  start,
  end,
  showDetail,
}) => {
  const [barChartData, setBarChartData] = useState<BarChartData>({ errors: [], violations: [], recognitions: [] });
  const { errorLegend, interpolationLegend, predictionLegend, fullLegend, axleLegend, bothLegend } = legends;
  const { t } = useTranslation();

  useEffect(() => {
    const currentErrors: SummaryError[] = errors
      ? errors.map((error) => {
          const newError = { ...error };

          if (newError.error) {
            newError.error = getIntegerPercent(newError.error);
          }

          if (newError.prediction) {
            newError.prediction = getIntegerPercent(newError.prediction);
          }

          if (newError.interpolation) {
            newError.interpolation = getIntegerPercent(newError.interpolation);
          }

          return newError;
        })
      : [];

    currentErrors.sort((a, b) => a.date - b.date);

    const currentViolations: SummaryViolation[] = violations ? [...violations] : [];
    currentViolations.sort((a, b) => a.date - b.date);

    const currentRecognitions: PlateRecognitionPayload[] = recognitions
      ? recognitions.map((element) => ({
          recognizedKey: element.recognized,
          unrecognizedKey: element.unrecognized,
          timestamp: element.date,
          date: formatDateTo(element.date, DATE_FORMAT.DAY_WITH_MONTH),
          fullDate: formatDateTo(element.date, DATE_FORMAT.FULLDATE_NO_TIME),
        }))
      : [];

    currentRecognitions.sort((a, b) => a.timestamp - b.timestamp);

    setBarChartData({ errors: currentErrors, violations: currentViolations, recognitions: currentRecognitions });
  }, [violations, start, end]);

  const showDetailHandler = (type: DetailType, data: any) => {
    showDetail(type, data.date, laneUid);
  };

  if (!barChartData.errors || !barChartData.violations) {
    return null;
  }

  return (
    <StyledLine gutter={[5, 5]}>
      <StyledLineType span={3}>
        <span>{laneType}</span>
      </StyledLineType>
      <StyledLineTypeLg span={10}>
        {barChartData.errors.length ? (
          <>
            <ViolationsLegend payload={[errorLegend, interpolationLegend, predictionLegend]} />
            <BarChartViolations
              data={barChartData?.errors}
              dataKeys={[
                { ...errorLegend, key: dayjs().toString(), stackId: 'err' },
                { ...interpolationLegend, key: dayjs().toString(), stackId: 'err' },
                { ...predictionLegend, key: dayjs().toString(), stackId: 'err' },
              ]}
              unit="%"
              dataKeyX="date"
              onClickBar={(data) => showDetailHandler(DetailType.summary, data)}
            />
          </>
        ) : (
          t('common.noData')
        )}
      </StyledLineTypeLg>
      <StyledLineTypeLg span={10}>
        {barChartData.recognitions.length ? (
          <>
            <PlateRecognitionWrapper
              data={barChartData.recognitions}
              recognizedKey="recognizedKey"
              unrecognizedKey="unrecognizedKey"
            />
          </>
        ) : (
          t('common.noData')
        )}
      </StyledLineTypeLg>
    </StyledLine>
  );
};
