import styled from 'styled-components/macro';

export const StyledContent = styled.div`
  width: 60%;
  height: 100%;
  position: relative;
`;

export const StyledEditLink = styled.div`
  float: right;
`;
