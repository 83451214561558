import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { adminActions } from 'store/admin/slices';

export const useGetGroups = (): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminActions.groups.requestGroups());
  }, []);
};
