import React, { useEffect, FC, useState } from 'react';
import { Spin } from 'antd';

import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector } from 'hooks/store-hooks';
import { wimActions } from 'store/wim/slices';
import { useUrlParams } from 'hooks/use-url-params';
import { ComponentFilterProps, withFilter } from 'hoc/withFilter';
import { useHeaderWithFiltersRenderer } from 'components/HeaderFilter';
import wimSelectors from 'store/wim/selectors';
import { WimMonitoringMetricStatistics } from 'store/wim/monitoring/types';
import { getDatePeriod } from 'pages/Home/helpers/get-date-period';
import { queryParamsToFilterValues } from './WimMonitoringFilter/hooks/schema';
import { MonitoringMetricStatistics } from './components/MonitoringMetricStatistics';
import { StyledMonitoringWrapper, StyledBlockWrapper, StyledBlockWrapperRight } from './styles';
import { WimMonitoringFilter } from './WimMonitoringFilter';
import { WimMonitoringMetricFilterValues } from './WimMonitoringFilter/hooks/use-wim-monitoring-filter';
import { WimMonitoringMetricStatisticsCard } from './components/WimMonitoringMetricStatisticsCard';

export interface WimMonitoringViewProps {
  wim?: any;
}

export const INITIAL_METRIC_FILTER_VALUES: Partial<WimMonitoringMetricFilterValues> = {
  start: dayjs().startOf('day').valueOf(),
  end: dayjs().endOf('day').valueOf(),
};

const MonitoringPage: FC<ComponentFilterProps<WimMonitoringMetricFilterValues>> = ({ filterManager }) => {
  const dispatch = useAppDispatch();
  const { queryUrlParams } = useUrlParams({});
  const wimMonitoringMetrics = useAppSelector(wimSelectors.monitoring.getWimMonitoringMetrics);
  const wimsMonitoringMetricStatistics = useAppSelector(wimSelectors.monitoring.getWimsMonitoringMetricStatistics);
  const isWimsMonitoringMetricStatisticsLoading = useAppSelector(
    wimSelectors.monitoring.isWimsMonitoringMetricStatisticsLoading
  );
  const isWimMonitoringMetricStatisticsByTimeLoading = useAppSelector(
    wimSelectors.monitoring.isWimMonitoringMetricStatisticsByTimeLoading
  );
  const wimMonitoringMetricStatisticsByTime = useAppSelector(
    wimSelectors.monitoring.getWimMonitoringMetricStatisticsByTime
  );
  const [isWimsMonitoringMetricStatisticsTableActive, setIsWimsMonitoringMetricStatisticsTableActive] = useState(false);
  const [isWimMonitoringMetricStatisticsByTimeTableActive, setIsWimMonitoringMetricStatisticsByTimeTableActive] =
    useState(false);
  const [currentWim, setCurrentWim] = useState<WimMonitoringMetricStatistics | null>(null);
  const { handleFilterOpen, isFilterSubmitted, state: filterState } = filterManager;
  const { t } = useTranslation();
  const currentInterval =
    filterState.end && filterState.start ? getDatePeriod(filterState.end - filterState.start) : undefined;

  useHeaderWithFiltersRenderer({ filterManager });

  const changeMetricHandler = (wim: WimMonitoringMetricStatistics) => {
    if (
      wimMonitoringMetrics &&
      queryUrlParams?.filter?.monitoringMetricId &&
      queryUrlParams?.filter?.start &&
      queryUrlParams?.filter?.end
    ) {
      const currentMonitoringMetric = wimMonitoringMetrics.find((monitoringMetric) => {
        return monitoringMetric.id.toString() === queryUrlParams.filter.monitoringMetricId;
      });

      if (currentMonitoringMetric) {
        dispatch(
          wimActions.monitoring.requestWimMonitoringMetricStatisticsByTime({
            start: queryUrlParams.filter.start,
            end: queryUrlParams.filter.end,
            wimUid: wim.uid,
            ...currentMonitoringMetric.filters,
          })
        );
        setCurrentWim(wim);
        setIsWimMonitoringMetricStatisticsByTimeTableActive(true);
      }
    }
  };

  useEffect(() => {
    handleFilterOpen();
  }, []);

  useEffect(() => {
    if (wimsMonitoringMetricStatistics) {
      setIsWimsMonitoringMetricStatisticsTableActive(true);
      setIsWimMonitoringMetricStatisticsByTimeTableActive(queryUrlParams?.filter?.wimUids?.length === 1);
    } else {
      setIsWimsMonitoringMetricStatisticsTableActive(false);
      setIsWimMonitoringMetricStatisticsByTimeTableActive(false);
    }
  }, [wimsMonitoringMetricStatistics]);

  useEffect(() => {
    if (wimMonitoringMetrics && wimMonitoringMetrics.length > 0) {
      if (queryUrlParams?.filter?.monitoringMetricId && queryUrlParams?.filter?.start && queryUrlParams?.filter?.end) {
        if (isFilterSubmitted) {
          const currentMonitoringMetric = wimMonitoringMetrics.find((monitoringMetric) => {
            return monitoringMetric.id.toString() === queryUrlParams.filter.monitoringMetricId;
          });

          if (currentMonitoringMetric) {
            dispatch(
              wimActions.monitoring.requestWimsMonitoringMetricStatistics({
                start: queryUrlParams.filter.start || filterState.start,
                end: queryUrlParams.filter.end || filterState.end,
                platformUids: queryUrlParams?.filter?.platformUids,
                ...currentMonitoringMetric.filters,
              })
            );
          }
        }
      } else {
        dispatch(wimActions.monitoring.clearWimsMonitoringMetricStatistics());
      }
    }
  }, [
    wimMonitoringMetrics,
    queryUrlParams?.filter?.monitoringMetricId,
    queryUrlParams?.filter?.start,
    queryUrlParams?.filter?.end,
    queryUrlParams?.filter?.platformUids,
  ]);

  return (
    <Spin spinning={isWimsMonitoringMetricStatisticsLoading}>
      <StyledMonitoringWrapper>
        <h1
          style={{
            display:
              !isWimsMonitoringMetricStatisticsTableActive && !isWimMonitoringMetricStatisticsByTimeTableActive
                ? 'inline-block'
                : 'none',
          }}
        >
          {t('wimsPage.monitoring.noMetric')}
        </h1>
        <StyledBlockWrapper>
          {isWimsMonitoringMetricStatisticsTableActive && wimsMonitoringMetricStatistics && (
            <MonitoringMetricStatistics
              onChangeWim={changeMetricHandler}
              wimsMonitoringMetricStatistics={wimsMonitoringMetricStatistics}
            />
          )}
        </StyledBlockWrapper>
        <StyledBlockWrapperRight>
          <Spin
            spinning={isWimMonitoringMetricStatisticsByTimeLoading}
            wrapperClassName={isWimMonitoringMetricStatisticsByTimeLoading ? 'min-height-bg' : ''}
          >
            {isWimMonitoringMetricStatisticsByTimeTableActive && wimMonitoringMetricStatisticsByTime && currentWim && (
              <WimMonitoringMetricStatisticsCard
                wim={currentWim}
                wimMonitoringMetricStatisticsByTime={wimMonitoringMetricStatisticsByTime}
                timePeriod={{ start: queryUrlParams?.filter?.start, end: queryUrlParams?.filter?.end }}
                datePeriod={currentInterval}
              />
            )}
          </Spin>
        </StyledBlockWrapperRight>
      </StyledMonitoringWrapper>
    </Spin>
  );
};

export default withFilter<WimMonitoringMetricFilterValues>({
  filterProps: {
    initialState: INITIAL_METRIC_FILTER_VALUES,
    formatUrlParams: queryParamsToFilterValues,
  },
  FilterContent: WimMonitoringFilter,
})(MonitoringPage);
