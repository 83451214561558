import { Select } from 'antd';
import styled from 'styled-components/macro';

export const StyledSelect = styled(Select)`
  width: 100%;
`;

export const StyledSelectWithLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLabel = styled.span`
  width: 35%;
`;
