import React, { useEffect, useState } from 'react';
import { MediaNode, MediaNodes } from '../../store/system/types';
import { StyledContent, StyledSummary } from '../AdminManagement/Groups/styles';
import { ListWithSummary, ModalWrapper } from '../../components';
import { useAppDispatch, useAppSelector } from '../../hooks/store-hooks';
import Row from './NodeRow';
import { useUrlParams } from '../../hooks/use-url-params';
import { useNodesState } from './hooks/use-nodes-state';
import { useNodesPageState } from './hooks/use-nodes-page-state';
import { NodeSummary } from './NodeSummary';
import { useGetNodes } from './hooks/use-get-nodes';

export interface MediaClusterPageProps {
  mediaNodes: MediaNodes;
}

const MediaCluster: React.FC = () => {
  const dispatch = useAppDispatch();
  const { queryUrlParams, onPushUrlParams } = useUrlParams({});
  const { mediaNodes, isNodesLoading, isNodesLoaded } = useNodesState();
  const { nodesPageState, handleUpdateNodesPageState } = useNodesPageState(
    mediaNodes ? mediaNodes.nodes : null,
    queryUrlParams
  );
  const [filteredUsers, setFilteredUsers] = useState(nodesPageState.nodes);

  // const nodes = useAppSelector(systemSelectors.mediaCluster.getMediaNodes);
  // const isLoading = useAppSelector(systemSelectors.mediaCluster.isLoading);

  useGetNodes();

  const onSelectedNode = (node: MediaNode) => {
    handleUpdateNodesPageState({ node });
    onPushUrlParams({ node: node.id });
  };

  const renderItem = (item: MediaNode) => (
    <Row item={item} onItemClick={onSelectedNode} selectedItem={nodesPageState.node} isListLoaded={isNodesLoaded} />
  );

  return (
    <StyledContent>
      <StyledSummary>
        <ListWithSummary
          list={mediaNodes ? mediaNodes.nodes : null}
          isSelectedItemLoading={isNodesLoading}
          isListLoading={isNodesLoading}
          renderItem={renderItem}
          rowKey={(item) => `list-item-${item.id}`}
        >
          {nodesPageState.node && <NodeSummary state={nodesPageState.node} />}
        </ListWithSummary>
      </StyledSummary>
    </StyledContent>
  );
};

export default MediaCluster;
