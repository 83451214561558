import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getRouteToTitle } from '../app-constants/i18n';
import { useDocumentTitle } from './use-document-title';
import { useSyncHeader, SyncHeaderHookReturnType } from './use-sync-header';

export function useOnRouteChange(): SyncHeaderHookReturnType {
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState<string>();

  useDocumentTitle({
    getRouteToTitle,
    location,
    currentRoute,
    setCurrentRoute,
  });

  const { setHeaderRenderer, headerTitle, headerContent, onBackButton, setHeaderOnBackButtonState, dev } =
    useSyncHeader({
      getRouteToTitle,
      location,
    });

  return {
    dev,
    setHeaderRenderer,
    headerTitle,
    headerContent,
    onBackButton,
    setHeaderOnBackButtonState,
  };
}
