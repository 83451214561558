import styled from 'styled-components/macro';
import { Button } from 'antd';
import { FilterOutlined, PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import palette from 'palette';

export const ButtonContainer = styled(Button)`
  display: flex;
  justify-content: center;
  margin: 0 24px;
  padding: 4px 8px;
`;

export const HeaderExtraContainer = styled.div`
  display: flex;
`;

export const PauseCircleIcon = styled(PauseCircleOutlined)`
  font-size: 22px;
  color: ${palette.blue.b1};
`;

export const PlayCircleIcon = styled(PlayCircleOutlined)`
  font-size: 22px;
  color: ${palette.blue.b1};
`;

export const Text = styled.span`
  font-size: 14px;
`;

export const FilterIcon = styled(FilterOutlined)`
  font-size: 20px;
  color: ${(props: { iconcolor: string }) => props.iconcolor};
`;

export const StyledHeaderExtraButtons = styled.div`
  display: flex;
`;
