import { RootState } from 'store/root-reducer';

const isLoading = (state: RootState) => state.auth.isLoading;
const isAuthenticated = (state: RootState) => state.auth.isAuthenticated;
const getLoginError = (state: RootState) => state.auth.loginError;
const isMFAActivationPage = (state: RootState) => state.auth.isMFAActivationPage;
const isMFAConfirmationPage = (state: RootState) => state.auth.isMFAConfirmationPage;
const isPasswordResetPage = (state: RootState) => state.auth.isPasswordResetPage;
const qrCodeBase64 = (state: RootState) => state.auth.qrCodeBase64;

export const authSelectors = {
  isLoading,
  getLoginError,
  isAuthenticated,
  isMFAActivationPage,
  isMFAConfirmationPage,
  isPasswordResetPage,
  qrCodeBase64,
};
