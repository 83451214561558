import styled from 'styled-components/macro';

export const LogoWrapper = styled.div`
  min-height: 64px;
  padding: 16px 0px;
  text-align: center;
  cursor: pointer;
`;

export default LogoWrapper;
