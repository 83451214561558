import React from 'react';
import L, { PointExpression } from 'leaflet';

import { CircleIcon } from 'assets/icons';
import { WimPlatformUid } from 'store/wim/types';
import { NormalizedTransitByWimUid } from 'store/wim/transits/normalization';
import { CustomMarker } from '../components/CustomMarker';

export const renderMarkers = (
  onClickMarker: (platform: WimPlatformUid) => void,
  transitsByWimUid: NormalizedTransitByWimUid[]
) => {
  if (!transitsByWimUid) {
    return null;
  }

  const baseSize = 30;
  const allTransits = transitsByWimUid.reduce<number[]>(
    (accum, transit) => [...accum, transit[Object.keys(transit)[0]].totalTransits],
    []
  );
  const minTransitsCount = Math.min(...allTransits);
  const zIndex = 100;

  return transitsByWimUid.map((transit) => {
    return Object.keys(transit).map((wimUid) => {
      const { mapPosition, totalTransits, platformName } = transit[wimUid];
      const currentSize = baseSize / (minTransitsCount / totalTransits);
      const size: PointExpression = [currentSize, currentSize];

      const currentZIndex = transitsByWimUid.reduce<number>(
        (accum, platform) =>
          platform[Object.keys(platform)[0]]?.totalTransits > totalTransits ? accum + 10 : accum - 10,
        zIndex
      );

      const Circle = L.icon({ iconUrl: CircleIcon, iconSize: size });
      const newMapPosition: [number, number] = [mapPosition.coordinates[1], mapPosition.coordinates[0]];

      return (
        <CustomMarker
          zIndexOffset={currentZIndex}
          key={`platform-${wimUid}`}
          icon={Circle}
          position={newMapPosition}
          name={platformName}
          onClick={() => onClickMarker(wimUid as WimPlatformUid)}
        />
      );
    });
  });
};
