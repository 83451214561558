import { ColumnsType } from 'antd/lib/table';

export const getMinColumnWidth = <TableRecord extends Record<string, any>>(columns: ColumnsType<TableRecord>): number =>
  columns.reduce((acc, currentColumn) => {
    let minWidth = acc;

    if (!minWidth) {
      minWidth = currentColumn.width as number;

      return minWidth;
    }

    if (currentColumn.width && minWidth > currentColumn.width) {
      minWidth = currentColumn.width as number;
    }

    return minWidth;
  }, 0);
