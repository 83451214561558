import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import i18n from 'i18n';

import { getSettings, updateSettings } from 'api/settings';
import { SagaPayload } from 'types';
import makeRequestSaga from 'store/common';

import {
  requestGetSettings,
  requestGetSettingsSuccess,
  requestGetSettingsError,
  requestPostSettings,
  requestPostSettingsSuccess,
  requestPostSettingsError,
} from './slice';

import { SettingsResponse, PostSettings } from './types';
import { setNotificationError, setNotificationSuccess } from '../notifications/slice';
import { MessageTitle } from '../notifications/types';

export function* settingsRequestSaga() {
  yield makeRequestSaga(requestGetSettingsSuccess, requestGetSettingsError, getSettings, {});
}

export function* settingsUpdateRequestSaga({ payload }: SagaPayload<PostSettings>) {
  try {
    const response: AxiosResponse<SettingsResponse> = yield call(updateSettings(payload), payload);
    if (response) {
      yield put(setNotificationSuccess({ title: MessageTitle.crudSuccess }));

      yield put(requestPostSettingsSuccess(response.data));
    }
  } catch (error) {
    const errRes = error as AxiosError;
    let err = { userMessage: i18n.t('settingsPage.serverError'), serverError: null };

    if (errRes.response && errRes.response.data) {
      err = errRes.response.data;
    }

    yield put(setNotificationError({ title: MessageTitle.crudError, message: error.message }));

    yield put(requestPostSettingsError(err));
  }
}

export function* settingsRequestWatchSaga() {
  yield takeLatest(requestGetSettings, settingsRequestSaga);
}

export function* settingsUpdateRequestWatchSaga() {
  yield takeLatest(requestPostSettings, settingsUpdateRequestSaga);
}
