import styled from 'styled-components/macro';

export const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const StyledBlock = styled.div`
  min-height: 470px;
`;
