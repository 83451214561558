import React from 'react';

import { Tooltip } from 'antd';

import { StyledTableTitle } from '../styles';

export const getTableTitle = (title: string) => (
  <Tooltip title={title}>
    <StyledTableTitle>{title}</StyledTableTitle>
  </Tooltip>
);
