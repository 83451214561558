import { useSelector } from 'react-redux';
import adminSelectors from 'store/admin/selectors';
import { useTranslation } from 'react-i18next';

const useDeviceGroupsNames = () => {
  const { t } = useTranslation();
  const deviceGroups = useSelector(adminSelectors.groups.getGroups);

  const getNames = (deviceGroupIds: number[]): string[] => {
    const groups = deviceGroups
      ?.filter((group) => deviceGroupIds.includes(group.id))
      .map((group) => group.name + (group.enabled ? '' : ` ${t('group.disabled')}`));

    return groups || [];
  };

  return {
    getDeviceGroupsNames: getNames,
  };
};

export { useDeviceGroupsNames };
