import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/root-reducer';
import { WimTransits } from '../types';
import {
  IntensityTraffic,
  TransitsArchiveFilterValues,
  WimTransit,
  WimTransitItemWithMapPosition,
  WimTransitPhotos,
} from '../types/transits';
import { groupTransitWithMapPositionByWimsUids } from './normalization';
import { IntensityTrafficSorting, intensityTrafficSorting } from './sorting';
import { DOCUMENT_STATUS } from './documentExport/slice';

export const getTransits = (state: RootState): WimTransits | null => state.wim.transits.transits;
export const isLoading = (state: RootState): boolean => state.wim.transits.isLoading;
export const isOnlineMode = (state: RootState): boolean => state.wim.transits.isOnlineMode;
export const getIntervalId = (state: RootState): number | null => state.wim.transits.onlineIntervalId;
export const getFilterValues = (state: RootState): Partial<TransitsArchiveFilterValues> | null =>
  state.wim.transits.filterValues;
export const getTransit = (state: RootState): WimTransit | null => state.wim.transits.transit;
export const getTransitPhotos = (state: RootState): WimTransitPhotos => state.wim.transits.transitPhotos;
export const isTransitPhotosLoading = (state: RootState): boolean => state.wim.transits.isTransitPhotosLoading;
export const getIntensityTraffic = createSelector(
  [
    (state: RootState): IntensityTraffic[] | null => state.wim.transits.intensityTraffic,
    (_state: RootState, sorting: IntensityTrafficSorting) => sorting,
  ],
  (intensityTrafficEntities, sorting) =>
    intensityTrafficEntities && intensityTrafficSorting(intensityTrafficEntities, sorting)
);
export const getNormalizedTransitsByWimsUid = createSelector(
  (state: RootState): WimTransitItemWithMapPosition[] | null => state.wim.transits.transitWithCoordinatesByPlate,
  (transitWithCoordinatesByPlate) =>
    transitWithCoordinatesByPlate && groupTransitWithMapPositionByWimsUids(transitWithCoordinatesByPlate)
);

export const exportDialogIsOpen = (state: RootState) => state.wim.transits.exportDialogIsOpen;

export const exportStartKey = (state: RootState): string | null => state.wim.documentExport.data;
export const exportStatus = (state: RootState): DOCUMENT_STATUS | null => state.wim.documentExport.status;
export const fileIsLoading = (state: RootState): boolean => state.wim.documentExport.fileIsLoading;
