import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware, { Task } from 'redux-saga';

import { SagaContext } from 'types/saga';
import { rootReducer } from './root-reducer';
import rootSaga from './root-saga';

const sagaMiddleware = createSagaMiddleware();
export const middleware = [...getDefaultMiddleware(), sagaMiddleware];

export const store = configureStore({
  reducer: rootReducer,
  middleware,
});

export const setupMiddleware = (context: SagaContext): Task => sagaMiddleware.run(rootSaga, context);
export type AppDispatch = typeof store.dispatch;
