import { useSelector } from 'react-redux';
import systemSelectors from '../../../store/system/selectors';

const useNotificationsState = () => {
  const externalNotifications = useSelector(systemSelectors.externalNotifications.getExternalNotifications);
  const isLoading = useSelector(systemSelectors.externalNotifications.isLoading);

  return {
    externalNotifications,
    isLoading,
    isLoaded: externalNotifications && !!externalNotifications.notifications.length,
  };
};

export { useNotificationsState };
