export interface LoginFormValues {
  email: string;
  password: string;
  remember: boolean;
}

export interface ConfirmationFormValues {
  code: string;
}

export interface PasswordResetFormValues {
  password: string;
  repeatPassword: string;
}

export enum LoginFormValue {
  email = 'email',
  password = 'password',
  remember = 'remember',
}

export enum ConfirmationFormValue {
  code = 'code',
}

export enum PasswordResetFormValue {
  password = 'password',
  repeatPassword = 'repeatPassword',
}
