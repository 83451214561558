import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';

(pdfMake as any).fonts = {
  // fonts via URL approach: https://pdfmake.github.io/docs/0.1/fonts/custom-fonts-client-side/url/
  Roboto: {
    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
  iconsFont: {
    normal: 'fontello.ttf',
  },
};

class PdfService {
  instance: typeof pdfMake;

  constructor() {
    this.instance = pdfMake;
  }

  getPdfDocumentBySchema(schema: TDocumentDefinitions) {
    return this.instance.createPdf(schema);
  }
}

const pdfService = new PdfService();

export default pdfService;
