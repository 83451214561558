import React from 'react';

import { useTranslation } from 'react-i18next';
import { TooltipProps } from 'recharts';
import dayjs from 'dayjs';

import { DATE_FORMAT } from 'app-constants/date';

import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { ViolationsType } from 'pages/Violations/types';

import {
  StyledLegendItemMarker,
  StyledTooltip,
  StyledTooltipWithWimStatisticsItem,
  StyledTooltipItemName,
  StyledTooltipItemValue,
} from './styles';

export interface ViolationsChartTooltipProps extends TooltipProps<ValueType, NameType> {
  unit: string;
}

const ViolationsChartTooltip: React.FC<ViolationsChartTooltipProps> = ({
  payload,
  label,
  unit,
}): JSX.Element | null => {
  const { t } = useTranslation();

  if (!payload) {
    return null;
  }

  const items = payload.map((item) => ({
    name: item.name && item.payload[item.name],
    dataKey: item.dataKey as ViolationsType,
    color: item.color,
  }));

  return (
    <StyledTooltip>
      {items.map(
        ({ dataKey, color, name }): JSX.Element => (
          <StyledTooltipWithWimStatisticsItem key={dataKey}>
            <StyledTooltipItemValue>
              {name} {unit}
            </StyledTooltipItemValue>
            <StyledLegendItemMarker color={color} />
            <StyledTooltipItemName>{t(`violationsPage.${dataKey}` as const)}</StyledTooltipItemName>
          </StyledTooltipWithWimStatisticsItem>
        )
      )}
      <span>{dayjs(label).format(DATE_FORMAT.TABLE_FORMAT)}</span>
    </StyledTooltip>
  );
};

export default ViolationsChartTooltip;
