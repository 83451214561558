import React, { PropsWithChildren } from 'react';
import { List, ListProps } from 'antd';

import { Loader } from 'components';
import { StyledList, StyledSummary } from './styles';

interface ListWithSummaryProps<ListItem> {
  list: ListItem[] | null;
  isListLoading: boolean;
  isSelectedItemLoading: boolean;
  renderItem: (item: ListItem) => JSX.Element;
  rowKey?: string | ((item: ListItem) => string);
}

export function ListWithSummary<ListItem>({
  list,
  isListLoading,
  isSelectedItemLoading,
  renderItem,
  rowKey,
  children,
}: PropsWithChildren<ListWithSummaryProps<ListItem>>): JSX.Element {
  const listProps: ListProps<ListItem> = {
    renderItem,
    rowKey,
    size: 'large',
    itemLayout: 'horizontal',
    loading: isListLoading,
    className: 'list-with-summary',
    split: false,
  };

  return (
    <StyledList>
      <List dataSource={list || undefined} {...listProps} />
      <StyledSummary>
        <Loader isLoading={isSelectedItemLoading}>{children}</Loader>
      </StyledSummary>
    </StyledList>
  );
}
