import { Card } from 'antd';
import styled from 'styled-components/macro';

import SFUITextSemibold from 'fonts/SFUIText-Semibold.woff';

export const StyledDetailCard = styled(Card)`
  @font-face {
    font-family: 'SFUITextSemibold';
    src: url(${SFUITextSemibold}) format('woff');
  }
`;

export const StyledValueWrapper = styled.span`
  font-family: 'SFUITextSemibold';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;
