import React, { FC } from 'react';
import { FieldArray } from 'formik';

import { EditableTableContainer } from 'components';
import { EditableTableColumn } from 'components/TableContainer/EditableTableContainer';
import { UserSocialNetwork } from 'store/user/types';

interface SocialNetworksTableProps {
  name: string;
  columns: EditableTableColumn<UserSocialNetwork>[];
  dataSource?: UserSocialNetwork[];
}

const FormikSocialNetworksTable: FC<SocialNetworksTableProps> = ({ name, columns, dataSource }) => {
  const rowAdd: UserSocialNetwork = {
    application: '',
    contact: '',
  };

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <EditableTableContainer<UserSocialNetwork>
          form={arrayHelpers}
          rowAdd={rowAdd}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          bordered
        />
      )}
    />
  );
};

export { FormikSocialNetworksTable };
