import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from './types';

interface InitialState {
  isLoading: boolean;
  entity: User | null;
  error: any;
}

const initialState: InitialState = {
  isLoading: false,
  entity: null,
  error: null,
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startUserLoading: (state) => {
      state.isLoading = true;
    },
    finishUserLoading: (state) => {
      state.isLoading = false;
    },
    getUserSuccess: (state, { payload }: PayloadAction<User>): void => {
      state.isLoading = false;
      state.entity = payload;
    },
    getUserError: (state, { payload }: PayloadAction<string>): void => {
      state.isLoading = false;
      state.error = payload;
    },
    clearUser: () => initialState,
  },
});

export const userActions = {
  ...user.actions,
  requestUser: 'userActions/requestUser',
};

export default user.reducer;
