import { WimPlatform } from 'store/wim/types';

export type UpdatePlatformValues = Omit<WimPlatform, 'uid' | 'lastTransitDatetime' | 'mapPosition'> & {
  mapPosition: string;
};

export type PlatformState = Omit<WimPlatform, 'mapPosition'> & {
  mapPosition: string;
};

export enum PlatformFieldName {
  wim = 'wim',
  alias = 'alias',
  description = 'description',
  mapPosition = 'mapPosition',
  lanes = 'lanes',
  address = 'address',
  region = 'region',
  road = 'road',
  provider = 'provider',
  status = 'status',
  measureStatus = 'measureStatus',
  serialNumber = 'serialNumber',
  lastTransitDatetime = 'lastTransitDatetime',
  uid = 'uid',
  verificationCertificate = 'verificationCertificate',
  effectiveDate = 'effectiveDate',
}

export enum PlatformMediaFieldName {
  type = 'type',
  file = 'file',
}

export type PlatformMediaType = 'schema' | 'overview';
export interface PlatformMedia {
  type: PlatformMediaType;
  name: string;
}

export type UpdatePlatformMedia = {
  uid: string;
  type: PlatformMediaType;
  file: string;
};
