import styled from 'styled-components/macro';
import { Col } from 'antd';
import palette from 'palette';

export const StyledWimContainer = styled.li`
  list-style: none;
  :not(:last-child):after {
    content: '';
    display: flex;
    height: 8px;
    margin: 20px 0px;
    border-top: 2px solid ${palette.grey.g6};
    border-bottom: 2px solid ${palette.grey.g6};
  }
`;

export const StyledWimsSm = styled(Col)`
  height: 150px;
  fontsize: 14px;
  color: ${palette.grey.g7};
`;
