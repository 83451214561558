import React, { FC } from 'react';

import { useAppSelector } from 'hooks/store-hooks';

import { statisticsSelectors } from 'store/statistics/selectors';
import { TimePeriod } from 'types/date-period';

import { createGaugeCard, GaugesStatistics } from '../helpers/create-gauge-card';
import { parseData } from '../helpers/data-parser';
import { GaugeStyled, StyledCardWrapper } from './styles';

interface GaugesProps {
  previousTimePeriod: TimePeriod;
}

const Gauges: FC<GaugesProps> = ({ previousTimePeriod }) => {
  const wims = useAppSelector(statisticsSelectors.wimsStatus.getWimsStatus);
  const transits = useAppSelector(statisticsSelectors.totalTransits.getTotalTraffic);
  const trucks = useAppSelector(statisticsSelectors.trucksTransits.getTrucksTransits);
  const overload = useAppSelector(statisticsSelectors.overloadStatistics.getOverload);

  return (
    <GaugeStyled>
      <StyledCardWrapper>
        {createGaugeCard({
          title: GaugesStatistics.wimsStatus,
          data: parseData(wims.data),
          isLoading: wims.isLoading,
        })}
      </StyledCardWrapper>
      <StyledCardWrapper>
        {createGaugeCard({
          title: GaugesStatistics.totalTransits,
          data: parseData(transits.data),
          isLoading: transits.isLoading,
          previousTimePeriod,
        })}
      </StyledCardWrapper>
      <StyledCardWrapper>
        {createGaugeCard({
          title: GaugesStatistics.trucksTransits,
          data: parseData(trucks.data),
          isLoading: trucks.isLoading,
          previousTimePeriod,
        })}
      </StyledCardWrapper>
      <StyledCardWrapper>
        {createGaugeCard({
          title: GaugesStatistics.overloadStatistics,
          data: parseData(overload.data),
          isLoading: overload.isLoading,
          previousTimePeriod,
        })}
      </StyledCardWrapper>
    </GaugeStyled>
  );
};

export default Gauges;
