import { VEHICLE_CLASSES } from 'app-constants/vehicle-classes';
import { WAGA_VEHICLE_TYPES } from 'app-constants/waga-vehicle-types';

export function getVehicleTypeDescriptionOld(vehicleTypeFromServer: string): string {
  if (+vehicleTypeFromServer || +vehicleTypeFromServer === 0) {
    const searchedVehicleType = Object.values(WAGA_VEHICLE_TYPES).find((vehicleType) =>
      vehicleType.vehicleClasses.find((vehicleClass) => vehicleClass === vehicleTypeFromServer.toString())
    );

    return searchedVehicleType?.description || vehicleTypeFromServer;
  }

  const searchedVehicleClass = VEHICLE_CLASSES.find((item) => item.name === vehicleTypeFromServer);
  const searchedVehicleType = Object.values(WAGA_VEHICLE_TYPES).find((vehicleType) =>
    vehicleType.vehicleClasses.find((vehicleClass) => vehicleClass === searchedVehicleClass?.id.toString())
  );
  return searchedVehicleType?.description || vehicleTypeFromServer;
}

export function getVehicleTypeDescription(vehicleTypeFromServer: number): string {
  if (vehicleTypeFromServer || vehicleTypeFromServer === 0) {
    const searchedVehicleType = Object.values(WAGA_VEHICLE_TYPES).find(
      (vehicleType) => vehicleType.id === vehicleTypeFromServer
    );

    return searchedVehicleType?.description || vehicleTypeFromServer.toString();
  }

  const searchedVehicleClass = VEHICLE_CLASSES.find((item) => item.name === vehicleTypeFromServer.toString());
  const searchedVehicleType = Object.values(WAGA_VEHICLE_TYPES).find((vehicleType) =>
    vehicleType.vehicleClasses.find((vehicleClass) => vehicleClass === searchedVehicleClass?.id.toString())
  );
  return searchedVehicleType?.description || vehicleTypeFromServer.toString();
}
