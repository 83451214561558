import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';

import { UserFormValues } from 'pages/AdminManagement/Users/types';
import { StyledError, StyledField } from './styles';

interface SocialNetworkContactCellProps {
  index: number;
  dataIndex: string;
}

export const FormikSocialNetworkContactCell: FC<SocialNetworkContactCellProps> = ({ index, dataIndex }) => {
  return (
    <>
      <StyledField name={`socialNetworks.${index}.${dataIndex}`} />
      <Field name={`socialNetworks.${index}.${dataIndex}`}>
        {({ meta }: FieldProps<UserFormValues>) => {
          const { error, touched } = meta;

          return touched && error ? <StyledError>{error}</StyledError> : null;
        }}
      </Field>
    </>
  );
};
