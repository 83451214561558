import { put, takeEvery } from 'redux-saga/effects';
import makeRequestSaga from 'store/common';
import { getPlatformsList } from 'api/statistics';
import { SagaPayload } from 'types';
import { GetStatisticQueryParamsType } from '../types';
import { requestPlatformsList, getPlatformsListSuccess, getPlatformsListError, setIsLoading } from './slice';

export function* platformsListRequestSaga({ payload }: SagaPayload<GetStatisticQueryParamsType>) {
  yield put(setIsLoading(true));
  yield makeRequestSaga(getPlatformsListSuccess, getPlatformsListError, getPlatformsList, payload);
}

export function* platformsListRequestWatchSaga() {
  yield takeEvery(requestPlatformsList, platformsListRequestSaga);
}
