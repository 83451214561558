import React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button } from 'antd';

import { stringifyUrlParams, useUrlParams } from 'hooks/use-url-params';
import { ROUTES } from 'app-constants/routes';
import { UNKNOWN_VRP } from 'pages/Violations/constants/violations';

interface VrpLinkProps {
  vrp: string;
  start?: number;
  end?: number;
}

const VrpLink: React.FC<VrpLinkProps> = ({ vrp, start, end }): JSX.Element => {
  const { t } = useTranslation();
  const { queryUrlParams } = useUrlParams({});
  const history = useHistory();
  const searchParams = {
    sort: {
      key: 'datetime',
      value: 'descend',
    },
    filter: {
      platformNames: [queryUrlParams.id],
      plate: vrp,
      start,
      end,
    },
  };

  const search = stringifyUrlParams(searchParams);

  return vrp === UNKNOWN_VRP ? (
    <span>{t('common.notFound')}</span>
  ) : (
    <Button
      type="link"
      onClick={() =>
        history.push({
          pathname: ROUTES.TransitsArchive,
          search,
          state: { fromPage: { pathname: ROUTES.Home } },
        })
      }
    >
      {vrp}
    </Button>
  );
};

export default VrpLink;
