import React from 'react';

import { TableContainerWithSorting } from 'components';
import { GetDetailTableColumns, ViolationsTableRow } from './table-schema';

interface TransitsTableProps {
  data: ViolationsTableRow[] | null;
  isLoading: boolean;
}

const DetailTable: React.FC<TransitsTableProps> = ({ data, isLoading = false }) => {
  const columns = Object.values(GetDetailTableColumns());

  if (!data) {
    return null;
  }

  return (
    <TableContainerWithSorting<ViolationsTableRow>
      columns={columns}
      scroll={{ y: 250 }}
      size="middle"
      dataSource={data}
      loading={isLoading}
    />
  );
};
export default DetailTable;
