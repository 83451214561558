import uniqid from 'uniqid';

export function setTableDataUid<T>(dataSource: T | undefined) {
  if (Array.isArray(dataSource) && dataSource) {
    return dataSource?.map((item) => {
      if (item.uid) {
        return item;
      }
      return { ...item, uid: uniqid() };
    });
  }
  return undefined;
}
