import { combineReducers } from '@reduxjs/toolkit';

import * as wimTtransitViolationActions from './violations/slice';
import wimTtransitViolationSlice from './violations/slice';
import * as wimTtransitSummaryActions from './summary/slice';
import wimTtransitSummarySlice from './summary/slice';

const wimTransitsSlices = combineReducers({
  violations: wimTtransitViolationSlice,
  summary: wimTtransitSummarySlice,
});

export const wimTransitsActions = {
  violations: { ...wimTtransitViolationActions },
  summary: { ...wimTtransitSummaryActions },
};

export default wimTransitsSlices;
