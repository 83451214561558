import { DatePeriodType } from 'app-constants/date';
import { LANGUAGES } from 'app-constants/languages';
import { Dayjs } from 'dayjs';
import { TimePeriod } from 'types/date-period';
import { MapPosition } from './platforms';

export interface AxleOld {
  axleIndex: number;
  interaxleSpace: number;
  overweightPercent: number;
  slope: number;
  totalWeight: number;
  totalWeightError: number;
  totalWeightErrorPercent: number;
  totalWeightWithError: number;
  weightLimit: number;
  totalWeightDiff?: number;
}

export interface Axle {
  tires: number;
  number: number;
  weight: number;
  speedLeft: number;
  tireWidth: number;
  wheelbase: number;
  speedRight: number;
  trackWidth: number;
  weightLeft: number;
  weightRight: number;
  axleGroupType: string;
}

export interface AxleWithDiff extends AxleOld {
  totalWeightDiff?: number;
}

export interface VendorError {
  type: string;
  limit: number;
  measured: number;
}

export interface VendorErrorOld {
  id: string;
  name: string;
}

export type OffenceType = 'weight' | 'axle' | 'size' | 'axlesGroup';

export interface WimTransitItemOld {
  id: string;
  framename: string;
  offenceTypes: OffenceType[];
  platformName: string;
  datetime: number;
  wim: string;
  wimName: string;
  vendorErrors: VendorErrorOld[];
  plate: string;
  plateRecognized: boolean;
  plateBack: string;
  plateBackRecognized: boolean;
  vehicleType: string;
  trailerPresence: boolean;
  weight: number;
  speed: number;
  direction: string;
  length: number;
  width: number;
  height: number;
  axlesCount: number;
  wheelBase: number;
  axles: AxleOld[];
}

export enum WimViolationFilterOptionType {
  OVERLOAD = 'OVERLOAD',
  OVERSPEED = 'OVERSPEED',
  OVERSIZE = 'OVERSIZE',
  OVERLENGTH = 'OVERLENGTH',
  OVERHEIGHT = 'OVERHEIGHT',
  OVERWIDTH = 'OVERWIDTH',
  OVERAXLELOAD = 'OVERAXLELOAD',
  OVERGROUPLOAD = 'OVERGROUPLOAD',
}

export enum WimViolationType {
  'OVERSPEED' = 'OVERSPEED',
  'OVERLOAD' = 'OVERLOAD',
  'OVERAXLELOAD' = 'OVERAXLELOAD',
  'OVERGROUPLOAD' = 'OVERGROUPLOAD',
  'OVERGROUPLOAD_DUAL' = 'OVERGROUPLOAD_DUAL',
  'OVERGROUPLOAD_TRIPLE' = 'OVERGROUPLOAD_TRIPLE',
  'OVERSIZE' = 'OVERSIZE',
  'OVERLENGTH' = 'OVERLENGTH',
  'OVERWIDTH' = 'OVERWIDTH',
  'OVERHEIGHT' = 'OVERHEIGHT',
}

export enum WimMeasurementFlagType {
  'OFF_SCALE' = 'OFF_SCALE',
  'NOT_WIM_LANE' = 'NOT_WIM_LANE',
  'ON_SCALE_MISSED' = 'ON_SCALE_MISSED',
  'SPEED_CHANGE' = 'SPEED_CHANGE',
  'WEIGHT_DIFF' = 'WEIGHT_DIFF',
  'PARTIAL_AXLE' = 'PARTIAL_AXLE',
  'UNEQUAL_AXLE' = 'UNEQUAL_AXLE',
  'TAILGATE' = 'TAILGATE',
  'WRONG_LANE' = 'WRONG_LANE',
  'RUNNING_SCALE' = 'RUNNING_SCALE',
  'CREDENTIAL' = 'CREDENTIAL',
  'DRIVER_ISSUE' = 'DRIVER_ISSUE',
  'HARDWARE_ISSUE' = 'HARDWARE_ISSUE',
  'INVALID_MEASUREMENT' = 'INVALID_MEASUREMENT',
  'INVALID_HEIGHT' = 'INVALID_HEIGHT',
  'INVALID_WIDTH' = 'INVALID_WIDTH',
  'INVALID_LENGTH' = 'INVALID_LENGTH',
  'INVALID_SPEED' = 'INVALID_SPEED',
  'INVALID_WEIGHT' = 'INVALID_WEIGHT',
  'INVALID_AXLES_COUNT' = 'INVALID_AXLES_COUNT',
  'INVALID_AXLE_LOAD' = 'INVALID_AXLE_LOAD',
  'INVALID_AXLE_1ST_LOAD' = 'INVALID_AXLE_1ST_LOAD',
  'INVALID_AXLE_RATIO_LOAD' = 'INVALID_AXLE_RATIO_LOAD',
  'INVALID_AXLE_1ST_DISTANCE' = 'INVALID_AXLE_1ST_DISTANCE',
  'INVALID_VRP_VALUE' = 'INVALID_VRP_VALUE',
  'LACK_OF_MEDIA' = 'LACK_OF_MEDIA',
}

export interface WimViolation {
  type: WimViolationType | '';
  limit: number;
  measured: number;
}

export interface WimMeasurementFlag {
  type: WimMeasurementFlagType | '';
  description: string;
}

export interface WimTransitItem {
  id: string;
  uid: string;
  lane: string;
  plate: string | null;
  plateRecognized: number;
  platformName: string;
  platformUid: string;
  datetime: number;
  framename: string;
  violations: WimViolation[];
  flags: WimMeasurementFlag[];
  plateBack: string | null;
  plateBackRecognized: number;
  weight: number;
  speed: number;
  direction: string;
  length: number;
  width: number;
  height: number;
  axlesCount: number;
  wheelBase: number;
  axles: Axle[];
  laneNumber: number;
  laneUid: string;
  vehicleClass: number;
  vehicleClassDescription: string;
  oversize: boolean;
  overaxleload: boolean;
  overgroupload: boolean;
  overload: boolean;
  originalPlate: string;
}

export type WimTransitItemWithMapPosition = WimTransitItem & { mapPosition: MapPosition };

export interface WimTransitAnalysisItemWithDiff extends WimTransitItemOld {
  axles: AxleWithDiff[];
  weightDiff?: number;
  heightDiff?: number;
  widthDiff?: number;
  lengthDiff?: number;
  axlesDiff?: number;
}
export interface WimTransitAnalysisItem extends WimTransitItemOld {
  children: WimTransitAnalysisItemWithDiff[];
}

export interface WimTransitAxle {
  axleGroupType: string;
  number: number;
  overweightPercent: number;
  speedLeft: number | null;
  speedRight: number | null;
  tireWidth: number | null;
  tires: number;
  totalWeightErrorPercent: number;
  totalWeightWithError: number;
  trackWidth: number | null;
  weight: number;
  weightError: number;
  weightLeft: number;
  weightLimit: number;
  weightRight: number;
  wheelbase: number;
  maxOverAxleLoad: number;
  overweight: boolean;
}

export interface WimTransitAxleGroup {
  overweight: boolean;
  type: string;
  axles: number[];
  totalWeightLimit: number;
  firstAxle: number;
  lastAxle: number;
  totalWeight: number;
  totalWeightError: number;
  totalWeightErrorPercent: number;
  totalWeightWithError: number;
  overweightPercent: number;
  maxOverAxleLoad: number;
}

export enum PhotoTypeOld {
  PlateFront = 'plateFront',
  PlateBack = 'plateBack',
  GeneralFront = 'generalFront',
  GeneralBack = 'generalBack',
  OverviewFront = 'overviewFront',
  OverviewBack = 'overviewBack',
  Collage = 'collage',
  Other = 'other',
}

export enum PhotoType {
  PLATE = 'PLATE',
  PLATER = 'PLATER',
  BODY = 'BODY',
  FRONT = 'FRONT',
  REAR = 'REAR',
  SIDE = 'SIDE',
  SIDEB = 'SIDEB',
}

export enum UnnecessaryPhotoType {
  THUMB = 'THUMB',
  VIDEO = 'VIDEO',
}

export interface WimTransitPhoto {
  datetime: number;
  filePath: string;
  type: PhotoType;
}

export interface WimTransitPhotos {
  VIDEO?: string;
  PLATE?: string;
  PLATER?: string;
  BODY?: string;
  THUMB?: string;
  FRONT?: string;
  REAR?: string;
  SIDE?: string;
  SIDEB?: string;
}

export interface WimSizeItem {
  value: number;
  limit: number;
  oversize: number;
  limitMaxAllowedExcess: number;
}
export interface WimTransitSizes {
  height: WimSizeItem;
  length: WimSizeItem;
  width: WimSizeItem;
  weight: WimSizeItem;
}

export interface WimTransit {
  id: string;
  uid: string;
  laneUid: string;
  platformUid: string;
  lane: string;
  flags: WimMeasurementFlag[];
  platformName: string;
  frameName: string;
  datetime: number;
  size: WimTransitSizes;
  weight: number;
  width: number;
  length: number;
  height: number;
  axlesCount: number;
  axles: WimTransitAxle[];
  plate: string;
  plateRecognized: number;
  plateBack: string;
  plateBackRecognized: number;
  wimName: string;
  speed: number;
  direction: string;
  vehicleClass: number;
  vehicleClassDescription: string;
  wheelBase: number;
  overweightPercent: number;
  weightError: number;
  weightErrorPercent: number;
  weightWithError: number;
  measurmentLimits: number | null;
  roadTemperature: number;
  countryPlate: string;
  axlesGroups: WimTransitAxleGroup[];
  violations: WimViolation[];
}

export interface SortTransitParams {
  id: number;
  platformName: number;
  datetime: number;
  lane: number;
  plate: number;
  plateRecognized: number;
  plateBack: number;
  weight: number;
  speed: number;
  direction: number;
  length: number;
  width: number;
  height: number;
  axlesCount: number;
}

export interface GetWimTransitsQueryParamsOld {
  plate?: string;
  plateRecognized?: boolean;
  plateBackRecognized?: boolean;
  start?: number;
  end?: number;
  format?: string;
  platformNames?: string[];
  vendorErrorsPresence?: boolean;
  offenceTypes?: string[];
  weightFrom?: number;
  weightTo?: number;
  lengthFrom?: number;
  lengthTo?: number;
  widthFrom?: number;
  widthTo?: number;
  heightFrom?: number;
  heightTo?: number;
  axleCountFrom?: number;
  axleCountTo?: number;
  trailerPresence?: boolean;
  id?: string;
  vehicleClasses?: string[];
  limit?: number;
  offset?: number;
  sort?: Partial<SortTransitParams>;
  lang?: LANGUAGES;
  withCoordinates?: boolean;
}
export interface GetWimTransitsQueryParams {
  plate?: string;
  plateRecognized?: boolean;
  plateRecognizedFrom?: number;
  plateRecognizedTo?: number;
  plateBack?: string;
  plateBackRecognized?: boolean;
  plateBackRecognizedFrom?: number;
  plateBackRecognizedTo?: number;
  start?: number;
  end?: number;
  format?: string;
  platformNames?: string[];
  platformUids?: string[];
  vendorErrorsPresence?: boolean;
  violationsTypes?: string[];
  violationsTypesByOr?: string[];
  weightFrom?: number;
  weightTo?: number;
  lengthFrom?: number;
  lengthTo?: number;
  widthFrom?: number;
  widthTo?: number;
  heightFrom?: number;
  heightTo?: number;
  axleCountFrom?: number;
  axleCountTo?: number;
  id?: string;
  uid?: string;
  vehicleClasses?: string[];
  limit?: number;
  offset?: number;
  sort?: Partial<SortTransitParams>;
  lang?: LANGUAGES;
  wimIds?: string[];
  withCoordinates?: boolean;
}

export type GetTransitWithCoordinatesByPlate = TimePeriod & { withCoordinates: true; plate: string };

export interface GetWimTransitQueryParams {
  lang?: LANGUAGES;
}
export interface GetWimTransitPayload extends GetWimTransitQueryParams {
  transitUid: string;
  ts: number;
}

export interface GetWimTransitPhotoQueryParam {
  type?: PhotoType;
}

export interface GetWimTransitPhoto {
  transitUid: string;
  photoType: PhotoType;
}

export interface GetWimTransitPhotos {
  transitUid: string;
}

export interface GetWimTransitPhotoPayload {
  photoType: PhotoType;
  value?: string;
}
export interface GetWimTransitPhotosPayload {
  photos: GetWimTransitPhotoPayload[];
}

export interface WimTransits {
  total: number;
  items: WimTransitItem[];
}

export type WimTransitsWithMapCoordinates = Omit<WimTransits, 'items'> & { items: WimTransitItemWithMapPosition[] };

export interface WimAnalysisTransits {
  total: number;
  items: WimTransitAnalysisItem[];
}

export interface TransitsFilterValues {
  id: string;
  plate: string;
  plateBack: string;
  vehicleClasses: string[];
  axleCountFrom: number;
  axleCountTo: number;
  lengthFrom: number;
  lengthTo: number;
  widthFrom: number;
  widthTo: number;
  heightFrom: number;
  heightTo: number;
  weightFrom: number;
  weightTo: number;
  start: number;
  end: number;
  plateRecognized: boolean;
  plateBackRecognized: boolean;
  violationsTypesByOr: string[];
  platformNames: string[];
  platformUids: string[];
  vendorErrorsPresence: boolean;
  period: DatePeriodType;
  datePeriod: DatePeriodType;
  wimIds: string[];
  ranges: [Dayjs, Dayjs];
}

export type TransitsArchiveFilterValues = TransitsFilterValues;

export type TransitsOnlineFilterValues = Partial<TransitsFilterValues>;

export type TransitsIntensityTrafficFilterValues = TransitsFilterValues;

export enum TransitsArchiveFilterKey {
  id = 'id',
  plate = 'plate',
  plateBack = 'plateBack',
  trailerPresence = 'trailerPresence',
  vehicleClasses = 'vehicleClasses',
  axleCountFrom = 'axleCountFrom',
  axleCountTo = 'axleCountTo',
  lengthFrom = 'lengthFrom',
  lengthTo = 'lengthTo',
  widthFrom = 'widthFrom',
  widthTo = 'widthTo',
  heightFrom = 'heightFrom',
  heightTo = 'heightTo',
  weightFrom = 'weightFrom',
  weightTo = 'weightTo',
  start = 'start',
  end = 'end',
  plateRecognized = 'plateRecognized',
  plateBackRecognized = 'plateBackRecognized',
  offenceTypes = 'offenceTypes',
  platformNames = 'platformNames',
  vendorErrorsPresence = 'vendorErrorsPresence',
  metricId = 'metricId',
  wimIds = 'wimIds',
}

export interface ComparativeAnalysisFilterValues {
  start: number;
  end: number;
  plate: string;
  weightFrom: number;
  weightTo: number;
  baseWim: string;
  intermediateWims: string[];
  endWim: string;
  isMoreThanBaseWeight: boolean;
}

export enum ComparativeAnalysisFilterKey {
  start = 'start',
  end = 'end',
  plate = 'plate',
  widthFrom = 'widthFrom',
  widthTo = 'widthTo',
  baseWim = 'baseWim',
  intermediateWims = 'intermediateWims',
  endWim = 'string',
  isMoreThanBaseWeight = 'boolean',
}

export interface IntensityTraffic {
  key: string;
  vehicleClass: string;
  name: string;
  totalTransits: number;
  totalViolations: number;
  overload: number;
  overaxleload: number;
  overgroupload: number;
  overdimension: number;
}

export enum IntensityTrafficKey {
  key = 'key',
  vehicleClass = 'vehicleClass',
  name = 'name',
  transitsCount = 'transitsCount',
  total = 'total',
  overload = 'weight',
  overaxleload = 'axleLoad',
  overgroupload = 'axlesLoad',
  overdimension = 'dimensions',
}

export type WimTransitDetails = {
  platformName: string;
  mapPosition: MapPosition;
  lanes: LanesTransit;
  totalTransits: number;
  averageSpeed: number;
  averageWeight: number;
  lastTransitDate: number;
  maxWeight: number;
  minWeight: number;
  totalViolations: number;
  transitUid: string;
};

export type LanesTransit = {
  [laneUid: string]: LaneTransit;
};

export type LaneTransit = {
  direction: string;
  lane: string;
  totalTransits: number;
  averageSpeed: number;
  averageWeight: number;
  lastTransitDate: number;
  maxWeight: number;
  minWeight: number;
  totalViolations: number;
  violations: TransitViolationsType[];
  transits: TransitCommonType[];
};

export type TransitViolationsType = {
  axle: number;
  both: number;
  full: number;
  date: number;
};

export type TransitCommonType = {
  date: number;
  total: number;
};
