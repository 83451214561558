import React from 'react';

import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { DatePeriodType } from 'app-constants/date';
import { StyledBarChart } from './styles';
import palette from '../../palette';
import { setDatePeriod } from './helpers/set-date-period';
import TooltipWithWimStatistics from './Tooltips/TooltipWithWimStatistics';

export interface WimMonitoringMetricBarChartProps {
  data: StatisticsDataType[];
  syncId: string;
  datePeriod?: DatePeriodType;
}

type StatisticsDataType = {
  date: number;
  metricValue: string;
};

export const WimMonitoringMetricBarChart: React.FC<WimMonitoringMetricBarChartProps> = ({
  data,
  syncId = '',
  datePeriod,
}) => {
  const sortedData = [...data].sort((a, b) => a.date - b.date);

  return (
    <StyledBarChart>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={250}
          height={150}
          data={sortedData}
          margin={{ top: 10, right: 10 }}
          syncId={syncId}
          barCategoryGap="10%"
        >
          <XAxis dataKey="date" minTickGap={12} tickFormatter={(value) => setDatePeriod(value, datePeriod)} />
          <YAxis axisLine={false} tickLine={false} />
          <Tooltip content={<TooltipWithWimStatistics />} />
          <Bar dataKey="metricValue" fill={palette.blue.b5} />
        </BarChart>
      </ResponsiveContainer>
    </StyledBarChart>
  );
};
