import React, { FC, useRef, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { toPng } from 'html-to-image';
import dayjs from 'dayjs';
import uuidv4 from 'uniqid';

import { convertMillimetersToMeters } from 'helpers/metric.convertor';

import Axle from './axle';

import {
  StyledFrontOverhang,
  StyledRearOverhang,
  StyledTotalHeight,
  StyledTotalLength,
  StyledValue,
  StyledVehicleFront,
  StyledVehicleSchema,
  StyledVehicleSchemaWrapper,
  StyledVehicleWeight,
  StyledWheelbase,
  StyledWrapper,
} from './styles';
import { separateByThousands } from '../../../../../helpers/number.formatter';

interface SchemaAutoProps {
  axles?: AxlesType[];
  width?: number;
  height?: number;
  length?: number;
  overWidth: boolean;
  overHeight: boolean;
  overLength: boolean;
  totalWeight?: number;
  overweightPercent?: number;
  setPathToSchemaImage?: (path: string | undefined) => void;
}

export type AxlesType = {
  weight: number;
  axleDistance: number;
  dualWheels: boolean;
  overload: boolean;
  group?: AxleGroupType;
};

export type AxleGroupType = {
  overload?: boolean;
  groupIndex?: number | null;
};

const VehicleSchema: FC<SchemaAutoProps> = ({
  axles = [],
  width = 0,
  height = 1510,
  length = 0,
  overWidth,
  overHeight,
  overLength,
  totalWeight = 0,
  overweightPercent = 0,
  setPathToSchemaImage,
}) => {
  const [lengthBlockWidth, setLengthBlockWidth] = useState<number>();
  const schemaBlockRef = useRef<HTMLDivElement>(null);
  const schema = useRef<HTMLDivElement>(null);

  const blockHeight = 120;
  const defaultAxleDistance = 4000;

  const { t } = useTranslation();
  useEffect(() => {
    if (schemaBlockRef) {
      setLengthBlockWidth(schemaBlockRef?.current?.clientWidth);
    }
  }, [schemaBlockRef]);

  useEffect(() => {
    if (schema.current === null) {
      return;
    }
    if (setPathToSchemaImage) {
      toPng(schema.current, { cacheBust: true })
        .then((dataUrl) => {
          setPathToSchemaImage(dataUrl);
        })
        .catch(() => setPathToSchemaImage(undefined));
    }
  }, [schema, setPathToSchemaImage]);

  let base = blockHeight * (defaultAxleDistance / (width || 2000));

  return (
    <StyledVehicleSchemaWrapper ref={schema}>
      <StyledVehicleFront>
        <div className="vehicle-dimensions">
          <div className="wrapper">
            <div className="left-wheel" />
            <div className="right-wheel" />
            <div className="body">
              <div className="screen" />
              <div className="lights">
                <div className="left-light" />
                <div className="right-light" />
              </div>
            </div>
            <StyledVehicleWeight overload={overweightPercent > 0}>
              {separateByThousands(totalWeight)} {t('units.weight.kilogram.shortName')}
            </StyledVehicleWeight>
            <StyledTotalLength width="105px" isViolation={overWidth} value={convertMillimetersToMeters(width)}>
              {convertMillimetersToMeters(width)} {t('units.length.metre.shortName')}
            </StyledTotalLength>
            <StyledTotalHeight isViolation={overHeight} value={convertMillimetersToMeters(height)}>
              {convertMillimetersToMeters(height)} {t('units.length.metre.shortName')}
            </StyledTotalHeight>
          </div>
        </div>
      </StyledVehicleFront>

      <StyledVehicleSchema>
        <StyledWrapper ref={schemaBlockRef}>
          <StyledFrontOverhang />
          {axles.reduce<JSX.Element[]>((accum, axle) => {
            const { axleDistance, dualWheels, weight, overload, group } = axle;
            if (axles[0] !== axle) {
              base = blockHeight * (axleDistance / (width || 2000));
              accum.push(
                <React.Fragment key={uuidv4()}>
                  <StyledWheelbase width={`${base}px`}>
                    <StyledValue value={convertMillimetersToMeters(axleDistance)}>
                      {convertMillimetersToMeters(axleDistance)} {t('units.length.metre.shortName')}
                    </StyledValue>
                  </StyledWheelbase>
                  <Axle totalWeight={weight} dualWheels={dualWheels} overload={overload} group={group} />
                </React.Fragment>
              );
            } else {
              accum.push(
                <Axle
                  totalWeight={weight}
                  dualWheels={dualWheels}
                  overload={overload}
                  group={group}
                  key={weight + dayjs().valueOf().toString()}
                />
              );
            }

            return accum;
          }, [])}
          {!axles.length && (
            <>
              <Axle totalWeight={0} />
              <StyledWheelbase width={`${base}px`}>
                <StyledValue value={convertMillimetersToMeters(0)}>
                  {convertMillimetersToMeters(0)} {t('units.length.metre.shortName')}
                </StyledValue>
              </StyledWheelbase>
              <Axle totalWeight={totalWeight || 0} />
            </>
          )}
          <StyledRearOverhang />
        </StyledWrapper>

        <StyledTotalLength
          width={`${lengthBlockWidth}px`}
          isViolation={overLength}
          left="0px"
          value={convertMillimetersToMeters(length)}
        >
          {convertMillimetersToMeters(length)} {t('units.length.metre.shortName')}
        </StyledTotalLength>
      </StyledVehicleSchema>
    </StyledVehicleSchemaWrapper>
  );
};

export default VehicleSchema;
