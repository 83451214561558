import palette from 'palette';
import styled from 'styled-components/macro';

export const StyledContainer = styled.div`
  margin: 24px;
  padding: 24px;
  background-color: ${palette.white.w1};
  font-size: 14px;
`;

export const StyledContent = styled.div`
  display: flex;
`;

export const StyledDetailsBlock = styled.div`
  display: flex;
  column-gap: 40px;
`;

export const StyledInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
`;
export const StyledCardWrapper = styled.div`
  flex: 0 0 50%;
`;
export const StyledFlexBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledGlossaryItem = styled.div`
  font-weight: 600;
`;

export const StyledDate = styled.span`
  font-weight: 600;
`;

export const SpinContainer = styled.div`
  margin: 60px 0;
  text-align: center;
  width: 100%;
`;

export const StyledSchemaImage = styled.img`
  width: 100%;
  height: 100%;
`;
