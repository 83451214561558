import { ExportOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';

import palette from 'palette';

export const StyledExtraButtons = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

export const ExportIcon = styled(ExportOutlined)`
  font-size: 20px;
  > svg {
    transition: color 150ms linear;
    color: currentColor;
    will-change: color;

    :hover {
      color: ${palette.blue.b1};
    }
  }
`;
