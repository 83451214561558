import { User } from 'store/user/types';

export type AuthQueryParams = {
  email: string;
  password: string;
};

export type LoginQueryParams = AuthQueryParams;

export type LogoutQueryParams = AuthQueryParams;

export enum JwtTokenType {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
}

export enum LoginErrorResponse {
  PASSWORD_LESS_6_CHARS = 'data.password should NOT be shorter than 6 characters',
  INCORRECT_EMAIL = 'data.email should match format "email"',
  INCORRECT_AUTH = "Email/password don't match",
  STATUS_MFA_ACTIVATION = 410,
  STATUS_MFA_CONFIRMATION = 411,
  STATUS_USER_INACTIVE = 409,
  PASSWORD_RESET = 426,
}

export type AccessToken = string & { readonly brand: unique symbol };
export type RefreshToken = string & { readonly brand: unique symbol };

export interface LoginResponse {
  user: User;
  accessToken: AccessToken;
  refreshToken: RefreshToken;
}

export interface LoginError {
  userMessage: string | null;
  serverError: string | null;
}

export interface GetJwtTokensRequest {
  refreshToken: RefreshToken;
}

export interface JwtTokensResponse {
  accessToken: AccessToken;
  refreshToken: RefreshToken;
}

export interface PostMfaConfirmationRequest {
  email: string;
  code: string;
  activation: boolean;
}

export interface PostPasswordResetRequest {
  email: string;
  password: string;
}

export interface TriggerPasswordResetRequest {
  email: string;
}
