import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import palette from 'palette';

export const StyledContainer = styled.div`
  background-color: rgba(245, 245, 245, 0.5);

  .ant-tabs-nav {
    background-color: ${palette.white.w1};
    height: 48px;
    margin: 0;
  }

  .ant-tabs-tab {
    min-width: 100px;
    justify-content: center;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-content-holder {
    background-color: rgb(255, 255, 255);
    margin: 24px;
    padding: 24px;
  }
`;

export const HintTooltip = styled.div`
  max-width: 500px;
  font-size: 14px;
  color: ${palette.black.b2};
  white-space: 'pre-line';
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;
