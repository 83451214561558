import { call, CallEffect, fork, put, PutEffect, SimpleEffect, takeEvery } from 'redux-saga/effects';

import {
  getWimPlatformOverviewImage,
  getWimPlatformSchemaImage,
  getWimStatisticDetails,
  getWimStatisticErrors,
} from 'api/wim';
import { SagaPayload } from 'types';
import makeRequestSaga from 'store/common';
import { AxiosResponse } from 'axios';
import {
  requestActiveWimPlatformErrors,
  getActiveWimPlatformDetailsSuccess,
  getActiveWimPlatformErrorsSuccess,
  getActiveWimPlatformDetailsError,
  getActiveWimPlatformErrorsError,
  requestActiveWimPlatform,
  clearWimPlatformSchemaImage,
  getWimPlatformSchemaImageSuccess,
  getWimPlatformSchemaImageError,
  requestWimPlatformSchemaImage,
  requestWimPlatformOverviewImage,
  clearWimPlatformOverviewImage,
  getWimPlatformOverviewImageSuccess,
  getWimPlatformOverviewImageError,
} from './slice';
import { GetActiveWimPlatformQuery } from '../types';
import { GetWimSchemaImage, GetWimSchemaImagePayload } from '../types/active-platform';

function* wimDetailsRequestSaga({ payload }: SagaPayload<GetActiveWimPlatformQuery>) {
  yield makeRequestSaga(
    getActiveWimPlatformDetailsSuccess,
    getActiveWimPlatformDetailsError,
    getWimStatisticDetails,
    payload
  );
}

function* wimErrorsRequestSaga({ payload }: SagaPayload<GetActiveWimPlatformQuery>) {
  yield makeRequestSaga(
    getActiveWimPlatformErrorsSuccess,
    getActiveWimPlatformErrorsError,
    getWimStatisticErrors,
    payload
  );
}

export function* wimPlatformSchemaImageRequestSaga({
  payload,
}: SagaPayload<GetWimSchemaImage>): Generator<
  | CallEffect<AxiosResponse>
  | PutEffect<{ payload: GetWimSchemaImagePayload | string; type: string }>
  | SimpleEffect<string>,
  void,
  AxiosResponse
> {
  try {
    yield put(clearWimPlatformSchemaImage());
    const response: AxiosResponse = yield call(getWimPlatformSchemaImage, {
      platformUid: payload.platformUid,
      params: {} as any,
    });

    if (response) {
      const image = `data:${response.headers['content-type'].toLowerCase()};base64,${Buffer.from(
        response.data,
        'utf8'
      ).toString('base64')}`;

      yield put(getWimPlatformSchemaImageSuccess({ value: image }));
    } else {
      yield put(getWimPlatformSchemaImageError(JSON.parse(response)));
    }
  } catch (error: any) {
    yield put(getWimPlatformSchemaImageError(error.message));
  }
}

export function* wimPlatformOverviewImageRequestSaga({
  payload,
}: SagaPayload<GetWimSchemaImage>): Generator<
  | CallEffect<AxiosResponse>
  | PutEffect<{ payload: GetWimSchemaImagePayload | string; type: string }>
  | SimpleEffect<string>,
  void,
  AxiosResponse
> {
  try {
    yield put(clearWimPlatformOverviewImage());
    const response: AxiosResponse = yield call(getWimPlatformOverviewImage, {
      platformUid: payload.platformUid,
      params: {} as any,
    });

    if (response) {
      const image = `data:${response.headers['content-type'].toLowerCase()};base64,${Buffer.from(
        response.data,
        'utf8'
      ).toString('base64')}`;

      yield put(getWimPlatformOverviewImageSuccess({ value: image }));
    } else {
      yield put(getWimPlatformOverviewImageError(JSON.parse(response)));
    }
  } catch (error: any) {
    yield put(getWimPlatformOverviewImageError(error.message));
  }
}

export function* activeWimPlatformRequestSaga({ type, payload }: SagaPayload<GetActiveWimPlatformQuery>) {
  yield fork(wimDetailsRequestSaga, { type, payload });
  yield fork(wimErrorsRequestSaga, { type, payload });
}

export function* activeWimPlatformRequestWatchSaga() {
  yield takeEvery(requestActiveWimPlatform, activeWimPlatformRequestSaga);
  yield takeEvery(requestActiveWimPlatformErrors, wimErrorsRequestSaga);
  yield takeEvery(requestWimPlatformSchemaImage, wimPlatformSchemaImageRequestSaga);
  yield takeEvery(requestWimPlatformOverviewImage, wimPlatformOverviewImageRequestSaga);
}
