import overWeight from 'assets/images/offences/over-weight.svg';
import overAxle from 'assets/images/offences/over-axle.svg';
import overAxlesgroup from 'assets/images/offences/over-axlesGroup.svg';
import overSize from 'assets/images/offences/over-size.svg';
import { WimViolationFilterOptionType, WimViolationType } from 'store/wim/types/transits';

import i18n from '../i18n';

const VIOLATIONS_TYPES = {
  OVERLOAD: {
    id: WimViolationFilterOptionType.OVERLOAD,
    name: i18n.t('violations.overload'),
    icon: overWeight,
  },
  OVERSPEED: {
    id: WimViolationFilterOptionType.OVERSPEED,
    name: i18n.t('violations.overspeed'),
    icon: '',
  },
  OVERSIZE: {
    id: WimViolationFilterOptionType.OVERSIZE,
    name: i18n.t('offences.oversize'),
    icon: overSize,
  },
  OVERLENGTH: {
    id: WimViolationFilterOptionType.OVERLENGTH,
    name: i18n.t('violations.overlength'),
    icon: overSize,
  },
  OVERHEIGHT: {
    id: WimViolationFilterOptionType.OVERHEIGHT,
    name: i18n.t('violations.overheight'),
    icon: overSize,
  },
  OVERWIDTH: {
    id: WimViolationFilterOptionType.OVERWIDTH,
    name: i18n.t('violations.overwidth'),
    icon: overSize,
  },
  OVERAXLELOAD: {
    id: WimViolationFilterOptionType.OVERAXLELOAD,
    name: i18n.t('violations.overaxleload'),
    icon: overAxle,
  },
  OVERGROUPLOAD: {
    id: WimViolationFilterOptionType.OVERGROUPLOAD,
    name: i18n.t('offences.overweightAxlesGroup'),
    icon: overAxlesgroup,
  },
};

const violationsTypeTitle: Record<WimViolationType, string> = {
  OVERSPEED: i18n.t('violations.overspeed'),
  OVERLOAD: i18n.t('violations.overload'),
  OVERAXLELOAD: i18n.t('violations.overaxleload'),
  OVERGROUPLOAD: i18n.t('violations.overgroupload_dual'),
  OVERGROUPLOAD_DUAL: i18n.t('violations.overgroupload_dual'),
  OVERGROUPLOAD_TRIPLE: i18n.t('violations.overgroupload_triple'),
  OVERSIZE: i18n.t('offences.oversize'),
  OVERLENGTH: i18n.t('violations.overlength'),
  OVERWIDTH: i18n.t('violations.overwidth'),
  OVERHEIGHT: i18n.t('violations.overheight'),
};

export { VIOLATIONS_TYPES, violationsTypeTitle };
