import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { createUser, getUserActivityLog, getUserActivityLogExport, getUsers, updateUser } from 'api/users';
import { ApiRequest, SagaPayload } from 'types';
import { CreateUser, UpdateUser, User, UserLogActivity, UserLogActivityQuery } from 'store/user/types';
import {
  requestUsers,
  getUsersSuccess,
  getUsersError,
  requestCreateUser,
  requestUpdateUser,
  getNewUserError,
  getUserLogActivitySuccess,
  getUserLogActivityError,
  getUserLogActivity,
  getUserLogActivityExport,
} from './slice';
import { normalizeUsersEmptyValues, normalizeUserEmptyValues } from '../normalization';
import adminSelectors from '../selectors';
import { setNotificationError, setNotificationSuccess } from '../notifications/slice';
import { MessageTitle } from '../notifications/types';

function* makeUserRequestSaga(
  failureAction: ActionCreatorWithPayload<string, string>,
  apiFn: ApiRequest,
  payload = {}
) {
  try {
    const response: AxiosResponse<User> = yield call(apiFn, payload);
    if (response) {
      const userWithNonNullValues: User = yield call(normalizeUserEmptyValues, response.data);
      return userWithNonNullValues;
    }
  } catch (error) {
    yield put(failureAction(error.message));
    yield put(setNotificationError({ title: MessageTitle.crudError, message: error.message }));
    throw error;
  }
  return null;
}

export function* usersRequestSaga() {
  try {
    const response: AxiosResponse<User[]> = yield call(getUsers, {});
    if (response) {
      const usersWithNonNullValues: User[] = yield call(normalizeUsersEmptyValues, response.data);
      yield put(getUsersSuccess(usersWithNonNullValues));
    }
  } catch (error) {
    yield put(getUsersError(error.message));
  }
}

export function* userActivityRequestSaga({ payload }: SagaPayload<UserLogActivityQuery>) {
  try {
    // yield put(clearUserLogActivity());
    const response: AxiosResponse<UserLogActivity[]> = yield call(getUserActivityLog(payload), {});
    if (response) {
      yield put(getUserLogActivitySuccess(response.data));
    }
  } catch (error) {
    yield put(getUserLogActivityError(error.message));
  }
}

export function* userActivityExportRequestSaga({ payload }: SagaPayload<UserLogActivityQuery>) {
  try {
    // yield put(clearUserLogActivity());
    const { fileName, ...data } = payload;
    const response: AxiosResponse = yield call(getUserActivityLogExport(data), {});
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${payload.fileName}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // yield put(getUserLogActivitySuccess(response.data));
    }
  } catch (error) {
    // yield put(getUserLogActivityError(error.message));
  }
}

export function* userUpdateRequestSaga({ payload }: SagaPayload<UpdateUser>) {
  const users: readonly User[] = yield select(adminSelectors.users.getUsers);

  const updatedUser: User | null | never = yield call(makeUserRequestSaga, getUsersError, updateUser(payload));

  if (updatedUser) {
    yield put(setNotificationSuccess({ title: MessageTitle.crudSuccess }));
    const newUsers = [...users].reduce<User[]>((acc, currentUser) => {
      if (currentUser.uid === updatedUser.uid) {
        return [...acc, updatedUser];
      }

      return [...acc, currentUser];
    }, []);

    yield put(getUsersSuccess(newUsers));
  }
}

export function* createUserRequestSaga({ payload }: SagaPayload<CreateUser>) {
  const users: readonly User[] = yield select(adminSelectors.users.getUsers);

  const addedUser: User | null | never = yield call(makeUserRequestSaga, getNewUserError, createUser(payload));

  if (addedUser) {
    yield put(setNotificationSuccess({ title: MessageTitle.crudError }));
    const newUsers = [...users].concat(addedUser);

    yield put(getUsersSuccess(newUsers));
  }
}

export function* usersRequestWatchSaga() {
  yield takeLatest(requestUsers, usersRequestSaga);
}

export function* createUserRequestWatchSaga() {
  yield takeLatest(requestCreateUser, createUserRequestSaga);
}

export function* userUpdateRequestWatchSaga() {
  yield takeLatest(requestUpdateUser, userUpdateRequestSaga);
}

export function* userActivityLogsWatchSaga() {
  yield takeLatest(getUserLogActivity, userActivityRequestSaga);
}

export function* userActivityLogsExportWatchSaga() {
  yield takeLatest(getUserLogActivityExport, userActivityExportRequestSaga);
}
