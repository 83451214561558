import styled from 'styled-components/macro';

import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import palette from 'palette';
import { Card } from 'antd';
import SFUITextMedium from 'fonts/SFUIText-Medium.woff';

export const StyledCheckCircleOutlined = styled(CheckCircleOutlined)`
  color: ${palette.green.g6};
`;

export const StyledExclamationCircleOutlined = styled(ExclamationCircleOutlined)`
  color: ${palette.orange.o1};
`;

export const StyledCard = styled(Card)`
  width: 100%;
  .ant-card-head-title {
    @font-face {
      font-family: 'SFUITextMedium';
      font-size: 14px;
      src: url(${SFUITextMedium}) format('woff');
    }
    font-family: 'SFUITextMedium';
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
  }
`;
