import { RootState } from 'store/root-reducer';
import { ActiveWimPlatformError, ActiveWimPlatformState } from '../types';

export const getActiveWimPlatformDetails = (state: RootState): ActiveWimPlatformState['wimDetails'] | null =>
  state.wim.activePlatform.activeWimPlatform.wimDetails;

export const getActiveWimPlatformErrors = (state: RootState): ActiveWimPlatformError | null =>
  state.wim.activePlatform.activeWimPlatform.wimErrors;

export const getWimSchemaImage = (state: RootState): string | undefined =>
  state.wim.activePlatform.wimPlatformSchemaImage;

export const getWimOverviewImage = (state: RootState): string | undefined =>
  state.wim.activePlatform.wimPlatformOverviewImage;
export const isWimSchemaLoading = (state: RootState): boolean => state.wim.activePlatform.isPlatformSchemaLoading;
