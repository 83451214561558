import { createGlobalStyle } from 'styled-components/macro';
import SFUITextRegular from './fonts/SFUIText-Regular.woff';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'SFUITextRegular';
    font-size: 14px;
    src: url(${SFUITextRegular}) format('woff');
  }

  body {
    font-family: 'SFUITextRegular';
    font-size: 14px;
  }
`;

export default GlobalStyle;
