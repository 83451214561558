import { combineReducers } from '@reduxjs/toolkit';

import * as overweightStatisticsActions from './overloadStatistics/slice';
import overweightStatisticsSlices from './overloadStatistics/slice';

import * as totalTransitsActions from './totalTransits/slice';
import totalTransitsSlice from './totalTransits/slice';

import * as trucksTransitsActions from './trucksTransits/slice';
import trucksTransitsSlice from './trucksTransits/slice';

import * as wimsStatusActions from './wimsStatus/slice';
import wimsStatusSlice from './wimsStatus/slice';

import * as transitsStatisticActions from './transitsStatistic/slice';
import transitsStatisticSlice from './transitsStatistic/slice';

import * as violationOverweightStatisticActions from './violationOverloadStatistic/slice';
import violationOverweightStatisticSlice from './violationOverloadStatistic/slice';

import * as vrpDetectStatisticActions from './vrpDetectStatistic/slice';
import vrpDetectStatisticSlice from './vrpDetectStatistic/slice';

import * as platformsListActions from './platformsList/slice';
import platformsListSlice from './platformsList/slice';

import * as intrudersListActions from './intrudersList/slice';
import intrudersListSlice from './intrudersList/slice';

import * as allStatisticsActions from './getAllStatistics/slice';
import allStatisticsSlice from './getAllStatistics/slice';

export const statisticsSlices = combineReducers({
  overload: overweightStatisticsSlices,
  totalTransits: totalTransitsSlice,
  trucksTransits: trucksTransitsSlice,
  wimsStatus: wimsStatusSlice,
  transitsStatistic: transitsStatisticSlice,
  violationOverloadStatistic: violationOverweightStatisticSlice,
  vrpDetectStatistic: vrpDetectStatisticSlice,
  platformsList: platformsListSlice,
  intrudersList: intrudersListSlice,
  allStatistics: allStatisticsSlice,
});

export const statisticsActions = {
  overweightStatisticsActions: { ...overweightStatisticsActions },
  totalTransitsActions: { ...totalTransitsActions },
  trucksTransitsActions: { ...trucksTransitsActions },
  wimsStatusActions: { ...wimsStatusActions },
  transitsStatisticActions: { ...transitsStatisticActions },
  violationOverweightStatisticActions: { ...violationOverweightStatisticActions },
  vrpDetectStatisticActions: { ...vrpDetectStatisticActions },
  platformsListActions: { ...platformsListActions },
  intrudersListActions: { ...intrudersListActions },
  allStatisticsActions: { ...allStatisticsActions },
};
