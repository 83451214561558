import { DatePeriodType, DATE_FORMAT } from 'app-constants/date';

export const getCurrentPeriod = (period?: DatePeriodType) => {
  switch (period) {
    case DatePeriodType.lastWeek:
      return DATE_FORMAT.DAY_WITH_MONTH;
    case DatePeriodType.lastMonth:
      return DATE_FORMAT.DAY_WITH_MONTH;
    case DatePeriodType.lastYear:
      return DATE_FORMAT.BY_MONTH;

    default:
      return DATE_FORMAT.RANGE_PICKER_TIME;
  }
};
