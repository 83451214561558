import { Collapse } from 'antd';
import styled from 'styled-components/macro';

import palette from 'palette';

export const StyledCollapse = styled(Collapse)`
  border: none;
  .ant-collapse-item {
    border: none;
  }
  .ant-collapse-header {
    background: ${palette.white.w1};
    font-family: 'SFUITextSemibold';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border: none;
    .ant-collapse-content {
      border: none;
    }
  }
`;

export const StyledWimItem = styled.div`
  margin: 0 0 0 5px;
`;
