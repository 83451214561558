import { useSelector } from 'react-redux';

import adminSelectors from 'store/admin/selectors';
import { Certificate } from 'store/admin/certificates/types';
import { useEffect } from 'react';
import { adminActions } from '../../../../store/admin/slices';
import { useAppDispatch } from '../../../../hooks/store-hooks';

function useWimCertificatesState() {
  const dispatch = useAppDispatch();
  const certificates = useSelector(adminSelectors.certificates.getCertificates);
  const isCertificatesLoading = useSelector(adminSelectors.certificates.isCertificatesLoading);

  const getCertificatesByWim = (wimUid: string): Certificate[] => {
    if (!certificates) {
      return [];
    }

    return certificates.filter((item) => item.wimUid === wimUid);
  };

  useEffect(() => {
    dispatch(adminActions.certificates.requestCertificates());
  }, []);

  return {
    getCertificatesByWim,
    isCertificatesLoading,
  };
}

export default useWimCertificatesState;
