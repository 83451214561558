import { Select } from 'antd';
import styled from 'styled-components/macro';

export const StyledSelect = styled(Select)`
  width: 100%;
`;

export const StyledSelectWithLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLabel = styled.span`
  width: 35%;
`;

export const StyledRadio = styled.div`
  width: 65%;
`;

export const StyledRadioLabel = styled.div`
  display: flex;
  gap: 10px;
`;
