import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, FieldProps, Formik } from 'formik';

import { useAppDispatch } from 'hooks/store-hooks';
import palette from 'palette';
import { userSelectors } from 'store/user/selectors';
import { authSelectors } from 'store/auth/selectors';
import { authActions } from 'store/auth/slice';
import { ConfirmationFormValues, ConfirmationFormValue } from '../types';
import { confirmValidationSchema } from '../validation-schema';
import { initialValues } from './initial-values';
import {
  StyledButton,
  StyledConfirmation,
  StyledConfirmationForm,
  StyledErrorMessage,
  StyledInputEmail,
  StyledQRCode,
} from '../styles';
import qr from '../../../assets/images/qr-code.webp';

interface LoginProps {
  isActivation: boolean;
  email: string;
  onSubmit: (submittedValues: ConfirmationFormValues) => void;
}

const Confirmation: React.FC<LoginProps> = ({ isActivation, email, onSubmit }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isUserLoading = useSelector(userSelectors.isUserLoading);
  const loginError = useSelector(authSelectors.getLoginError);
  const qrCodeBase64 = useSelector(authSelectors.qrCodeBase64);

  useEffect(() => {
    if (isActivation) {
      dispatch(authActions.requestQrActivation(email));
    }
  }, []);

  return (
    <StyledConfirmation>
      {qrCodeBase64 && (
        <>
          <h3>{t('loginForm.scanQrCode')}</h3>
          <StyledQRCode>
            <img src={qrCodeBase64} alt="QR Code" width={200} />
          </StyledQRCode>
        </>
      )}
      <Formik<ConfirmationFormValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={confirmValidationSchema}
      >
        <StyledConfirmationForm>
          <h3>{t('loginForm.pleaseSendCode')}</h3>
          <Field name={ConfirmationFormValue.code}>
            {({ field }: FieldProps) => (
              <StyledInputEmail
                {...field}
                placeholder={t('loginForm.code')}
                style={{ borderColor: loginError.userMessage ? palette.red.r3 : palette.grey.g5 }}
              />
            )}
          </Field>
          {loginError.userMessage && <StyledErrorMessage>{t(`loginForm.validationError`)}</StyledErrorMessage>}
          <StyledButton type="primary" htmlType="submit" loading={isUserLoading}>
            {t('loginForm.sendCode')}
          </StyledButton>
        </StyledConfirmationForm>
      </Formik>
    </StyledConfirmation>
  );
};

export default Confirmation;
