import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TotalTransitsType = {
  truckTrafficForCurrentPeriod: number;
  truckTrafficForPreviousPeriod: number;
  delta: number | null;
};

export interface TotalTransitsState {
  data: TotalTransitsType;
  isLoading: boolean;
  error: Error | null;
}

const initialState: TotalTransitsState = {
  data: {
    truckTrafficForCurrentPeriod: 0,
    truckTrafficForPreviousPeriod: 0,
    delta: 0,
  },
  isLoading: false,
  error: null,
};

const totalTransits = createSlice({
  name: 'totalTransits',
  initialState,
  reducers: {
    requestTotalTransits: (state, _payload): void => {
      state.isLoading = true;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>): void => {
      state.isLoading = payload;
    },
    getTotalTransitsSuccess: (state, { payload }): void => {
      state.isLoading = false;
      state.data = payload;
    },
    getTotalTransitsError: (state, { payload }): void => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { requestTotalTransits, getTotalTransitsSuccess, getTotalTransitsError, setIsLoading } =
  totalTransits.actions;

export default totalTransits.reducer;
