import { UserPermission, UserSocialNetwork, UserStatus } from 'store/user/types';
import { Group } from 'store/admin/groups/types';

export enum UsersListSort {
  default = 'default',
}

export enum UserFieldName {
  fullName = 'fullName',
  password = 'password',
  department = 'department',
  email = 'email',
  phone = 'phone',
  position = 'position',
  status = 'status',
  deviceGroups = 'deviceGroups',
  permissions = 'permissions',
  socialNetworks = 'socialNetworks',
  uid = 'uid',
  updateDate = 'updateDate',
  createDate = 'createDate',
  mfaEnabled = 'mfaEnabled',
  forcePasswordRefresh = 'forcePasswordRefresh',
}

export interface UserFormValues {
  password: string;
  fullName?: string;
  department?: string;
  email?: string;
  phone?: string;
  position?: string;
  status?: UserStatus;
  permissions?: UserPermission[];
  deviceGroups?: Group[];
  socialNetworks?: UserSocialNetwork[];
}

export type UpdateUserValues = UserFormValues;

export type CreateUserValues = Required<Pick<UserFormValues, 'email'>> & UserFormValues;

export type UsersFilterFormValues = {
  search: string;
};
