import styled from 'styled-components/macro';
import { red } from '@ant-design/colors';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import palette from 'palette';

interface WeightTextProps {
  isOverweight?: boolean;
}

export const WeightText = styled.span<WeightTextProps>`
  color: ${(props) => (props.isOverweight ? red.primary : 'black')};
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 150px 20px;
`;

export const OnlineText = styled.p`
  font-size: 14px;
`;

export const ExclamationIcon = styled(ExclamationCircleOutlined)`
  font-size: 22px;
  color: ${palette.orange.o1};
`;

export const CheckIcon = styled(CheckCircleOutlined)`
  font-size: 22px;
  color: ${palette.green.g2};
`;
