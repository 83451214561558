import { useSelector } from 'react-redux';
import systemSelectors from '../../../store/system/selectors';

const useNodesState = () => {
  const mediaNodes = useSelector(systemSelectors.mediaCluster.getMediaNodes);
  const isNodesLoading = useSelector(systemSelectors.mediaCluster.isLoading);

  return {
    mediaNodes,
    isNodesLoading,
    isNodesLoaded: mediaNodes && !!mediaNodes.nodes.length,
  };
};

export { useNodesState };
