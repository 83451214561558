import { ColumnType } from 'antd/lib/table';
import { CHARS } from 'app-constants/chars';
import dayjs from 'dayjs';
import i18n from 'i18n';

import { WimPlatform } from 'store/wim/types';
import { VerificationCertificate } from 'store/wim/types/platforms';
import { PlatformFieldName } from '../../types';

type PlatformInfoDataSource = {
  type: string;
  value: string | number;
};

type PlatformInfoColumn = {
  type: ColumnType<PlatformInfoDataSource>;
  value: ColumnType<PlatformInfoDataSource>;
};

export const platformInfoDataSource = (
  platform: Omit<
    WimPlatform,
    'mapPosition' | 'verificationCertificate' | 'measureValidity' | 'mediaValidity' | 'plateRecognition'
  > & {
    mapPosition: string;
    verificationCertificate: VerificationCertificate;
  }
): PlatformInfoDataSource[] =>
  Object.keys(platform).reduce<PlatformInfoDataSource[]>((acc, key) => {
    const columnValue = platform[key as keyof typeof platform];

    if (key === PlatformFieldName.uid || key === PlatformFieldName.lanes || key === PlatformFieldName.alias) {
      return acc;
    }

    if (key === PlatformFieldName.lastTransitDatetime) {
      return acc.concat({
        type: i18n.t('infoFields.lastUpdatedData'),
        value: columnValue ? dayjs(columnValue as number).format('DD.MM.YYYY / HH:mm:ss') : CHARS.mdash,
      });
    }

    if (key === PlatformFieldName.effectiveDate) {
      return acc.concat({
        type: i18n.t('infoFields.effectiveDate'),
        value: columnValue ? dayjs(columnValue as string).format('DD.MM.YYYY / HH:mm:ss') : CHARS.mdash,
      });
    }

    if (key === PlatformFieldName.mapPosition) {
      return acc.concat({
        type: i18n.t(`infoFields.coordinates`),
        value: columnValue as string,
      });
    }

    return acc.concat({
      type: i18n.t(`infoFields.${key}`),
      value: columnValue ? (columnValue as string | number) : CHARS.mdash,
    });
  }, []);

export const platformInfoColumns: PlatformInfoColumn = {
  type: {
    key: 'type',
    dataIndex: 'type',
  },
  value: {
    key: 'value',
    dataIndex: 'value',
  },
};
