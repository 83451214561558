import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import i18n from 'i18n';

import { SocialNetwork, User, UserSocialNetwork } from 'store/user/types';
import { capitalize } from 'helpers/string.formatter';
import { UserFieldName } from 'pages/Profile/types';
import { UserPermissionLocalesKey } from './types';

type UserInfoDataSource = {
  type: string;
  value: string | number;
};

type UserInfoColumn = {
  type: ColumnType<UserInfoDataSource>;
  value: ColumnType<UserInfoDataSource>;
};

type UserSocialNetworksColumn = {
  [key in keyof UserSocialNetwork]: ColumnType<UserSocialNetwork>;
};

export const userInfoDataSource = (
  user: User,
  getDeviceGroupsNames: (values: number[]) => string[]
): UserInfoDataSource[] =>
  Object.keys(user).reduce<UserInfoDataSource[]>((acc, key) => {
    let columnValue = user[key as keyof typeof user];

    if (key === UserFieldName.socialNetworks || key === UserFieldName.uid) {
      return acc;
    }

    if (key === UserFieldName.permissions) {
      columnValue =
        user.permissions?.length &&
        user.permissions
          .map((permission) => i18n.t(`user.permissions.${permission.slice(4) as UserPermissionLocalesKey}` as const))
          .join(', ');
    }

    if (key === UserFieldName.status) {
      columnValue = user.status && i18n.t(`user.status.${user.status}`).toString();
    }

    if (key === UserFieldName.updateDate || key === UserFieldName.createDate) {
      columnValue = dayjs(columnValue as number).format('DD.MM.YYYY / HH:mm:ss');
    }

    if (key === UserFieldName.deviceGroups) {
      columnValue = user.deviceGroups?.length ? getDeviceGroupsNames(user.deviceGroups).join(', ') : '-';
    }

    if (key === UserFieldName.mfaEnabled) {
      columnValue = user.mfaEnabled ? i18n.t(`group.status.yes`).toString() : i18n.t(`group.status.no`).toString();
    }

    if (key === UserFieldName.forcePasswordRefresh) {
      columnValue = user.forcePasswordRefresh
        ? i18n.t(`group.status.yes`).toString()
        : i18n.t(`group.status.no`).toString();
    }

    return acc.concat({
      type: i18n.t(`infoFields.${key}`),
      value: columnValue as string | number,
    });
  }, []);

export const userInfoColumns: UserInfoColumn = {
  type: {
    key: 'type',
    dataIndex: 'type',
  },
  value: {
    key: 'value',
    dataIndex: 'value',
  },
};

export const userSocialNetworksColumns: UserSocialNetworksColumn = {
  application: {
    title: i18n.t('user.socialNetworks.application'),
    key: 'application',
    dataIndex: 'application',
    render: (value: SocialNetwork) => capitalize(value),
  },
  contact: {
    title: i18n.t('user.socialNetworks.contact'),
    key: 'contact',
    dataIndex: 'contact',
  },
};
