import { put, takeEvery } from 'redux-saga/effects';
import makeRequestSaga from 'store/common';
import { getOverload } from 'api/statistics';
import { SagaPayload } from 'types';
import { requestOverweight, getOverweightSuccess, getOverweightError, setIsLoading } from './slice';
import { GetStatisticQueryParamsType } from '../types';

export function* overweightStatisticsRequestSaga({ payload }: SagaPayload<GetStatisticQueryParamsType>) {
  yield put(setIsLoading(true));
  yield makeRequestSaga(getOverweightSuccess, getOverweightError, getOverload, payload);
}

export function* overweightStatisticsRequestWatchSaga() {
  yield takeEvery(requestOverweight, overweightStatisticsRequestSaga);
}
