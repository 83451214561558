import palette from 'palette';
import styled from 'styled-components/macro';

export const StyledPopupContent = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledAddress = styled.div`
  line-height: 28px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding-bottom: 24px;
`;

export const StyledLastUpdated = styled.div`
  line-height: 24px;
  font-size: 16px;
  padding-bottom: 24px;
`;

export const StyledButtonsBlock = styled.div`
  display: flex;
  column-gap: 32px;
  line-height: 24px;
  font-size: 16px;
  padding-bottom: 24px;

  div {
    display: flex;
    align-items: center;

    > span:first-child {
      margin-right: 12px;
    }

    > a {
      span:first-child {
        margin-right: 12px;
      }
    }

    > span:last-child {
      padding-top: 2px;
    }
  }
`;

export const StyledInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 24px;
  font-size: 14px;
  row-gap: 4px;
`;

export const StyledSemiBoldValue = styled.span`
  font-weight: 600;
  font-size: 14px;
`;

export const StyledUpdateButton = styled.span`
  color: ${palette.blue.b3};
  cursor: pointer;
`;

export const StyledWimErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  .no-data {
    text-transform: lowercase;
    font-size: 8px;
    font-weight: 500;
    color: ${palette.grey.g1};
  }
`;

export const StyledWimDirection = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;
