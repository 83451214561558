import dayjs, { Dayjs, OpUnitType } from 'dayjs';

import { DATE_FORMAT } from 'app-constants/date';
import { DatePeriod, TimePeriod } from 'types/date-period';
import { DEFAULT_RANGE_DAYS } from 'app-constants/defaults';

type DateRange = { start: number; end: number };

type DateInterval = {
  [key in string]: number;
};

export const formatDateTo = (date: number | string, template: string): string => dayjs(date).format(template);

const getTotalDifference = (time: number | null) => {
  if (time) {
    let totalSeconds = dayjs().diff(time, 'seconds');
    const totalMinutes = Math.floor(totalSeconds / 60);
    totalSeconds -= totalMinutes * 60;

    return {
      minutes: totalMinutes,
      seconds: totalSeconds,
      lastTime: formatDateTo(time, DATE_FORMAT.TABLE_FORMAT),
    };
  }

  return {
    minutes: null,
    seconds: null,
    lastTime: null,
  };
};

const mapRange = (range: DateRange, interval: DateInterval) => {
  const [[unit, step]] = Object.entries(interval);
  let currentDate = dayjs(range.start);
  const datesRange = [];

  while (currentDate.isBefore(range.end) || currentDate.isSame(range.end)) {
    currentDate = currentDate.add(step, unit as OpUnitType);
    datesRange.push(currentDate);
  }
  return datesRange;
};

export const getDaysRange = (range: DateRange): Dayjs[] => mapRange(range, { days: 1 });

export const getUpdatedTime = (time: number | null) => getTotalDifference(time);

const getDifference = (time: number, interval: DateInterval) => {
  const [[step, unit]] = Object.entries(interval);

  return Math.abs(dayjs(time).diff(unit, step as OpUnitType));
};

export const timeDiff = (time: number, diffTime: number) => getDifference(time, { minutes: diffTime });

export const convertDatePeriodToTimePeriod = (datePeriod: DatePeriod): TimePeriod => {
  if (datePeriod === DatePeriod.LAST_HOUR) {
    return { start: dayjs().subtract(1, 'hour').valueOf(), end: dayjs().valueOf() };
  }

  if (datePeriod === DatePeriod.TODAY) {
    return { start: dayjs().startOf('day').valueOf(), end: dayjs().valueOf() };
  }

  if (datePeriod === DatePeriod.LAST_DAY) {
    return { start: dayjs().subtract(1, 'day').valueOf(), end: dayjs().valueOf() };
  }

  if (datePeriod === DatePeriod.LAST_WEEK) {
    return { start: dayjs().subtract(1, 'week').valueOf(), end: dayjs().valueOf() };
  }

  if (datePeriod === DatePeriod.LAST_14_DAYS) {
    return { start: dayjs().subtract(DEFAULT_RANGE_DAYS, 'days').valueOf(), end: dayjs().valueOf() };
  }

  if (datePeriod === DatePeriod.LAST_MONTH) {
    return { start: dayjs().subtract(1, 'month').valueOf(), end: dayjs().valueOf() };
  }

  if (datePeriod === DatePeriod.LAST_QUARTET) {
    return { start: dayjs().subtract(1, 'quarter').valueOf(), end: dayjs().valueOf() };
  }

  if (datePeriod === DatePeriod.LAST_YEAR) {
    return { start: dayjs().subtract(1, 'year').valueOf(), end: dayjs().valueOf() };
  }

  return { start: dayjs().valueOf(), end: dayjs().valueOf() };
};
