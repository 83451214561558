import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton, Typography, Tooltip } from 'antd';
import { WifiOutlined } from '@ant-design/icons';

import palette from 'palette';
import { getColorOfWimConnection } from 'helpers/icon.formatter';
import { NormalizedWimPlatform } from 'store/wim/normalization';
import { StyledListRow, StyledIconsBlock } from './styles';
import { HintTooltip } from '../../../../../../Transits/styles';

interface WimsListRowProps {
  item: NormalizedWimPlatform;
  selectedItem: NormalizedWimPlatform | null;
  isListLoaded: boolean | null;
  onItemClick: (item: NormalizedWimPlatform) => void;
}

export const Row: FC<WimsListRowProps> = ({ item, onItemClick, selectedItem, isListLoaded }) => {
  const { Text } = Typography;
  const { t } = useTranslation();

  return (
    <StyledListRow $isSelected={item.uid === selectedItem?.uid} onClick={() => onItemClick(item)}>
      <Skeleton loading={!isListLoaded} active>
        <Text strong>{item.wim}</Text>
        <Text>{item.address}</Text>
        <StyledIconsBlock>
          <Tooltip
            title={<HintTooltip>{t('map.mapModal.connectionValidityDetails', { period: 5 })}</HintTooltip>}
            color={palette.white.w1}
          >
            <WifiOutlined style={{ color: getColorOfWimConnection(item.lastTransitDatetime) }} />
          </Tooltip>
        </StyledIconsBlock>
      </Skeleton>
    </StyledListRow>
  );
};
