import { API_URLS } from 'app-constants/api';
import httpService from 'services/http.service';
import { ApiRequest } from 'types';

export const sendAuthForm: ApiRequest = (data) => httpService.post(API_URLS.login(), data);
export const getMfaQrCode: ApiRequest = (data) => httpService.post(API_URLS.mfaQrCode(), data);
export const postValidationCode: ApiRequest = (data) => httpService.post(API_URLS.mfaValidate(), data);

export const postPasswordReset: ApiRequest = (data) => httpService.put(API_URLS.passwordReset(), data);
export const triggerPasswordReset: ApiRequest = (data) => httpService.post(API_URLS.passwordReset(), data);

export const logout: ApiRequest = (refreshToken) => httpService.post(API_URLS.logout(), { refreshToken });
