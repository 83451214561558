import qs from 'qs';

import { API_URLS } from 'app-constants/api';
import httpService from 'services/http.service';
import {
  GetWimTransitsQueryParams,
  GetWimSummaryQueryParams,
  GetWimVehicleClassificationsQueryParams,
  GetWimTransitPayload,
} from 'store/wim/types';
import secondaryHttpService from 'services/secondary-http.service';
import { GetWimPlatformSummaryViolationsQueryParams } from 'store/wim/types/platforms';
import { ApiRequest, ApiRequestWithParam } from 'types';

export const getWimTransits: ApiRequest = (params: GetWimTransitsQueryParams) =>
  secondaryHttpService.get(`${API_URLS.getTransits()}`, params, (queryParams: any) => qs.stringify(queryParams));

export const getWimTransit =
  (params: GetWimTransitPayload): ApiRequest =>
  () =>
    secondaryHttpService.get(`/wim${API_URLS.getTransit(params.transitUid)}`, { lang: params.lang, ts: params.ts });

export const getWimPlatforms: ApiRequest = (params) =>
  secondaryHttpService.get(`${API_URLS.getWimPlatforms()}`, params);

export const getWimPlatformSummary: ApiRequest = (params: GetWimSummaryQueryParams) =>
  secondaryHttpService.get(`${API_URLS.getWimPlatforms()}/${params?.wimUid}${API_URLS.summary()}`, {
    start: params.start,
    end: params.end,
    type: params.type,
    lastTransit: params?.lastTransit,
  });

export const getWimSummary: ApiRequest = (params: GetWimPlatformSummaryViolationsQueryParams) =>
  secondaryHttpService.get(
    `${API_URLS.getWimPlatforms()}/${params?.wimUid}${API_URLS.getWimSummary(params?.laneUid)}`,
    {
      date: params.date,
    }
  );

export const getWimPlatformsSummary: ApiRequest = (params) =>
  secondaryHttpService.get(`${API_URLS.getWimPlatforms()}${API_URLS.summary()}`, params);

export const getWimViolations: ApiRequest = (params: GetWimPlatformSummaryViolationsQueryParams) =>
  secondaryHttpService.get(`${API_URLS.getWimPlatforms()}/${params?.wimUid}${API_URLS.violations()}`, {
    date: params?.date,
  });

export const getWimVehicleClassifications: ApiRequest = (params: GetWimVehicleClassificationsQueryParams) =>
  httpService.get(`${API_URLS.getWimVehicleClassifications()}`, params);

export const getWimMonitoringMetrics: ApiRequest = () => secondaryHttpService.get(`${API_URLS.wimMetrics()}`, {});

export const getTransitsExportDocument: ApiRequestWithParam<string> = (url: string) => {
  return secondaryHttpService.get(url, {});
};

export const getExportDocumentStatus: ApiRequestWithParam<{ key: string }> = (params) => {
  return secondaryHttpService.get(`${API_URLS.getExportStatus()}`, params);
};

export const getExportDocumentCompletedFile: ApiRequestWithParam<{ key: string; fileName: string }> = (params) => {
  return secondaryHttpService.getDocumentInBlob(`${API_URLS.getExportFile()}`, params);
};

export const deleteExportDocumentCancel: ApiRequestWithParam<{ key: string }> = (params) => {
  return secondaryHttpService.get(`${API_URLS.deleteExportCancel()}`, params);
};

export const getTransitPhotoInBase64: ApiRequestWithParam<{
  transitUid: string;
  params: Request;
}> = ({ transitUid, params }) => {
  return secondaryHttpService.getDocumentInBase64(`${API_URLS.getWimTransitPhoto(transitUid)}`, { ...params });
};

export const getWimStatisticDetails: ApiRequest = (params) => {
  return secondaryHttpService.get(`${API_URLS.getWimStatistics('/wim-details')}`, params);
};

export const getWimStatisticErrors: ApiRequest = (params) => {
  return secondaryHttpService.get(`${API_URLS.getWimStatistics('/wim-error-reasons')}`, params, (queryParams: any) =>
    qs.stringify(queryParams)
  );
};

export const getWimPlatformSchemaImage: ApiRequestWithParam<{
  platformUid: string;
  params: Request;
}> = ({ platformUid, params }) => {
  return secondaryHttpService.getDocumentInBase64(`${API_URLS.getWimPlatformSchemaImage(platformUid)}`, { ...params });
};

export const getWimPlatformOverviewImage: ApiRequestWithParam<{
  platformUid: string;
  params: Request;
}> = ({ platformUid, params }) => {
  return secondaryHttpService.getDocumentInBase64(`${API_URLS.getWimPlatformOverviewImage(platformUid)}`, {
    ...params,
  });
};
