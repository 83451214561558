import React, { useCallback, useState } from 'react';
import { LayersControl, MapContainer, Marker, TileLayer, Tooltip, ZoomControl } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import L from 'leaflet';

import 'leaflet/dist/leaflet.css';
import { PlatformType } from 'store/platforms/types';
import { Legend } from 'components';
import { AlertIcon, DangerIcon, NormalIcon } from 'components/LeafletIcons';
import { DEFAULT_MAP_COORDINATES } from 'app-constants/defaults';
import { NormalizedWimPlatforms, NormalizedWimPlatform } from 'store/wim/normalization';
import { StyledMapWrapper } from './styles';

export interface MapProps {
  platforms: NormalizedWimPlatforms; // TODO when detectors will be added to API, they are absent now
  platformType: PlatformType;
  onMarkerClick: (data: NormalizedWimPlatform, queryParam: string) => void; // TODO when detectors will be added to API, they are absent now
}

const Map: React.FC<MapProps> = (props) => {
  const { t } = useTranslation();
  const [wimUidTooltip, setWimUidTooltip] = useState('');
  const [isPermanentTooltips, setIsPermanentTooltips] = useState(true);
  const [filterStatus, setFilterStatus] = useState('');
  const { platforms, platformType, onMarkerClick } = props;

  const handleShowWimsInfo = () => setIsPermanentTooltips((prev) => !prev);

  const handleFilterByWimStatus = (status: string) => {
    if (status === filterStatus) {
      setFilterStatus((prev) => '');
    } else {
      setFilterStatus((prev) => status);
    }
  };

  const renderLegend = useCallback(
    () => (
      <Legend platformType={props.platformType} onChange={handleShowWimsInfo} onStatusClick={handleFilterByWimStatus} />
    ),
    [handleFilterByWimStatus, props.platformType]
  );

  const renderMarkers = () =>
    Object.keys(platforms.byUid)
      .filter((uid) => {
        const { lastTransitDatetime, measureValidity, plateRecognition, mapPosition, wim } = platforms.byUid[uid];
        let status = '';
        if (lastTransitDatetime) {
          if (plateRecognition && plateRecognition < 0.9) {
            status = 'warn';
          } else if (filterStatus === 'online') {
            status = 'online';
          }
        } else {
          status = 'offline';
        }
        return filterStatus === '' || status === filterStatus;
      })
      .map((uid) => {
        const { lastTransitDatetime, measureValidity, plateRecognition, mapPosition, wim } = platforms.byUid[uid];
        let statusIcon;
        if (lastTransitDatetime) {
          if (plateRecognition && plateRecognition < 0.9) {
            statusIcon = AlertIcon;
          } else {
            statusIcon = NormalIcon;
          }
        } else {
          statusIcon = DangerIcon;
        }
        return (
          <Marker
            key={`platform-${platformType}-${uid}`}
            icon={statusIcon}
            eventHandlers={{
              click: () => onMarkerClick(platforms.byUid[uid], 'uid'),
              mouseover: () => setWimUidTooltip(uid),
              mouseout: () => setWimUidTooltip(''),
            }}
            position={mapPosition.coordinates}
          >
            {(wimUidTooltip === uid || isPermanentTooltips) && (
              <Tooltip direction="top" offset={L.point(14, 0)} permanent>
                {wim}
              </Tooltip>
            )}
          </Marker>
        );
      });

  return (
    <StyledMapWrapper>
      <>{renderLegend()}</>
      <MapContainer
        style={{ height: 832 }}
        center={DEFAULT_MAP_COORDINATES}
        zoom={7}
        zoomControl={false}
        scrollWheelZoom={false}
      >
        <ZoomControl position="topleft" />
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name={`OSM ${t('map.mapLayer.transport')}`}>
            <TileLayer
              attribution={`&copy;
              <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy;
                <a href="https://carto.com/attributions">CARTO</a>`}
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name={`OSM ${t('map.mapLayer.default')}`}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name={`ArcGis ${t('map.mapLayer.transport')}`}>
            <TileLayer
              attribution='Tiles &copy; <a href="https://www.arcgis.com/">ArcGis</a>'
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </LayersControl.BaseLayer>
          {/* <LayersControl.BaseLayer name={t('map.mapLayer.administrative')}>
            <TileLayer
              attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>'
              url="http://gisfile.com/map/ukraine/{z}/{x}/{y}.png"
              // url="http://gisfile.com/layer/cadmap/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer> */}
        </LayersControl>
        {renderMarkers()}
      </MapContainer>
      {props.children}
    </StyledMapWrapper>
  );
};

export default Map;
