import React, { FC } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { SelectValue } from 'antd/lib/select';

import { User, UserPermission } from 'store/user/types';
import { StyledSelect, StyledSelectWithLabel, StyledLabel } from './styles';
import { UserPermissionLocalesKey } from './types';

interface SelectUserPermissionsProps {
  name: string;
  onChange: (value: SelectValue) => void;
  value?: User['permissions'];
}

const { Option } = Select;

export const FormikSelectUserPermissions: FC<SelectUserPermissionsProps> = ({ name, value, onChange }) => {
  const { t } = useTranslation();

  return (
    <StyledSelectWithLabel>
      <StyledLabel>{t('infoFields.permissions')}</StyledLabel>
      <Field
        as={StyledSelect}
        name={name}
        style={{ width: '100%' }}
        mode="multiple"
        defaultValue={value}
        onChange={onChange}
      >
        {Object.keys(UserPermission).map((permission) => (
          <Option key={`user-permission-${permission}`} value={`wim:${permission.toLowerCase()}`}>
            {t(`user.permissions.${permission.toLowerCase() as UserPermissionLocalesKey}` as const)}
          </Option>
        ))}
      </Field>
    </StyledSelectWithLabel>
  );
};
