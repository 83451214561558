import { createSlice } from '@reduxjs/toolkit';
import { SagaPayload } from 'types';

interface AllStatisticsState {
  isLoading: boolean;
  error: Error | null;
  entity: SagaPayload | null;
}

const initialState: AllStatisticsState = {
  isLoading: false,
  error: null,
  entity: null,
};

const allStatistics = createSlice({
  name: 'allStatistics',
  initialState,
  reducers: {
    requestAllStatistics: (state, _payload): void => {
      state.isLoading = true;
    },
    getAllStatisticsSuccess: (state, { payload }): void => {
      state.isLoading = false;
      state.entity = payload;
    },
    getAllStatisticsError: (state, { payload }): void => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { requestAllStatistics, getAllStatisticsSuccess, getAllStatisticsError } = allStatistics.actions;

export default allStatistics.reducer;
