import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  WimPlatform,
  GetWimSummaryQueryParams,
  WimPlatformSummary,
  WimPlatformWithDeepSummary,
  GetWimPlatformsSummaryQueryParams,
} from '../types';

type InitialState = {
  isPlatformsLoading: boolean;
  isSummaryLoading: boolean;
  isPlatformsSummaryLoading: boolean;
  platforms: WimPlatform[] | null;
  platformSummary: WimPlatformSummary[] | null;
  platformsSummary: WimPlatformWithDeepSummary[] | null;
  error: string | null;
};

const initialState: InitialState = {
  isPlatformsLoading: false,
  isSummaryLoading: false,
  isPlatformsSummaryLoading: false,
  platforms: null,
  platformSummary: null,
  platformsSummary: null,
  error: null,
};

const platforms = createSlice({
  name: 'platforms',
  initialState,
  reducers: {
    requestPlatforms: (state): void => {
      state.isPlatformsLoading = true;
    },
    requestPlatformSummary: (state, { payload }: PayloadAction<GetWimSummaryQueryParams>): void => {
      state.isSummaryLoading = true;
    },
    requestPlatformsSummary: (state, { payload }: PayloadAction<GetWimPlatformsSummaryQueryParams>): void => {
      state.isPlatformsSummaryLoading = true;
    },
    getPlatformsSuccess: {
      reducer(state, { payload }: PayloadAction<WimPlatform[]>): void {
        state.isPlatformsLoading = false;
        state.platforms = payload;
      },
      prepare(payload: WimPlatform[]) {
        const updatedPayload = payload.map((platform) => {
          if (platform.mapPosition.coordinates) {
            platform.mapPosition.coordinates.reverse();
          }

          return platform;
        });

        return { payload: updatedPayload };
      },
    },
    getPlatformSummarySuccess: (state, { payload }: PayloadAction<WimPlatformSummary[]>) => {
      state.isSummaryLoading = false;
      state.platformSummary = payload;
    },
    getPlatformsSummarySuccess: (state, { payload }: PayloadAction<WimPlatformWithDeepSummary[]>) => {
      state.isPlatformsSummaryLoading = false;
      state.platformsSummary = payload;
    },
    clearPlatformSummary: (state): void => {
      state.platformSummary = null;
    },
    getPlatformsError: (state, { payload }: PayloadAction<string>): void => {
      state.isPlatformsLoading = false;
      state.isSummaryLoading = false;
      state.isPlatformsSummaryLoading = false;
      state.error = payload;
    },
  },
});

export const {
  requestPlatforms,
  requestPlatformSummary,
  requestPlatformsSummary,
  getPlatformsSuccess,
  getPlatformSummarySuccess,
  getPlatformsSummarySuccess,
  clearPlatformSummary,
  getPlatformsError,
} = platforms.actions;

export default platforms.reducer;
