import React, { FC } from 'react';
import { Skeleton, Typography } from 'antd';

import { User } from 'store/user/types';
import { StyledListRow, StyledIconsBlock } from './styles';

interface UsersListRowProps {
  item: User;
  selectedItem: User | null;
  isListLoaded: boolean | null;
  onItemClick: (item: User) => void;
}

const Row: FC<UsersListRowProps> = ({ item, onItemClick, selectedItem, isListLoaded }) => {
  const { Text } = Typography;

  return (
    <StyledListRow $isSelected={item.uid === selectedItem?.uid} onClick={() => onItemClick(item)}>
      <Skeleton loading={!isListLoaded} active>
        <Text strong>{item.fullName}</Text>
        <Text>{item.email}</Text>
        <StyledIconsBlock>
          <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" />
          </svg>
        </StyledIconsBlock>
      </Skeleton>
    </StyledListRow>
  );
};

export default Row;
