import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';

import { StyledError, StyledField } from './styles';

interface FormikWimSCertificateCellProps {
  index: number;
  dataIndex: string;
  value: string;
  readOnly?: boolean;
}

export const FormikWimCertificateCell: FC<FormikWimSCertificateCellProps> = ({ index, dataIndex, value, readOnly }) => {
  return (
    <>
      <StyledField name={`certificates.${index}.${dataIndex}`} defaultValue={value} readOnly={readOnly} />
      <Field name={`certificates.${index}.${dataIndex}`}>
        {({ meta }: FieldProps) => {
          const { error, touched } = meta;

          return touched && error ? <StyledError>{error}</StyledError> : null;
        }}
      </Field>
    </>
  );
};
