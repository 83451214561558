import palette from 'palette';
import styled from 'styled-components/macro';

export const StyledLinks = styled.div`
  display: flex;
  column-gap: 32px;
  margin-bottom: 32px;
  min-height: 20px;

  a {
    color: ${palette.blue.b1};
    font-size: 16px;
    letter-spacing: 0;
    line-height: '24px';
  }
`;
