import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { URLSearchParams } from 'url';

import { PlatformsListType } from '../platformsList/slice';

export type IntrudersListType = PlatformsListType;

interface IntrudersListState {
  data: IntrudersListType[];
  isLoading: boolean;
  error: Error | null;
}

const initialState: IntrudersListState = {
  data: [],
  isLoading: false,
  error: null,
};

const intrudersList = createSlice({
  name: 'intrudersList',
  initialState,
  reducers: {
    requestIntrudersList: (state, _payload): void => {
      state.isLoading = true;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>): void => {
      state.isLoading = payload;
    },
    getIntrudersListSuccess: (state, { payload }): void => {
      state.isLoading = false;
      state.data = payload;
    },
    getIntrudersListError: (state, { payload }): void => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { requestIntrudersList, getIntrudersListSuccess, getIntrudersListError, setIsLoading } =
  intrudersList.actions;

export default intrudersList.reducer;
