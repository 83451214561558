import { all, fork } from 'redux-saga/effects';

import { intrudersListRequestWatchSaga } from './intrudersList/saga';
import { totalTransitsRequestWatchSaga } from './totalTransits/saga';
import { overweightStatisticsRequestWatchSaga } from './overloadStatistics/saga';
import { transitsStatisticRequestWatchSaga } from './transitsStatistic/saga';
import { violationOverwightStatisticRequestWatchSaga } from './violationOverloadStatistic/saga';
import { vrpDetectStatisticRequestWatchSaga } from './vrpDetectStatistic/saga';
import { wimsStatusRequestWatchSaga } from './wimsStatus/saga';
import { trucksTransitsRequestWatchSaga } from './trucksTransits/saga';
import { platformsListRequestWatchSaga } from './platformsList/saga';
import { allStatisticsWatchSaga } from './getAllStatistics/saga';

export default function* statisticsSaga() {
  yield all([
    fork(wimsStatusRequestWatchSaga),
    fork(totalTransitsRequestWatchSaga),
    fork(trucksTransitsRequestWatchSaga),
    fork(overweightStatisticsRequestWatchSaga),
    fork(transitsStatisticRequestWatchSaga),
    fork(violationOverwightStatisticRequestWatchSaga),
    fork(vrpDetectStatisticRequestWatchSaga),
    fork(intrudersListRequestWatchSaga),
    fork(platformsListRequestWatchSaga),
    fork(allStatisticsWatchSaga),
  ]);
}
