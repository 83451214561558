import { useEffect, useState } from 'react';

import { NormalizedWimPlatform, NormalizedWimPlatforms } from 'store/wim/normalization';
import { wimListSorters } from '../Grid/sorting-schema';
import { WimsListSort } from '../Grid/types';

export type QueryUrl = {
  defaultQuery: string | undefined;
  onUpdateUrl: (value: string) => void;
};

type UpdateStateHandler = (value: React.SetStateAction<WimsListPageState>) => void;

interface WimsListPageState {
  mapPlatforms: NormalizedWimPlatform[] | null;
  selectedPlatform: NormalizedWimPlatform | null;
}

function getSelectedWim(
  platforms: NormalizedWimPlatform[] | null,
  firstPlatform: NormalizedWimPlatform | null,
  previouslySelected: NormalizedWimPlatform | null,
  queryWimId?: string
): NormalizedWimPlatform | null {
  if (queryWimId && platforms) {
    return platforms.find((platform) => platform.uid === queryWimId) || null;
  }
  if (!previouslySelected && firstPlatform) {
    return firstPlatform;
  }
  return previouslySelected;
}

const useWimsGridState = (platforms: NormalizedWimPlatforms | null, queryUrlParams: Record<string, any>) => {
  const [wimsGridPageState, setWimsGridPageState] = useState<WimsListPageState>({
    mapPlatforms: null,
    selectedPlatform: null,
  });

  useEffect(() => {
    const mapPlatforms = platforms ? Object.keys(platforms.byUid).map((key) => platforms.byUid[key]) : null;

    const defaultSortedPlatforms = mapPlatforms
      ? mapPlatforms.sort(wimListSorters[queryUrlParams.sort as WimsListSort])
      : null;

    const firstPlatform = defaultSortedPlatforms ? defaultSortedPlatforms[0] : null;
    setWimsGridPageState((prevState) => ({
      mapPlatforms: defaultSortedPlatforms,
      selectedPlatform: getSelectedWim(
        defaultSortedPlatforms,
        firstPlatform,
        prevState.selectedPlatform,
        queryUrlParams.uid
      ),
    }));
  }, [platforms]);

  const handleUpdateWimsListPageState: UpdateStateHandler = (newState) => {
    setWimsGridPageState(newState);
  };

  return {
    wimsGridPageState,
    handleUpdateWimsListPageState,
  };
};

export { useWimsGridState };
