import React from 'react';

import { CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { DatePeriodType } from 'app-constants/date';

import { getIntegerPercent } from 'helpers/percent.formatter';
import { parseByPeriodStatisticData } from './helpers/get-data-by-period';
import { setDatePeriod } from './helpers/set-date-period';
import { getBars } from './helpers/get-bars';
import TooltipWithWimStatistics from './Tooltips/TooltipWithWimStatistics';
import { StyledBarChart } from './styles';

export interface PlatformStatisticsBarCharProps {
  data: StatisticDataType[];
  syncId: string;
  unit?: string;
  lineKey?: string;
  datePeriod?: DatePeriodType;
  start: number;
  end: number;
}

export type StatisticDataType = {
  date: number;
  totalTraffic?: number;
  weight?: number;
  overload?: number;
  dimensions?: number;
  detect?: number;
  totalWeight?: number;
  overaxleload?: number;
  overgroupload?: number;
  truckTraffic?: number;
  datePeriod?: DatePeriodType;
  count?: number;
  trucks?: number;
  metricValue?: string;
};

export const PlatformStatisticsBarChar: React.FC<PlatformStatisticsBarCharProps> = ({
  data,
  syncId = '',
  unit,
  lineKey,
  datePeriod,
  start,
  end,
}) => {
  const { t } = useTranslation();
  const sortedData = [...data].sort((a, b) => a.date - b.date);

  const bars = sortedData?.length > 0 ? getBars({ keys: Object.keys(sortedData[0]), lineKey }) : [];

  if (bars?.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('common.noData')} />;
  }

  const percentFormatter = (val: number) => {
    if (unit === '%') {
      return getIntegerPercent(val).toString();
    }
    return val.toString();
  };

  return (
    <StyledBarChart>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={250}
          height={150}
          data={parseByPeriodStatisticData(sortedData, start, end, datePeriod)}
          margin={{ top: 10, right: 10 }}
          syncId={syncId}
          barCategoryGap="10%"
        >
          <CartesianGrid strokeDasharray="1" vertical={false} />
          <XAxis dataKey="date" minTickGap={12} tickFormatter={(value) => setDatePeriod(value, datePeriod)} />
          <YAxis unit={unit} axisLine={false} tickLine={false} tickFormatter={percentFormatter} />
          <Tooltip content={<TooltipWithWimStatistics unit={unit} />} formatter={percentFormatter} />
          {bars}
        </ComposedChart>
      </ResponsiveContainer>
    </StyledBarChart>
  );
};
