import { SortOrder } from 'antd/lib/table/interface';

import { VEHICLE_CLASSES } from 'app-constants/vehicle-classes';
import { SortingKeys } from 'types/sorting';
import { IntensityTraffic, IntensityTrafficKey } from '../types/transits';

export type IntensityTrafficSorting = { key: keyof IntensityTraffic; value: SortOrder };

export const intensityTrafficSorting = (
  intensityTrafficEntities: IntensityTraffic[],
  sorting: IntensityTrafficSorting
): IntensityTraffic[] => {
  return [...intensityTrafficEntities].sort((a, b) => {
    if (sorting.key === IntensityTrafficKey.vehicleClass) {
      const currentVehicleClassId = VEHICLE_CLASSES.find((vehicleClass) => vehicleClass.name === a.vehicleClass)?.id;
      const nextVehicleClassId = VEHICLE_CLASSES.find((vehicleClass) => vehicleClass.name === b.vehicleClass)?.id;

      if (
        currentVehicleClassId &&
        nextVehicleClassId &&
        !Number.isNaN(+currentVehicleClassId) &&
        !Number.isNaN(+nextVehicleClassId)
      ) {
        return sorting.value === SortingKeys.ascend
          ? +currentVehicleClassId - +nextVehicleClassId
          : +nextVehicleClassId - +currentVehicleClassId;
      }
    }

    return sorting.value === SortingKeys.ascend
      ? +a[sorting.key as keyof IntensityTraffic] - +b[sorting.key as keyof IntensityTraffic]
      : +b[sorting.key as keyof IntensityTraffic] - +a[sorting.key as keyof IntensityTraffic];
  });
};
