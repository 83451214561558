export default `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABP8AAAHbCAQAAAAekvdXAAAAAmJLR0QA/4ePzL8AAAAJcEhZcwAA
LiMAAC4jAXilP3YAAAAHdElNRQflCwoMFTMTT4+CAAABYnpUWHRSYXcgcHJvZmlsZSB0eXBlIGlj
YwAAOI2dVFuOgzAM/M8p9gh+mxyHBiLt/S+wDiQUVlTd7iCKNHbs8SNN36WkrwY1SNBADIIiQqJA
uFG22OripCQuRKCTZp0JwEtt5ngZTkiGxs4egRQUpAyDrVbjVfgDamRtinAQC9NyKPsQ6UP/OfSD
k0vX0itgSiamzrbXi6Nse7hHh6AfwNwPoBiF7eAfMPgU7ZRoaA9UeiZYzc/88uQv/uvBt0DbPHZD
lSOzn3iC6anozOOhNHkrbRo1AwhbDenZybK5hYWAkXN7KGYbrahRnsZCcHhxO2+LcwpjjjG34+TQ
fg03Yw996eEBxEvI2LXUlo2mnnOi/G5sr/Dp+P8f6LgaJ1TWtsRjkREtLt2d4x1iETW6aL9ojHu6
9S+9c3hnH0IupeWC275Infe7JrXcKWaZlvadgfY9qllvSyNj7GnbXwY81lzSD3dD5+EuTNuGAAAD
vXpUWHRSYXcgcHJvZmlsZSB0eXBlIHhtcAAASImdVluy4joM/PcqZgmOZEv2coAkf1N1P+/yp1uG
Q0ICxcyhTgh+tFotyXL6//d/6Rf+ptJb0puu3jzbZGpXq14km1g1t26LziLLer1eVxGMdyscqa61
zJrL7Lko1jbrqTS/ODZW9UtZajF8A1AVm0R01UWy3rzpxZtho800ZpNk/rabLa6cS7QANsVW8tDL
mPhZHkyeMBi7ckf52SG5tjLXnITkVo8hrbKoyQw+kxZtGHHtGJvU8DurYvaGUcH7hJkV3w3PSTXJ
HIMXPA1PeCj55SN39wQssKZKKcVeXJMUk3SvecEn6wXurB5/sjhWyRKMPSx3foKJ4Cl4zsMAGLk6
4kNFvMEtWOD8ngUoIFQIhFgPpToUworHvE0Jgq0OYclqCLuNBQU+8g1jyzNEeNeE1xmuNPDJJA9p
8yNWT0hs0RMBMiiI3eh8Gt5zIZ4VGytDT1dhdQBPR2DkU4MnYASH+ZbTd8TvvA/bnz6kc13KjBL4
GxMt7bUB4HziiHop1W2E5Bw8naHvwVmhjqBjzVol0mANkVGuhB+KJubMsE8bhZlSAUQdpNLIardD
dR21gthyt5yl1Tg6uAFprdLfmKiljG8aiLrD/6g1ZDQBa0ERsO4UJcq6K3KNUmUl5h3sbEyZbuZ9
wKY97ga2B6wEIKEmUHoPWxNrzAbV6Yzyt9BpYEcJXMyizmfCRaA733Ho1kjGBTVAHgepqSBr7cbt
d68HG327oRfIV1n1K8x2ltSIadoEVZBnwujdz522jVvkOoCYgCHHEnkEqQnEzMYiJGOPDgHn1Ey0
4UytOP4rzid885gNrRBHsJ1hGMTQHGbo07BHkdnY1DBcwSID45UB+ar/nAgPBrt2EWvSYHKmBxab
Vm5BpDwi12GM2cX2wFzrwc5AojEhzdAlYLFGt0BHiB7iB3aMaB+NE/C3Pdv0pDs2yRIZWyLtoAhs
owvjTUIRpSJ4m2KsBEsHq9GOIC956J26cTEZPbrshhWac0QN7LxvG0RCn0KEfNp3DeRHh2PPc4iK
6aN7hNybImZzTy/L3+bJPk2ObqdPfn9ye0/APSHYdZA9KdNvDpY7XAo8HrJAdofP8LxEoTIBvzkb
x9GY3p6N69/Jj3YE7CmO8PLq+bfSs0LTv5XosULTXvuP0n9zQu7g/kn6tNf+Q1vaaseIUTcaGBei
6P33+Mj6c7nbFE103o+1Nval9/exA3zcHZ/w+wszLhFj6uTaXulehFvGjTv9AYdVuaNZ+b6VAAAX
a0lEQVR42u3d2XIkuQ0F0FRH+/8/WX6o1mirJVcCIM55cYTHHpFMIHmLWcvb/xaY3nv0AABgo7fr
/tV/o+cGFxH5AKjs6z52chQU/5iN2AfAbD72tpNioPjHPAQ/AOZ22+kOh0DxjzmIfgB08b4cjIDi
H/WJfgB0c+gc8E/06OGQd+EPgLZ27oFO/6hL8AOgu10PgsU/ahL9AOBmcwT08JeKhD8A+GrTzij+
UY/wBwA/bXg3vPhHLT7qAQCPrNwjxT8qEf0A4JlVO6X4Rx3CHwC8smK3FP+oQvgDgDVe7pjiHzUI
fwCw1otdU/yjAuEPALZ4unOKf+Qn/AHAVk92T/GP7IQ/ANjj4Q4q/pGb8AcAez3YRcU/AIBZ3Q2A
4h+ZOfsDgNOJf+Ql/AHAUXd2U/GPrIQ/ADjDrx1V/AMAaEX8IydnfwBwlh+7qvgHADC7bwFQ/CMj
Z38AcBnxDwBgfl+OVsQ/8nH2BwAXEv8AADr473hF/CMbZ38AcCnxDwCgFfGPXJz9AcBV/u2y4h8A
QCviHwBAK+IfmXj0CwBXel8W8Q8AoBnxDwCgFfEPAKCPd/GPTLzzDwAGEP8AAFoR/wAAWhH/AABa
Ef8AAFoR/wAAOnkX/wAAWhH/yMLXvgDAEOIfAEArf6MHAMO8RQ8AAFa4/HmY0z8AgFbEPwCAVsQ/
AIBWxD8AgFbEPwCAVsQ/AIBWxD8AgFbEPwCAVsQ/AIBWxD8AgFbEPwCAVsQ/AIBWxD8AgFbEPwCA
VsQ/AIBWxD8AgFbEPwCAVsQ/AIBWxD8AgFbEPwCAVsQ/AIBWxD8AgFbEPwCAVsQ/AIBWxD8AgFbE
PwCAVv5GD4A73qMHAADMS/zLQuQDAIYQ/6KJfQDAUOJfHMEPAAgg/sUQ/QCAIOLfaIIfABBK/BtJ
9AMAwol/o4h+AEAKvvZ5DOEPAEjC6d/1RD8AIBGnf1cT/gCAVJz+XUn0AwDScfp3HeEPAEhI/LuK
8AcApOTh7xVEPwAgLad/5xP+AIDExL+zCX8AQGri37mEPwAgOfHvTMIfAJCe+Hce4Q8AKED8AwBo
Rfw7i7M/AKAE8e8cwh8AUIT4dwbhDwAoQ/wDAGhF/DvO2R8AUIj4d5TwBwCUIv4BALQi/h3j7A8A
KEb8O0L4AwDKEf8AAFoR//Zz9gcAFCT+AQC0Iv7t5ewPAChJ/AMAaEX8AwBoRfzbx6NfAKAo8Q8A
oBXxbw9nfwBAWeIfAEAr4h8AQCvi33Ye/QIAhYl/AACtiH8AAK2If1t59AsAlCb+AQC0Iv4BALQi
/m3j0S8AUNzf6AHwwttF/15BFgCaEv+2GBuZrgp+AEBr4l9O9aPfthk4iwSAYbz3L6P64c+MASAt
8W+9USdUPaNQz1kDQADxLxsxCAC4lPgHANCK+LfWmEe/zv4AgIuJfwAArYh/mTj7AwAuJ/6t43vp
AIBJiH8AAK2If2v42AcAMA3xDwCgFfEvC2d/AMAQ4t9rPvYBAExE/AMAaEX8y8GjXwBgEPHvFY9+
AYCpiH8AAK2Ifxl49AsADCP+PefRLwAwGfEPAKAV8e8ZP/YGAExH/AMAaEX8i+bsDwAYSvx7zMc+
AIAJiX8AAK2If7E8+gUABhP/HvHoFwCYkvgHANCK+BfJo18AYDjx7z6PfgGASYl/cZz9AQABxL97
nP0BANMS/wAAWhH/onj0CwCEEP9+8+gXAJiY+AcA0Ir4F8OjXwAgiPj3k0e/AMDUxL8Izv4AgDDi
HwBAK+Lfdx79AgCTE//G8+gXAAgk/n3l7A8AmJ74BwDQivg3mke/AEAo8e+TR78AQAPi31jO/gCA
YOIfAEAr4t8Hj34BgBbEv5E8+gUAwol/AACtiH83Hv0CAE2If+N49AsAJCD+LYuzPwCgEfFvFGd/
AEAK4h8AQCvin0e/AEAr4t8YHv0CAEmIfwAArYh/Hv0CAK2IfyN49AsApCH+AQC00j3+jXj06+wP
AEike/wDAGimd/zzsQ8AoJ3e8W8Ej34BgFTEPwCAVjrHP49+AYCGOse/ETz6BQCSEf8AAFrpG/98
4x8A0FLf+AcA0FLX+OdjHwBAU13j3wiZH/2KvwDQVs/41z38dJ8/ALT2N3oAw42LPkIWAJBQZPwT
jwAAhhsb/wQ+AIBgY+Kf2AcAkMTV8U/wAwBI5br4J/gBACR0RfwT/AAA0jo7/ol+LEvur7wGgObO
jH+iHwBAemfFP9EPAKCEM+Kf6AcAUMbx3/wV/gAACjl2+if6cY8PfgBAYkdO/4Q/AIBy9p7+iX4A
ACXtO/0T/njMo18ASG1P/BP+AADK2h7/hD8AgMK2xj/hj+c8+gWA5LZ89EP0AwAob/3pn/DHa87+
ACC9tfFP+AMAmMK6+Cf8sYazPwAo4Phv/gIAUMia+OfsjzWc/QFACa/jn/AHADCRV/FP+GMdZ38A
UMTz+Cf8AQBM5ln8E/5Yy9kfAJThk78cJ/wBQCGP45+zP9YR/gCglEfxT/gDAJiSh78c4+wPAIq5
H/+c/bGO8AcA5dyLf8If6wh/AFCQh7/sJfwBQEm/45+zP9YQ/gCgKKd/7CH8AUBZP+Ofsz9eE/4A
oLC/0QOgGNEPAIr7fvrn7I/nhD8AKM97/1hP+AOACXx9+Ovsj8dEPwCYhPf+8ZroBwAT8fCXV4Q/
AJjK5+mfR7/8JvoBwHQ8/OU+wQ8AJvUR/5z98Un0A4CJOf3jk9gHAA2If4h9ANBKfPwTPQAABrrF
v5h3/gl+AADDxZz+CX4AAEHGxz/RDwAg0Ohf/RD+AABC/VlGvvNP+AMACDby9E/4AwAINy7+CX8A
AAmMin/CHwBACmPin/AHAJDE6E/+AgAQ6s+Az/06+wMASMPpHwBAK9fHP2d/AACJOP0DAGhF/AMA
aOXq+OfRLwBAKk7/AABaEf8AAFoR/wAAWhH/AABaEf8AAFoR/wAAWhH/AABaEf8AAFr5Gz0AGOY9
egC054vwgRTEP4BRbi9BhEAgmIe/AGO9O4kGYol/AOOJgEAg8Q8ghgAIBBH/AKIIgEAI8Q8gjgAI
BBD/ACIJgMBw4h8AQCviH0As53/AYOIfAEAr4h9ANOd/wFDiHwBAK+IfQDznf8BA4h8AQCviHwBA
K+IfAEAr4h8AQCviHwBAK3+jBwAAlLP90+pv0UPmk/gHAKxx7AuKvv6/RcFg4t8zWwpdKQMwoyu+
lfLj32nvDCL+/bS3zL2qAWAu138d+e0v2DWHE/9uzi3xz3+bkgagnrG/QyMEDtc9/l1d4EoagEri
foDQjjlQ3/g3ssCVNADZ5fjlaTvmED3jX0yJK2kAcsoR/b6Px355oW7xL77AhUAAMonfGR+Py255
kU7xL1OBK2oA4mXaGR+Nz255gS7xL2OBK2oA4mTcGR+N0255sg7xL3OBK2oAxsu8Mz4ar93yRLPH
vwoFrqgBGKnC3nh/1HbLk8wc/yqVt6IGYIRKe+O90dsrTzFr/KtY3ooagCtV3BvvzcFuedic8a9q
gStq2CpDx1S949DNPJXquOSwP9EDON178QKvPn4YS7/AOnP1ir3yoNni3xzlMMcsYAz9Aq/MGZZm
nNMwM8W/mcp7npnA9WbqfTjfvP0x78wuN897/2YrAu8DPF/v9ZytQ37Pr/f1hUfm7n175U6znP7N
Wd5zzooIHWqpwxxhmx4n4x3meLoZ4t/M5T3vzBipSx11mSes06cj+sz0NPXj3+wXffb5cb1ONTTz
i0HYplcv9JrtCarHvw4X3IbGEf2qp9+M4bd+fdBvxofUjn99LnafmXKunpXTc9bwqWcP9Jz1TpXj
X68L3Wu2nKNv1fSdOXSu/74z36xu/Ot3kfvNmGN6V0zv2dNZ79rvPfsNqsa/nhe456zZR7VYATpS
91ZglZrxr+/F7TtztlEpy2IV6EfNL4tVWKVi/Ot9YXvPnnVUyQcrQSfq/YOVeKle/HNRrQDPqRDo
SOd/ZTVeqBb/XNBlsQo8ozq+sx70oNJ/siJP1Yp/LuYHK8F9KuM3awI96f0nKsU/FxKe0yP3WRdm
p8bZqE78U9zfWQ9YT78wM/X9iJV5qE784ydlzXcqAjrS+c9YnQeqxD8X8B6rwifV8Jz1gZ70/l01
4p+L94iV4UYlvGaNmJG6ZpcK8U9xA2dwL2E2anoNq3RHhfjHM8oaVQA96fy1rNQv+eOfi/aKFepO
BaxnrQCW/PHPzRo4k3sKs1DLW1itH7LHP9ZQ1p25+gCvuVd+kzv+uVhrWSlYS7cwA3XMIZnjn+KG
V3QJdKTz97BqX2SOf2yhrDty1fexbkBzeeOfGzRwFfcXKlO/e1m5/+SNf2ylrLtxxQHYJWv8s7Ht
YdVgLd1CVWr3CKv3z9/oARTz9u8/FRDR8tXg29N/mm+8wDme9b7OTypn/MtULm8r/ts8431/sQXD
+dbVXL6XTrqFivJ00Lre1/lJXR3/6i7zlnG//ZsrjJKj2vZ0t26B+rb3vs5PJuPpX3x57IusOYq7
buCmlmN19rbE9wrUE981xzs/fhb2ySVn/It0vCRsa1wvusLOuXXG94pNALY4q1/ie58Bn/zdeonj
SuLtxNK2qTCrc6tbr8B6sZHp3F6N7X3hc/nj5vvP2esQV9o1y7rmqPu5oqojtwF1B2tc06XyR6AR
3/u35QYbczO+avtxssEVoiLLlfWsUyCvKzvfQUmQrF/7PNK1xRdR2vXKut6I+7m6km0C8NxcxyNf
/wIBxsS/vDfYEa88lDZnitoCRvwNvQK5jOlJnR9g1Onfui1r9MY2quSU9nN5Xx6wLGNjmV6BPObu
/OY7z7iHv/kWemS5jT7XyLfac4y1o9G35fHbgAqkgvF1OrYXvfQb7M8ybtFfFe/Y4h5faoqb42Y9
IY/+m8B39sjJjf3oR55X2TFlNvKv5lnr56OsMU7Gsg1ArPn3yGWpsk9eZPQnf3Ns9zaXHDLUAs/E
dYo3S8BXsz8di//L7UR88cv7hv/2CpHlpbQ/2XKzU63QUWznu+8Mcot/4w9cc5wCRlDay5LlFLie
kasWXanRfx96iu+8+BG08Dfwb39sZd0+XfQ2aBPP93P20ZEv+u9XkqF2RnVKFr1mCxk07rrI+Peh
04nGWI0LmymMC4D5XixBhBx90O2lX4iP9/7luORdWG1yU6HQUZ7OzzOSafX6zd88BZVnJPBTpurM
NBaAWbx1in82EgC4L9cemWs0E/qMf5Z6LOtNTtkqM8uvEgFMpM/pX7ZNDQCyyLdH5hvRVL7GP0s9
lvUmn4xVmXFMAKV1Of2zgQDAfTn3yJyjmsT3+Gepx7LesIZOATjPW5/TP+A1MQughZ/xb87b/5yz
As7ks790lXePzDuy8pz+xVLasIZOATjR7/jnNgs96X2A+b0tS4/TP5saAFRkB7/IvfhnsQGgB3t+
Sx1O/3LTeLCGTgE47t+99M+zfwgAwGwenf4JgNCLngeY3X93eg9/AQBaeRz/ZjkLmGUeAAD7fUlE
f9b9zwAAhpNFLvH84a9FBwCo71um894/AIBWXsU/538AALX9yHOvT/8EQACAun5luTUPfwVAACDC
e/QA5rTuvX8CIABARXdS3NqPftQNgF43wBo6BWBGdxPc+k/+1g2AAAAdPUhvW774RQAEojidBDjN
tu/9EwABAGp4mNu2fu2zAHg2ZxoAxMm9C+UeXXZPMtv2X/14KxcBFQ+soVMA5vE0re370bdqARAA
oI8XSW3vb/4KgMA4I04m3dXoysn/fF7ez/4e+lcrmaOsIADcZ4/cY9VL2b2nfxv+RAIKCNbI2ilZ
xwWQzcpkdiz+VfwgCADwVc6XWDlHldvqTHY0/t3+WP4ImLOIco6KztQkQE2b0tgZ8W/zHwVYTSSF
6+mz6jamsLPi3+1Pi4BbaDYy6lqX7l6QS9d70R478teZ8W/nEAZRSFCRzoUx9FpNO3PX2fHvwFAu
l6u0c40GPqlNIJr70BoH8tb+7/17NaRlcfmAY9xDYJz3NIc3Ov+1g9fqqvj3dXB5LqPShjWydIo+
AfjplPvztfHv+0Ddyj9YCbLLEgBH6TVbeCRH59sjHznx6oyIf7+HHXdpc5Q28JotAMaL3yV1/k+X
XJGR8e/1VEZc9F6lHT1XzjaueqI7pdIWoM+42sh+iO79kfrM9JcrPvmbXey2UmlTo7fIWh37txtv
AZCMPXKQjvEv9uEz1BFVr/oEvhv7EsUe2UDP+BdldGE705jP6GsacTO2AUC0mC60Rw6UK/6NuxQ2
GFhndK+M783WWwA8ML4T7ctD5Yp/I43f1JQ2NY190zlwz/iXKWO7Ue8P1jf+jQ1kEYXtTGNOEdd1
VP3aACCTcbukPXK4bPFvznc22dSobsyXMkVovgXAC9f3pWdjIbLFv/FGlDbUd/UtWp/Ac1EvVebs
/PYv/PLFv4j3N1xXfnGvatqX9sTiru1V1ezVP2R2VYfq/ED54l+Mq0ob5nL+7Tp2A/AyiUoi6/X8
PrVDhor50beM3pdzWyu2sG1qc3sL/0WOsyrMBgBVzNT59siU8S9uazuruKMLG652vFcydIktALY5
Y5fM0PukjH+xjhZ3hsK2qc0v9vzv5v3fSPb+P4GtdP4Z7JFL1vgXXeD7ijtLYcNI27olU5fYAmC/
up3PsixZ418O64tbYdPdZw+8PflnwFHRxyPfPev8rL3vhd+yLHnjX54C/zmOtyVrSX8dIfPL0yPf
5RzVb/oEzlSl8/nHF79s5XuKyEKAgY50/hFW75+88c8l2se6wWv6BGgtb/xjD5taL643dKTz97Jy
/8kc/1wm4AruLVSnhjkoc/xT4FtZr35cc4B13C+/yB3/AM5mC2AG6ngrK/ZN9vjncq1nrXpy3bex
XgDp4x9r2dT6cu2hI52/hdX6IX/8c8mAs7ifMBP1vJaV+iV//HPZ1rBGvbn+61gngGVZasQ/XrGp
oQagI52/hlW6o0b8c+mAo9xHmJG6fsUK3VUj/rl8z1gblkUdvGJ9mJXaZocq8U+BP2Jd+KAWHrM2
0JPef6BO/OMehc1X6gE60vmPWJmHKsU/lxFe0SX3WBVmp8bvsSpPVIp/LuVP1gNe0yd0oM5/siJP
1Yp/LudX1oJ71MV31oMu1DobVIt/CvyDdeARtfHJWtCJev9kLV6oF/9c1GWxBjynPm6sA/Sk91+q
GP9c2O7z5zU1Yg3oSNUvi1VYpWb8631xO8+d9brXSff505XKtwKrVI1/fS9w13mzXeda6Tx3uutd
/b1nv0Hd+NfzInecM/t1rZeu84abvh3Qd+abVY5//S50t/lyXMea6Thn+K5nF/Sc9U6141+vi91p
rpynW910my/c99auF7rN96Dq8a/PBe8yT87XqXY6zRVe6dMP/cLuYfXjX48C7zBHrtPl1thjlrBe
j57oMcuTzRD/5r/0s8+PEeavovlnCNvN3xfzz/ASc8S/mU835p0Zo81cSfoEHpm7N+ae3YVmiX/L
MmcRzDgn4sxaT7POC84x68ujWec1xEzxb75NYLb5EG/G2+V8M4Lzzdcn881oqL/RAzjZ27Is79GD
OG0mcIW3SXrkNhdgnXn2x4/ZcMBcp383MxTFDHMgr1nOAOeYBYwzR8/McgcLNdvp303t1zjKmhGq
nwHqE9ij9v74MQMOm/H076ZqgVQdN/XUfQVdd+SQQd3+0funmfP076beaxxlzWj1ukSfwHE6v72Z
49+yVCpxhU2USo+B9Qmcpc7+qPMvMHv8W5YKJa6wiZW/Rz5GCZypQu/r/Et0iH/LkrnEFTY55O2R
j9EBV8jc+zr/Ml3i37JkLHGFTS63iszVJfoErpdvf9T5F+sU/5YlU4krbLLSJdBRns7X+wN0i3/L
En/CoazJL7pL9AlE0PltdIx/NxFFrqypJeY0QJ9ArJgQqPOH6hv/bkYVubKmqo/aHbEV6BPIY1wI
1PkBuse/m+s2OEXNLK6MgfoEstL5kxL/vjqrzJU08zpvM9AnUMdnv9ohpyD+3fOzOF8Xu3Kmm+1d
ok9gBt/7WOcX9X8G69mRJaHqEQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0xMS0xMFQxMjoyMTo1
MSswMDowMIr98X0AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMTEtMTBUMTI6MjE6NTErMDA6MDD7
oEnBAAAAN3RFWHRpY2M6Y29weXJpZ2h0AENvcHlyaWdodCAxOTk5IEFkb2JlIFN5c3RlbXMgSW5j
b3Jwb3JhdGVkMWz/bQAAACB0RVh0aWNjOmRlc2NyaXB0aW9uAEFkb2JlIFJHQiAoMTk5OCmwuur2
AAAAAElFTkSuQmCC`;
