import React from 'react';
import { Menu as ANTMenu } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as H from 'history';
import { externalLink } from 'assets/icons/';

import { NavBarLanguageKey } from 'app-constants/nav-bar-items';
import { StyledExtendLink } from 'components/NavBar/styles';

interface NavbarItemProps {
  labelKey: NavBarLanguageKey;
  route: string | H.LocationDescriptorObject;
  isExternalLink?: boolean;
  icon?: JSX.Element;
  onClick?: () => void;
}

const NavBarItem: React.FC<NavbarItemProps> = ({ labelKey, route, isExternalLink = false, onClick, ...rest }) => {
  const { t } = useTranslation();

  return (
    <ANTMenu.Item onClick={onClick} {...rest}>
      <Link to={route} target={isExternalLink ? '_blank' : undefined}>
        {t(`navBar.${labelKey}` as const)}{' '}
        {isExternalLink && (
          <StyledExtendLink>
            <img src={externalLink} alt="link" />
          </StyledExtendLink>
        )}
      </Link>
    </ANTMenu.Item>
  );
};

export default NavBarItem;
