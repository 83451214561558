import { Card } from 'antd';
import styled from 'styled-components/macro';

import palette from 'palette';

interface StyledCardType {
  isCurrentPeriodValueIncrease: boolean;
}

export const StyledCards = styled.div`
  display: flex;
  column-gap: 32px;
  margin-bottom: 64px;
  min-height: 190px;
`;

export const StyledCard = styled(Card)<StyledCardType>`
  width: 245px;
  padding: 16px 24px 10px;

  .ant-card-head {
    border-bottom: none;
    border-radius: unset;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
    padding: 0;
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    padding: 0;

    &-value {
      color: rgba(0, 0, 0, 0.85);
      font-size: 30px;
      letter-spacing: 0;
      line-height: 38px;
      margin-bottom: 19px;
    }

    &-percent {
      color: ${({ isCurrentPeriodValueIncrease }) =>
        isCurrentPeriodValueIncrease ? palette.green.g6 : palette.red.r3};
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 10px;
    }

    &-disparity {
      color: ${palette.green.g5};
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 19px;
    }
  }

  .ant-card-footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid ${palette.grey.g3};
    padding: 8px 0 0 0;
  }
`;

export const StyledLanes = styled.div`
  display: flex;
  flex-direction: column;
`;
