import React, { useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import { ChartItemProps } from './types';

interface PieChartWithActiveShapeProps {
  data: ChartItemProps[];
  dataKey: string;
  colors: string[];
  width: number;
  heigth: number;
}

export const PieChartWithActiveShape: React.FC<PieChartWithActiveShapeProps> = ({
  data,
  dataKey,
  colors,
  width,
  heigth,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={width} height={heigth}>
        <Pie
          activeIndex={activeIndex}
          data={data}
          dataKey={dataKey}
          cx="50%"
          cy="50%"
          outerRadius={110}
          paddingAngle={2}
          isAnimationActive={false}
          onMouseEnter={onPieEnter}
          label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index + 1}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};
