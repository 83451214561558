import React from 'react';
import { useSelector } from 'react-redux';

import 'antd/dist/antd.css';

import { useOnRouteChange } from 'hooks/use-on-route-change';
import { SyncHeaderHookReturnType } from 'hooks/use-sync-header';

import { Loader } from 'components';
import { RoutesContainer } from 'routes';
import { useInitializeApp } from 'hooks/use-initialize-app';
import { authSelectors } from 'store/auth/selectors';
import { userSelectors } from 'store/user/selectors';
import AppLayout from './AppLayout';
import GlobalStyle from '../styles';
import { NotificationMessage } from '../components/NotificationMessage';

export type HeaderConfigContextType = Pick<
  SyncHeaderHookReturnType,
  'setHeaderRenderer' | 'setHeaderOnBackButtonState'
>;

export const HeaderConfigContext = React.createContext<HeaderConfigContextType>({
  setHeaderRenderer: () => {},
  setHeaderOnBackButtonState: () => {},
});

const HeaderConfigProvider = HeaderConfigContext.Provider;

export const App: React.FC = () => {
  useInitializeApp();

  const isAppLoading = useSelector(userSelectors.isUserLoading);
  const isAuthenticated = useSelector(authSelectors.isAuthenticated);

  const { setHeaderRenderer, dev, headerTitle, headerContent, onBackButton, setHeaderOnBackButtonState } =
    useOnRouteChange();
  const initialContext = { setHeaderRenderer, setHeaderOnBackButtonState };

  return (
    <Loader isLoading={isAppLoading}>
      <AppLayout
        isAuthenticated={isAuthenticated}
        onBack={onBackButton || undefined}
        title={headerTitle}
        extra={headerContent}
        dev={dev}
      >
        <NotificationMessage />
        <GlobalStyle />
        <HeaderConfigProvider value={initialContext}>
          <RoutesContainer />
        </HeaderConfigProvider>
      </AppLayout>
    </Loader>
  );
};
