import { useState } from 'react';

export type ModalState<State> = {
  isVisible: boolean;
  state: State | null;
  handleShowModal: (isShown: boolean) => void;
  addModalState: (data: State) => void;
};

export const useModal = <State>(): ModalState<State> => {
  const [state, setState] = useState<State | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  const handleShowModal = (isShown: boolean) => {
    setIsVisible(isShown);
  };

  const addModalState = (data: State) => {
    setState(data);
  };

  return {
    isVisible,
    state,
    addModalState,
    handleShowModal,
  };
};
