import palette from 'palette';
import styled from 'styled-components/macro';
import SFUITextMedium from '../../../fonts/SFUIText-Medium.woff';

export const TransitDocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  background-color: ${palette.white.w1};
  border-radius: 2px;
  margin: 24px;
  @font-face {
    font-family: 'SFUITextMedium';
    font-size: 14px;
    src: url(${SFUITextMedium}) format('woff');
  }
`;

export const LogoContainer = styled.div`
  padding: 10px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${palette.black.b3};
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  font-family: 'SFUITextMedium';
  font-size: 20px;
  margin: 0;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
`;

export const TransitInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TransitInfoSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 24px;
`;

export const SectionTitleContainer = styled.div`
  border-radius: 2px;
  padding: 24px 20px;
  background-color: rgba(245, 245, 245, 0.5);
  margin-bottom: 8px;
`;

export const SectionTitle = styled.h2`
  color: ${palette.black.b4};
  font-family: 'SFUITextMedium';
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
`;

export const SectionContentContainer = styled.div`
  margin-bottom: 32px;
`;

export const StyledImage = styled.img`
  width: 817px;
`;

export const Text = styled.span`
  color: ${palette.black.b4};
`;

export const StyledTableContainer = styled.div`
  .ant-table-thead > tr > th {
    background-color: inherit;
  }
`;

export const FooterContainer = styled.div`
  border-top: 1px solid ${palette.black.b3};
  padding: 16px 0;
`;
