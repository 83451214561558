import styled from 'styled-components/macro';

export const StyledSingleDatePicker = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCurrentDate = styled.span`
  margin: 0 5px;
`;
