import React, { FC, useLayoutEffect } from 'react';
import { Provider } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { setupMiddleware, store } from 'store/store';

const StoreProviderWithRouter: FC<RouteComponentProps> = ({ history, children }): JSX.Element => {
  useLayoutEffect(() => {
    setupMiddleware({
      routerHistory: history,
    });
  }, [history]);

  return <Provider store={store}>{children}</Provider>;
};

export default withRouter(StoreProviderWithRouter);
