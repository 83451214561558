import { API_URLS } from 'app-constants/api';
import httpService from 'services/http.service';
import { CreateUser, UpdateUser, UserLogActivityQuery } from 'store/user/types';
import { ApiRequest } from 'types';

export const getMyUser: ApiRequest = () => httpService.get(API_URLS.myUser(), {});

export const createUser =
  (user: CreateUser): ApiRequest =>
  () =>
    httpService.post(API_URLS.users(), user);

export const getUsers: ApiRequest = () => httpService.get(API_URLS.users(), {});

export const updateUser =
  (user: UpdateUser): ApiRequest =>
  () => {
    const { uid, ...copyUpdatedUser } = user;
    return httpService.patch(API_URLS.updateUser(uid), copyUpdatedUser);
  };

export const getUserActivityLog =
  (query: UserLogActivityQuery): ApiRequest =>
  () => {
    return httpService.get(API_URLS.userActivityLog(), query);
  };

export const getUserActivityLogExport =
  (query: UserLogActivityQuery): ApiRequest =>
  () => {
    return httpService.get(API_URLS.userActivityLogExport(), query);
  };
