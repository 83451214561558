export default `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlkAAAEhCAQAAADmaH39AAAAAmJLR0QA/4ePzL8AAAAJcEhZcwAA
CxMAAAsTAQCanBgAAAAHdElNRQflCwoMGgrL0htFAAABYnpUWHRSYXcgcHJvZmlsZSB0eXBlIGlj
YwAAOI2dVFuOgzAM/M8p9gh+mxyHBiLt/S+wDiQUVlTd7iCKNHbs8SNN36WkrwY1SNBADIIiQqJA
uFG22OripCQuRKCTZp0JwEtt5ngZTkiGxs4egRQUpAyDrVbjVfgDamRtinAQC9NyKPsQ6UP/OfSD
k0vX0itgSiamzrbXi6Nse7hHh6AfwNwPoBiF7eAfMPgU7ZRoaA9UeiZYzc/88uQv/uvBt0DbPHZD
lSOzn3iC6anozOOhNHkrbRo1AwhbDenZybK5hYWAkXN7KGYbrahRnsZCcHhxO2+LcwpjjjG34+TQ
fg03Yw996eEBxEvI2LXUlo2mnnOi/G5sr/Dp+P8f6LgaJ1TWtsRjkREtLt2d4x1iETW6aL9ojHu6
9S+9c3hnH0IupeWC275Infe7JrXcKWaZlvadgfY9qllvSyNj7GnbXwY81lzSD3dD5+EuTNuGAAAD
rnpUWHRSYXcgcHJvZmlsZSB0eXBlIHhtcAAASInFVkuWnDAM3OsUOQJItmwfh25gl/eyzPFTJdPT
QNPJTLLI8MYN/kil0s/y8/sP+Ya/MY8mdre11DL46OY3zyXp4OrZizdfbFZd1tvttqpivnniTC6W
02xDmsuQDHurN0m1TAUHs5UpLTk5fiHQDIdUbbVFB7uXalOpjoM+U5mPOvDb774U45pQA9AkX4nD
pr7wsT2QPMVg7sYT6eOEDrmmOQ+iBLeWmLKsi7nOwDNasoqZYg1zozm+BzOs3jGreB+xsuK3YhzN
ROeYnDA6Rliow+nRzTwFCuzJmlLyk2kqsUjzakl4BptgzlriT5eCXboE4hKaG59AohgV49wVAFGx
Av+QkVJhFjRw/YgCEOAqOEK9BVMNDGHHY91HAWFrAbFE1Ynd+4IEv+INZcvTRXg3wesMUyrwDAQP
aoeHr54iccQuCBgAQf1O46Vbz40YMw5mup6mQmsXPL4KRjxVWAJEMJhvg3wO+Ib75fjTBrnmJc1I
ga+oqHLkBgLnC0OspJSLd5dcC5cr6UfhzNACp2PPmjXCYA2Ska4U3xkVxkzXTx2JkZIhiDxoppLV
7y/Z9coVyNZN86A1R+ngAYS1aXujIqfUf6kg8g7/PddajDAhknWKJGXeVb1FqjITh4PY2Rkyzb20
LlaOct+IbRgTsu692CzMMe9QxyvInxUtXXakwOQeeT5TXDi68R1FN0cwLsgB4nihmgwy1+48vlnd
0djbAy0BSGbWr1DbmFLdp7JzqiLOlN7b6k7d+y1iHYIYgEHHEnEEqimIkY1NCMYWHQKecoXHKAiw
UP5ZdnEC1XvGTIsCDHMx1+INJQpfiWUksY5Z5YjtZwTEa+WjIjwRbADSA4D8LYIzADkhoNdab47g
4f4/EG1F/srVZzSbp8JrQI4Cs2sQggPstOXYNRAfzZZDBw6x3ZArtWIjto0ILnZXVoIZhiHVYekc
3RWCw7hq1dHFMGbudT6GrxHJjR6N24iuRxx/h0we0IChdgqDfhKrMUMUTNXEWwHuKEbwfMMqKAw3
YC1aNnTZNbLn3J7Jq34iX/DOPt5wtfpYD6Wyu2Xw8PjKEUL22UUmUvzo/VFqthLMwpZ5Nzttf5vl
75Jc/jWiHwCkIyjFGR/LJ4rsm7Yge4PY+tAXEsSOtDxFmaUD/tzZJG6Ev+ts6zkQ9k6Jvhy+lGtn
HiLo8rZ23iX9avmUf75P95WLS30m8vCk8j6OMiK/ADQ3xKbNcUgdAAAV9klEQVR42u3dvXbbSIKG
4U8e9zmTDTqbzHA2meEbWEFXYDrbaJrKNmv6BtZUtCHd2WZUT7JnI2o2mZD0JLMZ2VdAeG+A7Gz3
dMANaFmiREpE/Rf4Pgj8C6GAAj9UFQvAmfJV7/m7WexCARkoVe7527UWsQv2vLPYBThSpVKV3qg4
cLABuLMNr89aa6GF1rGLc1/qkVWr1vne9hSAMBrN9FkzNbELIqUbWYV6eqde7GIA+KbRjX6O3XlM
MbJq/aB+7EIA2KvRz7qO1+JKK7IK9fSRsSogeTf6Kc6XXelEVqGBflQRuxgAjjTTVfjYSiWy+hoR
V0B2gsdWCpFVa6QqdiEAGLrRh3BjW7+LvLOF/k3/rj9GLgUAc39SX/+n/w6zsbitrFpjBtuBTpjp
fYhJpy8i7uJQUwIL6IhayxAzKWN1DAv9h/4l0rYB+PB7/bPOfA/Gx4msUn/jJhygg2qV+qz/9beB
GGNZlaZMaAA6a6ELf6Na4SOLwAK6zmNohY4sAgs4Bd5CK2xkEVjAqfAUWiEji8ACTslCb93/0HDf
GJb6B4EFnJA/qtRfXf/QUJFV6G9MGwVOTKVfXd/IE6pjOOEJo8BJunA7uTTMDTtDAgs4URO3A0Ih
Wlm1pgG2AiBNN3rv7of5H8sq9A/93vtWAKTqT/ri7iUX/ltZjGIBp26t167maPmOrL7GHn7q9mWQ
n539vB8Mvs288rBf8bU/Eo1+jl1oz07r7DiXvLze2Gnn0J9SK22cLXON1PMyVWJqUJpuan8kuj9O
eZpnR62BJk4/v73Yu3QMk8retyw18Dqr6zRPSjdHgsjq9tnR08TZp7iIvTPPGTrZ0WmAJ2ud9klp
dySIrO6fHaXGTj7Lw9g78rTKSS7XQcrKSWl+JIis0zg7SgetrZWLvpKvqaSFg2H3K72O8z5aAA80
em/9OopCH+0L4iuyPlq+mXCti9SbkcCJudFby/lVfft2lp/IqjWwWn+ht7SvgOQ0utC11U+w7n35
iKxCE6v1F7oI9+5ZAC2sdWkVWrXt+LSPyBpbfZnp9VH3AKxd6sZibcvxLPeR1beaMrbWJYEFJO7S
YkzLsp3lOrJKjazWf+/u9kkAnqytvj20ame5jiy7TuEVg+5AFhpdGq9r1c5yG1lDqybfjGkNQDZu
LEa0LNpZLiOrsmrwrS1SG0B4H4w7hxbtLJeRZTfj4pKJDUBWGv1kvK5x88ZdZI2s5rt/svraFEAM
nyzaWaXZiq4iy26+e5PxI9GA07W2+OQatrPcRJbtTdDMxQLy9Ml4QMfwfkM3kTW2utmRqQ1AvoK3
s+z1rJ6hM49V7Ht4IpL5keB5WadzdhyyNP70F+03Zt/Ksu8UAsiZeTtr0H4V+8iyne++sC4BgJiu
jcezfmyfHraRNbC6CXrBfHegA0z7SkX7dpZdZJWWA2gfrNYGkIaZ8VdordtZdpFl1yn8xDeFQEeY
jme1bmfZRNbA6iZopo8C3WHezvqh3X83j6zK8slYTB8FusS0CVKq3+a/m0eW3dQGOoVAt5i3s1qN
iJtG1tDqJmibO5MApMm8nTU8/j+bRZbdk7HoFAJdFOR7Q7PIsusU2jzNEEC6AnxvaBJZdk/G4umj
QFcFaGe1jyzbN0HTKQS6y3s7q21k2d4ETacQ6DLv7ay2kfXR6slYdAqBrvPczmoXWXQKATzNczur
TWTRKQTwPK/trDaRZdcptHm7LIB8eG1nHR9ZdAoBHMdjO+vYyLLtFHJPIXA6PLazjo0s204h9xQC
p8RbO+u4yKJTCKANb+2sYyKLTiGAtjy1s46JLLtO4YInvAMnyFM76/nIsu8UAjhFXtpZz0WWbaeQ
9xQCp8pLO+u5yLLtFA69HhIAKTNvZx18s8TTkUWnEIA583ZW/1Bj6anIsu0UfqBTCJw48xmZBx7W
/lRkDaw6hTN9CnBAAKTMeTvrcGTZvZKCJ2MBkJy3sw5Hlu03hU2Y4wEgaY7bWYciy+49hXQKAdxy
2s7aH1klnUIAjjhtZ+2PLLtO4SWdQgD3OGxn7YusgWqLwvG4ZAC7HLazHkcWnUIArjlrZz2OrNGx
b23diydjAXjMWTvrYWTV6lkUi04hgP3MH0K10856GFk2A+90CgEcstC14Zo77ayXh/+ptZnlTdTx
mOz1MHahvSgN1hjGLrRnpcE6w9iF7pgf79poZzv/sLSKLADwYa3vb397v2No18YCAD8K9W9/+7t7
fz2x+q4QAPz5z+0vdx3DniaxywQAB3y/nUB11zH8IXaJAOCg3vaX28gqrOZjAYBf77a/3EZWL3Z5
AOAJ9faX28h6F7s8APCEYhtaL77+oRe7PADwpFq6jaw6dlkA4Bnn0m1knccuCwA8o5JoZQHIRaFy
G1mF1aspACCMchtZVexyAMAR6m1k1bHLAQBHeLWNrFexywEARyi3kVXGLgcAHKHaPslhE7scAHCU
sxf2PwMAAileMPgOIBsVrSwAGXnB4DuAfBBZAPJR0zEEkBEiC0BGXuqNwVoXsYsNoANG7e9wfmn0
7sJZ7D0F0AHr9qvQMQSQESILQEZMImsdu9AATtQrk8haxC41gBNV0jEEkBEiC0BGXn5Xt/nvv0mS
vnO2+d9i7z+ArNDKApARIgtALEX7VYgsALFU7VchsgBkhMgCkBEiC0BGXrb7799JUt3yNWJnsXcS
QFeEaGVteFMiADdCdQyJLQAOhBzLIrQAWAo7/E5bC4CV8N8YEloAjMWY5EBoATAUZ14WoQXACFNJ
AWQkVmTRzgJgIF4ri9AC0BodQwAZiRlZtLMAtEQrC0BGiCwAGYkbWXQNAbRCKwtARogsABkhsgBk
hMgCkBEiC0BGiCwAGSGyAGSEyAKQESILQEaILAAZIbIAZCRuZJ3F3n0AWSnOvot5a/LZb7EPAIB4
DNKHjiGAjMSMLLqFAFqilQUgI/EiizYWgNZiRRaBBcBAnMgisAAYYSwLQEZiRBZtLACGXgbeHnEF
wELYVhaBBcBKyMgisABYCtUxJK4AOOA/sggrAM606hj+tl1mOmuxAIAzzMsCkBEiC0BGiCwAGSGy
AGSEyAKQESILQEaILAAZIbIAZITIApARIgtARl6avPyUF6YCiCP0I/58q1Wq1BsVkuqdf5lJWusX
NWo0i11M4AiFKlUqdP7193fWWkhq9EULNVrELmhIZwavmJ7pInaxHyjV0xvVKo9eY6GFPutG69hF
Bx6pvp7PxdFrzL6ez7kxeMG9tGm9TGPv5z09jbQ02IfbZa5Bi6ADfCrU11gri/N5on6LoItvarCP
2UZWpZFV5e7uUT/27uDE9TR2dDZvNH4wKJKuk4msvtGuPr2sNMzq+oSuKDSw6insX5ZZXIZPIrL6
HqqX2EIchYbO+go5xlbnI8tnXBFbCMtvXOURW52OrFpz79V7V8292HWJjusHiKu7T2wVe3cP6Gxk
FQ6HJo/fyzJ2faKjKg9jsc8toyT7Dh2NrDrg9ej+skq6SY1cDaKczRstE2xrtY+sZfqRNYpUwdtl
nOS1CbkqIrSv7i+D2AfggfZHY5p2ZBUBx68OLfMEr03IUxWpv3B/mSR1ETaIrJSf5FAl0ZStNGUw
Hg70NU8gLnoJD8YfKdVWVi+BK9Ld0o9dTcjcMPo5fLeskgmtpUn6pBlZ/ejV+nAZxK5dZGwc/fx9
GFp17EMiyTB9Uoys9AJro43GsesXmUotsLZLP/ZhUWciK83A2mijUewaRobSDKyNNgmM0XYislL4
VuXw0o9dx8hMuhfg+GNatUn6vFATu053lJom8K3KYWNCCy30kh5OKHL89tAksvztZJHYrJF9RvlV
MiKpkg4saXsrXBFt65XJSibzsvztYg5xUCTeDkQq4sbBsSpNom27MFinMZtKarKp5/Uz6XQVESsZ
+cjhAixJtYaRtnxusM4Xs8iqPBS/zOj7uHiVjFz0MrkAS9LHSLO0KpOVXhi9UMhoU8/IoRF952Mm
V1DEUSQ/irUrxqevNNrm+oV+NVjNpEH3tH4is3GPl9cpibDSfDrVYaU+Bt9mbbTW4kw9g5GZtb53
WvhCS6dV3EhSPbv/V7NacvzQviu6h9irdjx3sZGKdbW4+4t1sajk+ny+CPxK4onRVNa3UpXAvNmR
o6lxSy2ryWAwqfbOQSuH/WqipbPnx694bin2mrs7n+vxsD8t9/3jpBoMnJ7P88BHaWVUSkkm8983
Tr8zK91Ubzkd9Y75j+O6f+Womukc4jE3892X9XhcH/MfR7167Oh87id/lL7G6txo5dJZ4ccu4uq4
6v2250U9dnJ9qgJWMvJgf1Yt6/H+ltWhZVKVUxfbDTgCNzUq4ddGwshmZWu2baxlMT+udfV4cXB1
iv8aWqTFto21LKft4urbqVg6iK1hoKNkNhz1rR1oepBrJ4Uf21Vwb2Sz+qQq5pbVXAWqZOTB7mxa
DvtWiWP7ps9VoKM0NSxfuV29MFzdxWNh7dpYS9P21U7mTKyqmfEs3LFpYy2LuVn7audDWVhehPsB
jpLJExw22mh59yPm0T6uFo+XLebzwj6wNtrIcji+DFDJyMPUPLCqiZuz2fIivLQ/CM8ojEt3L2/M
36w2sCz+Kn5gWYfW0HslIw/mfQaHgWUdWrXnozQxLlnPxaG2a0j20ggsy9Dyf11CHkZpBJZlaPkd
6hgb79KDcba5xdExv515YlrF9n1+p6HVC/BxQPpWZudPMXd/Nm9UmX66Nh6nOpgH1qMotftqdmo0
nmM67L9sNwfr+KWcujmYOEmGfQb3PYbtMjcfM+p7OT6l5Zuyq90fV1g+cX1lMKJjFpPLeuwnsDaa
loaVHOqrYaRsbHY+201reGoxnvLg43lwA8uE2XM70cj6CC01bNWkNKpiP43o22UwMKzkOs6nBAkx
OnPcj2LdX4w7hy4VMv1U3V/6j3+wmzv9NppocOT0ypXBT/d4TdouxdxotXweTwg/zOZzexmVvVuM
+w09J8ek1sDi+8Gd43T3Q8/ubcDt22MWWktafHse1x8eBVnd/oeWs+WFwzLucV1fjg1G5tZGj0pE
dxQG90E0/b+MPT+n6vLq+s8G5/O1fn7y38uDP/P2c145HcS/1PXtb+9HVulkPrtPzfiyP/O9kdfT
po69ozgJzea1/42cLbOf7Nzo3nF6sfMPP8Uu29PKxn9gSR+vEnu3Izqq/5fubMWrD/f/sPu6ik9p
f1h7fw2xlRCxCKh5F+RNTe8maX+qnzXTzf0/7kbWejfPEtP8cB1mQ72b2LuK7ivWvUWI7fQWxTr2
vlq53P3jw5eC3ewmWkrKplqH2dL559j7iu7r/VeoLe2+BSEzj4ZpHr/H8DLVZmT992BbmqV6DNAZ
zXmwx0Oef872fJ49nqL+OLLWeh+7nHsFrOJqnXlTGhkIN2Y6uIm9r4bWDzuF0r7IkhZpjmiFHBYP
12jHaSqb7m7Nmff7Wof7X3D/6W7i1mkqeaAMvCKynvVh/3sVXxz475eBX8P4rLAH/c0i9v6i28r/
Cbm16pfY+9vatT7t/4cXB1d5n9YtKGEji7Es+BW2HZ/d+Xy9bxRr63BkrXWRVmgBOAlPBNZTkUVo
AQjvycB6OrKktd6e+kA8gICeCaznIkuSLnUVey8AnITL5wLrmMiShnqvdex9WVTd3RpOz+JNd7dm
aK2LY/p0x0SWdKO3sSc9rIuQW/tSxt1bdF1TdndrRm70+riMOS6ypEYX+hC3rTUrw20ri6sSMkav
4Z61Phzfkzs2siTpk95GfM5D+bkOt7FZwG3hNIW7BIe82Bu41utD00b3aRNZUqP3uojVRZydB9tS
GWcPcUICXoI/18k+SnmmC1226721i6zbjUSJrXAtn7/2kq1idMbNu+5tqRXDJGkfWbcbe63r0GNb
13WY7SRaxeiUcONLyY1krXWtt6YNH7PIkqRGl/pelwFHt8qffwixmUWRwfcr6ICrfoitfOrF3s8d
N7rUa12a31dzZrrijp7OVRu8x62tZv7W/8OUL6+u/9X7ngAqFqu3/reSyEvuFprps2b2PbOXTopz
+8T4WqVKneupVzPaKH/64PtVlbPy+s9+twBsrYvr2veDK6/6EfsMM0kLfdHi64uYnXDTynrKobfo
VkYvhW+mF3Xjs7gX41nfYLVPaT7JFcEMZXAx9d/O+n6+rgxWu+Te4n02Jks1MVrtyGVca2m0Yi/2
wURktdF5sxwMfJ7P/SvD87mIfTjTNDGr5FHPXxUXc9MVYx9MRLcyO5/nha+zeVoaBtY89qFMVd+s
Joq5ryoeDAyrOMhbf5G4sdlp56/fUE1MPwixD2WqCsMDuvRTycadwo36sQ8lEtAzPZ/7Vz7OZ+NO
4YaJ1IeZXgU8hJZFYK3oFkKSadfQS2hZBFaw94XmyLBr6D605oXxKNZG49iHEYkYm5/PbkPL4gJM
n+EZqxRCa1JZBNYmwBRa5KGyOIschtawbxFYq9gHMXUjm0p2E1pWVySa0bjP5tLnKLQsuoQbbTSM
fQhTV1rVzrKYTyq7Cjb+lvDbGRL7ECIh5kMdG220rCa2Ux7qseX5XMY+hOkbW15ULK5Nk6qcWlZw
0BdoIgN259OmmA/7pisP+8XccvuMyx7Brp210UZLk2qeltbXo41oY+Ehu3bWRhsty+m4bp01tfXl
dyPaWEcaWx/olrHlKK6YJYx9XJxZrWLLUVxtjO76PUmFxfeGO9WsZTUZ9Z4aDZhU/SvrxvPdUsc+
dEiQ2d2Ge8/n3ujp4BrXvZGWjs7nTOYX+n+SwzGM7oI/qCnW9az6RfrDulpIUlN+KdfF7HxRSQ6b
vjd6H/IgIRtThxezRiqb+u/lUnrVlI0kLapfi+b17J+aUi7P5w9tXhqBuaMrU7hlRb8fB5SO+g0h
FwY5WrKZhBdnGcQ+ZEjYIPr52XapYh+y/AyjV1qbhQmkeNo0+jnaZhnGPlx5mkevuGMXOoV4Tk6d
QzqFhvKp5F7sQ4UMmD6MhgtwRlx9Pex3YfYKjpPHYEcv9mHKm/3cYd8Lo1g43jj6+frcMox9iPKX
diXP85huh0QUiY/Qck+hE+mGFr1+tJVyaDHs7kyaobVi7goMpBpa9BicSi+0CCyYSjG0CCzn0got
Ags2UgstAsuLdL49nDOGBWvpXITHBJYvdRKTS6dUMJxI485D5hV6VUZvUFPBcCf2RXjFc3RDiDeH
eMXMYDhWGL9w2H6ZMyIbShWlrTVlBAte9KK0tUYMcIQ1DFrNK56HBY+KwIPxSx76HUO4auYbFfhX
BXqm1or7CGMqvccW3UGEU3uPLbqDCSg18tRJHDM8ieBqT5fhlYZcftNRqO90SH5J9SKiUkNnr6rb
aKO5+rSuUlRqYB1cK42ZyoAkVBpZB9dSo273FdJ4j6GdQj2dq27dSprps2aaxS4+sKP8ej4XrdZa
fz2fF7GL71sXIutWoUqVXqlSceA6s9ZCa/2iRovuVy0yV6pUrVcqVR64HDdq1OiLFlqoiV3cULoU
WY/V337XnE6VoqPuX4gXWscuTiz/DzcdYxK/N+yoAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTEx
LTEwVDEyOjI2OjEwKzAwOjAwShzvSgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0xMS0xMFQxMjoy
NjoxMCswMDowMDtBV/YAAAA3dEVYdGljYzpjb3B5cmlnaHQAQ29weXJpZ2h0IDE5OTkgQWRvYmUg
U3lzdGVtcyBJbmNvcnBvcmF0ZWQxbP9tAAAAIHRFWHRpY2M6ZGVzY3JpcHRpb24AQWRvYmUgUkdC
ICgxOTk4KbC66vYAAAAASUVORK5CYII=`;
