import { useEffect, useState } from 'react';
import { ConnectorData } from '../../../store/system/types';

export type QueryUrl = {
  defaultQuery: string | undefined;
};

type UpdateStateHandler = (value: Partial<ConnectorsPageState>) => void;

interface ConnectorsPageState {
  connectors: ConnectorData[] | null;
  connector: ConnectorData | null;
}

function getSelected(
  connectors: ConnectorData[] | null,
  first: ConnectorData | null,
  previouslySelected: ConnectorData | null,
  queryId?: string
): ConnectorData | null {
  if (queryId && connectors) {
    return connectors.find((connector) => connector.id.toString() === queryId) || null;
  }
  if (!previouslySelected && first) {
    return first;
  }

  return previouslySelected;
}

const useConnectorsPageState = (items: ConnectorData[] | null, queryUrlParams: Record<string, any>) => {
  const [connectorsPageState, setConnectorsPageState] = useState<ConnectorsPageState>({
    connectors: null,
    connector: null,
  });

  useEffect(() => {
    if (items) {
      const copy = [...items];
      const defaultSorted = copy;

      const first = defaultSorted[0];
      setConnectorsPageState((prevState) => ({
        connectors: defaultSorted,
        connector: getSelected(defaultSorted, first, prevState.connector, queryUrlParams.connector),
      }));
    }
  }, [items]);

  const handleUpdatePageState: UpdateStateHandler = (newState) => {
    setConnectorsPageState({ ...connectorsPageState, ...newState });
  };

  return {
    connectorsPageState,
    handleUpdatePageState,
  };
};

export { useConnectorsPageState };
