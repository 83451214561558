import i18n from 'i18n';

import zeroId from 'assets/images/vehicles/0.svg';
import threeId from 'assets/images/vehicles/3.svg';
import fourId from 'assets/images/vehicles/4.svg';
import fiveId from 'assets/images/vehicles/5.svg';
import sixId from 'assets/images/vehicles/6.svg';
import sevenId from 'assets/images/vehicles/7.svg';
import eightId from 'assets/images/vehicles/8.svg';
import nineId from 'assets/images/vehicles/9.svg';
import tenId from 'assets/images/vehicles/10.svg';
import elevenId from 'assets/images/vehicles/11.svg';
import twelveId from 'assets/images/vehicles/12.svg';
import thirteenId from 'assets/images/vehicles/13.svg';
import fourteenId from 'assets/images/vehicles/14.svg';
import fiveteenId from 'assets/images/vehicles/15.svg';
import sixteenId from 'assets/images/vehicles/16.svg';
import seventeenId from 'assets/images/vehicles/17.svg';
import eighteenId from 'assets/images/vehicles/18.svg';
import nineteenId from 'assets/images/vehicles/19.svg';
import twentyId from 'assets/images/vehicles/20.svg';

import zeroBase64Icon from 'assets/images/vehicles/base64/0-base64';
import threeBase64Icon from 'assets/images/vehicles/base64/3-base64';
import fourBase64Icon from 'assets/images/vehicles/base64/4-base64';
import fiveBase64Icon from 'assets/images/vehicles/base64/5-base64';
import sixBase64Icon from 'assets/images/vehicles/base64/6-base64';
import sevenBase64Icon from 'assets/images/vehicles/base64/7-base64';
import eightBase64Icon from 'assets/images/vehicles/base64/8-base64';
import nineBase64Icon from 'assets/images/vehicles/base64/9-base64';
import tenBase64Icon from 'assets/images/vehicles/base64/10-base64';
import elevenBase64Icon from 'assets/images/vehicles/base64/11-base64';
import twelveBase64Icon from 'assets/images/vehicles/base64/12-base64';
import thirteenBase64Icon from 'assets/images/vehicles/base64/13-base64';
import fourteenBase64Icon from 'assets/images/vehicles/base64/14-base64';
import fiveteenBase64Icon from 'assets/images/vehicles/base64/15-base64';
import sixteenBase64Icon from 'assets/images/vehicles/base64/16-base64';
import seventeenBase64Icon from 'assets/images/vehicles/base64/17-base64';
import eighteenBase64Icon from 'assets/images/vehicles/base64/18-base64';
import nineteenBase64Icon from 'assets/images/vehicles/base64/19-base64';
import twentyBase64Icon from 'assets/images/vehicles/base64/20-base64';

type WagaVehicleType = {
  id: number;
  description: string;
  vehicleClasses: string[];
  icon: string;
  base64: string;
};

interface WagaVehiclesTypeList {
  [key: string]: WagaVehicleType;
}

export const WAGA_VEHICLE_TYPE_ID = 3;

const WAGA_VEHICLE_TYPES: WagaVehiclesTypeList = {
  '3': {
    id: 3,
    description: i18n.t('vehicleTypes.type3'),
    vehicleClasses: ['1', '2', '3', '4', '34', '35', '40', '41'],
    icon: threeId,
    base64: threeBase64Icon,
  },
  '4': {
    id: 4,
    description: i18n.t('vehicleTypes.type4'),
    vehicleClasses: ['5', '31'],
    icon: fourId,
    base64: fourBase64Icon,
  },
  '5': {
    id: 5,
    description: i18n.t('vehicleTypes.type5'),
    vehicleClasses: ['6', '7', '32'],
    icon: fiveId,
    base64: fiveBase64Icon,
  },
  '6': {
    id: 6,
    description: i18n.t('vehicleTypes.type6'),
    vehicleClasses: ['8', '9'],
    icon: sixId,
    base64: sixBase64Icon,
  },
  '7': {
    id: 7,
    description: i18n.t('vehicleTypes.type7'),
    vehicleClasses: ['39', '42', '57'],
    icon: sevenId,
    base64: sevenBase64Icon,
  },
  '8': {
    id: 8,
    description: i18n.t('vehicleTypes.type8'),
    vehicleClasses: ['10', '11', '12'],
    icon: eightId,
    base64: eightBase64Icon,
  },
  '9': {
    id: 9,
    description: i18n.t('vehicleTypes.type9'),
    vehicleClasses: ['14', '16', '17', '19', '33', '38'],
    icon: nineId,
    base64: nineBase64Icon,
  },
  '10': {
    id: 10,
    description: i18n.t('vehicleTypes.type10'),
    vehicleClasses: ['13', '55'],
    icon: tenId,
    base64: tenBase64Icon,
  },
  '11': {
    id: 11,
    description: i18n.t('vehicleTypes.type11'),
    vehicleClasses: ['15', '18', '20', '56'],
    icon: elevenId,
    base64: elevenBase64Icon,
  },
  '12': {
    id: 12,
    description: i18n.t('vehicleTypes.type12'),
    vehicleClasses: ['21'],
    icon: twelveId,
    base64: twelveBase64Icon,
  },
  '13': {
    id: 13,
    description: i18n.t('vehicleTypes.type13'),
    vehicleClasses: ['22'],
    icon: thirteenId,
    base64: thirteenBase64Icon,
  },
  '14': {
    id: 14,
    description: i18n.t('vehicleTypes.type14'),
    vehicleClasses: ['23', '44', '45', '46', '52'],
    icon: fourteenId,
    base64: fourteenBase64Icon,
  },
  '15': {
    id: 15,
    description: i18n.t('vehicleTypes.type15'),
    vehicleClasses: ['24', '25'],
    icon: fiveteenId,
    base64: fiveteenBase64Icon,
  },
  '16': {
    id: 16,
    description: i18n.t('vehicleTypes.type16'),
    vehicleClasses: ['26', '47'],
    icon: sixteenId,
    base64: sixteenBase64Icon,
  },
  '17': {
    id: 17,
    description: i18n.t('vehicleTypes.type17'),
    vehicleClasses: ['29', '48', '49', '50', '51', '53', '54'],
    icon: seventeenId,
    base64: seventeenBase64Icon,
  },
  '18': {
    id: 18,
    description: i18n.t('vehicleTypes.type18'),
    vehicleClasses: ['60', '61', '62', '63'],
    icon: eighteenId,
    base64: eighteenBase64Icon,
  },
  '19': {
    id: 19,
    description: i18n.t('vehicleTypes.type19'),
    vehicleClasses: ['27', '28', '36', '37', '43'],
    icon: nineteenId,
    base64: nineteenBase64Icon,
  },
  '20': {
    id: 20,
    description: i18n.t('vehicleTypes.type20'),
    vehicleClasses: ['0', '30', '58', '59'],
    icon: twentyId,
    base64: twentyBase64Icon,
  },
};

export { WAGA_VEHICLE_TYPES };
