import styled from 'styled-components/macro';

import palette from 'palette';

export const StyledError = styled.div`
  color: ${palette.red.r3};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
`;
