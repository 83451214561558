import { useTranslation } from 'react-i18next';

import palette from 'palette';

import { ERROR, VehiclesTypes } from 'pages/Violations/constants/violations';
import { ViolationsType } from 'pages/Violations/types';
import { VehicleTypes } from 'store/platforms/wims/wim/transits/summary/types';

export enum BarItems {
  'count' = 'count',
  'detect' = 'detect',
  'weigt' = 'weigt',
  'load' = 'load',
  'dimensions' = 'dimensions',
  'totalWeight' = 'totalWeight',
  'overaxleload' = 'overaxleload',
  'overgroupload' = 'overgroupload',
}

const ParseViolationEntry = (dataKey: VehicleTypes | ViolationsType = ERROR): string => {
  const { t } = useTranslation();

  switch (dataKey) {
    case VehiclesTypes.empty_truck:
      return t(`violationsPage.detail.empty`);

    case VehiclesTypes.truck:
      return t(`violationsPage.detail.laden`);

    case VehiclesTypes.car:
      return t(`violationsPage.detail.car`);

    default:
      return t(`violationsPage.${dataKey as ViolationsType}` as const);
  }
};

const setBarColor = (key: BarItems) => {
  switch (key) {
    case BarItems.load:
      return palette.red.r1;
    case BarItems.totalWeight:
      return palette.orange.o2;
    case BarItems.overaxleload:
      return palette.green.g5;
    case BarItems.overgroupload:
      return palette.orange.o2;

    default:
      return palette.blue.b5;
  }
};

export default { ParseViolationEntry, setBarColor };
