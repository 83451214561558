import { List } from 'antd';
import styled from 'styled-components/macro';

import palette from 'palette';

interface StyledListRowProps {
  $isSelected?: boolean;
}

export const StyledListRow = styled(List.Item)<StyledListRowProps>`
  display: grid;
  grid-template-columns: minmax(50px, auto) 1fr auto;
  grid-column-gap: 30px;
  margin-bottom: 8px;
  border: ${(props) => (props.$isSelected ? `1px solid ${palette.red.r3}` : `1px solid ${palette.grey.g2}`)};
  box-shadow: ${(props) => (props.$isSelected ? '0 2px 8px 0 rgba(0,0,0,0.09)' : `none`)};
  border-radius: 4px;
  cursor: pointer;
`;

export const StyledIconsBlock = styled.div`
  display: flex;
  column-gap: 16px;

  span {
    width: 18px;
    height: 18px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;
