import { call, put, takeEvery } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import { getConnectorsError, getConnectorsSuccess, requestConnectors } from './slice';
import { ConnectorData } from '../types';
import { getIntegrationsStatus } from '../../../api/system';

export function* connectorsRequestSaga() {
  try {
    const response: AxiosResponse<ConnectorData[]> = yield call(getIntegrationsStatus, {});
    if (response) {
      yield put(getConnectorsSuccess({ connectors: response.data }));
    }
  } catch (error) {
    yield put(getConnectorsError(error.message));
  }
}

export function* connectorsRequestWatchSaga() {
  yield takeEvery(requestConnectors, connectorsRequestSaga);
}
