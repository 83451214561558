import { Form, Input, Checkbox, SubmitButton } from 'formik-antd';
import styled, { css } from 'styled-components/macro';
import palette from 'palette';
import SFUIText from 'fonts/SFUIText-Regular.woff';

export const LoginPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${palette.grey.g4};
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  width: 400px;
  margin-top: 80px;
`;

export const StyledConfirmationForm = styled(Form)`
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  width: 400px;
  margin-top: 20px;
`;

export const AuthorizationWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const StyledButton = styled(SubmitButton)`
  height: 40px;
  width: 400px;
  border-radius: 4px;
  background-color: ${palette.green.g4};
  color: ${palette.white.w1};
  text-align: center;
  border: none;
  :hover {
    background-color: ${palette.green.g4};
  }
  :disabled {
    background-color: ${palette.green.g4};
    color: ${palette.white.w1};
    opacity: 0.5;
  }
`;

const styledInputStyles = css`
  @font-face {
    font-family: 'SFUIText';
    font-size: 14px;
    src: url(${SFUIText}) format('woff');
  }
  font-family: 'SFUIText';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  height: 40px;
  border: 1px solid ${palette.grey.g5};
  border-radius: 4px;
  background-color: ${palette.white.w1};
  color: ${palette.black.b3};
`;

export const StyledInputEmail = styled(Input)`
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  padding: 4px 11px;
  ${styledInputStyles}
`;

export const StyledInputPassword = styled(Input.Password)`
  padding: 0 8px;
  border-radius: 4px;

  input {
    ${styledInputStyles}
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  color: ${palette.black.b3};
  letter-spacing: 0;
  line-height: 22px;
`;

export const StyledErrorMessage = styled.div`
  font-family: 'SF UI Text';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  color: ${palette.red.r3};
`;

export const StyledConfirmation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

export const StyledQRCode = styled.div`
  display: flex;
  width: 200px;
`;

export default AuthorizationWrapper;
