import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetWimLanesData, UpdateWimsData } from '../../../wim/types/platforms';
import { UpdateWimValues } from '../../../../pages/AdminManagement/Wims/components/WimsSummary/modal/UpdateContent/types';

interface InitialState {
  isUpdateLanesLoading: boolean;
  isCreateLanesLoading: boolean;
  isGetLanesLoading: boolean;
  result: any | null;
  error: any;
  getLanesResult: UpdateWimValues | null;
  getLanesError: any;
}

const initialState: InitialState = {
  isUpdateLanesLoading: false,
  isCreateLanesLoading: false,
  isGetLanesLoading: false,
  result: null,
  error: null,
  getLanesResult: null,
  getLanesError: null,
};

const lanes = createSlice({
  name: 'lanes',
  initialState,
  reducers: {
    clearMessages: (state): void => {
      state.error = null;
      state.result = null;
    },
    requestCreateLanes: (state, { payload }: PayloadAction<UpdateWimsData>) => {
      state.isCreateLanesLoading = true;
    },
    requestCreateLanesSuccess: (state, { payload }: PayloadAction<any>): void => {
      state.isCreateLanesLoading = false;
      state.result = payload;
    },
    requestCreateLanesError: (state, { payload }: PayloadAction<string>): void => {
      state.isCreateLanesLoading = false;
      state.error = payload;
    },
    requestUpdateLanes: (state, { payload }: PayloadAction<UpdateWimsData>) => {
      state.isUpdateLanesLoading = true;
    },
    requestUpdateLanesSuccess: (state, { payload }: PayloadAction<any>): void => {
      state.isUpdateLanesLoading = false;
      state.result = payload;
    },
    requestUpdateLanesError: (state, { payload }: PayloadAction<string>): void => {
      state.isUpdateLanesLoading = false;
      state.error = payload;
    },
    requestGetLanes: (state, { payload }: PayloadAction<GetWimLanesData>): void => {
      state.isGetLanesLoading = true;
    },
    requestGetLanesSuccess: (state, { payload }: PayloadAction<UpdateWimValues>): void => {
      state.getLanesResult = payload;
      state.isGetLanesLoading = false;
    },
    requestGetLanesError: (state, { payload }: PayloadAction<string>): void => {
      state.getLanesError = payload;
      state.isGetLanesLoading = false;
    },
  },
});

export const {
  clearMessages,
  requestCreateLanes,
  requestCreateLanesSuccess,
  requestCreateLanesError,
  requestUpdateLanes,
  requestUpdateLanesSuccess,
  requestUpdateLanesError,
  requestGetLanes,
  requestGetLanesSuccess,
  requestGetLanesError,
} = lanes.actions;

export default lanes.reducer;
