import React, { PropsWithChildren } from 'react';
import { Input, Space, Select, Checkbox, Row, Col, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';

import { DatePicker, SliderWithNumericInputs } from 'components';
import { getInputSuffixFormatter } from 'helpers/label.formatter';
import { PLATE_FIELDS } from 'app-constants/plate-fields';
import { DATE_FORMAT, RANGES } from 'app-constants/date';

import { ContentFilterProps } from 'hoc/withFilter';
import { WAGA_VEHICLE_TYPES } from 'app-constants/waga-vehicle-types';
import { OPTION_KEYS_FOR_SELECT } from 'app-constants/select-options';
import { TransitsFilterValues } from 'store/wim/types/transits';
import { VIOLATIONS_TYPES } from 'app-constants/violations';
import {
  IdInputNumber,
  InputContainer,
  SearchIcon,
  StyledSelect,
  StyledSelectVehicleTypeItem,
  StyledTag,
  TooltipContentWrapper,
} from './styles';
import { useTransitsFilter } from './hooks/use-transits-filter';

interface TransitsFilterPanelProps {
  isOnline: boolean;
}

const { Option } = Select;

function TransitsFilterPanel<TransitsType extends TransitsFilterValues>({
  filterManager,
  isOnline,
}: PropsWithChildren<ContentFilterProps<TransitsType> & TransitsFilterPanelProps>): JSX.Element {
  const { t } = useTranslation();

  const {
    state,
    handleFilterChange,
    platforms,
    onPlatformNameChange,
    onRangePickerChange,
    onViolationsTypesChange,
    getOnPlateChange,
    getOnSliderChange,
  } = useTransitsFilter(filterManager);

  const ranges = {
    [t('date.month')]: RANGES.getMonthRange(),
    [t('date.year')]: RANGES.getYearRange(),
    [t('date.quarter')]: RANGES.getQuarterRange(),
  };

  return (
    <Space direction="vertical">
      <Row>
        <Space>
          <Col flex="stretch" style={{ width: 352 }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              {!isOnline && (
                <DatePicker.RangePicker
                  clearIcon={false}
                  value={[dayjs(state.start), dayjs(state.end)]}
                  showTime={{ format: DATE_FORMAT.RANGE_PICKER_TIME }}
                  format={DATE_FORMAT.RANGE_PICKER}
                  ranges={ranges as Record<string, [Dayjs | null, Dayjs | null]>}
                  onChange={onRangePickerChange}
                />
              )}
              <StyledSelect
                maxTagTextLength={20}
                maxTagCount={1}
                allowClear
                value={state.platformUids}
                showSearch
                placeholder={t('filter.allPlatforms')}
                optionFilterProp="children"
                onChange={onPlatformNameChange}
                mode="multiple"
              >
                <Option key={OPTION_KEYS_FOR_SELECT.All} value={OPTION_KEYS_FOR_SELECT.All}>
                  {t('filter.selectAll')}
                </Option>
                {platforms?.map((platform) => (
                  <Option key={platform.uid} value={platform.uid}>
                    {platform.wim}
                  </Option>
                ))}
              </StyledSelect>
            </Space>
          </Col>

          <Col>
            <Space direction="vertical" style={{ width: 280 }}>
              <StyledSelect
                value={state.violationsTypesByOr}
                maxTagCount={1}
                allowClear
                mode="multiple"
                placeholder={t('filter.allOffence')}
                onChange={onViolationsTypesChange}
                showSearch
              >
                <Option key={OPTION_KEYS_FOR_SELECT.All} value={OPTION_KEYS_FOR_SELECT.All}>
                  {t('filter.selectAll')}
                </Option>
                {Object.values(VIOLATIONS_TYPES).map((value) => (
                  <Option key={value.id} value={value.id}>
                    <div>{value.name}</div>
                  </Option>
                ))}
              </StyledSelect>

              <StyledSelect
                mode="multiple"
                maxTagCount={20}
                value={state.vehicleClasses}
                onChange={(value) => handleFilterChange('vehicleClasses', value)}
                placeholder={t('filter.allVehicleTypes')}
                tagRender={(props) => (
                  <StyledTag closable={props.closable} onClose={props.onClose}>
                    <img
                      src={WAGA_VEHICLE_TYPES[props.value as string].icon}
                      alt={WAGA_VEHICLE_TYPES[props.value as string].description}
                      width={40}
                      height={20}
                    />
                  </StyledTag>
                )}
                allowClear
              >
                {Object.keys(WAGA_VEHICLE_TYPES).map((wagaId) => (
                  <Option
                    key={`vehicleType-${WAGA_VEHICLE_TYPES[wagaId].description}`}
                    value={WAGA_VEHICLE_TYPES[wagaId].id}
                  >
                    <StyledSelectVehicleTypeItem>
                      <span>{WAGA_VEHICLE_TYPES[wagaId].id}. </span>
                      <img
                        src={WAGA_VEHICLE_TYPES[wagaId].icon}
                        alt={WAGA_VEHICLE_TYPES[wagaId].description}
                        width={50}
                        height={25}
                      />
                      <span>{WAGA_VEHICLE_TYPES[wagaId].description}</span>
                    </StyledSelectVehicleTypeItem>
                  </Option>
                ))}
              </StyledSelect>
            </Space>
          </Col>

          <Col>
            <Space direction="vertical">
              <Tooltip
                placement="bottom"
                title={<TooltipContentWrapper>{t('filter.plateHint')}</TooltipContentWrapper>}
              >
                <Input
                  value={state.plate}
                  onChange={getOnPlateChange(PLATE_FIELDS.Plate)}
                  placeholder={t('filter.plateFront')}
                  allowClear
                  suffix={<SearchOutlined />}
                />
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={<TooltipContentWrapper>{t('filter.plateHint')}</TooltipContentWrapper>}
              >
                <Input
                  // TODO: enable when API will be ready
                  disabled
                  value={state.plateBack}
                  onChange={getOnPlateChange(PLATE_FIELDS.PlateBack)}
                  placeholder={t('filter.plateBack')}
                  allowClear
                  suffix={<SearchOutlined />}
                />
              </Tooltip>
            </Space>
          </Col>

          <Col>
            <Space direction="vertical">
              <Checkbox
                disabled={!!state.plate}
                checked={state.plateRecognized === undefined ? state.plateRecognized : true}
                onChange={(e) => handleFilterChange('plateRecognized', e.target.checked ? false : undefined)}
              >
                {t('filter.plateNotRecognized')}
              </Checkbox>
              <Checkbox
                // TODO: enable when API will be ready
                disabled
                checked={state.plateBackRecognized === undefined ? state.plateBackRecognized : true}
                onChange={(e) => handleFilterChange('plateBackRecognized', e.target.checked ? false : undefined)}
              >
                {t('filter.plateNotRecognized')}
              </Checkbox>
            </Space>
          </Col>
        </Space>
      </Row>

      <Row>
        <Space>
          <InputContainer>
            <IdInputNumber
              value={state.id}
              stringMode
              maxLength={20}
              onChange={(value) => {
                handleFilterChange('id', value);
              }}
              placeholder={t('filter.factId')}
            />
            <SearchIcon />
          </InputContainer>
          <Checkbox
            checked={state.vendorErrorsPresence}
            onChange={(e) => handleFilterChange('vendorErrorsPresence', e.target.checked)}
          >
            {t('filter.errorMeasure')}
          </Checkbox>
        </Space>
      </Row>

      <Row>
        <Space>
          <SliderWithNumericInputs
            title={t('filter.axles')}
            max={10}
            startValue={state.axleCountFrom}
            endValue={state.axleCountTo}
            onStartChange={(value) => handleFilterChange('axleCountFrom', value)}
            onEndChange={(value) => handleFilterChange('axleCountTo', value)}
            onSliderChange={getOnSliderChange('axleCountFrom', 'axleCountTo')}
          />
          <SliderWithNumericInputs
            title={t('filter.weight')}
            max={81000}
            startValue={state.weightFrom}
            endValue={state.weightTo}
            onStartChange={(value) => handleFilterChange('weightFrom', value)}
            onEndChange={(value) => handleFilterChange('weightTo', value)}
            onSliderChange={getOnSliderChange('weightFrom', 'weightTo')}
            formatter={getInputSuffixFormatter(t('glossary.measureKilogram'))}
          />
          <SliderWithNumericInputs
            title={t('filter.length')}
            max={40}
            startValue={state.lengthFrom}
            endValue={state.lengthTo}
            onStartChange={(value) => handleFilterChange('lengthFrom', value)}
            onEndChange={(value) => handleFilterChange('lengthTo', value)}
            onSliderChange={getOnSliderChange('lengthFrom', 'lengthTo')}
            formatter={getInputSuffixFormatter(t('glossary.measureMeter'))}
          />
          <SliderWithNumericInputs
            title={t('filter.width')}
            max={10}
            startValue={state.widthFrom}
            endValue={state.widthTo}
            onStartChange={(value) => handleFilterChange('widthFrom', value)}
            onEndChange={(value) => handleFilterChange('widthTo', value)}
            onSliderChange={getOnSliderChange('widthFrom', 'widthTo')}
            formatter={getInputSuffixFormatter(t('glossary.measureMeter'))}
          />
          <SliderWithNumericInputs
            title={t('filter.height')}
            max={10}
            startValue={state.heightFrom}
            endValue={state.heightTo}
            onStartChange={(value) => handleFilterChange('heightFrom', value)}
            onEndChange={(value) => handleFilterChange('heightTo', value)}
            onSliderChange={getOnSliderChange('heightFrom', 'heightTo')}
            formatter={getInputSuffixFormatter(t('glossary.measureMeter'))}
          />
        </Space>
      </Row>
    </Space>
  );
}

export default TransitsFilterPanel;
