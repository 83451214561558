import dayjs from 'dayjs';

import { DATE_FORMAT } from 'app-constants/date';
import { TransitViolationsByWimType } from 'pages/Transits/TransitsMap/components/TransitsDetailsByWimModal';
import { LaneTransit } from 'store/wim/types/transits';
import { DateRangeStep } from 'types/date-period';

export type ViolationsForChart = {
  [laneUid: string]: TransitViolationsByWimType;
};

export const setDateRange = (start?: number, end?: number) => {
  if (start && end) {
    if (
      dayjs(Math.min(start)).startOf(DateRangeStep.DAY).valueOf() ===
      dayjs(Math.max(end)).startOf(DateRangeStep.DAY).valueOf()
    ) {
      return { format: DATE_FORMAT.RANGE_PICKER_TIME, step: DateRangeStep.HOUR };
    }
  }

  return { format: DATE_FORMAT.DAY_WITH_MONTH, step: DateRangeStep.DAY };
};

export const parseByChart = (lane: LaneTransit, step: DateRangeStep, start?: number, end?: number) => {
  const datesForChartByPeriod: number[] = [];

  if (start && end && step) {
    const currentStep = Math.abs(dayjs(start).startOf(step).diff(dayjs(start).endOf(step)));
    let currentDate = start;

    while (currentDate <= end + currentStep) {
      datesForChartByPeriod.push(dayjs(currentDate).startOf(step).valueOf());
      currentDate += currentStep;
    }
  }

  const newViolations = datesForChartByPeriod.reduce<Array<LaneTransitViolationsByDate>>((accum, date) => {
    const currentViolation = lane.violations
      .filter((violation) => dayjs(violation.date).startOf(step).valueOf() === date)
      .reduce<ViolationsCounter>(
        (counter, transit) => {
          return {
            ...counter,
            axle: counter.axle + transit.axle,
            both: counter.both + transit.both,
            full: counter.full + transit.full,
          };
        },
        { date, axle: 0, full: 0, both: 0 }
      );
    const currentTransit = lane.transits.filter((transit) => dayjs(transit.date).startOf(step).valueOf() === date);

    const transits = {
      date: currentViolation?.date || date,
      total: currentTransit.length || null,
      axle: currentViolation?.axle || 0,
      full: currentViolation?.full || 0,
      both: currentViolation?.both || 0,
    };

    accum.push({ ...transits });

    return accum;
  }, []);

  return newViolations;
};

export type ViolationsCounter = {
  date: number;
  axle: number;
  full: number;
  both: number;
};

export type LaneTransitViolationsByDate = {
  date: number;
  axle: number;
  full: number;
  both: number;
  total: number | null;
};
