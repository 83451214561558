import dayjs from 'dayjs';
import i18n from 'i18n';
import { Content } from 'pdfmake/interfaces';

import { CHARS } from 'app-constants/chars';
import { DATE_FORMAT } from 'app-constants/date';
import { WimMeasurementFlagType, WimTransit, WimViolationType } from 'store/wim/types';
import palette from 'palette';
import { separateByThousands } from 'helpers/number.formatter';
import { getVehicleClassIconById } from 'helpers/get-vehicle-class-icon';
import { getAxleInfo } from 'pages/Transits/helpers/get-axles-info';
import { violationsTypeTitle } from 'app-constants/violations';
import { measurementFlagsTypeTitle } from 'app-constants/measurement-flags';

import { cLogoSvg } from '../consts/pdf-icons';

export const getDocumentHeaderSchema = (transit: WimTransit, objectName = ``, fullLocation = ``) =>
  [
    {
      margin: [48, 10, 10, 10],
      columns: [
        {
          svg: cLogoSvg,
          width: 28,
          height: 28,
        },
        {
          margin: [0, 8, 66, 0],
          alignment: 'center',
          text: i18n.t('transitDocument.weighingResults'),
          fontSize: 14,
          bold: true,
        },
      ],
    },
    {
      columns: [
        {
          margin: [15, 2],
          style: 'table',
          table: {
            widths: ['auto', 180],
            layout: 'lightHorizontalLines',
            body: [
              [
                {
                  text: `${i18n.t('transitDocument.date')}`,
                  alignment: 'right',
                  border: [false, false, false, false],
                },
                {
                  text: dayjs(transit.datetime).format(DATE_FORMAT.TABLE_FORMAT),
                  alignment: 'center',
                  bold: true,
                },
              ],
            ],
          },
        },
        {
          width: 'auto',
          margin: [15, 2],
          style: 'table',
          table: {
            widths: ['auto', 80],
            body: [
              [
                {
                  text: `${i18n.t('transitDocument.object')}`,
                  border: [false, false, false, false],
                  alignment: 'right',
                },
                { text: objectName, alignment: 'center', bold: true },
              ],
            ],
          },
        },
      ],
    },
    {
      margin: [2, 2, 2, 2],
      table: {
        widths: [80, '*'],
        body: [
          [
            {
              text: `${i18n.t('transitDocument.location')}`,
              border: [false, false, false, false],
              alignment: 'right',
            },
            { text: fullLocation, alignment: 'left', bold: true },
          ],
        ],
      },
    },
  ] as Content;

export const getDocumentFooterSchema = ({
  currentPage,
  pageCount,
  reportDate,
}: {
  currentPage: number;
  pageCount: number;
  reportDate: number;
}) =>
  ({
    margin: [15, 0],
    table: {
      widths: ['auto', '*'],
      body: [
        [
          {
            style: 'footer',
            border: [false, true],
            alignment: 'left',
            text: `${i18n.t('transitDocument.page')}: ${currentPage.toString()} ${i18n.t(
              'transitDocument.of'
            )} ${pageCount}`,
          },
          {
            style: 'footer',
            border: [false, true],
            alignment: 'right',
            text: `${i18n.t('transitDocument.reportDate')}: ${dayjs(reportDate).format(DATE_FORMAT.FULLTIME)}`,
          },
        ],
      ],
    },
  } as Content);

export const getDocumentGeneralInfoSchema = (transit: WimTransit, transitCords: number[] | undefined) =>
  ({
    style: 'table',
    table: {
      widths: ['*', '*'],
      body: [
        [
          {
            text: `1. ${i18n.t('transitDocument.generalInformation')}`,
            style: 'sectionHeader',
            colSpan: 2,
          },
          {},
        ],
        [
          { text: i18n.t('transitDocument.recordNumber'), style: 'greyTableCell' },
          { text: `${transit.id}`, style: 'greyTableCell', bold: true },
        ],
        [{ text: i18n.t('transitDocument.numberPlate') }, { text: `${transit.plate}`, bold: true }],
        [
          { text: i18n.t('transitDocument.countryOfOrigin'), style: 'greyTableCell' },
          { text: `${transit.countryPlate}`, style: 'greyTableCell', bold: true },
        ],
        [{ text: i18n.t('transitDocument.axles') }, { text: `${transit.axlesCount}`, bold: true }],
        [
          { text: i18n.t('transitDocument.vehicleClass'), style: 'greyTableCell' },
          { text: `${transit.vehicleClass}`, style: 'greyTableCell', bold: true },
        ],
        [
          { text: i18n.t('transitDocument.vehiclePicture') },
          {
            image: getVehicleClassIconById(transit.vehicleClass, 'base64'),
            fit: [250, 15],
          },
        ],
        [
          { text: i18n.t('transitDocument.speed'), style: 'greyTableCell' },
          { text: `${transit.speed}`, style: 'greyTableCell', bold: true },
        ],
        [{ text: i18n.t('transitDocument.lane') }, { text: `${transit.lane}`, bold: true }],
        [
          { text: i18n.t('transitDocument.movingDirection'), style: 'greyTableCell' },
          { text: `${transit.direction}`, style: 'greyTableCell', bold: true },
        ],
        [{ text: i18n.t('transitDocument.pavementTemperature') }, { text: `${transit.roadTemperature}`, bold: true }],
        [
          { text: 'GPS', style: 'greyTableCell' },
          { text: `${transitCords ? transitCords[0] : -1.0} / ${transitCords ? transitCords[1] : -1.0}`, bold: true },
        ],
      ],
    },
  } as Content);

export const getDocumentRegisteredViolationsSchema = (transit: WimTransit) =>
  ({
    pageBreak: 'before',
    style: 'table',
    table: {
      widths: ['*'],
      margin: [10, 5, 10, 15],

      body: [
        [
          {
            text: `8. ${i18n.t('transitDocument.registeredViolations')}`,
            style: 'sectionHeader',
            fillColor: palette.black.b3,
            colSpan: 1,
          },
        ],
        [
          {
            ul: [
              ...(transit.violations.length
                ? transit.violations
                    .filter((violation) => violation.type !== '')
                    .map((violation) => {
                      return violationsTypeTitle[violation.type as WimViolationType];
                    })
                : [i18n.t('transitDocument.notRecorded')]),
            ],
          },
        ],
      ],
    },
  } as Content);

export const getDocumentIncorrectMeasurementsSchema = (transit: WimTransit) =>
  ({
    style: 'table',
    table: {
      widths: ['*'],
      margin: [10, 5, 10, 15],

      body: [
        [
          {
            text: `9. ${i18n.t('transitDocument.incorrectMeasurement')}`,
            style: 'sectionHeader',
            colSpan: 1,
          },
        ],
        [
          {
            ul: [
              ...(transit.flags.length
                ? transit.flags
                    .filter((flag) => flag.type !== '')
                    .map((flag) => {
                      return measurementFlagsTypeTitle[flag.type as WimMeasurementFlagType];
                    })
                : [i18n.t('transitDocument.notRecorded')]),
            ],
          },
        ],
      ],
    },
  } as Content);

export const getDocumentVehicleDimensionsSchema = (transit: WimTransit) =>
  ({
    style: 'table',
    table: {
      widths: ['*', '*', '*', '*'],
      margin: [10, 5, 10, 15],

      body: [
        [
          {
            text: `3. ${i18n.t('transitDocument.vehicleDimensions')}`,
            style: 'sectionHeader',
            colSpan: 4,
          },
          {},
          {},
          {},
        ],
        [
          { text: i18n.t('transitPage.sizesTable.columns.param'), style: 'darkGreyTableCell' },
          { text: i18n.t('transitPage.sizesTable.columns.value'), style: 'darkGreyTableCell' },
          { text: i18n.t('transitPage.sizesTable.columns.limit'), style: 'darkGreyTableCell' },
          { text: i18n.t('transitPage.sizesTable.columns.oversize'), style: 'darkGreyTableCell' },
        ],
        [
          i18n.t('transitPage.sizesTable.items.weight'),
          { text: transit.size.weight.value, bold: true },
          { text: transit.size.weight.limit > 0 ? transit.size.weight.limit : CHARS.mdash, bold: true },
          { text: i18n.t('glossary.not'), bold: true },
        ],
        [
          i18n.t('transitPage.sizesTable.items.length'),
          { text: transit.size.length.value, bold: true },
          { text: transit.size.length.limit > 0 ? transit.size.length.limit : CHARS.mdash, bold: true },
          { text: i18n.t('glossary.not'), bold: true },
        ],
        [
          { text: i18n.t('transitPage.sizesTable.items.width'), style: 'greyTableCell' },
          { text: transit.size.width.value, style: 'greyTableCell', bold: true },
          {
            text: transit.size.width.limit > 0 ? transit.size.width.limit : CHARS.mdash,
            style: 'greyTableCell',
            bold: true,
          },
          { text: i18n.t('glossary.not'), style: 'greyTableCell', bold: true },
        ],
        [
          i18n.t('transitPage.sizesTable.items.height'),
          { text: transit.size.height.value, bold: true },
          { text: transit.size.height.limit > 0 ? transit.size.height.limit : CHARS.mdash, bold: true },
          { text: i18n.t('glossary.not'), bold: true },
        ],
      ],
    },
  } as Content);

export const getDocumentVehicleWeightSchema = (transit: WimTransit) => {
  const axlesInfo = getAxleInfo(transit.axles, transit.axlesGroups);

  const axlesInfoTableData = axlesInfo
    ? axlesInfo.map(
        ({
          number,
          groupIndex,
          wheelbase,
          weight,
          weightLimit,
          totalWeightErrorPercent,
          totalWeightWithError,
          overweightPercent,
        }) => {
          let index;
          if (number < 0) {
            index = `${groupIndex} ${i18n.t('transitPage.axlesTable.items.group')}`;
          } else if (groupIndex) {
            index = `${number + 1} ${i18n.t('transitPage.axlesTable.items.axle')} (${groupIndex} ${i18n.t(
              'transitPage.axlesTable.items.group'
            )})`;
          } else {
            index = `${number + 1} ${i18n.t('transitPage.axlesTable.items.axle')}`;
          }

          return [
            { text: index },
            { text: wheelbase > 0 ? wheelbase.toString() : CHARS.mdash, bold: true },
            { text: weight > 0 ? separateByThousands(weight) : CHARS.mdash, bold: true },
            { text: weightLimit > 0 ? separateByThousands(+weightLimit.toFixed(2)) : CHARS.mdash, bold: true },
            { text: totalWeightErrorPercent > 0 ? totalWeightErrorPercent.toFixed(2) : CHARS.mdash, bold: true },
            { text: separateByThousands(+totalWeightWithError.toFixed(2)), bold: true },
            {
              text: overweightPercent && overweightPercent > 0 ? overweightPercent.toFixed(2) : i18n.t('glossary.not'),
              bold: true,
            },
          ];
        }
      )
    : [];

  return {
    style: 'table',
    table: {
      widths: ['*', '*', '*', '*', '*', '*', '*'],
      margin: [10, 5, 10, 15],

      body: [
        [
          {
            text: `4. ${i18n.t('transitDocument.vehicleWeight')}`,
            style: 'sectionHeader',
            colSpan: 7,
          },
          {},
          {},
          {},
          {},
          {},
          {},
        ],
        [
          { text: i18n.t('transitPage.axlesTable.columns.axleIndex'), style: 'darkGreyTableCell' },
          { text: i18n.t('transitPage.axlesTable.columns.interaxleSpace'), style: 'darkGreyTableCell' },
          { text: i18n.t('transitPage.axlesTable.columns.totalWeight'), style: 'darkGreyTableCell' },
          { text: i18n.t('transitPage.axlesTable.columns.weightLimit'), style: 'darkGreyTableCell' },
          { text: i18n.t('transitPage.axlesTable.columns.totalWeightErrorPercent'), style: 'darkGreyTableCell' },
          { text: i18n.t('transitPage.axlesTable.columns.totalWeightWithError'), style: 'darkGreyTableCell' },
          { text: i18n.t('transitPage.axlesTable.columns.overweightPercent'), style: 'darkGreyTableCell' },
        ],
        ...(transit.axles.length > 0 ? axlesInfoTableData : [[i18n.t('common.noData'), '', '', '', '', '', '']]),
      ],
    },
  } as Content;
};

export const getDocumentPictureSchema = (title: string, image?: string, pageBreakType?: string) =>
  ({
    pageBreak: pageBreakType,
    style: 'table',
    table: {
      widths: ['*'],
      margin: [10, 5, 10, 15],
      dontBreakRows: true,

      body: [
        [
          {
            text: title,
            style: 'sectionHeader',
            colSpan: 1,
          },
        ],
        [image ? { image, width: 555 } : { text: i18n.t('transitDocument.noPhoto') }],
      ],
    },
  } as Content);
