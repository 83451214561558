import React from 'react';

import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { DatePeriodType } from 'app-constants/date';

import { getIntegerPercent } from 'helpers/percent.formatter';
import { parseByPeriodStatisticData } from './helpers/get-data-by-period';
import { setDatePeriod } from './helpers/set-date-period';
import TooltipWithWimStatistics from './Tooltips/TooltipWithWimStatistics';
import { StyledBarChart } from './styles';
import palette from '../../palette';

export interface PlatformStatisticsBarCharProps {
  data: StatisticDataType[];
  syncId: string;
  unit?: string;
  lineKey?: string;
  datePeriod?: DatePeriodType;
  start: number;
  end: number;
}

export type StatisticDataType = {
  date: number;
  totalTraffic?: number;
  weight?: number;
  overload?: number;
  dimensions?: number;
  detect?: number;
  totalWeight?: number;
  overaxleload?: number;
  overgroupload?: number;
  truckTraffic?: number;
  datePeriod?: DatePeriodType;
  count?: number;
  trucks?: number;
};

export const PlateRecognitionStatisticsBarChart: React.FC<PlatformStatisticsBarCharProps> = ({
  data,
  syncId = '',
  unit,
  datePeriod,
  start,
  end,
}) => {
  const sortedData = [...data].sort((a, b) => a.date - b.date);

  const criticalValue = 0.6;
  const permissibleValue = 0.85;

  const coloredData = sortedData.map((item) => {
    let barColor = palette.yellow.y1;
    if (item?.detect && item.detect < criticalValue) {
      barColor = palette.red.r1;
    }
    if (item?.detect && item.detect > permissibleValue) {
      barColor = palette.green.g1;
    }

    return { ...item, color: barColor };
  });

  const percentFormatter = (val: number) => {
    if (unit === '%') {
      return getIntegerPercent(val).toString();
    }
    return val.toString();
  };

  return (
    <StyledBarChart>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={250}
          height={150}
          data={parseByPeriodStatisticData(coloredData, start, end, datePeriod)}
          margin={{ top: 10, right: 10 }}
          syncId={syncId}
          barCategoryGap="10%"
        >
          <CartesianGrid strokeDasharray="1" vertical={false} />
          <XAxis dataKey="date" minTickGap={12} tickFormatter={(value) => setDatePeriod(value, datePeriod)} />
          <YAxis unit={unit} axisLine={false} tickLine={false} tickFormatter={percentFormatter} />
          <Tooltip content={<TooltipWithWimStatistics unit={unit} />} formatter={percentFormatter} />
          <Bar dataKey="detect">
            {coloredData.map((item, key) => (
              <Cell cursor="pointer" fill={item.color} key={key.toString()} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </StyledBarChart>
  );
};
