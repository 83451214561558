import styled from 'styled-components/macro';
import { Card } from 'antd';

import palette from 'palette';

export const StyledHomePage = styled.div`
  padding: 24px;
  display: flex;
`;

export const StyledHomeContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 43px 24px;
  background: ${palette.white.w1};
  font-size: 14px;
`;

export const StyledCard = styled(Card)`
  width: 100%;

  .ant-card-head {
    border-bottom: none;
  }
  .ant-card-head-title {
    display: flex;
    flex-direction: column;
    padding: 5px 0 0 0;
  }
  .ant-card-body {
    display: flex;
    flex-direction: column;
    padding: 5px 24px;
    &-value {
      color: ${palette.black.b4};
      font-size: 30px;
      letter-spacing: 0;
      line-height: 38px;
    }

    &-disparity {
      color: ${palette.green.g5};
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 19px;
    }
  }

  .ant-card-footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid ${palette.grey.g3};
    padding: 8px 0 0 0;
  }
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
`;

export const StyledCheckbox = styled.div`
  display: flex;
  width: 100%;
  margin-right: 30px;
  gap: 10px;
  align-items: center;
`;
