import { Select } from 'antd';
import { Field } from 'formik';
import palette from 'palette';
import styled from 'styled-components/macro';

export const StyledContentForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const StyledField = styled(Field)`
  cursor: pointer;
  padding: 5px 12px;
  border: none;
  position: relative;

  :hover {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  [data-theme='dark'] :hover {
    border: 1px solid #434343;
  }
`;

export const StyledError = styled.div`
  color: ${palette.red.r3};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
`;

export const StyledEditBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 24px;
  font-size: 16px;
  row-gap: 4px;
  width: 70%;
`;

export const StyledSelectWithLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
`;

export const StyledButtons = styled.div`
  display: flex;
  column-gap: 15px;
  align-self: flex-end;
`;
