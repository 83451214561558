import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type VrpDetectStatisticType = {
  amountOfNotRecognized: number;
  amountOfRecognized: number;
  date: number;
};

interface VrpDetectStatisticState {
  data: VrpDetectStatisticType[];
  isLoading: boolean;
  error: Error | null;
}

const initialState: VrpDetectStatisticState = {
  data: [],
  isLoading: false,
  error: null,
};

const vrpDetectStatistic = createSlice({
  name: 'vrpDetectStatistic',
  initialState,
  reducers: {
    requestVrpDetectStatistic: (state, _payload): void => {
      state.isLoading = true;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>): void => {
      state.isLoading = payload;
    },
    getVrpDetectStatisticSuccess: (state, { payload }): void => {
      state.isLoading = false;
      state.data = payload;
    },
    getVrpDetectStatisticError: (state, { payload }): void => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { requestVrpDetectStatistic, getVrpDetectStatisticSuccess, getVrpDetectStatisticError, setIsLoading } =
  vrpDetectStatistic.actions;

export default vrpDetectStatistic.reducer;
