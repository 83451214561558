import React from 'react';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT } from 'app-constants/date';

import {
  NewViolationsForChart,
  LaneViolationsWithDirection,
} from 'components/BarChart/helpers/get-bars-from-transits-by-wim';
import { StyledLabel, StyledTooltip, StyledTooltipTitle, StyledValue } from './styles';
import { StyledDescription } from '../WimErrorTooltip/styles';
import { StyledMarker, StyledRectangle } from '../PlateRecognitionTooltip/styles';
import palette from '../../../../palette';

interface TooltipPayloadProps {
  chartType: string;
  color: string;
  dataKey: string;
  formatter: () => void;
  name: string;
  payload: NewViolationsForChart;
  type: string;
  unit: string;
  value: LaneViolationsWithDirection;
}

interface TooltipProps {
  active: boolean;
  payload: TooltipPayloadProps[];
}

interface LaneTransitViolationsPayload {
  total: number;
  color: string;
}

export type LaneTransitViolations = {
  axle: LaneTransitViolationsPayload | null;
  full: LaneTransitViolationsPayload | null;
  both: LaneTransitViolationsPayload | null;
  total: LaneTransitViolationsPayload | null;
};

export const BarChartTransitsViolationsByWimTooltip = (props: any): JSX.Element | null => {
  const { t } = useTranslation();
  const { active, payload } = props as TooltipProps;

  if (!active || !payload) {
    return null;
  }

  const date = +payload[0].payload.date;

  const groupPayloadByDirection = payload.reduce<LaneTransitViolations>(
    (acc, currentPayload) => {
      const groupedPayloadByDirection = {
        [currentPayload.name]: {
          total: currentPayload.payload[currentPayload.name],
          color: currentPayload.color,
        },
      };

      return { ...acc, ...groupedPayloadByDirection };
    },
    { axle: null, both: null, full: null, total: null }
  );

  return (
    <StyledTooltip className="custom-tooltip">
      <>
        <StyledTooltipTitle className="label">{`${t('transitsMapPage.chart.transits')}`}</StyledTooltipTitle>
        <StyledLabel className="label">
          <StyledMarker color={groupPayloadByDirection.total?.color || palette.red.r1}>
            <StyledRectangle fill={groupPayloadByDirection.total?.color || palette.red.r1} />
          </StyledMarker>
          {`${t('transitsMapPage.chart.total')}: `}
          <StyledValue className="label">{groupPayloadByDirection.total?.total || 0}</StyledValue>
        </StyledLabel>

        <StyledTooltipTitle className="label">{`${t('transitsMapPage.chart.violations')}`}</StyledTooltipTitle>

        <StyledLabel className="label">
          <StyledMarker color={groupPayloadByDirection.axle?.color || 'blue'}>
            <StyledRectangle fill={groupPayloadByDirection.axle?.color} />
          </StyledMarker>
          {`${t('transitsMapPage.chart.axle')}: `}
          <StyledValue className="label">{groupPayloadByDirection.axle?.total}</StyledValue>
        </StyledLabel>

        <StyledLabel className="label">
          <StyledMarker color={groupPayloadByDirection.both?.color || 'blue'}>
            <StyledRectangle fill={groupPayloadByDirection.both?.color} />
          </StyledMarker>
          {`${t('transitsMapPage.chart.both')}: `}
          <StyledValue className="label">{groupPayloadByDirection.both?.total}</StyledValue>
        </StyledLabel>

        <StyledLabel className="label">
          <StyledMarker color={groupPayloadByDirection.full?.color || 'blue'}>
            <StyledRectangle fill={groupPayloadByDirection.full?.color} />
          </StyledMarker>
          {`${t('transitsMapPage.chart.full')}: `}
          <StyledValue className="label">{groupPayloadByDirection.full?.total}</StyledValue>
        </StyledLabel>
      </>
      <StyledDescription className="desc">{dayjs(date).format(DATE_FORMAT.TABLE_FORMAT)}</StyledDescription>
    </StyledTooltip>
  );
};
