import { all } from 'redux-saga/effects';

import { detectorsRequestWatchSaga } from './detectors/saga';
import wimWatchSaga from './wims/saga';

function* platformsWatchSaga() {
  yield all([detectorsRequestWatchSaga(), wimWatchSaga()]);
}

export default platformsWatchSaga;
