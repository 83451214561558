import palette from 'palette';
import styled from 'styled-components/macro';

export const StyledPlatformListItem = styled.li`
  padding: 10px;
  width: 100%;
  margin: 0px;
  border-top: 1px solid ${palette.grey.g2};
  display: grid;
  grid-template-columns: 30px auto auto 40px;
  :first-child {
    border: none;
  }
`;
export const StyledVehicleClass = styled.span`
  margin: 0px 5px;
  display: grid;
  grid-template-columns: 30px auto;
`;

export const StyledCounter = styled.span`
  flex: 0 0 35px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledLink = styled.span`
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledValue = styled.span`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledRoad = styled.span`
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;
`;
