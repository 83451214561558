import { ColumnsType } from 'antd/lib/table';
import i18n from 'i18n';
import { Certificate } from 'store/admin/certificates/types';
import dayjs from 'dayjs';

export const certificateColumns: ColumnsType<Certificate> = [
  {
    title: i18n.t('common.name'),
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: i18n.t('common.type'),
    key: 'type',
    dataIndex: 'type',
  },
  {
    title: i18n.t('common.createdAt'),
    key: 'createDate',
    dataIndex: 'createDate',
  },
];

export const getDataSource = (data: Certificate[]): Certificate[] => {
  return data.map((item) => {
    return {
      ...item,
      createDate: dayjs(item.createDate).format('DD.MM.YYYY / HH:mm:ss'),
      updateDate: dayjs(item.updateDate).format('DD.MM.YYYY / HH:mm:ss'),
    } as Certificate;
  });
};
