import { DatePicker, Input, InputProps } from 'antd';
import { FieldArray } from 'formik';
import moment from 'moment';

import React, { FC } from 'react';
import { StyledError, StyledFormikInputFieldWithLabelAndDatePicker, StyledLabel } from './styles';
import { DATE_FORMAT } from '../../app-constants/date';

interface FormikInputFieldWithLabelAndDatePickerProps extends InputProps {
  label: string;
  name: string;
  errors?: string;
  touched?: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const FormikDatePickerWithLabel: FC<FormikInputFieldWithLabelAndDatePickerProps> = ({
  label,
  name,
  defaultValue,
  setFieldValue,
  onChange,
  errors,
  touched,
  ...restProps
}) => {
  return (
    <>
      <StyledFormikInputFieldWithLabelAndDatePicker>
        <StyledLabel>{label}</StyledLabel>
        <FieldArray
          name="verificationCertificate"
          render={(arrayHelpers) => (
            <Input.Group>
              <DatePicker
                name={name}
                value={moment(defaultValue as string)}
                style={{ width: '100%' }}
                showTime={{ format: DATE_FORMAT.RANGE_PICKER_TIME }}
                format={DATE_FORMAT.RANGE_PICKER}
                onChange={(date) => {
                  setFieldValue(name, date?.toISOString() || 0);
                }}
              />
            </Input.Group>
          )}
        />
      </StyledFormikInputFieldWithLabelAndDatePicker>
      {errors && touched ? <StyledError>{errors}</StyledError> : null}
    </>
  );
};
