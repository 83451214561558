import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { Select } from 'antd';

import { capitalize } from 'helpers/string.formatter';
import { UserFormValues } from 'pages/AdminManagement/Users/types';
import { SocialNetwork } from 'store/user/types';
import { StyledError } from './styles';

interface SocialNetworkApplicationCellProps {
  label: string;
  index: number;
  dataIndex: string;
}

export const FormikSocialNetworkApplicationCell: FC<SocialNetworkApplicationCellProps> = ({
  index,
  dataIndex,
  label,
}) => {
  return (
    <>
      <Field name={`socialNetworks.${index}.${dataIndex}`}>
        {({ field, form: { setFieldValue } }: FieldProps) => (
          <Select
            onChange={(value) => setFieldValue(`socialNetworks.${index}.${dataIndex}`, value)}
            onBlur={field.onBlur}
            defaultValue={label}
            style={{ width: 120 }}
          >
            {Object.keys(SocialNetwork).map((socialNetwork) => (
              <Select.Option key={`social-network-${socialNetwork}`} value={socialNetwork}>
                {capitalize(socialNetwork)}
              </Select.Option>
            ))}
          </Select>
        )}
      </Field>

      <Field name={`socialNetworks.${index}.${dataIndex}`}>
        {({ meta }: FieldProps<UserFormValues>) => {
          const { error, touched } = meta;

          return touched && error ? <StyledError>{error}</StyledError> : null;
        }}
      </Field>
    </>
  );
};
