import styled from 'styled-components/macro';

export const StyledInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 24px;
  font-size: 16px;
  row-gap: 4px;
`;

export const StyledSocialNetworkApplication = styled.div`
  display: flex;
  align-items: center;
`;
