import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { LaneDirectionType } from 'store/wim/types';
import { StyledError } from './styles';

interface FormikWimLaneTypeCellProps {
  label: string;
  index: number;
  dataIndex: string;
}

enum LaneDirectionTypes {
  DIRECT = 'DIRECT',
  OPPOSITE = 'OPPOSITE',
}

export const FormikWimLaneDirectionTypeCell: FC<FormikWimLaneTypeCellProps> = ({ index, dataIndex, label }) => {
  const { t } = useTranslation();
  return (
    <>
      <Field name={`wims.${index}.${dataIndex}`}>
        {({ field, form: { setFieldValue } }: FieldProps) => (
          <Select
            onChange={(value) => setFieldValue(`wims.${index}.${dataIndex}`, value)}
            onBlur={field.onBlur}
            defaultValue={label}
            style={{ width: 120 }}
          >
            {Object.keys(LaneDirectionTypes).map((laneDirectionType) => (
              <Select.Option key={`wims-${laneDirectionType}`} value={laneDirectionType}>
                {t(`glossary.${laneDirectionType as LaneDirectionType}` as const)}
              </Select.Option>
            ))}
          </Select>
        )}
      </Field>

      <Field name={`wims.${index}.${dataIndex}`}>
        {({ meta }: FieldProps) => {
          const { error, touched } = meta;

          return touched && error ? <StyledError>{error}</StyledError> : null;
        }}
      </Field>
    </>
  );
};
