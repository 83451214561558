import React from 'react';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

import palette from 'palette';
import { NormalizedWimPlatform } from 'store/wim/normalization';
import { ActiveWimPlatformDetail, ActiveWimPlatformDetails } from 'store/wim/types';
import { formatFloatPercent, getSimpleFloatNumber } from './percent.formatter';
import { WimPlatformLane } from './lanes.utility';

export function getPlatformDirectionsWithLanes(platform: NormalizedWimPlatform) {
  const { lanes } = platform;

  return (
    <>
      {lanes
        ? Object.keys(lanes.byDirection).map((direction, i) => (
            <div key={`direction-${direction}`}>
              {i === 0 ? <ArrowLeftOutlined /> : <ArrowRightOutlined />} <span>{direction}</span>
              <div key="lanes" style={{ display: 'flex', flexDirection: 'column' }}>
                {lanes.byDirection[direction]
                  ? lanes.byDirection[direction].lanesInfo.map(({ uid, laneDescription, laneType }) => {
                      return (
                        <span key={`laneUid-${uid}`}>
                          <span>{laneDescription}</span> <strong>{laneType}</strong>
                        </span>
                      );
                    })
                  : null}
              </div>
            </div>
          ))
        : null}
    </>
  );
}

export function getPlatformLanes(
  activeWimPlatformDetails: ActiveWimPlatformDetails,
  propertyKey: keyof ActiveWimPlatformDetail
) {
  return (
    <table>
      {Object.keys(activeWimPlatformDetails).map((wimUid) => {
        return (
          <tr key={`table-row-${propertyKey}-${wimUid}`}>
            <td style={{ border: `1px solid ${palette.grey.g11}`, textAlign: 'center' }}>
              {activeWimPlatformDetails[wimUid].name}
            </td>
            <td style={{ border: `1px solid ${palette.grey.g11}`, textAlign: 'center' }}>
              {Number.parseInt(activeWimPlatformDetails[wimUid][propertyKey], 10)}
            </td>
          </tr>
        );
      })}
    </table>
  );
}

export function getWimPlatformLanesWithFloatNumber(
  activeWimPlatformLanes: WimPlatformLane[],
  isFormattedPercent = false
) {
  return (
    <table>
      {activeWimPlatformLanes.map((lane) => {
        return Object.keys(lane).map((wimUid) => {
          const { name, value } = lane[wimUid];
          return (
            <tr key={`card-uid-error-${wimUid}`}>
              <td style={{ border: `1px solid ${palette.grey.g11}`, textAlign: 'center' }}>{name}</td>
              {!isFormattedPercent ? (
                <td style={{ border: `1px solid ${palette.grey.g11}`, textAlign: 'center' }}>
                  {value ? formatFloatPercent(Number.parseFloat(value)) : '0%'}
                </td>
              ) : (
                <td style={{ border: `1px solid ${palette.grey.g11}`, textAlign: 'center' }}>
                  {value ? `${getSimpleFloatNumber(Number.parseFloat(value))}%` : '0%'}
                </td>
              )}
            </tr>
          );
        });
      })}
    </table>
  );
}
