import styled from 'styled-components/macro';

import PingFang from 'fonts/PingFang-SC-Regular.woff';
import palette from 'palette';

export const GaugeStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

export const GaugeTitle = styled.span`
  color: ${palette.black.b5};
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 22px;
`;

export const GaugeTitleTotal = styled.span`
  color: ${palette.black.b1};
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 38px;
`;

interface PercentProps {
  delta: number | 'N/A';
}

export const GaugeTitlePercent = styled.span<PercentProps>`
  color: ${({ delta }) => (delta > 0 && delta !== 'N/A' ? palette.green.g6 : palette.red.r3)};
  margin: 20px 0;
  font-size: 16px;
  line-height: 24px;
`;

export const StyledTotalWims = styled.div`
  height: 100%;
  font-size: 14px;
  letter-spacing: 0px;
  margin-bottom: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  a {
    color: ${palette.black.b1};
  }
`;

export const StyledSeparator = styled.span`
  font-size: 25px;
  font-weight: 700;
`;

export const StyledWorkedWims = styled.span`
  font-size: 30px;
  font-weight: 600;
  color: ${palette.green.g6};
  :after {
    content: '|';
    color: ${palette.black.b1};
  }
`;

export const StyledAlertWims = styled.span`
  font-size: 30px;
  font-weight: 600;
  color: ${palette.orange.o3};
`;

export const StyledDeflectWims = styled.span`
  font-size: 30px;
  font-weight: 600;
  color: ${palette.red.r1};
  :after {
    content: '|';
    color: ${palette.black.b1};
  }
`;

export const StyledCardWrapper = styled.span`
  width: 300px;
`;

export const StyledFlexBlock = styled.div`
  padding: 3px;
  display: flex;
  align-items: center;
`;

export const StyledLegend = styled.div`
  margin-top: 5px;
`;

export const StyledWimStatuses = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledValue = styled.div`
  @font-face {
    font-family: 'PingFang';
    font-size: 160px;
    src: url(${PingFang}) format('woff');
  }
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
`;
