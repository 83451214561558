import React, { Component, ErrorInfo } from 'react';

import { ErrorResult } from 'components';
import { LocationState } from 'history';

interface ErrorBoundaryProps {
  children?: JSX.Element;
  location?: LocationState;
}

interface ErrorBoundaryState {
  error: Error | string;
  errorInfo: ErrorInfo | ErrorInfoStack | string;
  hasError: boolean;
}

interface ErrorInfoStack {
  componentStack: string;
}

class GlobalErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static getDerivedStateFromError(error: ErrorInfo) {
    return { hasError: true, error };
  }

  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      error: '',
      errorInfo: '',
      hasError: false,
    };
  }

  componentDidUpdate(prevProps: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (prevProps?.location?.pathname !== this.props?.location?.pathname) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfoStack) {
    // eslint-disable-next-line no-console
    this.setState({ errorInfo });
  }

  render() {
    const { hasError, errorInfo, error } = this.state;
    const { children } = this.props;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { componentStack } = errorInfo;
    return hasError ? <ErrorResult message={error.toString()} description={componentStack} /> : children;
  }
}

export default GlobalErrorBoundary;
