import { WimMeasurementFlagType } from 'store/wim/types/transits';
import i18n from '../i18n';

const measurementFlagsTypeTitle: Record<WimMeasurementFlagType, string> = {
  OFF_SCALE: i18n.t('measurementFlags.offScale'),
  NOT_WIM_LANE: i18n.t('measurementFlags.notWimLane'),
  ON_SCALE_MISSED: i18n.t('measurementFlags.onScaleMissed'),
  SPEED_CHANGE: i18n.t('measurementFlags.speedChange'),
  WEIGHT_DIFF: i18n.t('measurementFlags.weightDiff'),
  PARTIAL_AXLE: i18n.t('measurementFlags.partialAxle'),
  UNEQUAL_AXLE: i18n.t('measurementFlags.unequalAxle'),
  TAILGATE: i18n.t('measurementFlags.tailgate'),
  WRONG_LANE: i18n.t('measurementFlags.wrongLane'),
  RUNNING_SCALE: i18n.t('measurementFlags.runningScale'),
  CREDENTIAL: i18n.t('measurementFlags.credential'),
  DRIVER_ISSUE: i18n.t('measurementFlags.driverIssue'),
  HARDWARE_ISSUE: i18n.t('measurementFlags.hardwareIssue'),
  INVALID_MEASUREMENT: i18n.t('measurementFlags.invalidMeasurement'),
  INVALID_HEIGHT: i18n.t('measurementFlags.invalidHeight'),
  INVALID_WIDTH: i18n.t('measurementFlags.invalidWidth'),
  INVALID_LENGTH: i18n.t('measurementFlags.invalidLength'),
  INVALID_SPEED: i18n.t('measurementFlags.invalidSpeed'),
  INVALID_WEIGHT: i18n.t('measurementFlags.invalidWeight'),
  INVALID_AXLES_COUNT: i18n.t('measurementFlags.invalidAxlesCount'),
  INVALID_AXLE_LOAD: i18n.t('measurementFlags.invalidAxleLoad'),
  INVALID_AXLE_1ST_LOAD: i18n.t('measurementFlags.invalidAxle1stLoad'),
  INVALID_AXLE_RATIO_LOAD: i18n.t('measurementFlags.invalidAxleRatioLoad'),
  INVALID_AXLE_1ST_DISTANCE: i18n.t('measurementFlags.invalidAxle1stDistance'),
  INVALID_VRP_VALUE: i18n.t('measurementFlags.invalidVrpValue'),
  LACK_OF_MEDIA: i18n.t('measurementFlags.lackOfMedia'),
};

export { measurementFlagsTypeTitle };
