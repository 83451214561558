import React from 'react';

import parser from 'components/BarChart/helpers/bar-chart-helper';
import { VehicleTypes } from 'store/platforms/wims/wim/transits/summary/types';
import { ViolationsType } from 'pages/Violations/types';
import { StyledLegend, StyledLegendItem, StyledLegendItemMarker } from './styles';

interface ViolationsLegendProps {
  payload: ViolationsLegendPayloadType[];
  width?: string;
  height?: string;
}

type ViolationsLegendPayloadType = {
  fill: string;
  dataKey: ViolationsType | VehicleTypes;
};

export const ViolationsLegend: React.FC<ViolationsLegendProps> = ({ payload, width, height }) => {
  if (!payload) {
    return null;
  }

  return (
    <StyledLegend>
      {payload.map((entry) => {
        const type = parser.ParseViolationEntry(entry.dataKey);
        return (
          <StyledLegendItem key={`item-${entry.dataKey}`}>
            <StyledLegendItemMarker color={entry.fill} dataKey={entry.dataKey} width={width} height={height} />
            <span>{type}</span>
          </StyledLegendItem>
        );
      })}
    </StyledLegend>
  );
};
