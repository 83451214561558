import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { ConfigProvider, Empty, Table, TablePaginationConfig, TableProps } from 'antd';
import { ColumnsType, ColumnType, FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { ResizeCallbackData } from 'react-resizable';
import { useTranslation } from 'react-i18next';

import { ResizableTableTitle } from 'components';
import { getMinColumnWidth } from 'helpers/table.columns';
import { PaginationProps } from 'hooks/use-pagination';
import { StyledTableWrapper, WrappedTableTitle } from './styles';
import { setTableDataUid } from './helper/set-table-data-uid';

export type OnTableChangeAction = <TableRecord>(
  pagination: TablePaginationConfig,
  filters: Record<string, FilterValue | null>,
  sorter: SorterResult<TableRecord> | SorterResult<TableRecord>[],
  extra: TableCurrentDataSource<TableRecord>
) => void;

export interface DefaultTableContainerProps<TableRecord> extends TableProps<TableRecord> {
  pagination?: PaginationProps;
}

export const wrapTableTitle = (title: string) => <WrappedTableTitle>{title}</WrappedTableTitle>;

const DefaultTableContainer = <TableRecord extends Record<string, any>>({
  dataSource,
  columns,
  pagination,
  ...tableProps
}: PropsWithChildren<DefaultTableContainerProps<TableRecord>>): ReactElement => {
  const [tableData, setTableData] = useState({ columns: columns || [] });
  const { t } = useTranslation();

  useEffect(() => {
    if (columns && JSON.stringify(columns) !== JSON.stringify(tableData.columns)) {
      const newTableColumns = columns.map((column, index) => {
        const newColumn = column;
        newColumn.width = tableData.columns[index].width;

        return newColumn;
      });
      setTableData({ columns: newTableColumns });
    }
  }, [columns]);

  const handleResize =
    (index: number) =>
    (e: React.SyntheticEvent<Element, Event>, { size }: ResizeCallbackData) => {
      setTableData((prevTableData) => {
        const nextColumns = prevTableData.columns;
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width >= getMinColumnWidth(columns || []) ? size.width : getMinColumnWidth(columns || []),
        };
        return { columns: nextColumns };
      });
    };

  const newColumns = tableData.columns.map((col, index) => {
    const newColumn = col;

    newColumn.onHeaderCell = (column: ColumnType<TableRecord>) =>
      ({
        width: column.width,
        onResize: handleResize(index),
      } as React.HTMLAttributes<HTMLElement>);

    return newColumn;
  });

  const components: TableProps<TableRecord>['components'] = {
    header: {
      cell: ResizableTableTitle,
    },
  };

  return (
    <ConfigProvider renderEmpty={() => <Empty description={t('common.noData')} image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
      <StyledTableWrapper>
        <Table<TableRecord>
          components={components}
          dataSource={setTableDataUid(dataSource)}
          columns={newColumns as ColumnsType<TableRecord>}
          rowKey={(item) => item.uid}
          locale={{ emptyText: t('common.noData') }}
          pagination={
            pagination
              ? {
                  current: pagination.page,
                  pageSize: pagination.pageSize,
                  onChange: pagination.onChange,
                  showTotal: pagination.showTotal,
                  total: pagination.total,
                  pageSizeOptions: pagination?.pageSizeOptions,
                  locale: { items_per_page: `/ ${t('table.perPage')}` },
                }
              : false
          }
          {...tableProps}
        />
      </StyledTableWrapper>
    </ConfigProvider>
  );
};

export { DefaultTableContainer };
