import { ROUTES } from 'app-constants/routes';
import ua from 'locales/ua/translation.json';
import * as H from 'history';
import { stringifyUrlParams } from 'hooks/use-url-params';
import { WimGridViewParams, WimsListSort } from 'pages/Wim/Grid/types';

export type NavBarLanguageKey = keyof typeof ua.navBar;

type MenuItem = {
  labelKey: NavBarLanguageKey;
  route: string | H.LocationDescriptorObject;
  selectedKey: string;
};

export const DETECTORS_MENU_ITEMS: MenuItem[] = [
  {
    labelKey: 'detectorsMap',
    route: { pathname: ROUTES.TransitsOnlineDetectors },
    selectedKey: ROUTES.TransitsOnlineDetectors,
  },
  {
    labelKey: 'detectorsSearch',
    route: { pathname: ROUTES.ParkingsCameras },
    selectedKey: ROUTES.ParkingsCameras,
  },
  {
    labelKey: 'detectorsIntensityAccounting',
    route: { pathname: ROUTES.Accidents },
    selectedKey: ROUTES.Accidents,
  },
  {
    labelKey: 'detectorsCoefficientIntensityChange',
    route: { pathname: ROUTES.Complexes },
    selectedKey: ROUTES.Complexes,
  },
  {
    labelKey: 'detectorsVisualAccounting',
    route: { pathname: ROUTES.ParkingsCoverage },
    selectedKey: ROUTES.ParkingsCoverage,
  },
  {
    labelKey: 'detectorsVisualOvertakingIntensity',
    route: { pathname: ROUTES.Suspicions },
    selectedKey: ROUTES.Suspicions,
  },
  {
    labelKey: 'detectorsTransitsAccounting',
    route: { pathname: ROUTES.TransitsArchiveDetectors },
    selectedKey: ROUTES.TransitsArchiveDetectors,
  },
];

export const WIM_MENU_ITEMS: MenuItem[] = [
  {
    labelKey: 'wimsMap',
    route: { pathname: ROUTES.WimsMap },
    selectedKey: ROUTES.WimsMap,
  },
  {
    labelKey: 'wimsList',
    route: {
      pathname: ROUTES.WimsGrid,
      search: stringifyUrlParams({ view: WimGridViewParams.list, sort: WimsListSort.default }),
    },
    selectedKey: ROUTES.WimsGrid,
  },
  {
    labelKey: 'wimsMonitoring',
    route: { pathname: ROUTES.WimsMonitoring },
    selectedKey: ROUTES.WimsMonitoring,
  },
];

export const TRANSITS_MENU_ITEMS: MenuItem[] = [
  {
    labelKey: 'transitsOnline',
    route: { pathname: ROUTES.TransitsOnline },
    selectedKey: ROUTES.TransitsOnline,
  },
  {
    labelKey: 'transitsArchive',
    route: { pathname: ROUTES.TransitsArchive },
    selectedKey: ROUTES.TransitsArchive,
  },
  {
    labelKey: 'intensityTraffic',
    route: {
      pathname: ROUTES.TransitsIntensityTraffic,
      search: stringifyUrlParams({ sort: { key: 'transitsCount', value: 'descend' } }),
    },
    selectedKey: ROUTES.TransitsIntensityTraffic,
  },
];

export const DEVIATIONS_MENU_ITEMS: MenuItem[] = [
  {
    labelKey: 'wimDeviation',
    route: { pathname: ROUTES.WimViolations },
    selectedKey: ROUTES.WimViolations,
  },
  // {
  //   labelKey: 'comparativeAnalysis',
  //   route: { pathname: ROUTES.ComparativeAnalysis },
  //   selectedKey: ROUTES.ComparativeAnalysis,
  // },
];
