import React from 'react';
import i18n from 'i18n';
import { Tooltip } from 'antd';
import _ from 'lodash';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { measurementFlagsTypeTitle } from 'app-constants/measurement-flags';
// import correctMeasure from 'assets/icons/status/correct-measure.svg';
// import errorMeasure from 'assets/icons/status/error-measure.svg';
import palette from 'palette';
import { VendorErrorOld, WimMeasurementFlagType, WimTransitItem, WimTransitItemOld } from 'store/wim/types';
import { getTooltipList } from './tooltip.formatter';

const vendorErrorIcons = {
  correctMeasure: <CheckCircleOutlined style={{ color: palette.green.g2 }} />,
  errorMeasure: <ExclamationCircleOutlined style={{ color: palette.orange.o1 }} />,
};

function prepareVendorError(error: VendorErrorOld) {
  return error.name ? error.name : i18n.t('errors.unknownError').replace('%s', error.id);
}

export function getVendorErrorIconOld(vendorErrors: WimTransitItemOld['vendorErrors']) {
  const name = vendorErrors && !_(vendorErrors).isEmpty() ? 'errorMeasure' : 'correctMeasure';

  let content = null;

  if (!vendorErrors || _(vendorErrors).isEmpty()) {
    content = i18n.t('transitsPage.table.statuses.correctMeasure');
  }

  if (vendorErrors.length > 1) {
    content = getTooltipList(vendorErrors, prepareVendorError);
  }

  if (vendorErrors.length === 1) {
    content = prepareVendorError(vendorErrors[0]);
  }

  return name ? (
    <Tooltip
      title={
        <div
          style={{
            maxWidth: 500,
            fontSize: 14,
            color: palette.black.b2,
            whiteSpace: 'pre-line',
          }}
        >
          {content}
        </div>
      }
      color={palette.white.w1}
    >
      <span style={{ width: '22px' }}>{vendorErrorIcons[name as keyof typeof vendorErrorIcons]}</span>
    </Tooltip>
  ) : null;
}

export function getMeasurementFlagIcon(flags: WimTransitItem['flags']) {
  const mappedFlags = flags.filter((flag) => flag.type !== '');
  const name = mappedFlags && !_(mappedFlags).isEmpty() ? 'errorMeasure' : 'correctMeasure';

  let content = null;

  if (!mappedFlags || _(mappedFlags).isEmpty()) {
    content = i18n.t('transitsPage.table.statuses.correctMeasure');
  }

  if (mappedFlags.length > 1) {
    content = getTooltipList(
      mappedFlags.map((error) => measurementFlagsTypeTitle[error.type as WimMeasurementFlagType])
    );
  }

  if (mappedFlags.length === 1) {
    content = measurementFlagsTypeTitle[mappedFlags[0].type as WimMeasurementFlagType];
  }

  return name ? (
    <Tooltip
      title={
        <div
          style={{
            maxWidth: 500,
            fontSize: 14,
            color: palette.black.b2,
            whiteSpace: 'pre-line',
          }}
        >
          {content}
        </div>
      }
      color={palette.white.w1}
    >
      <span style={{ width: '22px' }}>{vendorErrorIcons[name as keyof typeof vendorErrorIcons]}</span>
    </Tooltip>
  ) : null;
}
