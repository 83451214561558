import { Select } from 'antd';
import palette from 'palette';
import styled from 'styled-components/macro';

export const StyledSelect = styled(Select)`
  width: 100%;

  &[aria-required='true'] {
    > .ant-select-selector {
      border-color: ${palette.red.r3};
    }
  }
`;

export const StyledFilter = styled.div`
  display: flex;
  width: 100%;
  background: ${palette.white.w1};
  padding: 20px;
`;
