import { CHARS } from 'app-constants/chars';

export function getIntegerPercent(value: number): number {
  return Math.round(value * 100);
}

export function getFloatPercent(value: number): number {
  return +(value * 100).toFixed(2);
}

export function getSimpleFloatNumber(value: number): string {
  return value.toFixed(2);
}

export function formatIntegerPercent(value: number): string {
  return `${getIntegerPercent(value)}%`;
}

export function formatFloatPercent(value: number): string {
  return Number.isNaN(value) || value === Infinity ? CHARS.mdash : `${getFloatPercent(value)}%`;
}

export function calculatePercenatgeChange(
  currentPeriodOfTime: number,
  previousPeriodOfTime: number,
  isFloat = true
): string {
  if (previousPeriodOfTime === 0) {
    return currentPeriodOfTime < 0 ? '-100%' : '100%';
  }

  return isFloat
    ? formatFloatPercent((currentPeriodOfTime - previousPeriodOfTime) / previousPeriodOfTime)
    : formatIntegerPercent((currentPeriodOfTime - previousPeriodOfTime) / previousPeriodOfTime);
}

export function calculateFloatPercenatgeChange(currentPeriodOfTime: number, previousPeriodOfTime: number) {
  if (Number.isNaN(currentPeriodOfTime) || Number.isNaN(previousPeriodOfTime)) {
    return CHARS.mdash;
  }

  if (currentPeriodOfTime === Infinity || previousPeriodOfTime === Infinity) {
    return CHARS.mdash;
  }

  return formatFloatPercent(currentPeriodOfTime - previousPeriodOfTime);
}
