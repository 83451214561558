import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spin } from 'antd';
import { Formik } from 'formik';

import palette from 'palette';
import { FormikSelectMediaType } from 'elements/FormikSelectMediaType';
import { FormikSelectMediaFile } from 'elements/FormikSelectMediaFile';
import { StyledButtons, StyledContentForm, StyledEditBlock } from './styles';
import { PlatformMedia, PlatformMediaFieldName, UpdatePlatformMedia } from '../../types';

interface UpdateContentProps {
  onSubmitForm: (values: Omit<UpdatePlatformMedia, 'uid'>) => void;
  isLoading: boolean;
  setFile: (file?: File) => void;
  fileName?: string;
}

export const UploadMedia: FC<UpdateContentProps> = ({ onSubmitForm, isLoading, setFile, fileName }) => {
  const { t } = useTranslation();
  const mediaTypes: PlatformMedia[] = [
    { type: 'schema', name: t('adminWimsPage.mediaType.schema') },
    { type: 'overview', name: t('adminWimsPage.mediaType.overview') },
  ];

  return (
    <Formik<Omit<UpdatePlatformMedia, 'uid'>>
      initialValues={{
        type: 'schema',
        file: '',
      }}
      onSubmit={(submittedValues) => {
        onSubmitForm(submittedValues);
      }}
    >
      {({ values, handleChange, handleSubmit, handleReset, setFieldValue }) => (
        <StyledContentForm onSubmit={handleSubmit} onReset={handleReset}>
          <Spin spinning={isLoading}>
            <StyledEditBlock>
              <FormikSelectMediaType name={PlatformMediaFieldName.type} mediaTypes={mediaTypes} />
              <FormikSelectMediaFile name={PlatformMediaFieldName.file} setFile={setFile} fileName={fileName} />
            </StyledEditBlock>
          </Spin>
          <StyledButtons>
            <Button htmlType="submit" style={{ color: palette.white.w1, backgroundColor: palette.blue.b1 }}>
              {t('common.upload')}
            </Button>
            <Button
              onClick={() => {
                setFile(undefined);
                handleReset();
              }}
              style={{ color: palette.white.w1, backgroundColor: palette.red.r3 }}
            >
              {t('common.cancel')}
            </Button>
          </StyledButtons>
        </StyledContentForm>
      )}
    </Formik>
  );
};
