import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TrucksTransitsType = {
  trafficForCurrentPeriod: number;
  trafficForPreviousPeriod: number;
  delta: number;
};

interface TrucksTransitsState {
  data: TrucksTransitsType;
  isLoading: boolean;
  error: Error | null;
}

const initialState: TrucksTransitsState = {
  data: {
    trafficForCurrentPeriod: 0,
    trafficForPreviousPeriod: 0,
    delta: 0,
  },
  isLoading: false,
  error: null,
};

const trucksTransits = createSlice({
  name: 'trucksTransits',
  initialState,
  reducers: {
    requestTrucksTransits: (state, _payload): void => {
      state.isLoading = true;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>): void => {
      state.isLoading = payload;
    },
    getTrucksTransitsSuccess: (state, { payload }): void => {
      state.isLoading = false;
      state.data = payload;
    },
    getTrucksTransitsError: (state, { payload }): void => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { requestTrucksTransits, getTrucksTransitsSuccess, getTrucksTransitsError, setIsLoading } =
  trucksTransits.actions;

export default trucksTransits.reducer;
