import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ActiveWimPlatformState, GetActiveWimPlatformQuery } from '../types';
import { GetWimSchemaImagePayload, GetWimSchemaImageSuccessPayload } from '../types/active-platform';

type InitialState = {
  activeWimPlatform: {
    wimDetails: ActiveWimPlatformState['wimDetails'] | null;
    wimErrors: ActiveWimPlatformState['wimErrors'] | null;
  };
  isLoading: boolean;
  wimPlatformDetailsError: any;
  wimPlatformErrorsError: any;
  isPlatformSchemaLoading: boolean;
  wimPlatformSchemaImage?: string;
  isPlatformOverviewLoading: boolean;
  wimPlatformOverviewImage?: string;
  error?: string;
};

const initialState: InitialState = {
  isLoading: false,
  activeWimPlatform: {
    wimDetails: null,
    wimErrors: null,
  },
  wimPlatformDetailsError: null,
  wimPlatformErrorsError: null,
  isPlatformSchemaLoading: false,
  wimPlatformSchemaImage: undefined,
  isPlatformOverviewLoading: false,
  wimPlatformOverviewImage: undefined,
  error: undefined,
};

const activeWimPlatform = createSlice({
  name: 'activePlatform',
  initialState,
  reducers: {
    requestActiveWimPlatform: (state, { payload }: PayloadAction<GetActiveWimPlatformQuery>): void => {
      state.isLoading = true;
    },
    requestActiveWimPlatformDetails: (state, { payload }: PayloadAction<GetActiveWimPlatformQuery>): void => {
      state.isLoading = true;
    },
    requestActiveWimPlatformErrors: (state, { payload }: PayloadAction<GetActiveWimPlatformQuery>): void => {
      state.isLoading = true;
    },
    getActiveWimPlatformDetailsSuccess: (
      state,
      { payload }: PayloadAction<ActiveWimPlatformState['wimDetails'] | null>
    ): void => {
      state.activeWimPlatform.wimDetails = payload;
      state.isLoading = false;
    },
    getActiveWimPlatformErrorsSuccess: (
      state,
      { payload }: PayloadAction<ActiveWimPlatformState['wimErrors'] | null>
    ): void => {
      state.activeWimPlatform.wimErrors = payload;
      state.isLoading = false;
    },
    clearActiveWimPlatform: () => initialState,
    clearActiveWimPlatformErrors: (state) => {
      state.activeWimPlatform.wimErrors = null;
    },
    getActiveWimPlatformDetailsError: (state, { payload }: PayloadAction<string>): void => {
      state.isLoading = false;
      state.wimPlatformDetailsError = payload;
    },
    getActiveWimPlatformErrorsError: (state, { payload }: PayloadAction<string>): void => {
      state.isLoading = false;
      state.wimPlatformErrorsError = payload;
    },
    clearWimPlatformSchemaImage: (state): void => {
      state.wimPlatformSchemaImage = undefined;
    },
    getWimPlatformSchemaImageSuccess: (state, { payload }: PayloadAction<GetWimSchemaImageSuccessPayload>): void => {
      state.isPlatformSchemaLoading = false;
      state.wimPlatformSchemaImage = payload.value;
    },
    getWimPlatformSchemaImageError: (state, { payload }: PayloadAction<string>): void => {
      state.isPlatformSchemaLoading = false;
      state.error = payload;
    },
    requestWimPlatformSchemaImage: (state, { payload }: PayloadAction<GetWimSchemaImagePayload>): void => {
      state.isPlatformSchemaLoading = true;
    },
    clearWimPlatformOverviewImage: (state): void => {
      state.wimPlatformOverviewImage = undefined;
    },
    getWimPlatformOverviewImageSuccess: (state, { payload }: PayloadAction<GetWimSchemaImageSuccessPayload>): void => {
      state.isPlatformSchemaLoading = false;
      state.wimPlatformOverviewImage = payload.value;
    },
    getWimPlatformOverviewImageError: (state, { payload }: PayloadAction<string>): void => {
      state.isPlatformSchemaLoading = false;
      state.error = payload;
    },
    requestWimPlatformOverviewImage: (state, { payload }: PayloadAction<GetWimSchemaImagePayload>): void => {
      state.isPlatformOverviewLoading = true;
    },
  },
});

export const {
  requestActiveWimPlatform,
  requestActiveWimPlatformDetails,
  requestActiveWimPlatformErrors,
  getActiveWimPlatformDetailsSuccess,
  getActiveWimPlatformErrorsSuccess,
  clearActiveWimPlatform,
  clearActiveWimPlatformErrors,
  getActiveWimPlatformDetailsError,
  getActiveWimPlatformErrorsError,
  clearWimPlatformSchemaImage,
  getWimPlatformSchemaImageSuccess,
  getWimPlatformSchemaImageError,
  requestWimPlatformSchemaImage,
  clearWimPlatformOverviewImage,
  getWimPlatformOverviewImageSuccess,
  getWimPlatformOverviewImageError,
  requestWimPlatformOverviewImage,
} = activeWimPlatform.actions;

export default activeWimPlatform.reducer;
