import React from 'react';

import { Col, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';

import { DATE_FORMAT, RANGES } from 'app-constants/date';
import { OPTION_KEYS_FOR_SELECT } from 'app-constants/select-options';
import { DatePicker } from 'components';
import { ContentFilterProps } from 'hoc/withFilter';
import { StyledFilter, StyledSelect } from 'pages/Transits/IntensityTraffic/IntensityTrafficFilter/styles';
import { FilterValues } from 'pages/Home';
import { useHomePageFilter } from './hooks/use-home-page-filter';

type RangesType = Record<string, [Dayjs | null, Dayjs | null]>;

const HomeFilter: React.FC<ContentFilterProps<FilterValues>> = ({ filterManager }) => {
  const { Option } = Select;
  const { t } = useTranslation();
  const { onPlatformNameChange, onRangeChange, state, platforms } = useHomePageFilter(filterManager);
  const defaultWimsValue = state.wimIds;

  const ranges = {
    [t('date.month')]: RANGES.getMonthRange(),
    [t('date.year')]: RANGES.getYearRange(),
    [t('date.quarter')]: RANGES.getQuarterRange(),
  } as RangesType;

  return (
    <StyledFilter>
      <Col span={5}>
        <StyledSelect
          maxTagTextLength={20}
          maxTagCount={1}
          allowClear
          value={defaultWimsValue}
          showSearch
          placeholder={t('filter.allPlatforms')}
          optionFilterProp="children"
          onChange={onPlatformNameChange}
          mode="multiple"
        >
          <Option key={OPTION_KEYS_FOR_SELECT.All} value={OPTION_KEYS_FOR_SELECT.All}>
            {t('filter.selectAll')}
          </Option>
          {platforms?.map(({ uid, wim }) => (
            <Option value={uid} key={uid}>
              {wim}
            </Option>
          ))}
        </StyledSelect>
      </Col>
      <Col span={12} offset={1}>
        <DatePicker.RangePicker
          clearIcon={false}
          value={[dayjs(state.start), dayjs(state.end)]}
          showTime={{ format: DATE_FORMAT.RANGE_PICKER_TIME }}
          format={DATE_FORMAT.RANGE_PICKER}
          ranges={ranges}
          onChange={onRangeChange}
        />
      </Col>
    </StyledFilter>
  );
};

export default HomeFilter;
