import React from 'react';

import { User } from 'store/user/types';
import { StyledContent } from './styles';
import { InfoContent } from './components/InfoContent';
import { BottomActions } from './components/bottomActions';

interface UserSummaryProps {
  state: User;
}

const UserSummary: React.FC<UserSummaryProps> = ({ state }) => (
  <StyledContent>
    <InfoContent state={state} />
    <BottomActions state={state} />
  </StyledContent>
);

export { UserSummary };
