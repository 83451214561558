import React from 'react';
import { Button, Tooltip } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import palette from 'palette';

import { ExportIcon } from './styles';

interface HeaderExportExtraWithMapProps {
  tooltipText: string;
  onExportButtonClick: () => void;
  onMapButtonClick?: () => void;
}

export const HeaderExportExtraWithMap: React.FC<HeaderExportExtraWithMapProps> = ({
  tooltipText,
  onExportButtonClick,
  onMapButtonClick,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Tooltip
        placement="bottomRight"
        title={<span style={{ color: palette.black.b1 }}>{t('navBar.transitsMap')}</span>}
        color={palette.white.w1}
      >
        <Button type="text" icon={<EnvironmentOutlined />} onClick={onMapButtonClick} />
      </Tooltip>
      <Tooltip
        placement="bottomRight"
        title={<span style={{ color: palette.black.b1 }}>{tooltipText}</span>}
        color={palette.white.w1}
      >
        <Button type="text" onClick={onExportButtonClick} icon={<ExportIcon rotate={90} />} />
      </Tooltip>
    </div>
  );
};
