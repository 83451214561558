import { Subtract } from 'helpers/types.utility';
import React, { FC } from 'react';

type Sorters = {
  [key: string]: (a: any, b: any) => number;
};

interface SortProps<SortContentProps> {
  sorters: Sorters;
  SortComponent: FC<SortContentProps>;
}

export interface ComponentSortProps<SortContentProps> {
  sortManager: SortProps<SortContentProps>;
}

export const withSort =
  <SortContentProps, OwnComponentProps = any>(sortProps: SortProps<SortContentProps>) =>
  (Component: React.FC<OwnComponentProps>) =>
  (props: OwnComponentProps) => {
    const componentPropsWithSort: ComponentSortProps<SortContentProps> = {
      sortManager: sortProps,
    };
    return <Component {...componentPropsWithSort} {...(props as OwnComponentProps)} />;
  };
