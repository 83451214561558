import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';

import { WimPlatform } from 'store/wim/types';
import { CreatePlatform } from '../modal/CreateContent/types';
import { wimListSorters } from '../sorting-schema';
import { WimsListSort } from '../types';

export type QueryUrl = {
  defaultQuery: string | undefined;
  onUpdateUrl: (value: string) => void;
};

type UpdateStateHandler = (value: React.SetStateAction<WimsPageState>) => void;

interface WimsPageState {
  platforms: WimPlatform[] | null;
  selectedPlatform: WimPlatform | null;
  newPlatform: CreatePlatform;
}

function getSelectedWim(
  platforms: WimPlatform[] | null,
  firstPlatform: WimPlatform | null,
  previouslySelected: WimPlatform | null,
  queryWimUid?: string
): WimPlatform | null {
  if (queryWimUid && platforms) {
    return platforms.find((platform) => platform.uid === queryWimUid) || null;
  }
  if (!previouslySelected && firstPlatform) {
    return firstPlatform;
  }
  return previouslySelected;
}

const newPlatform: CreatePlatform = {
  address: '',
  mapPosition: '',
  wim: '',
  serialNumber: '',
  alias: '',
  description: '',
  road: '',
  region: '',
  provider: '',
  status: '',
  measureStatus: '',
  verificationCertificate: {
    expirationDate: 0,
    number: '',
  },
};

const useWimsPageState = (platforms: WimPlatform[] | null, queryUrlParams: Record<string, any>) => {
  const [wimsPageState, setWimsPageState] = useState<WimsPageState>({
    platforms: null,
    selectedPlatform: null,
    newPlatform,
  });

  useEffect(() => {
    if (platforms) {
      const newPlatforms = cloneDeep(platforms);

      const defaultSortedPlatforms = queryUrlParams.sort
        ? newPlatforms.sort(wimListSorters[queryUrlParams.sort as WimsListSort])
        : newPlatforms;

      const firstPlatform = defaultSortedPlatforms[0];
      setWimsPageState((prevState) => ({
        platforms: defaultSortedPlatforms,
        selectedPlatform: getSelectedWim(
          defaultSortedPlatforms,
          firstPlatform,
          prevState.selectedPlatform,
          queryUrlParams.uid
        ),
        newPlatform,
      }));
    }
  }, [platforms, queryUrlParams.sort, queryUrlParams.uid]);

  const handleUpdateWimsPageState: UpdateStateHandler = (newState) => {
    setWimsPageState(newState);
  };

  return {
    wimsPageState,
    handleUpdateWimsPageState,
  };
};

export { useWimsPageState };
