import React, { PropsWithChildren, useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';

import { useSorting } from 'hooks/use-sorting';
import { DefaultTableContainer, DefaultTableContainerProps } from '../DefaultTableContainer';
import { assignSortingOrderToColumn } from './helper/sorting-order';

type TableContainerWithSortingProps<TableRecord> = DefaultTableContainerProps<TableRecord>;

const TableContainerWithSorting = <TableRecord extends Record<string, any>>({
  columns,
  ...tableProps
}: PropsWithChildren<TableContainerWithSortingProps<TableRecord>>): JSX.Element => {
  const { onSortingChange, sortingKey, sortingOrder } = useSorting();

  const newColumns = useMemo(
    () =>
      columns?.reduce((acc, currentColumn) => {
        const newColumn = assignSortingOrderToColumn(currentColumn, { sortingKey, sortingOrder });

        if ('children' in newColumn) {
          newColumn.children.map((childColumn) => {
            const newChildColumn = assignSortingOrderToColumn(childColumn, { sortingKey, sortingOrder });

            return newChildColumn;
          });
        }

        acc.push(newColumn);

        return acc;
      }, [] as ColumnsType<TableRecord>),
    [sortingKey, sortingOrder]
  );

  return <DefaultTableContainer columns={newColumns} onChange={onSortingChange} {...tableProps} />;
};

export { TableContainerWithSorting };
