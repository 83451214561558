import { useSelector } from 'react-redux';
import adminSelectors from 'store/admin/selectors';

const useUsersState = () => {
  const users = useSelector(adminSelectors.users.getUsers);
  const isUsersLoading = useSelector(adminSelectors.users.isUsersLoading);
  const isUpdateUserLoading = useSelector(adminSelectors.users.isUpdateUserLoading);
  const isCreateUserLoading = useSelector(adminSelectors.users.isCreateUserLoading);

  return {
    users,
    isUsersLoading,
    isUpdateUserLoading,
    isCreateUserLoading,
    isUsersLoaded: users && !!users.length,
  };
};

export { useUsersState };
