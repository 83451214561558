import React, { PropsWithChildren } from 'react';
import { Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';

interface DefaultDropdownProps<DropdownEntity> {
  entities: DropdownEntity[];
  onItemClick: (entity: DropdownEntity) => void;
  route: string;
  selectedItem: string;
  trigger: 'click' | 'hover' | 'contextMenu';
  locationState?: Record<string, any>;
}

export const DefaultDropdown = <DropdownEntity extends { uid: string; wim: string }>({
  entities,
  onItemClick,
  route,
  selectedItem,
  trigger,
  locationState,
}: PropsWithChildren<DefaultDropdownProps<DropdownEntity>>): JSX.Element => (
  <Dropdown
    overlay={() => (
      <Menu>
        {entities.map((entity, i) => (
          <Menu.Item key={`menuitem-${i + 1}`} onClick={() => onItemClick(entity)}>
            <Link to={{ pathname: `${route}/${entity.uid}`, state: locationState }}>{entity.wim}</Link>
          </Menu.Item>
        ))}
      </Menu>
    )}
    trigger={[trigger]}
  >
    <div
      className="ant-dropdown-link"
      style={{ cursor: 'pointer' }}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => e.preventDefault()}
      onClick={(e) => e.preventDefault()}
    >
      {selectedItem} <DownOutlined />
    </div>
  </Dropdown>
);
