import React, { FC } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import ua from 'locales/ua/translation.json';
import { UsersListSort } from '../../types';

export type UsersListSortKey = keyof typeof ua.lists.sorting;

export interface SortSelectProps {
  defaultValue: UsersListSort;
  onChange: (value: UsersListSort) => void;
}

const { Option } = Select;

const SortSelect: FC<SortSelectProps> = ({ defaultValue, onChange }) => {
  const { t } = useTranslation();
  return (
    <Select
      style={{ width: 200, float: 'right', marginBottom: 10 }}
      bordered={false}
      defaultValue={defaultValue}
      onChange={onChange}
    >
      {Object.keys(UsersListSort).map((key) => (
        <Option key={`sort-${key}`} value={key}>
          {t(`lists.sorting.${key as UsersListSortKey}` as const)}
        </Option>
      ))}
    </Select>
  );
};
export { SortSelect };
