import { call, put, takeEvery } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import { getExternalNotificationsError, getExternalNotificationsSuccess, requestExternalNotifications } from './slice';
import { ExternalNotification } from '../types';
import { getNotificationStats } from '../../../api/system';

export function* externalNotificationsRequestSaga() {
  try {
    const response: AxiosResponse<ExternalNotification[]> = yield call(getNotificationStats, {});
    if (response) {
      yield put(getExternalNotificationsSuccess({ notifications: response.data }));
    }
  } catch (error) {
    yield put(getExternalNotificationsError(error.message));
  }
}

export function* externalNotificationsRequestWatchSaga() {
  yield takeEvery(requestExternalNotifications, externalNotificationsRequestSaga);
}
