import { combineReducers } from '@reduxjs/toolkit';

import * as detectorsActions from './detectors/slice';
import detectorsSlices from './detectors/slice';
import * as wimsActions from './wims/slice';
import wimsSlices from './wims/slice';

const platformsSlices = combineReducers({
  detectors: detectorsSlices,
  wims: wimsSlices,
});

export const platformActions = {
  detectors: { ...detectorsActions },
  wims: { ...wimsActions },
};

export default platformsSlices;
