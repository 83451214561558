import React from 'react';
import { Tooltip } from 'antd';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import i18n from 'i18n';

import { wrapTableTitle } from 'components/TableContainer/DefaultTableContainer';
import { CHARS } from 'app-constants/chars';
import { DATE_FORMAT } from 'app-constants/date';
import { ROUTES } from 'app-constants/routes';
import { getVehicleClassIconByName, getVehicleIdByName } from 'helpers/get-vehicle-class-icon';
import { plateFormatter } from 'helpers/label.formatter';
import { getVendorErrorIconOld } from 'helpers/get-vendor-errors';
import { separateByThousands } from 'helpers/number.formatter';
import { getVehicleTypeDescriptionOld } from 'helpers/get-vehicle-description';
import palette from 'palette';
import { WimTransitAnalysisItem, WimTransitAnalysisItemWithDiff, WimTransitItemOld } from 'store/wim/types/transits';
import { stringifyUrlParams } from 'hooks/use-url-params';
import { URLParams } from 'types';

import { HintTooltip, StyledLink, StyledDiff } from './styles';

export const DEFAULT_PAGE_SIZE_OPTIONS = ['10', '25', '50', '100'];

export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_OFFSET = 0;
export const DEFAULT_PAGE = 1;
export const DEFAULT_AXLES = 9;

export const sizeAccuracy = 3;

export const initialTransitsTableSortingUrlParams: URLParams = {
  key: 'datetime',
  value: 'descend',
};

export type TransitsAnalysisTableRow = WimTransitAnalysisItemWithDiff & WimTransitAnalysisItem;
export type TransitsAnalysisTableColumns = {
  [key in keyof Omit<
    TransitsAnalysisTableRow,
    | 'wim'
    | 'plateRecognized'
    | 'plateBackRecognized'
    | 'trailerPresence'
    | 'weightDiff'
    | 'heightDiff'
    | 'widthDiff'
    | 'lengthDiff'
    | 'axlesDiff'
    | 'children'
    | 'framename'
    | 'offenceTypes'
  >]: ColumnType<TransitsAnalysisTableRow> | ColumnGroupType<TransitsAnalysisTableRow>;
};

const getCharForDiff = (diff: number) => {
  return diff > 0 ? `+${diff}` : diff;
};

const wrapWithLink = (content: React.ReactNode, row: WimTransitItemOld) => {
  return (
    <StyledLink
      to={{
        pathname: ROUTES.Transit,
        state: { fromComparativeAnalysis: true },
        search: stringifyUrlParams({ platformName: row.platformName, transitId: row.id }),
      }}
    >
      {content}
    </StyledLink>
  );
};

export const getTransitsAnalysisTableColumns = (): TransitsAnalysisTableColumns => ({
  id: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('comparativeAnalysisPage.table.columns.id')),
    width: 160,
    fixed: 'left',
    key: 'id',
    dataIndex: 'id',
    render: (id: number, row) => wrapWithLink(id || CHARS.mdash, row),
  },
  plate: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('comparativeAnalysisPage.table.columns.plate')),
    width: 140,
    fixed: 'left',
    key: 'plate',
    dataIndex: 'plate',
    render: (plate: string, row) =>
      wrapWithLink(row.plateRecognized ? plateFormatter(plate) : i18n.t('filter.plateNotRecognized'), row),
  },
  platformName: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('comparativeAnalysisPage.table.columns.platfromName')),
    width: 80,
    key: 'platformName',
    dataIndex: 'platformName',
    render: (platformName: string, row) => wrapWithLink(platformName || CHARS.mdash, row),
  },
  datetime: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('comparativeAnalysisPage.table.columns.datetime')),
    width: 170,
    key: 'datetime',
    dataIndex: 'datetime',
    render: (datetime: number, row) =>
      wrapWithLink(dayjs(datetime).format(DATE_FORMAT.TABLE_FORMAT) || CHARS.mdash, row),
  },
  wimName: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('comparativeAnalysisPage.table.columns.wimName')),
    width: 300,
    key: 'wimName',
    dataIndex: 'wimName',
    render: (wimName: string, row) => wrapWithLink(wimName || CHARS.mdash, row),
  },
  vendorErrors: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('comparativeAnalysisPage.table.columns.vendorErrors')),
    width: 110,
    key: 'vendorErrors',
    dataIndex: 'vendorErrors',
    render: (vendorErrors: WimTransitItemOld['vendorErrors'], row) =>
      wrapWithLink(getVendorErrorIconOld(vendorErrors), row),
  },
  plateBack: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('comparativeAnalysisPage.table.columns.plateBack')),
    width: 140,
    key: 'plateBack',
    dataIndex: 'plateBack',
    render: (plateBack: string, row) =>
      wrapWithLink(row.plateBackRecognized ? plateFormatter(plateBack) : i18n.t('filter.plateNotRecognized'), row),
  },
  vehicleType: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('comparativeAnalysisPage.table.columns.vehicleType')),
    width: 100,
    key: 'vehicleType',
    dataIndex: 'vehicleType',
    render: (vehicleType: WimTransitItemOld['vehicleType'], row) =>
      wrapWithLink(
        vehicleType ? (
          <Tooltip
            title={<HintTooltip>{getVehicleTypeDescriptionOld(vehicleType)}</HintTooltip>}
            color={palette.white.w1}
          >
            <span>{getVehicleIdByName(vehicleType)}. </span>
            <img src={getVehicleClassIconByName(vehicleType)} alt="vehicle-type" width={50} height={25} />
          </Tooltip>
        ) : (
          CHARS.mdash
        ),
        row
      ),
  },
  weight: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('comparativeAnalysisPage.table.columns.weight')),
    width: 150,
    key: 'weight',
    dataIndex: 'weight',
    render: (weight: number, row) => {
      if (row.weightDiff) {
        return wrapWithLink(
          (
            <span>
              {weight}
              <StyledDiff>{getCharForDiff(row.weightDiff * 100)}%</StyledDiff>
            </span>
          ) || CHARS.mdash,
          row
        );
      }

      return wrapWithLink(weight || CHARS.mdash, row);
    },
  },
  speed: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('comparativeAnalysisPage.table.columns.speed')),
    width: 120,
    key: 'speed',
    dataIndex: 'speed',
    render: (speed: number, row) => wrapWithLink(speed || CHARS.mdash, row),
  },
  direction: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('comparativeAnalysisPage.table.columns.direction')),
    width: 210,
    key: 'direction',
    dataIndex: 'direction',
    render: (direction: string, row) => wrapWithLink(direction || CHARS.mdash, row),
  },
  length: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('comparativeAnalysisPage.table.columns.length')),
    width: 120,
    key: 'length',
    dataIndex: 'length',
    render: (length: number, row) => {
      if (row.lengthDiff) {
        return wrapWithLink(
          (
            <span>
              {length ? length.toFixed(sizeAccuracy) : CHARS.mdash}
              <StyledDiff>{getCharForDiff(row.lengthDiff * 100)}%</StyledDiff>
            </span>
          ) || CHARS.mdash,
          row
        );
      }
      return wrapWithLink(length ? length.toFixed(sizeAccuracy) : CHARS.mdash, row);
    },
  },
  width: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('comparativeAnalysisPage.table.columns.width')),
    width: 130,
    key: 'width',
    dataIndex: 'width',
    render: (width: number, row) => {
      if (row.widthDiff) {
        return wrapWithLink(
          (
            <span>
              {width ? width.toFixed(sizeAccuracy) : CHARS.mdash}
              <StyledDiff>{getCharForDiff(row.widthDiff * 100)}%</StyledDiff>
            </span>
          ) || CHARS.mdash,
          row
        );
      }
      return wrapWithLink(width ? width.toFixed(sizeAccuracy) : CHARS.mdash, row);
    },
  },
  height: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('comparativeAnalysisPage.table.columns.height')),
    width: 130,
    key: 'height',
    dataIndex: 'height',
    render: (height: number, row) => {
      if (row.heightDiff) {
        return wrapWithLink(
          (
            <span>
              {height ? height.toFixed(sizeAccuracy) : CHARS.mdash}
              <StyledDiff>{getCharForDiff(row.heightDiff * 100)}%</StyledDiff>
            </span>
          ) || CHARS.mdash,
          row
        );
      }
      return wrapWithLink(height ? height.toFixed(sizeAccuracy) : CHARS.mdash, row);
    },
  },
  axlesCount: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('comparativeAnalysisPage.table.columns.axlesCount')),
    width: 100,
    key: 'axlesCount',
    dataIndex: 'axlesCount',
    render: (axlesCount: number, row) => {
      if (row.axlesDiff) {
        return wrapWithLink(
          (
            <span>
              {axlesCount || CHARS.mdash}
              <StyledDiff>{getCharForDiff(row.axlesDiff)}</StyledDiff>
            </span>
          ) || CHARS.mdash,
          row
        );
      }
      return wrapWithLink(axlesCount || CHARS.mdash, row);
    },
  },
  wheelBase: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('comparativeAnalysisPage.table.columns.wheelBase')),
    width: 160,
    key: 'wheelBase',
    dataIndex: 'wheelBase',
    render: (wheelBase: number, row) => wrapWithLink(wheelBase ? wheelBase.toFixed(sizeAccuracy) : CHARS.mdash, row),
  },
  axles: {
    title: (): JSX.Element => wrapTableTitle(`${i18n.t('comparativeAnalysisPage.table.columns.axleLoad')}`),
    key: 'axles',
    dataIndex: 'axles',
    children: Array.from(Array(DEFAULT_AXLES).keys()).map((index) => ({
      title: (): JSX.Element => wrapTableTitle(`${i18n.t('comparativeAnalysisPage.table.columns.axle')} ${index + 1}`),
      key: `axle-${index + 1}`,
      dataIndex: `axles`,
      width: 160,
      render: (axles: WimTransitItemOld['axles'], row) => {
        let content: React.ReactNode = CHARS.mdash;

        axles.forEach((axle, axleIndex) => {
          if (axleIndex === index) {
            if (axle.totalWeightDiff) {
              content = axle.totalWeight ? (
                <span>
                  {separateByThousands(axle.totalWeight)}
                  <StyledDiff>{getCharForDiff(axle.totalWeightDiff * 100)}%</StyledDiff>
                </span>
              ) : (
                CHARS.mdash
              );
            } else {
              content = axle.totalWeight ? separateByThousands(axle.totalWeight) : CHARS.mdash;
            }
          }
        });
        return wrapWithLink(<div>{content}</div>, row);
      },
    })),
  },
});
