import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type OverweightType = {
  overloadForCurrentPeriod: number;
  overloadForPreviousPeriod: number;
  delta: number | null;
};

export interface OverweightState {
  data: OverweightType;
  isLoading: boolean;
  error: Error | null;
}

const initialState: OverweightState = {
  data: {
    overloadForCurrentPeriod: 0,
    overloadForPreviousPeriod: 0,
    delta: 0,
  },
  isLoading: false,
  error: null,
};

const overloadStatistics = createSlice({
  name: 'overloadStatistics',
  initialState,
  reducers: {
    requestOverweight: (state, _payload): void => {
      state.isLoading = true;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>): void => {
      state.isLoading = payload;
    },
    getOverweightSuccess: (state, { payload }): void => {
      state.isLoading = false;
      state.data = payload;
    },
    getOverweightError: (state, { payload }): void => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { requestOverweight, getOverweightSuccess, getOverweightError, setIsLoading } = overloadStatistics.actions;

export default overloadStatistics.reducer;
