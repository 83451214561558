import React from 'react';

import { Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { CameraInfo } from 'app-constants/wim-cameras-mapper';
import { getCamerasInfoByWimId } from 'helpers/camera.utility';
import { StyledDropdown } from './styles';

interface LinksDropdownProps {
  title: string;
  platformUid: string;
  onItemClick: (cameraInfo: CameraInfo) => void;
}

export const LinksDropdown: React.FC<LinksDropdownProps> = ({ title, platformUid, onItemClick }) => {
  const camerasInfo = getCamerasInfoByWimId(platformUid);

  const linksMenu = (
    <Menu>
      {camerasInfo &&
        camerasInfo.map((cameraInfo) => {
          return (
            <Menu.Item key={cameraInfo.alias} onClick={() => onItemClick(cameraInfo)}>
              {cameraInfo.direction}
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const isDropdownDisabled = !camerasInfo || !camerasInfo.length;

  return (
    <StyledDropdown disabled={!!isDropdownDisabled} overlay={linksMenu}>
      <div
        className="ant-dropdown-link"
        style={{ cursor: 'pointer' }}
        role="button"
        tabIndex={0}
        onClick={(e) => e.preventDefault()}
        onKeyDown={(e) => e.preventDefault()}
      >
        <span>
          {title} ({camerasInfo ? camerasInfo.length : 0})
        </span>
        <DownOutlined />
      </div>
    </StyledDropdown>
  );
};
