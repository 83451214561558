import React from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import { logoHorizontal, logoThin, logoVertical } from 'assets';
import { LogoWrapper } from './styles';

interface LogoProps {
  collapsed?: boolean;
  vertical?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const Logo: React.FC<LogoProps> = ({ collapsed, vertical, onClick }) => {
  let src = logoThin;
  let key = 'thin';

  if (!collapsed && vertical) {
    src = logoVertical;
    key = 'vertical';
  } else if (!collapsed && !vertical) {
    src = logoHorizontal;
    key = 'horizontal';
  }

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition key={key} timeout={100} classNames="fade">
        <LogoWrapper onClick={onClick}>
          <img src={src} alt="Logo" />
        </LogoWrapper>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default Logo;
