import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Collapse, RadioChangeEvent, Spin, Tooltip } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  CameraOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  ReloadOutlined,
  WifiOutlined,
} from '@ant-design/icons';
import { batch, useDispatch } from 'react-redux';

import { HeaderConfigContext } from 'app/App';
import { ROUTES } from 'app-constants/routes';
import { CardWithRadioTabs, DefaultDropdown, ModalWrapper, HLSVideoPlayer } from 'components';
import palette from 'palette';
import { splitPlatformPath } from 'helpers/url.formatter';
import { CameraInfo } from 'app-constants/wim-cameras-mapper';
import { convertDatePeriodToTimePeriod, getUpdatedTime } from 'helpers/date.formatter';
import { getColorOfPercentValue, getColorOfWimConnection } from 'helpers/icon.formatter';
import { getPlatformDirectionsWithLanes } from 'helpers/get-platform-lanes';
import { parseQueryUrlString, stringifyUrlParams } from 'hooks/use-url-params';
import { NormalizedWimPlatform } from 'store/wim/normalization';
import { constructWimVideoSrcLink } from 'helpers/camera.utility';
import { DatePeriod } from 'types/date-period';
import { Modal } from 'types/modal';
import { wimActions } from 'store/wim/slices';
import dayjs from 'dayjs';
import { Links } from './components/Links';
import { SimpleCards } from './components/Cards/SimpleCards';
import { getContentList, tabList } from './components/Cards/CardWithTabs';
import useWimState from '../hooks/use-wim-state';
import useGetActivePlatform from '../hooks/use-get-active-platform';
import {
  StyledContainer,
  StyledDetailsBlock,
  StyledInfoBlock,
  StyledFlexBlock,
  StyledGlossaryItem,
  StyledCardWrapper,
  StyledDate,
  SpinContainer,
  StyledSchemaImage,
} from './styles';
import { StyledLanes } from './components/Cards/SimpleCards/styles';
import { useClearActiveWimPlatformOnUnmount } from '../hooks/use-clear-active-wim-platform-on-unmount';
import { useAppSelector } from '../../../hooks/store-hooks';
import wimSelectors from '../../../store/wim/selectors';
import InfoText from '../../../elements/InfoText';
import { getIntegerPercent } from '../../../helpers/percent.formatter';
import { StyledAddress, StyledUpdateButton } from '../Map/modal/WimContent/styles';
import { DEFAULT_RANGE_DAYS } from '../../../app-constants/defaults';
import { HintTooltip } from '../../Transits/styles';

type ActivePlatformLocationState = { fromPage: { pathname: string; search: string } };

const { Panel } = Collapse;

const ActivePlatform: React.FC = () => {
  const [cameraModalState, setCameraModalState] = useState({
    isCameraModalOpen: false,
    videoSrc: '',
    cameraOptions: { direction: '', alias: '' },
  });
  const dispatch = useDispatch();
  const location = useLocation();

  const [activeTabKey, setActiveTabKey] = useState(tabList[0].key);
  const [selectedPlatform, setSelectedPlatform] = useState<NormalizedWimPlatform | null>(null);
  const wimPlatformSchemaImage = useAppSelector(wimSelectors.activePlatform.getWimSchemaImage);
  const wimPlatformOverviewImage = useAppSelector(wimSelectors.activePlatform.getWimOverviewImage);
  const { t } = useTranslation();
  const history = useHistory<ActivePlatformLocationState>();
  const { onlyPlatformUid } = splitPlatformPath(history.location.pathname);
  const { normalizedPlatforms, activeWimPlatformDetails, activeWimPlatformErrors, normalizedSummary } = useWimState();
  const totalWimErrors = useMemo(
    () =>
      normalizedSummary &&
      normalizedSummary.totalErrors.reduce((acc, wimError) => {
        if (wimError.error) {
          acc.push(getIntegerPercent(wimError.error));
        }

        return acc;
      }, [] as number[]),
    [normalizedSummary]
  );

  const handleUpdateClick = () =>
    batch(() => {
      dispatch(wimActions.platforms.requestPlatforms());
      dispatch(
        selectedPlatform &&
          wimActions.platforms.requestPlatformSummary({
            wimUid: selectedPlatform.uid,
            start: dayjs()
              .subtract(DEFAULT_RANGE_DAYS - 1, 'days')
              .valueOf(),
            end: dayjs().valueOf(),
          })
      );
    });

  const minWimError = useMemo(() => totalWimErrors && Math.min(...totalWimErrors), [totalWimErrors]);

  const maxWimError = useMemo(() => totalWimErrors && Math.max(...totalWimErrors), [totalWimErrors]);

  const totalFlagsCount = useMemo(
    () => (activeWimPlatformErrors ? activeWimPlatformErrors.totalInvalid : 0),
    [activeWimPlatformErrors]
  );

  const formatViolation = useMemo(() => {
    if (Number.isSafeInteger(minWimError) && Number.isSafeInteger(maxWimError)) {
      return `${t('glossary.from')} ${minWimError}% ${t('glossary.until')} ${maxWimError}%`;
    }

    return undefined;
  }, [minWimError, maxWimError, t]);

  const { minutes, seconds, lastTime } = getUpdatedTime(selectedPlatform ? selectedPlatform.lastTransitDatetime : null);
  const mappedNormalizedPlatforms = useMemo(
    () => normalizedPlatforms && Object.values(normalizedPlatforms.byUid),
    [normalizedPlatforms]
  );
  const { setHeaderRenderer, setHeaderOnBackButtonState } = useContext(HeaderConfigContext);

  useGetActivePlatform(convertDatePeriodToTimePeriod(DatePeriod.TODAY), selectedPlatform?.uid);
  useClearActiveWimPlatformOnUnmount();

  const handleCameraModalClose = () => {
    setCameraModalState({
      isCameraModalOpen: false,
      videoSrc: '',
      cameraOptions: { direction: '', alias: '' },
    });
  };

  const handleCameraModalOpen = (cameraInfo: CameraInfo) => {
    setCameraModalState({
      isCameraModalOpen: true,
      videoSrc: constructWimVideoSrcLink(cameraInfo.alias),
      cameraOptions: cameraInfo,
    });
  };

  const onTabChange = (e: RadioChangeEvent) => {
    dispatch(wimActions.activePlatform.clearActiveWimPlatformErrors());
    setActiveTabKey(e.target.value);
    selectedPlatform &&
      dispatch(
        wimActions.activePlatform.requestActiveWimPlatformErrors({
          uid: selectedPlatform.uid,
          ...convertDatePeriodToTimePeriod(e.target.value as DatePeriod),
        })
      );
  };

  const onPlatformClick = useCallback((platform: NormalizedWimPlatform) => {
    dispatch(wimActions.activePlatform.clearActiveWimPlatform());
    setSelectedPlatform(null);
    setSelectedPlatform(platform);
  }, []);

  const onBackButton = () => {
    if (history.location.state) {
      const prevPageState = parseQueryUrlString(history.location.state.fromPage.search);
      const newState = stringifyUrlParams({ ...prevPageState, uid: selectedPlatform?.uid });
      return history.push(`${history.location.state.fromPage.pathname}${newState}`);
    }

    return history.push(`${ROUTES.WimsMap}?uid=${selectedPlatform?.uid}`);
  };

  const onCoordinatesClick = () => {
    return {
      pathname: ROUTES.WimsMap,
      search: stringifyUrlParams({ uid: selectedPlatform?.uid }),
    };
  };

  useEffect(() => {
    if (mappedNormalizedPlatforms) {
      const chosenPlatform = mappedNormalizedPlatforms.find((platform) => platform.uid === onlyPlatformUid) || null;

      setSelectedPlatform(chosenPlatform);
    }
  }, [mappedNormalizedPlatforms, onlyPlatformUid]);

  const headerDropdown = useCallback(() => {
    if (mappedNormalizedPlatforms) {
      return (
        <DefaultDropdown<NormalizedWimPlatform>
          entities={mappedNormalizedPlatforms}
          onItemClick={onPlatformClick}
          route={ROUTES.Wim}
          selectedItem={selectedPlatform?.wim || mappedNormalizedPlatforms[0].wim}
          trigger="click"
          locationState={history.location.state}
        />
      );
    }
    return null;
  }, [mappedNormalizedPlatforms, selectedPlatform && selectedPlatform.wim]);

  useEffect(() => {
    setHeaderRenderer(headerDropdown);
    setHeaderOnBackButtonState(() => onBackButton);

    return () => {
      setHeaderRenderer(null);
      setHeaderOnBackButtonState(undefined);
    };
  }, [headerDropdown, setHeaderRenderer]);

  useEffect(() => {
    if (
      selectedPlatform &&
      !wimPlatformSchemaImage &&
      history.location.search === `?modal=${Modal.PLATFORM_TRANSITS_SCHEMA}`
    ) {
      dispatch(wimActions.activePlatform.requestWimPlatformSchemaImage({ platformUid: selectedPlatform?.uid }));
    }
  }, [selectedPlatform, wimPlatformSchemaImage, history.location.search, dispatch]);

  useEffect(() => {
    if (
      selectedPlatform &&
      !wimPlatformOverviewImage &&
      history.location.search === `?modal=${Modal.PLATFORM_OVERVIEW}`
    ) {
      dispatch(wimActions.activePlatform.requestWimPlatformOverviewImage({ platformUid: selectedPlatform?.uid }));
    }
  }, [selectedPlatform, wimPlatformOverviewImage, history.location.search, dispatch]);

  return (
    <StyledContainer>
      <Links selectedPlatform={selectedPlatform} handleCameraModalOpen={handleCameraModalOpen} />
      <SimpleCards activeWimPlatformDetailsState={activeWimPlatformDetails} />
      <StyledDetailsBlock>
        <StyledCardWrapper>
          <CardWithRadioTabs
            style={{ width: '100%', marginBottom: 40 }}
            cardTitle={t('activePlatform.cards.title.incorrectMeasuresReasons')}
            cardStatistics={t('activePlatform.cards.title.incorrectMeasuresReasonsStats', {
              errors: totalFlagsCount,
              total: activeWimPlatformErrors ? activeWimPlatformErrors.total : 0,
              percent: activeWimPlatformErrors ? getIntegerPercent(totalFlagsCount / activeWimPlatformErrors.total) : 0,
            })}
            tabList={tabList}
            activeTabKey={activeTabKey}
            contentList={getContentList(activeWimPlatformErrors)}
            onTabChange={onTabChange}
          />
        </StyledCardWrapper>

        <StyledInfoBlock>
          <Spin spinning={!selectedPlatform}>
            {selectedPlatform && (
              <>
                <StyledAddress>
                  <Link
                    to={{
                      pathname: `${ROUTES.Wim}/${selectedPlatform.uid}`,
                      state: { fromPage: { pathname: location.pathname, search: location.search } },
                    }}
                  >
                    {selectedPlatform.address}
                  </Link>
                </StyledAddress>
                <StyledFlexBlock style={{ flexDirection: 'column', alignItems: 'flex-start', wordBreak: 'break-word' }}>
                  <span>
                    {t('infoFields.lastUpdatedData')}{' '}
                    {minutes && seconds && lastTime ? (
                      <StyledDate>{`${lastTime}
                (${t('glossary.minute', { minutes })} ${t('glossary.second', { seconds })} ${t('glossary.passed')})
                `}</StyledDate>
                    ) : (
                      t('common.noData')
                    )}
                  </span>{' '}
                  <StyledUpdateButton
                    onClick={handleUpdateClick}
                    onKeyDown={handleUpdateClick}
                    role="button"
                    tabIndex={-1}
                  >
                    <ReloadOutlined />
                  </StyledUpdateButton>
                  <span>
                    {t('infoFields.coordinates')}{' '}
                    <Button type="link" onClick={onCoordinatesClick}>
                      <Link
                        to={{
                          pathname: ROUTES.WimsMap,
                          search: stringifyUrlParams({ uid: selectedPlatform?.uid }),
                        }}
                      >
                        {selectedPlatform.mapPosition.coordinates.join(', ')}
                      </Link>
                    </Button>
                  </span>
                </StyledFlexBlock>
                <StyledFlexBlock style={{ flexDirection: 'row', columnGap: 53 }}>
                  <StyledFlexBlock style={{ columnGap: 8 }}>
                    <span>{t('common.connection')}</span>
                    <Tooltip
                      title={<HintTooltip>{t('map.mapModal.connectionValidityDetails', { period: 5 })}</HintTooltip>}
                      color={palette.white.w1}
                    >
                      <WifiOutlined style={{ color: getColorOfWimConnection(selectedPlatform.lastTransitDatetime) }} />
                    </Tooltip>
                  </StyledFlexBlock>
                  <StyledFlexBlock style={{ columnGap: 8 }}>
                    <span>{t('map.mapModal.measureValidity')}</span>
                    <Tooltip
                      title={<HintTooltip>{t('map.mapModal.measureValidityDetails')}</HintTooltip>}
                      color={palette.white.w1}
                    >
                      {selectedPlatform.measureValidity && selectedPlatform.measureValidity >= 0.9 ? (
                        <CheckCircleOutlined style={{ color: palette.green.g2 }} />
                      ) : (
                        <ExclamationCircleOutlined style={{ color: palette.orange.o2 }} />
                      )}
                    </Tooltip>
                  </StyledFlexBlock>
                  <StyledFlexBlock style={{ columnGap: 8 }}>
                    <span>{t('map.mapModal.mediaValidity')}</span>
                    <Tooltip
                      title={<HintTooltip>{t('map.mapModal.mediaValidityDetails')}</HintTooltip>}
                      color={palette.white.w1}
                    >
                      <CameraOutlined style={{ color: getColorOfPercentValue(selectedPlatform.mediaValidity) }} />
                    </Tooltip>
                  </StyledFlexBlock>
                </StyledFlexBlock>
                <StyledFlexBlock style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                  <InfoText label={t('infoFields.road')} value={selectedPlatform.road} />
                  <InfoText label={t('infoFields.status')} value={selectedPlatform.status} />
                  <InfoText label={t('infoFields.region')} value={selectedPlatform.region} />
                  <InfoText label={t('infoFields.provider')} value={selectedPlatform.provider} />
                  <InfoText label={t('infoFields.serialNumber')} value={selectedPlatform.serialNumber} />
                  <InfoText label={t('infoFields.measureStatus')} value={selectedPlatform.measureStatus} />
                  <InfoText
                    label={t('infoFields.laneQuantity')}
                    value={
                      selectedPlatform &&
                      Object.keys(selectedPlatform.lanes.byDirection)
                        .map((key) => selectedPlatform.lanes.byDirection[key].lanesInfo)
                        .flat().length
                    }
                  />
                  <InfoText
                    label={t('infoFields.verificationCertificate')}
                    value={selectedPlatform.verificationCertificate.number}
                  />
                  <InfoText
                    label={t('map.mapModal.laneViolation', { days: DEFAULT_RANGE_DAYS })}
                    value={formatViolation}
                  />
                  <StyledFlexBlock>
                    <span>{t('infoFields.criticalDefects')} </span>
                    <StyledGlossaryItem>{t('glossary.not')}</StyledGlossaryItem>
                  </StyledFlexBlock>
                  <Collapse ghost>
                    <Panel
                      header={
                        <span style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.65)' }}>
                          {t('activePlatform.cards.title.panelHeader')}
                        </span>
                      }
                      key={`panel-${selectedPlatform.uid}`}
                    >
                      <StyledLanes style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.65)' }}>
                        {getPlatformDirectionsWithLanes(selectedPlatform)}
                      </StyledLanes>
                    </Panel>
                  </Collapse>
                  <Collapse ghost>
                    <Panel
                      header={
                        <span style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.65)' }}>
                          {t('infoFields.verificationCertificates')}
                        </span>
                      }
                      key={`panel-${selectedPlatform.uid}`}
                    >
                      <div />
                    </Panel>
                  </Collapse>
                </StyledFlexBlock>
              </>
            )}
          </Spin>
        </StyledInfoBlock>
      </StyledDetailsBlock>
      <ModalWrapper
        isVisible={history.location.search === `?modal=${Modal.PLATFORM_TRANSITS_SCHEMA}`}
        onClose={history.goBack}
        title={t('activePlatform.modal.title.areaSchema')}
        width={900}
      >
        <>
          {!wimPlatformSchemaImage && (
            <SpinContainer>
              <Spin />
            </SpinContainer>
          )}
          {wimPlatformSchemaImage && <StyledSchemaImage src={wimPlatformSchemaImage} />}
        </>
      </ModalWrapper>
      <ModalWrapper
        isVisible={history.location.search === `?modal=${Modal.PLATFORM_OVERVIEW}`}
        onClose={history.goBack}
        title={t('activePlatform.modal.title.overview')}
        width={900}
      >
        <>
          {!wimPlatformOverviewImage && (
            <SpinContainer>
              <Spin />
            </SpinContainer>
          )}
          {wimPlatformOverviewImage && <StyledSchemaImage src={wimPlatformOverviewImage} />}
        </>
      </ModalWrapper>
      <ModalWrapper
        isVisible={cameraModalState.isCameraModalOpen}
        onClose={handleCameraModalClose}
        title={cameraModalState.cameraOptions.direction}
        width={900}
        destroyOnClose
      >
        <HLSVideoPlayer src={cameraModalState.videoSrc} />
      </ModalWrapper>
    </StyledContainer>
  );
};

export default ActivePlatform;
