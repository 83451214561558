import { useSelector } from 'react-redux';
import adminSelectors from 'store/admin/selectors';

const useSettingsState = () => {
  const settings = useSelector(adminSelectors.settings.getResult);
  const isSettingsLoading = useSelector(adminSelectors.settings.getSettingsLoading);
  const isUpdateSettingsLoading = useSelector(adminSelectors.settings.updateSettingsLoading);
  const settingsError = useSelector(adminSelectors.settings.settingsError);

  return {
    settings,
    isSettingsLoading,
    isUpdateSettingsLoading,
    settingsError,
  };
};

export { useSettingsState };
