import { put, takeEvery } from 'redux-saga/effects';
import makeRequestSaga from 'store/common';
import { getTotalTraffic } from 'api/statistics';
import { SagaPayload } from 'types';
import { GetStatisticQueryParamsType } from '../types';
import { requestTotalTransits, getTotalTransitsSuccess, getTotalTransitsError, setIsLoading } from './slice';

export function* totalTransitsRequestSaga({ payload }: SagaPayload<GetStatisticQueryParamsType>) {
  yield put(setIsLoading(true));
  yield makeRequestSaga(getTotalTransitsSuccess, getTotalTransitsError, getTotalTraffic, payload);
}

export function* totalTransitsRequestWatchSaga() {
  yield takeEvery(requestTotalTransits, totalTransitsRequestSaga);
}
