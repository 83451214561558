import styled from 'styled-components/macro';

export const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const StyledEditLink = styled.div`
  display: flex;
  flex-direction: column;
  float: right;
  margin-left: 10px;
  margin-right: 10px;
`;
