import React from 'react';

import { useHistory } from 'react-router';
import { Button } from 'antd';

import { stringifyUrlParams } from 'hooks/use-url-params';
import { ROUTES } from 'app-constants/routes';

interface IdLinkProps {
  id: string;
  uid: string;
  datetime: number;
}

const IdLink: React.FC<IdLinkProps> = ({ id, uid, datetime }): JSX.Element => {
  const history = useHistory();
  const searchParams = {
    transitUid: uid,
  };

  const search = stringifyUrlParams(searchParams);

  return (
    <Button
      type="link"
      onClick={() =>
        history.push({
          pathname: `${ROUTES.Transit}/${datetime}/${searchParams.transitUid}`,
          state: { fromPage: { pathname: ROUTES.Home } },
        })
      }
    >
      {id}
    </Button>
  );
};

export default IdLink;
