import React from 'react';
import { Layout as ANTLayout, PageHeaderProps } from 'antd';
import styled from 'styled-components/macro';

import { NavBar, Header } from 'components';

const StyledLayout = styled(ANTLayout)`
  min-height: 100vh;
`;

export interface AppLayoutProps extends PageHeaderProps {
  isAuthenticated: boolean;
  dev?: boolean;
}

const AppLayout: React.FC<AppLayoutProps> = ({ isAuthenticated, dev, children, extra = null, title = '', onBack }) =>
  isAuthenticated ? (
    <StyledLayout>
      <NavBar />
      <ANTLayout>
        {title || extra ? <Header extra={extra} title={title} onBack={onBack} dev={dev} /> : null}
        <ANTLayout.Content>{children}</ANTLayout.Content>
      </ANTLayout>
    </StyledLayout>
  ) : (
    <ANTLayout.Content>{children}</ANTLayout.Content>
  );

export default AppLayout;
