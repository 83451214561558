import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialState, Notification, NotificationMessageData } from './types';

const initialState: InitialState = {
  data: [],
};

const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotificationSuccess: (state, { payload }: PayloadAction<Notification>): void => {
      const newMessage: NotificationMessageData = {
        type: 'success',
        title: payload.title,
        message: payload.message,
      };
      state.data = [...state.data, newMessage];
    },
    setNotificationError: (state, { payload }: PayloadAction<Notification>): void => {
      const newMessage: NotificationMessageData = {
        type: 'error',
        title: payload.title,
        message: payload.message,
      };
      state.data = [...state.data, newMessage];
    },
    setNotificationWarning: (state, { payload }: PayloadAction<Notification>): void => {
      const newMessage: NotificationMessageData = {
        type: 'warning',
        title: payload.title,
        message: payload.message,
      };
      state.data = [...state.data, newMessage];
    },
    setNotificationInfo: (state, { payload }: PayloadAction<Notification>): void => {
      const newMessage: NotificationMessageData = {
        type: 'info',
        title: payload.title,
        message: payload.message,
      };
      state.data = [...state.data, newMessage];
    },
  },
});

export const { setNotificationSuccess, setNotificationError, setNotificationWarning, setNotificationInfo } =
  notifications.actions;

export default notifications.reducer;
