import { Dropdown } from 'antd';
import palette from 'palette';
import styled from 'styled-components/macro';

export const StyledDropdown = styled(Dropdown)`
  display: flex;
  align-items: center;

  color: ${({ disabled }) => (disabled ? palette.grey.g1 : palette.blue.b1)};
  font-size: 16px;
  letter-spacing: 0;
  line-height: '24px';

  .anticon svg {
    font-size: 14px;
  }
`;
