import { formatWimName } from 'helpers/label.formatter';
import { WimPlatform } from 'store/wim/types';
import { WimsListSort } from './types';

export const wimListSorters = {
  [WimsListSort.default]: (a: WimPlatform, b: WimPlatform) =>
    formatWimName(a.wim).localeCompare(formatWimName(b.wim), undefined, {
      numeric: true,
      sensitivity: 'base',
    }),
  [WimsListSort.last_updated]: (a: WimPlatform, b: WimPlatform) => {
    if (!a.lastTransitDatetime) {
      return -1;
    }
    if (!b.lastTransitDatetime) {
      return 1;
    }
    return a.lastTransitDatetime > b.lastTransitDatetime ? 1 : -1;
  },
};
