import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Group, CreateGroup, UpdateGroup } from './types';

interface InitialState {
  isLoading: boolean;
  isCreateGroupLoading: boolean;
  isUpdatedGroupLoading: boolean;
  entities: Group[] | null;
  error: any;
}

const initialState: InitialState = {
  isLoading: false,
  isUpdatedGroupLoading: false,
  isCreateGroupLoading: false,
  entities: null,
  error: null,
};

const groups = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    requestGroups: (state): void => {
      state.isLoading = true;
    },
    requestUpdateGroup: (state, { payload }: PayloadAction<UpdateGroup>) => {
      console.log('requestUpdateGroup');
      state.isUpdatedGroupLoading = true;
    },
    requestCreateGroup: (state, { payload }: PayloadAction<CreateGroup>) => {
      state.isCreateGroupLoading = true;
    },
    getGroupsSuccess: (state, { payload }: PayloadAction<Group[]>): void => {
      state.isLoading = false;
      state.isCreateGroupLoading = false;
      state.isUpdatedGroupLoading = false;
      state.entities = payload;
    },
    getGroupsError: (state, { payload }: PayloadAction<string>): void => {
      state.isLoading = false;
      state.isUpdatedGroupLoading = false;
      state.error = payload;
    },
    getNewGroupError: (state, { payload }: PayloadAction<string>): void => {
      state.isLoading = false;
      state.error = payload;
    },
    postCreateGroupSuccess: (state, { payload }: PayloadAction<Group>) => {
      state.isCreateGroupLoading = false;
      if (state.entities) {
        state.entities = [...state.entities, payload];
      } else {
        state.entities = [payload];
      }
    },
    postCreateGroupError: (state, { payload }: PayloadAction<string>) => {
      state.isCreateGroupLoading = false;
      state.error = payload;
    },
  },
});

export const {
  requestGroups,
  requestUpdateGroup,
  requestCreateGroup,
  getGroupsSuccess,
  getGroupsError,
  getNewGroupError,
  postCreateGroupSuccess,
  postCreateGroupError,
} = groups.actions;

export default groups.reducer;
