import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';

import { adminSlices } from './admin/slices';
import authSlice from './auth/slice';
import platformsSlices from './platforms/slices';
import { statisticsSlices } from './statistics/slices';
import userSlice from './user/slice';
import { wimSlices } from './wim/slices';
import { systemSlices } from './system/slices';

export const combinedReducers = combineReducers({
  wim: wimSlices,
  platforms: platformsSlices,
  auth: authSlice,
  statistics: statisticsSlices,
  user: userSlice,
  admin: adminSlices,
  system: systemSlices,
});

export type RootState = ReturnType<typeof combinedReducers>;

export const rootReducer: Reducer = (state: RootState, action: AnyAction): RootState => combinedReducers(state, action);
