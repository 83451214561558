import { combineReducers } from '@reduxjs/toolkit';

import * as transitsActions from './transits/slice';
import transitsSlices from './transits/slice';
import * as platformsActions from './platforms/slice';
import platformsSlices from './platforms/slice';
import * as vehicleClassificationsAction from './vehicle-classifications/slice';
import vehicleClassificationsSlices from './vehicle-classifications/slice';
import * as activePlatformActions from './active-platform/slice';
import activePlatformSlice from './active-platform/slice';
import monitoringSlice, { wimMonitoringActions } from './monitoring/slice';
import * as comparativeAnalysisActions from './comparative-analysis/slice';
import comparativeAnalysisSlices from './comparative-analysis/slice';
import documentExportSlice from './transits/documentExport/slice';
import * as documentExportActions from './transits/documentExport/slice';

export const wimSlices = combineReducers({
  transits: transitsSlices,
  platforms: platformsSlices,
  vehicleClassifications: vehicleClassificationsSlices,
  activePlatform: activePlatformSlice,
  monitoring: monitoringSlice,
  comparativeAnalysis: comparativeAnalysisSlices,
  documentExport: documentExportSlice,
});

export const wimActions = {
  transits: { ...transitsActions },
  platforms: { ...platformsActions },
  vehicleClassifications: { ...vehicleClassificationsAction },
  activePlatform: { ...activePlatformActions },
  monitoring: { ...wimMonitoringActions },
  comparativeAnalysis: { ...comparativeAnalysisActions },
  documentExport: { ...documentExportActions },
};
