import React, { FC, useContext, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import escapeStringRegexp from 'escape-string-regexp';

import { HeaderConfigContext } from 'app/App';
import { ListWithSummary, ModalWrapper } from 'components';
import { ComponentFilterProps, withFilter } from 'hoc/withFilter';
import { ComponentSortProps, withSort } from 'hoc/withSort';
import { useUrlParams } from 'hooks/use-url-params';
import { wimActions } from 'store/wim/slices';
import { WimPlatform } from 'store/wim/types';
import { Modal } from 'types/modal';
import useGetPlatforms from './hooks/use-get-platforms';
import { HeaderExtra } from './components/HeaderExtra';
import { Row } from './components/Row';
import { StyledContent, StyledSummary } from './styles';
import { CreateContent } from './modal/CreateContent';
import useWimState from './hooks/use-wim-state';
import { useWimsPageState } from './hooks/use-wims-page-state';
import { WimsListSort, WimsPageFilterFormValues } from './types';
import { PlatformSummary } from './components/PlatformSummary';
import { wimListSorters } from './sorting-schema';
import { SortSelect, SortSelectProps } from './components/SortSelect';
import { FilterPanel } from './components/FilterPanel';
import { WimsSummary } from './components/WimsSummary';
import { GroupsSummary } from './components/GroupsSummary';
import { UpdatePlatformValues } from './components/PlatformSummary/types';
import { normalizePlatformEmptyValues } from '../../../store/admin/normalization';
import { adminActions } from '../../../store/admin/slices';
import useGetWimGroups from './hooks/use-get-wim-groups';
import { useGetGroups } from '../Groups/hooks/use-get-groups';
import useWimGroupsState from './hooks/use-wim-groups-state';
import { CertificateSummary } from './components/CertificateSummary';
import useWimCertificatesState from './hooks/use-wim-certificates-state';

enum SummaryTab {
  MAIN_INFO = 'MAIN_INFO',
  WIMS = 'WIMS',
  GROUPS = 'GROUPS',
  CERTIFICATES = 'CERTIFICATES',
}

type WimsPageProps = ComponentFilterProps<WimsPageFilterFormValues> & ComponentSortProps<SortSelectProps>;

const WimsPage: FC<WimsPageProps> = ({ filterManager, sortManager }) => {
  const { setHeaderRenderer } = useContext(HeaderConfigContext);
  const dispatch = useDispatch();
  const { queryUrlParams, onPushUrlParams } = useUrlParams({});

  const { isPlatformsLoading, isPlatformsLoaded, platforms } = useWimState();
  const { platformWimGroupBindings, getGroupsByWim, isWimGroupsLoading } = useWimGroupsState();
  const { getCertificatesByWim } = useWimCertificatesState();
  const { wimsPageState, handleUpdateWimsPageState } = useWimsPageState(platforms, queryUrlParams);
  const [filteredPlatforms, setFilteredPlatforms] = useState(wimsPageState.platforms);
  const {
    goBack,
    location: { search },
  } = useHistory();
  const { t } = useTranslation();
  const mainInfoTabLabel = t('infoFields.mainInfo').slice(0, -1);

  const { sorters, SortComponent } = sortManager;
  const { handleFilterOpen, handleFilterClose, isFilterActive, isFilterSubmitted, state, handleFilterSubmitted } =
    filterManager;

  const onFilter = () => {
    if (wimsPageState.platforms && state.search) {
      const regExp = new RegExp(escapeStringRegexp(state.search || ''), 'i');
      const selectedPlatform = wimsPageState.platforms.filter(({ wim }) => regExp.test(wim))[0] || null;

      setFilteredPlatforms(wimsPageState.platforms.filter(({ wim }) => regExp.test(wim)));
      handleUpdateWimsPageState({
        ...wimsPageState,
        selectedPlatform,
      });
      handleFilterSubmitted(false);
    }
  };

  const onSort = (sort: WimsListSort) => {
    if (wimsPageState.platforms) {
      const sortedPlatform = wimsPageState.platforms.sort(sorters[sort]);

      handleUpdateWimsPageState({ ...wimsPageState, platforms: sortedPlatform });
      onPushUrlParams({ sort });
    }
  };

  useGetPlatforms();
  useGetGroups();
  useGetWimGroups();

  useEffect(() => {
    setHeaderRenderer(
      <HeaderExtra isFilterActive={isFilterActive} onFilterOpen={handleFilterOpen} onFilterClose={handleFilterClose} />
    );

    return () => setHeaderRenderer(null);
  }, [setHeaderRenderer, isFilterActive]);

  useEffect(() => {
    if (!queryUrlParams.filter) {
      setFilteredPlatforms(wimsPageState.platforms);
    }
  }, [queryUrlParams.filter, wimsPageState.platforms]);

  useEffect(() => {
    if (isFilterSubmitted) {
      onFilter();
    }
  }, [isFilterSubmitted, wimsPageState.platforms]);

  const onItemSelect = (item: WimPlatform) => {
    if (item.uid !== wimsPageState.selectedPlatform?.uid) {
      dispatch(wimActions.platforms.clearPlatformSummary());
    }
    handleUpdateWimsPageState({ ...wimsPageState, selectedPlatform: item });
    onPushUrlParams({ uid: item.uid });
  };

  const onSubmitNewPlatform = (values: Partial<UpdatePlatformValues>) => {
    const normalizedValues = normalizePlatformEmptyValues(values);
    if (Object.keys(values).length > 0) {
      dispatch(adminActions.platforms.clearMessages());
      dispatch(adminActions.platforms.requestCreatePlatform(normalizedValues));
    }
    goBack();
  };

  const renderItem = (item: WimPlatform) => (
    <Row
      item={item}
      onItemClick={onItemSelect}
      selectedItem={wimsPageState.selectedPlatform}
      isListLoaded={isPlatformsLoaded}
    />
  );

  return (
    <StyledContent>
      <StyledSummary>
        <SortComponent defaultValue={queryUrlParams.sort} onChange={onSort} />
        <ListWithSummary
          list={filteredPlatforms}
          isSelectedItemLoading={isPlatformsLoading}
          isListLoading={isPlatformsLoading}
          renderItem={renderItem}
          rowKey={(item) => `list-item-${item.uid}`}
        >
          <Tabs defaultActiveKey={SummaryTab.MAIN_INFO}>
            <Tabs.TabPane key={SummaryTab.MAIN_INFO} tab={mainInfoTabLabel}>
              {wimsPageState.selectedPlatform && (
                <PlatformSummary state={wimsPageState.selectedPlatform} isLoading={isPlatformsLoading} isEditable />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane key={SummaryTab.WIMS} tab={SummaryTab.WIMS}>
              {wimsPageState.selectedPlatform && (
                <WimsSummary
                  selectedPlatformId={wimsPageState.selectedPlatform.uid}
                  lanes={wimsPageState.selectedPlatform.lanes}
                  isLoading={isPlatformsLoading}
                  isEditable
                />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane key={SummaryTab.GROUPS} tab={t('navBar.groupsList')}>
              {wimsPageState.selectedPlatform && (
                <GroupsSummary
                  groups={getGroupsByWim(wimsPageState.selectedPlatform.uid)}
                  isEditable
                  wimUid={wimsPageState.selectedPlatform.uid}
                  isWimGroupsLoading={isWimGroupsLoading}
                />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane key={SummaryTab.CERTIFICATES} tab={t('navBar.certificates')}>
              {wimsPageState.selectedPlatform && (
                <CertificateSummary
                  certificates={getCertificatesByWim(wimsPageState.selectedPlatform.uid)}
                  selectedPlatformId={wimsPageState.selectedPlatform.uid}
                  isLoading={isPlatformsLoading}
                  isEditable
                />
              )}
            </Tabs.TabPane>
          </Tabs>
        </ListWithSummary>
      </StyledSummary>
      <ModalWrapper
        isVisible={search.includes(`modal=${Modal.NEW_PLATFORM}`)}
        onClose={goBack}
        title={t('adminWimsPage.createPlatform')}
        width={900}
        destroyOnClose
      >
        <CreateContent state={wimsPageState.newPlatform} onSubmitForm={onSubmitNewPlatform} />
      </ModalWrapper>
    </StyledContent>
  );
};

function formatUrlParams({ search }: Record<string, string>): Partial<WimsPageFilterFormValues> {
  return {
    search: search || undefined,
  };
}

export default withFilter<WimsPageFilterFormValues>({
  filterProps: {
    initialState: { search: '' },
    formatUrlParams,
  },
  FilterContent: FilterPanel,
})(
  withSort<SortSelectProps>({
    sorters: wimListSorters,
    SortComponent: SortSelect,
  })(WimsPage)
);
