import env from 'config/env';
import { UserId } from 'store/user/types';
import { WimPlatformUid } from 'store/wim/types';

export const API_URLS = {
  base: env.WAGA_API_URL,
  secondary: env.SECONDARY_WAGA_API_URL,
  wim(slug: string): string {
    return `/wim/${slug}`;
  },
  summary(): string {
    return `/summary`;
  },
  transits(): string {
    return 'transits';
  },
  export(): string {
    return 'export';
  },
  auth(slug: string): string {
    return `/auth/${slug}`;
  },
  users(slug?: string): string {
    return `/users${slug || ''}`;
  },
  userActivityLog(): string {
    return '/activity-logs';
  },
  userActivityLogExport(): string {
    return '/activity-logs-xlsx';
  },
  device(slug?: string): string {
    return `device${slug || ''}`;
  },
  deviceGroup(): string {
    return this.wim(`${this.device()}/group`);
  },
  deviceGroups(): string {
    return this.wim(`${this.device()}/groups`);
  },
  deviceCertificates(): string {
    return this.wim(`${this.device()}/certificates`);
  },
  wimGroupBindings(): string {
    return `${this.deviceGroups()}/bindings`;
  },
  wimGroupBinding(): string {
    return `${this.deviceGroups()}/binding`;
  },
  getTransits(): string {
    return this.wim(this.transits());
  },
  getExportStatus(): string {
    return this.wim(`${this.export()}/status`);
  },
  getExportFile(): string {
    return this.wim(`${this.export()}/file`);
  },
  deleteExportCancel(): string {
    return this.wim(`${this.export()}/cancel`);
  },
  getTransit(transitUid: string): string {
    return `/${this.transits()}/${transitUid}/card`;
  },
  getWimPlatforms(): string {
    return this.wim('platforms');
  },
  getWimVehicleClassifications(): string {
    return this.wim('vehicle-classifications');
  },
  getWimStatistics(slug?: string): string {
    return this.wim(`statistics${slug || ''}`);
  },
  login(): string {
    return this.auth('login');
  },
  logout(): string {
    return this.auth('logout');
  },
  mfa(slug: string): string {
    return this.auth(`mfa/${slug}`);
  },
  mfaQrCode(): string {
    return this.mfa('qrcode');
  },
  mfaValidate(): string {
    return this.mfa('validate');
  },
  passwordReset(): string {
    return this.auth('password/reset');
  },
  myUser(): string {
    return this.users('/me');
  },
  updateUser(uid: UserId): string {
    return this.users(`/${uid}`);
  },
  refreshAccessToken(): string {
    return this.auth('refresh');
  },
  violations(): string {
    return `/violations`;
  },
  getWimSummary(laneUid?: string): string {
    return this.wim(`${laneUid}/summary`);
  },
  statistics(slug: string): string {
    return this.wim(`statistics${slug}`);
  },
  intrudersStatistics(slug: string): string {
    return `traffic/statistics${slug}`;
  },
  wimMetrics(): string {
    return this.wim('metrics');
  },
  wimStatisticsByMetric(): string {
    return this.getWimStatistics('/wim-by-metric');
  },
  wimStatisticsByTimePeriodMetric(): string {
    return this.getWimStatistics('/wim-metric-by-time-periods');
  },
  getWimTransitPhoto(uid: string): string {
    return `transit/${uid}/media`;
  },
  getWimPlatformSchemaImage(uid: string): string {
    return `wim/${uid}/schema`;
  },
  getWimPlatformOverviewImage(uid: string): string {
    return `wim/${uid}/overview`;
  },
  updatePlatform(uid: WimPlatformUid): string {
    return `wim/${uid}`;
  },
  uploadPlatformMedia(uid: WimPlatformUid): string {
    return `media/wim/${uid}`;
  },
  createPlatform(): string {
    return `wim`;
  },
  wimLanes(platformUid: WimPlatformUid): string {
    return `/wim/${platformUid}/lanes`;
  },
  wimLaneTypes(): string {
    return `/wim/platforms/lanes/types`;
  },
  settings(): string {
    return '/settings';
  },
  integrationsStatus(): string {
    return '/system/integrations/status';
  },
  mediaClusterStatus(): string {
    return '/system/media/cluster/metrics';
  },
  notificationsStats(): string {
    return '/system/notifications/stats';
  },
};
