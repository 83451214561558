import React from 'react';
import { Tooltip } from 'antd';

import overAxleAxlesgroupSizeWeight from 'assets/images/offences/over-axle-axlesGroup-size-weight.svg';
import overAxleAxlesgroupSize from 'assets/images/offences/over-axle-axlesGroup-size.svg';
import overAxleAxlesgroupWeight from 'assets/images/offences/over-axle-axlesGroup-weight.svg';
import overAxleAxlesgroup from 'assets/images/offences/over-axle-axlesGroup.svg';
import overAxleSizeWeight from 'assets/images/offences/over-axle-size-weight.svg';
import overAxleSize from 'assets/images/offences/over-axle-size.svg';
import overAxleWeight from 'assets/images/offences/over-axle-weight.svg';
import overAxlesgroupSizeWeight from 'assets/images/offences/over-axlesGroup-size-weight.svg';
import overAxlesgroupSize from 'assets/images/offences/over-axlesGroup-size.svg';
import overAxlesgroupWeight from 'assets/images/offences/over-axlesGroup-weight.svg';
import overAxlesgroup from 'assets/images/offences/over-axlesGroup.svg';
import overSizeWeight from 'assets/images/offences/over-size-weight.svg';
import overWeight from 'assets/images/offences/over-weight.svg';
import overAxle from 'assets/images/offences/over-axle.svg';
import overSize from 'assets/images/offences/over-size.svg';
import over from 'assets/icons/status/error-measure.svg';
import palette from 'palette';
import { violationsTypeTitle } from 'app-constants/violations';
import { OffenceType, WimViolationType } from 'store/wim/types/transits';
import { WimTransitItem } from 'store/wim/types';
import { getTooltipList } from './tooltip.formatter';

const offenceTypeImages = {
  overAxleAxlesgroupSizeWeight,
  overAxleAxlesgroupSize,
  overAxleAxlesgroupWeight,
  overAxleAxlesgroup,
  overAxleSizeWeight,
  overAxleSize,
  overAxleWeight,
  overAxlesgroupSizeWeight,
  overAxlesgroupSize,
  overAxlesgroupWeight,
  overAxlesgroup,
  overSizeWeight,
  overWeight,
  overAxle,
  overSize,
  over,
};

const offenceTitleByViolationType: Record<Exclude<WimViolationType, 'OVERSPEED'>, OffenceType> = {
  OVERAXLELOAD: 'axle',
  OVERLOAD: 'weight',
  OVERGROUPLOAD: 'axlesGroup',
  OVERGROUPLOAD_DUAL: 'axlesGroup',
  OVERGROUPLOAD_TRIPLE: 'axlesGroup',
  OVERSIZE: 'size',
  OVERHEIGHT: 'size',
  OVERLENGTH: 'size',
  OVERWIDTH: 'size',
};

export function getViolationIcon(violations: WimTransitItem['violations']) {
  const violationMap = new Map<OffenceType, WimViolationType>();

  violations.forEach((violation) => {
    if (violation.type !== WimViolationType.OVERSPEED && violation.type !== '') {
      violationMap.set(offenceTitleByViolationType[violation.type], violation.type);
    }
  });

  const violationKeys = [...violationMap.keys()];

  const name = `over${violationKeys
    .map((violationKey) => violationKey[0].toUpperCase() + violationKey.slice(1).toLowerCase())
    .sort()
    .join('')}`;

  const mappedViolations = violations
    .filter((violation) => violation.type !== '')
    .map((violation) => {
      return `${violationsTypeTitle[violation.type as WimViolationType]}`;
    })
    .sort();
  let content = null;
  if (mappedViolations && mappedViolations.length > 1) {
    content = getTooltipList(mappedViolations);
  }
  if (mappedViolations && mappedViolations.length === 1) {
    const firstViolationType = mappedViolations[0];
    content = firstViolationType;
  }

  return (
    content && (
      <Tooltip
        title={
          content && (
            <div
              style={{
                maxWidth: 500,
                fontSize: 14,
                color: palette.black.b2,
                whiteSpace: 'pre-line',
              }}
            >
              {content}
            </div>
          )
        }
        color={palette.white.w1}
      >
        <span>
          <img src={offenceTypeImages[name as keyof typeof offenceTypeImages]} alt={name} width="40px" height="16px" />
        </span>
      </Tooltip>
    )
  );
}
