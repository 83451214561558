import palette from 'palette';
import styled from 'styled-components/macro';

export const PlatePhotoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PhotoPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${palette.grey.g5};
  width: 110px;
  height: 40px;
  font-size: 14px;
  color: ${palette.grey.g1};
  border-radius: 4px;
`;

export const StyledImg = styled.img`
  border-radius: 4px;
`;
