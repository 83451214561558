import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import Hls, { ErrorData, Events } from 'hls.js';
import HLSWrapper, { HLSErrorHandler, HLSWrapperInterface } from 'services/hls.service';

interface VideoPlayerProps {
  src: string;
  options?: {
    autoPlay?: boolean;
    controls?: boolean;
    width?: string;
    height?: string;
  };
}

const HLSVideoPlayer: React.FC<VideoPlayerProps> = ({
  src = '',
  options = { autoPlay: true, controls: true, width: '100%', height: '100%' },
}) => {
  const [hls, setHls] = useState<HLSWrapperInterface | null>(new HLSWrapper());

  const playerRef = useRef<HTMLVideoElement | null>(null);

  const errorHandler = (event: Events.ERROR, data: ErrorData) => {
    if (data.fatal) {
      switch (data.type) {
        case Hls.ErrorTypes.NETWORK_ERROR:
          hls?.handleError(HLSErrorHandler.NETWORK_ERROR);
          break;
        case Hls.ErrorTypes.MEDIA_ERROR:
          hls?.handleError(HLSErrorHandler.MEDIA_ERROR);
          break;
        default:
          console.error(data);
          hls?.destroy();
          break;
      }
    }
  };

  useEffect(() => {
    if (playerRef.current && hls) {
      hls.attachListener(Hls.Events.ERROR, errorHandler);

      hls.attachMediaRef(playerRef.current);
      hls.attachListener(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource(src);
      });
    }
    return () => {
      if (hls) {
        hls.destroy();
      }
      setHls(null);
    };
  });

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      className="videoCanvas"
      ref={playerRef}
      width={options.width}
      height={options.height}
      controls={options.controls}
      autoPlay={options.autoPlay}
    />
  );
};

export default HLSVideoPlayer;
