import React, { useEffect, useState } from 'react';
import { ConnectorData } from '../../store/system/types';
import { StyledContent, StyledSummary } from '../AdminManagement/Groups/styles';
import { ListWithSummary, ModalWrapper } from '../../components';
import { useAppDispatch, useAppSelector } from '../../hooks/store-hooks';
import Row from './ConnectorRow';
import { useUrlParams } from '../../hooks/use-url-params';
import { useConnectorsPageState } from './hooks/use-connectors-page-state';
import { useGetConnectors } from './hooks/use-get-connectors';
import { ConnectorSummary } from './ConnectorSummary';
import { useConnectorsState } from './hooks/use-connectors-state';

const Connectors: React.FC = () => {
  const dispatch = useAppDispatch();
  const { queryUrlParams, onPushUrlParams } = useUrlParams({});
  const { connectors, isLoading, isLoaded } = useConnectorsState();
  const { connectorsPageState, handleUpdatePageState } = useConnectorsPageState(
    connectors ? connectors.connectors : null,
    queryUrlParams
  );

  useGetConnectors();

  const onSelectedNode = (connector: ConnectorData) => {
    handleUpdatePageState({ connector });
    onPushUrlParams({ connector: connector.id });
  };

  const renderItem = (item: ConnectorData) => (
    <Row
      item={item}
      onItemClick={onSelectedNode}
      selectedItem={connectorsPageState.connector}
      isListLoaded={isLoaded}
    />
  );

  return (
    <StyledContent>
      <StyledSummary>
        <ListWithSummary
          list={connectors ? connectors.connectors : null}
          isSelectedItemLoading={isLoading}
          isListLoading={isLoading}
          renderItem={renderItem}
          rowKey={(item) => `list-item-${item.id}`}
        >
          {connectorsPageState.connectors && <ConnectorSummary state={connectorsPageState.connector} />}
        </ListWithSummary>
      </StyledSummary>
    </StyledContent>
  );
};

export default Connectors;
