import _ from 'lodash';

import { WimTransitDetails, WimTransitItem, WimTransitItemWithMapPosition, WimViolationType } from '../types/transits';

export type NormalizedTransitByWimUid = {
  [platformUid: string]: WimTransitDetails;
};

export type ErrorsType = {
  limit: number;
  measured: number;
  type: OffenceTypes;
};

export enum OffenceTypes {
  weight = 'OVERLOAD',
  axle = 'OVERAXLELOAD',
}

const getDataByLane = (data: WimTransitItem[], laneUid: string) =>
  data.filter((transit) => transit.laneUid === laneUid);

const getDataByWim = (data: WimTransitItem[], wimUid: string) =>
  data.filter((transit) => transit.platformUid === wimUid);

const getAverageSpeed = (data: WimTransitItem[], wimUid: string | null, laneUid: string | null): number => {
  let filteredData: WimTransitItem[] = data;

  if (wimUid && !laneUid) {
    filteredData = data.filter((transit) => transit.platformUid === wimUid);
  }
  if (laneUid) {
    filteredData = data.filter((transit) => transit.laneUid === laneUid);
  }

  return Math.round(
    filteredData.reduce<number>((prevWeight, { speed }) => prevWeight + speed, 0) / filteredData.length
  );
};

const getAverageWeight = (data: WimTransitItem[], wimUid: string | null, laneUid: string | null): number => {
  let filteredData: WimTransitItem[] = data;

  if (wimUid && !laneUid) {
    filteredData = data.filter((transit) => transit.platformUid === wimUid);
  }
  if (laneUid) {
    filteredData = data.filter((transit) => transit.laneUid === laneUid);
  }

  return Math.round(
    filteredData.reduce<number>((prevWeight, { weight }) => prevWeight + weight, 0) / filteredData.length
  );
};

const getLastDate = (data: WimTransitItem[], wimUid: string | null, laneUid: string | null) => {
  let filteredData: WimTransitItem[] = data;

  if (wimUid && !laneUid) {
    filteredData = getDataByWim(data, wimUid);
  }
  if (laneUid) {
    filteredData = getDataByLane(data, laneUid);
  }

  return filteredData.reduce<number>(
    (prevDate, { datetime }): number => (prevDate < datetime ? datetime : prevDate),
    0
  );
};

const getMinMaxWeight = (data: WimTransitItem[], wimUid: string | null, laneUid: string | null) => {
  let filteredData: WimTransitItem[] = data;

  if (wimUid && !laneUid) {
    filteredData = getDataByWim(data, wimUid);
  }
  if (laneUid) {
    filteredData = getDataByLane(data, laneUid);
  }

  const weights = filteredData.map((transit) => transit.weight);

  return {
    min: Math.min(...weights),
    max: Math.max(...weights),
  };
};

export const totalViolationsCounter = (data: WimTransitItem[], wimUid: string | null, laneUid: string | null) => {
  let filteredData: WimTransitItem[] = data;

  if (wimUid && !laneUid) {
    filteredData = getDataByWim(data, wimUid);
  }
  if (laneUid) {
    filteredData = getDataByLane(data, laneUid);
  }

  return filteredData.reduce<number>((accum, transit) => {
    const newValue = accum + transit.violations.length;
    return newValue;
  }, 0);
};

export const groupTransitWithMapPositionByWimsUids = (
  transits: WimTransitItemWithMapPosition[]
): NormalizedTransitByWimUid[] => {
  const newData = transits.reduce<NormalizedTransitByWimUid[]>((accum, item) => {
    const { lane, mapPosition, datetime, direction, laneUid, platformName, platformUid, violations, uid } = item;
    const currentIndex = accum.findIndex((value) => value[platformUid]);

    const violationsByLane = {
      date: datetime,
      axle: violations.find((violation) => violation.type === WimViolationType.OVERAXLELOAD) ? 1 : 0,
      both:
        violations.find((violation) => violation.type === WimViolationType.OVERAXLELOAD) &&
        violations.find((violation) => violation.type === WimViolationType.OVERLOAD)
          ? 1
          : 0,
      full: violations.find((violation) => violation.type === WimViolationType.OVERLOAD) ? 1 : 0,
    };
    const transitsByLane = {
      date: datetime,
      total: 1,
    };
    const totalViolations = violationsByLane.axle + violationsByLane.both + violationsByLane.full;

    if (currentIndex === -1) {
      const newItem: NormalizedTransitByWimUid = {
        [platformUid]: {
          platformName,
          mapPosition,
          transitUid: uid,
          totalTransits: getDataByWim(transits, platformUid).length,
          averageSpeed: getAverageSpeed(transits, platformUid, null),
          averageWeight: getAverageWeight(transits, platformUid, null),
          lastTransitDate: getLastDate(transits, platformUid, null),
          maxWeight: getMinMaxWeight(transits, platformUid, null).max,
          minWeight: getMinMaxWeight(transits, platformUid, null).min,
          totalViolations,
          lanes: {
            [laneUid]: {
              direction,
              lane,
              totalTransits: getDataByLane(transits, laneUid).length,
              averageSpeed: getAverageSpeed(transits, null, laneUid),
              averageWeight: getAverageWeight(transits, null, laneUid),
              lastTransitDate: getLastDate(transits, null, laneUid),
              maxWeight: getMinMaxWeight(transits, null, laneUid).max,
              minWeight: getMinMaxWeight(transits, null, laneUid).min,
              totalViolations,
              violations: [violationsByLane],
              transits: [transitsByLane],
            },
          },
        },
      };

      accum.push(newItem);
    } else {
      let currentLane = accum[currentIndex][platformUid].lanes[laneUid];
      const currentPlatform = accum[currentIndex][platformUid];
      const { lanes } = accum[currentIndex][platformUid];

      if (!currentLane) {
        currentLane = {
          lane,
          direction,
          totalTransits: getDataByLane(transits, laneUid).length,
          averageSpeed: getAverageSpeed(transits, null, laneUid),
          averageWeight: getAverageWeight(transits, null, laneUid),
          lastTransitDate: getLastDate(transits, null, laneUid),
          maxWeight: getMinMaxWeight(transits, null, laneUid).max,
          minWeight: getMinMaxWeight(transits, null, laneUid).min,
          totalViolations,
          violations: [violationsByLane],
          transits: [transitsByLane],
        };
        lanes[laneUid] = currentLane;
      } else {
        currentLane.violations.push(violationsByLane);
        currentLane.transits.push(transitsByLane);
        currentLane.totalViolations += totalViolations;
      }
      currentPlatform.totalViolations += totalViolations;
    }

    return accum;
  }, []);

  return newData;
};
