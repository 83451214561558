import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
  getCertificatesError,
  getCertificatesSuccess,
  postCreateCertificateError,
  postCreateCertificateSuccess,
  requestCertificates,
  requestUpdateCertificates,
} from './slice';
import { getCertificates, updateCertificates } from '../../../api/certificates';
import { Certificate, UpdateCertificates } from './types';
import { SagaPayload } from '../../../types';
import { setNotificationError, setNotificationSuccess } from '../notifications/slice';
import { MessageTitle } from '../notifications/types';

export function* certificatesRequestSaga() {
  try {
    const response: AxiosResponse<Certificate[]> = yield call(getCertificates, {});
    if (response) {
      yield put(getCertificatesSuccess(response.data));
    }
  } catch (error) {
    yield put(getCertificatesError(error.message));
  }
}

export function* certificatesUpdateRequestSaga({ payload }: SagaPayload<UpdateCertificates>) {
  try {
    const response: AxiosResponse<Certificate[]> | null | never = yield call(updateCertificates(payload), {});
    const updatedCertificates = response?.data;

    if (updatedCertificates) {
      yield put(postCreateCertificateSuccess(updatedCertificates));
    }
  } catch (error) {
    yield put(postCreateCertificateError(error.message));
  }
}

export function* certificatesRequestWatchSaga() {
  yield takeLatest(requestCertificates, certificatesRequestSaga);
}

export function* certificatesUpdateWatchSaga() {
  yield takeLatest(requestUpdateCertificates, certificatesUpdateRequestSaga);
}
