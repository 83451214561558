export const cLogoSvg = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 18.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 72.6 67.4" style="enable-background:new 0 0 72.6 67.4;" xml:space="preserve">
<style type="text/css">
.st0{fill:#0098D8;}
.st1{fill:#13588F;}
.st2{fill:#FFCB05;}
.st3{fill:#6D6E71;}
.st4{fill:#FFFFFF;}
</style>
<g>
<g>
<polygon class="st0" points="56.3,37 36.4,0 30.5,11 46.9,41.6 		"/>
<polygon class="st0" points="59,42 50.4,48 60.8,67.4 72.6,67.4 		"/>
</g>
<g>
<path class="st1" d="M59.7,43.4l-1,0c-0.6,0.4-1.2,0.7-1.9,1.1c-2.3,1.2-6.4,3.6-5.4,5.7l3.2,5.8L60,44L59.7,43.4z"/>
</g>
<polygon class="st2" points="3,62.2 28.3,15.1 34.2,26.1 19.1,54.3 	"/>
<polygon class="st3" points="68.6,34 71.3,34 30.3,67.4 0,67.4 	"/>
<polygon class="st4" points="69.5,34 70,34 15.7,67.4 12.7,67.4 	"/>
</g>
</svg>`;

export const truckIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="35" height="19">
<path d="M.38 13.249H.5V.87C.5.39.887 0 1.364 0h22.17c.477 0 .864.39.864.871v12.48h1.163V1.048c0-.481.387-.871.864-.871h2.278c1.866 0 3.418 1.379 3.707 3.18h1.726c.477 0 .864.39.864.87v11.949a.868.868 0 0 1-.864.87h-1.19A2.809 2.809 0 0 1 30.28 19a2.81 2.81 0 0 1-2.665-1.953H23.63A2.81 2.81 0 0 1 20.964 19c-1.07 0-2-.607-2.473-1.498A2.801 2.801 0 0 1 16.018 19c-1.545 0-2.802-1.267-2.802-2.824 0-.727.274-1.39.724-1.892h-2.274c.449.501.723 1.165.723 1.892 0 1.557-1.257 2.824-2.802 2.824-1.07 0-2.001-.607-2.473-1.498A2.801 2.801 0 0 1 4.64 19c-1.545 0-2.802-1.267-2.802-2.824 0-.727.274-1.39.723-1.892h-.349c-.076.403-.281.758-.703.758h-1c-.477 0-.5-.526-.5-1.007 0-.482-.108-.786.37-.786zM2.229 1.742l-.016 10.842H22.67V1.742H2.23zm19.809 14.434a1.08 1.08 0 0 0-1.074-1.083 1.08 1.08 0 0 0-1.074 1.083 1.08 1.08 0 0 0 1.074 1.082 1.08 1.08 0 0 0 1.074-1.082zm-4.946 0a1.08 1.08 0 0 0-1.073-1.083 1.08 1.08 0 0 0-1.074 1.083 1.08 1.08 0 0 0 1.073 1.082 1.08 1.08 0 0 0 1.074-1.082zM28.703 1.919H27.29v1.437h3.35a2.033 2.033 0 0 0-1.936-1.437zm4.569 5.762h-1.848v2.042h1.848V7.681zm0 3.784h-2.713a.868.868 0 0 1-.864-.871V6.81c0-.481.387-.871.864-.871h2.713v-.84h-5.983v10.206h.326a2.81 2.81 0 0 1 2.665-1.954c1.244 0 2.3.821 2.666 1.954h.326v-3.84zm-4.065 4.71a1.08 1.08 0 0 0 1.073 1.083 1.08 1.08 0 0 0 1.074-1.082 1.08 1.08 0 0 0-1.074-1.083 1.08 1.08 0 0 0-1.073 1.083zm-25.64 0a1.08 1.08 0 0 0 1.074 1.083 1.08 1.08 0 0 0 1.074-1.082 1.08 1.08 0 0 0-1.074-1.083 1.08 1.08 0 0 0-1.074 1.083zm6.02 1.083a1.08 1.08 0 0 0 1.074-1.082 1.08 1.08 0 0 0-1.074-1.083 1.08 1.08 0 0 0-1.074 1.083 1.08 1.08 0 0 0 1.074 1.082zm-2.08-2.974H6.72c.153.17.285.36.394.565.108-.204.24-.394.394-.565z" />
</svg>`;
