import i18n from 'i18n';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { WimTransit } from 'store/wim/types';
import palette from 'palette';
import pdfService from 'services/pdf.service';
import { WimTransitPhotos } from 'store/wim/types/transits';

import {
  getDocumentFooterSchema,
  getDocumentGeneralInfoSchema,
  getDocumentHeaderSchema,
  getDocumentIncorrectMeasurementsSchema,
  getDocumentPictureSchema,
  getDocumentRegisteredViolationsSchema,
  getDocumentVehicleDimensionsSchema,
  getDocumentVehicleWeightSchema,
} from './helpers/schema';

export const getTransitPdfDocument = (
  transit: WimTransit,
  reportDate: number,
  documentPhotos: WimTransitPhotos,
  vehicleSchemaImageSrc: string | undefined,
  objectName: string,
  fullLocationText: string,
  transitCords: number[] | undefined
) => {
  const schema = {
    header: getDocumentHeaderSchema(transit, objectName, fullLocationText),
    content: [
      getDocumentGeneralInfoSchema(transit, transitCords),
      getDocumentPictureSchema(`2. ${i18n.t('transitDocument.schema')}`, vehicleSchemaImageSrc),
      getDocumentVehicleDimensionsSchema(transit),
      getDocumentVehicleWeightSchema(transit),
      getDocumentPictureSchema(`5. ${i18n.t('transitDocument.numberPlatePicture')}`, documentPhotos.PLATE, 'before'),
      getDocumentPictureSchema(`6. ${i18n.t('transitDocument.vehicleFrontPicture')}`, documentPhotos.FRONT),
      getDocumentPictureSchema(`7. ${i18n.t('transitDocument.vehicleSidePicture')}`, documentPhotos.SIDE),
      getDocumentRegisteredViolationsSchema(transit),
      getDocumentIncorrectMeasurementsSchema(transit),
    ],
    footer(currentPage, pageCount) {
      return getDocumentFooterSchema({ currentPage, pageCount, reportDate });
    },
    styles: {
      header: {
        bold: true,
        fontSize: 12,
      },
      sectionHeader: { color: palette.white.w1, bold: true, fillColor: palette.black.b1 },
      greyTableCell: { color: palette.black.b1, fillColor: palette.grey.g8 },
      darkGreyTableCell: { color: palette.white.w1, fillColor: palette.grey.g9, bold: true },
      table: {
        margin: [0, 1, 0, 1],
      },
      footer: {
        fontSize: 10,
      },
    },
    defaultStyle: {
      font: 'Roboto',
      fontSize: 10,
    },
    pageMargins: [15, 120, 15, 40],
  } as TDocumentDefinitions;

  return pdfService.getPdfDocumentBySchema(schema);
};
