import i18n from 'i18n';

import { ROUTES } from './routes';

interface TitleInterface {
  docTitle: string;
  headerTitle: string;
  dev?: boolean;
}

interface GetRouteToTitleInterface {
  [path: string]: TitleInterface;
}

export const getRouteToTitle = (path: string): GetRouteToTitleInterface => ({
  [ROUTES.Home]: {
    docTitle: i18n.t('homePage.home'),
    headerTitle: i18n.t('homePage.home'),
  },
  [ROUTES.Locations]: {
    docTitle: '',
    headerTitle: '',
  },
  [ROUTES.WimViolations]: {
    docTitle: i18n.t('violationsPage.violationAPWSC'),
    headerTitle: i18n.t('violationsPage.violationAPWSC'),
  },
  [ROUTES.ComparativeAnalysis]: {
    docTitle: i18n.t('comparativeAnalysisPage.headerTitle'),
    headerTitle: i18n.t('comparativeAnalysisPage.headerTitle'),
    dev: true,
  },
  [ROUTES.Detectors]: {
    docTitle: i18n.t('navBar.detectorsMap'),
    headerTitle: '',
  },
  [ROUTES.NotFound]: {
    docTitle: i18n.t('common.notFound'),
    headerTitle: '',
  },
  [ROUTES.Accidents]: {
    docTitle: i18n.t('navBar.detectorsIntensityAccounting'),
    headerTitle: '',
  },
  [ROUTES.Complexes]: {
    docTitle: i18n.t('navBar.detectorsCoefficientIntensityChange'),
    headerTitle: '',
  },
  [ROUTES.ComplexesExport]: {
    docTitle: i18n.t('navBar.businessAnalytic'),
    headerTitle: '',
  },
  [ROUTES.TransitsSpeed]: {
    docTitle: i18n.t('navBar.wimList'),
    headerTitle: '',
  },
  [ROUTES.ParkingsCameras]: {
    docTitle: i18n.t('navBar.detectorsSearch'),
    headerTitle: '',
  },
  [ROUTES.ParkingsCoverage]: {
    docTitle: i18n.t('navBar.detectorsVisualAccounting'),
    headerTitle: '',
  },
  [ROUTES.Platforms]: {
    docTitle: '',
    headerTitle: '',
  },
  [ROUTES.Suspicions]: {
    docTitle: '',
    headerTitle: '',
  },
  [ROUTES.Wim]: {
    docTitle: i18n.t('navBar.wimMap'),
    headerTitle: '',
  },
  [ROUTES.Transits]: {
    docTitle: i18n.t('navBar.transits'),
    headerTitle: '',
  },
  [ROUTES.Transit]: {
    docTitle: i18n.t('navBar.transit'),
    headerTitle: i18n.t('transitPage.headerTitle'),
  },
  [ROUTES.TransitDocument]: {
    docTitle: i18n.t('navBar.transitDocument'),
    headerTitle: i18n.t('transitDocument.headerTitle'),
  },
  [ROUTES.TransitsOnline]: {
    docTitle: i18n.t('navBar.transitsOnline'),
    headerTitle: '',
  },
  [ROUTES.TransitsOnlineWim]: {
    docTitle: `${i18n.t('navBar.transitsOnline')} - ${i18n.t('transitsPage.tabs.wim')}`,
    headerTitle: i18n.t('transitsPage.header.onlineTitle'),
  },
  [ROUTES.TransitsOnlineDetectors]: {
    docTitle: `${i18n.t('navBar.transitsOnline')} - ${i18n.t('transitsPage.tabs.detectors')}`,
    headerTitle: '',
  },
  [ROUTES.TransitsArchive]: {
    docTitle: i18n.t('navBar.transitsArchive'),
    headerTitle: '',
  },
  [ROUTES.TransitsIntensityTraffic]: {
    docTitle: i18n.t('navBar.intensityTraffic'),
    headerTitle: i18n.t('navBar.intensityTraffic'),
  },
  [ROUTES.TransitsArchiveWim]: {
    docTitle: `${i18n.t('navBar.transitsArchive')} - ${i18n.t('transitsPage.tabs.wim')}`,
    headerTitle: i18n.t('transitsPage.header.archiveTitle'),
  },
  [ROUTES.TransitsArchiveDetectors]: {
    docTitle: `${i18n.t('navBar.transitsArchive')} - ${i18n.t('transitsPage.tabs.detectors')}`,
    headerTitle: '',
  },
  [ROUTES.TransitsMap]: {
    docTitle: `${i18n.t('navBar.transitsMap')}`,
    headerTitle: `${i18n.t('navBar.transitsMap')}`,
  },
  [ROUTES.Card]: {
    docTitle: '',
    headerTitle: '',
  },
  [ROUTES.VehicleClassification]: {
    docTitle: '',
    headerTitle: '',
  },
  [ROUTES.WimsMap]: {
    docTitle: i18n.t('navBar.wimsMap'),
    headerTitle: '',
  },
  [ROUTES.WimsGrid]: {
    docTitle: i18n.t('navBar.wimsList'),
    headerTitle: i18n.t('navBar.wimsList'),
  },
  [ROUTES.WimsMonitoring]: {
    docTitle: i18n.t('navBar.wimsMonitoring'),
    headerTitle: i18n.t('navBar.wimsMonitoring'),
  },
  // [getActiveWimRoute(path)]: {
  //   docTitle: i18n.t('navBar.wim'),
  //   headerTitle: i18n.t('common.back'),
  // },
  [ROUTES.AdminUsers]: {
    docTitle: i18n.t('navBar.usersList'),
    headerTitle: i18n.t('navBar.usersList'),
  },
  [ROUTES.SystemSettings]: {
    docTitle: i18n.t('navBar.systemSettings'),
    headerTitle: i18n.t('navBar.systemSettings'),
  },
  [ROUTES.Profile]: {
    docTitle: i18n.t('navBar.profile'),
    headerTitle: i18n.t('navBar.profile'),
  },
  [ROUTES.AdminWims]: {
    docTitle: i18n.t('navBar.wimsList'),
    headerTitle: i18n.t('navBar.wimsList'),
  },
  [ROUTES.AdminGroups]: {
    docTitle: i18n.t('navBar.groupsList'),
    headerTitle: i18n.t('navBar.groupsList'),
  },
  [ROUTES.ServerMonitoring]: {
    docTitle: i18n.t('system.mediaCluster.page'),
    headerTitle: i18n.t('system.mediaCluster.page'),
  },
  [ROUTES.ExternalNotifications]: {
    docTitle: i18n.t('system.externalNotifications.page'),
    headerTitle: i18n.t('system.externalNotifications.page'),
  },
  [ROUTES.ExternalConnectors]: {
    docTitle: i18n.t('system.connectors.page'),
    headerTitle: i18n.t('system.connectors.page'),
  },
});

function getActiveWimRoute(pathname: string) {
  const activeWimRegex = /wim\/(?!map|grid).*/g;

  if (pathname.match(activeWimRegex)) {
    return pathname;
  }

  return '';
}

export type RouteToTitle = typeof getRouteToTitle;
