import React from 'react';
import dayjs from 'dayjs';
import i18n from 'i18n';
import { Collapse, Spin } from 'antd';

import {
  calculatePercenatgeChange,
  formatFloatPercent,
  calculateFloatPercenatgeChange,
} from 'helpers/percent.formatter';
import { getPlatformLanes, getWimPlatformLanesWithFloatNumber } from 'helpers/get-platform-lanes';
import { getWimPlatformLanes } from 'helpers/lanes.utility';
import { ActiveWimPlatformState } from 'store/wim/types';
import { StyledCard, StyledCards, StyledLanes } from './styles';
import { DATE_FORMAT } from '../../../../../../app-constants/date';

interface SimpleCardsProps {
  activeWimPlatformDetailsState: ActiveWimPlatformState['wimDetails'] | null;
}

interface Cards {
  activeWimPlatformDetailsState: ActiveWimPlatformState['wimDetails'];
}

interface Card {
  totalCurrentTraffic: number;
  totalPreviousTraffic: number;
  totalCurrentCargoTraffic: number;
  totalPreviousCargoTraffic: number;
  totalCurrentVrpRecognition: number;
  totalPreviousVrpRecognition: number;
  totalCurrentWeigthViolation: number;
  totalPreviousWeigthViolation: number;
  totalCurrentInvalidMedia: number;
  totalPreviousInvalidMedia: number;
}

export const getCards = ({ activeWimPlatformDetailsState }: Cards) => {
  const lanesQuantity = Object.keys(activeWimPlatformDetailsState).length;
  const {
    totalCurrentCargoTraffic,
    totalCurrentTraffic,
    totalCurrentVrpRecognition,
    totalCurrentWeigthViolation,
    totalPreviousCargoTraffic,
    totalPreviousTraffic,
    totalPreviousVrpRecognition,
    totalPreviousWeigthViolation,
    totalCurrentInvalidMedia,
    totalPreviousInvalidMedia,
  } = Object.keys(activeWimPlatformDetailsState).reduce<Card>(
    (acc, wimUid) => {
      const {
        currentPeriodTotalTraffic,
        previousPeriodTotalTraffic,
        currentPeriodCargoTraffic,
        previousPeriodCargoTraffic,
        currentPeriodVrpRecognition,
        previousPeriodVrpRecognition,
        currentPeriodWeightViolations,
        previousPeriodWeightViolations,
        currentPeriodInvalidMedia,
        previousPeriodInvalidMedia,
      } = activeWimPlatformDetailsState[wimUid];

      acc.totalCurrentTraffic += Number.parseInt(currentPeriodTotalTraffic, 10);
      acc.totalPreviousTraffic += Number.parseInt(previousPeriodTotalTraffic, 10);
      acc.totalCurrentCargoTraffic += Number.parseInt(currentPeriodCargoTraffic, 10);
      acc.totalPreviousCargoTraffic += Number.parseInt(previousPeriodCargoTraffic, 10);
      acc.totalCurrentVrpRecognition += Number.parseFloat(currentPeriodVrpRecognition);
      acc.totalPreviousVrpRecognition += Number.parseFloat(previousPeriodVrpRecognition);
      acc.totalCurrentWeigthViolation += Number.parseInt(currentPeriodWeightViolations, 10);
      acc.totalPreviousWeigthViolation += Number.parseInt(previousPeriodWeightViolations, 10);
      acc.totalCurrentInvalidMedia += Number.parseFloat(currentPeriodInvalidMedia);
      acc.totalPreviousInvalidMedia += Number.parseFloat(previousPeriodInvalidMedia);

      return acc;
    },
    {
      totalCurrentTraffic: 0,
      totalPreviousTraffic: 0,
      totalCurrentCargoTraffic: 0,
      totalPreviousCargoTraffic: 0,
      totalCurrentVrpRecognition: 0,
      totalPreviousVrpRecognition: 0,
      totalCurrentWeigthViolation: 0,
      totalPreviousWeigthViolation: 0,
      totalCurrentInvalidMedia: 0,
      totalPreviousInvalidMedia: 0,
    }
  );

  return {
    commonTraffic: {
      id: dayjs().add(5).valueOf(),
      title: i18n.t('activePlatform.cards.title.commonTraffic'),
      value: totalCurrentTraffic,
      percent: calculatePercenatgeChange(totalCurrentTraffic, totalPreviousTraffic),
      panel: {
        header: i18n.t('activePlatform.cards.title.panelHeader'),
        children: (
          <StyledLanes>{getPlatformLanes(activeWimPlatformDetailsState, 'currentPeriodTotalTraffic')}</StyledLanes>
        ),
      },
      footerLabel: dayjs().subtract(1, 'day').format(DATE_FORMAT.FULLDATE_NO_TIME),
      footerValue: totalPreviousTraffic,
    },
    cargo: {
      id: dayjs().add(6).valueOf(),
      title: i18n.t('activePlatform.cards.title.cargo'),
      value: totalCurrentCargoTraffic,
      percent: calculatePercenatgeChange(totalCurrentCargoTraffic, totalPreviousCargoTraffic),

      panel: {
        header: i18n.t('activePlatform.cards.title.panelHeader'),
        children: (
          <StyledLanes>{getPlatformLanes(activeWimPlatformDetailsState, 'currentPeriodCargoTraffic')}</StyledLanes>
        ),
      },
      footerLabel: dayjs().subtract(1, 'day').format(DATE_FORMAT.FULLDATE_NO_TIME),
      footerValue: totalPreviousCargoTraffic,
    },
    recognized: {
      id: dayjs().add(7).valueOf(),
      title: i18n.t('activePlatform.cards.title.recognized'),
      value: formatFloatPercent(totalCurrentVrpRecognition / 100 / lanesQuantity),
      percent: calculateFloatPercenatgeChange(
        totalCurrentVrpRecognition / 100 / lanesQuantity,
        totalPreviousVrpRecognition / 100 / lanesQuantity
      ),
      panel: {
        header: i18n.t('activePlatform.cards.title.panelHeader'),
        children: (
          <StyledLanes>
            {getWimPlatformLanesWithFloatNumber(
              getWimPlatformLanes(activeWimPlatformDetailsState, 'currentPeriodVrpRecognition'),
              true
            )}
          </StyledLanes>
        ),
      },
      footerLabel: dayjs().subtract(1, 'day').format(DATE_FORMAT.FULLDATE_NO_TIME),
      footerValue: formatFloatPercent(totalPreviousVrpRecognition / 100 / lanesQuantity),
    },
    offenceByWeight: {
      id: dayjs().add(8).valueOf(),
      title: i18n.t('activePlatform.cards.title.offenceWeight'),
      value: totalCurrentWeigthViolation,
      percent: calculatePercenatgeChange(totalCurrentWeigthViolation, totalPreviousWeigthViolation),
      panel: {
        header: i18n.t('activePlatform.cards.title.panelHeader'),
        children: (
          <StyledLanes>{getPlatformLanes(activeWimPlatformDetailsState, 'currentPeriodWeightViolations')}</StyledLanes>
        ),
      },
      footerLabel: dayjs().subtract(1, 'day').format(DATE_FORMAT.FULLDATE_NO_TIME),
      footerValue: totalPreviousWeigthViolation,
    },
    invalidMedia: {
      id: dayjs().add(9).valueOf(),
      title: i18n.t('activePlatform.cards.title.invalidMedia'),
      value: totalCurrentInvalidMedia,
      percent: calculatePercenatgeChange(totalCurrentInvalidMedia, totalPreviousInvalidMedia),
      panel: {
        header: i18n.t('activePlatform.cards.title.panelHeader'),
        children: (
          <StyledLanes>{getPlatformLanes(activeWimPlatformDetailsState, 'currentPeriodInvalidMedia')}</StyledLanes>
        ),
      },
      footerLabel: dayjs().subtract(1, 'day').format(DATE_FORMAT.FULLDATE_NO_TIME),
      footerValue: totalPreviousInvalidMedia,
    },
  };
};

const { Panel } = Collapse;

export const SimpleCards: React.FC<SimpleCardsProps> = ({ activeWimPlatformDetailsState }) => {
  const cards =
    activeWimPlatformDetailsState &&
    getCards({
      activeWimPlatformDetailsState,
    });

  return (
    <Spin spinning={!cards}>
      <StyledCards>
        {cards && (
          <>
            {Object.values(cards).map((card) => (
              <StyledCard
                size="small"
                key={card.id}
                title={card.title}
                isCurrentPeriodValueIncrease={Number.parseFloat(card.percent) > 0}
              >
                <span className="ant-card-body-value">{card.value}</span>
                <span className="ant-card-body-percent">
                  {Number.parseFloat(card.percent) > 0 ? `+${card.percent}` : card.percent}
                </span>
                <Collapse ghost>
                  <Panel header={card.panel.header} key={`panel-${card.id}`}>
                    {card.panel.children}
                  </Panel>
                </Collapse>
                <div className="ant-card-footer">
                  <span>{card.footerLabel}</span>
                  <span>{card.footerValue}</span>
                </div>
              </StyledCard>
            ))}
          </>
        )}
      </StyledCards>
    </Spin>
  );
};
