import React, { FC } from 'react';
import { Skeleton, Typography } from 'antd';

import { StyledListRow, StyledIconsBlock } from './styles';
import { MediaNode } from '../../../store/system/types';

interface NodeListRowProps {
  item: MediaNode;
  selectedItem: MediaNode | null;
  isListLoaded: boolean | null;
  onItemClick: (item: MediaNode) => void;
}

const Row: FC<NodeListRowProps> = ({ item, onItemClick, selectedItem, isListLoaded }) => {
  const { Text } = Typography;

  return (
    <StyledListRow $isSelected={item.id === selectedItem?.id} onClick={() => onItemClick(item)}>
      <Skeleton loading={!isListLoaded} active>
        <Text strong>{item.id}</Text>
        <Text>{item.name}</Text>
        <StyledIconsBlock>
          <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" />
          </svg>
        </StyledIconsBlock>
      </Skeleton>
    </StyledListRow>
  );
};

export default Row;
