import { List } from 'antd';
import styled from 'styled-components/macro';

import palette from 'palette';

interface StyledListItemProps {
  $isSelected?: boolean;
}

export const StyledList = styled.div`
  display: flex;
  width: 100%;
  column-gap: 37px;

  .wims-list {
    width: 50%;
    max-height: 700px;
    overflow: auto;
  }
`;

export const StyledSummary = styled.div`
  border: 1px solid ${palette.grey.g3};
  border-radius: 4px;
  padding: 24px 32px;
  width: 50%;
  position: relative;
`;

export const StyledListItem = styled(List.Item)<StyledListItemProps>`
  display: grid;
  grid-template-columns: minmax(50px, auto) 1fr auto;
  grid-column-gap: 30px;
  margin-bottom: 8px;
  border: ${(props) => (props.$isSelected ? `1px solid ${palette.red.r3}` : `1px solid ${palette.grey.g2}`)};
  box-shadow: ${(props) => (props.$isSelected ? '0 2px 8px 0 rgba(0,0,0,0.09)' : `none`)};
  border-radius: 4px;
  cursor: pointer;
`;

export const StyledIconsBlock = styled.div`
  display: flex;
  column-gap: 16px;

  span {
    width: 18px;
    height: 18px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;
