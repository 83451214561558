import React from 'react';

import { Col } from 'antd';
import dayjs from 'dayjs';

import { DATE_FORMAT } from 'app-constants/date';
import { DatePicker } from 'components';
import { ContentFilterProps } from 'hoc/withFilter';
import { StyledFilter } from 'pages/Transits/IntensityTraffic/IntensityTrafficFilter/styles';
import { TimePeriod } from 'types/date-period';
import { useTransitsByPlateFilter } from './hooks/use-transits-by-plate-filter';

export type FilterValues = TimePeriod & {
  withCoordinates: boolean;
};

const TransitsByPlateFilter: React.FC<ContentFilterProps<FilterValues>> = ({ filterManager }) => {
  const { onRangeChange, state } = useTransitsByPlateFilter(filterManager);

  return (
    <StyledFilter>
      <Col span={7} offset={1}>
        <DatePicker.RangePicker
          clearIcon={false}
          value={[dayjs(state.start).startOf('day'), dayjs(state.end).endOf('day')]}
          showTime={{ format: DATE_FORMAT.RANGE_PICKER_TIME }}
          format={DATE_FORMAT.RANGE_PICKER}
          onChange={onRangeChange}
        />
      </Col>
    </StyledFilter>
  );
};

export default TransitsByPlateFilter;
