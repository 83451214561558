import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Space } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { HeaderConfigContext } from 'app/App';
import { TabsContainer, HeaderFilter } from 'components';
import { DATE_FORMAT } from 'app-constants/date';
import { SortDirection } from 'app-constants/table';
import { formatDateTo } from 'helpers/date.formatter';
import { ComponentFilterProps, withFilter } from 'hoc/withFilter';
import { useAppDispatch, useAppSelector } from 'hooks/store-hooks';
import { useUrlParams } from 'hooks/use-url-params';
import palette from 'palette';
import wimSelectors from 'store/wim/selectors';
import { wimActions } from 'store/wim/slices';
import { TransitsOnlineFilterValues } from 'store/wim/types/transits';
import { INITIAL_TRANSITS_FILTER_VALUES } from 'store/wim/filter/transits';
import env from 'config/env';
import TransitsOnline from './components/TransitsOnline';
import { OnlineModeSettings } from './components/OnlineModeSettings';
import TransitsFilterPanel from '../TransitsFilterPanel';
import { queryParamsToFilterValues } from '../TransitsFilterPanel/schema';
import {
  ButtonContainer,
  HeaderExtraContainer,
  PauseCircleIcon,
  PlayCircleIcon,
  StyledHeaderExtraButtons,
  Text,
} from './styles';

interface HeaderStreamer {
  translations: {
    started: string;
    at: string;
    updated: string;
    stop: string;
    start: string;
  };
  isOnline: boolean;
  isUpdated: boolean;
  startDate: number;
  lastDate: number;
  refreshTime: number;
  viewLimit: number;
  onRefreshTimeChange: (value: number) => void;
  setViewLimit: React.Dispatch<React.SetStateAction<number>>;
  handleOnlineModeChange: () => void;
}

const getHeaderStreamer = (config: HeaderStreamer) => (
  <HeaderExtraContainer>
    <Space direction="horizontal">
      {config.isOnline && (
        <Text>
          {`${config.translations.started} ${config.translations.at}`}{' '}
          {formatDateTo(config.startDate, DATE_FORMAT.FULLTIME)}
        </Text>
      )}
      {config.isUpdated && (
        <Text>
          {`${config.translations.updated} ${config.translations.at}`}{' '}
          {formatDateTo(config.lastDate, DATE_FORMAT.FULLTIME)}
        </Text>
      )}
    </Space>
    <OnlineModeSettings
      refreshTime={config.refreshTime}
      viewLimit={config.viewLimit}
      onRefreshTimeChange={config.onRefreshTimeChange}
      onViewLimitChange={config.setViewLimit}
    />
    <ButtonContainer
      style={{ color: palette.blue.b1 }}
      type="text"
      onClick={config.handleOnlineModeChange}
      icon={config.isOnline ? <PauseCircleIcon /> : <PlayCircleIcon />}
    >
      {config.isOnline ? config.translations.stop : config.translations.start}
    </ButtonContainer>
  </HeaderExtraContainer>
);

const OnlineTabs: React.FC<ComponentFilterProps<TransitsOnlineFilterValues>> = ({ filterManager }) => {
  const dispatch = useAppDispatch();
  const isOnline = useAppSelector(wimSelectors.transits.isOnlineMode);
  const { setHeaderRenderer } = useContext(HeaderConfigContext);
  const { t } = useTranslation();

  const translations = {
    started: t('common.started'),
    at: t('glossary.at'),
    updated: t('common.updated'),
    stop: t('common.stop'),
    start: t('common.start'),
  };

  const [startDate, setStartDate] = useState(dayjs().valueOf());
  const [lastDate, setLastDate] = useState<number>(dayjs().valueOf());
  const [isUpdated, setUpdated] = useState(false);

  const [refreshTime, setRefreshTime] = useState(10);
  const [viewLimit, setViewLimit] = useState(10);
  const { isFilterActive } = filterManager;
  const filterValues = useAppSelector(wimSelectors.transits.getFilterValues);
  const { queryUrlParams } = useUrlParams({});

  useEffect(() => {
    dispatch(wimActions.transits.clearTransits());

    return () => {
      dispatch(wimActions.transits.stopTransitsOnline());
    };
  }, [dispatch]);

  const requestTransits = (startDateParam: number, lastDateParam: number, filter?: Record<string, any>) =>
    dispatch(
      wimActions.transits.requestTransits({
        start: startDateParam,
        end: lastDateParam,
        limit: viewLimit,
        offset: 0,
        sort: {
          [queryUrlParams.sort.key]:
            queryUrlParams.sort.value === 'descend' ? SortDirection.DESCEND : SortDirection.ASCEND,
        },
        ...filter,
      })
    );

  useEffect(() => {
    requestTransits(startDate, lastDate);
  }, [queryUrlParams.sort.key, queryUrlParams.sort.value]);

  useEffect(() => {
    if (isOnline) {
      setUpdated(true);
      const id = window.setInterval(() => {
        const newLastDate = dayjs().valueOf();
        delete queryUrlParams.filter?.start;
        delete queryUrlParams.filter?.end;
        requestTransits(startDate, newLastDate, queryUrlParams.filter);
        setLastDate(newLastDate);
      }, refreshTime * 1000);

      dispatch(wimActions.transits.setOnlineInterval({ intervalId: id }));

      return () => clearInterval(id);
    }

    return undefined;
  }, [dispatch, isOnline, startDate, refreshTime, viewLimit, lastDate, queryUrlParams.filter, filterValues]);

  const onlineTabs = [
    { key: 'wim', title: t('transitsPage.tabs.wim'), content: <TransitsOnline /> },
    {
      key: 'detectors',
      title: t('transitsPage.tabs.detectors'),
      content: <h1>Tab Content 2</h1>,
      isHidden: !env.DETECTORS_ENABLED,
    },
  ];

  const handleOnlineModeChange = () => {
    if (isOnline) {
      dispatch(wimActions.transits.stopTransitsOnline());
    } else {
      const newStartDate = dayjs().valueOf();
      const newLastDate = dayjs().valueOf();
      requestTransits(newStartDate, newLastDate);
      setStartDate(newStartDate);
      setLastDate(newLastDate);
      dispatch(wimActions.transits.setOnlineMode(true));
    }
  };

  const onRefreshTimeChange = (value: number) => {
    if (isOnline) {
      const newStartDate = dayjs().valueOf();
      const newLastDate = dayjs().valueOf();
      requestTransits(newStartDate, newLastDate);
      setStartDate(newStartDate);
      setLastDate(newLastDate);
    }
    setRefreshTime(value);
  };

  const headerStreamer = useCallback(
    () => (
      <StyledHeaderExtraButtons>
        {getHeaderStreamer({
          isOnline,
          isUpdated,
          startDate,
          lastDate,
          refreshTime,
          viewLimit,
          onRefreshTimeChange,
          setViewLimit,
          handleOnlineModeChange,
          translations,
        })}
        <HeaderFilter filterManager={filterManager} />
      </StyledHeaderExtraButtons>
    ),
    [isFilterActive, isOnline, isUpdated, startDate, refreshTime, viewLimit, lastDate, ...Object.values(translations)]
  );

  useEffect(() => {
    setHeaderRenderer(headerStreamer);

    return () => setHeaderRenderer(null);
  }, [setHeaderRenderer, headerStreamer]);

  return <TabsContainer tabs={onlineTabs} />;
};

export default withFilter<TransitsOnlineFilterValues>({
  filterProps: {
    initialState: INITIAL_TRANSITS_FILTER_VALUES,
    formatUrlParams: queryParamsToFilterValues,
    setFilterValuesAction: wimActions.transits.setFilterValues,
  },
  FilterContent: TransitsFilterPanel,
  filterContentProps: { isOnline: true },
})(OnlineTabs);
