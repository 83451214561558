import React from 'react';

import { DefaultTableContainer } from 'components';
import { Button } from 'antd';
import { ExportIcon } from 'components/HeaderExportExtra/styles';
import { UserLogActivity } from 'store/user/types';
import { StyledContent } from './styles';
import { logInfoDataSource, logsColumns } from './table-schema';
import { StyledEditLink } from '../UserSummary/styles';

interface GroupsSummaryProps {
  logs: UserLogActivity[] | null;
  onExport: () => void;
}

const LogsSummary: React.FC<GroupsSummaryProps> = ({ logs, onExport }) => {
  const content = logInfoDataSource(logs || []);

  return (
    <StyledContent>
      <StyledEditLink>
        <Button type="text" onClick={onExport} icon={<ExportIcon rotate={90} />} />
      </StyledEditLink>
      <DefaultTableContainer columns={logsColumns} dataSource={content} bordered />
    </StyledContent>
  );
};

export { LogsSummary };
