import i18n from 'i18n';
import * as Yup from 'yup';

const minValue = 3 * 60; // 3 mins.
const maxValue = 30 * 60; // 30 mins.

export const updateSettingsValidationSchema = Yup.object().shape({
  accessTokenExpiration: Yup.number()
    .required(i18n.t('settingsPage.validation.required'))
    .typeError(i18n.t('settingsPage.validation.isNumber'))
    .min(minValue, i18n.t('settingsPage.validation.minValue', { value: minValue }))
    .max(maxValue, i18n.t('settingsPage.validation.maxValue', { value: maxValue })),
});
