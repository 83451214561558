import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Field, FieldProps, Formik } from 'formik';

import palette from 'palette';
import { userSelectors } from 'store/user/selectors';
import { authSelectors } from 'store/auth/selectors';
import { PasswordResetFormValue, PasswordResetFormValues } from '../types';
import { passwordResetValidationSchema } from '../validation-schema';
import { initialValues } from './initial-values';
import {
  StyledButton,
  StyledConfirmation,
  StyledConfirmationForm,
  StyledErrorMessage,
  StyledInputPassword,
} from '../styles';

interface PasswordResetProps {
  email: string;
  onSubmit: (submittedValues: PasswordResetFormValues) => void;
}

const PasswordReset: React.FC<PasswordResetProps> = ({ email, onSubmit }) => {
  const { t } = useTranslation();
  const isUserLoading = useSelector(userSelectors.isUserLoading);
  const loginError = useSelector(authSelectors.getLoginError);
  return (
    <StyledConfirmation>
      <Formik<PasswordResetFormValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={passwordResetValidationSchema}
      >
        <StyledConfirmationForm>
          <h3>
            {t('loginForm.resetPassword')}
            <div />
            <Tooltip placement="right" title={t('infoFields.passwordTooltip')}>
              <QuestionCircleOutlined />
            </Tooltip>
          </h3>
          <Field name={PasswordResetFormValue.password}>
            {({ field }: FieldProps) => (
              <StyledInputPassword
                {...field}
                placeholder={t('loginForm.newPassword')}
                style={{ borderColor: loginError.userMessage ? palette.red.r3 : palette.grey.g5 }}
              />
            )}
          </Field>
          <Field name={PasswordResetFormValue.repeatPassword}>
            {({ field }: FieldProps) => (
              <StyledInputPassword
                {...field}
                placeholder={t('loginForm.repeatPassword')}
                style={{ borderColor: loginError.userMessage ? palette.red.r3 : palette.grey.g5 }}
              />
            )}
          </Field>
          {loginError.userMessage && <StyledErrorMessage>{loginError.userMessage}</StyledErrorMessage>}
          <StyledButton type="primary" htmlType="submit" loading={isUserLoading}>
            {t('loginForm.login')}
          </StyledButton>
        </StyledConfirmationForm>
      </Formik>
    </StyledConfirmation>
  );
};

export default PasswordReset;
