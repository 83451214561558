import { Location } from 'history';
import { useEffect } from 'react';
import { DEFAULT_APP_DOCUMENT_TITLE } from '../app-constants/defaults';
import { ROUTES } from '../app-constants/routes';
import { RouteToTitle } from '../app-constants/i18n';

type DocumentTitleProps = {
  getRouteToTitle: RouteToTitle;
  location: Location;
  currentRoute?: string;
  setCurrentRoute: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export function useDocumentTitle({
  location,
  getRouteToTitle,
  currentRoute,
  setCurrentRoute,
}: DocumentTitleProps): void {
  const titlePrefix = DEFAULT_APP_DOCUMENT_TITLE;

  useEffect(() => {
    if (currentRoute === location.pathname) {
      return;
    }

    const localizedRoutes = getRouteToTitle(location.pathname);
    const title = localizedRoutes[location.pathname as ROUTES];

    if (!title && currentRoute && location.pathname.includes(currentRoute)) {
      return;
    }

    const documentTitle = title ? `${titlePrefix} - ${title.docTitle}` : titlePrefix;

    document.title = documentTitle;

    setCurrentRoute(location.pathname);
  }, [currentRoute, location.pathname, getRouteToTitle, titlePrefix, setCurrentRoute]);
}
