import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ComparativeAnalysisFilterValues, WimAnalysisTransits } from '../types/transits';

type InitialState = {
  isLoading: boolean;
  transits: WimAnalysisTransits | null;
  error: any;
  filterValues: Partial<ComparativeAnalysisFilterValues> | null;
};

const initialState: InitialState = {
  isLoading: false,
  transits: null,
  error: null,
  filterValues: null,
};

const comparativeAnalysis = createSlice({
  name: 'comparativeAnalysis',
  initialState,
  reducers: {
    requestTransits: (state) => {
      state.isLoading = true;
    },
    getTransitsSuccess: (state, { payload }: PayloadAction<WimAnalysisTransits>): void => {
      state.isLoading = false;
      state.transits = payload;
    },
    getTransitsError: (state, { payload }: PayloadAction<string>): void => {
      state.isLoading = false;
      state.error = payload;
    },
    clearTransits: (state): void => {
      state.transits = null;
    },
    setFilterValues: (state, { payload }: PayloadAction<Partial<ComparativeAnalysisFilterValues>>): void => {
      state.filterValues = payload;
    },
    clearFilterValues: (state): void => {
      state.filterValues = null;
    },
  },
});

export const {
  requestTransits,
  getTransitsSuccess,
  getTransitsError,
  clearTransits,
  setFilterValues,
  clearFilterValues,
} = comparativeAnalysis.actions;

export default comparativeAnalysis.reducer;
