import React from 'react';

import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ColumnGroupType, ColumnType } from 'antd/lib/table';

import { WimViolationType } from 'store/platforms/wims/wim/transits/violations/types';
import { getTableTitle } from 'pages/Violations/helpers/get-table-title';
import { DATE_FORMAT } from 'app-constants/date';
import VrpLink from '../../helpers/VrpLink';

export type ViolationsTableRow = WimViolationType;

export type ViolationsTableColumns = {
  [key in keyof Omit<ViolationsTableRow, 'overload'>]:
    | ColumnType<ViolationsTableRow>
    | ColumnGroupType<ViolationsTableRow>;
};

export const GetDetailTableColumns = (): ViolationsTableColumns => {
  const { t } = useTranslation();

  return {
    date: {
      title: () => getTableTitle(t('violationsPage.detail.date')),
      showSorterTooltip: false,
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => a.date - b.date,
      render: (date: number) => <span>{dayjs(date).format(DATE_FORMAT.FULLTIME)}</span>,
    },
    vrp: {
      title: () => getTableTitle(t('violationsPage.detail.vrp')),
      showSorterTooltip: false,
      dataIndex: 'vrp',
      render: (text: string, row) => (
        <VrpLink
          vrp={text}
          start={dayjs(row.date).startOf('day').valueOf()}
          end={dayjs(row.date).endOf('day').valueOf()}
        />
      ),
    },
    totalWeight: {
      title: () => getTableTitle(t('violationsPage.detail.totalWeight')),
      showSorterTooltip: false,
      dataIndex: 'totalWeight',
      key: 'totalWeight',
      sorter: (a, b) => a.totalWeight - b.totalWeight,
    },
    maxAxleOverweight: {
      title: () => getTableTitle(t('violationsPage.detail.maxAxleOverweight')),
      showSorterTooltip: false,
      dataIndex: 'maxAxleOverweight',
      key: 'maxAxleOverweight',
      sorter: (a, b) => a.maxAxleOverweight - b.maxAxleOverweight,
    },
    axlesCount: {
      title: () => getTableTitle(t('violationsPage.detail.axlesCount')),
      showSorterTooltip: false,
      dataIndex: 'axlesCount',
      key: 'axlesCount',
      sorter: (a, b) => a.axlesCount - b.axlesCount,
    },
  };
};
