import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  IntensityTraffic,
  TransitsIntensityTrafficFilterValues,
  TransitsOnlineFilterValues,
} from 'store/wim/types/transits';
import { ComponentFilterProps, withFilter } from 'hoc/withFilter';
import { Loader, TableContainerWithSorting } from 'components';
import { useUrlParams } from 'hooks/use-url-params';
import wimSelectors from 'store/wim/selectors';
import { wimActions } from 'store/wim/slices';

import { RootState } from 'store/root-reducer';

import { useHeaderWithFiltersRenderer } from 'components/HeaderFilter';
import { queryParamsToFilterValues } from '../TransitsFilterPanel/schema';
import { StyledIntensityTraffic } from './styles';
import { getIntensityTrafficColumns } from './table-schema';
import IntensityTrafficFilter, { INITIAL_TRANSITS_FILTER_VALUES } from './IntensityTrafficFilter';
import { parseIntensityTrafficData } from './IntensityTrafficFilter/helpers/parse-intensity-traffic-data';

const IntensityTrafficPage: FC<ComponentFilterProps<TransitsIntensityTrafficFilterValues>> = ({ filterManager }) => {
  const { queryUrlParams } = useUrlParams({});
  const [currentParams, setCurrentParams] = useState(queryUrlParams.filter);
  const dispatch = useDispatch();
  const { state } = filterManager;

  const intensityTraffic = useSelector((intensityTrafficState) =>
    wimSelectors.transits.getIntensityTraffic(intensityTrafficState as RootState, {
      key: queryUrlParams.sort.key,
      value: queryUrlParams.sort.value,
    })
  );
  const columns = Object.values(getIntensityTrafficColumns());

  useHeaderWithFiltersRenderer({ filterManager });

  useEffect(() => {
    dispatch(wimActions.transits.requestIntensityTraffic(currentParams || state));
  }, [dispatch, currentParams]);

  useEffect(() => {
    setCurrentParams(queryUrlParams.filter);
  }, [queryUrlParams]);

  if (!intensityTraffic) {
    return <Loader isLoading />;
  }

  return (
    <StyledIntensityTraffic>
      <TableContainerWithSorting<IntensityTraffic>
        columns={columns}
        dataSource={parseIntensityTrafficData(intensityTraffic)}
        showSorterTooltip={false}
        scroll={{ y: 'calc(100vh - 220px)' }}
        size="small"
        className="intensity-traffic"
      />
    </StyledIntensityTraffic>
  );
};

export default withFilter<TransitsOnlineFilterValues>({
  filterProps: {
    initialState: INITIAL_TRANSITS_FILTER_VALUES,
    formatUrlParams: queryParamsToFilterValues,
  },
  FilterContent: IntensityTrafficFilter,
})(IntensityTrafficPage);
