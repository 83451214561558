import { ColumnsType } from 'antd/lib/table';
import i18n from 'i18n';
import { Wim } from 'store/wim/types';

export const wimsColumns: ColumnsType<Wim> = [
  {
    title: i18n.t('violationsPage.laneType'),
    key: 'laneType',
    dataIndex: 'laneType',
  },
  {
    title: i18n.t('violationsPage.direction'),
    key: 'direction',
    dataIndex: 'direction',
  },
  {
    title: i18n.t('violationsPage.laneDescription'),
    key: 'laneDescription',
    dataIndex: 'laneDescription',
  },
];
