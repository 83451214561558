import React, { useContext, useEffect, useState } from 'react';

import { FilterButton, Loader, Map, ModalWrapper } from 'components';
import { ROUTES } from 'app-constants/routes';
import { withFilter, ComponentFilterProps } from 'hoc/withFilter';
import { withModal, ComponentModalProps } from 'hoc/withModal';
import { useUrlParams } from 'hooks/use-url-params';
import { PlatformType } from 'store/platforms/types';
import { NormalizedWimPlatform, NormalizedWimPlatforms } from 'store/wim/normalization';
import { WimContent } from './modal/WimContent';
import useWimState from '../hooks/use-wim-state';
import useGetPlatformSummary from '../hooks/use-get-platform-summary';
import { StyledContainer } from './styles';
import { useClearSummaryOnMount } from '../hooks/use-clear-summary-on-mount';
import { WimsGridFilter } from '../Grid/WimsGridFilter';
import { HeaderConfigContext } from '../../../app/App';
import {
  getFilteredByLanes,
  getFilteredByProvider,
  getFilteredByRegion,
  getFilteredByRoad,
  getFilteredBySearch,
  getFilteredByStatus,
} from './filters';

type WimPageProps = {
  getTitle: (state: NormalizedWimPlatform) => string | undefined;
  getRoute: (state: NormalizedWimPlatform) => string | undefined;
};

type WimPagePropsWithFilter = WimPageProps & ComponentFilterProps<Record<string, any>>;

const WimsMapPage: React.FC<ComponentModalProps<NormalizedWimPlatform> & WimPagePropsWithFilter> = ({
  modalManager,
  filterManager,
  getTitle,
  getRoute,
}) => {
  console.log('WimsMapPage--->', { modalManager, filterManager, getTitle, getRoute });

  useClearSummaryOnMount();

  const { setHeaderRenderer } = useContext(HeaderConfigContext);
  const { normalizedPlatforms, normalizedSummary, isSummaryLoading, isPlatformsLoaded } = useWimState();
  const { queryUrlParams } = useUrlParams({});
  const {
    handleFilterOpen,
    handleFilterClose,
    isFilterActive,
    state: filterState,
    isFilterSubmitClicked,
  } = filterManager;
  const { onOpen, onClose, isVisible, route, title, state, setModalState, showModal } = modalManager;
  const [filteredPlatforms, setFilteredPlatforms] = useState<NormalizedWimPlatforms | null>(null);

  useGetPlatformSummary(state?.uid);

  useEffect(() => {
    setHeaderRenderer(
      <FilterButton isFilterActive={isFilterActive} onOpen={handleFilterOpen} onClose={handleFilterClose} />
    );

    return () => setHeaderRenderer(null);
  }, [isFilterActive, setHeaderRenderer]);

  useEffect(() => {
    if (queryUrlParams.uid && normalizedPlatforms) {
      const selectedPlatformByQuery = normalizedPlatforms.byUid[queryUrlParams.uid];
      setModalState(selectedPlatformByQuery);
      showModal(true);
    }
  }, [normalizedPlatforms]);

  useEffect(() => {
    if (normalizedPlatforms && filteredPlatforms === null) {
      setFilteredPlatforms(normalizedPlatforms);
    }
  }, [filteredPlatforms, normalizedPlatforms]);

  useEffect(() => {
    if (!normalizedPlatforms) {
      return;
    }
    let result = { ...normalizedPlatforms };

    if (Object.keys(filterState).length === 0) {
      setFilteredPlatforms(result);
      return;
    }

    if (!isFilterSubmitClicked) {
      return;
    }

    if (filterState.lanes) {
      result = getFilteredByLanes(result, filterState.lanes);
    }
    if (filterState.status) {
      result = getFilteredByStatus(result, filterState.status);
    }
    if (filterState.region) {
      result = getFilteredByRegion(result, filterState.region);
    }
    if (filterState.search) {
      result = getFilteredBySearch(result, filterState.search);
    }
    if (filterState.road) {
      result = getFilteredByRoad(result, filterState.road);
    }
    if (filterState.provider) {
      result = getFilteredByProvider(result, filterState.provider);
    }
    setFilteredPlatforms(result);
  }, [filterState, isFilterSubmitClicked, normalizedPlatforms]);

  return (
    <StyledContainer>
      {normalizedPlatforms && filteredPlatforms && (
        <Loader isLoading={!isPlatformsLoaded}>
          <Map platformType={PlatformType.WIM} onMarkerClick={onOpen} platforms={filteredPlatforms} />
        </Loader>
      )}
      <ModalWrapper
        isVisible={isVisible}
        onClose={() => onClose(PlatformType.WIM.toLowerCase())}
        title={title}
        style={{ top: 80, left: 400, height: 'calc(100% - 70px)' }}
      >
        <Loader isLoading={isSummaryLoading}>
          {state && normalizedSummary && <WimContent state={state} summary={normalizedSummary} route={route} />}
        </Loader>
      </ModalWrapper>
    </StyledContainer>
  );
};

const getTitle = (state: NormalizedWimPlatform) => state?.wim;
const getRoute = (state: NormalizedWimPlatform) => `${ROUTES.Wim}/${state?.uid}`;

export default withFilter<Record<string, string>>({
  filterProps: {
    initialState: {},
    formatUrlParams: () => ({}),
  },
  FilterContent: WimsGridFilter,
})((props) =>
  withModal<WimPagePropsWithFilter, NormalizedWimPlatform>({
    getTitle,
    getRoute,
    ...props,
  })(WimsMapPage)
);
