import React, { FC } from 'react';

import ua from 'locales/ua/translation.json';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { WimsListSort } from './types';

export type WimsListSortKey = keyof typeof ua.lists.sorting;

export interface SortSelectProps {
  defaultValue: WimsListSort;
  onChange: (value: WimsListSort) => void;
}

const { Option } = Select;

const SortSelect: FC<SortSelectProps> = ({ defaultValue, onChange }) => {
  const { t } = useTranslation();
  return (
    <Select style={{ width: 200 }} bordered={false} defaultValue={defaultValue} onChange={onChange}>
      {Object.keys(WimsListSort).map((key) => (
        <Option key={`sort-${key}`} value={key}>
          {t(`lists.sorting.${key as WimsListSortKey}` as const)}
        </Option>
      ))}
    </Select>
  );
};
export { SortSelect };
