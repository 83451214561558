import React, { FC, useContext, useEffect, useState } from 'react';
import { LayersControl, MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { Loader } from 'components';
import { WimTransitDetails } from 'store/wim/types/transits';
import { parseQueryUrlString, stringifyUrlParams } from 'hooks/use-url-params';
import { WimPlatformUid } from 'store/wim/types';
import { useHeaderWithFiltersRenderer } from 'components/HeaderFilter';
import { HeaderConfigContext } from 'app/App';
import { ComponentFilterProps, withFilter } from 'hoc/withFilter';
import { ROUTES } from 'app-constants/routes';
import { wimActions } from 'store/wim/slices';
import { useAppSelector } from 'hooks/store-hooks';
import wimSelectors from 'store/wim/selectors';
import { DateRangeStep } from 'types/date-period';
import useGetPlatforms from 'pages/Wim/hooks/use-get-platforms';
import { DEFAULT_MAP_COORDINATES } from 'app-constants/defaults';
import { renderMarkers } from './helpers/renderMarkers';
import { TransitsDetailsByWimModal } from './components/TransitsDetailsByWimModal';
import TransitsByPateFilter, { FilterValues } from './components/TransitsByPateFilter';
import { INITIAL_TRANSITS_BY_PLATE_FILTER_VALUES } from './components/TransitsByPateFilter/hooks/use-transits-by-plate-filter';
import { queryParamsToFilterValues } from '../TransitsFilterPanel/schema';
import { StyledMapWrapper } from './styles';

const TransitsMap: FC<ComponentFilterProps<FilterValues>> = ({ filterManager }) => {
  const { setHeaderOnBackButtonState } = useContext(HeaderConfigContext);
  const { state, isFilterSubmitted, isFilterSubmitClicked } = filterManager;
  const normalizedTransitsByWimsUids = useAppSelector(wimSelectors.transits.getNormalizedTransitsByWimsUid);
  const [isShowDetails, setIsShowDetails] = useState(false);
  const [transitsDetailsByWim, setTransitsDetailsByWim] = useState<WimTransitDetails>();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { location } = history;
  const queryUrlParams = parseQueryUrlString(location.search);

  useHeaderWithFiltersRenderer({ filterManager });
  useGetPlatforms();

  const onBackButton = (): null => {
    const firstTransit = normalizedTransitsByWimsUids ? normalizedTransitsByWimsUids[0] : null;
    const platformUid = firstTransit ? Object.keys(firstTransit)[0] : '';
    const transitUid = firstTransit ? Object.values(firstTransit)[0].transitUid : '';
    const transitTs = firstTransit ? Object.values(firstTransit)[0].lastTransitDate : '';

    if (!platformUid || !transitUid) {
      history.push({
        pathname: ROUTES.TransitsArchiveWim,
        search: stringifyUrlParams({
          sort: { key: 'datetime', value: 'descend' },
          filter: { plate: queryUrlParams?.plate },
        }),
      });

      return null;
    }

    history.push({
      pathname: `${ROUTES.Transit}/${transitTs}/${transitUid}`,
    });

    return null;
  };

  useEffect(() => {
    setHeaderOnBackButtonState(() => onBackButton);
  }, [normalizedTransitsByWimsUids]);

  useEffect(() => {
    if (queryUrlParams?.filter?.start && queryUrlParams?.filter?.end && queryUrlParams?.plate) {
      if (isFilterSubmitted) {
        dispatch(
          wimActions.transits.requestTransitWithCoordinatesByPlate({
            withCoordinates: true,
            plate: queryUrlParams.plate,
            start: queryUrlParams.filter.start,
            end: queryUrlParams.filter.end,
          })
        );
      } else if (isFilterSubmitClicked) {
        dispatch(
          wimActions.transits.requestTransitWithCoordinatesByPlate({
            withCoordinates: true,
            plate: queryUrlParams.plate,
            start: queryUrlParams.filter.start,
            end: queryUrlParams.filter.end,
          })
        );
      }
    } else {
      dispatch(
        wimActions.transits.requestTransitWithCoordinatesByPlate({
          withCoordinates: true,
          plate: queryUrlParams?.plate,
          start: dayjs().startOf(DateRangeStep.DAY).valueOf(),
          end: dayjs().endOf(DateRangeStep.DAY).valueOf(),
        })
      );
    }
  }, [queryUrlParams?.filter?.start, queryUrlParams?.filter?.end, dispatch]);

  const onClickMarkerHandler = (wimUid: WimPlatformUid) => {
    setIsShowDetails(true);

    const currentWim = normalizedTransitsByWimsUids && normalizedTransitsByWimsUids.find((transit) => transit[wimUid]);

    if (currentWim) {
      setTransitsDetailsByWim(currentWim[wimUid as string]);
    }
  };

  const markers = normalizedTransitsByWimsUids ? renderMarkers(onClickMarkerHandler, normalizedTransitsByWimsUids) : [];

  if (!normalizedTransitsByWimsUids) {
    return <Loader isLoading />;
  }

  return (
    <StyledMapWrapper>
      <TransitsDetailsByWimModal
        onClose={setIsShowDetails}
        isVisible={isShowDetails}
        transitsDetailsByWim={transitsDetailsByWim}
        start={state.start}
        end={state.end}
      />
      <MapContainer
        style={{ height: 752 }}
        center={DEFAULT_MAP_COORDINATES}
        zoom={7}
        zoomControl={false}
        scrollWheelZoom={false}
      >
        <ZoomControl position="topleft" zoomOutTitle={t('common.zoomOut')} zoomInTitle={t('common.zoomOut')} />
        <LayersControl position="topright">
          <LayersControl.BaseLayer name={t('map.mapLayer.default')}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer checked name={t('map.mapLayer.transport')}>
            <TileLayer
              attribution={`&copy;
              <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy;
                <a href="https://carto.com/attributions">CARTO</a>`}
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name={t('map.mapLayer.administrative')}>
            <TileLayer
              attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>'
              url="http://gisfile.com/map/ukraine/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        {markers}
      </MapContainer>
    </StyledMapWrapper>
  );
};

export default withFilter<FilterValues>({
  filterProps: {
    initialState: INITIAL_TRANSITS_BY_PLATE_FILTER_VALUES,
    formatUrlParams: queryParamsToFilterValues,
  },
  FilterContent: TransitsByPateFilter,
})(TransitsMap);
