import React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { Tabs, TabsProps } from 'antd';

interface Tab {
  title: string;
  key: string;
  content: React.ReactNode;
  isHidden?: boolean;
}

interface TabsContainerProps extends TabsProps {
  tabs: Tab[];
}

const { TabPane } = Tabs;

const TabsContainer: React.FC<TabsContainerProps> = ({ tabs, ...props }) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { tabKey } = useParams<{ tabKey?: string }>();

  const tabPath = pathname.slice(0, pathname.lastIndexOf('/'));
  return (
    <Tabs
      activeKey={tabKey}
      onChange={(key) => {
        history.push(`${tabPath}/${key}${search}`);
      }}
      {...props}
    >
      {tabs.map(
        (tab) =>
          !tab.isHidden && (
            <TabPane tab={tab.title} key={tab.key}>
              {tab.content}
            </TabPane>
          )
      )}
    </Tabs>
  );
};

export default TabsContainer;
