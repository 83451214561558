import React from 'react';
import { Button, Tooltip } from 'antd';

import palette from 'palette';

import { ExportIcon } from './styles';

interface HeaderExportExtraProps {
  tooltipText: string;
  onExportButtonClick: () => void;
}

export const HeaderExportExtra: React.FC<HeaderExportExtraProps> = ({ tooltipText, onExportButtonClick }) => (
  <div>
    <Tooltip
      placement="bottomRight"
      title={<span style={{ color: palette.black.b1 }}>{tooltipText}</span>}
      color={palette.white.w1}
    >
      <Button type="text" onClick={onExportButtonClick} icon={<ExportIcon rotate={90} />} />
    </Tooltip>
  </div>
);
