export enum Modal {
  EDIT_USER = 'editUser',
  NEW_USER = 'newUser',
  EDIT_GROUP = 'editGroup',
  NEW_GROUP = 'newGroup',
  PLATFORM_TRANSITS_SCHEMA = 'platformTransitsSchema',
  PLATFORM_OVERVIEW = 'platformOverview',
  EDIT_PLATFORM = 'editPlatform',
  UPLOAD_PLATFORM_MEDIA = 'uploadPlatformMedia',
  EDIT_WIMS = 'editWims',
  EDIT_PLATFORM_GROUPS = 'editWimPlatformGroups',
  NEW_PLATFORM = 'newPlatform',
  EDIT_DEVICE_CERTIFICATE = 'editCertificate',
}
