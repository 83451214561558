import React, { useEffect, useState } from 'react';

import { Row } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

import { WimPlatformSummary } from 'store/wim/types/platforms';
import { Loader } from 'components';
import useWimState from 'pages/Wim/hooks/use-wim-state';
import { LaneInfo } from 'store/wim/normalization';
import { DetailType } from 'pages/Violations';
import { Lanes } from './Lanes';
import { StyledWimContainer, StyledWimsSm } from './styles';

interface WimProps {
  directionName: string;
  direction: boolean;
  year: number;
  lanesInfo: LaneInfo[];
  start?: number;
  end?: number;
  showDetail: (detailType: DetailType, date: number, laneUid?: string) => void;
}

export type CurrentLane = {
  type: string;
} & WimPlatformSummary;

const Wim: React.FC<WimProps> = ({ direction, directionName, lanesInfo, year, start, end, showDetail }) => {
  const [lanes, setLanes] = useState<CurrentLane[]>([]);
  const { isSummaryLoading, normalizedSummary } = useWimState();

  useEffect(() => {
    if (normalizedSummary?.lanes?.byUid) {
      const newLanes = lanesInfo.map((lane) => ({ ...normalizedSummary.lanes.byUid[lane.uid], type: lane.laneType }));
      setLanes(newLanes);
    }
  }, [normalizedSummary]);

  return (
    <StyledWimContainer>
      <Loader isLoading={isSummaryLoading}>
        <Row gutter={[5, 5]}>
          <StyledWimsSm span={3}>
            <span>{directionName}</span>
            <br />
            {!direction ? <ArrowLeftOutlined /> : <ArrowRightOutlined />}
          </StyledWimsSm>
          <Lanes lanes={lanes} showDetail={showDetail} year={year} start={start} end={end} />
        </Row>
      </Loader>
    </StyledWimContainer>
  );
};

export default Wim;
