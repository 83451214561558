import { all, takeEvery } from 'redux-saga/effects';
import { getWimMonitoringMetrics } from 'api/wim';
import { getWimMonitoringMetricStatistics, getWimMonitoringMetricStatisticsByTime } from 'api/statistics';
import { GetWimMonitoringMetricStatisticsParams } from 'api/types/statistics';
import { SagaPayload } from 'types';
import makeRequestSaga from 'store/common';
import { wimMonitoringActions } from './slice';
import { WimPlatformUid } from '../types';

function* wimMonitoringMetricsRequestSaga() {
  yield makeRequestSaga(
    wimMonitoringActions.getWimMonitoringMetricsSuccess,
    wimMonitoringActions.getWimMonitoringMetricsError,
    getWimMonitoringMetrics
  );
}

function* wimMonitoringMetricsRequestWatchSaga() {
  yield takeEvery(wimMonitoringActions.requestWimMonitoringMetrics, wimMonitoringMetricsRequestSaga);
}

function* wimsMonitoringMetricStatisticsRequestSaga({ payload }: SagaPayload<GetWimMonitoringMetricStatisticsParams>) {
  yield makeRequestSaga(
    wimMonitoringActions.getWimsMonitoringMetricStatisticsSuccess,
    wimMonitoringActions.getWimsMonitoringMetricStatisticsError,
    getWimMonitoringMetricStatistics,
    payload
  );
}

function* wimsMonitoringMetricStatisticsRequestWatchSaga() {
  yield takeEvery(
    wimMonitoringActions.requestWimsMonitoringMetricStatistics,
    wimsMonitoringMetricStatisticsRequestSaga
  );
}

function* wimsMonitoringMetricStatisticsByTimeRequestSaga({
  payload,
}: SagaPayload<GetWimMonitoringMetricStatisticsParams & { wimUid: WimPlatformUid }>) {
  yield makeRequestSaga(
    wimMonitoringActions.getWimMonitoringMetricStatisticsByTimeSuccess,
    wimMonitoringActions.getWimMonitoringMetricStatisticsByTimeError,
    getWimMonitoringMetricStatisticsByTime,
    payload
  );
}

function* wimsMonitoringMetricStatisticsByTimeRequestWatchSaga() {
  yield takeEvery(
    wimMonitoringActions.requestWimMonitoringMetricStatisticsByTime,
    wimsMonitoringMetricStatisticsByTimeRequestSaga
  );
}

export function* wimMonitoringWatchSaga() {
  yield all([
    wimsMonitoringMetricStatisticsByTimeRequestWatchSaga(),
    wimsMonitoringMetricStatisticsRequestWatchSaga(),
    wimMonitoringMetricsRequestWatchSaga(),
  ]);
}
