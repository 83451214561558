import { combineReducers } from '@reduxjs/toolkit';

import * as wimTtransitViolationActions from './wim/transits/slices';
import wimTtransitViolationSlice from './wim/transits/slices';

const wimsSlices = combineReducers({
  transits: wimTtransitViolationSlice,
});

export const wimsActions = {
  transits: { ...wimTtransitViolationActions },
};

export default wimsSlices;
