import React, { useState } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { StyledTitle, StyledInfo } from './styles';

const { Paragraph } = Typography;
interface CollapsedInfoProps {
  info: string;
}

export const CollapsedInfo: React.FC<CollapsedInfoProps> = ({ info }) => {
  const [isShowErrorInfo, setIsShowErrorInfo] = useState(true);

  const toggleCollapseHandler = () => {
    setIsShowErrorInfo(!isShowErrorInfo);
  };

  return (
    <Paragraph>
      <StyledTitle>
        <CloseCircleOutlined className="result-error-icon" onClick={toggleCollapseHandler} />
        Error detail {'>>'}
      </StyledTitle>
      <StyledInfo>{isShowErrorInfo && <div className="result-error-collapsed-info">{info}</div>}</StyledInfo>
    </Paragraph>
  );
};
