import { API_URLS } from 'app-constants/api';
import { ApiRequest } from 'types';
import secondaryHttpService from '../services/secondary-http.service';
import { UpdateCertificates } from '../store/admin/certificates/types';

export const getCertificates: ApiRequest = () => secondaryHttpService.get(API_URLS.deviceCertificates(), {});

export const updateCertificates =
  (data: UpdateCertificates): ApiRequest =>
  () => {
    return secondaryHttpService.put(API_URLS.deviceCertificates(), data);
  };
