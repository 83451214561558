import React, { useEffect } from 'react';

import { DefaultTableContainer } from 'components';
import { ComponentFilterProps, withFilter } from 'hoc/withFilter';
import useGetPlatforms from 'pages/Wim/hooks/use-get-platforms';
import { useHeaderWithFiltersRenderer } from 'components/HeaderFilter';
import { INITIAL_TRANSITS_FILTER_VALUES } from 'store/wim/filter/transits';
import { ComparativeAnalysisFilterValues } from 'store/wim/types/transits';
import wimSelectors from 'store/wim/selectors';
import { wimActions } from 'store/wim/slices';
import { useAppDispatch, useAppSelector } from 'hooks/store-hooks';

import { getTransitsAnalysisTableColumns, TransitsAnalysisTableRow } from './table-schema';
import ComparativeAnalysisFilterPanel from './ComparativeAnalysisFilterPanel';
import { queryParamsToFilterValues } from './ComparativeAnalysisFilterPanel/schema';
import ComingSoon from '../../../components/Common/ComingSoon';

const ComparativeAnalysisPage: React.FC<ComponentFilterProps<ComparativeAnalysisFilterValues>> = ({
  filterManager,
}) => {
  useGetPlatforms();
  useHeaderWithFiltersRenderer({ filterManager });
  const columns = Object.values(getTransitsAnalysisTableColumns());
  const dispatch = useAppDispatch();

  const transits = useAppSelector(wimSelectors.comparativeAnalysis.getAnalysisTransits);
  const isLoading = useAppSelector(wimSelectors.comparativeAnalysis.isLoading);

  useEffect(() => {
    dispatch(wimActions.comparativeAnalysis.requestTransits());
  }, []);

  const comingSoon = false;

  return (
    <div style={{ height: '100%' }}>
      {comingSoon && <ComingSoon />}
      {!comingSoon && (
        <DefaultTableContainer<TransitsAnalysisTableRow>
          loading={isLoading}
          dataSource={transits?.items}
          columns={columns}
          showSorterTooltip={false}
          scroll={{ y: 500 }}
          size="small"
          rowClassName={(record) => (record.offenceTypes.length ? 'ant-error-row' : '')}
          rowKey={(record) => record.id}
        />
      )}
    </div>
  );
};

export default withFilter<ComparativeAnalysisFilterValues>({
  filterProps: {
    initialState: INITIAL_TRANSITS_FILTER_VALUES,
    formatUrlParams: queryParamsToFilterValues,
  },
  FilterContent: ComparativeAnalysisFilterPanel,
})(ComparativeAnalysisPage);
