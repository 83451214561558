import { takeEvery } from 'redux-saga/effects';

import makeRequestSaga from 'store/common';
import { getWimVehicleClassifications } from 'api/wim';
import { SagaPayload } from 'types';
import { GetWimVehicleClassificationsQueryParams } from '../types';
import {
  requestWimVehicleClassifications,
  getWimVehicleClassificationsSuccess,
  getWimVehicleClassificationsError,
} from './slice';

export function* wimVehicleClassificationsRequestSaga({
  payload,
}: SagaPayload<GetWimVehicleClassificationsQueryParams>) {
  yield makeRequestSaga(
    getWimVehicleClassificationsSuccess,
    getWimVehicleClassificationsError,
    getWimVehicleClassifications,
    payload
  );
}

export function* wimVehicleClassificationsRequestWatchSaga() {
  yield takeEvery(requestWimVehicleClassifications, wimVehicleClassificationsRequestSaga);
}
