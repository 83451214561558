import styled from 'styled-components/macro';

export const StyledSemiBoldValue = styled.span`
  font-weight: 600;
  font-size: 14px;
`;

export const StyledLabel = styled.span`
  width: 30%;
`;
