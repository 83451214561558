import styled from 'styled-components/macro';

import { StyledCard } from 'components/Cards/styles';

export const StyledCharts = styled.div`
  width: 50%;
  padding: 10px;
`;

export const StyledChartBlock = styled(StyledCard)`
  margin-bottom: 10px;
  width: 100%;
  min-height: 200px;
`;
