import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetWimGroupBindingsResponse, PostGroupBindings, WimGroupBinding } from './types';

interface InitialState {
  isUpdateGroupsLoading: boolean;
  isCreateGroupsLoading: boolean;
  isGetGroupsLoading: boolean;
  result: any | null;
  error: any;
  getGroupsResult: GetWimGroupBindingsResponse | null;
  getGroupsError: any;
}

const initialState: InitialState = {
  isUpdateGroupsLoading: false,
  isCreateGroupsLoading: false,
  isGetGroupsLoading: false,
  result: null,
  error: null,
  getGroupsResult: null,
  getGroupsError: null,
};

const wimGroups = createSlice({
  name: 'wimGroups',
  initialState,
  reducers: {
    requestGetGroupBindings: (state): void => {
      state.isGetGroupsLoading = true;
    },
    requestGetGroupBindingsSuccess: (state, { payload }: PayloadAction<GetWimGroupBindingsResponse>): void => {
      state.getGroupsResult = payload;
      state.isGetGroupsLoading = false;
    },
    requestGetGroupBindingsError: (state, { payload }: PayloadAction<string>): void => {
      state.getGroupsError = payload;
      state.isGetGroupsLoading = false;
    },
    requestPostGroupBindings: (state, { payload }: PayloadAction<PostGroupBindings>): void => {
      state.isGetGroupsLoading = true;
    },
    requestPostGroupBindingsSuccess: (state, { payload }: PayloadAction<WimGroupBinding>): void => {
      // state.getGroupsResult = state.getGroupsResult ? [...state.getGroupsResult, { wimUuid: payload.] : [payload];
      state.isGetGroupsLoading = false;
    },
    requestPostGroupBindingsError: (state, { payload }: PayloadAction<string>): void => {
      state.getGroupsError = payload;
      state.isGetGroupsLoading = false;
    },
    requestDeleteGroupBindings: (state, { payload }: PayloadAction<PostGroupBindings>): void => {
      state.isGetGroupsLoading = true;
    },
    requestDeleteGroupBindingsSuccess: (state, { payload }: PayloadAction<any>): void => {
      // TODO: remove group
      state.isGetGroupsLoading = false;
    },
    requestDeleteGroupBindingsError: (state, { payload }: PayloadAction<string>): void => {
      state.getGroupsError = payload;
      state.isGetGroupsLoading = false;
    },
  },
});

export const {
  requestGetGroupBindings,
  requestGetGroupBindingsSuccess,
  requestGetGroupBindingsError,
  requestPostGroupBindings,
  requestPostGroupBindingsSuccess,
  requestPostGroupBindingsError,
  requestDeleteGroupBindings,
  requestDeleteGroupBindingsSuccess,
  requestDeleteGroupBindingsError,
} = wimGroups.actions;

export default wimGroups.reducer;
