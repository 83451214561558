import { all } from 'redux-saga/effects';

import {
  intensityTrafficRequestWatchSaga,
  wimTransitPhotoRequestWatchSaga,
  wimTransitPhotosRequestWatchSaga,
  wimTransitRequestWatchSaga,
  wimTransitsRequestWatchSaga,
  wimTransitWithCoordinatesByPlateRequestWatchSaga,
} from './transits/saga';
import {
  wimPlatformsRequestWatchSaga,
  wimPlatformsSummaryRequestWatchSaga,
  wimPlatformSummaryRequestWatchSaga,
} from './platforms/saga';
import { wimVehicleClassificationsRequestWatchSaga } from './vehicle-classifications/saga';
import { activeWimPlatformRequestWatchSaga } from './active-platform/saga';
import { wimMonitoringWatchSaga } from './monitoring/saga';
import { wimTransitsAnalysisRequestWatchSaga } from './comparative-analysis/saga';
import {
  wimTransitsExportDocumentRequestWatchSaga,
  wimTransitsExportDocumentStatusRequestWatchSaga,
  wimTransitsExportDocumentFileRequestWatchSaga,
  wimTransitsExportDocumentCancelRequestWatchSaga,
} from './transits/documentExport/saga';

function* wimWatchSaga() {
  yield all([
    wimTransitsRequestWatchSaga(),
    wimTransitRequestWatchSaga(),
    wimTransitPhotoRequestWatchSaga(),
    wimTransitPhotosRequestWatchSaga(),
    wimPlatformsRequestWatchSaga(),
    wimPlatformSummaryRequestWatchSaga(),
    wimPlatformsSummaryRequestWatchSaga(),
    wimVehicleClassificationsRequestWatchSaga(),
    activeWimPlatformRequestWatchSaga(),
    intensityTrafficRequestWatchSaga(),
    wimMonitoringWatchSaga(),
    wimTransitsAnalysisRequestWatchSaga(),
    wimTransitsExportDocumentRequestWatchSaga(),
    wimTransitsExportDocumentStatusRequestWatchSaga(),
    wimTransitsExportDocumentFileRequestWatchSaga(),
    wimTransitsExportDocumentCancelRequestWatchSaga(),
    wimTransitWithCoordinatesByPlateRequestWatchSaga(),
  ]);
}

export default wimWatchSaga;
