import { Button, Spin } from 'antd';
import { ErrorMessage, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { adminActions } from 'store/admin/slices';
import { FormikInputFieldWithLabel } from 'elements/FormikInputFieldWithLabel';
import palette from 'palette';
import { useGetSettings } from './hooks/use-get-settings';
import { useSettingsState } from './hooks/use-settings-state';
import {
  StyledButtons,
  StyledContent,
  StyledContentForm,
  StyledEditBlock,
  StyledErrorMessage,
  StyledSummary,
} from './styles';
import { SettingsFieldName, UpdateSettingsValues } from './types';
import { updateSettingsValidationSchema } from './validation-schema';

const SettingsPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { settings, isSettingsLoading, isUpdateSettingsLoading, settingsError } = useSettingsState();

  useGetSettings();

  const onSubmitSettings = (values: UpdateSettingsValues) => {
    if (!values.accessTokenExpiration) {
      return;
    }

    dispatch(adminActions.systemSettings.requestPostSettings(values as any));
  };

  return (
    <StyledContent>
      <StyledSummary>
        <Formik<UpdateSettingsValues>
          initialValues={{
            accessTokenExpiration: settings?.accessTokenExpiration,
          }}
          enableReinitialize
          validationSchema={updateSettingsValidationSchema}
          onSubmit={(submittedValues) => {
            onSubmitSettings({
              ...submittedValues,
            });
          }}
        >
          {({ values, handleChange, handleSubmit, errors }) => (
            <StyledContentForm onSubmit={handleSubmit}>
              <Spin spinning={isSettingsLoading || isUpdateSettingsLoading}>
                <StyledEditBlock>
                  <FormikInputFieldWithLabel
                    name={SettingsFieldName.accessTokenExpiration}
                    onChange={handleChange}
                    style={{ borderColor: errors.accessTokenExpiration ? palette.red.r3 : palette.grey.g5 }}
                    label={t('infoFields.accessTokenExpiration')}
                    defaultValue={values.accessTokenExpiration || 0}
                    type="number"
                  />

                  <ErrorMessage component={StyledErrorMessage} name={SettingsFieldName.accessTokenExpiration} />
                </StyledEditBlock>
              </Spin>
              <StyledButtons>
                <Button
                  disabled={isSettingsLoading || isUpdateSettingsLoading}
                  htmlType="submit"
                  style={{ color: palette.white.w1, backgroundColor: palette.blue.b1 }}
                >
                  {t('common.apply')}
                </Button>
              </StyledButtons>
              {settingsError?.userMessage && <StyledErrorMessage>{settingsError.userMessage}</StyledErrorMessage>}
            </StyledContentForm>
          )}
        </Formik>
      </StyledSummary>
    </StyledContent>
  );
};

export default SettingsPage;
