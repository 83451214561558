import styled from 'styled-components/macro';

import { Card } from 'antd';

import palette from 'palette';
import SFUITextMedium from 'fonts/SFUIText-Medium.woff';

export const StyledCard = styled(Card)`
  width: 100%;
  min-height: 270px;
  min-width: 260px;
  padding: 20px 24px 10px 24px;
  position: relative;

  .ant-card-head {
    border-bottom: none;
    min-height: 30px;
    padding: 0;
  }

  .ant-card-head-title {
    white-space: normal;

    > span {
      font-family: 'SFUITextMedium';
      font-size: 16px !important;
      src: url(${SFUITextMedium}) format('woff');
    }

    display: flex;
    flex-direction: column;
    padding: 0;
    font-family: 'SFUITextMedium';
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    position: absolute;
    bottom: 20px;
    width: calc(100% - 24px * 2);

    &-value {
      color: ${palette.black.b4};
      font-size: 30px;
      letter-spacing: 0;
      line-height: 38px;
    }

    &-disparity {
      color: ${palette.green.g5};
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 19px;
    }
  }

  .ant-card-footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid ${palette.grey.g3};
    padding: 8px 0 0 0;

    &.home {
      flex-direction: column;
      align-items: center;
    }
  }
`;
