import React from 'react';

import { VendorErrorOld } from 'store/wim/types';

export function getTooltipList(values: string[] | VendorErrorOld[], handler?: (error: VendorErrorOld) => string) {
  const content: JSX.Element[] = [];

  values.forEach((value: string | VendorErrorOld) =>
    typeof value === 'string'
      ? content.push(<li key={value}>{value}</li>)
      : content.push(<li key={value.id}>{handler && handler(value)}</li>)
  );

  return <ul style={{ margin: 0, paddingLeft: 30 }}>{content}</ul>;
}
