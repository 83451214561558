import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Field, FieldProps, Formik } from 'formik';
import { useLocation } from 'react-router';

import { useAppDispatch } from 'hooks/store-hooks';
import palette from 'palette';
import { authActions } from 'store/auth/slice';
import { userSelectors } from 'store/user/selectors';
import { authSelectors } from 'store/auth/selectors';
import { LoginFormValues, LoginFormValue } from '../types';
import { signInValidationSchema } from '../validation-schema';
import { initialValues } from './initial-values';
import {
  StyledButton,
  StyledCheckbox,
  StyledErrorMessage,
  StyledForm,
  StyledInputEmail,
  StyledInputPassword,
} from '../styles';

interface LoginProps {
  onSubmit: (submittedValues: LoginFormValues) => void;
}

const Login: React.FC<LoginProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const isUserLoading = useSelector(userSelectors.isUserLoading);
  const loginError = useSelector(authSelectors.getLoginError);
  const dispatch = useAppDispatch();

  return (
    <Formik<LoginFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={signInValidationSchema}
    >
      <StyledForm>
        <Field name={LoginFormValue.email}>
          {({ field }: FieldProps) => (
            <StyledInputEmail
              {...field}
              placeholder={t('loginForm.username')}
              style={{ borderColor: loginError.userMessage ? palette.red.r3 : palette.grey.g5 }}
            />
          )}
        </Field>
        <Field name={LoginFormValue.password}>
          {({ field }: FieldProps) => (
            <StyledInputPassword
              {...field}
              placeholder={t('loginForm.password')}
              style={{ borderColor: loginError.userMessage ? palette.red.r3 : palette.grey.g5 }}
              iconRender={(visible: boolean) =>
                visible ? <EyeTwoTone style={{ fontSize: 18 }} /> : <EyeInvisibleOutlined style={{ fontSize: 18 }} />
              }
            />
          )}
        </Field>
        {loginError.userMessage && <StyledErrorMessage>{loginError.userMessage}</StyledErrorMessage>}
        <Field as={StyledCheckbox} name={LoginFormValue.remember} valuePropName="checked" noStyle>
          {t('loginForm.remember')}
        </Field>
        <StyledButton type="primary" htmlType="submit" loading={isUserLoading}>
          {t('loginForm.login')}
        </StyledButton>
      </StyledForm>
    </Formik>
  );
};

export default Login;
