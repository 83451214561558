import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultTableContainer } from 'components/TableContainer/DefaultTableContainer';
import InfoText from 'elements/InfoText';
import { User } from 'store/user/types';
import { useDeviceGroupsNames } from 'pages/AdminManagement/Users/hooks/use-device-groups-names';
import { userInfoColumns, userInfoDataSource, userSocialNetworksColumns } from './table-schema';
import { StyledInfoBlock } from './styles';

interface InfoContentProps {
  state: User;
}

export const InfoContent: FC<InfoContentProps> = ({ state }) => {
  const { getDeviceGroupsNames } = useDeviceGroupsNames();
  const infoColumns = Object.values(userInfoColumns);
  const infoDataSource = userInfoDataSource(state, getDeviceGroupsNames);
  const socialNetworksColumns = Object.values(userSocialNetworksColumns);
  const { t } = useTranslation();

  return (
    <StyledInfoBlock>
      <InfoText label={t('infoFields.mainInfo')} value={' '} />
      <DefaultTableContainer columns={infoColumns} dataSource={infoDataSource} bordered />
      <br />
      <InfoText label={t('infoFields.socialNetworks')} value={' '} />
      <DefaultTableContainer columns={socialNetworksColumns} dataSource={state.socialNetworks} bordered />
    </StyledInfoBlock>
  );
};
