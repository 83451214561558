import React from 'react';
import { useTranslation } from 'react-i18next';

import { plateFormatter } from 'helpers/label.formatter';

import { PhotoPlaceholder, PlatePhotoContainer, StyledImg } from './styles';
import { TransitInfoItem } from '../TransitInfoItem';

interface PlatePhotoProps {
  title: string;
  plate: string;
  isPlateRecognized: boolean;
  photoSrc?: string;
}

export const PlatePhoto: React.FC<PlatePhotoProps> = ({ title, plate, isPlateRecognized, photoSrc }) => {
  const { t } = useTranslation();
  return (
    <PlatePhotoContainer>
      <TransitInfoItem
        title={title}
        value={isPlateRecognized ? plateFormatter(plate) : t('transitPage.plateNotRecognized')}
      />
      {photoSrc ? (
        <StyledImg width={110} height={40} src={photoSrc} alt="plate" />
      ) : (
        <PhotoPlaceholder>{t('transitPage.noPhoto')}</PhotoPlaceholder>
      )}
    </PlatePhotoContainer>
  );
};
