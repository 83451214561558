import * as transitsSelectors from './transits/selectors';
import * as platformsSelectors from './platforms/selectors';
import * as vehicleClassificationsSelectors from './vehicle-classifications/selectors';
import * as activePlatformSelectors from './active-platform/selectors';
import * as monitoringSelectors from './monitoring/selectors';
import * as comparativeAnalysisSelectors from './comparative-analysis/selectors';

const wimSelectors = {
  transits: {
    ...transitsSelectors,
  },
  platforms: {
    ...platformsSelectors,
  },
  vehicleClassifications: {
    ...vehicleClassificationsSelectors,
  },
  activePlatform: {
    ...activePlatformSelectors,
  },
  monitoring: {
    ...monitoringSelectors,
  },
  comparativeAnalysis: {
    ...comparativeAnalysisSelectors,
  },
};

export default wimSelectors;
