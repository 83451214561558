import React from 'react';
import { Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { DEFAULT_ONLINE_MODE_SECONDS_RANGE, DEFAULT_ONLINE_MODE_VIEW_LIMIT_RANGE } from 'app-constants/defaults';

import { OnlineModeSettingsContainer, Text, RefreshTimeContainer, SelectContainer } from './styles';

const { Option } = Select;

interface OnlineModeSettingsProps {
  refreshTime: number;
  viewLimit: number;
  onRefreshTimeChange: (value: number) => void;
  onViewLimitChange: (value: number) => void;
}

export const OnlineModeSettings: React.FC<OnlineModeSettingsProps> = ({
  refreshTime,
  viewLimit,
  onRefreshTimeChange,
  onViewLimitChange,
}) => {
  const { t } = useTranslation();

  return (
    <OnlineModeSettingsContainer>
      <RefreshTimeContainer direction="horizontal">
        <Text>{t('transitsPage.header.refreshTimeTitle')}</Text>
        <SelectContainer width={100}>
          <Select value={refreshTime} onChange={onRefreshTimeChange}>
            {DEFAULT_ONLINE_MODE_SECONDS_RANGE.map((timeRange) => (
              <Option key={timeRange} value={timeRange}>
                {timeRange} {t('glossary.secondShort')}
              </Option>
            ))}
          </Select>
        </SelectContainer>
      </RefreshTimeContainer>
      <Space direction="horizontal">
        <Text>{t('transitsPage.header.viewLimitTitle')}</Text>
        <SelectContainer width={86}>
          <Select value={viewLimit} onChange={onViewLimitChange}>
            {DEFAULT_ONLINE_MODE_VIEW_LIMIT_RANGE.map((pageSize) => (
              <Option key={pageSize} value={pageSize}>
                {pageSize}
              </Option>
            ))}
          </Select>
        </SelectContainer>
        <Text>{t('glossary.transit', { transits: viewLimit })}</Text>
      </Space>
    </OnlineModeSettingsContainer>
  );
};
