import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Tooltip } from 'antd';

import { ContentFilterProps } from 'hoc/withFilter';
import { SelectValue } from 'antd/lib/select';
import { StyledFilterInputSearch, StyledFilterSelectSearch } from './styles';
import { WimsGridFilterFormValues } from './types';
import { UAD_WIM_STATUSES, UAD_LANES, UAD_REGIONS } from '../../../app-constants/static-dictionaries';

const WimsGridFilter: FC<ContentFilterProps<WimsGridFilterFormValues>> = ({ filterManager }) => {
  const { t } = useTranslation();
  const { handleFilterChange, state, onFilterSubmitClick } = filterManager;

  const onWimChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFilterChange('search', event.currentTarget.value);
  };
  const onRoadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFilterChange('road', event.currentTarget.value);
  };
  const onRegionSelect = (value: SelectValue) => {
    handleFilterChange('region', value);
  };
  const onProviderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFilterChange('provider', event.currentTarget.value);
  };

  const onStatusSelect = (value: SelectValue) => {
    handleFilterChange('status', value);
  };

  const onLaneChange = (value: SelectValue) => {
    handleFilterChange('lanes', value);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onFilterSubmitClick();
    }
  };

  return (
    <>
      <Row gutter={[16, 24]}>
        <Col span={6} offset={1}>
          <Tooltip placement="right" title={t('infoFields.wimTooltip')}>
            <StyledFilterInputSearch
              id="search"
              name="search"
              placeholder={t('infoFields.wim')}
              onChange={onWimChange}
              value={state.search}
              onKeyDown={onKeyDown}
            />
          </Tooltip>
        </Col>
        <Col span={6} offset={1}>
          <StyledFilterInputSearch
            id="road"
            name="road"
            placeholder={t('infoFields.road')}
            onChange={onRoadChange}
            value={state.road}
            onKeyDown={onKeyDown}
          />
        </Col>
        <Col span={6} offset={1}>
          <StyledFilterInputSearch
            id="provider"
            name="provider"
            placeholder={t('infoFields.provider')}
            onChange={onProviderChange}
            value={state.provider}
            onKeyDown={onKeyDown}
          />
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={6} offset={1}>
          <StyledFilterSelectSearch
            placeholder={t('infoFields.region')}
            onSelect={onRegionSelect}
            options={UAD_REGIONS}
            value={state.region}
          />
        </Col>
        <Col span={6} offset={1}>
          <StyledFilterSelectSearch
            placeholder="К-ть смуг руху"
            onSelect={onLaneChange}
            options={UAD_LANES}
            value={state.lanes}
          />
        </Col>
        <Col span={6} offset={1}>
          <StyledFilterSelectSearch
            placeholder={t('infoFields.status')}
            onSelect={onStatusSelect}
            options={UAD_WIM_STATUSES}
            value={state.status}
          />
        </Col>
      </Row>
    </>
  );
};

export { WimsGridFilter };
