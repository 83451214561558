import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransitsArchiveFilterValues } from '../../types';
import { GetExportDocumentStatusResponse } from './saga';

export enum DOCUMENT_STATUS {
  'AWAITING' = 'AWAITING',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'LIMIT_EXCEEDED' = 'LIMIT_EXCEEDED',
  'COMPLETED' = 'COMPLETED',
  'INTERRUPTED' = 'INTERRUPTED',
  'FAILED' = 'FAILED',
}
export interface TransitExportDocumentPayloadInt {
  key: string;
}

type InitialState = {
  isLoading: boolean;
  fileIsLoading: boolean;
  status: DOCUMENT_STATUS | null;
  data: string | null;
  error: string | unknown;
  file: string | null;
};

const initialState: InitialState = {
  isLoading: false,
  fileIsLoading: false,
  status: null,
  data: null,
  error: null,
  file: null,
};

const documentExport = createSlice({
  name: 'documentExport',
  initialState,
  reducers: {
    setDocumentExportInitialState: (state) => {
      state.status = null;
    },
    requestTransitsExportDocument: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = true;
      state.status = DOCUMENT_STATUS.AWAITING;
    },
    getTransitsExportDocumentSuccess: (state, { payload }: PayloadAction<TransitExportDocumentPayloadInt>) => {
      state.isLoading = false;
      state.data = payload.key;
      state.status = DOCUMENT_STATUS.IN_PROGRESS;
    },
    getTransitsExportDocumentError: (state, { payload }: PayloadAction<string>): void => {
      state.isLoading = false;
      state.error = payload;
      state.status = DOCUMENT_STATUS.FAILED;
    },
    requestExportDocumentStatus: (state, { payload }: PayloadAction<string>) => {},
    getExportDocumentStatusSuccess: (state, { payload }: PayloadAction<GetExportDocumentStatusResponse>) => {
      state.status = payload.status;
    },
    getExportDocumentStatusError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.status = DOCUMENT_STATUS.FAILED;
    },
    requestExportDocumentFile: (state, { payload }: PayloadAction<{ key: string; fileName: string }>) => {
      state.fileIsLoading = true;
    },
    getExportDownloadFileSuccess: (state, { payload }: PayloadAction<unknown>) => {
      state.fileIsLoading = false;
      state.status = null;
    },
    getExportDownloadFileError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.fileIsLoading = false;
      state.status = null;
    },
    requestExportDocumentCancel: (state, { payload }: PayloadAction<string>) => {
      // DELETE /api/events/export/cancel?key=<>
    },
  },
});

export const {
  setDocumentExportInitialState,
  requestTransitsExportDocument,
  getTransitsExportDocumentSuccess,
  getTransitsExportDocumentError,
  requestExportDocumentStatus,
  getExportDocumentStatusSuccess,
  getExportDocumentStatusError,
  requestExportDocumentFile,
  getExportDownloadFileSuccess,
  getExportDownloadFileError,
  requestExportDocumentCancel,
} = documentExport.actions;

export default documentExport.reducer;
