import palette from 'palette';
import styled from 'styled-components/macro';

export const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const StyledSummary = styled.div`
  padding: 24px;
  margin: 24px;
  height: calc(100% - 48px);
  background-color: ${palette.white.w1};
`;

export const StyledContentForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  max-width: 70%;
`;

export const StyledEditBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 24px;
  font-size: 16px;
  row-gap: 4px;
  min-height: 80px;
`;

export const StyledButtons = styled.div`
  display: flex;
  column-gap: 15px;
  align-self: flex-end;
`;

export const StyledErrorMessage = styled.div`
  font-family: 'SF UI Text';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  color: ${palette.red.r3};
  align-self: flex-end;
`;
