/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipProps } from 'recharts';
import { NameType } from 'recharts/types/component/DefaultTooltipContent';

import { formatIntegerPercent } from 'helpers/percent.formatter';
import {
  StyledBody,
  StyledColumn,
  StyledLegend,
  StyledMarker,
  StyledRectangle,
  StyledRow,
  StyledTable,
  StyledTooltip,
  StyledValue,
} from './styles';

function PlateRecognitionTooltip({ payload }: TooltipProps<number, NameType>): JSX.Element | null {
  const { t } = useTranslation();

  if (!payload) {
    return null;
  }

  const items = payload.map(({ dataKey, value, payload, color }) => ({
    dataKey,
    value,
    percent: value ? formatIntegerPercent(value / (payload.unrecognizedKey + payload.recognizedKey)) : '0%',
    color,
  }));

  const total = items.reduce((prev, curr) => prev + (curr.value || 0), 0);
  const fullDate = payload[0]?.payload?.fullDate;

  return (
    <StyledTooltip>
      <StyledTable>
        <StyledBody>
          {items.map((item) => (
            <StyledRow key={`row-${item.dataKey}`}>
              <StyledColumn>
                <StyledValue>{item.value}</StyledValue>
              </StyledColumn>
              <StyledColumn>
                <StyledValue>{item.percent}</StyledValue>
              </StyledColumn>
              <StyledColumn>
                <StyledLegend>
                  <StyledMarker color={item.color as string}>
                    <StyledRectangle fill={item.color} />
                  </StyledMarker>
                  {item.dataKey === 'recognizedKey'
                    ? t('map.mapModal.recognizedPlate')
                    : t('map.mapModal.unrecognizedPlate')}
                </StyledLegend>
              </StyledColumn>
            </StyledRow>
          ))}
          <StyledRow>
            <StyledColumn>
              <StyledValue accented>{total}</StyledValue>
            </StyledColumn>
            <StyledColumn />
            <StyledColumn>
              <StyledValue accented small align="left">
                {t('common.total')}
              </StyledValue>
            </StyledColumn>
          </StyledRow>
        </StyledBody>
      </StyledTable>
      {fullDate}
    </StyledTooltip>
  );
}

export { PlateRecognitionTooltip };
