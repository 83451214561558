import React from 'react';
import i18n from 'i18n';

import { EmptyPieChartWithActiveShape, PieChartWithActiveShape } from 'components';
import { TabListItem } from 'components/Cards/CardWithRadioTabs';
import palette from 'palette';
import { ActiveWimPlatformError } from 'store/wim/types';
import { StyledChartWrapper, StyledContent } from './styles';

export const tabList: TabListItem[] = [
  {
    key: 'today',
    tab: i18n.t('activePlatform.cards.tab.today'),
    isDisabled: false,
  },
  {
    key: 'lastWeek',
    tab: i18n.t('activePlatform.cards.tab.lastWeek'),
    isDisabled: false,
  },
  {
    key: 'lastMonth',
    tab: i18n.t('activePlatform.cards.tab.lastMonth'),
    isDisabled: false,
  },
];

export const legendsList = [
  i18n.t('measurementFlags.invalidWeight'),
  i18n.t('measurementFlags.invalidHeight'),
  i18n.t('measurementFlags.invalidLength'),
  i18n.t('measurementFlags.invalidWidth'),
  i18n.t('measurementFlags.invalidSpeed'),
  i18n.t('measurementFlags.invalidAxlesCount'),
  i18n.t('measurementFlags.invalidAxleLoad'),
  i18n.t('measurementFlags.invalidAxleRatioLoad'),
  i18n.t('measurementFlags.invalidAxle1stLoad'),
  i18n.t('measurementFlags.invalidAxle1stDistance'),
];

const getChartData = (activeWimPlatformError: ActiveWimPlatformError) => [
  {
    name: i18n.t('measurementFlags.invalidWeight'),
    value: activeWimPlatformError.totalWeight,
  },
  {
    name: i18n.t('measurementFlags.invalidHeight'),
    value: activeWimPlatformError.height,
  },
  {
    name: i18n.t('measurementFlags.invalidLength'),
    value: activeWimPlatformError.length,
  },
  {
    name: i18n.t('measurementFlags.invalidWidth'),
    value: activeWimPlatformError.width,
  },
  {
    name: i18n.t('measurementFlags.invalidSpeed'),
    value: activeWimPlatformError.speed,
  },
  {
    name: i18n.t('measurementFlags.invalidAxlesCount'),
    value: activeWimPlatformError.axleCount,
  },
  {
    name: i18n.t('measurementFlags.invalidAxleLoad'),
    value: activeWimPlatformError.axleLoad,
  },
  {
    name: i18n.t('measurementFlags.invalidAxleRatioLoad'),
    value: activeWimPlatformError.axleRatioLoad,
  },
  {
    name: i18n.t('measurementFlags.invalidAxle1stLoad'),
    value: activeWimPlatformError.axleFLoad,
  },
  {
    name: i18n.t('measurementFlags.invalidAxle1stDistance'),
    value: activeWimPlatformError.axleFDistance,
  },
];

const emptyChartData = [
  {
    name: i18n.t('activePlatform.cards.title.noElements'),
    value: 0,
  },
];

const COLORS = [
  palette.purple.p1,
  palette.yellow.y1,
  palette.blue.b6,
  palette.green.g6,
  palette.orange.o1,
  palette.red.r1,
  palette.black.b1,
  palette.blue.b1,
  palette.red.r6,
  palette.grey.g1,
];

export const getContentList = (activeWimPlatformError: ActiveWimPlatformError | null) => {
  if (!activeWimPlatformError) {
    return null;
  }

  const chartValues = [
    activeWimPlatformError.totalWeight,
    activeWimPlatformError.height,
    activeWimPlatformError.length,
    activeWimPlatformError.width,
    activeWimPlatformError.speed,
    activeWimPlatformError.axleCount,
    activeWimPlatformError.axleLoad,
    activeWimPlatformError.axleRatioLoad,
    activeWimPlatformError.axleFLoad,
    activeWimPlatformError.axleFDistance,
  ];
  const isChartEmpty = chartValues.every((value) => value === 0);

  return {
    today: (
      <StyledContent>
        <StyledChartWrapper>
          {isChartEmpty ? (
            <EmptyPieChartWithActiveShape
              data={emptyChartData}
              dataKey="value"
              colors={[palette.grey.g1]}
              width={400}
              height={400}
            />
          ) : (
            <PieChartWithActiveShape
              data={getChartData(activeWimPlatformError)}
              dataKey="value"
              colors={COLORS}
              width={400}
              heigth={400}
            />
          )}
        </StyledChartWrapper>
      </StyledContent>
    ),
    lastWeek: (
      <StyledContent>
        <StyledChartWrapper>
          {isChartEmpty ? (
            <EmptyPieChartWithActiveShape
              data={emptyChartData}
              dataKey="value"
              colors={[palette.grey.g1]}
              width={300}
              height={300}
            />
          ) : (
            <PieChartWithActiveShape
              data={getChartData(activeWimPlatformError)}
              dataKey="value"
              colors={COLORS}
              width={300}
              heigth={300}
            />
          )}
        </StyledChartWrapper>
      </StyledContent>
    ),
    lastMonth: (
      <StyledContent>
        <StyledChartWrapper>
          {isChartEmpty ? (
            <EmptyPieChartWithActiveShape
              data={emptyChartData}
              dataKey="value"
              colors={[palette.grey.g1]}
              width={300}
              height={300}
            />
          ) : (
            <PieChartWithActiveShape
              data={getChartData(activeWimPlatformError)}
              dataKey="value"
              colors={COLORS}
              width={400}
              heigth={400}
            />
          )}
        </StyledChartWrapper>
      </StyledContent>
    ),
  };
};
