import { RootState } from 'store/root-reducer';

import { Certificate } from './types';

export const getCertificates = (state: RootState): Certificate[] | null => state.admin.certificates.entities;
export const isCertificatesLoading = (state: RootState): boolean => state.admin.certificates.isLoading;
export const isUpdatedCertificateLoading = (state: RootState): boolean =>
  state.admin.certificates.isUpdatedCertificateLoading;
export const isCreateCertificateLoading = (state: RootState): boolean =>
  state.admin.certificates.isCreateCertificateLoading;
