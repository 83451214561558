import React, { FC } from 'react';

import { Link } from 'react-router-dom';
import { stringifyUrlParams, useUrlParams } from 'hooks/use-url-params';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'app-constants/routes';

import { ModalWrapper } from 'components';
import { BarChartTransitsByWim } from 'components/BarChart';
import { capitalize } from 'helpers/string.formatter';
import { WimTransitDetails } from 'store/wim/types/transits';
import { TransitsDetailListByPlatform } from './components/TransitsDetailListByPlatform';
import { TransitsDetailListByWim } from './components/TransitsDetailListByWim';
import { StyledDetailCard } from './styles';
import { parsedWimDataFromModal } from '../../helpers/get-platform-for-modal';

export type TransitViolationsByWimType = {
  date: number;
  axle: number;
  both: number;
  full: number;
  direction: string;
};

interface TransitsDetailsByWimModalProps {
  transitsDetailsByWim?: WimTransitDetails;
  isVisible: boolean;
  start?: number;
  end?: number;
  onClose: (isShow: boolean) => void;
}

export enum TransitsDetailsByWimModalKeys {
  averageSpeed = 'averageSpeed',
  averageWeight = 'averageWeight',
  lastTransitDate = 'lastTransitDate',
  maxWeight = 'lastTransitDate',
  minWeight = 'minWeight',
  totalTransits = 'totalTransits',
  totalViolations = 'totalViolations',
  wimName = 'wimName',
  wimUid = 'wimUid',
}

export const TransitsDetailsByWimModal: FC<TransitsDetailsByWimModalProps> = ({
  onClose,
  isVisible,
  transitsDetailsByWim,
  start,
  end,
}) => {
  const { queryUrlParams } = useUrlParams({});
  const { t } = useTranslation();

  if (!transitsDetailsByWim) {
    return null;
  }

  return (
    <ModalWrapper
      isVisible={isVisible}
      width={250}
      onClose={() => onClose(false)}
      title={
        <Link
          to={{
            pathname: ROUTES.TransitsArchiveWim,
            search: stringifyUrlParams({
              filter: {
                plate: queryUrlParams?.plate,
                start: queryUrlParams?.filter?.start || start,
                end: queryUrlParams?.filter?.end || end,
              },
              sort: { key: 'datetime', value: 'descend' },
            }),
          }}
        >
          {queryUrlParams?.plate || capitalize(t('wimsPage.monitoring.filters.unrecognized.vrp'))}
        </Link>
      }
    >
      <StyledDetailCard>
        <TransitsDetailListByPlatform transitsDetailsByWim={parsedWimDataFromModal(transitsDetailsByWim)} />
        <TransitsDetailListByWim lanes={transitsDetailsByWim?.lanes} start={start} end={end} />
      </StyledDetailCard>
    </ModalWrapper>
  );
};
