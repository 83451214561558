import React, { FC } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { SelectValue } from 'antd/lib/select';

import ua from 'locales/ua/translation.json';
import { User, UserStatus } from 'store/user/types';
import { StyledSelect, StyledSelectWithLabel, StyledLabel } from './styles';

type UserStatusLocalesKey = keyof typeof ua.user.status;

interface SelectUserStatusProps {
  name: string;
  onChange: (value: SelectValue) => void;
  value?: User['status'];
}

const { Option } = Select;

export const FormikSelectUserStatus: FC<SelectUserStatusProps> = ({ name, value, onChange }) => {
  const { t } = useTranslation();

  return (
    <StyledSelectWithLabel>
      <StyledLabel>{t('infoFields.status')}</StyledLabel>
      <Field as={StyledSelect} name={name} style={{ width: '100%' }} defaultValue={value} onChange={onChange}>
        {Object.keys(UserStatus).map((status) => (
          <Option key={`user-status-${status}`} value={status}>
            {t(`user.status.${status as UserStatusLocalesKey}` as const)}
          </Option>
        ))}
      </Field>
    </StyledSelectWithLabel>
  );
};
