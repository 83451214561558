import React, { PropsWithChildren, useContext, useEffect } from 'react';

import { HeaderConfigContext } from 'app/App';
import { Button } from 'antd';
import { FilterIcon } from 'pages/Transits/OnlineTabs/styles';
import palette from 'palette';
import { ComponentFilterProps } from 'hoc/withFilter';
import { StyledExtraButtons } from './styles';

export function HeaderFilter<FilterValues>({
  filterManager,
  children,
}: PropsWithChildren<ComponentFilterProps<FilterValues>>): JSX.Element {
  const { isFilterActive, handleFilterClose, handleFilterOpen } = filterManager;
  return (
    <StyledExtraButtons>
      <Button
        type="text"
        icon={
          <FilterIcon
            iconcolor={isFilterActive ? palette.green.g1 : palette.black.b1}
            onClick={() => (isFilterActive ? handleFilterClose() : handleFilterOpen())}
          />
        }
      />
      {children}
    </StyledExtraButtons>
  );
}

export function useHeaderWithFiltersRenderer<FilterValues>({
  filterManager,
}: ComponentFilterProps<FilterValues>): void {
  const { setHeaderRenderer } = useContext(HeaderConfigContext);
  const { isFilterActive } = filterManager;

  useEffect(() => {
    setHeaderRenderer(<HeaderFilter filterManager={filterManager} />);
    return () => setHeaderRenderer(null);
  }, [setHeaderRenderer, isFilterActive]);
}
