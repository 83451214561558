import React from 'react';
import i18n from 'i18n';

import { EditableTableColumn } from 'components/TableContainer/EditableTableContainer';
import { UserSocialNetwork } from 'store/user/types';
import { FormikSocialNetworkContactCell } from 'elements/FormikSocialNetworkContactCell';
import { FormikSocialNetworkApplicationCell } from 'elements/FormikSocialNetworkApplicationCell';

type CreateUserSocialNetworksColumns = {
  [key in keyof UserSocialNetwork]: EditableTableColumn<UserSocialNetwork>;
};

export const getCreateUserSocialNetworksColumns = (): CreateUserSocialNetworksColumns => ({
  application: {
    title: i18n.t('user.socialNetworks.application'),
    key: 'application',
    dataIndex: 'application',
    editable: true,
    render: (text, _record, index) => (
      <FormikSocialNetworkApplicationCell dataIndex="application" index={index} label={text} />
    ),
  },
  contact: {
    title: i18n.t('user.socialNetworks.contact'),
    key: 'contact',
    dataIndex: 'contact',
    editable: true,
    render: (_text, _record, index) => <FormikSocialNetworkContactCell dataIndex="contact" index={index} />,
  },
});
