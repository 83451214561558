import React from 'react';

import { useTranslation } from 'react-i18next';

import useWimState from 'pages/Wim/hooks/use-wim-state';

import { WimPlatformUid } from 'store/wim/types';
import { DetailType } from '..';
import { StyledHeaderWims, StyledHeaderWrapper, StyledWimsWrapper } from './styles';
import Wim from './components/Wim';

interface WimProps {
  wimUid: WimPlatformUid;
  year: number;
  start?: number;
  end?: number;
  showDetail: (detailType: DetailType, date: number, laneUid?: string) => void;
}

const WimsList: React.FC<WimProps> = ({ wimUid, year, start, end, showDetail }) => {
  const { t } = useTranslation();
  const { normalizedPlatforms } = useWimState();

  const byDirections = normalizedPlatforms?.byUid[wimUid]?.lanes?.byDirection;

  return (
    <StyledWimsWrapper>
      <StyledHeaderWrapper gutter={[5, 5]}>
        <StyledHeaderWims span={3}>{t('violationsPage.direction')}</StyledHeaderWims>
        <StyledHeaderWims span={3}>{t('violationsPage.laneType')}</StyledHeaderWims>
        <StyledHeaderWims span={9}>{t('violationsPage.sensorError')}</StyledHeaderWims>
        <StyledHeaderWims span={9}>{t('violationsPage.recognitions')}</StyledHeaderWims>
      </StyledHeaderWrapper>
      {byDirections &&
        Object.keys(byDirections).map((direction, i) => (
          <Wim
            key={direction}
            direction={i > 0}
            directionName={direction}
            lanesInfo={byDirections[direction].lanesInfo}
            year={year}
            showDetail={showDetail}
            start={start}
            end={end}
          />
        ))}
    </StyledWimsWrapper>
  );
};

export default WimsList;
