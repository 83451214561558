import dayjs, { Dayjs } from 'dayjs';

import { FilterReturnType } from 'hooks/use-filter';

import { FilterValues } from '..';

export const INITIAL_TRANSITS_BY_PLATE_FILTER_VALUES: Partial<FilterValues> = {
  start: dayjs().startOf('day').valueOf(),
  end: dayjs().endOf('day').valueOf(),
};

export function useTransitsByPlateFilter<TransitsType extends FilterValues>({
  state,
  handleFilterChange,
}: Pick<FilterReturnType<Partial<TransitsType>>, 'handleFilterChange' | 'state'>) {
  const onRangeChange = (ranges: unknown) => {
    const [start, end] = ranges as [Dayjs, Dayjs];

    handleFilterChange('start', start.valueOf());
    handleFilterChange('end', end.valueOf());
  };

  return {
    state,
    handleFilterChange,
    onRangeChange,
  };
}
