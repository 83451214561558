import { takeEvery } from 'redux-saga/effects';

import makeRequestSaga from 'store/common';
import { getWimSummary } from 'api/wim';
import { SagaPayload } from 'types';
import { GetWimPlatformSummaryViolationsQueryParams } from 'store/wim/types/platforms';
import { getWimTransitSummaryError, requestWimTransitSummary, getWimTransitSummarySuccess } from './slice';

export function* wimTransitsSummaryRequestSaga({ payload }: SagaPayload<GetWimPlatformSummaryViolationsQueryParams>) {
  yield makeRequestSaga(getWimTransitSummarySuccess, getWimTransitSummaryError, getWimSummary, payload);
}

export function* wimTransitsSummaryRequestWatchSaga() {
  yield takeEvery(requestWimTransitSummary, wimTransitsSummaryRequestSaga);
}
