import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledDevTitle } from './style';

const PageInDevTitle = () => {
  const { t } = useTranslation();
  return <StyledDevTitle> ({t('common.comingSoon')})</StyledDevTitle>;
};

export default PageInDevTitle;
