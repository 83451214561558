import dayjs, { Dayjs } from 'dayjs';
import { SelectValue } from 'antd/lib/select';

import { useAppSelector } from 'hooks/store-hooks';
import { FilterReturnType } from 'hooks/use-filter';
import wimSelectors from 'store/wim/selectors';
import { ComparativeAnalysisFilterValues } from 'store/wim/types/transits';
import { PLATE_FIELDS } from 'app-constants/plate-fields';

export function useComparativeAnalysisFilter<ComparativeAnalysisType extends ComparativeAnalysisFilterValues>({
  state,
  handleFilterChange,
}: Pick<FilterReturnType<Partial<ComparativeAnalysisType>>, 'handleFilterChange' | 'state'>) {
  const platforms = useAppSelector(wimSelectors.platforms.getPlatforms);

  const onPlatformNameChange = (value: SelectValue) => {
    if (value && Array.isArray(value)) {
      handleFilterChange('intermediateWims', value);
    }
  };

  const onRangePickerChange = (ranges: [Dayjs | null, Dayjs | null] | null) => {
    if (!ranges) {
      return;
    }

    const [start, end] = ranges;

    if (start && end) {
      handleFilterChange('start', dayjs(start).valueOf());
      handleFilterChange('end', dayjs(end).valueOf());
    }
  };

  const getOnPlateChange =
    (field: PLATE_FIELDS.Plate) =>
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = target;
      handleFilterChange(field, value);
    };

  const getOnSliderChange =
    (startField: keyof ComparativeAnalysisFilterValues, endField: keyof ComparativeAnalysisFilterValues) =>
    ([startValue, endValue]: [number, number]) => {
      handleFilterChange(startField, startValue);
      handleFilterChange(endField, endValue);
    };

  return {
    state,
    handleFilterChange,
    platforms,
    onPlatformNameChange,
    onRangePickerChange,
    getOnPlateChange,
    getOnSliderChange,
  };
}
