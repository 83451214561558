import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from '../../../app-constants/date';
import { ExternalNotification } from '../../../store/system/types';
import { StyledInfoBlock, StyledPopupContent } from '../../Wim/Grid/components/List/components/WimContent/styles';
import InfoText from '../../../elements/InfoText';

interface NotificationSummaryProps {
  state: ExternalNotification;
}
export const NotificationSummary: React.FC<NotificationSummaryProps> = ({ state }) => {
  const { t } = useTranslation();

  return (
    <StyledPopupContent>
      <StyledInfoBlock>
        <InfoText
          label={t('system.externalNotifications.timestamp')}
          value={dayjs(state.timestamp).format(DATE_FORMAT.TABLE_FORMAT)}
        />
        <InfoText label={t('system.externalNotifications.channel')} value={state.channel} />
        <InfoText label={t('system.externalNotifications.group')} value={state.group} />
        <InfoText label={t('system.externalNotifications.text')} value={state.text} />
      </StyledInfoBlock>
    </StyledPopupContent>
  );
};
