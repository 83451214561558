import L from 'leaflet';

import {
  WIMAlertIcon,
  WIMDangerIcon,
  WIMNormalIcon,
  CameraDangerIcon,
  CameraNormalIcon,
  CameraAlertIcon,
} from 'assets/icons';

export const AlertIcon = L.icon({ iconUrl: WIMAlertIcon });
export const DangerIcon = L.icon({ iconUrl: WIMDangerIcon });
export const NormalIcon = L.icon({ iconUrl: WIMNormalIcon });
export const DangerCameraIcon = L.icon({ iconUrl: CameraDangerIcon });
export const NormalCameraIcon = L.icon({ iconUrl: CameraNormalIcon });
export const AlertCameraIcon = L.icon({ iconUrl: CameraAlertIcon });
