import React from 'react';
import { Tooltip } from 'antd';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import i18n from 'i18n';

import { wrapTableTitle } from 'components/TableContainer/DefaultTableContainer';
import { CHARS } from 'app-constants/chars';
import { DATE_FORMAT } from 'app-constants/date';
import { ROUTES } from 'app-constants/routes';
import { getVehicleClassIconById } from 'helpers/get-vehicle-class-icon';
import { plateFormatter } from 'helpers/label.formatter';
import { getViolationIcon } from 'helpers/get-violation-type';
import { getMeasurementFlagIcon } from 'helpers/get-vendor-errors';
import { separateByThousands } from 'helpers/number.formatter';
import { getVehicleTypeDescription } from 'helpers/get-vehicle-description';
import { convertMillimetersToMeters } from 'helpers/metric.convertor';
import palette from 'palette';
import { WimTransitItem } from 'store/wim/types/transits';
import { URLParams } from 'types';

import { HintTooltip, StyledLink } from './styles';

export const DEFAULT_PAGE_SIZE_OPTIONS = ['10', '25', '50', '100'];

export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_OFFSET = 0;
export const DEFAULT_PAGE = 1;
export const DEFAULT_AXLES = 9;

export const sizeAccuracy = 3;

export const initialTransitsTableSortingUrlParams: URLParams = {
  key: 'datetime',
  value: 'descend',
};

export type TransitsTableRow = WimTransitItem;
export type TransitsTableColumns = {
  [key in keyof Omit<
    TransitsTableRow,
    | 'wim'
    | 'plateRecognized'
    | 'plateBackRecognized'
    | 'trailerPresence'
    | 'framename'
    | 'uid'
    | 'laneNumber'
    | 'vehicleClassDescription'
    | 'laneUid'
    | 'platformUid'
    | 'oversize'
    | 'overaxleload'
    | 'overgroupload'
    | 'overload'
    | 'mapPosition'
    | 'originalPlate'
  >]: ColumnType<TransitsTableRow> | ColumnGroupType<TransitsTableRow>;
};

const wrapWithLink = (content: React.ReactNode, row: WimTransitItem, queryUrlParams?: Record<string, any>) => {
  return (
    <StyledLink
      to={{
        pathname: `${ROUTES.Transit}/${row.datetime}/${row.uid}`,
        state: {
          pathname: window.location.pathname,
          queryUrlParams: window.location.search,
        },
      }}
    >
      {content}
    </StyledLink>
  );
};

export const getOnlineTransitsTableColumns = (): TransitsTableColumns => ({
  plate: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.plate')),
    width: 140,
    sorter: true,
    fixed: 'left',
    key: 'plate',
    dataIndex: 'plate',
    render: (plate: string, row) => wrapWithLink(plateFormatter(plate), row),
  },
  direction: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.direction')),
    width: 210,
    sorter: true,
    key: 'direction',
    dataIndex: 'direction',
    render: (direction: string, row) => wrapWithLink(direction || CHARS.mdash, row),
  },
  datetime: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.datetime')),
    width: 170,
    sorter: true,
    key: 'datetime',
    dataIndex: 'datetime',
    render: (datetime: number, row) =>
      wrapWithLink(dayjs(datetime).format(DATE_FORMAT.TABLE_FORMAT) || CHARS.mdash, row),
  },
  weight: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.weight')),
    width: 150,
    sorter: true,
    key: 'weight',
    dataIndex: 'weight',
    render: (weight: number, row) => wrapWithLink(weight || CHARS.mdash, row),
  },
  vehicleClass: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.vehicleType')),
    width: 100,
    key: 'vehicleClass',
    dataIndex: 'vehicleClass',
    render: (vehicleClass: WimTransitItem['vehicleClass'], row) =>
      wrapWithLink(
        vehicleClass ? (
          <Tooltip
            title={<HintTooltip>{getVehicleTypeDescription(vehicleClass)}</HintTooltip>}
            color={palette.white.w1}
          >
            <span>{vehicleClass.toString()}. </span>
            <img src={getVehicleClassIconById(vehicleClass)} alt="vehicle-type" width={50} height={25} />
          </Tooltip>
        ) : (
          CHARS.mdash
        ),
        row
      ),
  },
  axles: {
    title: (): JSX.Element =>
      wrapTableTitle(
        `${i18n.t('transitsPage.table.columns.axleLoad')} / ${i18n.t(
          'transitsPage.table.columns.axleSlope'
        )} / ${i18n.t('transitsPage.table.columns.axleWheelBase')}`
      ),
    key: 'axles',
    dataIndex: 'axles',
    children: Array.from(Array(DEFAULT_AXLES).keys()).map((index) => ({
      title: (): JSX.Element => wrapTableTitle(`${i18n.t('transitsPage.table.columns.axle')} ${index + 1}`),
      key: `axle-${index + 1}`,
      dataIndex: `axles`,
      width: 160,
      render: (axles: WimTransitItem['axles'], row) => {
        let content = `${CHARS.mdash} / ${CHARS.mdash} / ${CHARS.mdash}`;

        axles.forEach((axle, axleIndex) => {
          if (axleIndex === index) {
            content = [
              axle.weight ? separateByThousands(axle.weight) : CHARS.mdash,
              axle.tires || CHARS.mdash,
              axle.tireWidth ? convertMillimetersToMeters(axle.tireWidth).toFixed(sizeAccuracy) : CHARS.mdash,
            ].join(' / ');
          }
        });

        return wrapWithLink(<div>{content}</div>, row);
      },
    })),
  },
  id: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.id')),
    width: 120,
    sorter: true,
    // fixed: 'left',
    key: 'id',
    dataIndex: 'id',
    render: (id: number, row) => wrapWithLink(id || CHARS.mdash, row),
  },
  violations: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.offence')),
    width: 100,
    key: 'violations',
    dataIndex: 'violations',
    render: (violations: WimTransitItem['violations'], row) => wrapWithLink(getViolationIcon(violations), row),
  },
  platformName: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.platfromName')),
    width: 80,
    sorter: true,
    key: 'platformName',
    dataIndex: 'platformName',
    render: (platformName: string, row) => wrapWithLink(platformName || CHARS.mdash, row),
  },
  lane: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.wimName')),
    width: 150,
    key: 'lane',
    dataIndex: 'lane',
    render: (lane: string, row) => wrapWithLink(lane || CHARS.mdash, row),
  },
  flags: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.vendorErrors')),
    width: 110,
    key: 'flags',
    dataIndex: 'flags',
    render: (flags: WimTransitItem['flags'], row) => wrapWithLink(getMeasurementFlagIcon(flags), row),
  },
  plateBack: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.plateBack')),
    width: 140,
    sorter: true,
    key: 'plateBack',
    dataIndex: 'plateBack',
    render: (plateBack: string, row) => wrapWithLink(plateFormatter(plateBack), row),
  },
  speed: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.speed')),
    width: 120,
    sorter: true,
    key: 'speed',
    dataIndex: 'speed',
    render: (speed: number, row) => wrapWithLink(speed || CHARS.mdash, row),
  },
  length: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.length')),
    width: 120,
    sorter: true,
    key: 'length',
    dataIndex: 'length',
    render: (length: number, row) =>
      wrapWithLink(length ? convertMillimetersToMeters(length).toFixed(sizeAccuracy) : CHARS.mdash, row),
  },
  width: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.width')),
    width: 130,
    sorter: true,
    key: 'width',
    dataIndex: 'width',
    render: (width: number, row) =>
      wrapWithLink(width ? convertMillimetersToMeters(width).toFixed(sizeAccuracy) : CHARS.mdash, row),
  },
  height: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.height')),
    width: 130,
    sorter: true,
    key: 'height',
    dataIndex: 'height',
    render: (height: number, row) =>
      wrapWithLink(height ? convertMillimetersToMeters(height).toFixed(sizeAccuracy) : CHARS.mdash, row),
  },
  axlesCount: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.axlesCount')),
    width: 100,
    sorter: true,
    key: 'axlesCount',
    dataIndex: 'axlesCount',
    render: (axlesCount: number, row) => wrapWithLink(axlesCount || CHARS.mdash, row),
  },
  wheelBase: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.wheelBase')),
    width: 160,
    key: 'wheelBase',
    dataIndex: 'wheelBase',
    render: (wheelBase: number, row) =>
      wrapWithLink(wheelBase ? convertMillimetersToMeters(wheelBase).toFixed(sizeAccuracy) : CHARS.mdash, row),
  },
});

export const getArchiveTransitsTableColumns = (): TransitsTableColumns => ({
  platformName: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.platfromName')),
    width: 80,
    sorter: true,
    key: 'platformName',
    dataIndex: 'platformName',
    render: (platformName: string, row) => wrapWithLink(platformName || CHARS.mdash, row),
  },
  plate: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.plate')),
    width: 140,
    sorter: true,
    fixed: 'left',
    key: 'plate',
    dataIndex: 'plate',
    render: (plate: string, row) => wrapWithLink(plateFormatter(plate), row),
  },
  direction: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.direction')),
    width: 210,
    sorter: true,
    key: 'direction',
    dataIndex: 'direction',
    render: (direction: string, row) => wrapWithLink(direction || CHARS.mdash, row),
  },
  lane: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.wimName')),
    width: 150,
    key: 'lane',
    dataIndex: 'lane',
    render: (lane: string, row) => wrapWithLink(lane || CHARS.mdash, row),
  },
  datetime: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.datetime')),
    width: 170,
    sorter: true,
    key: 'datetime',
    dataIndex: 'datetime',
    render: (datetime: number, row) =>
      wrapWithLink(dayjs(datetime).format(DATE_FORMAT.TABLE_FORMAT) || CHARS.mdash, row),
  },
  weight: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.weight')),
    width: 150,
    sorter: true,
    key: 'weight',
    dataIndex: 'weight',
    render: (weight: number, row) => wrapWithLink(weight || CHARS.mdash, row),
  },
  flags: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.vendorErrors')),
    width: 110,
    key: 'flags',
    dataIndex: 'flags',
    render: (flags: WimTransitItem['flags'], row) => wrapWithLink(getMeasurementFlagIcon(flags), row),
  },
  vehicleClass: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.vehicleType')),
    width: 100,
    key: 'vehicleClass',
    dataIndex: 'vehicleClass',
    render: (vehicleClass: WimTransitItem['vehicleClass'], row) =>
      wrapWithLink(
        vehicleClass ? (
          <Tooltip
            title={<HintTooltip>{getVehicleTypeDescription(vehicleClass)}</HintTooltip>}
            color={palette.white.w1}
          >
            <span>{vehicleClass.toString()}. </span>
            <img src={getVehicleClassIconById(vehicleClass)} alt="vehicle-type" width={50} height={25} />
          </Tooltip>
        ) : (
          CHARS.mdash
        ),
        row
      ),
  },
  axles: {
    title: (): JSX.Element =>
      wrapTableTitle(
        `${i18n.t('transitsPage.table.columns.axleLoad')} / ${i18n.t(
          'transitsPage.table.columns.axleSlope'
        )} / ${i18n.t('transitsPage.table.columns.axleWheelBase')}`
      ),
    key: 'axles',
    dataIndex: 'axles',
    children: Array.from(Array(DEFAULT_AXLES).keys()).map((index) => ({
      title: (): JSX.Element => wrapTableTitle(`${i18n.t('transitsPage.table.columns.axle')} ${index + 1}`),
      key: `axle-${index + 1}`,
      dataIndex: `axles`,
      width: 160,
      render: (axles: WimTransitItem['axles'], row) => {
        let content = `${CHARS.mdash} / ${CHARS.mdash} / ${CHARS.mdash}`;

        axles.forEach((axle, axleIndex) => {
          if (axleIndex === index) {
            content = [
              axle.weight ? separateByThousands(axle.weight) : CHARS.mdash,
              axle.tires || CHARS.mdash,
              axle.tireWidth ? convertMillimetersToMeters(axle.tireWidth).toFixed(sizeAccuracy) : CHARS.mdash,
            ].join(' / ');
          }
        });

        return wrapWithLink(<div>{content}</div>, row);
      },
    })),
  },
  id: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.id')),
    width: 120,
    sorter: true,
    // fixed: 'left',
    key: 'id',
    dataIndex: 'id',
    render: (id: number, row) => wrapWithLink(id || CHARS.mdash, row),
  },
  violations: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.offence')),
    width: 100,
    key: 'violations',
    dataIndex: 'violations',
    render: (violations: WimTransitItem['violations'], row) => wrapWithLink(getViolationIcon(violations), row),
  },
  plateBack: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.plateBack')),
    width: 140,
    sorter: true,
    key: 'plateBack',
    dataIndex: 'plateBack',
    render: (plateBack: string, row) => wrapWithLink(plateFormatter(plateBack), row),
  },
  speed: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.speed')),
    width: 120,
    sorter: true,
    key: 'speed',
    dataIndex: 'speed',
    render: (speed: number, row) => wrapWithLink(speed || CHARS.mdash, row),
  },
  length: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.length')),
    width: 120,
    sorter: true,
    key: 'length',
    dataIndex: 'length',
    render: (length: number, row) =>
      wrapWithLink(length ? convertMillimetersToMeters(length).toFixed(sizeAccuracy) : CHARS.mdash, row),
  },
  width: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.width')),
    width: 130,
    sorter: true,
    key: 'width',
    dataIndex: 'width',
    render: (width: number, row) =>
      wrapWithLink(width ? convertMillimetersToMeters(width).toFixed(sizeAccuracy) : CHARS.mdash, row),
  },
  height: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.height')),
    width: 130,
    sorter: true,
    key: 'height',
    dataIndex: 'height',
    render: (height: number, row) =>
      wrapWithLink(height ? convertMillimetersToMeters(height).toFixed(sizeAccuracy) : CHARS.mdash, row),
  },
  axlesCount: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.axlesCount')),
    width: 100,
    sorter: true,
    key: 'axlesCount',
    dataIndex: 'axlesCount',
    render: (axlesCount: number, row) => wrapWithLink(axlesCount || CHARS.mdash, row),
  },
  wheelBase: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitsPage.table.columns.wheelBase')),
    width: 160,
    key: 'wheelBase',
    dataIndex: 'wheelBase',
    render: (wheelBase: number, row) =>
      wrapWithLink(wheelBase ? convertMillimetersToMeters(wheelBase).toFixed(sizeAccuracy) : CHARS.mdash, row),
  },
});
