import React, { FC } from 'react';

import { TooltipProps } from 'recharts';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DATE_FORMAT } from 'app-constants/date';

import palette from 'palette';
import { getIntegerPercent } from 'helpers/percent.formatter';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { StyledTooltipWithWimStatistics, StyledTooltipWithWimStatisticsItem, StyledValue } from './styles';

export enum TranslateChart {
  transitsStatistic = 'transitsStatistic',
  violationOverloadStatistic = 'violationOverloadStatistic',
  vrpDetectStatistic = 'vrpDetectStatistic',
  totalTraffic = 'totalTraffic',
  overload = 'overload',
  overaxleload = 'overaxleload',
  overgroupload = 'overgroupload',
}

interface CustomTooltipProps extends TooltipProps<ValueType, NameType> {
  unit?: string;
}

const TooltipWithWimStatistics: FC<CustomTooltipProps> = ({ payload, unit }): JSX.Element | null => {
  const { t } = useTranslation();
  if (!payload) {
    return null;
  }
  const date = payload[0]?.payload?.date;
  return (
    <StyledTooltipWithWimStatistics>
      <StyledValue>{date ? dayjs(date).format(DATE_FORMAT.TABLE_FORMAT) : t('homePage.charts.noDate')}</StyledValue>
      {payload?.map((item) => {
        let value = item.payload[item.name as string];
        const color = item.payload?.color || item.color;

        if (unit === '%') {
          value = `${getIntegerPercent(value)} %`;
        }

        return (
          <StyledTooltipWithWimStatisticsItem key={`${item.name}${item.value}${item.payload.date}`} color={color}>
            {t(`homePage.charts.${item.dataKey as TranslateChart}` as const)}: <StyledValue>{value}</StyledValue>
          </StyledTooltipWithWimStatisticsItem>
        );
      })}
    </StyledTooltipWithWimStatistics>
  );
};

export default TooltipWithWimStatistics;
