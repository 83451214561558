import React, { FC } from 'react';

import { DefaultTableContainer } from 'components/TableContainer/DefaultTableContainer';
import { platformInfoColumns, platformInfoDataSource } from './table-schema';
import { StyledInfoBlock } from './styles';
import { PlatformState } from '../../types';

interface InfoContentProps {
  state: PlatformState;
}

export const InfoContent: FC<InfoContentProps> = ({ state }) => {
  const infoColumns = Object.values(platformInfoColumns);
  const infoDataSource = platformInfoDataSource(state);

  return (
    <StyledInfoBlock>
      <DefaultTableContainer columns={infoColumns} dataSource={infoDataSource} bordered />
    </StyledInfoBlock>
  );
};
