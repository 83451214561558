import * as mediaClusterSelectors from './media-cluster/selectors';
import * as externalNotifications from './notifications/selectors';
import * as connectors from './connectors/selectors';

const systemSelectors = {
  mediaCluster: {
    ...mediaClusterSelectors,
  },
  externalNotifications: {
    ...externalNotifications,
  },
  connectors: {
    ...connectors,
  },
};

export default systemSelectors;
