import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spin, Tooltip } from 'antd';
import { ErrorMessage, Formik } from 'formik';
import { useSelector } from 'react-redux';

import palette from 'palette';
import { FormikSocialNetworksTable } from 'components/TableContainer/FormikSocialNetworksTable';
import { FormikInputFieldWithLabel } from 'elements/FormikInputFieldWithLabel';
import { FormikSelectUserStatus } from 'elements/FormikSelectUserStatus';
import { FormikSelectUserPermissions } from 'elements/FormikSelectUserPermissions';
import { CreateUserValues, UserFieldName } from 'pages/AdminManagement/Users/types';
import { CreateUser } from 'store/user/types';
import adminSelectors from 'store/admin/selectors';
import { FormikSelectDeviceGroups } from 'elements/FormikSelectDeviceGroups';
import { useAppSelector } from 'hooks/store-hooks';
import { FormikCheckboxWithLabel } from 'elements/FormikCheckboxWithLabel';
import { createUserValidationSchema } from './validation-schema';
import { StyledButtons, StyledContentForm, StyledEditBlock } from './styles';
import { getCreateUserSocialNetworksColumns } from './table-schema';
import { StyledErrorMessage } from '../../../../Authorization/styles';

interface CreateContentProps {
  state: CreateUser;
  onSubmitForm: (values: CreateUserValues) => void;
}

export const CreateContent: FC<CreateContentProps> = ({ state, onSubmitForm }) => {
  const columns = Object.values(getCreateUserSocialNetworksColumns());
  const isCreateUserLoading = useSelector(adminSelectors.users.isCreateUserLoading);
  const deviceGroups = useAppSelector(adminSelectors.groups.getGroups);
  const { t } = useTranslation();

  return (
    <Formik<CreateUserValues>
      initialValues={{
        fullName: state.fullName,
        password: null,
        department: state.department,
        email: state.email,
        phone: state.phone,
        position: state.position,
        status: state.status,
        permissions: state.permissions,
        socialNetworks: state.socialNetworks,
        mfaEnabled: state.mfaEnabled,
        forcePasswordRefresh: state.forcePasswordRefresh,
      }}
      validationSchema={createUserValidationSchema}
      onSubmit={(submittedValues) => {
        onSubmitForm({
          ...submittedValues,
          email: submittedValues.email,
          password: submittedValues.password,
        });
      }}
    >
      {({ values, handleChange, handleSubmit, handleReset, setFieldValue, errors }) => (
        <StyledContentForm onSubmit={handleSubmit} onReset={handleReset}>
          <Spin spinning={isCreateUserLoading}>
            <StyledEditBlock>
              <FormikInputFieldWithLabel
                name={UserFieldName.fullName}
                onChange={handleChange}
                label={t('infoFields.fullName')}
                defaultValue={values.fullName}
              />
              <Tooltip placement="right" title={t('infoFields.passwordTooltip')}>
                <FormikInputFieldWithLabel
                  name={UserFieldName.password}
                  type="password"
                  onChange={handleChange}
                  style={{ borderColor: errors.password ? palette.red.r3 : palette.grey.g5 }}
                  label={t('infoFields.password')}
                  // defaultValue={values.password || ''}
                />
              </Tooltip>
              <ErrorMessage component={StyledErrorMessage} name={UserFieldName.password} />
              <FormikInputFieldWithLabel
                name={UserFieldName.email}
                onChange={handleChange}
                style={{ borderColor: errors.email ? palette.red.r3 : palette.grey.g5 }}
                label={t('infoFields.email')}
                defaultValue={values.email}
              />
              <ErrorMessage component={StyledErrorMessage} name={UserFieldName.email} />
              <FormikInputFieldWithLabel
                name={UserFieldName.phone}
                onChange={handleChange}
                label={t('infoFields.phone')}
                defaultValue={values.phone}
              />
              <FormikInputFieldWithLabel
                name={UserFieldName.department}
                onChange={handleChange}
                label={t('infoFields.department')}
                defaultValue={values.department}
              />
              <FormikInputFieldWithLabel
                name={UserFieldName.position}
                onChange={handleChange}
                label={t('infoFields.position')}
                defaultValue={values.position}
              />
              <FormikSelectUserPermissions
                name={UserFieldName.permissions}
                value={values.permissions}
                onChange={(value) => setFieldValue(UserFieldName.permissions, value)}
              />
              <FormikSelectUserStatus
                name={UserFieldName.status}
                value={values.status}
                onChange={(value) => setFieldValue(UserFieldName.status, value)}
              />
              <FormikSelectDeviceGroups
                name={UserFieldName.deviceGroups}
                value={values.deviceGroups}
                deviceGroups={deviceGroups || []}
                onChange={(value) => setFieldValue(UserFieldName.deviceGroups, value)}
              />
              <FormikCheckboxWithLabel
                name={UserFieldName.mfaEnabled}
                onChange={handleChange}
                label={t('infoFields.mfaEnabled')}
                defaultValue={values.mfaEnabled || true}
              />
              <FormikCheckboxWithLabel
                name={UserFieldName.forcePasswordRefresh}
                onChange={handleChange}
                label={t('infoFields.forcePasswordRefresh')}
                defaultValue={values.forcePasswordRefresh || false}
              />
              <br />
              <span>{t('infoFields.socialNetworks')}</span>
              <FormikSocialNetworksTable
                name={UserFieldName.socialNetworks}
                columns={columns}
                dataSource={values.socialNetworks}
              />
            </StyledEditBlock>
          </Spin>
          <StyledButtons>
            <Button htmlType="submit" style={{ color: palette.white.w1, backgroundColor: palette.blue.b1 }}>
              {t('common.apply')}
            </Button>
            <Button onClick={handleReset} style={{ color: palette.white.w1, backgroundColor: palette.red.r3 }}>
              {t('common.cancel')}
            </Button>
          </StyledButtons>
        </StyledContentForm>
      )}
    </Formik>
  );
};
