import dayjs from 'dayjs';
import { isEmpty, isNull, isUndefined } from 'lodash';
import { WimMonitoringFilterParams, WimMonitoringMetricFilterValues } from './use-wim-monitoring-filter';

const isValueSignificant = (value: unknown): boolean => !isEmpty(value) || !isUndefined(value) || !isNull(value);

export const queryParamsToFilterValues = (filterValues: {
  [key: string]: any;
}): Partial<WimMonitoringMetricFilterValues> => {
  return Object.keys(filterValues).reduce((acc, currentFilterValueKey) => {
    if (isValueSignificant(filterValues[currentFilterValueKey])) {
      acc[currentFilterValueKey as keyof WimMonitoringMetricFilterValues] = filterValues[currentFilterValueKey];
    }

    if (currentFilterValueKey === WimMonitoringFilterParams.start) {
      acc[currentFilterValueKey] = filterValues[currentFilterValueKey]
        ? dayjs(parseInt(filterValues[currentFilterValueKey], 10)).valueOf()
        : dayjs().subtract(1, 'day').valueOf();
    }

    if (currentFilterValueKey === WimMonitoringFilterParams.end) {
      acc[currentFilterValueKey] = filterValues[currentFilterValueKey]
        ? dayjs(parseInt(filterValues[currentFilterValueKey], 10)).valueOf()
        : dayjs().valueOf();
    }

    if (currentFilterValueKey === WimMonitoringFilterParams.monitoringMetricId) {
      acc[currentFilterValueKey] = filterValues[currentFilterValueKey];
    }

    return acc;
  }, {} as Partial<WimMonitoringMetricFilterValues>);
};
