import { call, put, select, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  deleteExportDocumentCancel,
  getExportDocumentCompletedFile,
  getExportDocumentStatus,
  getTransitsExportDocument,
} from 'api/wim';
import { getExportUrl } from 'helpers/url.formatter';
import { API_URLS } from 'app-constants/api';
import { TransitsArchiveFilterValues } from '../../types';
import {
  DOCUMENT_STATUS,
  getExportDocumentStatusError,
  getExportDocumentStatusSuccess,
  getExportDownloadFileError,
  getExportDownloadFileSuccess,
  getTransitsExportDocumentError,
  getTransitsExportDocumentSuccess,
  requestExportDocumentCancel,
  requestExportDocumentFile,
  requestExportDocumentStatus,
  requestTransitsExportDocument,
} from './slice';
import wimSelectors from '../../selectors';
import { INITIAL_TRANSITS_FILTER_VALUES } from '../../filter/transits';
import { SagaPayload } from '../../../../types';

export interface TransitExportDocumentResponse {
  key: string;
}

export interface GetExportDocumentStatusResponse {
  status: DOCUMENT_STATUS;
}

export function* wimTransitsExportDocumentRequestSaga({ payload }: SagaPayload<string>) {
  try {
    const filterValues: Partial<TransitsArchiveFilterValues> | null = yield select(
      wimSelectors.transits.getFilterValues
    );

    const params = filterValues || INITIAL_TRANSITS_FILTER_VALUES;

    const response: AxiosResponse<TransitExportDocumentResponse> = yield call(
      getTransitsExportDocument,
      getExportUrl(API_URLS.getTransits().substring(1), {
        ...params,
        format: payload,
      })
    );

    if (response) {
      yield put(getTransitsExportDocumentSuccess(response.data));
    } else {
      yield put(getTransitsExportDocumentError(JSON.parse(response)));
    }
  } catch (error) {
    yield put(getTransitsExportDocumentError(error.message));
  }
}

export function* wimTransitsExportDocumentStatusRequestSaga({ payload }: SagaPayload<string>) {
  try {
    const response: AxiosResponse<GetExportDocumentStatusResponse> = yield call(getExportDocumentStatus, {
      key: payload,
    });

    if (response) {
      yield put(getExportDocumentStatusSuccess(response.data));
    }
  } catch (error) {
    yield put(getExportDocumentStatusError(error.message));
  }
}

export function* wimTransitsExportDocumentFileRequestSaga({ payload }: SagaPayload<{ key: string; fileName: string }>) {
  try {
    const response: AxiosResponse = yield call(getExportDocumentCompletedFile, {
      key: payload.key,
      fileName: payload.fileName,
    });

    if (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${payload.fileName}`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      yield put(getExportDownloadFileSuccess(response.data));
    }
  } catch (error) {
    yield put(getExportDownloadFileError(error.message));
  }
}

export function* wimTransitsExportDocumentCancelRequestSaga({ payload }: SagaPayload<string>) {
  try {
    yield call(deleteExportDocumentCancel, {
      key: payload,
    });
  } catch {
    console.log('Export cancel error.');
  }
}

export function* wimTransitsExportDocumentRequestWatchSaga() {
  yield takeEvery(requestTransitsExportDocument, wimTransitsExportDocumentRequestSaga);
}

export function* wimTransitsExportDocumentStatusRequestWatchSaga() {
  yield takeEvery(requestExportDocumentStatus, wimTransitsExportDocumentStatusRequestSaga);
}

export function* wimTransitsExportDocumentFileRequestWatchSaga() {
  yield takeEvery(requestExportDocumentFile, wimTransitsExportDocumentFileRequestSaga);
}

export function* wimTransitsExportDocumentCancelRequestWatchSaga() {
  yield takeEvery(requestExportDocumentCancel, wimTransitsExportDocumentCancelRequestSaga);
}
