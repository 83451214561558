import { useSelector } from 'react-redux';
import systemSelectors from '../../../store/system/selectors';

const useConnectorsState = () => {
  const connectors = useSelector(systemSelectors.connectors.getConnectors);
  const isLoading = useSelector(systemSelectors.connectors.isLoading);

  return {
    connectors,
    isLoading,
    isLoaded: connectors && !!connectors.connectors.length,
  };
};

export { useConnectorsState };
