import React, { FC } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import GlobalErrorBoundary from 'errors';

import { ROUTES } from 'app-constants/routes';

interface ProtectedRouteProps extends Omit<RouteProps, 'component'> {
  isAuthenticated: boolean;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, isAuthenticated, ...routeProps }) => (
  <Route
    {...routeProps}
    render={({ location }) => {
      return isAuthenticated ? (
        <GlobalErrorBoundary location={location}>
          <>{children}</>
        </GlobalErrorBoundary>
      ) : (
        <Redirect
          to={{
            pathname: ROUTES.Authorization,
            state: { from: location },
          }}
        />
      );
    }}
  />
);

export { ProtectedRoute };
