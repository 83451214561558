import React from 'react';

import palette from 'palette';
import { getIntegerPercent } from './percent.formatter';

type SetBacgroundType = {
  [key: string]: string | number;
};
const setBackground = (props: SetBacgroundType): JSX.Element => {
  const { width = 20, height = 10, x = 0, y = 0, fill } = props;
  let bg = fill;

  if (props.interpolation) {
    bg = 'url(#stripedBG)';
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg">
      <defs>
        <pattern id="stripedBG" width="4px" height="4px" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
          <line stroke={palette.blue.b5} strokeWidth="2px" y2="4px" />
        </pattern>
      </defs>
      <path
        d={`m ${x} ${y} h ${Math.abs(width as number)} v ${height} h -${Math.abs(width as number)} Z`}
        fill={bg as string}
      />
    </svg>
  );
};

export default setBackground;
