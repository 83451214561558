import styled from 'styled-components/macro';

export const StyledMapWrapper = styled.div`
  padding: 24px;

  .leaflet-container {
    .leaflet-map-pane {
      z-index: 40;
    }

    .leaflet-top,
    .leaflet-bottom {
      z-index: 50;
    }
  }
`;
