import { useEffect } from 'react';

import { useAppDispatch } from 'hooks/store-hooks';
import { wimActions } from 'store/wim/slices';
import { WimPlatformUid } from 'store/wim/types';
import { TimePeriod } from 'types/date-period';

function useGetActivePlatform(timePeriod: TimePeriod, platformUid?: WimPlatformUid): void {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (platformUid) {
      dispatch(
        wimActions.activePlatform.requestActiveWimPlatform({
          uid: platformUid,
          start: timePeriod.start,
          end: timePeriod.end,
        })
      );
    }
  }, [platformUid]);
}

export default useGetActivePlatform;
