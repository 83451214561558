import { VEHICLE_CLASSES } from 'app-constants/vehicle-classes';
import { WAGA_VEHICLE_TYPES } from 'app-constants/waga-vehicle-types';
import { truckIcon } from 'assets';

function getVehicleClassIconById(vehicleClassId: number, format: 'normal' | 'base64' = 'normal'): string {
  if (format === 'base64') {
    return WAGA_VEHICLE_TYPES[vehicleClassId]?.base64 || '';
  }

  return WAGA_VEHICLE_TYPES[vehicleClassId]?.icon || truckIcon;
}

const getVehicleClassIconByName = (vehicleName: string): string => {
  if (+vehicleName || +vehicleName === 0) {
    const searchedVehicleType = Object.values(WAGA_VEHICLE_TYPES).find((vehicleType) =>
      vehicleType.vehicleClasses.find((vehicleClass) => vehicleClass === vehicleName.toString())
    );

    return searchedVehicleType?.icon || vehicleName;
  }

  const searchedVehicleClass = VEHICLE_CLASSES.find((item) => item.name === vehicleName);

  return searchedVehicleClass?.icon || truckIcon;
};

const getVehicleIdByName = (vehicleName: string) => {
  if (Number.parseInt(vehicleName, 10) || Number.parseInt(vehicleName, 10) === 0) {
    const searchedVehicleClass = VEHICLE_CLASSES.find((item) => item.name === vehicleName);
    return searchedVehicleClass?.id || 0;
  }
  const searchedVehicleClass = VEHICLE_CLASSES.find((item) => item.name === vehicleName);

  return searchedVehicleClass?.id || 0;
};

export { getVehicleClassIconByName, getVehicleClassIconById, getVehicleIdByName };
