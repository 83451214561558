import { Modal } from 'antd';
import styled from 'styled-components/macro';
import SFUITextMedium from 'fonts/SFUIText-Medium.woff';

export const StyledModal = styled(Modal)`
  z-index: 500;
  .ant-modal-content {
    max-height: 85vh;
    min-width: 560px;
    overflow: auto;
  }
`;

export const StyledModalTitle = styled.span`
  @font-face {
    font-family: 'SFUITextMedium';
    font-size: 14px;
    src: url(${SFUITextMedium}) format('woff');
  }
  font-family: 'SFUITextMedium';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
`;
