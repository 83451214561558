import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { userActions } from 'store/user/slice';

export function useInitializeApp() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: userActions.requestUser });
  }, [dispatch]);
}
