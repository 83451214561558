import styled from 'styled-components/macro';

export const StyledViolations = styled.div`
  width: 100%;
  padding: 15px;
`;

export const StyledTableTitle = styled.div`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 55px;
  overflow: hidden;
`;
