import styled from 'styled-components/macro';

import PingFang from 'fonts/PingFang-SC-Regular.woff';
import palette from 'palette';

interface StyledTooltipProps {
  color?: string;
}

export const StyledTooltipWithWimStatistics = styled.div`
  position: relative;
  padding: 5px;
  min-width: 150px;
  background: ${palette.white.w1};
`;

export const StyledTooltipWithWimStatisticsItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  ::before {
    content: '';
    position: static;
    margin: 0 5px 0 0;
    width: 20px;
    height: 20px;
    background: ${({ color }: StyledTooltipProps) => color};
  }
`;

export const StyledValue = styled.span`
  @font-face {
    font-family: 'PingFang';
    font-size: 160px;
    src: url(${PingFang}) format('woff');
  }
  margin: 0 0 0 5px;
  font-family: 'PingFang';
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
`;
