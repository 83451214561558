import React, { FC } from 'react';
import { List, ListProps } from 'antd';

import { ROUTES } from 'app-constants/routes';
import { Loader } from 'components';
import useGetPlatformSummary from 'pages/Wim/hooks/use-get-platform-summary';
import { NormalizedWimPlatform } from 'store/wim/normalization';
import { Row } from './components/Row';
import { WimContent } from './components/WimContent';
import { StyledList, StyledSummary } from './styles';
import { WimGridViewProps } from '../../types';

const WimsListView: FC<WimGridViewProps> = ({
  platforms,
  summary,
  selectedPlatform,
  onSelectPlatform,
  isSummaryLoading,
  isListLoaded,
}) => {
  useGetPlatformSummary(selectedPlatform?.uid);

  const renderItem = (item: NormalizedWimPlatform) => (
    <Row item={item} onItemClick={onSelectPlatform} selectedItem={selectedPlatform} isListLoaded={isListLoaded} />
  );

  const listProps: ListProps<NormalizedWimPlatform> = {
    renderItem,
    size: 'large',
    itemLayout: 'horizontal',
    loading: !isListLoaded,
    rowKey: (item) => `wim-item-${item.uid}`,
    className: 'wims-list',
    split: false,
  };

  return (
    <StyledList>
      <List dataSource={platforms} {...listProps} />
      <StyledSummary>
        <Loader isLoading={isSummaryLoading}>
          {summary && selectedPlatform && (
            <WimContent state={selectedPlatform} summary={summary} route={`${ROUTES.Wim}/${selectedPlatform.uid}`} />
          )}
        </Loader>
      </StyledSummary>
    </StyledList>
  );
};

export default WimsListView;
