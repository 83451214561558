import { User } from 'store/user/types';
import { isNull, isUndefined } from 'lodash';
import { UpdateWimPlatformFormData, Wim } from '../wim/types/platforms';
import { UpdatePlatformValues } from '../../pages/AdminManagement/Wims/components/PlatformSummary/types';
import { UpdateWimValues } from '../../pages/AdminManagement/Wims/components/WimsSummary/modal/UpdateContent/types';

export const normalizeUsersEmptyValues = (users: User[]): User[] =>
  users.map((user) => {
    const newUser: User = user;

    return normalizeUserEmptyValues(newUser);
  });

export function normalizeUserEmptyValues(user: User): User {
  const newUser = user;
  Object.keys(newUser).forEach((userKey) => {
    if (newUser[userKey as keyof User] === null) {
      Object.assign(newUser, { [userKey]: undefined });
    }
  });
  return newUser;
}

export function normalizePlatformEmptyValues(platform: Partial<UpdatePlatformValues>): UpdateWimPlatformFormData {
  const newPlatform: UpdateWimPlatformFormData = {};
  Object.keys(platform).forEach((platformKey) => {
    if (platform[platformKey as keyof UpdateWimPlatformFormData] !== null) {
      const newValue =
        platform[platformKey as keyof UpdateWimPlatformFormData] === ''
          ? '!' // pass this key for saving null
          : platform[platformKey as keyof UpdateWimPlatformFormData];
      Object.assign(newPlatform, { [platformKey]: newValue });
    }
  });
  return newPlatform;
}

export function normalizeWimsEmptyValues(wim: Partial<UpdateWimValues>): Wim[] {
  const newLanes: Wim[] = [];
  const wims = wim.wims ? wim.wims : [];
  const lanes = wim.lanes ? wim.lanes : [];
  Object.values(wims).forEach((lane, index) => {
    const newLane: Wim = lanes[index];
    if (lane !== undefined) {
      Object.keys(lane).forEach((laneKey) => {
        if (lane[laneKey as keyof Wim] !== null) {
          const newValue = lane[laneKey as keyof Wim] === '' ? undefined : lane[laneKey as keyof Wim];
          Object.assign(newLane, { [laneKey]: newValue });
        }
      });
    }
    newLanes.push(newLane);
  });
  return newLanes;
}
