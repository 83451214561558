import React from 'react';
import { StyledSemiBoldValue } from './styles';

interface InfoTextProps {
  label: string;
  value?: PropertyKey;
}

const InfoText: React.FC<InfoTextProps> = ({ label, value }) => (
  <span>
    {label} <StyledSemiBoldValue>{value || '-'}</StyledSemiBoldValue>
  </span>
);

export default InfoText;
