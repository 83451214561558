import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CreateUser, UpdateUser, User, UserLogActivity, UserLogActivityQuery } from 'store/user/types';

interface InitialState {
  isLoading: boolean;
  isCreateUserLoading: boolean;
  isUpdatedUserLoading: boolean;
  entities: User[] | null;
  error: any;
  isActivityLogsLoading: boolean;
  activityLogs: UserLogActivity[] | null;
  activityLogsError: any;
}

const initialState: InitialState = {
  isLoading: false,
  isUpdatedUserLoading: false,
  isCreateUserLoading: false,
  entities: null,
  error: null,
  isActivityLogsLoading: false,
  activityLogs: null,
  activityLogsError: null,
};

const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    requestUsers: (state): void => {
      state.isLoading = true;
    },
    requestUpdateUser: (state, { payload }: PayloadAction<UpdateUser>) => {
      state.isUpdatedUserLoading = true;
    },
    requestCreateUser: (state, { payload }: PayloadAction<CreateUser>) => {
      state.isCreateUserLoading = true;
    },
    getUsersSuccess: (state, { payload }: PayloadAction<User[]>): void => {
      state.isLoading = false;
      state.isCreateUserLoading = false;
      state.isUpdatedUserLoading = false;
      state.entities = payload;
    },
    getUsersError: (state, { payload }: PayloadAction<string>): void => {
      state.isLoading = false;
      state.isUpdatedUserLoading = false;
      state.error = payload;
    },
    getUserLogActivity: (state, { payload }: PayloadAction<UserLogActivityQuery>): void => {
      state.isActivityLogsLoading = true;
    },
    getUserLogActivitySuccess: (state, { payload }: PayloadAction<UserLogActivity[]>): void => {
      state.isActivityLogsLoading = false;
      state.activityLogs = payload;
    },
    getUserLogActivityError: (state, { payload }: PayloadAction<string>): void => {
      state.isActivityLogsLoading = false;
      state.activityLogsError = payload;
    },
    clearUserLogActivity: (state) => {
      state.activityLogs = null;
    },
    getNewUserError: (state, { payload }: PayloadAction<string>): void => {
      state.isLoading = false;
      state.error = payload;
    },
    getUserLogActivityExport: (state, { payload }: PayloadAction<UserLogActivityQuery>): void => {
      // state.isActivityLogsLoading = true;
    },
  },
});

export const {
  requestUsers,
  requestUpdateUser,
  requestCreateUser,
  getUsersSuccess,
  getUsersError,
  getNewUserError,
  getUserLogActivity,
  getUserLogActivitySuccess,
  getUserLogActivityError,
  clearUserLogActivity,
  getUserLogActivityExport,
} = users.actions;

export default users.reducer;
