import styled from 'styled-components/macro';

import palette from 'palette';

export const StyledTableWrapper = styled.div`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }

  .ant-pagination-total-text {
    flex-grow: 1;
    color: ${palette.black.b3};
  }

  .ant-table-thead {
    font-weight: 500;
    color: ${palette.black.b4};
  }

  .ant-table-tbody {
    color: ${palette.black.b3};
  }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-item,
  .ant-pagination-options-size-changer {
    border-radius: 4px;
    font-family: 'SFUITextRegular';
    font-size: 12px;

    .ant-pagination-item-link {
      color: ${palette.black.b3};
      border-radius: 4px;
    }

    .ant-select-selector {
      color: ${palette.black.b3};
      border-radius: 4px;
    }

    a {
      color: ${palette.black.b3};
    }
  }
`;
