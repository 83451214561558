import * as usersSelectors from './users/selectors';
import * as platformsSelectors from './platforms/selectors';
import * as lanesSelectors from './platforms/wimLanes/selectors';
import * as groupsSelectors from './groups/selectors';
import * as wimGroupsSelectors from './platforms/wimGroups/selectors';
import * as settingsSelectors from './settings/selectors';
import * as certificatesSelectors from './certificates/selectors';
import * as notificationsSelectors from './notifications/selectors';

const adminSelectors = {
  users: {
    ...usersSelectors,
  },
  platforms: {
    ...platformsSelectors,
  },
  lanes: {
    ...lanesSelectors,
  },
  groups: {
    ...groupsSelectors,
  },
  wimGroups: {
    ...wimGroupsSelectors,
  },
  settings: {
    ...settingsSelectors,
  },
  certificates: {
    ...certificatesSelectors,
  },
  notifications: { ...notificationsSelectors },
};

export default adminSelectors;
