import { CardDataType } from './create-gauge-card';

export const parseData = (data: { [key: string]: any }) =>
  Object.keys(data).reduce<CardDataType>((accum, key) => {
    const result = { ...accum };
    const current = key.search(/CurrentPeriod|ok/);
    const second = key.search(/PreviousPeriod|error/);

    if (current !== -1) {
      result.currentData = data[key];
    }
    if (second !== -1) {
      result.secondData = data[key];
    }
    if (key === 'warning') {
      result.warning = data[key];
    }
    if (key === 'delta') {
      result.delta = data[key] ?? 'N/A';
    }
    if (key === 'total') {
      result.total = data[key];
    }

    return result;
  }, {});
