import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { systemActions } from 'store/system/slices';

export const useGetNodes = (): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(systemActions.mediaNodes.requestNodes());
  }, []);
};
