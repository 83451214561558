import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Badge, Descriptions } from 'antd';
import { DATE_FORMAT } from '../../../app-constants/date';
import { ConnectorData } from '../../../store/system/types';

interface ConnectorSummaryProps {
  state: ConnectorData | null;
}

export const ConnectorSummary: React.FC<ConnectorSummaryProps> = ({ state }) => {
  const { t } = useTranslation();
  return (
    <Descriptions title={state?.name} layout="vertical" bordered>
      <Descriptions.Item label="Версія">1.0</Descriptions.Item>
      <Descriptions.Item label={t('system.connectors.type')}>{state?.type}</Descriptions.Item>
      <Descriptions.Item label={t('system.connectors.definition')}>{state?.definition}</Descriptions.Item>
      <Descriptions.Item label={t('system.connectors.timestamp')} span={2}>
        {dayjs(state?.timestamp).format(DATE_FORMAT.TABLE_FORMAT)}
      </Descriptions.Item>
      <Descriptions.Item label={t('system.connectors.enabled')} span={2}>
        {dayjs(state?.timestamp).format(DATE_FORMAT.TABLE_FORMAT)}
      </Descriptions.Item>
      <Descriptions.Item label={t('system.connectors.status')}>
        <Badge status="error" text="Офлайн" />
      </Descriptions.Item>
      <Descriptions.Item label={t('system.connectors.rate')}>{state?.rate}</Descriptions.Item>
      <Descriptions.Item label={t('system.connectors.error_rate')}>{state?.rate}</Descriptions.Item>
    </Descriptions>
  );
};
