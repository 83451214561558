import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';

import { ModalWrapper } from 'components';
import SingleDatePicker from 'components/SingleDatePicker';

import { useUrlParams } from 'hooks/use-url-params';
import { BarChartViolations } from 'components/BarChart';
import { useAppDispatch, useAppSelector } from 'hooks/store-hooks';
import { ViolationsLegend } from 'components/BarChart/Legends';

import { requestWimTransitsViolations } from 'store/platforms/wims/wim/transits/violations/slice';
import { WimPlatformUid } from 'store/wim/types';
import legends from '../WimsList/components/Wim/Lanes/Lane/legends';
import DetailTable from './DetailTable';
import { getViolationsChartData } from '../helpers/get-violation-chart-data';
import {
  StyledDetailBarChartWrapper,
  StyledDetailTitle,
  StyledDetailViolationsHeader,
  StyledDetailWrapper,
} from './styles';

interface ViolationsDetailProps {
  isActive: boolean;
  wimUid?: WimPlatformUid;
  closeDetail: () => void;
}

type ParsedDataType = {
  [key: string]: number | string;
};

const ViolationsDetail: React.FC<ViolationsDetailProps> = ({ isActive, wimUid, closeDetail }) => {
  const { fullLegend, axleLegend, bothLegend } = legends;
  const { t } = useTranslation();
  const [currentDate, setCurrentDate] = useState(dayjs().startOf('day'));
  const [currentViolations, setCurrentViolations] = useState<ParsedDataType[]>([]);
  const { queryUrlParams } = useUrlParams({});
  const [laneType, setLaneType] = useState<string>();
  const [direction, setDirection] = useState<string>();
  const platforms = useAppSelector((platformsState) => platformsState.wim.platforms.platforms);

  const dispatch = useAppDispatch();

  const { violationsData, isWimTransitViolationsLoading } = useAppSelector(
    (state) => state.platforms.wims.transits.violations
  );

  useEffect(() => {
    if (queryUrlParams?.violationsModal?.laneUid) {
      const currentPlatform = platforms?.find((platform) => platform.uid === wimUid);
      const currentWim = currentPlatform?.lanes?.find((lane) => lane.uid === queryUrlParams.violationsModal.laneUid);

      setLaneType(currentWim?.laneType);
      setDirection(currentWim?.direction);
    }
  }, [platforms, wimUid]);

  useEffect(() => {
    if (violationsData && violationsData.length) {
      setCurrentViolations(getViolationsChartData(violationsData));
    }
  }, [violationsData]);

  useEffect(() => {
    if (queryUrlParams?.violationsModal?.date) {
      setCurrentDate(dayjs(parseInt(queryUrlParams.violationsModal.date, 10)));
    }
  }, [queryUrlParams?.violationsModal?.date]);

  useEffect(() => {
    if (queryUrlParams?.violationsModal?.laneUid) {
      dispatch(
        requestWimTransitsViolations({
          date: currentDate.valueOf(),
          wimUid,
          laneUid: queryUrlParams.violationsModal.laneUid,
        })
      );
    }
  }, [currentDate, wimUid]);

  const tableViolations = violationsData?.map((violation) => ({ ...violation, key: violation.date })) || [];

  return (
    <ModalWrapper
      isVisible={isActive}
      onClose={closeDetail}
      title={direction && laneType ? `${direction || ''},  ${laneType || ''}  ` : ''}
    >
      <Spin spinning={!violationsData}>
        <StyledDetailViolationsHeader className="detail-header">
          <SingleDatePicker currentDate={currentDate} setDateHandler={setCurrentDate} />
        </StyledDetailViolationsHeader>
        <StyledDetailWrapper>
          <StyledDetailTitle>{t('violationsPage.detail.totalCountViolation')}</StyledDetailTitle>
          <StyledDetailBarChartWrapper>
            <BarChartViolations
              data={currentViolations}
              dataKeys={[
                { ...axleLegend, key: 'axleOverweight', stackId: '1' },
                { ...bothLegend, key: 'bothOverweight', stackId: '1' },
                { ...fullLegend, key: 'fullOverweight', stackId: '1' },
              ]}
              dataKeyX="weight"
              tooltip={false}
            />
            <ViolationsLegend payload={[fullLegend, axleLegend, bothLegend]} />
          </StyledDetailBarChartWrapper>
          <StyledDetailTitle>
            {t('common.violations')}({violationsData?.length || 0})
          </StyledDetailTitle>
        </StyledDetailWrapper>
        <DetailTable data={tableViolations} isLoading={isWimTransitViolationsLoading} />
      </Spin>
    </ModalWrapper>
  );
};

export default ViolationsDetail;
