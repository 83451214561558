import React from 'react';

import { Col } from 'antd';

import { DetailType } from 'pages/Violations';
import { Lane } from './Lane';
import { CurrentLane } from '..';

export type LanesProps = {
  lanes: CurrentLane[];
  year: number;
  start?: number;
  end?: number;
  showDetail: (detailType: DetailType, date: number, laneUid?: string) => void;
};

export const Lanes: React.FC<LanesProps> = ({ lanes, year, start, end, showDetail }) => (
  <Col span={21}>
    {lanes.map((lane) => (
      <Lane
        violations={lane.violations}
        errors={lane.errors}
        recognitions={lane.plateRecognitions}
        laneUid={lane.uid}
        laneType={lane.type}
        key={lane.uid}
        showDetail={showDetail}
        year={year}
        start={start}
        end={end}
      />
    ))}
  </Col>
);
