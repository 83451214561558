import React from 'react';
import { Result, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import 'antd/dist/antd.css';
import { ROUTES } from 'app-constants/routes';

const PermissionDenied: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Result
      status="403"
      title={t('permissionDenied.title')}
      subTitle={t('permissionDenied.description')}
      extra={
        <Button type="primary" onClick={() => history.push(ROUTES.Home)}>
          {t('common.back')}
        </Button>
      }
    />
  );
};

export default PermissionDenied;
