import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { requestAllStatistics } from 'store/statistics/getAllStatistics/slice';
import { useUrlParams } from 'hooks/use-url-params';
import { HeaderFilter } from 'components/HeaderFilter';
import { useAppDispatch } from 'hooks/store-hooks';
import { ComponentFilterProps, withFilter } from 'hoc/withFilter';
import { queryParamsToFilterValues } from 'pages/Transits/TransitsFilterPanel/schema';
import useGetPlatforms from 'pages/Wim/hooks/use-get-platforms';
import { TimePeriod } from 'types/date-period';
import { useTranslation } from 'react-i18next';
import { INITIAL_TRANSITS_FILTER_VALUES } from './HomeFilter/hooks/use-home-page-filter';
import HomeFilter from './HomeFilter';
import Charts from './Charts';
import Gauge from './Gauges';
import StyledPlatformCards from './PlatformCards';
import { StyledHomeContent, StyledHomePage, StyledContentWrapper, StyledCheckbox } from './styles';
import { getDatePeriod } from './helpers/get-date-period';
import { StyledHeaderExtraButtons } from '../Transits/OnlineTabs/styles';
import { HeaderConfigContext } from '../../app/App';

export type FilterValues = {
  start?: number;
  end?: number;
  wimIds?: string[];
};
const timeToUpdate = 60;

const HomePage: FC<ComponentFilterProps<FilterValues>> = ({ filterManager }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setHeaderRenderer } = useContext(HeaderConfigContext);
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [update, setUpdate] = useState<number>(0);
  const [isRefresh, setIsRefresh] = useState<boolean>(false);
  const { queryUrlParams } = useUrlParams({});
  const { state, isFilterSubmitted } = filterManager;
  const currentInterval = state.end && state.start ? getDatePeriod(state.end - state.start) : undefined;
  const previousStartDate = () => {
    if (Number(queryUrlParams?.filter?.start) && Number(queryUrlParams?.filter?.end)) {
      return dayjs(Number(queryUrlParams?.filter?.start))
        .subtract(Number(queryUrlParams?.filter?.end) - Number(queryUrlParams?.filter?.start), 'ms')
        .valueOf();
    }

    return dayjs()
      .startOf('day')
      .subtract(dayjs().endOf('day').valueOf() - dayjs().startOf('day').valueOf())
      .valueOf();
  };

  const previousTimePeriod: TimePeriod = {
    start: previousStartDate(),
    end: Number(queryUrlParams?.filter?.start) || dayjs().startOf('day').valueOf(),
  };

  useGetPlatforms();

  useEffect(() => {
    if (queryUrlParams?.filter?.start && queryUrlParams?.filter?.end) {
      if (isFilterSubmitted) {
        dispatch(
          requestAllStatistics({
            start: queryUrlParams?.filter?.start,
            end: queryUrlParams?.filter?.end,
            wimUids: queryUrlParams?.filter?.wimIds,
          })
        );
      }
    } else {
      dispatch(
        requestAllStatistics({
          start: dayjs().startOf('day').valueOf(),
          end: dayjs().endOf('day').valueOf(),
        })
      );
    }
  }, [
    dispatch,
    update,
    isFilterSubmitted,
    queryUrlParams?.filter?.end,
    queryUrlParams?.filter?.start,
    queryUrlParams?.filter?.wimIds,
  ]);

  const onChangeRefresh = () => {
    if (!timer) {
      const intrvl = setInterval(() => {
        setUpdate((prev) => prev + 1);
      }, 1000 * timeToUpdate);
      setTimer(intrvl);
      setUpdate((prev) => prev + 1);
    }
    if (timer) {
      clearInterval(timer);
      setTimer(undefined);
    }
    setIsRefresh((prev) => !prev);
  };

  const headerStreamer = useCallback(
    () => (
      <StyledHeaderExtraButtons>
        <StyledCheckbox>
          <input type="checkbox" checked={isRefresh} onChange={onChangeRefresh} />
          <div>{t('common.updateEvery', { time: timeToUpdate })}</div>
        </StyledCheckbox>
        <HeaderFilter filterManager={filterManager} />
      </StyledHeaderExtraButtons>
    ),
    [isRefresh]
  );

  useEffect(() => {
    setHeaderRenderer(headerStreamer);

    return () => setHeaderRenderer(null);
  }, [setHeaderRenderer, headerStreamer]);

  return (
    <StyledHomePage>
      <StyledHomeContent>
        <Gauge previousTimePeriod={previousTimePeriod} />
        <StyledContentWrapper>
          <Charts
            datePeriod={currentInterval}
            start={state.start || dayjs().startOf('day').valueOf()}
            end={state.end || dayjs().valueOf()}
          />
          <StyledPlatformCards />
        </StyledContentWrapper>
      </StyledHomeContent>
    </StyledHomePage>
  );
};

export default withFilter<FilterValues>({
  filterProps: {
    initialState: INITIAL_TRANSITS_FILTER_VALUES,
    formatUrlParams: queryParamsToFilterValues,
  },
  FilterContent: HomeFilter,
})(HomePage);
