import React from 'react';
import i18n from 'i18n';
import { ColumnGroupType, ColumnType } from 'antd/lib/table';

import { wrapTableTitle } from 'components/TableContainer/DefaultTableContainer';
import { CHARS } from 'app-constants/chars';
import { separateByThousands } from 'helpers/number.formatter';
import { WimTransitAxle, WimSizeItem, WimTransitAxleGroup } from 'store/wim/types/transits';
import { convertMillimetersToMeters } from 'helpers/metric.convertor';

import { StyledCellText, StyledPercentText } from './styles';

export type AxlesTableRow = Omit<
  WimTransitAxle,
  | 'tires'
  | 'totalWeightError'
  | 'overweight'
  | 'speedLeft'
  | 'speedRight'
  | 'tireWidth'
  | 'axleGroupType'
  | 'trackWidth'
  | 'weightError'
  | 'weightLeft'
  | 'weightRight'
> & { groupIndex?: number };

export type TransitAxlesTableColumns = {
  [key in keyof Omit<AxlesTableRow, 'tires' | 'totalWeightError' | 'groupIndex'>]:
    | ColumnType<AxlesTableRow>
    | ColumnGroupType<AxlesTableRow>;
};

export type SizesTableRow = WimSizeItem & { param: string };
export type TransitSizesTableColumns = {
  [key in keyof SizesTableRow]: ColumnType<SizesTableRow> | ColumnGroupType<SizesTableRow>;
};

export const getTransitAxlesTableColumns = ({ withErrors }: { withErrors: boolean }): TransitAxlesTableColumns => ({
  number: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitPage.axlesTable.columns.axleIndex')),
    dataIndex: 'number',
    key: 'number',
    render: (value, { groupIndex, overweightPercent }) => {
      if (value === 0) {
        return (
          <StyledCellText isError={withErrors && overweightPercent > 0}>{`${value + 1} ${i18n.t(
            'transitPage.axlesTable.items.axle'
          )}`}</StyledCellText>
        );
      }

      if (!value || value < 0) {
        return (
          <StyledCellText isError={withErrors && overweightPercent > 0}>{`${groupIndex} ${i18n.t(
            'transitPage.axlesTable.items.group'
          )}`}</StyledCellText>
        );
      }

      if (groupIndex) {
        return (
          <StyledCellText isError={withErrors && overweightPercent > 0}>{`${value + 1} ${i18n.t(
            'transitPage.axlesTable.items.axle'
          )} (${groupIndex} ${i18n.t('transitPage.axlesTable.items.group')})`}</StyledCellText>
        );
      }

      return (
        <StyledCellText isError={withErrors && overweightPercent > 0}>{`${value + 1} ${i18n.t(
          'transitPage.axlesTable.items.axle'
        )}`}</StyledCellText>
      );
    },
  },
  wheelbase: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitPage.axlesTable.columns.interaxleSpace')),
    dataIndex: 'wheelbase',
    key: 'wheelbase',
    render: (value: number) => {
      return value > 0 ? convertMillimetersToMeters(value).toFixed(2) : CHARS.mdash;
    },
  },
  weight: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitPage.axlesTable.columns.totalWeight')),
    dataIndex: 'weight',
    key: 'weight',
    render: (value: number, { totalWeightErrorPercent, overweightPercent }) => {
      return (
        <StyledCellText isError={withErrors && totalWeightErrorPercent === 0 && overweightPercent > 0}>
          {value > 0 ? separateByThousands(+value.toFixed(2)) : CHARS.mdash}
        </StyledCellText>
      );
    },
  },
  weightLimit: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitPage.axlesTable.columns.weightLimit')),
    dataIndex: 'weightLimit',
    key: 'weightLimit',
    render: (value: number) => (value > 0 ? separateByThousands(+value.toFixed(2)) : CHARS.mdash),
  },
  totalWeightErrorPercent: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitPage.axlesTable.columns.totalWeightErrorPercent')),
    dataIndex: 'totalWeightErrorPercent',
    key: 'totalWeightErrorPercent',
    render: (value: number) => {
      const textSign = value > 0 ? '+' : '';
      const textValue = (value * 100).toFixed(2);

      return <StyledPercentText value={value}>{textSign + textValue}</StyledPercentText>;
    },
  },
  totalWeightWithError: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitPage.axlesTable.columns.totalWeightWithError')),
    dataIndex: 'totalWeightWithError',
    key: 'totalWeightWithError',
    render: (value: number, { overweightPercent, totalWeightErrorPercent }) => {
      return (
        <StyledCellText isError={withErrors && totalWeightErrorPercent > 0 && overweightPercent > 0}>
          {separateByThousands(+value.toFixed(2))}
        </StyledCellText>
      );
    },
  },
  overweightPercent: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitPage.axlesTable.columns.overweightPercent')),
    dataIndex: 'overweightPercent',
    key: 'overweightPercent',
    render: (value: number, { overweightPercent }) => {
      return (
        <StyledCellText isError={withErrors && overweightPercent > 0}>
          {value > 0 ? value.toFixed(2) : i18n.t('glossary.not')}
        </StyledCellText>
      );
    },
  },
  maxOverAxleLoad: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitPage.sizesTable.columns.limitMaxAllowedExcess')),
    dataIndex: 'maxOverAxleLoad',
    key: 'maxOverAxleLoad',
    render: (value) => {
      const getOversizeString = () => {
        if (value <= 0) {
          return CHARS.mdash;
        }

        return `${value} ${i18n.t('units.weight.kilogram.shortName')}`;
      };

      return getOversizeString();
    },
  },
});

export const getTransitSizesTableColumns = ({ withErrors }: { withErrors: boolean }): TransitSizesTableColumns => ({
  param: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitPage.sizesTable.columns.param')),
    dataIndex: 'param',
    key: 'param',
    render: (value, { oversize }) => {
      return <StyledCellText isError={withErrors && oversize > 0}>{value}</StyledCellText>;
    },
  },
  value: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitPage.sizesTable.columns.value')),
    dataIndex: 'value',
    key: 'value',
    render: (value, { param }) => {
      if (param === i18n.t('transitPage.sizesTable.items.weight')) {
        return `${value} ${i18n.t('units.weight.kilogram.shortName')}`;
      }
      return `${convertMillimetersToMeters(value)} ${i18n.t('units.length.metre.shortName')}`;
    },
  },
  limit: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitPage.sizesTable.columns.limit')),
    dataIndex: 'limit',
    key: 'limit',
    render: (value, { param }) => {
      if (value === 0) {
        return CHARS.mdash;
      }
      if (param === i18n.t('transitPage.sizesTable.items.weight')) {
        return `${value} ${i18n.t('units.weight.kilogram.shortName')}`;
      }

      return `${convertMillimetersToMeters(value)} ${i18n.t('units.length.metre.shortName')}`;
    },
  },
  oversize: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitPage.sizesTable.columns.oversize')),
    dataIndex: 'oversize',
    key: 'oversize',
    render: (value, { oversize, param, limit }) => {
      const getOversizeString = () => {
        if (value <= 0 || !limit || limit === 0 || limit === null) {
          return i18n.t('glossary.not');
        }
        if (param === i18n.t('transitPage.sizesTable.items.weight')) {
          return `${value} ${i18n.t('units.weight.kilogram.shortName')}`;
        }

        return `${convertMillimetersToMeters(value)} ${i18n.t('units.length.metre.shortName')}`;
      };

      return <StyledCellText isError={withErrors && oversize > 0}>{getOversizeString()}</StyledCellText>;
    },
  },
  limitMaxAllowedExcess: {
    title: (): JSX.Element => wrapTableTitle(i18n.t('transitPage.sizesTable.columns.limitMaxAllowedExcess')),
    dataIndex: 'limitMaxAllowedExcess',
    key: 'limitMaxAllowedExcess',
    render: (value, { param }) => {
      const getOversizeString = () => {
        if (value <= 0) {
          return CHARS.mdash;
        }
        if (param === i18n.t('transitPage.sizesTable.items.weight')) {
          return `${value} ${i18n.t('units.weight.kilogram.shortName')}`;
        }

        return `${convertMillimetersToMeters(value)} ${i18n.t('units.length.metre.shortName')}`;
      };

      return getOversizeString();
    },
  },
});
