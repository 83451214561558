import React from 'react';

import { RightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { stringifyUrlParams, useUrlParams } from 'hooks/use-url-params';

import { ROUTES } from 'app-constants/routes';
import { VEHICLE_CLASSES } from 'app-constants/vehicle-classes';
import VrpLink from 'pages/Violations/helpers/VrpLink';
import { PlatformsListType } from 'store/statistics/platformsList/slice';
import {
  StyledCounter,
  StyledLink,
  StyledPlatformListItem,
  StyledRoad,
  StyledValue,
  StyledVehicleClass,
} from './styles';
import palette from '../../../../palette';
import { INITIAL_TRANSITS_FILTER_VALUES } from '../../../../store/wim/filter/transits';
import { WimViolationFilterOptionType } from '../../../../store/wim/types/transits';

type ListItemProps = {
  counter: number;
} & Partial<PlatformsListType>;

const ListItem = ({ wimUid, vrpFront, vehicleClass, name, road, violations, counter }: ListItemProps) => {
  const { queryUrlParams } = useUrlParams({});
  const history = useHistory();
  return (
    <StyledPlatformListItem key={`${name || vrpFront}`}>
      <StyledCounter>{counter}</StyledCounter>
      <StyledLink>
        {vrpFront ? (
          <VrpLink
            vrp={vrpFront}
            start={queryUrlParams.filter?.start || dayjs().startOf('day').valueOf()}
            end={queryUrlParams.filter?.end || dayjs().valueOf()}
          />
        ) : (
          <Button
            type="link"
            onClick={() =>
              history.push({
                pathname: `${ROUTES.Wim}/${wimUid}`,
                state: { fromPage: { pathname: ROUTES.Home } },
              })
            }
          >
            {name}
          </Button>
        )}
      </StyledLink>
      <StyledRoad>
        {vehicleClass ? (
          <StyledVehicleClass>
            <span>{vehicleClass}. </span>
            <span>
              <img src={VEHICLE_CLASSES[vehicleClass].icon} alt="" height={25} />
            </span>
          </StyledVehicleClass>
        ) : (
          road
        )}
      </StyledRoad>
      <StyledValue>
        {vrpFront ? (
          violations
        ) : (
          <Link
            color={palette.blue.b3}
            to={{
              pathname: ROUTES.TransitsArchiveWim,
              search: stringifyUrlParams({
                sort: { key: 'datetime', value: 'descend' },
                filter: {
                  ...INITIAL_TRANSITS_FILTER_VALUES,
                  platformUids: [wimUid],
                  violationsTypesByOr: Object.values(WimViolationFilterOptionType),
                  start: queryUrlParams.filter?.start || dayjs().startOf('day').valueOf(),
                  end: queryUrlParams.filter?.end || dayjs().valueOf(),
                },
              }),
            }}
          >
            {violations}
          </Link>
        )}
      </StyledValue>
    </StyledPlatformListItem>
  );
};

export default ListItem;
