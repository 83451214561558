import styled from 'styled-components/macro';

import { carIcon, emptyTruckIcon, truckIcon } from 'assets';
import { INTRERPOLATION, VehiclesTypes } from 'pages/Violations/constants/violations';
import palette from 'palette';

interface LegendMarkerType {
  dataKey?: string;
  color?: string;
  width?: string;
  height?: string;
}

export const StyledLegendItemMarker = styled.div<LegendMarkerType>`
  width: ${({ width = '16px' }) => width};
  height: ${({ height = '10px' }) => height};
  margin: 15px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-mask-image: ${({ dataKey, color }) => {
    switch (dataKey) {
      case INTRERPOLATION:
        return `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg"%3E%3Cdefs%3E%3Cpattern id="stripedBG" width="4px" height="4px" patternUnits="userSpaceOnUse" patternTransform="rotate(45)"%3E%3Cline stroke="%238cc8ff" stroke-width="2px" y2="4px"%3E%3C/line%3E%3C/pattern%3E%3C/defs%3E%3Cpath d="m 0 0 h 20 v 10 h -20 Z" fill="url(%23stripedBG)"%3E%3C/path%3E%3C/svg%3E')`;
      case VehiclesTypes.empty_truck:
        return `url(${emptyTruckIcon})`;
      case VehiclesTypes.truck:
        return `url(${truckIcon})`;
      case VehiclesTypes.car:
        return `url(${carIcon})`;
      default:
        return color;
    }
  }};
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  background-color: ${({ color, dataKey }) => {
    switch (dataKey) {
      case VehiclesTypes.empty_truck:
        return palette.orange.o2;
      case VehiclesTypes.truck:
        return palette.purple.p2;
      case VehiclesTypes.car:
        return palette.green.g5;
      default:
        return color;
    }
  }};
`;

export const StyledLegend = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

export const StyledLegendItem = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 0 0 5px;
`;
