import styled from 'styled-components/macro';
import { Space } from 'antd';

import palette from 'palette';

export const FilterWrapper = styled.form`
  width: 100%;
  padding: 20px;
  background-color: ${palette.white.w1};
  border-bottom: 1px solid ${palette.grey.g3};
`;

export const ButtonsContainer = styled(Space)`
  display: flex;
  justify-content: flex-end;
`;
