import { useEffect, useState } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { useAppDispatch } from './store-hooks';
import { useUrlParams } from './use-url-params';

export interface FilterProps<T> {
  initialState: T;
  formatUrlParams?: (urlParams: Record<string, any>) => T;
  setFilterValuesAction?: ActionCreatorWithPayload<T, string>;
  defaultOpen?: boolean;
}

export interface FilterReturnType<T> {
  isFilterActive: boolean;
  state: T;
  onFilterSubmitClick: (query?: T) => void;
  onFilterClearClick: () => void;
  onRemoveFields: (...fields: (keyof T)[]) => void;
  handleFilterChange: (field: keyof T, value: unknown) => void;
  handleFilterOpen: () => void;
  handleFilterClose: () => void;
  isFilterSubmitted: boolean;
  handleFilterSubmitted: (isSubmitted: boolean) => void;
  isFilterSubmitClicked: boolean;
}

export function useFilter<T>({
  initialState,
  formatUrlParams,
  setFilterValuesAction,
  defaultOpen,
}: FilterProps<T>): FilterReturnType<T> {
  const { queryUrlParams, onUrlParamsChange, onPushUrlParams, onSliceUrlParams } = useUrlParams({});
  const [state, setState] = useState<T>(initialState);
  const [isFilterActive, setIsFilterActive] = useState(defaultOpen || !!queryUrlParams?.filter || false);
  const [isFilterSubmitted, setIsFilterSubmitted] = useState(!!queryUrlParams?.filter);
  const [isFilterSubmitClicked, setIsFilterSubmitClicked] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (queryUrlParams.filter) {
      formatUrlParams ? setState(formatUrlParams(queryUrlParams.filter) as T) : setState(queryUrlParams.filter as T);
      setFilterValuesAction && dispatch(setFilterValuesAction({ ...queryUrlParams.filter }));
    }
  }, []);

  const onFilterSubmitClick = () => {
    onUrlParamsChange({ filter: state });
    onPushUrlParams({ filter: state });
    setFilterValuesAction && dispatch(setFilterValuesAction({ ...state }));
    setIsFilterSubmitted(true);
    setIsFilterSubmitClicked(true);
  };

  const onFilterClearClick = () => {
    const { filter, ...restQueryParams } = queryUrlParams;
    onUrlParamsChange(restQueryParams);
    setState(initialState);
    onSliceUrlParams(restQueryParams);
    setFilterValuesAction && dispatch(setFilterValuesAction({ ...initialState }));
    setIsFilterSubmitted(false);
  };

  const onRemoveFields = (...fields: (keyof T)[]) => {
    const newState = { ...state };
    fields.forEach((fieldName) => delete newState[fieldName]);
    setState(newState);
  };

  const handleFilterChange = (field: keyof T, value: unknown) => {
    setState((s) => ({ ...s, [field]: value }));
    setIsFilterSubmitClicked(false);
  };

  const handleFilterOpen = () => {
    setIsFilterActive(true);
  };

  const handleFilterClose = () => {
    setIsFilterActive(false);
  };

  const handleFilterSubmitted = (isSubmitted: boolean) => {
    setIsFilterSubmitted(isSubmitted);
  };

  return {
    isFilterActive,
    state,
    onFilterSubmitClick,
    onFilterClearClick,
    handleFilterChange,
    handleFilterOpen,
    handleFilterClose,
    isFilterSubmitted,
    handleFilterSubmitted,
    isFilterSubmitClicked,
    onRemoveFields,
  };
}
