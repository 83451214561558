import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { WIMAlertIcon, WIMDangerIcon, WIMNormalIcon } from 'assets/icons';
import { PlatformType } from 'store/platforms/types';
import { Button, Checkbox } from 'antd';
import { Link } from 'react-router-dom';

interface LegendProps {
  platformType: PlatformType;
  onChange?: () => void;
  onStatusClick?: (status: string) => void;
}

// todo: move into styles.ts
const StyledFlexBlock = styled.div`
  display: flex;
  align-items: center;
  column-gap: 7px;
`;

const StyledLegend = styled.div`
  background-color: white;
  position: absolute;
  display: flex;
  column-gap: 24px;
  padding: 8px 25px;
  top: 40px;
  left: 10px;
  z-index: 1000;
`;

const Legend: React.FC<LegendProps> = ({ platformType, onChange, onStatusClick }) => {
  const { t } = useTranslation();

  const getLegend = () => {
    switch (platformType) {
      case PlatformType.DETECTORS:
        return (
          <StyledFlexBlock>
            <span>{t('map.mapLegend.camera')}</span>
          </StyledFlexBlock>
        );

      case PlatformType.WIM:
        return (
          <>
            <StyledFlexBlock>
              <Button type="link" onClick={(e) => (onStatusClick ? onStatusClick('online') : undefined)}>
                <img src={WIMNormalIcon} alt={t('map.mapLegend.active')} />
                <span>{t('map.mapLegend.active')}</span>
              </Button>
            </StyledFlexBlock>
            <StyledFlexBlock>
              <Button type="link" onClick={(e) => (onStatusClick ? onStatusClick('offline') : undefined)}>
                <img src={WIMDangerIcon} alt={t('map.mapLegend.danger')} />
                <span>{t('map.mapLegend.danger')}</span>
              </Button>
            </StyledFlexBlock>
            <StyledFlexBlock>
              <Button type="link" onClick={(e) => (onStatusClick ? onStatusClick('warn') : undefined)}>
                <img src={WIMAlertIcon} alt={t('map.mapLegend.alert')} />
                <span>{t('map.mapLegend.alert')}</span>
              </Button>
            </StyledFlexBlock>
            <StyledFlexBlock>
              <Checkbox defaultChecked onChange={onChange}>
                {t('map.mapLegend.info')}
              </Checkbox>
            </StyledFlexBlock>
          </>
        );
      default:
        return null;
    }
  };

  return <StyledLegend>{getLegend()}</StyledLegend>;
};

export default Legend;
