import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { wimActions } from 'store/wim/slices';

const useClearActiveWimPlatformOnUnmount = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(wimActions.activePlatform.clearActiveWimPlatform());
    };
  }, [dispatch]);
};

export { useClearActiveWimPlatformOnUnmount };
