import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spin } from 'antd';
import { FieldArray, Formik } from 'formik';

import { EditableTableContainer } from 'components';
import palette from 'palette';
import { Certificate } from 'store/admin/certificates/types';
import { StyledButtons, StyledContentForm, StyledEditBlock } from './styles';
import { certificatesColumns } from './table-schema';
import { UpdateCertificateValues } from './types';

interface UpdateContentProps {
  certificates: Certificate[];
  onSubmitForm: (values: Partial<UpdateCertificateValues>) => void;
  isLoading: boolean;
}

export const UpdateContent: FC<UpdateContentProps> = ({ certificates, onSubmitForm, isLoading }) => {
  const { t } = useTranslation();

  const rowAdd: Certificate = {
    uid: '',
    wimUid: '',
    name: '',
    type: '',
    description: '',
    createDate: '',
    updateDate: '',
  };

  return (
    <Formik
      initialValues={{
        certificates,
      }}
      onSubmit={(submittedValues) => {
        onSubmitForm(submittedValues);
      }}
    >
      {({ values, handleSubmit, handleReset }) => (
        <StyledContentForm onSubmit={handleSubmit} onReset={handleReset}>
          <Spin spinning={isLoading}>
            <StyledEditBlock>
              <FieldArray
                name="certificates"
                render={(arrayHelpers) => (
                  <EditableTableContainer<Certificate>
                    form={arrayHelpers}
                    rowAdd={rowAdd}
                    columns={certificatesColumns}
                    dataSource={values.certificates}
                    pagination={false}
                    bordered
                  />
                )}
              />
            </StyledEditBlock>
          </Spin>
          <StyledButtons>
            <Button htmlType="submit" style={{ color: palette.white.w1, backgroundColor: palette.blue.b1 }}>
              {t('common.apply')}
            </Button>
            <Button onClick={handleReset} style={{ color: palette.white.w1, backgroundColor: palette.red.r3 }}>
              {t('common.cancel')}
            </Button>
          </StyledButtons>
        </StyledContentForm>
      )}
    </Formik>
  );
};
