import React from 'react';

import setBackground from 'helpers/set-background';
import { Bar, Line } from 'recharts';

import bg, { BarItems } from 'components/BarChart/helpers/bar-chart-helper';
import palette from 'palette';

interface BarProps {
  keys: string[];
  axleXDatakey?: string;
  lineKey?: string;
}

export const getBars = ({ keys, axleXDatakey = 'date', lineKey = '' }: BarProps) =>
  keys.reduce<JSX.Element[]>((accum, key) => {
    if (key !== axleXDatakey) {
      accum.push(
        <React.Fragment key={key}>
          {key !== lineKey ? (
            <Bar dataKey={key} stackId={1} fill={bg.setBarColor(key as BarItems)} shape={setBackground} />
          ) : (
            <Line type="monotone" dataKey={lineKey} stroke={palette.orange.o2} strokeWidth={3} />
          )}
        </React.Fragment>
      );
    }

    return accum;
  }, []);
