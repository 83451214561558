import styled from 'styled-components/macro';

export const StyledFormikInputFieldWithLabel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const StyledLabel = styled.span`
  width: 35%;
`;

export const fieldError = {
  boxShadow: '0 0 3px 0px red',
  border: '1px solid red',
};

export const StyledError = styled.div`
  text-align: center;
  font-size: 12px;
  color: red;
`;
