import styled from 'styled-components/macro';

import palette from 'palette';

export const StyledMonitoringWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${palette.white.w1};
  padding: 24px;
  margin: 24px;
`;

export const StyledBlockWrapper = styled.div`
  max-width: calc(50% - 10px);
`;

export const StyledBlockWrapperRight = styled.div`
  max-width: calc(50% - 10px);
  min-width: calc(50% - 10px);

  .min-height-bg {
    min-height: 200px;
    background: ${palette.grey.g11};
  }
`;
