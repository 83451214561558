import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetWimPlatformSummaryViolationsQueryParams } from 'store/wim/types/platforms';
import { WimViolationType } from './types';

type InitialState = {
  isWimTransitViolationsLoading: boolean;
  violationsData: WimViolationType[] | null;
  error: string | null;
};

const initialState: InitialState = {
  isWimTransitViolationsLoading: false,
  violationsData: null,
  error: null,
};

const wimTransitViolation = createSlice({
  name: 'wimTransitViolation',
  initialState,
  reducers: {
    requestWimTransitsViolations: (
      state,
      { payload }: PayloadAction<GetWimPlatformSummaryViolationsQueryParams>
    ): void => {
      state.isWimTransitViolationsLoading = true;
    },

    getWimTransitViolationsSuccess: (state, { payload }: PayloadAction<WimViolationType[]>): void => {
      state.isWimTransitViolationsLoading = false;
      state.violationsData = payload;
    },

    getWimTransitViolationsError: (state, { payload }: PayloadAction<string>): void => {
      state.isWimTransitViolationsLoading = false;
      state.error = payload;
    },
  },
});

export const { requestWimTransitsViolations, getWimTransitViolationsSuccess, getWimTransitViolationsError } =
  wimTransitViolation.actions;

export default wimTransitViolation.reducer;
