import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import palette from 'palette';

import { ComponentFilterProps } from 'hoc/withFilter';
import { FilterValues } from 'pages/Home';
import { HeaderConfigContext } from 'app/App';
import dayjs from 'dayjs';
import { HeaderFilter } from '../HeaderFilter';
import { ExportIcon } from '../HeaderFilter/styles';
import { useAppDispatch, useAppSelector } from '../../hooks/store-hooks';
import wimSelectors from '../../store/wim/selectors';
import { wimActions } from '../../store/wim/slices';
import ExportDialog from '../ExportDialog';

interface FilterWithExportButtonProps extends ComponentFilterProps<FilterValues> {
  progress?: number;
  completed?: boolean;
  fileIsLoading: boolean;
  visibleButtons: { cancel: boolean; export: boolean; getFile: boolean };
  openExportDialog: () => void;
  closeExportDialog: () => void;
  onExport: (format: string) => void;
  onCancel: () => void;
  getFile: () => void;
}

export const HeaderFilterWithExportButton: FC<FilterWithExportButtonProps> = React.memo(
  ({
    filterManager,
    openExportDialog,
    closeExportDialog,
    onExport,
    progress,
    completed,
    fileIsLoading,
    onCancel,
    getFile,
    visibleButtons,
  }) => {
    const { t } = useTranslation();
    return (
      <HeaderFilter filterManager={filterManager}>
        <Tooltip
          title={<span style={{ color: palette.black.b1 }}>{t('common.exportXlsx')}</span>}
          color={palette.white.w1}
        >
          <Button type="text" onClick={openExportDialog} icon={<ExportIcon rotate={90} />} />
        </Tooltip>
        <ExportDialog
          onClose={closeExportDialog}
          onExport={onExport}
          progress={progress}
          onCancel={onCancel}
          getFile={getFile}
          completed={completed || undefined}
          fileIsLoading={fileIsLoading}
          visibleButtons={visibleButtons}
        />
      </HeaderFilter>
    );
  }
);

export const useHeaderWithFiltersRendererWithExport = ({ filterManager }: ComponentFilterProps<FilterValues>): void => {
  const dispatch = useAppDispatch();
  const { setHeaderRenderer } = useContext(HeaderConfigContext);
  const { isFilterActive } = filterManager;
  const exportDialogIsOpen = useAppSelector(wimSelectors.transits.exportDialogIsOpen);
  const key = useAppSelector(wimSelectors.transits.exportStartKey);
  const exportStatus = useAppSelector(wimSelectors.transits.exportStatus);
  const fileIsLoading = useAppSelector(wimSelectors.transits.fileIsLoading);

  const [progress, setProgress] = useState<number | undefined>();
  const [format, setFormat] = useState<string>('csv');
  const [completed, setCompleted] = useState<boolean>(false);
  const [intrvl, setIntrvl] = useState<ReturnType<typeof setInterval> | null>(null);
  const [isCanceled, setIsCanceled] = useState(false);

  const onCloseExportDialog = () => {
    dispatch(wimActions.transits.onCloseExportDialog());
  };

  const onExport = async (_format: string) => {
    setIsCanceled(false);
    setProgress(5);
    setFormat(_format);
    dispatch(wimActions.documentExport.requestTransitsExportDocument(_format));
  };

  const toggleExportDialog = () => {
    if (exportDialogIsOpen) {
      dispatch(wimActions.transits.onCloseExportDialog());
      return;
    }

    dispatch(wimActions.transits.onOpenExportDialog());
  };

  const getStatus = useCallback(() => {
    if (key) {
      dispatch(wimActions.documentExport.requestExportDocumentStatus(key));
    }
  }, [key]);

  const getFile = useCallback(() => {
    if (key) {
      const { start, end } = filterManager.state;
      const fileName = `export_waga_${dayjs(start).format('YYYY-MM-DDTHH-mm-ss')}_${dayjs(end).format(
        'YYYY-MM-DDTHH-mm-ss'
      )}.${format}`;
      dispatch(wimActions.documentExport.requestExportDocumentFile({ key, fileName }));
      setCompleted(false);
      setIsCanceled(false);
      onCloseExportDialog();
      if (intrvl) {
        clearInterval(intrvl);
      }
      setIntrvl(null);
      setProgress(undefined);
    }
  }, [key]);

  const setGetProgressInterval = () => {
    if (intrvl) {
      return;
    }

    setIntrvl(
      setInterval(() => {
        getStatus();
        if (progress) {
          setProgress((prev) => {
            if (!prev) {
              return 5;
            }
            return prev > 95 ? prev : prev + 2;
          });
        }
      }, 1000)
    );
  };

  const onCancel = useCallback(
    (error = false) => {
      setIsCanceled(true);
      setCompleted(false);
      onCloseExportDialog();
      if (intrvl) {
        clearInterval(intrvl);
      }
      setIntrvl(null);
      if (!error && key) {
        dispatch(wimActions.documentExport.requestExportDocumentCancel(key));
      }
      if (error) {
        // dispatch(wimActions.documentExport.setDocumentExportInitialState());
      }
      setProgress(undefined);
    },
    [intrvl, key, isCanceled]
  );

  useEffect(() => {
    if (isCanceled) {
      return;
    }
    if (exportStatus === 'COMPLETED' && progress) {
      setCompleted(true);
      if (intrvl) {
        clearInterval(intrvl);
      }
      if (!completed) {
        setProgress(100);
      }
      return;
    }
    if (exportStatus === 'LIMIT_EXCEEDED') {
      onCancel(true);
      alert(`Error: Перевищено ліміт експорту.`);
      dispatch(wimActions.documentExport.setDocumentExportInitialState());
      return;
    }
    if (exportStatus === 'FAILED' || exportStatus === 'INTERRUPTED') {
      onCancel(true);
      alert(`Error: Помилка експорту`);
      dispatch(wimActions.documentExport.setDocumentExportInitialState());
      return;
    }
    if (exportStatus === 'IN_PROGRESS' && !intrvl) {
      setGetProgressInterval();
    }
  }, [exportStatus, intrvl, completed, progress]);

  useEffect(() => {
    setHeaderRenderer(
      <HeaderFilterWithExportButton
        filterManager={filterManager}
        openExportDialog={toggleExportDialog}
        closeExportDialog={onCloseExportDialog}
        onExport={onExport}
        progress={progress}
        onCancel={onCancel}
        getFile={getFile}
        completed={completed}
        fileIsLoading={fileIsLoading}
        visibleButtons={{
          cancel: Boolean(key) && Boolean(intrvl) && (completed || Boolean(progress)),
          export: !(completed || progress),
          getFile: Boolean(progress) && completed,
        }}
      />
    );
    return () => setHeaderRenderer(null);
    // eslint-disable-next-line
  }, [setHeaderRenderer, isFilterActive, exportDialogIsOpen, progress, getFile, completed, fileIsLoading]);
};
