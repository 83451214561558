import httpService from 'services/http.service';
import { ApiRequest } from 'types';
import { API_URLS } from '../app-constants/api';
import { UpdateWimPlatformData, UpdateWimPlatformFormData, WimPlatformUid } from '../store/wim/types/platforms';
import secondaryHttpService from '../services/secondary-http.service';
import { UpdatePlatformMedia } from '../pages/AdminManagement/Wims/components/PlatformSummary/types';

export const getPlatformDetectors: ApiRequest = (data) => httpService.get('URL', data);

export const updatePlatform =
  (platform: UpdateWimPlatformData): ApiRequest =>
  () => {
    const { uid, ...copyUpdatedPlatform } = platform;
    return secondaryHttpService.put(API_URLS.updatePlatform(uid), copyUpdatedPlatform);
  };

export const createPlatform =
  (platform: UpdateWimPlatformFormData): ApiRequest =>
  () => {
    return secondaryHttpService.post(API_URLS.createPlatform(), platform);
  };

export const updatePlatformMedia =
  (data: UpdatePlatformMedia): ApiRequest =>
  () => {
    const { uid } = data;
    return secondaryHttpService.post(API_URLS.uploadPlatformMedia(uid as WimPlatformUid), data);
  };
