import styled from 'styled-components/macro';

import { Layout } from 'antd';

import palette from 'palette';

const { Sider } = Layout;

export const StyledSider = styled(Sider)`
  background: ${palette.blue.b2};
  overflow: auto;
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;

  .ant-menu,
  .ant-menu-item {
    background: ${palette.blue.b2};
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 calc(50% - 24px / 2);
  }

  .ant-menu-title-content,
  .ant-menu-item > span > a {
    color: rgba(255, 255, 255, 0.85);
  }

  .ant-menu.ant-menu-sub {
    background: ${palette.blue.b4};
  }

  .ant-menu-sub > .ant-menu-item {
    background: ${palette.blue.b4};
  }

  .ant-menu-item-icon > svg {
    width: 1.5em;
    height: 1.5em;
  }

  .ant-layout-sider-trigger {
    background: ${palette.blue.b2};
    color: rgba(255, 255, 255, 0.85);

    .trigger-wrapper.folded {
      padding: 0 calc(50% - 24px / 2);
      display: flex;
      align-items: center;
      transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .trigger-wrapper {
      display: flex;
      align-items: center;
      transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
      padding-left: 24px;

      .trigger-title {
        margin-left: 10px;
        opacity: 1;
        transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
      }

      .trigger-title.folded-title {
        opacity: 0;
        transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
      }

      .anticon {
        transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
          margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
      }

      .anticon.anticon-menu-unfold {
        transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
          margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      .anticon.anticon-menu-fold {
        transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
          color 0.3s;

        svg {
          width: 1.5em;
          height: 1.5em;
        }
      }
    }
  }
`;

export const StyledExtendLink = styled.span`
  position: absolute;
  top: -7px;
  margin-left: 3px;
`;
