import { WimTransitItemOld } from 'store/wim/types';
import { WimAnalysisTransits } from 'store/wim/types/transits';

const mockedTransitCard: WimTransitItemOld = {
  id: '1002191052',
  framename: 'WIM15 - Н-08 км 468+739',
  wimName: 'LU-DN-W2 (Лукашево-Дніпро)',
  platformName: 'LU',
  datetime: 1642105713000,
  wim: 'LU-DN-W2',
  vendorErrors: [
    {
      id: '101',
      name: 'Неможливо визначити положення колеса',
    },
    {
      id: '108',
      name: 'Маса ТЗ поза допустимого діапазону',
    },
    {
      id: '109',
      name: 'Маса осьового навантаження поза допустимого діапазону',
    },
    {
      id: '111',
      name: 'ТЗ між смугами руху',
    },
  ],
  offenceTypes: [],
  plate: 'AP0826EO',
  plateRecognized: true,
  plateBack: 'UNKNOWN_NR',
  plateBackRecognized: false,
  vehicleType: 'Легковий автомобіль',
  trailerPresence: false,
  weight: 928,
  speed: 57,
  direction: 'Лукашево-Дніпро',
  length: 3.59,
  width: 1.527,
  height: 1.434,
  axlesCount: 2,
  wheelBase: 2.31,
  axles: [
    {
      axleIndex: 1,
      totalWeight: 520,
      totalWeightError: 0,
      totalWeightWithError: 520,
      totalWeightErrorPercent: 0,
      overweightPercent: 0,
      slope: 1,
      interaxleSpace: 2.31,
      weightLimit: 0,
    },
    {
      axleIndex: 2,
      totalWeight: 408,
      totalWeightError: 0,
      totalWeightWithError: 408,
      totalWeightErrorPercent: 0,
      overweightPercent: 0,
      slope: 1,
      interaxleSpace: 0,
      weightLimit: 0,
    },
  ],
};

const mockedTransitCard2 = {
  id: '1000395832',
  framename: 'WIM8 - Н-31 км 122+000',
  wimName: 'GA-DN-W2 (Галагурівка-Дніпро)',
  platformName: 'GA',
  datetime: 1642616539000,
  wim: 'GA-DN-W2',
  vendorErrors: [
    {
      id: '105',
      name: 'Вібрація шасі ТЗ',
    },
    {
      id: '205',
      name: 'Помилка вимірювання температури',
    },
    {
      id: '206',
      name: 'Калібрування системи недійсна',
    },
  ],
  offenceTypes: [],
  plate: 'BC7293HB',
  plateRecognized: true,
  plateBack: 'UNKNOWN_NR',
  plateBackRecognized: false,
  vehicleType: '2-вісний тягач із 3-вісним напівпричепом',
  trailerPresence: true,
  weight: 22187,
  speed: 62,
  direction: 'Галагурівка-Дніпро',
  length: 16.303,
  width: 2.677,
  height: 4.086,
  axlesCount: 5,
  wheelBase: 11.99,
  axles: [
    {
      axleIndex: 1,
      totalWeight: 7517,
      totalWeightError: 827,
      totalWeightWithError: 6690,
      totalWeightErrorPercent: 11.001729413329786,
      overweightPercent: 0,
      slope: 1,
      interaxleSpace: 3.79,
      weightLimit: 11000,
    },
    {
      axleIndex: 2,
      totalWeight: 7665,
      totalWeightError: 843,
      totalWeightWithError: 6822,
      totalWeightErrorPercent: 10.998043052837573,
      overweightPercent: 0,
      slope: 2,
      interaxleSpace: 5.58,
      weightLimit: 11000,
    },
    {
      axleIndex: 3,
      totalWeight: 2187,
      totalWeightError: 0,
      totalWeightWithError: 2187,
      totalWeightErrorPercent: 0,
      overweightPercent: 0,
      slope: 1,
      interaxleSpace: 1.31,
      weightLimit: 0,
    },
    {
      axleIndex: 4,
      totalWeight: 2302,
      totalWeightError: 0,
      totalWeightWithError: 2302,
      totalWeightErrorPercent: 0,
      overweightPercent: 0,
      slope: 1,
      interaxleSpace: 1.31,
      weightLimit: 0,
    },
    {
      axleIndex: 5,
      totalWeight: 2516,
      totalWeightError: 0,
      totalWeightWithError: 2516,
      totalWeightErrorPercent: 0,
      overweightPercent: 0,
      slope: 1,
      interaxleSpace: 0,
      weightLimit: 0,
    },
  ],
};

export const mockedComparativeAnalysisTransits: WimAnalysisTransits = {
  total: 2,
  items: [
    {
      ...mockedTransitCard,
      children: [
        {
          ...mockedTransitCard,
          weight: 1020.8,
          height: 1.5774,
          weightDiff: 0.1,
          heightDiff: 0.1,
          axles: [
            {
              axleIndex: 1,
              totalWeight: 416,
              totalWeightError: 0,
              totalWeightWithError: 416,
              totalWeightErrorPercent: 0,
              overweightPercent: 0,
              slope: 1,
              interaxleSpace: 2.31,
              weightLimit: 0,
              totalWeightDiff: -0.2,
            },
            {
              axleIndex: 2,
              totalWeight: 489.6,
              totalWeightError: 0,
              totalWeightWithError: 408,
              totalWeightErrorPercent: 0,
              overweightPercent: 0,
              slope: 1,
              interaxleSpace: 0,
              weightLimit: 0,
              totalWeightDiff: 0.2,
            },
          ],
        },
        {
          ...mockedTransitCard,
          weight: 1113.6,
          height: 1.72,
          weightDiff: 0.2,
          heightDiff: 0.2,
          width: 1.2216,
          widthDiff: -0.2,
          length: 2.872,
          lengthDiff: -0.2,
          axlesCount: 3,
          axlesDiff: 1,
          axles: [
            {
              axleIndex: 1,
              totalWeight: 416,
              totalWeightError: 0,
              totalWeightWithError: 416,
              totalWeightErrorPercent: 0,
              overweightPercent: 0,
              slope: 1,
              interaxleSpace: 2.31,
              weightLimit: 0,
              totalWeightDiff: -0.2,
            },
            {
              axleIndex: 2,
              totalWeight: 408,
              totalWeightError: 0,
              totalWeightWithError: 408,
              totalWeightErrorPercent: 0,
              overweightPercent: 0,
              slope: 1,
              interaxleSpace: 0,
              weightLimit: 0,
              totalWeightDiff: 0.2,
            },
            {
              axleIndex: 3,
              totalWeight: 408,
              totalWeightError: 0,
              totalWeightWithError: 408,
              totalWeightErrorPercent: 0,
              overweightPercent: 0,
              slope: 1,
              interaxleSpace: 0,
              weightLimit: 0,
            },
          ],
        },
        {
          ...mockedTransitCard,
          weight: 1067.2,
          height: 2.38044,
          weightDiff: 0.15,
          heightDiff: 0.66,
        },
      ],
    },
    {
      ...mockedTransitCard2,
      children: [
        {
          ...mockedTransitCard2,
          weight: 26624.4,
          width: 1.6062,
          weightDiff: 0.2,
          widthDiff: -0.4,
        },
        {
          ...mockedTransitCard2,
          axlesCount: 4,
          axles: [
            {
              axleIndex: 1,
              totalWeight: 8268.7,
              totalWeightError: 827,
              totalWeightWithError: 6690,
              totalWeightErrorPercent: 11.001729413329786,
              overweightPercent: 0,
              slope: 1,
              interaxleSpace: 3.79,
              weightLimit: 11000,
              totalWeightDiff: 0.1,
            },
            {
              axleIndex: 2,
              totalWeight: 7665,
              totalWeightError: 843,
              totalWeightWithError: 6822,
              totalWeightErrorPercent: 10.998043052837573,
              overweightPercent: 0,
              slope: 2,
              interaxleSpace: 5.58,
              weightLimit: 11000,
            },
            {
              axleIndex: 3,
              totalWeight: 2624.4,
              totalWeightError: 0,
              totalWeightWithError: 2187,
              totalWeightErrorPercent: 0,
              overweightPercent: 0,
              slope: 1,
              interaxleSpace: 1.31,
              weightLimit: 0,
              totalWeightDiff: 0.2,
            },
            {
              axleIndex: 4,
              totalWeight: 2302,
              totalWeightError: 0,
              totalWeightWithError: 2302,
              totalWeightErrorPercent: 0,
              overweightPercent: 0,
              slope: 1,
              interaxleSpace: 1.31,
              weightLimit: 0,
            },
          ],
          length: 13.0424,
          lengthDiff: -0.2,
          axlesDiff: -1,
        },
      ],
    },
  ],
};
