import { LabeledValue, SelectValue } from 'antd/lib/select';
import dayjs, { Dayjs } from 'dayjs';

import { OPTION_KEYS_FOR_SELECT } from 'app-constants/select-options';
import { useAppSelector } from 'hooks/store-hooks';
import { FilterReturnType } from 'hooks/use-filter';

import wimSelectors from 'store/wim/selectors';

import { TransitsFilterValues } from 'store/wim/types/transits';

export const INITIAL_TRANSITS_FILTER_VALUES: Partial<TransitsFilterValues> = {
  start: dayjs().startOf('day').valueOf(),
  end: dayjs().endOf('day').valueOf(),
};

export function useHomePageFilter<TransitsType extends TransitsFilterValues>({
  state,
  handleFilterChange,
}: Pick<FilterReturnType<Partial<TransitsType>>, 'handleFilterChange' | 'state'>) {
  const platforms = useAppSelector(wimSelectors.platforms.getPlatforms);

  const onPlatformNameChange = (value: SelectValue) => {
    if (value && Array.isArray(value)) {
      if (value.includes(OPTION_KEYS_FOR_SELECT.All as string & LabeledValue)) {
        handleFilterChange(
          'wimIds',
          platforms?.map((platform) => platform.uid)
        );
      } else {
        handleFilterChange('wimIds', value);
      }
    }
  };

  const onRangeChange = (ranges: [Dayjs | null, Dayjs | null] | null) => {
    if (!ranges) {
      return;
    }
    const [start, end] = ranges;
    handleFilterChange('start', dayjs(start).valueOf());
    handleFilterChange('end', dayjs(end).valueOf());
  };

  return {
    state,
    handleFilterChange,
    platforms,
    onPlatformNameChange,
    onRangeChange,
  };
}
