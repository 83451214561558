import React, { FC, useRef } from 'react';
import ReactDOM from 'react-dom';

import { FilterContainer } from 'components';
import { FilterProps, FilterReturnType, useFilter } from 'hooks/use-filter';

export interface ComponentFilterProps<FilterValues> {
  filterManager: Pick<
    FilterReturnType<Partial<FilterValues>>,
    | 'handleFilterClose'
    | 'handleFilterOpen'
    | 'isFilterActive'
    | 'isFilterSubmitted'
    | 'state'
    | 'handleFilterSubmitted'
    | 'isFilterSubmitClicked'
  >;
}

export interface ContentFilterProps<FilterValues> {
  filterManager: Pick<
    FilterReturnType<Partial<FilterValues>>,
    'handleFilterChange' | 'state' | 'onFilterSubmitClick' | 'onRemoveFields'
  >;
}

export const withFilter =
  <FilterValues extends Record<string, any>, OwnComponentProps = any, OwnContentFilterProps = any>({
    filterProps,
    FilterContent,
    filterContentProps,
  }: {
    filterProps: FilterProps<Partial<FilterValues>>;
    FilterContent: FC<ContentFilterProps<FilterValues> & OwnContentFilterProps>;
    filterContentProps?: Omit<OwnContentFilterProps, keyof ContentFilterProps<FilterValues>>;
  }) =>
  (Component: React.FC<OwnComponentProps>) =>
  (props: OwnComponentProps) => {
    const {
      handleFilterChange,
      onFilterClearClick,
      onFilterSubmitClick,
      state,
      isFilterActive,
      handleFilterClose,
      handleFilterOpen,
      isFilterSubmitted,
      handleFilterSubmitted,
      isFilterSubmitClicked,
      onRemoveFields,
    } = useFilter<Partial<FilterValues>>(filterProps);
    const headerNodeRef = useRef<Element | null>(null);

    if (!headerNodeRef.current) {
      headerNodeRef.current = document.querySelector('header');
    }

    const contentPropsWithFilter: ContentFilterProps<FilterValues> = {
      filterManager: { handleFilterChange, state, onFilterSubmitClick, onRemoveFields },
    };

    const componentPropsWithFilter: ComponentFilterProps<FilterValues> = {
      filterManager: {
        handleFilterClose,
        handleFilterOpen,
        isFilterActive,
        isFilterSubmitted,
        state,
        handleFilterSubmitted,
        isFilterSubmitClicked,
      },
    };

    return (
      <>
        {isFilterActive &&
          headerNodeRef.current &&
          ReactDOM.createPortal(
            <FilterContainer onFilterSubmitClick={onFilterSubmitClick} onFilterClearClick={onFilterClearClick}>
              <FilterContent {...contentPropsWithFilter} {...(filterContentProps as OwnContentFilterProps)} />
            </FilterContainer>,
            headerNodeRef.current
          )}
        <Component {...componentPropsWithFilter} {...(props as OwnComponentProps)} />
      </>
    );
  };
