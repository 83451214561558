import i18n from 'i18n';

import React from 'react';

import { EditableTableColumn } from 'components/TableContainer/EditableTableContainer';
import { Certificate } from 'store/admin/certificates/types';
import { FormikWimCertificateCell } from 'elements/FormikWimCertificateCell';

export const certificatesColumns: EditableTableColumn<Certificate>[] = [
  {
    title: 'ID',
    key: 'uid',
    dataIndex: 'uid',
    editable: false,
    render: (_text, _record, index) => (
      <FormikWimCertificateCell dataIndex="uid" index={index} value={_text} readOnly />
    ),
  },
  {
    title: i18n.t('common.name'),
    key: 'name',
    dataIndex: 'name',
    editable: true,
    render: (_text, _record, index) => <FormikWimCertificateCell index={index} dataIndex="name" value={_text} />,
  },
  {
    title: i18n.t('common.type'),
    key: 'type',
    dataIndex: 'type',
    editable: true,
    render: (text, _record, index) => <FormikWimCertificateCell dataIndex="type" index={index} value={text} />,
  },
  {
    title: i18n.t('common.description'),
    key: 'description',
    dataIndex: 'description',
    editable: true,
    render: (text, _record, index) => <FormikWimCertificateCell dataIndex="description" index={index} value={text} />,
  },
  {
    title: i18n.t('common.createdAt'),
    key: 'createDate',
    dataIndex: 'createDate',
    editable: false,
    render: (text, record, index) => (
      <FormikWimCertificateCell index={index} dataIndex="createDate" value={text} readOnly />
    ),
  },
  {
    title: i18n.t('common.updatedAt'),
    key: 'updateDate',
    dataIndex: 'updateDate',
    editable: false,
    render: (text, record, index) => (
      <FormikWimCertificateCell index={index} dataIndex="updateDate" value={text} readOnly />
    ),
  },
];
