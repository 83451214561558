import { call, put, takeLatest } from 'redux-saga/effects';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ApiRequest, SagaPayload } from '../../../../types';
import { UpdateWimsData, WimPlatformUid } from '../../../wim/types/platforms';
import {
  clearMessages,
  requestCreateLanes,
  requestCreateLanesSuccess,
  requestCreateLanesError,
  requestUpdateLanes,
  requestUpdateLanesSuccess,
  requestUpdateLanesError,
  requestGetLanesError,
  requestGetLanesSuccess,
} from './slice';
import { createLanes, updateLanes, getLanes } from '../../../../api/lanes';
import { setNotificationError, setNotificationSuccess } from '../../notifications/slice';
import { MessageTitle } from '../../notifications/types';

function* makeUserRequestSaga(
  failureAction: ActionCreatorWithPayload<string, string>,
  apiFn: ApiRequest,
  payload = {}
) {
  try {
    const response: AxiosResponse<string> = yield call(apiFn, payload);
    if (response) {
      return response;
    }
  } catch (error) {
    yield put(failureAction(error.message));
    throw error;
  }
  return null;
}

export function* lanesUpdateRequestSaga({ payload }: SagaPayload<UpdateWimsData>) {
  try {
    const lanesUpdated = yield call(makeUserRequestSaga, requestUpdateLanesError, updateLanes(payload));
    yield put(setNotificationSuccess({ title: MessageTitle.crudSuccess }));
    yield put(requestUpdateLanesSuccess(lanesUpdated.data));
  } catch (error) {
    yield put(setNotificationError({ title: MessageTitle.crudError, message: error.message }));
    yield put(requestUpdateLanesError(error));
  }
}

export function* lanesCreateRequestSaga({ payload }: SagaPayload<UpdateWimsData>) {
  try {
    const platformUpdated = yield call(makeUserRequestSaga, requestCreateLanesError, createLanes(payload));
    yield put(setNotificationSuccess({ title: MessageTitle.crudSuccess }));
    yield put(requestCreateLanesSuccess(platformUpdated.data));
  } catch (error) {
    yield put(setNotificationError({ title: MessageTitle.crudError, message: error.message }));
    yield put(requestCreateLanesError(error));
  }
}

export function* lanesGetRequestSaga({ payload }: SagaPayload<{ platformUid: WimPlatformUid }>) {
  try {
    const lanesGot = yield call(makeUserRequestSaga, requestGetLanesError, getLanes(payload.platformUid));
    yield put(requestGetLanesSuccess(lanesGot.data));
  } catch (error) {
    yield put(requestGetLanesError(error));
  }
}

export function* lanesUpdateRequestWatchSaga() {
  yield takeLatest(requestUpdateLanes, lanesUpdateRequestSaga);
}

export function* lanesCreateRequestWatchSaga() {
  yield takeLatest(requestCreateLanes, lanesCreateRequestSaga);
}

export function* clearMessagesSaga() {
  yield put(clearMessages());
}
