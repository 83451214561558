import palette from 'palette';
import styled from 'styled-components/macro';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { StyledTotalWeight } from './axle/styles';

interface VehicleSchemaProps {
  width?: string;
  height?: string;
  right?: string;
  left?: string;
  value?: string | number;
  isViolation?: boolean;
}

const schemaHeight = '300px';

export const StyledVehicleSchemaWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: ${schemaHeight};
  align-content: center;
`;

export const StyledWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  width: ${({ width }: VehicleSchemaProps) => width};
`;
export const StyledVehicleWeight = styled(StyledTotalWeight)`
  width: 100px;
  top: 150px;
  margin: -6px 10px;
`;
export const StyledVehicleFront = styled.div`
  flex: 0 0 auto;
  margin: 20px 0 0 0;
  position: relative;
  width: 160px;
  .wrapper {
    height: 248px;
    margin-top: 30px;
    padding-left: 40px;
    padding-bottom: 60px;
    position: relative;
    width: 100%;
    .left-wheel,
    .right-wheel {
      background-color: ${palette.grey.g7};
      border: ${palette.white.w1} 5px solid;
      border-radius: 10px;
      display: block;
      height: 50px;
      position: absolute;
      width: 30px;
      top: 85px;
    }
    .left-wheel {
      right: 0;
    }
    .right-wheel {
      left: 40px;
    }
    .body {
      background-color: ${palette.grey.g2};
      border-radius: 10px 10px 5px 5px;
      height: 120px;
      margin: 0 8px;
      position: relative;
      .screen {
        background-color: ${palette.white.w1};
        border-radius: 3px;
        height: 50px;
        left: 5px;
        position: absolute;
        right: 5px;
        top: 10px;
      }
      .lights {
        bottom: 15px;
        height: 15px;
        left: 8px;
        position: absolute;
        right: 8px;
        margin: 0 0 15px 0;
        .left-light,
        .right-light {
          background-color: ${palette.white.w1};
          border-radius: 15px;
          height: 15px;
          position: absolute;
          width: 15px;
          top: 0;
        }
        .left-light {
          right: 0;
        }
        .right-light {
          left: 0;
        }
      }
    }
  }
`;

export const StyledVehicleSchema = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 60px;
  position: relative;
  height: ${schemaHeight};
  width: ${({ width }: VehicleSchemaProps) => width};
`;

export const StyledFrontOverhang = styled.div`
  position: relative;
  flex: 0 1 auto;
  min-width: 60px;
  max-width: 300px;
  margin: 0 -38px;
  position: relative;
  text-align: center;
  z-index: 10;
  margin-left: 0;
  ::before {
    content: '';
    display: block;
    height: 25px;
    width: 25px;
    position: absolute;
    top: 85px;
    left: -5px;
    z-index: 10;
    border-top: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid ${palette.grey.g7};
  }
  ::after {
    content: '';
    background: ${palette.grey.g2};
    display: block;
    height: 120px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    border-radius: 20px 0 0 20px;
  }
`;

export const StyledWheelbase = styled.div`
  flex: 0 1 auto;
  min-width: 80px;
  max-width: 300px;
  width: ${({ width }: VehicleSchemaProps) => (width && width !== '0px' ? width : '100px')};
  margin: 0 -38px;
  position: relative;
  text-align: center;
  z-index: 10;
  ::after {
    background: ${palette.grey.g2};
    content: '';
    display: block;
    height: 120px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
`;

export const StyledRearOverhang = styled.div`
  flex: 0 1 auto;
  min-width: 60px;
  max-width: 300px;
  margin: 0 -38px;
  position: relative;
  text-align: center;
  z-index: 10;
  margin-right: 0;
  ::after {
    background: ${palette.grey.g2};
    content: '';
    display: block;
    height: 120px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 5px;
    width: 100%;
  }
`;

export const StyledValue = styled.span`
  background: ${({ value }: VehicleSchemaProps) => (value ? palette.white.w1 : palette.red.r1)};
  color: ${({ value }: VehicleSchemaProps) => (value ? palette.black.b1 : palette.white.w1)};
  height: 24px;
  line-height: 24px;
  padding: 0 1px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  white-space: nowrap;
  z-index: 1;
  ::before,
  ::after {
    content: '';
    position: absolute;
    border: 12.5px solid transparent;
    height: 0;
    pointer-events: none;
    width: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  ::before {
    border-right-color: ${({ value }: VehicleSchemaProps) => (value ? palette.white.w1 : palette.red.r1)};
    right: 100%;
  }
  ::after {
    border-left-color: ${({ value }: VehicleSchemaProps) => (value ? palette.white.w1 : palette.red.r1)};
    left: 100%;
  }
`;

export const StyledTotalHeight = styled.div`
  position: absolute;
  left: 12px;
  top: 60px;
  transform: translateX(-50%) translateY(-50%) rotateZ(-90deg);
  height: 24px;
  line-height: 24px;
  width: 96px;
  background: ${({ value, isViolation }: VehicleSchemaProps) =>
    value && !isViolation ? palette.grey.g2 : palette.red.r1};
  text-align: center;
  ::before,
  ::after {
    content: '';
    position: absolute;
    border: 12px solid transparent;
    height: 0;
    pointer-events: none;
    width: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  ::before {
    border-right-color: ${({ value, isViolation }: VehicleSchemaProps) =>
      value && !isViolation ? palette.grey.g2 : palette.red.r1};
    right: 100%;
  }
  ::after {
    border-left-color: ${({ value, isViolation }: VehicleSchemaProps) =>
      value && !isViolation ? palette.grey.g2 : palette.red.r1};
    left: 100%;
  }
`;

export const StyledTotalLength = styled.div`
  bottom: 0;
  height: 24px;
  line-height: 24px;
  position: absolute;
  right: ${({ right = '12px' }: VehicleSchemaProps) => right};
  left: ${({ left }: VehicleSchemaProps) => left};
  background: ${({ value, isViolation }: VehicleSchemaProps) =>
    value && !isViolation ? palette.grey.g2 : palette.red.r1};
  text-align: center;
  width: ${({ width }: VehicleSchemaProps) => width};
  ::before,
  ::after {
    content: '';
    position: absolute;
    border: 12px solid transparent;
    height: 0;
    pointer-events: none;
    width: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  ::before {
    border-right-color: ${({ value, isViolation }: VehicleSchemaProps) =>
      value && !isViolation ? palette.grey.g2 : palette.red.r1};
    right: 100%;
  }
  ::after {
    border-left-color: ${({ value, isViolation }: VehicleSchemaProps) =>
      value && !isViolation ? palette.grey.g2 : palette.red.r1};
    left: 100%;
  }
`;
