import dayjs from 'dayjs';
import { isEmpty, isNull, isUndefined } from 'lodash';
import { ComparativeAnalysisFilterKey, ComparativeAnalysisFilterValues } from 'store/wim/types/transits';

const isValueSignificant = (value: unknown): boolean => !isEmpty(value) || !isUndefined(value) || !isNull(value);

export const queryParamsToFilterValues = (filterValues: {
  [key: string]: any;
}): Partial<ComparativeAnalysisFilterValues> => {
  return Object.keys(filterValues).reduce((acc, currentFilterValueKey) => {
    if (isValueSignificant(filterValues[currentFilterValueKey])) {
      acc[currentFilterValueKey as keyof ComparativeAnalysisFilterValues] = filterValues[currentFilterValueKey];
    }

    if (currentFilterValueKey === ComparativeAnalysisFilterKey.start) {
      acc[currentFilterValueKey] = filterValues[currentFilterValueKey]
        ? dayjs(parseInt(filterValues[currentFilterValueKey], 10)).valueOf()
        : dayjs().subtract(1, 'hour').valueOf();
    }

    if (currentFilterValueKey === ComparativeAnalysisFilterKey.end) {
      acc[currentFilterValueKey] = filterValues[currentFilterValueKey]
        ? dayjs(parseInt(filterValues[currentFilterValueKey], 10)).valueOf()
        : dayjs().valueOf();
    }

    return acc;
  }, {} as Partial<ComparativeAnalysisFilterValues>);
};
