import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetWimMonitoringMetricStatisticsParams } from 'api/types/statistics';
import { WimPlatformUid } from '../types';

import {
  WimMonitoringMetricStatistics,
  WimMonitoringMetric,
  WimMonitoringMetricStatisticsByTime,
  WimMonitoringMetricStatisticsResponse,
} from './types';

interface InitialState {
  wimMonitoringMetrics: WimMonitoringMetric[] | null;
  wimsMonitoringMetricStatistics: WimMonitoringMetricStatistics[] | null;
  wimMonitoringMetricStatisticsByTime: WimMonitoringMetricStatisticsByTime | null;
  isWimMonitoringMetricsLoading: boolean;
  isWimMonitoringMetricStatisticsLoading: boolean;
  isWimMonitoringMetricStatisticsByTimeLoading: boolean;
  isMetricsError: any;
}

const initialState: InitialState = {
  wimMonitoringMetrics: null,
  wimsMonitoringMetricStatistics: null,
  wimMonitoringMetricStatisticsByTime: null,
  isWimMonitoringMetricsLoading: false,
  isWimMonitoringMetricStatisticsLoading: false,
  isWimMonitoringMetricStatisticsByTimeLoading: false,
  isMetricsError: null,
};

const wimMonitoring = createSlice({
  name: 'wimMonitoring',
  initialState,
  reducers: {
    requestWimMonitoringMetrics: (state) => {
      state.isWimMonitoringMetricsLoading = true;
    },
    requestWimsMonitoringMetricStatistics: (state, _payload: PayloadAction<GetWimMonitoringMetricStatisticsParams>) => {
      state.isWimMonitoringMetricStatisticsLoading = true;
    },
    requestWimMonitoringMetricStatisticsByTime: (
      state,
      _payload: PayloadAction<GetWimMonitoringMetricStatisticsParams & { wimUid: WimPlatformUid }>
    ) => {
      state.isWimMonitoringMetricStatisticsByTimeLoading = true;
    },
    getWimMonitoringMetricsSuccess: (state, { payload }: PayloadAction<WimMonitoringMetric[]>) => {
      state.isWimMonitoringMetricsLoading = false;
      state.wimMonitoringMetrics = payload;
    },
    getWimsMonitoringMetricStatisticsSuccess: (
      state,
      { payload }: PayloadAction<WimMonitoringMetricStatisticsResponse>
    ) => {
      state.isWimMonitoringMetricStatisticsLoading = false;
      state.wimsMonitoringMetricStatistics = payload.items;
    },
    getWimMonitoringMetricStatisticsByTimeSuccess: (
      state,
      { payload }: PayloadAction<WimMonitoringMetricStatisticsByTime>
    ) => {
      state.isWimMonitoringMetricStatisticsByTimeLoading = false;
      state.wimMonitoringMetricStatisticsByTime = payload;
    },
    getWimMonitoringMetricsError: (state, { payload }: PayloadAction<any>) => {
      state.isWimMonitoringMetricsLoading = false;
      state.isMetricsError = payload;
    },
    getWimsMonitoringMetricStatisticsError: (state, { payload }: PayloadAction<any>) => {
      state.isWimMonitoringMetricStatisticsLoading = false;
      state.isMetricsError = payload;
    },
    getWimMonitoringMetricStatisticsByTimeError: (state, { payload }: PayloadAction<any>) => {
      state.isWimMonitoringMetricStatisticsByTimeLoading = false;
      state.isMetricsError = payload;
    },
    clearWimsMonitoringMetricStatistics: (state) => {
      const { wimMonitoringMetrics, ...restInitialState } = initialState;
      return { wimMonitoringMetrics: state.wimMonitoringMetrics, ...restInitialState };
    },
  },
});

export const wimMonitoringActions = {
  ...wimMonitoring.actions,
};

export default wimMonitoring.reducer;
