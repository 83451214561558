import Hls, { HlsConfig, HlsListeners } from 'hls.js';

import env from 'config/env';
import { getBasicAuthHeaderValue } from 'helpers/basic-auth.utility';

export interface HLSWrapperInterface {
  instance: Hls;
  attachListener<E extends keyof HlsListeners>(event: E, cb: HlsListeners[E]): void;
  loadSource(url: string): void;
  attachMediaRef(playerRef: HTMLMediaElement): void;
  destroy(): void;
  handleError(errorHandler: HLSErrorHandler): void;
}

export enum HLSErrorHandler {
  NETWORK_ERROR = 'onNetworkError',
  MEDIA_ERROR = 'onMediaError',
}

class HLSWrapper implements HLSWrapperInterface {
  private readonly config: Partial<HlsConfig> = {
    xhrSetup: (xhr: XMLHttpRequest): void => {
      xhr.setRequestHeader('Authorization', getBasicAuthHeaderValue(env.AUTH.username, env.AUTH.password));
    },
  };

  instance: Hls;

  constructor() {
    this.instance = new Hls(this.config);
  }

  attachListener<E extends keyof HlsListeners>(event: E, cb: HlsListeners[E]): void {
    this.instance.on(event, cb);
  }

  loadSource(url: string): void {
    this.instance.loadSource(url);
  }

  attachMediaRef(playerRef: HTMLMediaElement): void {
    this.instance.attachMedia(playerRef);
  }

  destroy(): void {
    this.instance.destroy();
  }

  handleError(errorHandler: HLSErrorHandler): void {
    this[errorHandler]();
  }

  private onNetworkError(): void {
    this.instance.startLoad();
  }

  private onMediaError(): void {
    this.instance.recoverMediaError();
  }
}

export default HLSWrapper;
