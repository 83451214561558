import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';

import { PlatformMedia } from 'pages/AdminManagement/Wims/components/PlatformSummary/types';
import { StyledSelectWithLabel, StyledLabel, StyledRadio, StyledRadioLabel } from './styles';

interface SelectPlatformMediaTypeProps {
  name: string;
  mediaTypes: PlatformMedia[];
}

export const FormikSelectMediaType: FC<SelectPlatformMediaTypeProps> = ({ name, mediaTypes }) => {
  const { t } = useTranslation();

  return (
    <StyledSelectWithLabel>
      <StyledLabel>{t('infoFields.mediaType')}</StyledLabel>
      <StyledRadio>
        {mediaTypes.map((item) => (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <StyledRadioLabel key={item.type}>
            <Field type="radio" name={name} value={item.type} />
            {item.name}
          </StyledRadioLabel>
        ))}
      </StyledRadio>
    </StyledSelectWithLabel>
  );
};
