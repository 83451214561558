import styled from 'styled-components/macro';
import { Empty } from 'antd';

export const StyledEmpty = styled(Empty)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .ant-empty-description {
    font-size: 24px;
  }
`;
