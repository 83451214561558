import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  CameraOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  ReloadOutlined,
  WifiOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MediaNode } from '../../../store/system/types';
import {
  StyledAddress,
  StyledButtonsBlock,
  StyledInfoBlock,
  StyledLastUpdated,
  StyledPopupContent,
  StyledUpdateButton,
} from '../../Wim/Grid/components/List/components/WimContent/styles';
import InfoText from '../../../elements/InfoText';

interface NodeSummaryProps {
  state: MediaNode;
}
export const NodeSummary: React.FC<NodeSummaryProps> = ({ state }) => {
  const { t } = useTranslation();

  return (
    <StyledPopupContent>
      <StyledInfoBlock>
        <InfoText label={t('system.mediaCluster.name')} value={state.name} />
        <InfoText label={t('system.mediaCluster.capacity')} value={state.capacity} />
        <InfoText label={t('system.mediaCluster.objects')} value={state.objects} />
        <InfoText label={t('system.mediaCluster.free')} value={state.free} />
        <InfoText label={t('system.mediaCluster.use')} value={state.use} />
        <InfoText label={t('system.mediaCluster.readRate')} value={state.readRate} />
        <InfoText label={t('system.mediaCluster.writeRate')} value={state.writeRate} />
      </StyledInfoBlock>
    </StyledPopupContent>
  );
};
