export enum DatePeriod {
  LAST_HOUR = 'lastHour',
  TODAY = 'today',
  LAST_DAY = 'lastDay',
  LAST_WEEK = 'lastWeek',
  LAST_14_DAYS = 'last14Days',
  LAST_MONTH = 'lastMonth',
  LAST_QUARTET = 'lastQuartet',
  LAST_YEAR = 'lastYear',
}

export interface TimePeriod {
  start: number;
  end: number;
}

export enum DateRangeStep {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  YEAR = 'year',
}
