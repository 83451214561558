import styled from 'styled-components/macro';
import { Space } from 'antd';

export const OnlineModeSettingsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  border-right: 1px solid #e8e8e8;
`;

export const Text = styled.span`
  font-size: 14px;
`;

export const RefreshTimeContainer = styled(Space)`
  margin-right: 8px;
`;

export const SelectContainer = styled.div`
  width: ${(props: { width: number }) => props.width};
`;
