import dayjs, { QUnitType } from 'dayjs';

import { DatePeriodType } from 'app-constants/date';
import { StatisticDataType } from '../PlatformStatisticsBarChar';

export const parseByPeriodStatisticData = (
  data: StatisticDataType[],
  start: number,
  end: number,
  datePeriod?: DatePeriodType
) => {
  let interval: [number, QUnitType] = [1, 'hour'];
  const currentDatePeriod: StatisticDataType[] = [];

  switch (datePeriod) {
    case DatePeriodType.lastHour:
      interval = [10, 'minute'];
      break;
    case DatePeriodType.today:
      interval = [1, 'hour'];
      break;
    case DatePeriodType.lastWeek:
      interval = [1, 'day'];
      break;
    case DatePeriodType.lastMonth:
      interval = [1, 'day'];
      break;
    case DatePeriodType.lastYear:
      interval = [1, 'month'];
      break;

    default:
      break;
  }

  let dateInterval = dayjs(start)
    .add(...interval)
    .valueOf();

  for (let currentDate = start; currentDate <= end; currentDate = dateInterval) {
    dateInterval = dayjs(currentDate)
      .add(...interval)
      .valueOf();

    const currentDataItem = data.find(
      (item) =>
        item.date >= currentDate &&
        item.date <
          dayjs(currentDate)
            .add(...interval)
            .valueOf()
    );

    if (currentDataItem) {
      currentDatePeriod.push({ ...currentDataItem, date: currentDate });
    } else {
      currentDatePeriod.push({ date: currentDate });
    }
  }

  return currentDatePeriod;
};
