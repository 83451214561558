import styled from 'styled-components/macro';

import palette from 'palette';
import { AxleGroupType } from '..';

interface StyledAxleProps {
  overload?: boolean;
  group?: AxleGroupType;
}

export const StyledAxle = styled.div`
  flex: 0 0 auto;
  padding: 34px 0;
  position: relative;
  width: 70px;
  z-index: 20;
`;

export const StyledTotalWeight = styled.span`
  background: ${({ overload }: StyledAxleProps) => (!overload ? palette.green.g9 : palette.red.r1)};
  color: ${({ overload }: StyledAxleProps) => (!overload ? palette.black.b1 : palette.white.w1)};
  border-radius: 3px;
  bottom: 0;
  display: block;
  height: 24px;
  line-height: 24px;
  position: absolute;
  text-align: center;
  width: 100%;
  ::after {
    content: '';
    position: absolute;
    border: 5px solid transparent;
    height: 0;
    pointer-events: none;
    width: 0;
    border-bottom-color: ${({ overload }: StyledAxleProps) => (!overload ? palette.green.g9 : palette.red.r1)};
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const StyledSingleWheel = styled.div`
  background: ${({ overload }: StyledAxleProps) => (!overload ? palette.grey.g7 : palette.red.r1)};
  border: ${palette.white.w1} 5px solid;
  border-radius: 10px;
  display: block;
  height: 30px;
  margin: 0 auto;
  position: relative;
  width: 50px;
`;

export const StyledDualWheel = styled(StyledSingleWheel)`
  ::after {
    background-color: ${palette.white.w1};
    content: '';
    display: block;
    position: absolute;
    top: 9px;
    bottom: 9px;
    left: 0;
    right: 0;
  }
`;

export const StyledSeparator = styled.div`
  align-items: center;
  background: ${({ group }: StyledAxleProps) => {
    if (!group?.groupIndex) {
      return palette.white.w1;
    }
    if (!group.overload) {
      return palette.blue.b1;
    }
    return palette.red.r1;
  }};
  display: flex;
  height: 76px;
  justify-content: center;
  margin: 0 auto;
  width: 4px;
`;
