import React from 'react';

import { useTranslation } from 'react-i18next';

import { TabsContainer } from 'components';
import env from 'config/env';

import { INITIAL_TRANSITS_FILTER_VALUES } from 'store/wim/filter/transits';
import { ComponentFilterProps, withFilter } from 'hoc/withFilter';
import { wimActions } from 'store/wim/slices';
import { VEHICLE_CONDITION } from 'store/wim/constants';
import { TransitsArchiveFilterValues } from 'store/wim/types/transits';
import { useHeaderWithFiltersRendererWithExport } from 'components/HeaderFilterWithExportButton';
import TransitsArchive from './components/TransitsArchive';
import TransitsFilterPanel from '../TransitsFilterPanel';
import { queryParamsToFilterValues } from '../TransitsFilterPanel/schema';
import { useGetWimVehicleClassifications } from '../hooks/use-get-vehicle-classifications';

const ArchiveTabs: React.FC<ComponentFilterProps<TransitsArchiveFilterValues>> = ({ filterManager }) => {
  const { t } = useTranslation();
  useGetWimVehicleClassifications({ filter: VEHICLE_CONDITION.Used });

  const getArchiveTabs = () => [
    {
      key: 'wim',
      title: t('transitsPage.tabs.wim'),
      content: <TransitsArchive filterManager={{ ...filterManager }} />,
    },
    {
      key: 'detectors',
      title: t('transitsPage.tabs.detectors'),
      content: <h1>Tab Content 2</h1>,
      isHidden: !env.DETECTORS_ENABLED,
    },
  ];

  useHeaderWithFiltersRendererWithExport({ filterManager });
  return (
    <>
      <TabsContainer tabs={getArchiveTabs()} />
    </>
  );
};

export default withFilter<TransitsArchiveFilterValues>({
  filterProps: {
    initialState: INITIAL_TRANSITS_FILTER_VALUES,
    formatUrlParams: queryParamsToFilterValues,
    setFilterValuesAction: wimActions.transits.setFilterValues,
  },
  FilterContent: TransitsFilterPanel,
})(ArchiveTabs);
