import i18n from 'i18n';

import React from 'react';

import { EditableTableColumn } from 'components/TableContainer/EditableTableContainer';
import { Wim } from 'store/wim/types';
import { FormikWimSimpleCell } from 'elements/FormikWimSimpleCell';
import { FormikWimLaneDirectionTypeCell } from 'elements/FormikWimLaneTypeCell';

export const wimsColumns: EditableTableColumn<Wim>[] = [
  {
    title: 'ID',
    key: 'uid',
    dataIndex: 'uid',
    editable: true,
    render: (_text, _record, index) => <FormikWimSimpleCell dataIndex="uid" index={index} value={_text} readOnly />,
  },
  {
    title: i18n.t('violationsPage.laneDescription'),
    key: 'laneDescription',
    dataIndex: 'laneDescription',
    editable: true,
    render: (_text, _record, index) => <FormikWimSimpleCell index={index} dataIndex="laneDescription" value={_text} />,
  },
  {
    title: i18n.t('violationsPage.laneType'),
    key: 'laneType',
    dataIndex: 'laneType',
    editable: true,
    render: (text, _record, index) => <FormikWimSimpleCell dataIndex="laneType" index={index} value={text} />,
  },
  {
    title: i18n.t('violationsPage.laneDirection'),
    key: 'laneDirection',
    dataIndex: 'laneDirection',
    editable: true,
    render: (text, _record, index) => (
      <FormikWimLaneDirectionTypeCell label={text} index={index} dataIndex="laneDirection" />
    ),
  },
  {
    title: i18n.t('violationsPage.laneIndex'),
    key: 'laneIndex',
    dataIndex: 'laneIndex',
    editable: true,
    render: (_text, _record, index) => <FormikWimSimpleCell index={index} dataIndex="laneIndex" value={_text} />,
  },
  {
    title: i18n.t('violationsPage.laneOrigin'),
    key: 'laneOrigin',
    dataIndex: 'laneOrigin',
    editable: true,
    render: (text, record, index) => <FormikWimSimpleCell index={index} dataIndex="laneOrigin" value={text} />,
  },
  {
    title: i18n.t('violationsPage.laneDestination'),
    key: 'laneDestination',
    dataIndex: 'laneDestination',
    editable: true,
    render: (text, record, index) => <FormikWimSimpleCell index={index} dataIndex="laneDestination" value={text} />,
  },
];
