import { all } from 'redux-saga/effects';

import {
  createUserRequestWatchSaga,
  userUpdateRequestWatchSaga,
  usersRequestWatchSaga,
  userActivityLogsWatchSaga,
  userActivityLogsExportWatchSaga,
} from './users/saga';
import {
  platformUpdateRequestWatchSaga,
  platformCreateRequestWatchSaga,
  platformUploadMediaRequestWatchSaga,
} from './platforms/saga';
import { lanesUpdateRequestWatchSaga, lanesCreateRequestWatchSaga } from './platforms/wimLanes/saga';
import {
  groupsGetRequestWatchSaga,
  addWimGroupRequestSagaWatchSaga,
  deleteWimGroupRequestSagaWatchSaga,
} from './platforms/wimGroups/saga';
import { groupsRequestWatchSaga, createGroupRequestWatchSaga, groupUpdateRequestWatchSaga } from './groups/saga';
import { settingsRequestWatchSaga, settingsUpdateRequestWatchSaga } from './settings/saga';
import { certificatesRequestWatchSaga, certificatesUpdateWatchSaga } from './certificates/saga';

export function* adminWatchSaga() {
  yield all([
    usersRequestWatchSaga(),
    userUpdateRequestWatchSaga(),
    createUserRequestWatchSaga(),
    platformUpdateRequestWatchSaga(),
    platformCreateRequestWatchSaga(),
    lanesUpdateRequestWatchSaga(),
    lanesCreateRequestWatchSaga(),
    groupsRequestWatchSaga(),
    createGroupRequestWatchSaga(),
    groupUpdateRequestWatchSaga(),
    groupsGetRequestWatchSaga(),
    addWimGroupRequestSagaWatchSaga(),
    deleteWimGroupRequestSagaWatchSaga(),
    settingsRequestWatchSaga(),
    settingsUpdateRequestWatchSaga(),
    userActivityLogsWatchSaga(),
    platformUploadMediaRequestWatchSaga(),
    userActivityLogsExportWatchSaga(),
    certificatesRequestWatchSaga(),
    certificatesUpdateWatchSaga(),
  ]);
}
