import React, { FC } from 'react';
import { Skeleton, Typography } from 'antd';
import { WifiOutlined } from '@ant-design/icons';

import palette from 'palette';
import { formatWimName, formatWimAddress } from 'helpers/label.formatter';
import { getColorOfWimConnection } from 'helpers/icon.formatter';
import { WimPlatform } from 'store/wim/types';
import { StyledListRow, StyledIconsBlock } from './styles';

interface WimsListRowProps {
  item: WimPlatform;
  selectedItem: WimPlatform | null;
  isListLoaded: boolean | null;
  onItemClick: (item: WimPlatform) => void;
}

export const Row: FC<WimsListRowProps> = ({ item, onItemClick, selectedItem, isListLoaded }) => {
  const { Text } = Typography;

  return (
    <StyledListRow $isSelected={item.uid === selectedItem?.uid} onClick={() => onItemClick(item)}>
      <Skeleton loading={!isListLoaded} active>
        <Text strong>{item.wim}</Text>
        <Text>{formatWimAddress(item.address)}</Text>
        <StyledIconsBlock>
          <WifiOutlined style={{ color: getColorOfWimConnection(item.lastTransitDatetime) }} />
        </StyledIconsBlock>
      </Skeleton>
    </StyledListRow>
  );
};
