import qs from 'qs';

import { API_URLS } from 'app-constants/api';

import secondaryHttpService from 'services/secondary-http.service';
import { GetWimTransitsQueryParams, GetWimTransitsQueryParamsOld } from 'store/wim/types';
import { ApiRequest } from 'types';
import { ROUTES } from 'app-constants/routes';

export const getWimsAvailability: ApiRequest = (params: GetWimTransitsQueryParams) =>
  secondaryHttpService.get(
    `${API_URLS.statistics(ROUTES.StatisticAvailability)}`,
    params,
    (queryParams: URLSearchParams) => qs.stringify(queryParams)
  );

export const getTotalTraffic: ApiRequest = (params: GetWimTransitsQueryParams) =>
  secondaryHttpService.get(
    `${API_URLS.statistics(ROUTES.StatisticTotalTraffic)}`,
    params,
    (queryParams: URLSearchParams) => qs.stringify(queryParams)
  );

export const getTruckTraffic: ApiRequest = (params: GetWimTransitsQueryParams) =>
  secondaryHttpService.get(
    `${API_URLS.statistics(ROUTES.StatisticTruckTraffic)}`,
    params,
    (queryParams: URLSearchParams) => qs.stringify(queryParams)
  );

export const getOverload: ApiRequest = (params: GetWimTransitsQueryParams) =>
  secondaryHttpService.get(`${API_URLS.statistics(ROUTES.StatisticOverload)}`, params, (queryParams: URLSearchParams) =>
    qs.stringify(queryParams)
  );

export const getTransitsStatistic: ApiRequest = (params: GetWimTransitsQueryParams) =>
  secondaryHttpService.get(
    `${API_URLS.statistics(ROUTES.StatisticTotalAndTruckTrafficByTimePeriods)}`,
    params,
    (queryParams: URLSearchParams) => qs.stringify(queryParams)
  );

export const getViolationOverloadStatistic: ApiRequest = (params: GetWimTransitsQueryParams) =>
  secondaryHttpService.get(
    `${API_URLS.statistics(ROUTES.StatisticOverloadByTimePeriods)}`,
    params,
    (queryParams: URLSearchParams) => qs.stringify(queryParams)
  );

export const getVrpDetectStatistic: ApiRequest = (params: GetWimTransitsQueryParams) =>
  secondaryHttpService.get(
    `${API_URLS.statistics(ROUTES.StatisticVrpRecognitionByTimePeriods)}`,
    params,
    (queryParams: URLSearchParams) => qs.stringify(queryParams)
  );

export const getPlatformsList: ApiRequest = (params: GetWimTransitsQueryParams) =>
  secondaryHttpService.get(
    `${API_URLS.statistics(ROUTES.StatisticViolations)}`,
    params,
    (queryParams: URLSearchParams) => qs.stringify(queryParams)
  );

export const getIntrudersList: ApiRequest = (params: GetWimTransitsQueryParamsOld) =>
  secondaryHttpService.get(`${API_URLS.intrudersStatistics(ROUTES.StatisticOffenders)}`, params, (queryParams: any) =>
    qs.stringify(queryParams)
  );

export const getIntensityTraffic: ApiRequest = (params: GetWimTransitsQueryParams) =>
  secondaryHttpService.get(`${API_URLS.statistics(ROUTES.StatisticTrafficIntensity)}`, params, (queryParams: any) =>
    qs.stringify(queryParams)
  );

export const getWimMonitoringMetricStatistics: ApiRequest = (params) =>
  secondaryHttpService.get(`${API_URLS.wimStatisticsByMetric()}`, params, (queryParams: any) =>
    qs.stringify(queryParams)
  );

export const getWimMonitoringMetricStatisticsByTime: ApiRequest = (params) =>
  secondaryHttpService.get(`${API_URLS.wimStatisticsByTimePeriodMetric()}`, params);
