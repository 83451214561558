import styled from 'styled-components/macro';
import { Input, Select } from 'antd';

import palette from 'palette';

export const StyledGridWrapper = styled.div`
  .ant-tabs {
    padding: 0 24px 24px;
    background-color: ${palette.white.w1};
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px;
  }
`;

export const StyledFilterFormSearch = styled(Input.Search)`
  grid-row: 1 / 2;
  grid-column: 1 / 2;
`;

export const StyledFilterInputSearch = styled(Input)`
  grid-row: 1 / 2;
  grid-column: 1 / 2;
`;

export const StyledFilterSelectSearch = styled(Select)`
  grid-row: 1 / 2;
  grid-column: 1 / 2;
`;

export const StyledFilterFormActions = styled.div`
  display: grid;
  grid-template: auto / auto auto;
  grid-column: 2;
  grid-row: 2;
  justify-self: end;
  grid-column-gap: 10px;
`;
