import styled from 'styled-components/macro';

interface StyledChartLegendItemProps {
  $bulletColor?: string;
}

export const StyledContent = styled.div`
  display: flex;
  align-items: center;
  height: 500px;
`;

export const StyledChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
`;

export const StyledChartLegendList = styled.ul`
  display: flex;
  width: 180px;
  flex-direction: column;
  justify-content: center;
  list-style: none;

  li:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const StyledChartLegendItem = styled.li<StyledChartLegendItemProps>`
  &::before {
    content: '\u2022';
    color: ${({ $bulletColor }) => $bulletColor};
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;
