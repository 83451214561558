import httpService from 'services/http.service';
import { ApiRequest } from 'types';
import { API_URLS } from '../app-constants/api';
import { UpdateWimsData, WimPlatformUid } from '../store/wim/types/platforms';
import secondaryHttpService from '../services/secondary-http.service';

export const getPlatformDetectors: ApiRequest = (data) => httpService.get('URL', data);

export const updateLanes =
  (wimLanes: UpdateWimsData): ApiRequest =>
  () => {
    const { platformUid, lanes } = wimLanes;
    return secondaryHttpService.put(API_URLS.wimLanes(platformUid), { lanes });
  };

export const createLanes =
  (wimLanes: UpdateWimsData): ApiRequest =>
  () => {
    const { platformUid, lanes } = wimLanes;
    return secondaryHttpService.post(API_URLS.wimLanes(platformUid), lanes);
  };

export const getLaneTypes = (): ApiRequest => {
  return () => {
    return secondaryHttpService.get(API_URLS.wimLaneTypes(), null);
  };
};

export const getLanes =
  (platformUid: WimPlatformUid): ApiRequest =>
  () => {
    return secondaryHttpService.get(API_URLS.wimLanes(platformUid), null);
  };
