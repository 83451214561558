export const CHARS = {
  ballot: '\u2718',
  bullet: '\u2022',
  check: '\u2714',
  exclamation: '\u2757',
  infinity: '\u221e',
  laquo: '\u00AB',
  lineFeed: '\u000a',
  mdash: '\u2014',
  minus: '\u2212',
  nbsp: '\u00a0',
  ndash: '\u2013',
  raquo: '\u00BB',
  rouble: '\u20bd',
  space: '\u0020',
};
