import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetWimTransitsQueryParams, WimTransits } from '../types';
import {
  TransitsArchiveFilterValues,
  GetWimTransitPayload,
  WimTransit,
  GetWimTransitPhotoPayload,
  GetWimTransitPhoto,
  WimTransitPhotos,
  GetWimTransitPhotosPayload,
  IntensityTraffic,
  WimTransitItemWithMapPosition,
  GetTransitWithCoordinatesByPlate,
  GetWimTransitPhotos,
} from '../types/transits';

type InitialState = {
  isOnlineMode: boolean;
  onlineIntervalId: number | null;
  isLoading: boolean;
  transits: WimTransits | null;
  error: any;
  filterValues: Partial<TransitsArchiveFilterValues> | null;
  transit: WimTransit | null;
  isTransitPhotosLoading: boolean;
  transitPhotos: WimTransitPhotos;
  intensityTraffic: IntensityTraffic[];
  transitWithCoordinatesByPlate: WimTransitItemWithMapPosition[] | null;
  exportDialogIsOpen: boolean;
};

const initialState: InitialState = {
  isOnlineMode: false,
  onlineIntervalId: null,
  isLoading: false,
  transits: null,
  error: null,
  filterValues: null,
  transit: null,
  isTransitPhotosLoading: false,
  transitPhotos: {
    PLATE: undefined,
    PLATER: undefined,
    BODY: undefined,
    THUMB: undefined,
    FRONT: undefined,
    REAR: undefined,
    SIDE: undefined,
    SIDEB: undefined,
  },
  intensityTraffic: [],
  transitWithCoordinatesByPlate: [],
  exportDialogIsOpen: false,
};

const transits = createSlice({
  name: 'transits',
  initialState,
  reducers: {
    requestTransits: (state, { payload }: PayloadAction<GetWimTransitsQueryParams>): void => {
      state.isLoading = true;
    },
    getTransitsSuccess: (state, { payload }: PayloadAction<WimTransits>): void => {
      state.isLoading = false;
      state.transits = payload;
    },
    getTransitsError: (state, { payload }: PayloadAction<string>): void => {
      state.isLoading = false;
      state.error = payload;
      if (state.onlineIntervalId) {
        clearInterval(state.onlineIntervalId);
      }
      state.onlineIntervalId = null;
      state.isOnlineMode = false;
    },
    setOnlineMode: (state, { payload }: PayloadAction<boolean>): void => {
      state.transits = null;
      state.isOnlineMode = payload;
    },
    setOnlineInterval: (state, { payload }: PayloadAction<{ intervalId: number }>): void => {
      state.onlineIntervalId = payload.intervalId;
    },
    stopTransitsOnline: (state): void => {
      if (state.onlineIntervalId) {
        clearInterval(state.onlineIntervalId);
      }
      state.isLoading = false;
      state.onlineIntervalId = null;
      state.isOnlineMode = false;
    },
    clearTransits: (state): void => {
      state.transits = null;
    },
    setFilterValues: (state, { payload }: PayloadAction<Partial<TransitsArchiveFilterValues>>): void => {
      state.filterValues = payload;
    },
    clearFilterValues: (state): void => {
      state.filterValues = null;
    },
    requestTransit: (state, { payload }: PayloadAction<GetWimTransitPayload>): void => {
      state.isLoading = true;
    },
    getTransitSuccess: (state, { payload }: PayloadAction<WimTransit>): void => {
      state.isLoading = false;
      state.transit = payload;
    },
    getTransitError: (state, { payload }: PayloadAction<string>): void => {
      state.isLoading = false;
      state.error = payload;
    },
    clearTransit: (state): void => {
      state.transit = null;
    },
    requestTransitPhoto: (state, { payload }: PayloadAction<GetWimTransitPhoto>): void => {
      state.isTransitPhotosLoading = true;
    },
    getTransitPhotoSuccess: (state, { payload }: PayloadAction<GetWimTransitPhotoPayload>): void => {
      state.isTransitPhotosLoading = false;
      state.transitPhotos[payload.photoType] = payload.value;
    },
    getTransitPhotoError: (state, { payload }: PayloadAction<string>): void => {
      state.isTransitPhotosLoading = false;
      state.error = payload;
    },
    requestTransitPhotos: (state, { payload }: PayloadAction<GetWimTransitPhotos>): void => {
      state.isTransitPhotosLoading = true;
    },
    getTransitPhotosSuccess: (state, { payload }: PayloadAction<GetWimTransitPhotosPayload>): void => {
      state.isTransitPhotosLoading = false;
      payload.photos.forEach((photo) => {
        state.transitPhotos[photo.photoType] = photo.value;
      });
    },
    getTransitPhotosError: (state, { payload }: PayloadAction<string>): void => {
      state.isTransitPhotosLoading = false;
      state.error = payload;
    },
    clearTransitPhotos: (state): void => {
      state.transitPhotos = {
        PLATE: undefined,
        PLATER: undefined,
        BODY: undefined,
        THUMB: undefined,
        FRONT: undefined,
        REAR: undefined,
        SIDE: undefined,
        SIDEB: undefined,
      };
    },
    clearTransitsInfo: (state): void => {
      state.transits = null;
      state.filterValues = null;
    },
    clearTransitInfo: (state): void => {
      state.transit = null;
      state.transitPhotos = {
        PLATE: undefined,
        PLATER: undefined,
        BODY: undefined,
        THUMB: undefined,
        FRONT: undefined,
        REAR: undefined,
        SIDE: undefined,
        SIDEB: undefined,
      };
    },
    requestIntensityTraffic: (state, { payload }: PayloadAction<any>): void => {
      state.isLoading = true;
    },
    getIntensityTrafficSuccess: (state, { payload }: PayloadAction<IntensityTraffic[]>): void => {
      state.isLoading = false;
      state.intensityTraffic = payload;
    },
    getIntensityTrafficError: (state, { payload }: PayloadAction<string>): void => {
      state.isLoading = false;
    },
    onOpenExportDialog: (state) => {
      state.exportDialogIsOpen = true;
    },
    onCloseExportDialog: (state) => {
      state.exportDialogIsOpen = false;
    },
    requestTransitWithCoordinatesByPlate: (
      state,
      { payload }: PayloadAction<GetTransitWithCoordinatesByPlate>
    ): void => {
      state.isLoading = true;
    },
    getTransitWithCoordinatesByPlate: (state, { payload }: PayloadAction<WimTransitItemWithMapPosition[]>): void => {
      state.isLoading = false;
      state.transitWithCoordinatesByPlate = payload;
    },
  },
});

export const {
  requestTransits,
  getTransitsSuccess,
  getTransitsError,
  setOnlineMode,
  setOnlineInterval,
  stopTransitsOnline,
  clearTransits,
  setFilterValues,
  clearFilterValues,
  requestTransit,
  getTransitSuccess,
  getTransitError,
  clearTransit,
  requestTransitPhoto,
  getTransitPhotoSuccess,
  getTransitPhotoError,
  requestTransitPhotos,
  getTransitPhotosSuccess,
  getTransitPhotosError,
  clearTransitPhotos,
  clearTransitsInfo,
  clearTransitInfo,
  requestIntensityTraffic,
  getIntensityTrafficSuccess,
  getIntensityTrafficError,
  onCloseExportDialog,
  onOpenExportDialog,
  requestTransitWithCoordinatesByPlate,
  getTransitWithCoordinatesByPlate,
} = transits.actions;

export default transits.reducer;
