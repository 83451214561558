import * as Yup from 'yup';
import i18n from 'i18n';
import YupPassword from 'yup-password';

YupPassword(Yup);

export const signInValidationSchema = Yup.object().shape({
  email: Yup.string().required(i18n.t('loginForm.emptyLogin')),
  password: Yup.string().required(i18n.t('loginForm.emptyPass')).min(6, i18n.t('loginForm.passwordLess6Chars')),
});

export const confirmValidationSchema = Yup.object().shape({
  code: Yup.string().required(i18n.t('loginForm.emptyCode')),
});

export const passwordResetValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required(i18n.t('loginForm.emptyPass'))
    .min(8, i18n.t('loginForm.passwordLess6Chars'))
    .minRepeating(1, i18n.t('user.validation.password.minRepeating'))
    .minLowercase(1, i18n.t('user.validation.password.minLowercase'))
    .minUppercase(1, i18n.t('user.validation.password.minUppercase'))
    .minNumbers(1, i18n.t('user.validation.password.minNumbers'))
    .minSymbols(1, i18n.t('user.validation.password.minSymbols')),
  repeatPassword: Yup.string().oneOf([Yup.ref('password'), null], i18n.t('user.validation.password.minSymbols')),
});
