import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UpdateWimValues } from 'pages/AdminManagement/Wims/components/WimsSummary/modal/UpdateContent/types';
import { UpdateWimPlatformData, UpdateWimPlatformFormData } from '../../wim/types/platforms';
import {
  UpdatePlatformMedia,
  UpdatePlatformValues,
} from '../../../pages/AdminManagement/Wims/components/PlatformSummary/types';

interface InitialState {
  isUpdatePlatformLoading: boolean;
  isCreatePlatformLoading: boolean;
  result: Pick<UpdateWimValues, 'lanes'> | null;
  error: any;
}

const initialState: InitialState = {
  isUpdatePlatformLoading: false,
  isCreatePlatformLoading: false,
  result: null,
  error: null,
};

const platforms = createSlice({
  name: 'platforms',
  initialState,
  reducers: {
    requestUpdatePlatform: (state, { payload }: PayloadAction<UpdateWimPlatformData>) => {
      state.isUpdatePlatformLoading = true;
    },
    requestUpdatePlatformSuccess: (state, { payload }: PayloadAction<UpdatePlatformValues>): void => {
      state.isUpdatePlatformLoading = false;
      state.result = payload;
    },
    requestUpdatePlatformError: (state, { payload }: PayloadAction<string>): void => {
      state.isUpdatePlatformLoading = false;
      state.error = payload;
    },
    requestCreatePlatform: (state, { payload }: PayloadAction<UpdateWimPlatformFormData>) => {
      state.isCreatePlatformLoading = true;
    },
    requestCreatePlatformSuccess: (state, { payload }: PayloadAction<any>): void => {
      state.isCreatePlatformLoading = false;
      state.result = payload;
    },
    requestCreatePlatformError: (state, { payload }: PayloadAction<string>): void => {
      state.isCreatePlatformLoading = false;
      state.error = payload;
    },
    clearMessages: (state): void => {
      state.error = null;
      state.result = null;
    },
    requestUploadPlatformMedia: (state, { payload }: PayloadAction<UpdatePlatformMedia>) => {},
  },
});

export const {
  requestUpdatePlatform,
  requestUpdatePlatformSuccess,
  requestUpdatePlatformError,
  requestCreatePlatform,
  requestCreatePlatformSuccess,
  requestCreatePlatformError,
  clearMessages,
  requestUploadPlatformMedia,
} = platforms.actions;

export default platforms.reducer;
