import React from 'react';
import { ColumnType, ColumnGroupType } from 'antd/lib/table/interface';
import i18n from 'i18n';
import { Tooltip } from 'antd';

import { WAGA_VEHICLE_TYPES } from 'app-constants/waga-vehicle-types';
import { wrapTableTitle } from 'components/TableContainer/DefaultTableContainer';
import { CHARS } from 'app-constants/chars';
import palette from 'palette';
import { WimTransitItemOld } from 'store/wim/types';
import { IntensityTraffic } from 'store/wim/types/transits';
import { HintTooltip } from '../styles';

export type IntensityTrafficColumns = {
  vehicleClass: ColumnType<IntensityTraffic>;
  transitsCount: ColumnType<IntensityTraffic>;
  violations: ColumnGroupType<IntensityTraffic>;
};

export const getIntensityTrafficColumns = (): IntensityTrafficColumns => {
  return {
    vehicleClass: {
      title: (): JSX.Element => wrapTableTitle(i18n.t('transitsIntensityTrafficPage.table.columns.vehicleClass')),
      key: 'vehicleClass',
      dataIndex: 'vehicleClass',
      align: 'left',
      sorter: true,
      ellipsis: true,
      render: (vehicleType: WimTransitItemOld['vehicleType']) =>
        vehicleType ? (
          <Tooltip
            title={<HintTooltip>{WAGA_VEHICLE_TYPES[vehicleType as string].description}</HintTooltip>}
            color={palette.white.w1}
          >
            <span>{vehicleType}. </span>
            <img src={WAGA_VEHICLE_TYPES[vehicleType as string].icon} alt="vehicle-type" height={25} />
          </Tooltip>
        ) : (
          CHARS.mdash
        ),
    },
    transitsCount: {
      title: (): JSX.Element => wrapTableTitle(i18n.t('transitsIntensityTrafficPage.table.columns.transitsCount')),
      dataIndex: 'total',
      key: 'total',
      sorter: true,
    },
    violations: {
      title: (): JSX.Element => wrapTableTitle(i18n.t('common.violations')),
      children: [
        {
          title: (): JSX.Element => wrapTableTitle(i18n.t('common.total')),
          dataIndex: 'totalViolations',
          key: 'totalViolations',
          sorter: true,
        },
        {
          title: (): JSX.Element => wrapTableTitle(i18n.t('transitsIntensityTrafficPage.table.columns.weight')),
          dataIndex: 'overload',
          key: 'overload',
          sorter: true,
        },
        {
          title: (): JSX.Element => wrapTableTitle(i18n.t('transitsIntensityTrafficPage.table.columns.axleLoad')),
          dataIndex: 'overaxleload',
          key: 'overaxleload',
          sorter: true,
        },
        {
          title: (): JSX.Element => wrapTableTitle(i18n.t('transitsIntensityTrafficPage.table.columns.axlesLoad')),
          dataIndex: 'overgroupload',
          key: 'overgroupload',
          sorter: true,
        },
        {
          title: (): JSX.Element => wrapTableTitle(i18n.t('transitsIntensityTrafficPage.table.columns.dimensions')),
          dataIndex: 'overdimension',
          key: 'overdimension',
          sorter: true,
        },
      ],
    },
  };
};
